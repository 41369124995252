<!--<div class="cover-image-experience" *ngIf="details.coverPicture">-->
<!--  &lt;!&ndash;    <img src="{{details.coverPicture}}" alt="{{details.name}}" />&ndash;&gt;-->
<!--</div>-->
<div class="container">
  <div class="row">
    <div class="inn-pag-ban">
      <h2>{{details.name}}</h2>
<!--      <h3>{{details.members}} {{"Members" | translate}}</h3>-->
    </div>
  </div>
</div>
