import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Group} from '../../interfaces/group';
import {GROUPS_PATH} from '../../../utils/actionsLabels/pathLabels';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {NotificationService} from '../../services/notification.service';
import {TokenHelperService} from '../../services/token-helper.service';

@Component({
  selector: 'app-suggested-group-item',
  templateUrl: './suggested-group-item.component.html',
  styleUrls: ['./suggested-group-item.component.scss']
})
export class SuggestedGroupItemComponent implements OnInit {
  @Input() data: Group;
  @Output() askJoinClickAction: EventEmitter<{ toElement?: string, output?: any }> = new EventEmitter<any>();
  linkDetails = '';
  isLogged = false;
  disabled = false;
  showMembers = false;

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private notificationService: NotificationService,
    private tokenHelperService: TokenHelperService ) {
  }

  ngOnInit(): void {
    this.linkDetails = GROUPS_PATH + '/' + this.data.id;
    this.isLogged = this.tokenHelperService.getConnectedUser();
    if (this.data.members > 1) {
      this.showMembers = true;
    }
  }

  askJoinGroup() {
    if (this.disabled === false) {
      this.askJoinClickAction.emit({output: this.data.id});
    }
    this.disabled = true;

    // if (!this.isLogged) {
    //   this.router.navigate(['/login']).then();
    // } else {
    //   this.notificationService.showSuccess(JOIN_GROUP_MESSAGE_CONTENT, JOIN_GROUP_MESSAGE_TITLE);
    // }
  }
}
