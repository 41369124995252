import {Component, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions} from 'chart.js';
import {Color, Label} from 'ng2-charts';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {

  lineChartData: ChartDataSets[] = [
    {data: [30, 12, 1, 25, 20, 7, 9], label: 'visits', lineTension: 0}
  ];


// Labels shown on the x-axis
  lineChartLabels: Label[] = [
    '2020-05-16T23:59:59+00:00',
    '2020-05-17T23:59:59+00:00',
    '2020-05-18T23:59:59+00:00',
    '2020-05-19T23:59:59+00:00',
    '2020-05-20T23:59:59+00:00',
    '2020-05-21T23:59:59+00:00',
    '2020-05-22T23:59:59+00:00'];


  // Define chart options
  lineChartOptions: ChartOptions = {
    responsive: true
  };

  // Define colors of chart segments
  lineChartColors: Color[] = [

    { // dark grey
      backgroundColor: '#c5d1e400',
      borderColor: '#16191f',
    }
  ];

  // Set true to show legends
  lineChartLegend = true;

  // Define type of chart
  lineChartType = 'line';

  lineChartPlugins = [];

  constructor() {
  }

  ngOnInit(): void {
  }

  // events
  chartClicked({event, active}: { event: MouseEvent, active: {}[] }): void {
  }

  chartHovered({event, active}: { event: MouseEvent, active: {}[] }): void {
  }
}
