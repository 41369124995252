<h1 mat-dialog-title> {{'On click' | translate }} </h1>


<div mat-dialog-actions class="  on-click-popup">

  <div class="  col-md-12 "  >
    <div class="  select-type "  >
      <h4 class="col-md-12"> {{'Select action' | translate }} </h4>
      <mat-form-field >
        <mat-select  [(ngModel)]="type">
          <div *ngFor="let option of types">
            <mat-option value="{{option.value}}">{{option.label}}</mat-option>
          </div>
        </mat-select>
      </mat-form-field>
    </div>
    <!----------------------------------- null Start ------------------------------------->
    <div class=" button-popup" *ngIf="!type || type === ''">
      <button  class=" col-md-offset-6 col-md-4  waves-effect waves-light btn-large button" (click)="cancel()">{{ 'Cancel' | translate }}</button>
    </div>
    <!----------------------------------- null Start ------------------------------------->

  </div>

    <!----------------------------------- URL Start ------------------------------------->
    <form  [formGroup]="formUrlPopup"  class="  col-md-12 " *ngIf="type === 'URL'">
      <div class="select-type">
        <h4 class="col-md-12 "> {{type }} </h4>
        <input type="url"
             id="Url"
             class="autocomplete "
             [formControl]="formUrlPopup.controls.url"
               (change)=" urlChange = true"
               >
        <div class="error-form" *ngIf="!formUrlPopup.valid &&  urlChange === true">
          {{ VALID_LINK | translate}}
        </div>
      </div>
      <div  class="row button-popup  " >
        <button *ngIf="!action" type="submit" class=" col-md-offset-1 col-md-4  waves-effect waves-light btn-large save-popup-style " (click)="submitUrl()" [disabled]="!formUrlPopup.valid ">{{ 'Add' | translate }}</button>
        <button *ngIf="action" type="submit" class=" col-md-offset-1 col-md-4  waves-effect waves-light btn-large save-popup-style " (click)="submitUrl()" [disabled]="!formUrlPopup.valid || (urlChange === false && action[0].type === type)">{{ 'EDIT' | translate }}</button>
        <button  class=" col-md-offset-1 col-md-4  waves-effect waves-light btn-large button" (click)="cancel()">{{ 'Cancel' | translate }}</button>
      </div>
    </form>
    <!----------------------------------- URL Start ------------------------------------->

</div>


