import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainMenuComponent} from './components/main-menu/main-menu.component';
import {TranslateModule} from '@ngx-translate/core';
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {ExperienceComponent} from './components/experience/experience.component';
import {NearbyListingComponent} from './components/nearby-listing/nearby-listing.component';
import {SuggestedGroupComponent} from './components/suggested-group/suggested-group.component';
import {QrcodeComponent} from './components/qrcode/qrcode.component';
import {GalleryComponent} from './components/gallery/gallery.component';
import {RouterModule} from '@angular/router';

import {FooterComponent} from './components/footer/footer.component';
import {ExperienceGridComponent} from './components/experience-grid/experience-grid.component';
import {FirstMenuComponent} from './components/editor-menu/first-menu/first-menu.component';
import {SecondMenuComponent} from './components/editor-menu/second-menu/second-menu.component';

import {MainMenuConnectedComponent} from './components/main-menu-connected/main-menu-connected.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ColorPickerModule} from 'ngx-color-picker';
import {MatExpansionModule} from '@angular/material/expansion';
import {DetailsMenuComponent} from './components/experience-details/details-menu/details-menu.component';
import {DetailsHeaderComponent} from './components/experience-details/details-header/details-header.component';
import {DetailsAboutComponent} from './components/experience-details/details-about/details-about.component';
import {DetailsGalleryComponent} from './components/experience-details/details-gallery/details-gallery.component';
import {DetailsReviewsComponent} from './components/experience-details/details-reviews/details-reviews.component';
import {
  DetailsWriteReviewComponent
} from './components/experience-details/details-write-review/details-write-review.component';
import {
  DetailsContactCreatorComponent
} from './components/experience-details/details-contact-creator/details-contact-creator.component';
import {
  DetailsSuggestionsComponent
} from './components/experience-details/details-suggestions/details-suggestions.component';
import {ProfilePictureComponent} from './components/account-components/profile-picture/profile-picture.component';
import {NotificationsComponent} from './components/account-components/notifications/notifications.component';
import {AccountMenuComponent} from './components/account-components/account-menu/account-menu.component';
import {ChangePasswordComponent} from './components/account-components/change-password/change-password.component';
import {
  GeneralInformationsComponent
} from './components/account-components/general-informations/general-informations.component';
import {ManageProfileComponent} from './components/account-components/manage-profile/manage-profile.component';
import {
  ImportTreeDObjectDialogComponent
} from './components/popups/import-tree-dobject-dialog/import-tree-dobject-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {VideoUrlDialogComponent} from './components/popups/video-url-dialog/video-url-dialog.component';
import {RxReactiveFormsModule} from '@rxweb/reactive-form-validators';
import {ErrorPopupComponent} from './components/popups/error-popup/error-popup.component';
import {MatSelectModule} from '@angular/material/select';
import {SingleReviewComponent} from './components/experience-details/single-review/single-review.component';
import {
  SingleSuggestedExperienceComponent
} from './components/single-suggested-experience/single-suggested-experience.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {
  DisplayInformationPageComponent
} from './components/display-information-page/display-information-page.component';
import {
  PopupExperienceVideoComponent
} from './components/experience-details/popup-experience-video/popup-experience-video.component';
import {MarkerNameDialogComponent} from './components/popups/marker-name-dialog/marker-name-dialog.component';
import {RightMenuComponent} from './components/editor-menu/right-menu/right-menu.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog/confirm-dialog.component';
import {MarkerTypeDialogComponent} from './components/popups/marker-type-dialog/marker-type-dialog.component';
import {
  AddEditExperienceFormComponent
} from './components/add-edit-experience-form/add-edit-experience-form.component';
import {EditProfileComponent} from './components/account-components/edit-profile/edit-profile.component';
import {MyExperiencesComponent} from './components/account-components/my-experiences/my-experiences.component';
import {OwnReviewComponent} from './components/experience-details/own-review/own-review.component';
import {AddNewElementComponent} from './components/add-new-element/add-new-element.component';
import {
  GroupDetailsHeaderComponent
} from './components/group-details/group-details-header/group-details-header.component';
import {
  GroupDetailsAboutComponent
} from './components/group-details/group-details-about/group-details-about.component';
import {
  GroupDetailsContactAdminsComponent
} from './components/group-details/group-details-contact-admins/group-details-contact-admins.component';
import {
  GroupDetailsGroupsSuggestionsComponent
} from './components/group-details/group-details-groups-suggestions/group-details-groups-suggestions.component';
import {
  GroupDetailsMembersComponent
} from './components/group-details/group-details-members/group-details-members.component';
import {
  GroupDetailsMemberComponent
} from './components/group-details/group-details-member/group-details-member.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {
  GroupDetailsExperiencesComponent
} from './components/group-details/group-details-experiences/group-details-experiences.component';
import {
  GroupDetailsDeleteGroupComponent
} from './components/group-details/group-details-delete-group/group-details-delete-group.component';
import {
  FixedMenuOfConnectedUserComponent
} from './components/fixed-menu-of-connected-user/fixed-menu-of-connected-user.component';
import {
  ExperienceStatusComponent
} from './components/experience-details/experience-status/experience-status.component';
import {AddEditGroupFormComponent} from './components/add-edit-group-form/add-edit-group-form.component';
import {ManageMembersGroupComponent} from './components/manage-members-group/manage-members-group.component';
import {GroupsIManageComponent} from './components/groups-i-manage/groups-i-manage.component';
import {YesNoDialogComponent} from './components/popups/yes-no-dialog/yes-no-dialog.component';
import {A11yModule} from '@angular/cdk/a11y';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {SingleGroupOwnerComponent} from './components/single-group-owner/single-group-owner.component';
import {GroupsIAmInComponent} from './components/groups-i-am-in/groups-i-am-in.component';
import {JoinFirstGroupComponent} from './components/join-first-group/join-first-group.component';
import {CreateFirstGroupComponent} from './components/create-first-group/create-first-group.component';
import {GroupGridComponent} from './components/group-grid/group-grid.component';
import {MatchHeightDirective} from './directives/match-height.directive';
import {SuggestedGroupItemComponent} from './components/suggested-group-item/suggested-group-item.component';
import {LineChartModule} from '@swimlane/ngx-charts';
import {NgSelectModule} from '@ng-select/ng-select';
import {AnalyticsChartComponent} from './components/analytics-chart/analytics-chart.component';
import {AnalyticsVisitorsComponent} from './components/analytics-visitors/analytics-visitors.component';
import {AnalyticsDurationComponent} from './components/analytics-duration/analytics-duration.component';
import {AnalyticsFilterComponent} from './components/analytics-filter/analytics-filter.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {ChartsModule} from 'ng2-charts';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatTabsModule} from '@angular/material/tabs';
import {IMaskModule} from 'angular-imask';
import {OnClickTypeDialogComponent} from './components/popups/on-click-type-dialog/on-click-type-dialog.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {Import3DFilesComponent} from './components/popups/import3-dfiles/import3-dfiles.component';
import {RatingComponent} from './components/rating/rating/rating.component';
import {MyGroupsSectionComponent} from './components/my-groups/my-groups-section/my-groups-section.component';
import {EditGroupSectionComponent} from './components/my-groups/edit-group-section/edit-group-section.component';
import {AddExperienceDialogComponent} from './components/popups/add-experience-dialog/add-experience-dialog.component';
import {
  CreateExperienceDialogComponent
} from './components/popups/create-experience-dialog/create-experience-dialog.component';
import {LoginDialogComponent} from './components/popups/login-dialog/login-dialog.component';
import {ManageExperiencesComponent} from './components/my-groups/manage-experiences/manage-experiences.component';
import {ManageMembersComponent} from './components/my-groups/manage-members/manage-members.component';
import {GroupMembersDialogComponent} from './components/popups/group-members-dialog/group-members-dialog.component';
import {NgxJdenticonModule} from 'ngx-jdenticon';
import {AddEditTemplateFormComponent} from './components/add-edit-template-form/add-edit-template-form.component';
import {FormTemplateComponent} from './components/my-templates/form-template/form-template.component';
import {
  ChooseCreateTemplateFormComponent
} from './components/choose-create-template-form/choose-create-template-form.component';
import {NgxCarouselModule} from 'ngx-light-carousel';
import {
  ExperienceTemplateSingleComponent
} from './components/experience-template-single/experience-template-single.component';
import {
  MyTemplatesProfileComponent
} from './components/my-templates/my-templates-profile/my-templates-profile.component';
import {MyTemplatesSectionComponent} from './components/my-templates-section/my-templates-section.component';
import {
  CreateTemplateDialogComponent
} from './components/popups/create-template-dialog/create-template-dialog.component';
import {
  SingleSuggestedTemplateComponent
} from './components/single-suggested-template/single-suggested-template.component';
import {
  ChooseCreateExperienceFormComponent
} from './components/choose-create-experience-form/choose-create-experience-form.component';
import {
  CreateFromExperienceDialogComponent
} from './components/popups/create-from-experience-dialog/create-from-experience-dialog.component';
import {AssetsListingComponent} from './components/assets-library/assets-listing/assets-listing.component';
import {AssetFormComponent} from './components/assets-library/asset-form/asset-form.component';
import {ListingExperienceItemComponent} from './components/listing-experience-item/listing-experience-item.component';
import {ListingTemplateItemComponent} from './components/listing-template-item/listing-template-item.component';
import {
  ExperiencesSectionComponent
} from './components/home-components/experiences-section/experiences-section.component';
import {OwlModule} from 'ngx-owl-carousel';
import {TemplatesSectionComponent} from './components/home-components/templates-section/templates-section.component';
import {GroupsSectionComponent} from './components/home-components/groups-section/groups-section.component';
import {ListingGroupItemComponent} from './components/listing-group-item/listing-group-item.component';
import {AutosizeModule} from '@techiediaries/ngx-textarea-autosize';
import { ImportAssetDialogComponent } from './components/popups/import-asset-dialog/import-asset-dialog.component';
import { FacebookModule } from 'ngx-facebook';
import {ShareButtonModule} from "ngx-sharebuttons/button";
import { MyFavoritesComponent } from './components/account-components/my-favorites/my-favorites.component';
import { MyTemplatesFavoritesComponent } from './components/account-components/my-templates-favorites/my-templates-favorites.component';
import { ListingExperienceFavoriteItemComponent } from './components/listing-experience-favorite-item/listing-experience-favorite-item.component';
import { ListingTemplateFavoriteItemComponent } from './components/listing-template-favorite-item/listing-template-favorite-item.component';
import { ListingGroupFavoriteItemComponent } from './components/listing-group-favorite-item/listing-group-favorite-item.component';
import { MyGroupsFavoritesComponent } from './components/account-components/my-groups-favorites/my-groups-favorites.component';
import { ModerateComponent } from './components/moderate/moderate.component';
import { RejectExperienceDialogComponent } from './components/popups/reject-experience-dialog/reject-experience-dialog.component';
import { RejectReviewDialogComponent } from './components/popups/reject-review-dialog/reject-review-dialog.component';
import { ResetLikeDislikeDialogComponent } from './components/popups/reset-like-dislike-dialog/reset-like-dislike-dialog.component';
import { AcceptInGroupDialogComponent } from './components/popups/accept-in-group-dialog/accept-in-group-dialog.component';
import { DiscardGroupDialogComponent } from './components/popups/discard-group-dialog/discard-group-dialog.component';
import { ValidateItemDialogComponent } from './components/popups/validate-item-dialog/validate-item-dialog.component';
import { LeaveGroupDialogComponent } from './components/popups/leave-group-dialog/leave-group-dialog.component';

@NgModule({
  declarations: [
    MainMenuComponent,
    BreadcrumbComponent,
    ExperienceComponent,
    NearbyListingComponent,
    SuggestedGroupComponent,
    QrcodeComponent,
    GalleryComponent,
    FooterComponent,
    ExperienceGridComponent,
    FirstMenuComponent,
    SecondMenuComponent,
    MainMenuConnectedComponent,
    DetailsMenuComponent,
    DetailsHeaderComponent,
    DetailsAboutComponent,
    DetailsGalleryComponent,
    DetailsReviewsComponent,
    DetailsWriteReviewComponent,
    DetailsContactCreatorComponent,
    DetailsSuggestionsComponent,
    ProfilePictureComponent,
    NotificationsComponent,
    AccountMenuComponent,
    ChangePasswordComponent,
    GeneralInformationsComponent,
    ManageProfileComponent,
    ImportTreeDObjectDialogComponent,
    VideoUrlDialogComponent,
    ErrorPopupComponent,
    SingleReviewComponent,
    SingleSuggestedExperienceComponent,
    DisplayInformationPageComponent,
    PopupExperienceVideoComponent,
    MarkerNameDialogComponent,
    RightMenuComponent,
    ConfirmDialogComponent,
    MarkerTypeDialogComponent,
    AddEditExperienceFormComponent,
    EditProfileComponent,
    MyExperiencesComponent,
    OwnReviewComponent,
    AddNewElementComponent,
    GroupDetailsHeaderComponent,
    GroupDetailsHeaderComponent,
    GroupDetailsAboutComponent,
    GroupDetailsContactAdminsComponent,
    GroupDetailsGroupsSuggestionsComponent,
    GroupDetailsMembersComponent,
    GroupDetailsMembersComponent,
    GroupDetailsMemberComponent,
    GroupDetailsExperiencesComponent,
    GroupDetailsDeleteGroupComponent,
    AddEditGroupFormComponent,
    FixedMenuOfConnectedUserComponent,
    ExperienceStatusComponent,
    ManageMembersGroupComponent,
    GroupsIManageComponent,
    YesNoDialogComponent,
    SingleGroupOwnerComponent,
    GroupsIAmInComponent,
    JoinFirstGroupComponent,
    CreateFirstGroupComponent,
    GroupGridComponent,
    MatchHeightDirective,
    SuggestedGroupItemComponent,
    AnalyticsChartComponent,
    AnalyticsVisitorsComponent,
    AnalyticsDurationComponent,
    AnalyticsFilterComponent,
    OnClickTypeDialogComponent,
    Import3DFilesComponent,
    RatingComponent,
    MyGroupsSectionComponent,
    EditGroupSectionComponent,
    AddExperienceDialogComponent,
    CreateExperienceDialogComponent,
    LoginDialogComponent,
    ManageExperiencesComponent,
    ManageMembersComponent,
    GroupMembersDialogComponent,
    AddEditTemplateFormComponent,
    FormTemplateComponent,
    ChooseCreateTemplateFormComponent,
    ExperienceTemplateSingleComponent,
    MyTemplatesProfileComponent,
    MyTemplatesSectionComponent,
    CreateTemplateDialogComponent,
    SingleSuggestedTemplateComponent,
    ChooseCreateExperienceFormComponent,
    CreateFromExperienceDialogComponent,
    AssetsListingComponent,
    AssetFormComponent,
    ListingExperienceItemComponent,
    ListingTemplateItemComponent,
    ExperiencesSectionComponent,
    TemplatesSectionComponent,
    GroupsSectionComponent,
    ListingGroupItemComponent,
    ImportAssetDialogComponent,
    MyFavoritesComponent,
    MyTemplatesFavoritesComponent,
    ListingExperienceFavoriteItemComponent,
    ListingTemplateFavoriteItemComponent,
    ListingGroupFavoriteItemComponent,
    MyGroupsFavoritesComponent,
    ModerateComponent,
    RejectExperienceDialogComponent,
    RejectReviewDialogComponent,
    ResetLikeDislikeDialogComponent,
    AcceptInGroupDialogComponent,
    DiscardGroupDialogComponent,
    ValidateItemDialogComponent,
    LeaveGroupDialogComponent
  ],
    imports: [
        AutosizeModule,
        ImageCropperModule,
        DragDropModule,
        RxReactiveFormsModule,
        CommonModule,
        TranslateModule,
        RouterModule,
        FormsModule,
        ColorPickerModule,
        MatExpansionModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
        MatDialogModule,
        NgbModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatButtonModule,
        MatCardModule,
        NgxPaginationModule,
        A11yModule,
        AutocompleteLibModule,
        LineChartModule,
        NgSelectModule,
        MatInputModule,
        ChartsModule,
        MatTabsModule,
        IMaskModule,
        ImageCropperModule,
        NgxJdenticonModule,
        NgxCarouselModule,
        OwlModule,
        FacebookModule,
        ShareButtonModule
    ],
  exports: [
    MainMenuComponent,
    BreadcrumbComponent,
    ExperienceComponent,
    NearbyListingComponent,
    SuggestedGroupComponent,
    QrcodeComponent,
    GalleryComponent,
    FooterComponent,
    ExperienceGridComponent,
    FirstMenuComponent,
    SecondMenuComponent,
    MainMenuConnectedComponent,
    DetailsMenuComponent,
    DetailsHeaderComponent,
    DetailsAboutComponent,
    DetailsGalleryComponent,
    DetailsReviewsComponent,
    DetailsWriteReviewComponent,
    DetailsContactCreatorComponent,
    DetailsSuggestionsComponent,
    ProfilePictureComponent,
    NotificationsComponent,
    AccountMenuComponent,
    ChangePasswordComponent,
    GeneralInformationsComponent,
    ManageProfileComponent,
    SingleSuggestedExperienceComponent,
    DisplayInformationPageComponent,
    PopupExperienceVideoComponent,
    RightMenuComponent,
    ConfirmDialogComponent,
    AddEditExperienceFormComponent,
    EditProfileComponent,
    MyExperiencesComponent,
    SingleReviewComponent,
    OwnReviewComponent,
    GroupDetailsHeaderComponent,
    GroupDetailsAboutComponent,
    GroupDetailsContactAdminsComponent,
    GroupDetailsGroupsSuggestionsComponent,
    GroupDetailsMembersComponent,
    GroupDetailsExperiencesComponent,
    AddNewElementComponent,
    GroupDetailsDeleteGroupComponent,
    AddEditGroupFormComponent,
    FixedMenuOfConnectedUserComponent,
    ExperienceStatusComponent,
    ManageMembersGroupComponent,
    GroupsIManageComponent,
    SingleGroupOwnerComponent,
    GroupsIAmInComponent,
    JoinFirstGroupComponent,
    CreateFirstGroupComponent,
    SuggestedGroupItemComponent,
    AnalyticsChartComponent,
    AnalyticsVisitorsComponent,
    AnalyticsDurationComponent,
    AnalyticsFilterComponent,
    RatingComponent,
    ChooseCreateExperienceFormComponent,
    ListingExperienceItemComponent,
    ListingTemplateItemComponent,
    ExperiencesSectionComponent,
    TemplatesSectionComponent,
    GroupsSectionComponent,
    MyTemplatesFavoritesComponent,
    MyGroupsFavoritesComponent,
    MyFavoritesComponent,
    ModerateComponent
  ]
})
export class ReusablesModule {
}
