<div class="row">
   <h4>{{sectionTitle | translate}}</h4>
   <div class="db-list-com tz-db-table">
     <div class="item-view">
       <a [routerLink]="[experiencesLink]">
         {{'VIEW_MORE' | translate}}
       </a>
     </div>
      <!------------ no published experiences --------->
      <div class="tz-2-com tz-2-main" *ngIf="experiences.length == 0">
        <div class="center no_data_message">
          <div class="icon-content"><i class="fas fa-cubes"></i></div>
          {{"NO_PUBLISEHD_EXPERIENCE_MESSAGE" | translate}}
        </div>
      </div>
      <!------------ end no published experiences  --------->
      <!------------ published experiences  exist --------->
        <!----------------------------  carousel  ------------------------------>
       <owl-carousel *ngIf="experiences.length != 0" [options]="carouselOptions" [carouselClasses]="['owl-theme', 'row', 'sliding']">
         <app-listing-experience-item *ngFor="let item of experiences;let i= index" [data]="item" [homeSection]="'homeSection'">
         </app-listing-experience-item>
        </owl-carousel>
        <!----------------------------  end carousel  ------------------------------>
      <!------------ end published experiences  exist--------->
   </div>
</div>
