<div class="col-md-4" >


  <a *ngIf="disabled !== true" [routerLink]="link" >
    <div class="list-mig-like-com com-mar-bot-30"
         [ngStyle]="{'background': data.coverPicture !== '' ? 'url(' + data.coverPicture + ') center no-repeat' : 'red' }">
      <div class="list-mig-lc-con">
        <app-rating [rating]="data.rating" *ngIf="data.rating > 0"></app-rating>
        <h5 title="{{data.name}}" class="ellipsis-style">{{data.name}}</h5>
        <h6>{{data.createdDate | date}}</h6>
      </div>
    </div>
  </a>




  <a *ngIf="disabled === true && notSelected !== true" >
    <div class="list-mig-like-com com-mar-bot-30 "
         [ngClass]="{'selected-exp': data === selectedData}"
         [ngStyle]="{'background': data.coverPicture !== '' ? 'url(' + data.coverPicture + ') center no-repeat' : 'red' }">
      <div class="list-mig-lc-con">
        <app-rating [rating]="data.rating" *ngIf="data.rating > 0"></app-rating>
        <h5 title="{{data.name}}" class="ellipsis-style">{{data.name}}</h5>
        <h6>{{data.createdDate | date}}</h6>
      </div>
    </div>
  </a>


  <div *ngIf="disabled === true && notSelected === true" >
    <div class="list-mig-like-com com-mar-bot-30 "
         [ngClass]="{'selected-exp': data === selectedData }"
         [ngStyle]="{'background': data.coverPicture !== '' ? 'url(' + data.coverPicture + ') center no-repeat' : 'red' }">
      <div class="list-mig-lc-img" *ngIf="remove" >
        <button (click)="onRemoveExperience(data)" class="home-list-pop-rat list-mi-pr remove-button"><i class="fa fa-remove"></i></button>
      </div>
      <div class="list-mig-lc-img" *ngIf="delete" >
        <button (click)="onDeleteExperience(data)" class="home-list-pop-rat list-mi-pr delete-button"><i class="fa fa-trash"></i></button>
      </div>
      <div class="list-mig-lc-img" *ngIf="cancelDelete" >
        <button (click)="onCancelDeleteExperience(data)" class="home-list-pop-rat list-mi-pr cancel-delete-button"><i class="fa fa-trash"></i></button>
      </div>
      <div class="list-mig-lc-con">
        <app-rating [rating]="data.rating" *ngIf="data.rating > 0"></app-rating>
        <h5 title="{{data.name}}" class="ellipsis-style">{{data.name}}</h5>
        <h6>{{data.createdDate | date}}</h6>
      </div>
    </div>
  </div>

</div>
