
export function addHttp(onClick) {
  let find = null;
  if (onClick) {
     find = onClick.find(x => x.type === 'URL');
  }
  if (find) {
    const position = find.info[0].search('http');
    if (position !== 0) {
      find.info = ['http://' + find.info[0]];
    }
  }
  return onClick;
}

export function round(n: number, x: number){
  return Math.round(n * Math.pow(10, x)) / Math.pow(10, x);
}

