import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  EDIT_PATH,
  EXPERIENCE_PATH,
  MY_TEMPLATES_PATH
} from '../../../utils/actionsLabels/pathLabels';

@Component({
  selector: 'app-fixed-menu-of-connected-user',
  templateUrl: './fixed-menu-of-connected-user.component.html',
  styleUrls: ['./fixed-menu-of-connected-user.component.scss']
})
export class FixedMenuOfConnectedUserComponent implements OnInit {

  @Input() id: string;
  @Input() source: string;
  @Input() displayValidateFixedMenu: boolean;
  @Input() disabledButton: boolean;
  @Output() request = new EventEmitter();
  link: string;
  constructor() { }

  ngOnInit(): void {
    switch (this.source) {
      case 'template':
        this.link = MY_TEMPLATES_PATH + '/' + this.id + EDIT_PATH;
        break;
      case  'experience':
        this.link = EXPERIENCE_PATH + '/' + this.id + EDIT_PATH;
        break;
    }
  }

  validateExperience() {
    this.request.emit();
  }
}
