<div class="tz-2-com tz-2-main">
  <h4>{{'Edit password' | translate }}</h4>
  <div class="db-list-com tz-db-table">
    <form class="s12 ng-pristine ng-valid" [formGroup]="changePasswordForm"
          (ngSubmit)="onSubmit(changePasswordForm.value)">
      <div class="form-group">
        <div class="input-field s12">
          <input
            type="password" class="validate" formControlName="currentPassword" id="currentPassword">
          <label for="currentPassword">{{'CURRENT_PASSWORD' | translate}}</label>
          <div
            *ngIf="changePasswordForm.controls['currentPassword'].invalid && changePasswordForm.controls['currentPassword'].touched"
            class="alert alert-danger">
            {{'Current password is required.' | translate}}
          </div>
        </div>
        <div class="input-field s12">
          <input
            type="password" class="validate" formControlName="password" id="password">
          <label for="password">{{'PASSWORD' | translate}}</label>
          <div
            *ngIf="changePasswordForm.controls['password'].invalid && changePasswordForm.controls['password'].touched"
            class="alert alert-danger">
            {{'Please enter a valid password!' | translate}}
            <div
              *ngIf="changePasswordForm.controls['password'].hasError('minlength')">
              {{'- Must have at least 8 characters!' | translate}} </div>
            <div
              *ngIf="changePasswordForm.controls['password'].hasError('hasNumber')">
              {{'- Must have at least 1 number!' | translate}} </div>
            <div
              *ngIf="changePasswordForm.controls['password'].hasError('hasCapitalCase')">
              {{'- Must contain at least 1 Letter in Capital Case!' | translate}} </div>
            <div
              *ngIf="changePasswordForm.controls['password'].hasError('hasSmallCase')">
              {{'- Must contain at least 1 Letter in Small Case!' | translate}} </div>
            <div
              *ngIf="changePasswordForm.controls['password'].hasError('hasSpecialCharacters')">
              {{'- Must contain at least 1 Special Character!' | translate}} </div>
          </div>
        </div>
        <div class="input-field s12">
          <input
            type="password" class="validate" formControlName="passwordConfirm" id="passwordConfirm">
          <label for="passwordConfirm">{{'CONFIRM_PASSWORD' | translate}}</label>
          <div
            *ngIf="changePasswordForm.controls['passwordConfirm'].invalid
            && changePasswordForm.controls['passwordConfirm'].touched && !formIsNotValid"
            class="alert alert-danger">
            {{'Confirm password is required.' | translate}}
          </div>
          <div *ngIf="formIsNotValid" class="alert alert-danger">
            {{'password not matching' | translate}}
          </div>
        </div>
      </div>
      <div class="actions-container">
        <div class="input-field s4" [ngClass]="{'disabledSubmit': changePasswordForm.invalid}">
          <input type="submit" [disabled]="changePasswordForm.invalid" value="Save"
                 class="waves-effect waves-light btn-large">
        </div>
        <div class="input-field s4">
          <input type="button" value="Cancel" class="waves-effect waves-light btn-large" (click)="resetForm()">
        </div>
      </div>
    </form>
  </div>
</div>
