import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {ErrorManagerService} from '../../reusables/services/error-manager.service';
import {NOT_FOUND_CONTENT} from '../../utils/errorsReactions/errors-texts';
import { NOT_FOUND_PAGE_TITLE} from '../../utils/actionsLabels/labels';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-not-fount',
  templateUrl: './not-fount.component.html',
  styleUrls: ['./not-fount.component.scss']
})
export class NotFountComponent implements OnInit {

  constructor(private titleService: Title,
              private translate: TranslateService,
              private router: Router,
              private errorManagerService: ErrorManagerService) { }
  pageTitle = NOT_FOUND_PAGE_TITLE;
  content = NOT_FOUND_CONTENT;
  ngOnInit(): void {
    window.scrollTo(0, 0);
    const routerValue = this.router.url.substr(1);
    if (this.errorManagerService.getErrorTitle(routerValue)) {
      this.pageTitle = this.errorManagerService.getErrorTitle(routerValue);
      this.content = this.errorManagerService.getErrorContent(routerValue);
    }
    this.translate.get(this.pageTitle).subscribe((res: string) => {
      this.titleService.setTitle(res);
    });
  }

}
