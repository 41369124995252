export const ReviewStatusEnum = [
  {
    label: 'PUBLISHED',
    value: 'PUBLISHED',
  },
  {
    label: 'REJECTED',
    value: 'REJECTED',
  },
  {
    label: 'IN_MODERATION',
    value: 'IN_MODERATION',
  }
];
