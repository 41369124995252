import {Component, OnInit} from '@angular/core';
import {User} from '../../reusables/interfaces/user';
import {Experience} from '../../reusables/interfaces/experience';
import {
  ASK_FOR_CONFIRM_DELETE_EXPERIENCE_ACTION,
  DELETE,
  DELETE_EXPERIENCE_ACTION,
  EDIT_EXPERIENCE_ACTION,
  MY_EXPERIENCES_PAGE_TITLE,
  PREVIEW_EXPERIENCE_ACTION,
  SUCCESS_DELETE_EXPERIENCE_MESSAGE_TITLE
} from '../../utils/actionsLabels/labels';
import {CREATE_EXPERIENCE_PATH, EXPERIENCE_PATH} from '../../utils/actionsLabels/pathLabels';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from '../../reusables/services/users.service';
import {Title} from '@angular/platform-browser';
import {CookieService} from 'ngx-cookie-service';
import {NotificationService} from '../../reusables/services/notification.service';
import {ConfirmDialogService} from '../../reusables/components/confirm-dialog/confirm-dialog.service';
import {ExperiencesService} from '../../reusables/services/experiences.service';
import {HttpParams} from '@angular/common/http';
import {OWNER_EXPERIENCE} from '../../utils/queryParams/experiencesQueryParams';
import {TokenHelperService} from '../../reusables/services/token-helper.service';
import {AuthGuard} from '../../reusables/_helpers';

@Component({
  selector: 'app-profile-my-experiences',
  templateUrl: './profile-my-experiences.component.html',
  styleUrls: ['./profile-my-experiences.component.scss']
})
export class ProfileMyExperiencesComponent implements OnInit {
  deleteText = DELETE;
  createExperience = CREATE_EXPERIENCE_PATH;
  user: User;
  experiences: Experience [];
  doneForUserProfile = false;
  experiencesAreLoaded = false;
  disabledButton = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private usersService: UsersService,
              private experiencesService: ExperiencesService,
              private titleService: Title,
              private cookieService: CookieService,
              private confirmDialogService: ConfirmDialogService,
              private notificationService: NotificationService,
              private tokenHelperService: TokenHelperService,
              private authGuard: AuthGuard) {
  }

  ngOnInit(): void {
    if (this.authGuard.canActivate) {
      this.titleService.setTitle(MY_EXPERIENCES_PAGE_TITLE);
      this.getProfile();
    }
  }

  getProfile() {
    this.usersService.getUserProfile().subscribe((data: User) => {
      this.user = data;
      this.doneForUserProfile = true;
      this.getUserExperiences(this.user.id);
    });
  }

  getUserExperiences(id) {
    // TODO: add endpoint users/{userId}/experiences
    let params = new HttpParams();
    params = params.append(OWNER_EXPERIENCE, id);
    this.experiencesService.getExperiences(params)
      .subscribe((data: Experience[]) => {
        this.experiences = data;
        this.experiencesAreLoaded = true;
        this.disabledButton = false;
      });
  }

  experienceAction(event) {
    if (event && event.data !== '') {
      switch (event.data) {
        case EDIT_EXPERIENCE_ACTION:
          this.openEditExperience(event.id);
          break;
        case DELETE_EXPERIENCE_ACTION:
          this.deleteExperience(event.id, event.name);
          break;
        case PREVIEW_EXPERIENCE_ACTION:
          this.openDetailsExperience(event.id);
          break;
      }
    }
  }

  openEditExperience(id) {
    // TODO: replace this path by const from label.ts
    this.router.navigateByUrl('experiences/' + id + '/edit').then();
  }

  deleteExperience(id, name) {
    this.confirmDialogService.confirmThis(ASK_FOR_CONFIRM_DELETE_EXPERIENCE_ACTION + '"' + name + '"?', () => {
      this.disabledButton = true;
      this.experiencesService.deleteExperience(id).subscribe(() => {
        this.notificationService.showSuccess('', SUCCESS_DELETE_EXPERIENCE_MESSAGE_TITLE);
        this.getUserExperiences(this.user.id);
      });
    }, () => {
    });
  }

  openDetailsExperience(id) {
    this.router.navigateByUrl(EXPERIENCE_PATH + '/' + id).then();
  }
}
