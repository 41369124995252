import {Component, Input, OnInit} from '@angular/core';
import {AnalyticsDuration} from '../../interfaces/analytics-duration';

@Component({
  selector: 'app-analytics-duration',
  templateUrl: './analytics-duration.component.html',
  styleUrls: ['./analytics-duration.component.scss']
})
export class AnalyticsDurationComponent implements OnInit {

  @Input() analyticsDuration: AnalyticsDuration;
  @Input() doneForAnalyticsDuration: boolean;

  constructor() {
  }

  ngOnInit(): void {

  }

}
