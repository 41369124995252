<div class="tz-2">
  <div class="tz-2-com tz-2-main">
    <app-add-edit-group-form (request)="readData($event)" [groupId]="groupId"></app-add-edit-group-form>
  </div>
</div>
<div class="row waiting_load" *ngIf="waitingLoad">
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
</div>
