import * as BABYLON from 'babylonjs';
import {addHttp, round} from '../../../utils/functions/GlobalFunctions';
import {OnClick} from '../../interfaces/on-click';
import {rotationForUnity3DObj} from '../../../utils/functions/RotationFunction';
import {OBJFileLoader} from 'babylonjs-loaders';
import {fromPxToM} from '../../../utils/functions/PositionFunction';
import {v4 as uuidv4} from 'uuid';
import {FOUR_DECIMALS, TOW_DECIMALS} from '../../../utils/global-constants/forModel';
import {getScale, getScaleForEdit} from '../../../utils/functions/sizeFunction';
import {
  DEFAULT_FILE_NAME,
  DEFAULT_IMAGE_URL, DEFAULT_OBJ_3D_URL,
  DEFAULT_OBJ_3D_ZIP_URL
} from "../../../utils/global-constants/editor-const";

export class ThreeDObject {
  public uniqueId: string;
  public urlSource: string; //.obj .glb or .gltf url
  public urlZip ; // zip url
  public fileName: string;
  public name = '3D';
  public type = 'object';
  public visible = true;
  public triggerVisible = true;
  public rotation = {x: 0, y: 0, z: 0};
  public position = {x: 0, y: 20, z: 30};
  public scale = {x: 1, y: 1, z: 1};
  public nbrCopy = 0;
  public onClick: Array<OnClick>; // [{ type: 'URL', info:['https..........']}]


  // recreate=true: si c'est un objet dans content (database)
  constructor(object?, recreate?, name?, position?, urlSource?, urlZip?, fileName?) {
    if (object) { // used on clone or recreate from content
      this.type = 'object';
      this.rotation = {x: object.rotation.x, y: object.rotation.y, z: object.rotation.z};
      this.scale = {x: object.scale.x, y: object.scale.y, z: object.scale.z};
      this.onClick = object.onClick;
      this.urlSource = object.urlSource;
      this.fileName = object.fileName;
      if ( recreate) { // recreate from content
        this.urlZip = object.uri;
        this.name = object.name;
        this.visible = true;
        this.position = {x: object.position.x, y: object.position.y, z: object.position.z};
        this.nbrCopy = 0;
        this.triggerVisible = true;
        this.onClick = object.onClick;
      } else { // used on clone (recreate === null)
        this.urlSource = object.urlSource;
        this.urlZip = object.urlZip;
        this.visible = object.visible;
      }
    }
    if (name) { // create new or clone
      this.name = name;
    }
    if (position) { // create new or clone
      this.position = position;
    }
    if (urlZip) { // create new
      this.urlZip = urlZip;
    }
    if (urlSource) { // create new
      this.urlSource = urlSource;
    }
    if (fileName) {
      this.fileName = fileName;
    }

  }

  configBabylonObjFileLoader() {
    OBJFileLoader.OPTIMIZE_WITH_UV = true;
  }

  addThreeDObjectFromObjScene(scene: BABYLON.Scene, recreate?) {
    let checkFile = false;
    const x = new XMLHttpRequest();
    x.timeout = 15000;
    x.open('GET', this.urlSource);
    x.onreadystatechange = ( event) => {
      if ( checkFile === false ) {
        checkFile = true;
        if ( x.status === 404 ) {
          this.urlSource = DEFAULT_OBJ_3D_URL;
          this.urlZip = DEFAULT_OBJ_3D_ZIP_URL;
          this.scale =  {x: 200, y: 200, z: 200};
          this.fileName = DEFAULT_FILE_NAME;
        }
        let concatMesh = null;
        const index = this.urlSource.lastIndexOf('/');
        this.configBabylonObjFileLoader();
        BABYLON.SceneLoader.ImportMesh('', this.urlSource.substring(0, index + 1),
          this.urlSource.substring(index + 1, this.urlSource.length),
          scene,
          (newMeshes) => {
            if (!newMeshes) {
              throw Error('can not import');
            }
            const concatMeshes = [];
            newMeshes.forEach((mesh) => {
              if (mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind) && mesh.getTotalVertices() > 0){
                concatMeshes.push(mesh as BABYLON.Mesh);
              }
            });
            try {
              concatMesh = BABYLON.Mesh.MergeMeshes(concatMeshes, true, true, undefined, false, true);
              // if size > 50cm
              if (!recreate && x.status !== 404) { this.scale = getScale(concatMesh.geometry.extend.maximum.x, concatMesh.geometry.extend.maximum.y, concatMesh.geometry.extend.maximum.z, this.scale); }
              concatMesh.scaling = new BABYLON.Vector3(this.scale.x, this.scale.y , this.scale.z);
              concatMesh.position = new BABYLON.Vector3(this.position.x, this.position.y , this.position.z );
              concatMesh.rotation = new BABYLON.Vector3(this.rotation.x, this.rotation.y , this.rotation.z );
              concatMesh.renderingGroupId = 2;
              concatMesh.id = '3DObject';
              concatMesh.isVisible = this.visible;
              concatMesh.uniqueId = uuidv4();
              this.uniqueId = concatMesh.uniqueId;
            } catch (e) {
              this.urlSource = 'ERROR';
            }
          });
        return concatMesh;
      }

    };
    x.send();
  }

  recreate(scene) {
    this.addThreeDObjectFromObjScene(scene, true);
  }

  editFile(scene, oldGeometry) {
    let checkFile = false;
    const x = new XMLHttpRequest();
    x.timeout = 15000;
    x.open('GET', this.urlSource);
    x.onreadystatechange = ( event) => {
      if (checkFile === false) {
        checkFile = true;
        if (x.status === 404) {
          this.urlSource = DEFAULT_OBJ_3D_URL;
          this.urlZip = DEFAULT_OBJ_3D_ZIP_URL;
          this.scale = {x: 200, y: 200, z: 200};
          this.fileName = DEFAULT_FILE_NAME;
        }
        let concatMesh = null;
        const index = this.urlSource.lastIndexOf('/');
        this.configBabylonObjFileLoader();
        BABYLON.SceneLoader.ImportMesh('', this.urlSource.substring(0, index + 1),
          this.urlSource.substring(index + 1, this.urlSource.length),
          scene,
          (newMeshes) => {
            if (!newMeshes) {
              throw Error('can not import');
            }
            const concatMeshes = [];
            newMeshes.forEach((mesh) => {
              if (mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind) && mesh.getTotalVertices() > 0) {
                concatMeshes.push(mesh as BABYLON.Mesh);
              }
            });
            try {
              concatMesh = BABYLON.Mesh.MergeMeshes(concatMeshes, true, true, undefined, false, true);
              if (x.status !== 404) {
                this.scale = getScaleForEdit(concatMesh.geometry.extend.maximum, oldGeometry, this.scale);
              }
              concatMesh.scaling = new BABYLON.Vector3(this.scale.x, this.scale.y, this.scale.z);
              concatMesh.position = new BABYLON.Vector3(this.position.x, this.position.y, this.position.z);
              concatMesh.rotation = new BABYLON.Vector3(this.rotation.x, this.rotation.y, this.rotation.z);

              concatMesh.renderingGroupId = 2;
              concatMesh.id = '3DObject';
              concatMesh.isVisible = this.visible;
              concatMesh.uniqueId = this.uniqueId;
            } catch (e) {
              this.urlSource = 'ERROR';
            }
          });
        return concatMesh;
      }
    };
    x.send();
  }

  outputJson(trigger) {
    // round function : pour limiter le nombre des chiffres après la virgule
    return {
      id: this.uniqueId,
      type: '3dmodel',
      name: this.name,
      uri: this.urlZip,
      fileName: this.fileName,
      urlSource: this.urlSource,
      visibility: this.visible,
      position: {
        x: round(fromPxToM(this.position.x - trigger.position.x), FOUR_DECIMALS),
        y: round(fromPxToM(this.position.y - trigger.position.y), FOUR_DECIMALS),
        z: round(fromPxToM(this.position.z - trigger.position.z), FOUR_DECIMALS),
      },
      rotation: {
        x: round(rotationForUnity3DObj(this.rotation).x, TOW_DECIMALS),
        y: round(rotationForUnity3DObj(this.rotation).y, TOW_DECIMALS),
        z: round(rotationForUnity3DObj(this.rotation).z, TOW_DECIMALS),
      },
      scale: {
        x: round(this.scale.x, TOW_DECIMALS),
        y: round(this.scale.y, TOW_DECIMALS),
        z: round(this.scale.z, TOW_DECIMALS),
      },
      onClick: addHttp(this.onClick)
    };
  }
}

