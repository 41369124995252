<div class="tz-2-com tz-2-main">
  <h4>{{section_title | translate }}</h4>
  <div class="db-list-com tz-db-table">
    <div class="manage-profile-content">
      <div class="info-item" *ngFor="let item of data">
        <div class="info-label">{{item.label | translate }} :</div>
        <div class="info-content">{{item.value}}</div>
      </div>
    </div>

    <div class="db-mak-pay-bot">
      <a (click)="sendAction()"
         class="waves-effect waves-light btn-large">{{edit_password_label | translate }}</a>
      <a (click)="editProfile()"
         class="waves-effect waves-light btn-large">{{edit_profile_label | translate }}</a>
    </div>
  </div>
</div>
