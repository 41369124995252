import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Experience} from '../../interfaces/experience';
import {EXPERIENCE_PATH} from '../../../utils/actionsLabels/pathLabels';

@Component({
  selector: 'app-experience-grid',
  templateUrl: './experience-grid.component.html',
  styleUrls: ['./experience-grid.component.scss']
})
export class ExperienceGridComponent implements OnInit {

  constructor() { }
  link;
  experienceLink = EXPERIENCE_PATH;


  @Input() data: Experience; // experience details
  @Input() selectedData: Experience; // l'experience sélectionnée (changement de style)
  @Input() disabled: boolean; // on peut cliquer sur l'experience :  redirection vers experience details (false) ou sans redirection(true)
  @Input() notSelected: boolean; // on ne peut pas(true) ou on peut(false) cliquer sur l'experience

  @Input() remove: boolean; // on peut supprimer l'experience de la liste // = show remove icon
  @Output() onRemove = new EventEmitter(); // on peut supprimer l'experience de la liste

  @Input() delete: boolean; // on peut supprimer l'experience du group // = show trash icon
  @Output() onDelete = new EventEmitter(); // on peut supprimer l'experience du group

  @Input() cancelDelete: boolean; // l'experience est seléctionnée pour qu'elle soit supprimer du group
  @Output() onCancelDelete = new EventEmitter(); // annuler la suppression de l'experience du group

  ngOnInit(): void {
    this.link =  '/' + this.experienceLink  + '/' + this.data.id;
  }

  onRemoveExperience(data: Experience) {
    this.onRemove.emit(data);
  }
  onDeleteExperience(data: Experience) {
    this.onDelete.emit(data);
  }
  onCancelDeleteExperience(data: Experience) {
    this.onCancelDelete.emit(data);
  }

}
