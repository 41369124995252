<div class="pglist-p3 pglist-bg pglist-p-com" id="ld-rew">
  <div class="pglist-p-com-ti">
    <h3><span>{{"Write Your" | translate}}</span> {{"Review" | translate}}</h3></div>
  <div class="list-pg-inn-sp">
    <div class="list-pg-write-rev">
      <form #reviewForm="ngForm" name="form" (ngSubmit)="f.form.valid && onSubmit(reviewForm)"
            #f="ngForm">
        <p>{{"INVITE_TO_WRITE_REVIEW" | translate}}</p>
        <div class="row" *ngIf="connectedUser">
          <div class=" col s12">
            <div class="avatar">
              <img *ngIf="connectedUser.profilePicture && connectedUser.profilePicture !== ''" src="{{connectedUser.profilePicture}}"/>
              <div *ngIf="!connectedUser.profilePicture && connectedUser.profilePicture == ''"> </div>
            </div>
            <div class="connectedUser">
              {{connectedUser.displayName}}
            </div>
          </div>
        </div>
        <div class="row" *ngIf="connectedUser">
          <div class="input-field col s12">
            <textarea id="re_msg" class="materialize-textarea" style="line-height:1.5em" (keyup)="substrName(reviewForm)"
                      [(ngModel)]="model.review" name="review" #review="ngModel" maxlength="3000" autosize></textarea>
            <label *ngIf="typ=='add_review'" for="re_msg" >{{'Write Review' | translate}}
              <span style="color:red;font-weight:bold;font-size:11px"> (*) </span></label>
            <label *ngIf="typ=='update_review'" for="re_msg" ></label>
            <span class="v2-db-note" *ngIf="model.review.length >= 3000">{{"review_info" | translate}}</span>
          </div>
        </div>
        <div class="row" *ngIf="connectedUser">
          <div class="col s12">
            <fieldset class="rating" *ngFor="let i of arrayOne(5); let j = index;">
              <input type="radio" id="star{{j+1}}" name="rating" value="{{j+1}}"
                     (click)="ratingValueChange(j+1)"/>
              <label id="labelStar{{j+1}}" for="star{{j+1}}" [title]="ratingMsg[j]"
                     (mouseover)="initialColor(false, j)" (mouseleave)="initialColor(true, j)"
                     [ngClass]="(model.id !='' && model.rating > j)?'full coloring-star-clicked':'full'"></label>
            </fieldset>
            <br><span style="color:red;font-weight:bold;font-size:11px"> (*) </span>
          </div>
        </div>
        <div class="row">
          <div class="input-field col s12 buttons-display">
            <button type="submit" class="waves-effect waves-light btn-large full-btn" *ngIf="connectedUser && typ === 'add_review'"
                    [disabled]="(typ !== 'add_review' || ratingValue === 0 ||  model.review === '' ) &&
                    (typ !== 'update_review' ||  (model.review === singleReviewReview && ratingValue === singleReviewRating) ||  model.review === '')">
              {{'Submit Review' | translate}}
            </button>
            <button type="submit" class="col-md-6 waves-effect waves-light btn-large full-btn-submit" *ngIf="connectedUser && typ === 'update_review'"
                    [disabled]="(typ !== 'add_review' || ratingValue === 0 ||  model.review === '' ) &&
                    (typ !== 'update_review' ||  (model.review === singleReviewReview && ratingValue === singleReviewRating) ||  model.review === '')">
              {{'Submit Review' | translate}}
            </button>
            <button  class="col-md-6 waves-effect waves-light btn-large  full-btn-cancel"   *ngIf="connectedUser && typ === 'update_review'" (click)="cancelEditReview()">
              <div class="full-btn-cancel-text">{{'Cancel' | translate}}</div>
            </button>
            <a [routerLink]="[login]"  class="waves-effect waves-light btn-large full-btn" *ngIf="!connectedUser">
              {{'login and add' | translate}}
            </a>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
