<script src="app.module.ts"></script>
<body>
  <app-main-menu *ngIf="!isLogged && isNotInResetPassword()"></app-main-menu>
  <app-main-menu-connected [isEditor]="false"
                           *ngIf="isLogged && isNotInEditor()"></app-main-menu-connected>
  <div class="app-content">
    <router-outlet></router-outlet>
  </div>
  <app-footer *ngIf="isNotInEditor() && isNotInResetPassword()"></app-footer>
</body>
