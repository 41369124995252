import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {ExperiencesService} from '../../reusables/services/experiences.service';
import {GroupsService} from '../../reusables/services/groups.service';
import {
  COMING_SOON,
  EXPERIENCES_PAGE_TITLE,
  EXPERIENCES_SECTION_TITLE,
  SUCCESS_JOIN_REQUEST_SENT_MESSAGE,
  SUCCESS_JOIN_REQUEST_SENT_TITLE
} from '../../utils/actionsLabels/labels';
import {LOGIN_PATH, SUGGESTED_GROUPS_PATH} from '../../utils/actionsLabels/pathLabels';
import {Title} from '@angular/platform-browser';
import {HttpParams} from '@angular/common/http';
import {LIMIT_FILTER, RANDOM_FILTER, SORT_FILTER} from '../../utils/queryParams/groupsQueryParams';
import {NotificationService} from '../../reusables/services/notification.service';
import {Experience} from '../../reusables/interfaces/experience';
import {Group} from '../../reusables/interfaces/group';
import {TokenHelperService} from '../../reusables/services/token-helper.service';
import {Router} from '@angular/router';
import {LoginDialogComponent} from '../../reusables/components/popups/login-dialog/login-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-experiences',
  templateUrl: './experiences.component.html',
  styleUrls: ['./experiences.component.scss']
})
export class ExperiencesComponent implements OnInit, OnDestroy, OnChanges {

  experiences: Experience [];
  groups: Group [];
  p = 1; // initial page in pagination of experiences
  g = 1; // initial page in pagination of groups
  doneForExperiences = false;
  doneForGroups = false;
  sectionTitle = EXPERIENCES_SECTION_TITLE;
  suggestedGroupsLink = SUGGESTED_GROUPS_PATH;
  isLogged;
  userId;
  dialogRef;
  destroyComponent = false;

  constructor(private experiencesService: ExperiencesService,
              private groupsService: GroupsService,
              private notificationService: NotificationService,
              private nameService: Title,
              private tokenHelperService: TokenHelperService,
              private translate: TranslateService,
              private router: Router,
              public dialog: MatDialog) {
  }
  ngOnDestroy(): void {
    this.destroyComponent = true;
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
  ngOnChanges() {
  }

  ngOnInit(): void {
    this.isLogged = this.tokenHelperService.getConnectedUser();
    if (this.isLogged) {
      this.userId = this.tokenHelperService.getTokenData().user_id;
    }
    window.scrollTo(0, 0);
    // this.nameService.setTitle(EXPERIENCES_PAGE_TITLE);
    this.translate.get(EXPERIENCES_PAGE_TITLE).subscribe((res: string) => {
      this.nameService.setTitle(res);
    });
    // get public and published experiences
    this.getExperiences();
    // get 5 groups in a random order
    this.getGroups();
  }
  getExperiences() {
    const params = new HttpParams();
    this.experiencesService.getExperiences(params).subscribe((data: Experience[]) => {
      this.experiences = data;
      this.doneForExperiences = true;
    });
  }
  getGroups() {
    let paramsGroups = new HttpParams();
    paramsGroups = paramsGroups.append(LIMIT_FILTER, '5');
    paramsGroups = paramsGroups.append(SORT_FILTER, RANDOM_FILTER);
    this.groupsService.getGroups(paramsGroups).subscribe((data: Group[]) => {
      this.groups = data;
      this.doneForGroups = true;
    });
    window.scrollTo(0, 0);
  }

  onItemGroupClick(event) {
    if ( this.isLogged) {
      this.joinGroup(event.output);
    } else {this.openLoginPopup(); }
  }

  showNotification() {
    this.notificationService.showWarning(COMING_SOON, '');
  }
  openLoginPopup() {
    if (this.destroyComponent !== true) { // prevent popup from appearing on other pages
      this.dialogRef = this.dialog.open(LoginDialogComponent, {
        disableClose: false,
        autoFocus: true,
        panelClass: 'my-dialog',
        width: '800px',
        maxHeight: '500px',
      });
      this.dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.router.navigate([LOGIN_PATH], {queryParams: {returnUrl: this.router.url}}).then(); // queryParams pour stocker l'ancien url
        }
      });
    }
  }

  joinGroup(id) {
    this.groupsService.askJoinGroup(id).subscribe(() => {
      this.notificationService.showSuccess(SUCCESS_JOIN_REQUEST_SENT_MESSAGE, SUCCESS_JOIN_REQUEST_SENT_TITLE );
      this.getGroups();
      });
  }
}
