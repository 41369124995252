import {Component, OnInit} from '@angular/core';
import {
  ADD_MEMBERS_TO_GROUP_EVENT,
  CREATE_GROUP_PAGE_TITLE,
  PAGE_TITLE_PREFIX,
  SUCCESS_ADD_GROUP_MESSAGE_TITLE,
  SUCCESS_INVITE_MEMBERS_TO_GROUP_MESSAGE_TITLE
} from '../../utils/actionsLabels/labels';
import {Router} from '@angular/router';
import {User} from '../../reusables/interfaces/user';
import {UsersService} from '../../reusables/services/users.service';
import {Title} from '@angular/platform-browser';
import {Group} from '../../reusables/interfaces/group';
import {NotificationService} from '../../reusables/services/notification.service';
import {GroupsService} from '../../reusables/services/groups.service';
import {HttpParams} from '@angular/common/http';
import {AuthGuard} from '../../reusables/_helpers';
import {IS_MEMBERS} from '../../utils/queryParams/groupsQueryParams';
import {UserNotMember} from '../../reusables/interfaces/user-not-member';

@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss',
    '../../../assets/customCss/waiting-load.scss']
})
export class FormGroupComponent implements OnInit {

  doneForUserProfile = false;
  user: User;
  userId: string;
  group: Group;
  showMembers = false;
  showUsers = false;
  users: UserNotMember[] = [];
  createdGroupId: string;
  groupName = '';
  waitingLoad = false;

  constructor(
    private router: Router,
    private usersService: UsersService,
    private titleService: Title,
    private notificationService: NotificationService,
    private authGuard: AuthGuard,
    private groupsService: GroupsService) {
  }

  ngOnInit() {
    this.titleService.setTitle(PAGE_TITLE_PREFIX + CREATE_GROUP_PAGE_TITLE);
    if (this.authGuard.canActivate) {
      this.getProfile();
    }
  }

  getProfile() {
    this.usersService.getUserProfile().subscribe((data: User) => {
      this.user = data;
      this.doneForUserProfile = true;
    });
  }

  getUsersNotMembers() {
    let params = new HttpParams();
    params = params.append(IS_MEMBERS, '0');
    this.groupsService.getGroupMembers(this.group.id, params).subscribe((data: any) => {
      this.users = data.list_members;
      this.showUsers = true;
    });
  }

  readData(event) {
    this.waitingLoad = true;
    this.sendAddRequest(event);
  }

  createFormData(event) {
    const formData = new FormData();
    // send data
    formData.append('name', event.data.name);
    formData.append('description', event.data.description);
    formData.append('type', event.data.privacy);
    formData.append('avatarDisplayName', event.data.iconPictureDisplayName);
    formData.append('coverPictureDisplayName', event.data.coverPictureDisplayName);
    // send file
    if (event.coverPicture) {
      formData.append('coverPicture', event.coverPicture);
    }
    if (event.avatar) {
      formData.append('avatar', event.avatar);
    }
    return formData;
  }

  sendAddRequest(event) {
    const requestAdd = this.createFormData(event);
    this.groupsService.addGroup(requestAdd).subscribe((data: Group) => {
      this.waitingLoad = false;
      this.createdGroupId = data.id;
      this.groupName = data.name;
      this.group = data;
      this.notificationService.showSuccess('', SUCCESS_ADD_GROUP_MESSAGE_TITLE);
      this.showMembers = true;
      this.getUsersNotMembers();
    });
  }

  addMembersEvent(event) {
    this.waitingLoad = true;
    if (event && event.action) {
      switch (event.action) {
        case ADD_MEMBERS_TO_GROUP_EVENT:
          this.addMembers(event.data);
          break;
      }
    }
  }

  addMembers(membersList) {
    this.groupsService.inviteGroupMembers(membersList, this.group.id).subscribe(() => {
      this.waitingLoad = false;
      this.notificationService.showSuccess('', SUCCESS_INVITE_MEMBERS_TO_GROUP_MESSAGE_TITLE);
      this.router.navigateByUrl('/groups/' + this.group.id).then();
    });
  }

  requestSearchingMembers(event) {
    let membersList = '';
    if (event.added && event.added.length > 0) {
      membersList = event.added.toString();
    }
    this.groupsService.searchMembersLike(event.term, membersList).subscribe((data: UserNotMember []) => {
      this.users = data;
    }, () => {
    });
  }
}
