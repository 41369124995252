<section *ngIf="doneForUserProfile">
  <div class="tz">
    <div class="tz-l">
      <app-profile-picture [user]="user"></app-profile-picture>
      <app-account-menu [user]="user"></app-account-menu>
    </div>
    <div class="tz-2">
      <div class="tz-2-com tz-2-main">
        <h4>{{"Analytics" | translate}}</h4>
        <div class="db-list-com tz-db-table">

          <div class="filter" *ngIf="doneForExperiences">
            <app-analytics-filter [experiences]="experiences" [initialSelection]="initialSelection"
                                  (selectionChanged)="onSelectionChanged($event)"></app-analytics-filter>
          </div>

          <div class="top">
            <app-analytics-visitors [analyticsVisitors]="analyticsVisitors" [doneForAnalyticsVisitors]="doneForAnalyticsVisitors" class="top-left"></app-analytics-visitors>
            <app-analytics-duration [analyticsDuration]="analyticsDuration" [doneForAnalyticsDuration]="doneForAnalyticsDuration" class="top-right"></app-analytics-duration>
          </div>
          <div class="bottom">
            <app-analytics-chart [analyticsChart]="analyticsChart" [doneForAnalyticsChart]="doneForAnalyticsChart" (chartDurationChangedEvent)="updateChartData($event)"></app-analytics-chart>
          </div>
        </div>
      </div>
    </div>
<!--    <app-notifications></app-notifications>-->
  </div>
</section>


