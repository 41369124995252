import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {SIGNUP_PATH} from '../../../../utils/actionsLabels/pathLabels';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit {
  signUpLink = SIGNUP_PATH;
  constructor(public dialogRef: MatDialogRef<any>) { }

  ngOnInit(): void {
  }

  onLogin() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close();
  }
}
