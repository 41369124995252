import {Component, Input, OnInit} from '@angular/core';
import {COMING_SOON} from '../../../utils/actionsLabels/labels';
import {EXPERIENCE_PATH} from '../../../utils/actionsLabels/pathLabels';
import {Experience} from '../../interfaces/experience';
import {Router} from '@angular/router';
import {NotificationService} from '../../services/notification.service';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss']
})
export class ExperienceComponent implements OnInit {
  constructor(private router: Router,
              private notificationService: NotificationService) {
  }

  @Input() data: Experience;
  linkDetails;
  experienceDetails = EXPERIENCE_PATH;

  ngOnInit(): void {
    this.linkDetails = EXPERIENCE_PATH + '/' + this.data.id;

  }
  comingSoonAction() {
    this.notificationService.showWarning('', COMING_SOON);
  }
}
