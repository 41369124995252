<div class="pglist-p1 pglist-bg pglist-p-com" id="ld-abour">
  <div class="pglist-p-com-ti">
    <h3><span>{{section.label | translate}}</span>
      <span *ngIf="section.membersNbr" class ="section-number"> {{membersNbr}}</span></h3>
  </div>
  <div class="list-pg-inn-sp" *ngIf="section.membersList">
    <section class="p-about com-padd">
      <div>
        <div class="row" *ngIf="members.length <= itemsPerPage">
          <div class="pg-cus-rev ">

            <app-group-details-member
              *ngFor="let item of members"
              [member]="item"></app-group-details-member>
          </div>
        </div>
        <div class="row"  id="getHeight" *ngIf="members.length > itemsPerPage">
          <div class="pg-cus-rev members-group-container" >
            <app-group-details-member
              *ngFor="let item of members | paginate: { itemsPerPage: itemsPerPage, currentPage: g , id: 'pagesMembers'}"
              [member]="item"></app-group-details-member>
          </div>
        </div>
        <a (click)="onClick()" [class.isDisabled]="buttonsDisabled"><span class="span-link">{{'VIEW_MORE' | translate}}</span></a>
      </div>
    </section>
  </div>
</div>

