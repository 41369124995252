import {Component, OnInit} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {FormBuilder, Validators} from '@angular/forms';
import {CookieService} from 'ngx-cookie-service';
import {UsersService} from '../../reusables/services/users.service';
import {ActivatedRoute, Router} from '@angular/router';
import {
  ACCOUNT_NOT_VERIFIED,
  ACCOUNT_NOT_VERIFIED_MSG,
  COMING_SOON,
  ERROR_TEXT,
  FAILED_LOGIN_MESSAGE,
  SIGNIN_PAGE_TITLE
} from '../../utils/actionsLabels/labels';
import {FORGOT_PASSWORD_PATH, SIGNUP_PATH} from '../../utils/actionsLabels/pathLabels';
import {NotificationService} from '../../reusables/services/notification.service';
import {AuthenticationService} from '../../reusables/services/authentication.service';
import {Title} from '@angular/platform-browser';
import {emailForm} from '../../utils/global-constants/regExp';
import {auth} from 'firebase/app';
import 'firebase/auth';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm;
  signUpLink;
  forgotPasswordLink;
  isSubmitted = false;
  returnUrl: string;

  constructor(private formBuilder: FormBuilder,
              private fireAuth: AngularFireAuth,
              private  usersService: UsersService,
              private router: Router,
              private route: ActivatedRoute,
              private cookieService: CookieService,
              private notificationService: NotificationService,
              private translate: TranslateService,
              private authenticationService: AuthenticationService,
              private titleService: Title) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required,
        Validators.pattern(emailForm)]],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    // this.titleService.setTitle(SIGNIN_PAGE_TITLE);
    this.translate.get(SIGNIN_PAGE_TITLE).subscribe((res: string) => {
      this.titleService.setTitle(res);
    });
    this.forgotPasswordLink = FORGOT_PASSWORD_PATH;
    this.signUpLink = SIGNUP_PATH;
    window.scrollTo(0, 0);
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  onLoginWithFirebase(loginData) {
    const {email, password} = loginData;
    this.fireAuth.signInWithEmailAndPassword(email, password).then(() => {
      auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
        if (!auth().currentUser.emailVerified) {
          this.notificationService.showError(ACCOUNT_NOT_VERIFIED_MSG, ACCOUNT_NOT_VERIFIED);
        } else {
          this.authenticationService.login(idToken);
          setTimeout(() => {
            // login successful so redirect to return url
            this.router.navigateByUrl(this.returnUrl).then();
            this.usersService.changeNavBar(true);
          }, 300);
        }
      }).catch(() => {
        this.notificationService.showError(FAILED_LOGIN_MESSAGE, ERROR_TEXT);
      });
    }).catch(() => {
      this.notificationService.showError(FAILED_LOGIN_MESSAGE, ERROR_TEXT);
    });
  }

  showMessage() {
    this.notificationService.showWarning(COMING_SOON, '');
  }
}
