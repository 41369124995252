import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SecondMenuComponent} from '../../editor-menu/second-menu/second-menu.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {youtubeVideoForm} from '../../../../utils/global-constants/regExp';
import {VALID_VIDEO_LINK, VIDEO_LINK} from '../../../../utils/actionsLabels/labels';

class DialogData {
}

@Component({
  selector: 'app-video-url-dialog',
  templateUrl: './video-url-dialog.component.html',
  styleUrls: ['./video-url-dialog.component.scss']
})
export class VideoUrlDialogComponent implements OnInit {
  url = '';
  form: FormGroup;
  VALID_VIDEO_LINK = VALID_VIDEO_LINK ;
  VIDEO_LINK = VIDEO_LINK;
  constructor(
    public dialogRef: MatDialogRef<SecondMenuComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(source): void {
    this.dialogRef.close({action: source, url: this.form.controls.url.value});
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      url : new FormControl(this.url, [Validators.pattern(youtubeVideoForm), Validators.required] )
    });
  }

}
