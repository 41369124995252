<div class="home-list-pop list-spac">
  <div class="col-md-3 list-ser-img">
    <img *ngIf="data.coverPicture && data.coverPicture != ''" src="{{data.coverPicture}}" alt="{{data.name}}">
  </div>
  <div class="col-md-9 home-list-pop-desc inn-list-pop-desc">
    <a [routerLink]="[linkDetails]" title="{{data.name}}" class="experience_name">
      <h3 class="ellipsis-style">{{data.name}}</h3></a>
    <p class="ellipsis-style-description">{{data.description}}</p>
    <div class="list-enqu-btn">
      <br/>
      <ul>
        <li>
          <a (click)="comingSoonAction()">
            <i class="fa fa-commenting-o" aria-hidden="true"></i> {{'CONTACT-CREATOR' | translate}}
          </a>
        </li>
        <li>
          <a [routerLink]="[linkDetails]" >
            <i class="fa fa-play" aria-hidden="true"></i>{{'SEE-EXPERIENCE-DETAILS' | translate}}
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
