import {Component, OnInit} from '@angular/core';
import {COMING_SOON} from '../../../utils/actionsLabels/labels';
import {SUGGESTED_GROUPS_PATH} from '../../../utils/actionsLabels/pathLabels';

import {NotificationService} from '../../services/notification.service';

@Component({
  selector: 'app-join-first-group',
  templateUrl: './join-first-group.component.html',
  styleUrls: ['./join-first-group.component.scss']
})
export class JoinFirstGroupComponent implements OnInit {
  suggestedGroupsPath = SUGGESTED_GROUPS_PATH;

  constructor(private notificationService: NotificationService) { }

  ngOnInit(): void {
  }

  showNotification() {
    this.notificationService.showWarning(COMING_SOON, '');
  }

}
