import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {map} from 'rxjs/operators';
import {
  ASK_FOR_CONFIRM_DELETE_ACTION,
  PAGE_TITLE_PREFIX,
  SCROLL_TO_ABOUT,
  SCROLL_TO_GALLERY,
  SCROLL_TO_MY_REVIEW,
  SCROLL_TO_OWN_REVIEW,
  SCROLL_TO_USER_REVIEW,
  SCROLL_TO_WRITE_REVIEW,
  SUCCESS_ADD_REVIEW_MESSAGE_CONTENT,
  SUCCESS_ADD_REVIEW_MESSAGE_TITLE,
  SUCCESS_DELETE_REVIEW_MESSAGE_TITLE,
  SUCCESS_EDIT_REVIEW_MESSAGE_CONTENT,
  SUCCESS_EDIT_REVIEW_MESSAGE_TITLE,
  WRITE_EXPERIENCE_FAVORITE_ACTION,
  WRITE_REVIEW_ADD,
  WRITE_REVIEW_DELETE,
  WRITE_REVIEW_EDIT,
  WRITE_REVIEW_UPDATE,
  WRITE_LIKE_DISLIKE_EDIT,
  WRITE_LIKE_ACTION,
  WRITE_DISLIKE_ACTION,
  SUCCESS_VALIDATE_EXPERIENCE_MESSAGE_TITLE,
  SUCCESS_ADMIN_EDIT_REVIEW_MESSAGE_TITLE, SUCCESS_ADMIN_ADD_REVIEW_MESSAGE_TITLE, DELETE
} from '../../utils/actionsLabels/labels';
import {ExperiencesService} from '../../reusables/services/experiences.service';
import {Experience, Gallery} from '../../reusables/interfaces/experience';
import {Title} from '@angular/platform-browser';
import {Creator, User} from '../../reusables/interfaces/user';
import {UsersService} from '../../reusables/services/users.service';
import {ExperienceVideo} from '../../reusables/interfaces/experience-video';
import {Review} from '../../reusables/interfaces/review';
import {NotificationService} from '../../reusables/services/notification.service';
import {menuAfterAddingReview, menuBeforeAddingReview, menuOfExperienceOwner, menuReview} from '../../utils/jsonData/detailsExperienceMenu';
import {ReviewFormData} from '../../reusables/interfaces/review-form-data';
import {ConfirmDialogService} from '../../reusables/components/confirm-dialog/confirm-dialog.service';
import {HttpParams} from '@angular/common/http';
import {
  CURRENT_EXPERIENCE_ID, FULL_RESPONSE,
  LIMIT_FILTER,
  RANDOM_FILTER,
  SORT_FILTER,
  USER_ID_IS,
  USER_IS_NOT
} from '../../utils/queryParams/experiencesQueryParams';
import {TokenHelperService} from '../../reusables/services/token-helper.service';
import {LikeDislikeReview} from '../../reusables/interfaces/like-dislike';
import {ExperienceFavorite} from "../../reusables/interfaces/ExperienceFavorite";
import {LikeDislike} from "../../utils/global-constants/Like-dislike-const";
import {StatusExperience} from "../../utils/global-constants/experiences-const";
import {userRole} from "../../utils/global-constants/user-const";
import {experienceStatus} from "../../utils/global-constants/experienceStatusColors";
import {MatDialog} from "@angular/material/dialog";
import {ValidateItemDialogComponent} from "../../reusables/components/popups/validate-item-dialog/validate-item-dialog.component";


@Component({
  selector: 'app-details-experience',
  templateUrl: './details-experience.component.html',
  styleUrls: ['./details-experience.component.scss',
    '../../../assets/customCss/waiting-load.scss']
})
export class DetailsExperienceComponent implements OnInit {

  menu = menuReview; // inner menu
  reviewsAreLoaded = false;
  id: string; // current experience id
  experience: Experience;
  suggestedExperiences: Experience[];
  experienceCreator: Creator;
  connectedUser: User;
  myReview: any;
  singleReview: ReviewFormData;
  rating: number;
  doneDetails = false;
  halfPosition;
  valueToDisplay;
  isHalfExists = false;
  emptyStars;
  coverExperience = '#41809b';
  gallery: Array<Gallery>;
  video: string;
  reviews: Review[] = [];
  videoStatus = false;
  videoData: ExperienceVideo;
  displayForm = false; // if connected user added a review, he can't show form
  displayMenu = false; // if connected user added a review, he can't write review in menu
  ownReview = false;
  activeSubMenu = SCROLL_TO_ABOUT;
  reviewsCounter = 0;
  displayEditFixedMenu = false;
  displayValidateFixedMenu = false;
  userId: string;
  waitingLoad = false;
  action: string;
  likeDislike: LikeDislikeReview;
  likes = 0;
  dislikes = 0;
  LikeMap = new Map<String, number>();
  DislikeMap = new Map<String, number>();
  etatLike =new Map<String, boolean>();
  etatDislike =new Map<String, boolean>();
  etatExperienceFavorite =new Map<String, boolean>();
  experienceFavorite: ExperienceFavorite;
  experienceFavoriteExist = false;
  doneForExpFavorite = false;
  disabledButton = false;
  disabledButtonFavorite = false;
  dialogRef;
  destroyComponent = false;
  deleteText = DELETE;

  constructor(private route: ActivatedRoute,
              private experiencesService: ExperiencesService,
              private usersService: UsersService,
              private titleService: Title,
              private notificationService: NotificationService,
              private confirmDialogService: ConfirmDialogService,
              private tokenHelperService: TokenHelperService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.route.paramMap.pipe(
      map(params => {
          this.initValues();
          this.id = params.get('id');
          this.getExperience();
        }
      )
    ).subscribe(() => {
    });
  }
  ngOnDestroy(): void {
    this.destroyComponent = true;
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  getProfile() {
    this.usersService.getUserProfile().subscribe((data: User) => {
      this.connectedUser = data;
      this.userId = data.id;
      if (this.connectedUser.id === this.experience.creator.userId) {
        this.displayEditFixedMenu = true;
      }
      if ((this.connectedUser.role === userRole.ADMIN.valueOf() || this.connectedUser.role === userRole.SUPER_ADMIN.valueOf())
        && this.experience.status === StatusExperience.IN_MODERATION.valueOf()) {
        this.displayValidateFixedMenu = true;
      }
      this.manageReviews();
    });
  }

  initValues() {
    this.doneDetails = false;
    this.isHalfExists = false;
    this.displayForm = false;
    this.ownReview = false;
    this.displayMenu = false;
    this.reviews = [];
    this.reviewsCounter = 0;
    this.menu = menuReview;
    this.activeSubMenu = SCROLL_TO_ABOUT;
    this.reviewsAreLoaded = false;
    this.displayEditFixedMenu = false;
    this.displayValidateFixedMenu = false;
  }

  scrollTo(event) {
    if (event.action && event.action === 'scroll') {
      this.scrollAction(event.target);
    }
  }

  scrollAction(el) {
    const item = document.getElementById(el);
    if (item) {
      window.scrollTo({top: item.offsetTop + 230, behavior: 'smooth'});
    }
  }


  getReviewOfConnectedUser() {
    let params = new HttpParams();
    params = params.append(USER_ID_IS, this.connectedUser.id);
    this.experiencesService.getReviewsOfExperience(this.id, params).subscribe((data: Review) => {
      this.myReview = data;
      // this.manageSections(SCROLL_TO_MY_REVIEW);
      let reviewAction: string;
      if (this.myReview && this.myReview[0] && this.myReview[0].id !== '') {
        this.waitingLoad = false;
        this.reviewsCounter = 1;
        reviewAction = SCROLL_TO_MY_REVIEW;
        //get likes and dislikes
        this.LikeMap.set(this.myReview[0].id,this.myReview[0].number_likes);
        this.DislikeMap.set(this.myReview[0].id, this.myReview[0].number_dislikes);

        this.etatDislike.set(this.myReview[0].id,false);
        this.etatLike.set(this.myReview[0].id,false);
        //end get likes and dislikes
      } else {
        reviewAction = SCROLL_TO_WRITE_REVIEW;
      }
      this.getReviewsOfUsersNotConnected();
      this.manageSections(reviewAction);
    });
  }

  getExperience() {
    window.scrollTo(0, 0);
    this.experiencesService.getDetailExperiences(this.id).subscribe((data: Experience) => {
      this.experience = data;
      this.titleService.setTitle(PAGE_TITLE_PREFIX + this.experience.name);
      if (this.tokenHelperService.getConnectedUser()) {
        this.getProfile();
      } else {
        this.manageReviews();
      }
      this.experienceCreator = this.experience.creator;

      this.getSuggestedExperiences(this.experience.id);
      this.gallery = this.experience.gallery;
      this.video = this.experience.video;
      if (this.experience.coverPicture !== '') {
        this.coverExperience = 'url(' + this.experience.coverPicture + ') no-repeat center bottom';
      } else {
        this.coverExperience = '#41809b';
      }
      this.applyRating();
      this.doneDetails = true;

    });
    //exp favorite
    this.etatExperienceFavorite.set(this.id,false);
    this.experiencesService.getUserExperienceFavorite(this.id).subscribe((response: ExperienceFavorite) => {
        this.experienceFavorite = response;
        if (this.experienceFavorite.experience_id){
          this.etatExperienceFavorite.set(this.id,true);
          this.experienceFavoriteExist = true;
        }
        this.doneForExpFavorite = true;
    });

  }

  /**
   * check if there are a connected user
   * if YES
   *    check if connected user is the creator of current experience
   *    if YES, the connected user can not add a review
   *    ** 1- display all reviews of experience
   *    ** 2- hide "My Review" section
   *    ** 3- hide "Write Review" section
   *    if NOT
   *    ** 1- Check if connected User has a review
   *       if YES, display "My Review" section
   *       if NOT, display "Write review" section
   *    ** 2- display reviews of other users
   * **
   * if NOT
   * ** 1- display all reviews of experience
   * ** 2- display "login to add review"
   */
  manageReviews() {
    // * check if there are a connected user
    if (this.userId) { // there is a connected user
      this.usersService.getUserProfile().subscribe((data: User) => {
        this.reviewsAreLoaded = false;
        this.connectedUser = data;
        if (this.connectedUser.id === this.experience.creator.userId) {
          this.getReviews();
          this.manageSections(SCROLL_TO_OWN_REVIEW);
        } else { // connected user is not the creator of current experience
          this.getReviewOfConnectedUser();
        }
      });
    } else { // no connected user
      this.getReviews();
      this.manageSections(SCROLL_TO_WRITE_REVIEW);
    }
    this.displayMenu = true;
  }

  getSuggestedExperiences(id) {
    let params = new HttpParams();
    params = params.append(CURRENT_EXPERIENCE_ID, id);
    params = params.append(LIMIT_FILTER, '3');
    params = params.append(SORT_FILTER, RANDOM_FILTER);
    this.experiencesService.getExperiences(params).subscribe((data: Experience []) => {
      this.suggestedExperiences = data;
    });
  }

  applyRating() {
    const stringOfData = this.experience.rating.toString();
    this.halfPosition = Math.floor(this.experience.rating);
    if (stringOfData.indexOf('.5') > 0) {
      this.isHalfExists = true;
      this.emptyStars = 4 - this.halfPosition;
      this.valueToDisplay = stringOfData;
    } else {
      this.valueToDisplay = stringOfData;
      this.emptyStars = 5 - this.experience.rating;
    }
  }

  openVideo(event) {
    this.videoData = {name: this.experience.name, video: event.video};
    this.videoStatus = true;
  }

  refreshPopupStatus(event) {
    this.videoStatus = event.videoStatus;
  }

  sendData(event) {
    if (event.type && event.type !== '') {
      switch (event.type) {
        case WRITE_REVIEW_ADD:
          this.waitingLoad = true;
          this.addReview(event.data);
          break;
        case WRITE_REVIEW_UPDATE:
          this.waitingLoad = true;
          this.updateReview(event.data);
          break;
        case WRITE_REVIEW_EDIT:
          this.waitingLoad = true;
          this.editReview(event.data);
          break;
        case WRITE_REVIEW_DELETE:
          this.deleteReview(event.data);
          this.reviewsCounter = 0;
          this.singleReview = {
            review: '',
            rating: 0,
            id: '0'
          };
          break;
        case WRITE_LIKE_ACTION:
          this.waitingLoad = true;
          this.LikeAction(event.data);
          break;
        case WRITE_DISLIKE_ACTION:
          this.waitingLoad = true;
          this.DislikeAction(event.data);
          break;
        case WRITE_LIKE_DISLIKE_EDIT:
          this.waitingLoad = true;
          this.editLikeDislike(event.data,event.action, event.data1);
          break;
        case WRITE_EXPERIENCE_FAVORITE_ACTION:
          this.waitingLoad = true;
          this.ExperienceFavoriteAction(event.data);
          break;
      }
    }
  }

  addReview(data) {
    const requestBody = new FormData();
    requestBody.append('rating', data.rating);
    requestBody.append('review', data.review);
    requestBody.append('experienceId', this.experience.id);
    this.experiencesService.addExperienceReview(this.experience.id, requestBody)
      .subscribe((response: Review) => {
        this.waitingLoad = false;
        this.displayForm = false;
        this.myReview = response;
        this.getExperience();
        if (this.connectedUser.role == userRole.SUPER_ADMIN.valueOf() || this.connectedUser.role === userRole.ADMIN.valueOf()) {
          this.notificationService.showSuccess(SUCCESS_ADD_REVIEW_MESSAGE_CONTENT, SUCCESS_ADMIN_ADD_REVIEW_MESSAGE_TITLE);
        } else {
          this.notificationService.showSuccess(SUCCESS_ADD_REVIEW_MESSAGE_CONTENT, SUCCESS_ADD_REVIEW_MESSAGE_TITLE);
        }
      });
  }

  updateReview(data) {
    const requestBody = new FormData();
    requestBody.append('id', data.id);
    requestBody.append('userId', this.userId);
    requestBody.append('rating', data.rating);
    requestBody.append('review', data.review);
    requestBody.append('experienceId', this.experience.id);
    this.experiencesService.updateExperienceReview(data.id, this.experience.id, requestBody).subscribe(() => {
      // this.waitingLoad = false;
      // this.manageSections(SCROLL_TO_MY_REVIEW);
      this.getExperience();
      if (this.connectedUser.role == userRole.SUPER_ADMIN.valueOf() || this.connectedUser.role === userRole.ADMIN.valueOf()) {
        this.notificationService.showSuccess(SUCCESS_EDIT_REVIEW_MESSAGE_CONTENT, SUCCESS_ADMIN_EDIT_REVIEW_MESSAGE_TITLE);
      } else {
        this.notificationService.showSuccess(SUCCESS_EDIT_REVIEW_MESSAGE_CONTENT, SUCCESS_EDIT_REVIEW_MESSAGE_TITLE);
      }
    });
  }

  changeMenu() {
    this.menu = menuAfterAddingReview;
  }

  editReview(data) {
    // getReviewDetails
    this.getDetailsReview(data);
  }

  deleteReview(data) {
    const id = data;
    this.confirmDialogService.confirmThis(ASK_FOR_CONFIRM_DELETE_ACTION, () => {
      this.waitingLoad = true;
      this.experiencesService.deleteReviewOfExperience(id, this.experience.id).subscribe(() => {
        this.waitingLoad = false;
        this.notificationService.showSuccess('', SUCCESS_DELETE_REVIEW_MESSAGE_TITLE);
        this.manageSections(SCROLL_TO_WRITE_REVIEW);
        this.getExperience();
      });
    }, () => {
    });
  }

  manageSections(activeBloc) {
    switch (activeBloc) {
      case SCROLL_TO_MY_REVIEW:
        this.displayForm = false;
        this.ownReview = true;
        this.menu = menuAfterAddingReview;
        break;
      case SCROLL_TO_WRITE_REVIEW:
        this.displayForm = true;
        this.ownReview = false;
        this.menu = menuBeforeAddingReview;
        break;
      case SCROLL_TO_OWN_REVIEW:
        this.displayForm = false;
        this.ownReview = false;
        this.menu = menuOfExperienceOwner;
    }
  }

  getDetailsReview(id) {
    this.experiencesService.getDetailsOfReview(id, this.experience.id).subscribe((data: Review) => {
      this.waitingLoad = false;
      this.singleReview = {review: data.review, rating: data.rating, id: data.id};
      this.manageSections(SCROLL_TO_WRITE_REVIEW);
    });
  }

  getReviews() {
    const params = new HttpParams();
    this.experiencesService.getReviewsOfExperience(this.experience.id, params)
      .subscribe((response: Review[]) => {

        this.reviews = response;
        if (this.reviews && this.reviews.length) {
          this.reviewsCounter = this.reviews.length;
        }
        this.reviewsAreLoaded = true;
        //get likes and dislikes
        for(let i=0;i<this.reviews.length;i++) {
          this.LikeMap.set(this.reviews[i].id, this.reviews[i].number_likes);
          this.DislikeMap.set(this.reviews[i].id, this.reviews[i].number_dislikes);

          // button like/dislike color for user
          if(this.reviews[i].user_action==LikeDislike.LIKE){
            this.etatDislike.set(this.reviews[i].id,false);
            this.etatLike.set(this.reviews[i].id,true);
          } else if (this.reviews[i].user_action==LikeDislike.DISLIKE){
            this.etatDislike.set(this.reviews[i].id,true);
            this.etatLike.set(this.reviews[i].id,false);
          } else {
            this.etatDislike.set(this.reviews[i].id, false);
            this.etatLike.set(this.reviews[i].id, false);
          }
        }
        //end get likes and dislikes
      });
  }

  getReviewsOfUsersNotConnected() {
    let params = new HttpParams();
    if (this.userId) {
      params = params.append(USER_IS_NOT, this.userId);
      params = params.append(FULL_RESPONSE, String(true))
    }
    this.experiencesService.getReviewsOfExperience(this.experience.id, params).subscribe( (response: Review[]) => {
        this.reviews = response;
        for(let i=0;i<this.reviews.length;i++)
        {
          this.LikeMap.set(this.reviews[i].id, this.reviews[i].number_likes);
          this.DislikeMap.set(this.reviews[i].id, this.reviews[i].number_dislikes);

          // button like/dislike color for user
          if(this.reviews[i].user_action==LikeDislike.LIKE){
            this.etatDislike.set(this.reviews[i].id,false);
            this.etatLike.set(this.reviews[i].id,true);
          }else if (this.reviews[i].user_action==LikeDislike.DISLIKE){
            this.etatDislike.set(this.reviews[i].id,true);
            this.etatLike.set(this.reviews[i].id,false);
          } else {
            this.etatDislike.set(this.reviews[i].id,false);
            this.etatLike.set(this.reviews[i].id,false );
          }
        }
        if (this.reviews && this.reviews.length > 0) {
          this.reviewsCounter += this.reviews.length;
        }
        this.reviewsAreLoaded = true;
        this.waitingLoad = false;
    });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (document.querySelector('#' + SCROLL_TO_ABOUT)
      && this.isScrolledIntoView(document.querySelector('#' + SCROLL_TO_ABOUT))) {
      this.activeSubMenu = SCROLL_TO_ABOUT;
    } else if (document.querySelector('#' + SCROLL_TO_GALLERY)
      && this.isScrolledIntoView(document.querySelector('#' + SCROLL_TO_GALLERY))) {
      this.activeSubMenu = SCROLL_TO_GALLERY;
    } else if (document.querySelector('#' + SCROLL_TO_WRITE_REVIEW)
      && this.isScrolledIntoView(document.querySelector('#' + SCROLL_TO_WRITE_REVIEW))) {
      this.activeSubMenu = SCROLL_TO_WRITE_REVIEW;
    } else if (document.querySelector('#' + SCROLL_TO_MY_REVIEW)
      && this.isScrolledIntoView(document.querySelector('#' + SCROLL_TO_MY_REVIEW))) {
      this.activeSubMenu = SCROLL_TO_MY_REVIEW;
    } else if (document.querySelector('#' + SCROLL_TO_USER_REVIEW)
      && this.isScrolledIntoView(document.querySelector('#' + SCROLL_TO_USER_REVIEW))) {
      this.activeSubMenu = SCROLL_TO_USER_REVIEW;
    }
  }

  isScrolledIntoView(elem) {
    const rect = elem.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;
    // Only completely visible elements return true:
    return (elemTop >= 0) && (elemBottom <= window.innerHeight);
  }

  cancelEditReview() {
    this.displayForm = false;
    this.ownReview = true;
    this.activeSubMenu = SCROLL_TO_MY_REVIEW;
    this.menu = menuAfterAddingReview;
  }

  shareFacebook(event) {
    // Update Meta tags dynamically for Facebook (Open graph)
    // this.shareService.setSocialTags(
    //                 event,
    //                 this.experience.name,
    //                 this.experience.description,
    //                 this.experience.coverPicture);

    this.windowPopup("https://www.facebook.com/dialog/share?app_id=145889360804091&display=popup&href=" + event +
      "&image"+this.experience.coverPicture+"&hashtag=AR,AugmentedReality", 700, 400);
  }
  shareTwitter(event) {
    this.windowPopup("https://twitter.com/intent/tweet/?text=Visit%20and%20test%20the%20AR%20experience!%0A&url="+event+
          "%0A&hashtags=AR,AugmentedReality%0A&via=Camlann10", 700, 400);
  }

  shareLinkedin(event) {
    this.windowPopup("https://www.linkedin.com/shareArticle?mini=true&url="+event, 700,400);
    // this.windowPopup("https://www.linkedin.com/sharing/share-offsite/?url="+event+"&image"+this.experience.coverPicture, 700, 400);
  }

  windowPopup(url, width, height) {
    // Calculate the position of the popup so it’s centered on the screen.
    const left = (screen.width / 2) - (width / 2), top = (screen.height / 2) - (height / 2);
    window.open(url,
        "",
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left
      );
  }

  // like_dislike

  addLike(id) {
    const requestBody = new FormData();
    const action = LikeDislike.LIKE ;
    requestBody.append('userId', this.userId);
    requestBody.append('reviewId', id);
    requestBody.append('action', action);
    this.experiencesService.addLikeDislikeReview(this.experience.id, id, requestBody).subscribe(() => {
      this.getReviewsOfUsersNotConnected();
    });
  }

  addDislike(id) {
    const requestBody = new FormData();
    const action = LikeDislike.DISLIKE ;
    requestBody.append('user_id', this.userId);
    requestBody.append('review_id', id);
    requestBody.append('action', action);
    this.experiencesService.addLikeDislikeReview(this.experience.id, id, requestBody).subscribe(() => {
      this.getReviewsOfUsersNotConnected();
    });
  }

  editLikeDislike(id, action, id_action) {
    const requestBody = new FormData();
    requestBody.append('user_id', this.userId);
    requestBody.append('review_id', id);
    requestBody.append('action',action);
    this.experiencesService.editLikeDislikeReview(this.experience.id, id, id_action, requestBody).subscribe(() => {
      this.getReviewsOfUsersNotConnected();
    });
  }

  deleteLikeDislike(id, id_action) {
    this.experiencesService.deleteLikeDislikeReview(this.experience.id, id, id_action).subscribe(() => {
      this.getReviewsOfUsersNotConnected();
    });
  }

  LikeAction(id) {
    for (let i = 0; i < this.reviews.length; i++) {
      if (this.reviews[i].id == id) {
        if (this.reviews[i].user_action == LikeDislike.DISLIKE) {
          this.editLikeDislike(id,LikeDislike.LIKE, this.reviews[i].id_action);
          this.DislikeMap.set(id,this.DislikeMap.get(id)-1);
          this.LikeMap.set(id,this.LikeMap.get(id)+1);
          this.etatLike.set(id,true);
          this.etatDislike.set(id,false);
        } else {
          if (this.reviews[i].user_action == LikeDislike.LIKE) {
            this.deleteLikeDislike(id, this.reviews[i].id_action);
            this.LikeMap.set(id, this.LikeMap.get(id) - 1);
            this.etatLike.set(id, false);
          } else {
            this.addLike(id);
            this.LikeMap.set(id, this.LikeMap.get(id) + 1);
            this.etatLike.set(id, true);
          }
        }
        break;
      }
    }
  }

  DislikeAction(id) {
    for (let i = 0; i < this.reviews.length; i++) {
      if (this.reviews[i].id == id) {
        if (this.reviews[i].user_action == LikeDislike.LIKE) {
          this.editLikeDislike(id,LikeDislike.DISLIKE, this.reviews[i].id_action);
          this.DislikeMap.set(id,this.DislikeMap.get(id)+1);
          this.LikeMap.set(id,this.LikeMap.get(id)-1);
          this.etatDislike.set(id,true);
          this.etatLike.set(id,false);
        } else {
          if (this.reviews[i].user_action == LikeDislike.DISLIKE) {
            this.deleteLikeDislike(id, this.reviews[i].id_action);
            this.DislikeMap.set(id,this.DislikeMap.get(id)-1);
            this.etatDislike.set(id,false);
          } else {
            this.addDislike(id);
            this.DislikeMap.set(id,this.DislikeMap.get(id)+1);
            this.etatDislike.set(id,true);
          }
        }
        break;
      }
    }
  }

  //experience favorite

  addExperienceToFavorites(id) {
    this.disabledButtonFavorite = true;
    const requestBody = new FormData();
    requestBody.append('userId', this.userId);
    requestBody.append('experienceId', id);
    this.experiencesService.addExperienceToFavorites(id, requestBody).subscribe(() => {
      this.waitingLoad = false;
      this.experienceFavoriteExist = true;
      this.disabledButtonFavorite = false;
    });
  }

  deleteExperienceFromFavorites(id) {
    this.disabledButtonFavorite = true;
    this.experiencesService.deleteExperienceFromFavorites(id).subscribe(() => {
      this.waitingLoad = false;
      this.experienceFavoriteExist = false;
      this.disabledButtonFavorite = false;
    });
  }

  ExperienceFavoriteAction(id) {
    this.waitingLoad = true;
    if (this.experienceFavoriteExist == true) {
      this.deleteExperienceFromFavorites(id);
      this.etatExperienceFavorite.set(id,false);
    }
    else {
      this.addExperienceToFavorites(id);
      this.etatExperienceFavorite.set(id,true);
    }
  }

  validateExp() {
    if (this.destroyComponent !== true) { // prevent popup from appearing on other pages
      this.dialogRef = this.dialog.open(ValidateItemDialogComponent, {
        disableClose: false,
        autoFocus: true,
        panelClass: 'my-dialog',
        width: '600px',
        maxHeight: '500px',
        data: {name: this.experience.name , type: 'experience'}
      });
      this.dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.disabledButton = true;
          const requestBody = new FormData();
          requestBody.append('status', String(experienceStatus[0].value));
          this.experiencesService.moderateExperience(requestBody, this.experience.id).subscribe(() => {
            this.notificationService.showSuccess('', SUCCESS_VALIDATE_EXPERIENCE_MESSAGE_TITLE);
            this.getExperience();
            this.displayValidateFixedMenu = false;
          }, (error => {
            this.getExperience();
            this.disabledButton = true;
          }));
        }
      });
    }
  }
}
