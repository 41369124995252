<div class="col-md-12 ">
  <h1 class="col-md-6" mat-dialog-title>{{"Group_Members" | translate}} </h1>
  <div class="input-field col-md-6">

<!--    <input type="text"-->
<!--           id="searchExperience"-->
<!--           class="autocomplete" placeholder="{{ 'Search_Members' | translate }}">-->

  </div>
</div>


  <!----------------------------------  members list   ------------------------->
<br>
<div class="col-md-12 " >
  <div class="row " *ngIf="data && data.length !== 0 ">
    <app-group-details-member
      *ngFor="let item of data"
      [member]="item"></app-group-details-member>
  </div>
  <div class="row  waiting-get-data-popup " *ngIf="!data || data.length === 0 ">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>
</div>

<br>

<!----------------------------------  buttons   ------------------------->
<div class="row actions-popup ">
  <button class="  button waves-effect waves-light btn-large"
          (click)="close();">{{'Close' | translate}}</button>
</div>






