<section class="tz-register">
  <div class="log-in-pop">
    <div class="log-in-pop-left inDesktop">
      <h1>{{"HELLO" | translate}} !</h1>
      <br>
      <h4 class="beta-text">{{"dont have an account" | translate}}<a href="https://www.camlann.io/beta-subscription/">{{"subscribe to our beta" | translate}}</a></h4>
<!--      <h4>{{"Log in with social media" | translate}}</h4>-->
<!--      <br>-->
<!--      <ul>-->
<!--        <li><a (click)="showMessage()"><div class="social-icon"><img src="assets/images/facebook_logo.png" width="17"/></div> {{"Log in with Facebook" | translate}}</a> </li>-->
<!--        <li><a (click)="showMessage()"><div class="social-icon"><img src="assets/images/google_logo.png" width="16"/></div> {{"Log in with Google" | translate}}</a> </li>-->
<!--        <li><a (click)="showMessage()"><div class="social-icon"><i class="fa fa-twitter tw1"></i></div> {{"Log in with Twitter" | translate}}</a> </li>-->
<!--      </ul>-->
    </div>
    <div class="log-in-pop-right">
      <h4>{{"Login" | translate}}</h4>
      <br>
      <form class="s12" [formGroup]="loginForm" (ngSubmit)="onLoginWithFirebase(loginForm.value)">
        <div class="form-group input-field s12">
          <label for="email">{{'EMAIL' | translate}}</label>
          <input [class.invalid]="loginForm.get('email').invalid && loginForm.get('email').touched"
                 type="email" class="form-control" formControlName="email" id="email"/>

          <div *ngIf="loginForm.controls['email'].invalid && loginForm.controls['email'].touched"
               class="alert alert-danger">
            <div *ngIf="loginForm.controls['email'].errors.required">
              {{'Please enter your email!' | translate}}
            </div>
            <div *ngIf="loginForm.controls['email'].errors.pattern">
              {{'Please enter a valid email!' | translate}}
            </div>
          </div>
        </div>
        <div class="form-group input-field s12">
          <label for="password">{{'PASSWORD' | translate}}</label>
          <input [class.invalid]="loginForm.get('password').invalid && loginForm.get('password').touched"
                 type="password" class="form-control" formControlName="password" id="password"/>
          <div *ngIf="loginForm.controls['password'].invalid && loginForm.controls['password'].touched"
               class="alert alert-danger">
            {{'Please enter a valid password!' | translate}}
          </div>
        </div>
        <div class="input-field s4" [ngClass]="{'disabledSubmit': loginForm.invalid}" class="waves-effect waves-light log-in-btn">
          <input type="submit" [disabled]="loginForm.invalid"  value="Login" >
        </div>
<!--        <div>-->
<!--          <div class="input-field s12"><a [routerLink]="[forgotPasswordLink]" class="forgot-pswrd">{{'Forgot password' | translate}}</a></div>-->
<!--        </div>-->
      </form>
    </div>
    <div class="log-in-pop-left inMobile" >
            <h4 class="beta-text">{{"dont have an account" | translate}}<a href="https://www.camlann.io/beta-subscription/">{{"subscribe to our beta" | translate}}</a></h4>

<!--      <h4>{{"Log in with social media" | translate}}</h4>-->
<!--      <br>-->
<!--      <ul>-->
<!--        <li><a (click)="showMessage()"><div class="social-icon"><img src="assets/images/facebook_logo.png" width="17"/></div> {{"Log in with Facebook" | translate}}</a> </li>-->
<!--        <li><a (click)="showMessage()"><div class="social-icon"><img src="assets/images/google_logo.png" width="16"/></div> {{"Log in with Google" | translate}}</a> </li>-->
<!--        <li><a (click)="showMessage()"><div class="social-icon"><i class="fa fa-twitter tw1"></i></div> {{"Log in with Twitter" | translate}}</a> </li>-->
<!--      </ul>-->
    </div>
  </div>
</section>
