import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {AR_WEB} from './utils/actionsLabels/labels';
import {Router} from '@angular/router';
import {UsersService} from './reusables/services/users.service';
import {TokenHelperService} from './reusables/services/token-helper.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  isLogged = false;
  title = AR_WEB;

  constructor(translate: TranslateService,
              private titleService: Title,
              private userService: UsersService,
              private router: Router,
              private tokenHelperService: TokenHelperService
              ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');
    this.setTitle(this.title);
    this.userService.navbar.subscribe(val => {
      this.isLogged = val;
    });
    this.isLogged = this.tokenHelperService.getConnectedUser();
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  isNotInEditor() {
    return !this.router.url.includes('editor');
  }
  isNotInResetPassword() {
    return !this.router.url.includes('reset-password');
  }
}
