<section *ngIf="doneForAnalyticsDuration">
  <div class="">
    <div class="">
      <div class="tz-2-com tz-2-main">
<!--        <h4 *ngIf="analyticsDuration.experience_name != null">" {{analyticsDuration.experience_name}} " {{"visits duration" | translate}}</h4>-->
        <h4 >{{"Visits duration" | translate}}</h4>
        <div class="db-list-com tz-db-table">

          <table class="table table-striped">
            <tbody>
            <tr>
              <td>{{"All visits duration" | translate}}</td>
              <td class="value">{{analyticsDuration.all_visits_duration}}</td>
            </tr>
            <tr>
              <td>{{"Unique visit duration" | translate}}</td>
              <td class="value">{{analyticsDuration.unique_visits_duration}}</td>
            </tr>
            <tr>
              <td>{{"Last 24 hours" | translate}}</td>
              <td class="value">{{analyticsDuration.last_24_hours}}</td>
            </tr>
            <tr>
              <td>{{"Last week" | translate}}</td>
              <td class="value">{{analyticsDuration.last_week}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>

