import {Component, Input, OnInit} from '@angular/core';
import {statusClass} from '../../../../utils/global-constants/experienceStatusColors';

@Component({
  selector: 'app-experience-status',
  templateUrl: './experience-status.component.html',
  styleUrls: ['./experience-status.component.scss']
})
export class ExperienceStatusComponent implements OnInit {

  constructor() { }
  @Input() status: number;
  statusClassTab = statusClass;
  ngOnInit(): void {
  }

}
