import {Injectable} from '@angular/core';
import {COOKIES_CONNECTED_USER_ID_TOKEN,} from '../../utils/actionsLabels/labels';
import {AngularFireAuth} from '@angular/fire/auth';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class TokenHelperService {
  constructor(private auth: AngularFireAuth,
              private cookieService: CookieService) {
  }

  getConnectedUser() {
    if (this.getTokenData() ) {
      return true;
    } else {
      return false;
    }
  }

  GetToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.auth.onAuthStateChanged(user => {
        if (user) {
          user.getIdToken(false).then(idToken => {
            resolve(idToken);
          });
        } else {
          resolve(null);
        }
      }).then();
    });
  }

  getTokenData() {
    if (this.cookieService.get(COOKIES_CONNECTED_USER_ID_TOKEN)) {
      const token = this.cookieService.get(COOKIES_CONNECTED_USER_ID_TOKEN);
      if (token != null) {
        return JSON.parse(atob(token.split('.')[1]));
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}
