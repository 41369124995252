<div class="pglist-p3 pglist-bg pglist-p-com" id="ld-rer">
  <div class="pglist-p-com-ti">
    <h3><span>{{"Rating and Reviews" | translate}}</span></h3></div>
  <div class="list-pg-inn-sp">
    <div class="lp-ur-all">
      <div class="lp-ur-all-right">
        <h5>{{'Overall Ratings' | translate}}</h5>
        <p>
          <span class="span-rating">
            {{rating}} <i class="fa fa-star" aria-hidden="true"></i>
          </span> {{'based on X reviews' | translate: param}}
        </p>
      </div>
    </div>
    <div class="lp-ur-all-rat" *ngIf="reviews?.length > 0">
      <h5>{{'Reviews' | translate}}</h5>
      <ng-container >
        <div class="reviews-container" *ngIf="doneForReviews && reviews.length > 3">
          <app-single-review *ngFor="let item of reviews | paginate: { itemsPerPage: 3, currentPage: r , id: 'pagesExperiences'}"
                              [data]="item" (reviewData)="reviewData($event)" [likes]="likes" [dislikes]="dislikes"
                              [etatLike]="etatLike" [etatDislike]="etatDislike" ></app-single-review>
        </div>
        <div class="reviews-container" *ngIf="doneForReviews && reviews.length <= 3">
          <app-single-review *ngFor="let item of reviews"
                             [data]="item" (reviewData)="reviewData($event)" [likes]="likes" [dislikes]="dislikes"
                             [etatLike]="etatLike" [etatDislike]="etatDislike"></app-single-review>
        </div>
      </ng-container>
      <div class="row center-align" *ngIf="doneForReviews && reviews.length > 3">
        <pagination-controls (pageChange)="r = $event" id="pagesExperiences"
                             previousLabel=""
                             nextLabel=""
                             directionLinks="true"
                             autoHide="false"></pagination-controls>
      </div>
    </div>
  </div>
</div>
