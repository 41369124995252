<section>
  <div class="tz" *ngIf="user && user.userId !== ''">
    <div class="tz-l"  >
      <app-profile-picture [user]="user"></app-profile-picture>
      <app-account-menu [user]="user"></app-account-menu>
    </div>
    <div class="tz-2">
        <router-outlet></router-outlet>
    </div>
  </div>
</section>
