import {Component, Input, OnInit} from '@angular/core';
import {AnalyticsVisitors} from '../../interfaces/analytics-visitors';

@Component({
  selector: 'app-analytics-visitors',
  templateUrl: './analytics-visitors.component.html',
  styleUrls: ['./analytics-visitors.component.scss']
})
export class AnalyticsVisitorsComponent implements OnInit {

  @Input() analyticsVisitors: AnalyticsVisitors;
  @Input() doneForAnalyticsVisitors: boolean;

  constructor() {
  }

  ngOnInit(): void {

  }

}
