<section *ngIf="doneForUserProfile">
  <div class="tz">
    <div class="tz-l">
      <app-profile-picture [user]="user"></app-profile-picture>
      <app-account-menu [user]="user"></app-account-menu>
    </div>
    <div class="tz-2">
      <app-my-favorites *ngIf="doneForExperiences"
                        (experienceAction)="experienceFavoriteAction($event)"
                        [noFavoritesExperiences]="noFavoritesExperiences"
                        [listExperiences]="listExperiences"
                        [disabledButton]="disabledButtonExp"
                        [carouselOptions]="carouselOptions"></app-my-favorites>
      <app-my-groups-favorites *ngIf="doneForGroups"
                               (groupAction)="groupFavoriteAction($event)"
                               [noFavoritesGroup]="noFavoritesGroup"
                               [listGroups]="listGroups"
                               [disabledButtonG]="disabledButtonGroup"
                               [carouselOptions]="carouselOptions"></app-my-groups-favorites>
      <app-my-templates-favorites *ngIf="doneForTemplates"
                                  (templateAction)="templateFavoriteAction($event)"
                                  [noFavoritesTemplates]="noFavoritesTemplates"
                                  [listTemplates]="listTemplates"
                                  [disabledButtonTemp]="disabledButtonTemp"
                                  [carouselOptions]="carouselOptions"></app-my-templates-favorites>
    </div>
    <!--    <app-notifications></app-notifications>-->
  </div>
</section>
<app-confirm-dialog [confirmText]="deleteText"></app-confirm-dialog>
