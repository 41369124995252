import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SecondMenuComponent} from '../../editor-menu/second-menu/second-menu.component';
import {ON_CLICK_TYPE, VALID_VIDEO_LINK} from '../../../../utils/actionsLabels/labels';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {urlForm} from '../../../../utils/global-constants/regExp';

@Component({
  selector: 'app-on-click-type-dialog',
  templateUrl: './on-click-type-dialog.component.html',
  styleUrls: ['./on-click-type-dialog.component.scss']
})
export class OnClickTypeDialogComponent implements OnInit {
  public types = ON_CLICK_TYPE;
  VALID_LINK = VALID_VIDEO_LINK ;

  public type;

  formUrlPopup: FormGroup;
  urlChange = false;

  constructor(
    public dialogRef: MatDialogRef<SecondMenuComponent>,
    @Inject(MAT_DIALOG_DATA) public action) {}


  ngOnInit(): void {
    this.formUrlPopup = new FormGroup({
      url : new FormControl('', [Validators.pattern(urlForm), Validators.required] )
    });
    // edit form data
    if (this.action) {
      this.type = this.action[0].type;
      this.formUrlPopup.controls.url.setValue(this.action[0].info[0]);
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  submitUrl() {
    this.dialogRef.close({type: this.type, url: this.formUrlPopup.controls.url.value});
  }

}
