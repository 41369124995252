import {fromPxToCm} from './PositionFunction';

export function changeScale(width, height, thickness, objScale) {
  if (fromPxToCm(width) > 50 || fromPxToCm(height) > 50 || fromPxToCm(thickness) > 50) {
    const scale = 50 / Math.max(fromPxToCm(width), fromPxToCm(height))
    objScale = {x: scale, y: scale, z: scale};
  }
  return objScale;
}
export function getScale(width, height, thickness, objScale) {
  const scale = 10 / Math.max(fromPxToCm(width), fromPxToCm(height), fromPxToCm(thickness));
  objScale = {x: scale, y: scale, z: scale};
  return objScale;
}

export function getScaleForEdit(newGeometry, oldGeometry, objScale) {
  // on va calculer le SCALE pour obtenir deux objets ont presque la même grandeur

  // obtenir le scale initial
  const scaleOld = getScale(oldGeometry.x, oldGeometry.y, oldGeometry.z,   {x: 1, y: 1, z: 1});
  const scaleNew = getScale(newGeometry.x, newGeometry.y, newGeometry.z,   {x: 1, y: 1, z: 1});
  // multiplier par le scale ajouté par user
  const scale = scaleNew.x * (objScale.x / scaleOld.x );

  objScale = {x: scale, y: scale, z: scale};
  return objScale;
}
