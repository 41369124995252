import {xColor, yColor, zColor} from '../../utils/global-constants/colors';
import {fromPxToCm, Inverse} from '../../utils/functions/PositionFunction';
import {rotationForSecondMenu} from '../../utils/functions/RotationFunction';
import {FILE} from '../../utils/actionsLabels/editorLabels';
import {EditorElementType, GizmoTypes} from 'src/app/utils/global-constants/editor-const';

export const IMASK_POSITION =  {
  mask: Number,  // enable number mask
  // other options are optional with defaults below
  scale: 1,  // digits after point, 0 for integers
  signed: true,  // disallow negative
  padFractionalZeros: false,  // if true, then pads zeros at end to the length of scale
  normalizeZeros: true,  // appends or removes zeros at ends
  radix: '.',  // fractional delimiter
  mapToRadix: ['.'] , // symbols to process as radix
  // additional number interval options (e.g.)
  min: -999999999,
  max: 999999999
};
export const IMASK_ROTATION =  {
  mask: Number,  // enable number mask
  // other options are optional with defaults below
  scale: 0,  // digits after point, 0 for integers
  signed: false,  // disallow negative
  padFractionalZeros: false,  // if true, then pads zeros at end to the length of scale
  normalizeZeros: true,  // appends or removes zeros at ends
  radix: '.',  // fractional delimiter
  mapToRadix: ['.'] , // symbols to process as radix
  // additional number interval options (e.g.)
  min: 0,
  max: 360
};
export const IMASK_SCALE =  {
  mask: Number,  // enable number mask
  // other options are optional with defaults below
  scale: 2,  // digits after point, 0 for integers
  signed: false,  // disallow negative
  padFractionalZeros: false,  // if true, then pads zeros at end to the length of scale
  normalizeZeros: true,  // appends or removes zeros at ends
  radix: '.',  // fractional delimiter
  mapToRadix: ['.'] , // symbols to process as radix
  // additional number interval options (e.g.)
  min: 0.1,
  max: 9999
};
export const IMASK_SIZE =  {
  mask: Number,  // enable number mask
  // other options are optional with defaults below
  scale: 2,  // digits after point, 0 for integers
  signed: false,  // disallow negative
  padFractionalZeros: false,  // if true, then pads zeros at end to the length of scale
  normalizeZeros: true,  // appends or removes zeros at ends
  radix: '.',  // fractional delimiter
  mapToRadix: ['.'] , // symbols to process as radix
  // additional number interval options (e.g.)
  min: 0.1,
  max: 999999999999999999999999999
};
export const IMASK_LATITUDE =  {
  mask: Number,  // enable number mask
  // other options are optional with defaults below
  scale: 16,  // digits after point, 0 for integers
  signed: true,  // disallow negative
  padFractionalZeros: false,  // if true, then pads zeros at end to the length of scale
  normalizeZeros: true,  // appends or removes zeros at ends
  radix: '.',  // fractional delimiter
  mapToRadix: ['.'] , // symbols to process as radix
  // additional number interval options (e.g.)
  min: -90,
  max: 90
};
export const IMASK_LONGITUDE =  {
  mask: Number,  // enable number mask
  // other options are optional with defaults below
  scale: 16,  // digits after point, 0 for integers
  signed: true,  // disallow negative
  padFractionalZeros: false,  // if true, then pads zeros at end to the length of scale
  normalizeZeros: true,  // appends or removes zeros at ends
  radix: '.',  // fractional delimiter
  mapToRadix: ['.'] , // symbols to process as radix
  // additional number interval options (e.g.)
  min: -180,
  max: 180
};

/***************************** get second menu elements **************************************/
export function getInputText(label: string, value: string, change: string) {
  return {
    html: {
      label,
        value,
        element: {
        type: 'input',
      },
    },
    change
  };
}
export function getInputFile(label: string, value: string, change: string, disabled: boolean) {
  return {
    html: {
      label,
      value,
      element: {
        type: 'input-file',
        editIcon : 'fas fa-edit',
        disabled ,
      },
    },
    change
  };
}
export function getRange(label, value, change, min, max) {
  return {
    html: {
      label,
      value,
      element: {
        type: 'range',
        min,
        max,
      },
    },
    change
  };
}
export function getColor(label, value, change) {
  return {
    html: {
      label,
      value,
      element: {
        type: 'color',
      },
    },
    change,
  };
}
/***************************** get second menu subsections **************************************/
export function getSubsectionPosition(selectedObj, selectedTrigger) {
  return {
    html: {
      label: 'Position',
      click: GizmoTypes.POSITION,
      subname: 'Position relative to marker',
      content: [
        {
          html: {
            label: 'X (cm)',
            color: xColor,
            value: Math.round(fromPxToCm(selectedObj.position.x - selectedTrigger.position.x) * 10) / 10,
            element: {
              type: 'input-number',
              step: '0.1',
              max: '9999999999999',
              min: '-9999999999999',
              mask: IMASK_POSITION
            },
          },
          change: 'positionx'
        }, {
          html: {
            label: 'Y (cm)',
            color: yColor,
            value: Math.round(fromPxToCm(selectedObj.position.y - selectedTrigger.position.y) * 10) / 10,
            element: {
              type: 'input-number',
              step: '0.1',
              max: '9999999999999',
              min: '-9999999999999',
              mask: IMASK_POSITION
            },
          },
          change: 'positiony'
        },
        {
          html: {
            label: 'Z (cm)',
            color: zColor,
            value: Inverse(Math.round(fromPxToCm(selectedObj.position.z - selectedTrigger.position.z) * 10) / 10),
            element: {
              type: 'input-number',
              step: '0.1',
              max: '9999999999999',
              min: '-9999999999999',
              mask: IMASK_POSITION
            },
          },
          change: 'positionz'
        }
      ]
    },
  };
}
export function getSubsectionRotation(selectedObj) {
  return {
    html: {
      label: 'Rotation',
      click: GizmoTypes.ROTATION,
      subname: 'Rotation values between 0 and 360',
      content: [
        {
          html: {
            label: ' X ',
            color: xColor,
            value: rotationForSecondMenu(selectedObj.rotation.x, 'x'), element: {
              type: 'input-number',
              step: '1',
              max: '360',
              min: '0',
              mask: IMASK_ROTATION
            },
          },
          change: 'rotationx'
        }, {
          html: {
            label: ' Y ',
            color: yColor,
            value: rotationForSecondMenu(selectedObj.rotation.y, 'y'),
            element: {
              type: 'input-number',
              step: '1',
              max: '360',
              min: '0',
              mask: IMASK_ROTATION
            },
          },
          change: 'rotationy'
        },
        {
          html: {
            label: ' Z',
            color: zColor,
            value: rotationForSecondMenu(selectedObj.rotation.z, 'z'),
            element: {
              type: 'input-number',
              step: '1',
              max: '360',
              min: '0',
              mask: IMASK_ROTATION
            },
          },
          change: 'rotationz'
        }
      ]
    },
  };
}
export function getSubsectionSize(selectedObj) {
  return {
    html: {
      label: 'Size',
      click: GizmoTypes.SIZE,
      subname: '',
      content: [
        {
          html: {
            label: ' Height (cm)',
            value: Math.round(fromPxToCm(selectedObj.scale.x * selectedObj.height) * 100) / 100,
            element: {
              type: 'input-number',
              step: '0.1',
              max: '99999999999',
              min: '0',
              mask: IMASK_SIZE
            },
          },
          change: 'marker-height'
        },
        {
          html: {
            label: ' Width (cm)',
            value: Math.round(fromPxToCm(selectedObj.scale.x * selectedObj.width) * 100) / 100,
            element: {
              type: 'input-number',
              step: '0.1',
              max: '99999999999',
              min: '0',
              mask: IMASK_SIZE
            },
          },
          change: 'marker-width'
        },
      ]
    },
  };
}
export function getSubsectionScale(selectedObj) {
  return  {
    html: {
      label: 'Scale',
      click: GizmoTypes.SCALE,
      subname: 'Scale Value',
      content: [
        {
          html: {
            label: ' Scale ',
            value: selectedObj.scale.x,
            element: {
              type: 'input-number',
              step: '0.1',
              max: '99999999999',
              min: '0',
              mask: IMASK_SCALE
            },
          },
          change: 'scale'
        },
      ]
    },
  };
}

/***************************** get second menu sections **************************************/
export function getSectionActions() {
  return {
    name: 'Actions',
    show: true,
    actions: [
      {
        type: 'button',
        label: 'Copy',
        icon: 'fa fa-copy',
        click: 'clone'
      },
      {
        type: 'button',
        label: 'Delete',
        icon: 'fa fa-trash',
        click: 'delete'
      },
    ],
    selected: null,
  };
}
export function getSectionActionsAudio(selectedObj) {
  let selected;
  if (selectedObj.isPlaying === true) { selected = 0; } else { selected = -1; }
  return     {
    name: 'Actions',
    show: true,
    actions: [
      {
        type: 'dbButton',
        label1: 'Play',
        label2: 'Pause',
        icon1: 'fa fa-play',
        icon2: 'fa fa-pause',
        click: 'play',
      },
      {
        type: 'button',
        label: 'Copy',
        icon: 'fa fa-copy',
        click: 'clone'
      },
      {
        type: 'button',
        label: 'Delete',
        icon: 'fa fa-trash',
        click: 'delete'
      },
    ],
    selected,
  };
}

export function getSectionOnClick(selectedObj) {
  return {
    name: 'On click',
    show: false,
    content: [
      {
        html: {
          label: '',
          value: selectedObj.onClick,
          element: {
            type: 'list',
          },
        },
        change: ''
      },
      {
        html: {
          label: 'Add action',
          value: selectedObj.onClick,
          element: {
            type: 'addButton',
            editLabel: 'Edit',
            deleteLabel: 'Delete',
          },
        },
        change: ''
      },
    ]
  };

}

/***************************** get second menu functions **************************************/
export function getSecondMenuText(selectedObj, selectedTrigger) {
  return [
    {
      name: 'Text',
      show: false,
      content: [
        getInputText('Component name', selectedObj.name, 'name'),
        {
          html: {
            label: 'Content',
            value: selectedObj.content,
            element: {
              type: 'textarea',
            },
          },
          change: 'textContent'
        },

        {
          html: {
            label: 'Font',
            value: selectedObj.fontFamily,
            element: {
              type: 'select',
              options:
                [
                  {value: 'Ubuntu', label: 'Ubuntu'},
                  {value: 'Roboto', label: 'Roboto'},
                  {value: 'Open Sans', label: 'Open Sans'},
                  {value: 'Roboto Mono', label: 'Roboto Mono'},
                  {value: 'Noto Serif', label: 'Noto Serif'}, ],
              changed: false
            },
          },
          change: 'textFont'
        },
        {
          html: {
            label: 'Align',
            value: selectedObj.align,
            element: {
              type: 'radio',
              options: [{value: '1', label: 'Right', icon: 'icon fa fa-align-right fa-1x'},
                {value: '2', label: 'Center', icon: 'icon fa fa-align-center fa-1x'},
                {value: '3', label: 'Left', icon: 'icon fa fa-align-left fa-1x'},
              ],
              checked: selectedObj.align,
            },
          },
          change: 'textAlign'
        },
        getRange('Font size', selectedObj.fontSize, 'textFontSize', 5, 100),
        getColor('Text color', selectedObj.color, 'textColor'),
      ]
    },
    {
      name: 'Location',
      show: true,
      content: [

        {
          html: {
            label: '',
            value: '',
            element: {
              type: 'tab-group',
              tabgroup: [
                getSubsectionPosition(selectedObj, selectedTrigger),
                getSubsectionRotation(selectedObj)
              ],
            },
          },
          change: ''
        }

      ]
    },
    {
      name: 'Outline',
      show: false,
      content: [
        getRange('Width', selectedObj.outlineWidth, 'outlineWidth', 0, 10),
        getColor('Color', selectedObj.outlineColor, 'outlineColor'),
      ]
    },
    {
      name: 'Background',
      show: false,
      content: [
        getRange('Width (cm)', fromPxToCm(selectedObj.width), 'backgroundWidth', 1, 50),
        getRange('Height (cm)', fromPxToCm(selectedObj.height), 'backgroundHeight', 1, 50),
        getColor('Color', selectedObj.background, 'backgroundColor'),
        getRange('Border width', selectedObj.thickness, 'borderWidth', 0, 20),
        getColor('Border color', selectedObj.borderColor, 'borderColor'),
      ]
    },
    getSectionOnClick(selectedObj),
    getSectionActions()
  ];
}
export function getSecondMenuImage(selectedObj, selectedTrigger) {
  return [
    {
      name: 'Image',
      show: false,
      content: [
        getInputText('Component name', selectedObj.name, 'name'),
        getInputFile(FILE, selectedObj.fileName, 'image-file', true),
      ]
    },
    {
      name: 'Location',
      show: true,
      content: [
        {
          html: {
            label: '',
            value: '',
            element: {
              type: 'tab-group',
              tabgroup: [
                getSubsectionPosition(selectedObj, selectedTrigger),
                getSubsectionRotation(selectedObj),
                getSubsectionSize(selectedObj)],
            },
          },
          change: ''
        }

      ]
    },
    getSectionOnClick(selectedObj),
    getSectionActions(),
  ];
}
export function getSecondMenu3DObj(selectedObj, selectedTrigger) {
  return [
    {
      name: '3D Object',
      show: false,
      content: [
        getInputText('Component name', selectedObj.name, 'name'),
        getInputFile(FILE, selectedObj.fileName, 'obj-file', true),
      ]
    },
    {
      name: 'Location',
      show: true,
      content: [

        {
          html: {
            label: '',
            value: '',
            element: {
              type: 'tab-group',
              tabgroup: [
                getSubsectionPosition(selectedObj, selectedTrigger),
                getSubsectionRotation(selectedObj),
                getSubsectionScale(selectedObj)],
            },
          },
          change: ''
        }

      ]
    },
    getSectionOnClick(selectedObj),
    getSectionActions(),
  ];
}
export function getSecondMenuVideo(selectedObj, selectedTrigger) {
  return [
    {
      name: 'Video',
      show: false,
      content: [
        getInputText('Component name', selectedObj.name, 'name'),
        {
          html: {
            label: 'Url',
            value: selectedObj.uri,
            element: {
              type: 'input-video-link',
            },
          },
          change: 'videoUri'
        }
      ]
    },
    {
      name: 'Location',
      show: true,
      content: [

        {
          html: {
            label: '',
            value: '',
            element: {
              type: 'tab-group',
              tabgroup: [
                getSubsectionPosition(selectedObj, selectedTrigger),
                getSubsectionRotation(selectedObj),
                getSubsectionSize(selectedObj)]
            },
          },
          change: ''
        }

      ]
    },
    getSectionActions(),
  ];
}
export function getSecondMenuAudio(selectedObj) {
  return [
    {
      name: 'Audio',
      show: true,
      content: [
        getInputText('Component name', selectedObj.name, 'audioName'),
        getInputFile(FILE, selectedObj.fileName, 'audio-file', true),

        {
          html: {
            label: 'Volume',
            value: selectedObj.volume,
            element: {
              type: 'range',
              min: 0.1,
              max: 1.0,
              step: 0.1
            },
          },
          change: 'audioVolume'
        },
        {
          html: {
            label: 'Rate',
            value: selectedObj.rate,
            element: {
              type: 'range',
              min: 0.1,
              max: 2.0,
              step: 0.1
            },
          },
          change: 'audioRate'
        },
        {
          html: {
            label: 'Loop',
            value: selectedObj.loop,
            element: {
              type: 'slide',
            },
          },
          change: 'audioLoop'
        },
      ]
    },
    getSectionActionsAudio(selectedObj),
  ];
}
export function getSecondMenuMarker(selectedObj) {
  return [
    {
      name: 'Marker',
      show: true,
      content: [
        getInputText('Marker name', selectedObj.name, 'name'),
        getInputFile(FILE, selectedObj.fileName, 'marker-file', true),
      ]
    },
    {
      name: 'Location',
      show: true,
      content: [

        {
          html: {
            label: '',
            value: '',
            element: {
              type: 'tab-group',
              tabgroup: [
                {
                  html: {
                    label: 'Position',
                    click: GizmoTypes.POSITION,
                    subname: 'Move arrows to change marker position',
                    content: [
                    ]
                  },
                },
                getSubsectionSize(selectedObj)],
            },
          },
          change: ''
        }
      ]
    },
    getSectionActions(),

  ];
}
export function getSecondMenuSurface(selectedObj) {
  return [
    {
      name: 'Surface',
      show: true,
      content: [
        getInputText('Surface name', selectedObj.name, 'name'),
      ]
    },
  ];
}
export function getSecondMenuGeoPosition(selectedObj) {
  return [
    {
      name: 'GeoPosition',
      show: true,
      content: [
        getInputText('Name', selectedObj.name, 'name'),
        {
          html: {
            label: 'Latitude (DMS)',
            value: selectedObj.latitude,
            element: {
              type: 'input-number',
              step: '0.1',
              max: '90',
              min: '-90',
              mask: IMASK_LATITUDE
            },
          },
          change: 'latitude'
        },
        {
          html: {
            label: 'Longitude (DMS)',
            value: selectedObj.longitude,
            element: {
              type: 'input-number',
              step: '0.1',
              max: '180',
              min: '-180',
              mask: IMASK_LONGITUDE
            },
          },
          change: 'longitude'
        }
      ]
    },
  ];
}
// retoune json pour le second menu selon le type de l'objet selectionné
export function getSecondMenu(selectedObj, selectedTrigger, selectedMesh, showSecondMenu) {
  showSecondMenu = true;
  if (selectedMesh.name.substring(0, 5) === EditorElementType.audio.toString()) {
    return getSecondMenuAudio(selectedObj);
  }
  switch (selectedMesh.id) {
    case 'text':
     return getSecondMenuText(selectedObj, selectedTrigger);
    case EditorElementType.image.toString():
      return getSecondMenuImage(selectedObj, selectedTrigger);
    case '3DObject':
      return getSecondMenu3DObj(selectedObj, selectedTrigger);
    case 'video':
      return getSecondMenuVideo(selectedObj, selectedTrigger);
    case EditorElementType.marker.toString():
      return getSecondMenuMarker(selectedObj);
    case EditorElementType.surface.toString():
      return getSecondMenuSurface(selectedObj);
    case EditorElementType.geo.toString():
      return getSecondMenuGeoPosition(selectedObj);
  }
}
