<li class="view-msg">

    <div class="list-left-near group-container" [ngClass]="{'group-container-image-background': !data.avatar || data.avatar == ''}">
      <img *ngIf="data.avatar && data.avatar != ''" src="{{data.avatar}}" alt="{{data.name}}">
    </div>

    <div>
      <div class="item-title">
        <a [routerLink]="linkDetails" title="{{data.name}}">
          <h5 class="ellipsis-style">{{data.name}}</h5>
        </a>
      </div>
      <div class="item-p">
        <p class="padding-p ellipsis-style">{{data.description}}</p>
      </div>
      <div class="item-info">
        <p class="padding-p"  *ngIf="showMembers"><span>{{data.members}} {{"members" | translate}}</span></p>
      </div>
      <div class="item-button">
        <span class="custom-btn" (click)="askJoinGroup()">
          {{'JOIN_GROUP' | translate}}
        </span>
      </div>


    </div>
</li>
