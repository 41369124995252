export enum VisibleGlobally {
  VISIBLE = 'VISIBLE',
  NOT_VISIBLE = 'NOT_VISIBLE',
}

export enum StatusExperience {
  PUBLISHED = 0,
  UNPUBLISHED = 1,
  DRAFT = 2,
  DELETE = 3,
  IN_MODERATION = 4
}
