import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseURL, BaseURLMock} from '../../utils/global-constants/baseUrl';

@Injectable({
  providedIn: 'root'
})
export class ExperiencesService {

  constructor(private httpClient: HttpClient) {}

  getExperiences(parameters) {
    return this.httpClient.get(BaseURL + 'experiences',
      {params: parameters});
  }

  getDetailExperiences(id) {
    return this.httpClient.get(BaseURL + 'experiences/' + id);
  }

  addExperienceReview(id, data) {
    return this.httpClient.post(BaseURL + 'experiences/' + id + '/reviews', data);
  }

  getReviewsOfExperience(id, parameters) {
    return this.httpClient.get(BaseURL + 'experiences/' + id + '/reviews',
      {params: parameters});
  }

  getDetailsOfReview(idReview, idExperience) {
    return this.httpClient.get(BaseURL + 'experiences/' + idExperience + '/reviews/' + idReview);
  }

  updateExperienceReview(idReview, idExperience, data) {
    return this.httpClient.put(BaseURL + 'experiences/' + idExperience + '/reviews/' + idReview, data);
  }

  deleteReviewOfExperience(idReview, idExperience) {
    return this.httpClient.delete(BaseURL + 'experiences/' + idExperience + '/reviews/' + idReview,
      {responseType: 'text'});
  }

  getExperiencesReviews(parameters) {
    return this.httpClient.get(BaseURL + 'experiences/reviews', {params: parameters});
  }

  addExperience(request, parameters) {
    return this.httpClient.post(BaseURL + 'experiences', request, {params: parameters});
  }

  updateExperience(request, id) {
    return this.httpClient.put(BaseURL + 'experiences/' + id, request);
  }

  deleteExperience(idExperience) {
    return this.httpClient.delete(BaseURL + 'experiences/' + idExperience,
      {responseType: 'text'});
  }

  editExperienceContent(experienceId, contentId, content) {
    return this.httpClient.put(BaseURL + 'experiences/' + experienceId + '/content/' + contentId, content);
  }


  getExperienceContent(experienceId) {
    return this.httpClient.get(BaseURL + 'experiences/' + experienceId + '/content');
  }

  // get groups where experience is shared
  getExperienceGroups(experienceId) {
    return this.httpClient.get(BaseURL + 'experiences/' + experienceId + '/groups');
  }


  // still using mockserver
  getExperienceVisitorsAnalytics(experienceId) {
    return this.httpClient.get(BaseURLMock + 'visitoranalytics/experiences/' + experienceId);
  }

  getAllExperiencesVisitorsAnalytics() {
    return this.httpClient.get(BaseURLMock + 'visitoranalytics/experiences');
  }

  getAllExperiencesDurationAnalytics() {
    return this.httpClient.get(BaseURLMock + 'durationanalytics/experiences');
  }

  getExperienceDurationAnalytics(experienceId) {
    return this.httpClient.get(BaseURLMock + 'durationanalytics/experiences/' + experienceId);
  }

  getAllExperiencesChartAnalytics(from: string, to: string, type: string) {
    return this.httpClient.get(BaseURLMock + 'chartanalytics/experiences', {params: {from, to, type}});
  }

  getExperienceChartAnalytics(experienceId: number, from: string, to: string, type: string) {
    // todo : update this when the backend is ready!
    return this.httpClient.get(BaseURLMock + 'chartanalytics/experiences', {params: {from, to, type}});
  }

  // like_dislike

  addLikeDislikeReview(idExperience, idReview, data) {
    return this.httpClient.post(BaseURL + 'experiences/' + idExperience + '/reviews/' + idReview + '/like-dislike', data
    );
  }

  editLikeDislikeReview(idExperience, idReview, idAction, data) {
    return this.httpClient.put(BaseURL + 'experiences/' + idExperience + '/reviews/' + idReview + '/like-dislike/' +
      idAction, data);
  }

  deleteLikeDislikeReview(idExperience, idReview, idAction) {
    return this.httpClient.delete(BaseURL + 'experiences/' + idExperience + '/reviews/' + idReview + '/like-dislike/'
      + idAction, {responseType: 'text'});
  }

  // ExperienceFavorite

  addExperienceToFavorites(idExperience,data) {
    return this.httpClient.post(BaseURL + 'experiences/' + idExperience + '/experience-favorite' , data);
  }

  getListExperiencesFavorite(parameters) {
    return this.httpClient.get(BaseURL + 'experiences/experience-favorite',
      {params: parameters});
  }
  getUserExperienceFavorite(idExperience) {
    return this.httpClient.get(BaseURL + 'experiences/' + idExperience + '/experience-favorite');
  }
  deleteExperienceFromFavorites(idExperience) {
    return this.httpClient.delete(BaseURL + 'experiences/' + idExperience + '/experience-favorite',
      {responseType: 'text'});
  }

  // moderate
  moderateExperience(data, id) {
    return this.httpClient.patch(BaseURL + 'experiences/' + id + '/moderate', data);
  }

  moderateTemplate(data, id) {
    return this.httpClient.patch(BaseURL + 'templates/' + id + '/moderate', data);
  }

  moderateExpReview(data, idExperience, idReview) {
    return this.httpClient.patch(BaseURL + 'experiences/' + idExperience + '/reviews/' + idReview + '/moderate', data)
  }

  resetLikeDislikeExpReview(data, idExperience, idReview) {
    return this.httpClient.patch(BaseURL + 'experiences/' + idExperience + '/reviews/' + idReview + '/like-dislike', data);
  }


  // templates
  addTemplate(request, parameters) {
    return this.httpClient.post(BaseURL + 'templates', request, {params: parameters});
  }

  getTemplates(parameters) {
    return this.httpClient.get(BaseURL + 'templates', {params: parameters});
  }

  getDetailTemplates(id) {
    return this.httpClient.get(BaseURL + 'templates/' + id);
  }

  updateTemplate(request, id) {
    return this.httpClient.put(BaseURL + 'templates/' + id, request);
  }

  deleteTemplate(id) {
    return this.httpClient.delete(BaseURL + 'templates/' + id, {responseType: 'text'});
  }

  getTemplateContent(templateId) {
    return this.httpClient.get(BaseURL + 'templates/' + templateId + '/content');
  }

  editTemplateContent(templateId, contentId, content) {
    return this.httpClient.put(BaseURL + 'templates/' + templateId + '/content/' + contentId, content);
  }

  // TemplateFavorite

  addTemplateToFavorites(idTemplate,data) {
    return this.httpClient.post(BaseURL + 'templates/' + idTemplate + '/template-favorite' , data);
  }

  getListTemplatesFavorite(parameters) {
    return this.httpClient.get(BaseURL + 'templates/template-favorite',
      {params: parameters});
  }
  getUserTemplateFavorite(idTemplate) {
    return this.httpClient.get(BaseURL + 'templates/' + idTemplate + '/template-favorite');
  }
  deleteTemplateFromFavorites(idTemplate) {
    return this.httpClient.delete(BaseURL + 'templates/' + idTemplate + '/template-favorite',
      {responseType: 'text'});
  }

  // template review
  addTemplateReview(id, data) {
    return this.httpClient.post(BaseURL + 'templates/' + id + '/reviews', data);
  }

  getTemplateReviews(id, parameters) {
    return this.httpClient.get(BaseURL + 'templates/' + id + '/reviews',
      {params: parameters});
  }

  getDetailsTemplateReview(idReview, idTemplate) {
    return this.httpClient.get(BaseURL + 'templates/' + idTemplate + '/reviews/' + idReview);
  }

  updateTemplateReview(idReview, idTemplate, data) {
    return this.httpClient.put(BaseURL + 'templates/' + idTemplate+ '/reviews/' + idReview, data);
  }

  deleteTemplateReview(idReview, idTemplate) {
    return this.httpClient.delete(BaseURL + 'templates/' + idTemplate + '/reviews/' + idReview,
      {responseType: 'text'});
  }
  getTemplatesReviews(parameters) {
    return this.httpClient.get(BaseURL + 'templates/reviews', {params: parameters});
  }
  resetLikeDislikeTempReview(data, idTemplate, idReview) {
    return this.httpClient.patch(BaseURL + 'templates/' + idTemplate + '/reviews/' + idReview + '/like-dislike', data);
  }
  moderateTempReview(data, idTemplate, idReview) {
    return this.httpClient.patch(BaseURL + 'templates/' + idTemplate + '/reviews/' + idReview + '/moderate', data)
  }

  // like_dislike

  addLikeDislikeTemplateReview(idTemplate, idReview, data) {
    return this.httpClient.post(BaseURL + 'templates/' + idTemplate + '/reviews/' + idReview + '/like-dislike', data
    );
  }

  editLikeDislikeTemplateReview(idTemplate, idReview, idAction, data) {
    return this.httpClient.put(BaseURL + 'templates/' + idTemplate + '/reviews/' + idReview + '/like-dislike/' +
      idAction, data);
  }

  deleteLikeDislikeTemplateReview(idTemplate, idReview, idAction) {
    return this.httpClient.delete(BaseURL + 'templates/' + idTemplate + '/reviews/' + idReview + '/like-dislike/'
      + idAction, {responseType: 'text'});
  }

}
