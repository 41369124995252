<div class="col-md-4">
  <a [routerLink]="link">
    <div class="group-item">
      <div class="img_container lln1">
        <img src="{{data.avatar}}" alt="{{data.name}}" *ngIf="data.avatar"/>
      </div>
      <div class="list-left-near lln2 left">
        <h5>{{data.name}}</h5>
        <span class="collapse-description">{{data.description}}</span>
      </div>
    </div>
  </a>
</div>
