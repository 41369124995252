import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BLANK_TEMPLATE, CREATE_TEMPLATE_PAGE_TITLE} from '../../../utils/actionsLabels/labels';
import {Experience} from '../../interfaces/experience';
import {Template} from '../../interfaces/templates';
import {CREATE_TEMPLATE_PATH} from '../../../utils/actionsLabels/pathLabels';
import {Router} from '@angular/router';

@Component({
  selector: 'app-choose-create-template-form',
  templateUrl: './choose-create-template-form.component.html',
  styleUrls: ['./choose-create-template-form.component.scss']
})
export class ChooseCreateTemplateFormComponent implements OnInit {

  pageTitle = CREATE_TEMPLATE_PAGE_TITLE;
  @Input() userExperiences: Experience[];
  @Input() doneForUserExperiences: boolean;
  @Input() userTemplates: Template [];
  @Input() doneForUserTemplates: boolean;
  @Input() publishedTemplates: Template [];
  @Input() doneForPublishedTemplates: boolean;
  blankTemplate = BLANK_TEMPLATE;
  @Input() carouselOptions;
  @Output() openCreateTemplatePage: EventEmitter<{type: string, source: string, content_id: string}> = new EventEmitter<any>();
  myLibrary: any[];

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    // add attribute 'type' (used in the carousel to know the type of the item)
    this.publishedTemplates.forEach(item => {
     item['type'] = 'template';
    });
    this.userTemplates.forEach(item => {
     item['type'] = 'template';
    });
    this.userExperiences.forEach(item => {
     item['type'] = 'experience';
    });
    // construct an array for my library (my templates + my experiences)
    this.myLibrary = [].concat(this.userTemplates, this.userExperiences);
  }

  onSelect(typeAction, sourceAction, content) {
    this.openCreateTemplatePage.emit({type: typeAction, source: sourceAction, content_id: content});
  }

  useTemplateOrExperience(event) {
    this.openCreateTemplatePage.emit({type: event.type, source: event.source, content_id: event.content_id});
  }

}
