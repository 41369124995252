import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseURLGroup, BaseURLMock} from '../../utils/global-constants/baseUrl';


@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  constructor(private httpClient: HttpClient) {
  }
// ----------------------------------    GROUP    --------------------------------------//
  getGroups(parameters) {
    return this.httpClient.get(BaseURLGroup + 'groups',
      {params: parameters});
  }
  getGroupById(id: string) {
    return this.httpClient.get(BaseURLGroup + 'groups/' + id);
  }
  addGroup(request) {
    return this.httpClient.post(BaseURLGroup + 'groups', request);
  }
  deleteGroup(groupId) {
    return this.httpClient.delete(BaseURLGroup + 'groups/' + groupId,
      {responseType: 'text'});
  }
  updateGroup(request, groupId: string) {
    return this.httpClient.put(BaseURLGroup + 'groups/' + groupId, request);
  }


// ----------------------------------    GROUP - MEMBERS   --------------------------------------//

  getGroupMembers(id: string, parameters?) {
    return this.httpClient.get(BaseURLGroup + 'groups/' + id + '/members', {params: parameters});
  }
  leaveGroup(groupId: string, memberId: string) {
    return this.httpClient.delete(BaseURLGroup + 'groups/' + groupId + '/members/' + memberId,
      { responseType: 'text'});
  }
  deleteMembers(request, groupId: string) {
    return this.httpClient.patch(BaseURLGroup + 'groups/' + groupId + '/members' , request);
  }
  askJoinGroup(groupId: string) {
    return this.httpClient.post(BaseURLGroup + 'groups/' + groupId + '/join-requests' , '');
  }
  inviteGroupMembers(members, groupId: string) {
    return this.httpClient.post(BaseURLGroup + 'groups/' + groupId + '/' + 'invited-members', members);
  }
  addGroupMember(keyCode, groupId: string) {
    return this.httpClient.post(BaseURLGroup + 'groups/' + groupId + '/' + 'members', keyCode);
  }
  getInvitationByCode(keyCode) {
    return this.httpClient.get(BaseURLGroup + 'invitations/' + keyCode);
  }
  getRequestJoinByCode(keyCode) {
    return this.httpClient.get(BaseURLGroup + 'requests/' + keyCode);
  }
  getRequestJoinByGroupID(groupId, userId) {
    return this.httpClient.get(BaseURLGroup + 'groups/' + groupId + '/join-requests/' + userId);
  }
  addGroupMembers(members, groupId: string) {
    return this.httpClient.post(BaseURLGroup + 'groups/' + groupId + '/' + 'members', members);
  }
  refuseInvitation(groupId, memberId, data) {
    return this.httpClient.patch(BaseURLGroup + '/groups/' + groupId + '/invited-members/' + memberId, data);
  }
  refuseJoinRequest(groupId, memberId, data) {
    return this.httpClient.patch(BaseURLGroup + '/groups/' + groupId + '/join-requests/' + memberId, data);
  }


// ----------------------------------    GROUP - EXPERIENCES   --------------------------------------//

  getGroupExperiences(id: string, parameters?) {
    return this.httpClient.get(BaseURLGroup + 'groups/' + id + '/experiences', {params: parameters});
  }
  addExperiencesToGroup(groupId: string, experiences) {
    return this.httpClient.post(BaseURLGroup + 'groups/' + groupId + '/experiences', experiences);
  }
  deleteExperiences(request, groupId: string) {
    return this.httpClient.patch(BaseURLGroup + 'groups' + '/' + groupId + '/experiences' , request);
  }


  // ==================================================================> still using mockserver
  getUserSuggestedGroups(userId: string) {
    return this.httpClient.get(BaseURLMock + 'users/' + userId + '/suggested');
  }

  searchMembersLike(term, members) {
    return this.httpClient.get(BaseURLMock + 'users?term=' + term + '&members=' + members);
  }

  // ----------------------------------    GROUP - My favorites   --------------------------------------//

  addGroupToFavorites(idGroup,data) {
    return this.httpClient.post(BaseURLGroup + 'groups/' + idGroup + '/group-favorite' , data);
  }

  getListGroupFavorite(parameters) {
    return this.httpClient.get(BaseURLGroup + 'groups/group-favorite',
      {params: parameters});
  }
  getUserGroupFavorite(idGroup) {
    return this.httpClient.get(BaseURLGroup + 'groups/' + idGroup + '/group-favorite');
  }
  deleteGroupFromFavorites(idGroup) {
    return this.httpClient.delete(BaseURLGroup + 'groups/' + idGroup + '/group-favorite',
      {responseType: 'text'});
  }

}
