import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GroupsService} from '../../../services/groups.service';
import {TokenHelperService} from '../../../services/token-helper.service';
import {NotificationService} from '../../../services/notification.service';
import {PAGE_TITLE_PREFIX_EDIT_MY_GROUP, SUCCESS_EDIT_GROUP_MESSAGE_TITLE} from '../../../../utils/actionsLabels/labels';
import {Title} from '@angular/platform-browser';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-edit-group-section',
  templateUrl: './edit-group-section.component.html',
  styleUrls: ['./edit-group-section.component.scss', '../../../../../assets/customCss/waiting-load.scss']
})
export class EditGroupSectionComponent implements OnInit {
  waitingLoad = false;
  groupId: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private groupsService: GroupsService,
              private titleService: Title,
              private translate: TranslateService,
              private tokenHelperService: TokenHelperService,
              private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    // this.titleService.setTitle(PAGE_TITLE_PREFIX_EDIT_MY_GROUP);
    this.translate.get(PAGE_TITLE_PREFIX_EDIT_MY_GROUP).subscribe((res: string) => {
      this.titleService.setTitle(res);
    });
    this.groupId = this.route.snapshot.paramMap.get('id');
  }

  readData(event: any) {
    this.waitingLoad = true;
    const formData = this.createFormData(event);
    this.sendEditRequest(formData);
  }

  createFormData(event) {
    const formData = new FormData();
    // sent group object data
    formData.append('avatar', event.data.iconPicture);
    formData.append('coverPicture', event.data.coverPicture);
    formData.append('avatarDisplayName', event.data.iconPictureDisplayName);
    formData.append('coverPictureDisplayName', event.data.coverPictureDisplayName);
    formData.append('name', event.data.name);
    formData.append('description', event.data.description);
    formData.append('type', event.data.privacy);
    // send file if exist
    if (event.coverPicture) {
      formData.append('coverPictureFile', event.coverPicture);
    }
    if (event.avatar) {
      formData.append('avatarFile', event.avatar);
    }
    return formData;
  }

  sendEditRequest(formData) {
    this.groupsService.updateGroup(formData, this.groupId).subscribe((data) => {
      this.router.navigateByUrl('/groups/' + this.groupId).then();
      this.notificationService.showSuccess('', SUCCESS_EDIT_GROUP_MESSAGE_TITLE);
    });
  }
}
