import {Component, OnInit} from '@angular/core';
import {User} from '../../../interfaces/user';
import {
  ASK_FOR_CONFIRM_DELETE_GROUP_ACTION,
  LABEL_MY_GROUPS,
  MY_GROUPS_PAGE_TITLE,
  SUCCESS_DELETE_GROUP_MESSAGE_TITLE,
  SUCCESS_LEAVE_GROUP_TITLE
} from '../../../../utils/actionsLabels/labels';
import {Group} from '../../../interfaces/group';
import {CookieService} from 'ngx-cookie-service';
import {UsersService} from '../../../services/users.service';
import {GroupsService} from '../../../services/groups.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {TokenHelperService} from '../../../services/token-helper.service';
import {HttpParams} from '@angular/common/http';
import {CREATOR_GROUP, MEMBER_GROUP, ROLE_GROUP} from '../../../../utils/queryParams/groupsQueryParams';
import {ConfirmDialogService} from '../../confirm-dialog/confirm-dialog.service';
import {NotificationService} from '../../../services/notification.service';
import {RoleGroup} from '../../../../utils/global-constants/groups-const';
import {AuthGuard} from '../../../_helpers';
import {map} from 'rxjs/operators';
import {LeaveGroupDialogComponent} from "../../popups/leave-group-dialog/leave-group-dialog.component";
import {MatDialog} from "@angular/material/dialog";


@Component({
  selector: 'app-my-groups-section',
  templateUrl: './my-groups-section.component.html',
  styleUrls: ['./my-groups-section.component.scss', '../../../../../assets/customCss/waiting-load.scss']
})
export class MyGroupsSectionComponent implements OnInit {

  user: User;
  pageTitle = LABEL_MY_GROUPS;
  groupsIManage: Group[];
  groupsIAmMember: Group[];
  doneGroupsIManage = false;
  doneGroupsIAmMember = false;

  getGroupIManageResponse = false;
  getGroupIAmMemberResponse = false;
  userId: string;
  disabledButton = false;
  dialogRef;
  destroyComponent = false;
  constructor(private cookieService: CookieService,
              private usersService: UsersService,
              private groupsService: GroupsService,
              private router: Router,
              private route: ActivatedRoute,
              private titleService: Title,
              private tokenHelperService: TokenHelperService,
              private authGuard: AuthGuard,
              private notificationService: NotificationService,
              private confirmDialogService: ConfirmDialogService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.titleService.setTitle(MY_GROUPS_PAGE_TITLE);
    this.route.paramMap.pipe(
      map(params => {
          // this.userId = this.tokenHelperService.getTokenData().user_id;
        }
      )
    ).subscribe(() => {
    });
    if (this.authGuard.canActivate) {
      this.getProfile();
    }
  }
  ngOnDestroy(): void {
    this.destroyComponent = true;
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  getProfile() {
    this.usersService.getUserProfile().subscribe((data: User) => {
      this.user = data;
      this.userId = data.id;
      this.getGroupsUserManages();
      this.getGroupsUserMember();
    });
  }

  getGroupsUserManages() {
    let paramsGroups = new HttpParams();
    paramsGroups = paramsGroups.append(ROLE_GROUP, RoleGroup.MANAGER);
    paramsGroups = paramsGroups.append(CREATOR_GROUP, this.user.id);
    this.groupsService.getGroups(paramsGroups).subscribe((data: Group []) => {
      this.groupsIManage = data;
      this.doneGroupsIManage = true;
      this.disabledButton = false;
      this.getGroupIManageResponse = true;
    }, (error => {
      this.getGroupIManageResponse = true;
    }));
  }

  getGroupsUserMember() {
    let params = new HttpParams();
    params = params.append(ROLE_GROUP, RoleGroup.MEMBER);
    params = params.append(MEMBER_GROUP, '' + this.user.id);
    this.groupsService.getGroups(params).subscribe((data: Group []) => {
      this.groupsIAmMember = data;
      this.doneGroupsIAmMember = true;
      this.getGroupIAmMemberResponse = true;
      this.disabledButton = false;
    }, (error => {
      this.getGroupIAmMemberResponse = true;
    }));
  }

  DeleteGroup(event) {
    this.confirmDialogService.confirmThis(ASK_FOR_CONFIRM_DELETE_GROUP_ACTION + '"' + event.name + '"?', () => {
      this.disabledButton = true;
      this.groupsService.deleteGroup(event.id).subscribe(() => {
        this.notificationService.showSuccess('', SUCCESS_DELETE_GROUP_MESSAGE_TITLE);
        this.getGroupsUserManages();
      });
    }, () => {
    });
  }

  leaveGroup(event) {
    if (this.destroyComponent !== true) { // prevent popup from appearing on other pages
      this.dialogRef = this.dialog.open(LeaveGroupDialogComponent, {
        disableClose: false,
        autoFocus: true,
        panelClass: 'my-dialog',
        width: '750px',
        maxHeight: '500px',
        data: {name: event.name_group}
      });
      this.dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.disabledButton = true;
          this.groupsService.leaveGroup(event.id_group, this.userId).subscribe(() => {
            this.notificationService.showSuccess('', SUCCESS_LEAVE_GROUP_TITLE);
            this.getGroupsUserMember();
          });
        }
      });
    }
  }
}
