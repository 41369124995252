import {Component, OnInit} from '@angular/core';
import {User} from '../../reusables/interfaces/user';
import {map} from 'rxjs/operators';
import {
  EDIT_ANALYTICS_ID_PAGE_TITLE, EDIT_EXPERIENCE_BUTTON, EDIT_EXPERIENCE_BUTTON_TITLE,
  PAGE_TITLE_PREFIX
} from '../../utils/actionsLabels/labels';
import {ExperiencesService} from '../../reusables/services/experiences.service';
import {NotificationService} from '../../reusables/services/notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from '../../reusables/services/users.service';
import {Title} from '@angular/platform-browser';
import {AuthGuard} from '../../reusables/_helpers';
import {Experience} from '../../reusables/interfaces/experience';
import {FormBuilder, FormGroup} from '@angular/forms';


@Component({
  selector: 'app-edit-analytics',
  templateUrl: './edit-analytics.component.html',
  styleUrls: ['./edit-analytics.component.scss']
})
export class EditAnalyticsComponent implements OnInit {
  experience: Experience;
  currentExperienceId = '';
  doneForUserProfile = false;
  user: User;
  waitingLoad = false;
  myForm: FormGroup;
  name: string;
  buttonTextTitle = EDIT_EXPERIENCE_BUTTON_TITLE;
  buttonText = EDIT_EXPERIENCE_BUTTON;
  pageTitle = EDIT_ANALYTICS_ID_PAGE_TITLE;

  constructor(private experiencesService: ExperiencesService,
              private formBuilder: FormBuilder,
              private notificationService: NotificationService,
              private router: Router,
              private route: ActivatedRoute,
              private usersService: UsersService,
              private titleService: Title,
              private authGuard: AuthGuard) {
    this.myForm = this.formBuilder.group({analyticsId: [''],});
  }

  ngOnInit(): void {
    if (this.authGuard.canActivate) {
      this.route.paramMap.pipe(
        map(params => {
          this.currentExperienceId = params.get('id');
          if (this.currentExperienceId && this.currentExperienceId !== '') {
            this.titleService.setTitle(PAGE_TITLE_PREFIX + EDIT_ANALYTICS_ID_PAGE_TITLE);
            this.getExperienceDetails(this.currentExperienceId);
          }
        })
      ).subscribe(() => {
      });
    }
  }

  getProfile() {
    this.usersService.getUserProfile().subscribe((data: User) => {
      this.user = data;
      this.doneForUserProfile = true;
    });
  }

  getExperienceDetails(id) {
    this.experiencesService.getDetailExperiences(id).toPromise().then((data: Experience) => {
      this.experience = data;
      this.name = this.experience.name;
      this.getProfile();
      this.initForm(this.experience);
    });
  }
  resetForm() {
    if (this.experience && this.experience.id !== '') {
      this.initForm(this.experience);
      window.history.back();
    } else {
      this.myForm.reset();
    }
  }
  initForm(exp: Experience) {
    this.myForm.get('analyticsId').setValue(exp.analyticsId);
  }

  onSubmit(data) {
    const dataRequest = {analyticsId: data.analyticsId };
    this.readData(dataRequest);
  }

  readData(event) {
    this.waitingLoad = true;
    this.experiencesService.updateExperience(this.createFormData(event), this.currentExperienceId)
      .subscribe((data) => {
        this.waitingLoad = true;
        window.history.back();
      }, (error => {
        this.waitingLoad = false;
      }));
  }

  createFormData(event) {
    const formData = new FormData();
    formData.append('analyticsId', event.analyticsId);
    formData.append('groups', JSON.stringify(""));
    return formData;
  }

  analytics(){
    this.getExperienceDetails(this.currentExperienceId);
    if(this.experience.analyticsId != "") {
      window.open("https://analytics.google.com/", "_blank");
    }
  }
}

