import {Component, Input, OnInit} from '@angular/core';
import {Group} from '../../../interfaces/group';

@Component({
  selector: 'app-group-details-header',
  templateUrl: './group-details-header.component.html',
  styleUrls: ['./group-details-header.component.scss']
})
export class GroupDetailsHeaderComponent implements OnInit {

  @Input() details: Group;

  constructor() {
  }

  ngOnInit(): void {
  }

}
