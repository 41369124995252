<div class="tz-2-com tz-2-main">
  <h4>{{pageTitle  | translate }}</h4>
  <div class="db-list-com tz-db-table">
      <div class="hom-cre-acc-left hom-cre-acc-right">

      <!----------------------------  create blank template section  ------------------------------>
        <div class="row">
           <div class="db-v2-list-form-inn-tit">
              <h5>{{"TEMPLATE_BLANK" | translate}}</h5>
           </div>
        </div>
        <div>
           <a (click)="onSelect(blankTemplate, '', '')">
              <div class="blank-template-btn">
                 <div class="custom-icon-container">
                    <i class="fa fa-plus" aria-hidden="true"></i> {{"Create" | translate }}
                 </div>
              </div>
           </a>
        </div>
      <!----------------------------  end create blank template section  ------------------------------>
      <!----------------------------  published template section  ------------------------------>
        <div class="row">
           <div class="db-v2-list-form-inn-tit">
              <h5>{{"FROM_PUBLISHED_TEMPLATES" | translate}}</h5>
           </div>
        </div>
        <!------------ no published templates --------->
        <div class="tz-2-com tz-2-main" *ngIf="publishedTemplates.length == 0">
          <div class="center no_data_message">
            <div class="icon-content"><i class="fas fa-cubes"></i></div>
              {{"NO_PUBLISEHD_TEMPLATE_MESSAGE" | translate}}
          </div>
        </div>
        <!------------ end no published templates --------->
        <!------------ published templates exist --------->
          <!----------------------------  carousel  ------------------------------>
          <owl-carousel *ngIf="publishedTemplates.length != 0" [options]="carouselOptions" [carouselClasses]="['owl-theme', 'row', 'sliding']">
             <app-experience-template-single *ngFor="let item of publishedTemplates;let i= index" [data]="item" (request)="useTemplateOrExperience($event)">
             </app-experience-template-single>
          </owl-carousel>
          <!----------------------------  end carousel  ------------------------------>
        <!------------ published templates exist --------->
      <!----------------------------  end published template section  ------------------------------>
      <!----------------------------  my library section  ------------------------------>
        <div class="row">
           <div class="db-v2-list-form-inn-tit text-item">
              <h5>{{"FROM_MY_LIBRARY" | translate}}</h5>
           </div>
        </div>
        <!------------------- no library --------------->
        <div class="tz-2-com tz-2-main" *ngIf="myLibrary.length == 0">
          <div class="center no_data_message">
            <div class="icon-content"><i class="fas fa-cubes"></i></div>
             {{"do_not_have_templates_experiences_msg" | translate}}
          </div>
        </div>
        <!----------------- end no library --------------->
        <!------------------- library exist --------------->
          <!----------------------------  carousel  ------------------------------>
          <owl-carousel *ngIf="myLibrary.length != 0" [options]="carouselOptions" [carouselClasses]="['owl-theme', 'row', 'sliding']">
             <app-experience-template-single *ngFor="let item of myLibrary;let i= index" [data]="item" (request)="useTemplateOrExperience($event)">
             </app-experience-template-single>
          </owl-carousel>
          <!----------------------------  end carousel  ------------------------------>
        <!------------------- end library exist --------------->
      <!----------------------------  end my library section  ------------------------------>

      </div>
  </div>
</div>
