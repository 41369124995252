import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Experience} from '../../../interfaces/experience';

@Component({
  selector: 'app-add-experience-dialog',
  templateUrl: './add-experience-dialog.component.html',
  styleUrls: ['./add-experience-dialog.component.scss']
})
export class AddExperienceDialogComponent implements OnInit {
  form: FormGroup;

  constructor(public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      exp: new FormControl(null, [Validators.required]),
    });
  }

  onAdd() {
    this.dialogRef.close(this.form.controls.exp.value);
  }

  cancel() {
    this.dialogRef.close();
  }

  onSelect(item: Experience) {
    if (item !== this.form.controls.exp.value) {
      this.form.controls.exp.setValue(item);
    } else {
      this.form.controls.exp.setValue(null);
    }
  }
}
