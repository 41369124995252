<div class="experience-details-container" *ngIf="doneDetails">
  <app-experience-status [status]="statusNumber" *ngIf="displayEditFixedMenu || displayValidateFixedMenu" ></app-experience-status>
  <!------------------------------- menu --------------------------------->
  <section>
    <app-details-menu (scrollTo)="scrollTo($event)" [menu]="menu"
                      [activeSubMenu]="activeSubMenu"></app-details-menu>
  </section>
  <!----------------------------- end menu --------------------------------->
  <!---------------------- cover picture + content -------------------------------->
  <section class="pg-list-1" [ngStyle]="{'background': coverTemplate}" *ngIf="doneDetails">
    <app-details-header [details]="template"></app-details-header>
    <div class="rating-content" *ngIf="template.rating > 0">
      <app-rating [rating]="template.rating"></app-rating>
    </div>
  </section>
  <!--------------------- end cover picture + content -------------------------------->
  <!--------------------------- details template -------------------------------->
  <section class="list-pg-bg">
    <div class="container">
      <div class="row">
        <div class="com-padd">
          <!------------------------- waiting details template ------------------------>
          <div class="row  waiting-get-data " *ngIf=" !pageStructure.left || pageStructure.left.length === 0 ||
                                                      !pageStructure.right || pageStructure.right.length === 0">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
          <!----------------------- end waiting details template ----------------------->
          <!---****************************** left side start **********************************************---->
          <div class="list-pg-lt list-page-com-p" *ngIf="pageStructure.left && pageStructure.left.length !== 0">
            <div *ngFor="let section of pageStructure.left">
              <!---------------------------------- Section About ------------------------------------------------------->
              <app-details-about *ngIf="section.type === 'about-section'" id="about"
                                 [source]="'template'"
                                 [details]="template"
                                 (useTemplate)="useTemplate($event)"
                                 (shareOnFacebook)="shareFacebook($event)"
                                 (shareOnTwitter)="shareTwitter($event)"
                                 (shareOnLinkedin)="shareLinkedin($event)"
                                 [etatTemplateFavorite]="etatTemplateFavorite"
                                 [disabledButtonFavorite]="disabledButtonFavorite"
                                 (request)="sendData($event)" ></app-details-about>
              <!-------------------------------- Section Gallery ------------------------------------------------------->
              <app-details-gallery id="gallery" [gallery]="gallery" [source]="'template'" [video]="video"
                                   *ngIf="section.type == 'gallery-section' && (gallery && gallery.length !== 0 || video)"
                                 (openVideo)="openVideo($event)"></app-details-gallery>
              <!-------------------------------- Section write review ------------------------------------------------------->
              <app-details-write-review id="write_review" *ngIf="section.type === 'write-review-section'" [connectedUser]="user"
                                        (request)="sendData($event)"
                                        [singleReview]="singleReview"
                                        (cancelEditReviewEvent) = "cancelEditReview()"
                                        ></app-details-write-review>
              <!-------------------------------- Section own review ------------------------------------------------------->
              <app-own-review id="my_review" *ngIf="section.type === 'own-review-section' && ownReview" [connectedUser]="user"
                              [likes]="LikeMap" [dislikes]="DislikeMap" [etatLike]="etatLike" [etatDislike]="etatDislike"
                              [review]="myReview" (reviewData)="sendData($event)" ></app-own-review>
              <!-------------------------------- Section rating and reviews -------------------------------------------->
              <app-details-reviews id="user_review" *ngIf="section.type === 'rating-reviews-section' && reviewsAreLoaded"
                                   [rating]="template.rating" [reviews]="reviews" [reviewsCounter]="reviewsCounter"
                                   [likes]="LikeMap" [dislikes]="DislikeMap" [etatLike]="etatLike" [etatDislike]="etatDislike"
                                   (review)="sendData($event)" ></app-details-reviews>
            </div>
          </div>
          <!--********************************************** left side end **********************************************--->
          <!---****************************** right side start **********************************************---->
           <div class="list-pg-rt" *ngIf="pageStructure.right && pageStructure.right.length !== 0">
            <div  *ngFor="let section of pageStructure.right">
              <!---------------------------------- Section Creator ----------------------------------------------------->
              <app-details-contact-creator *ngIf="section.type === 'creator-section' && templateCreator && templateCreator.userId"
                                           [data]="templateCreator"></app-details-contact-creator>
              <!---------------------------------- Section Used -------------------------------------------------------->
              <div class="pglist-p3 pglist-bg pglist-p-com" *ngIf="section.type === 'used-section'">
                <div class="used-section">
                  <p>
                    <i class="far fa-clone"></i> {{template.number_uses}} {{'times used' | translate}}
                  </p>
                </div>
              </div>
              <!---------------------------- Section suggested templates -------------------------------------------------->
              <div *ngIf="section.type == 'suggested-groups-section'">
                <app-details-suggestions *ngIf="doneSuggestedTemplates === true && suggestedTemplates.length > 0"
                                       [suggestedTemplates]="suggestedTemplates"
                                       [source]="'template'"></app-details-suggestions>
              </div>
            </div>
           </div>
          <!--********************************************* right side end **********************************************--->
        </div>
      </div>
    </div>
  </section>
  <!-------------------------- end details template -------------------------------->
  <app-fixed-menu-of-connected-user *ngIf="displayEditFixedMenu || displayValidateFixedMenu" [id]="template.id" [source]="'template'"
                                    [displayValidateFixedMenu]="displayValidateFixedMenu"
                                    (request)="validateTemp()" [disabledButton]="disabledButton"></app-fixed-menu-of-connected-user>
</div>

<app-popup-experience-video [isOpened]="videoStatus" [data]="videoData" (readingStatus)="refreshPopupStatus($event)"
                            *ngIf="videoData"></app-popup-experience-video>
<app-confirm-dialog></app-confirm-dialog>


<div class="row waiting_load" *ngIf="waitingLoad">
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
</div>
