<div class="pglist-p3 pglist-bg pglist-p-com" id="ld-gal" *ngIf="gallery && gallery.length  !== 0 || video">
  <div class="pglist-p-com-ti">
    <h3><span>{{ "GALLERY" | translate}}</span> </h3>
  </div>
  <div class="list-pg-inn-sp">
    <div id="myCarousel" class="carousel slide" data-ride="carousel">
      <ol class="carousel-indicators">
          <li *ngFor="let item of gallery; let i = index;"  data-target="#myCarousel"  [attr.data-slide-to]="i"  [ngClass]="i > 0 ? '': 'active'" ></li>
          <li  *ngIf="video" data-target="#myCarousel"  [attr.data-slide-to]="gallery.length" [ngClass]="gallery.length > 0 ? '': 'active'"></li>
      </ol>
      <!-- Wrapper for slides -->
      <div class="carousel-inner">
        <div *ngFor="let item of gallery; let i = index;" class="item" [ngClass]="i > 0 ? '': 'active'" >
          <img *ngIf="source=='experience'"src="{{item.url}}" />
          <img *ngIf="source=='template'" src="{{item.uri}}" />
        </div>
        <div *ngIf="video" class="item " [ngClass]="gallery.length > 0 ? '': 'active'">
          <img  src="assets/images/play.png"  style="background: url({{thumbnail}}) ; background-repeat: no-repeat; background-position: center" (click)="addAction(video)" class="hasAction" />
        </div>
      </div>
      <!-- Left and right controls -->
      <a class="left carousel-control" href="#myCarousel" data-slide="prev"> <i class="fa fa-angle-left list-slider-nav" aria-hidden="true"></i> </a>
      <a class="right carousel-control" href="#myCarousel" data-slide="next"> <i class="fa fa-angle-right list-slider-nav list-slider-nav-rp" aria-hidden="true"></i> </a>
    </div>


  </div>
</div>
