<div style="display: block;  margin-top: 250px; width: 500px">
  <div class="chartjs-container">
    <canvas baseChart
            [datasets]="lineChartData"
            [labels]="lineChartLabels"
            [options]="lineChartOptions"
            [colors]="lineChartColors"
            [legend]="lineChartLegend"
            [chartType]="lineChartType"
            [plugins]="lineChartPlugins"
            (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)">
    </canvas>
  </div>
</div>
