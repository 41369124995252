import {Component, Input, OnInit} from '@angular/core';
import {TEMPLATES_PATH} from '../../../utils/actionsLabels/pathLabels';
import {Template} from '../../interfaces/templates';

@Component({
  selector: 'app-single-suggested-template',
  templateUrl: './single-suggested-template.component.html',
  styleUrls: ['./single-suggested-template.component.scss']
})
export class SingleSuggestedTemplateComponent implements OnInit {
  coverTemplate = '#233951';
  @Input() data: Template;
  link: string;
  templateLink = TEMPLATES_PATH;
  constructor() { }

  ngOnInit(): void {
    if (this.data.cover_picture !== '') {
      this.coverTemplate = 'url(' + this.data.cover_picture + ') no-repeat center bottom';
    }
    this.link =  '/' + this.templateLink  + '/' + this.data.id;
  }

}
