// actions to do after reading error.status
import {
  CONFLICT_TITLE,
  FORBIDDEN_CONTENT,
  FORBIDDEN_PATH,
  FORBIDDEN_TITLE,
  NOT_FOUND_CONTENT,
  NOT_FOUND_PATH,
  NOT_FOUND_TITLE,
  OOPS_CONTENT,
  OOPS_PATH,
  OOPS_TITLE,
  UNAUTHORISED_PATH,
  UNAUTHORIZED_CONTENT,
  UNAUTHORIZED_TITLE
} from './errors-texts';

export const actionsErrorsManager = ['redirection', 'notification', 'access_denied'];
export const errorsManager = [
  {
    status: 401,
    action: actionsErrorsManager[2],
    title: UNAUTHORIZED_TITLE,
    content: UNAUTHORIZED_CONTENT,
    path: UNAUTHORISED_PATH
  }, {
    status: 403,
    action: actionsErrorsManager[0],
    title: FORBIDDEN_TITLE,
    content: FORBIDDEN_CONTENT,
    path: FORBIDDEN_PATH
  }, {
    status: 404,
    action: actionsErrorsManager[0],
    title: NOT_FOUND_TITLE,
    content: NOT_FOUND_CONTENT,
    path: NOT_FOUND_PATH
  }, {
    status: 409,
    action: actionsErrorsManager[1],
    title: CONFLICT_TITLE
  }, {
    action: actionsErrorsManager[0],
    title: OOPS_TITLE,
    content: OOPS_CONTENT,
    path: OOPS_PATH
  }
];
