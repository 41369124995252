import * as BABYLON from 'babylonjs';
import {NgZone} from '@angular/core';

import * as MATERIEL from 'babylonjs-materials';
import {EditorElementType} from '../../utils/global-constants/editor-const';

export function addCamera(canvas: HTMLCanvasElement, scene: BABYLON.Scene, cameraName: string) {
  const camera = new BABYLON.ArcRotateCamera(cameraName, -Math.PI / 2, Math.PI / 3, 2000, new BABYLON.Vector3(0, 0,0), scene);
  // add control sensibility
  camera.angularSensibilityX = 8000;
  camera.angularSensibilityY = 8000;
  // add control camera
  camera.upperBetaLimit = Math.PI / 2 * 0.98;
  camera._panningMouseButton = 2;
  camera.panningSensibility = 30;
  camera.speed = 0.10;
  camera.maxZ = 100000;
  camera.attachControl(canvas, true);
  return camera;
}
export function addlight(scene: BABYLON.Scene) {
  const light = new BABYLON.HemisphericLight('light1', new BABYLON.Vector3(0, 1, 0), scene);
  light.groundColor = new BABYLON.Color3(.3, .3, .3);
  light.intensity = .9;

  const light2 = new BABYLON.HemisphericLight('light1', new BABYLON.Vector3(0, 1, 0), scene);
  light2.groundColor = new BABYLON.Color3(.3, .3, .3);
  light2.intensity = .9;
}

export function addGround(scene: BABYLON.Scene){
  const groundMaterial = new MATERIEL.GridMaterial('groundMaterial', scene);
  groundMaterial.majorUnitFrequency = 30;
  groundMaterial.minorUnitVisibility = 0.1;
  groundMaterial.gridRatio = 5;
  groundMaterial.backFaceCulling = false;
  groundMaterial.lineColor = new BABYLON.Color3(1.5, 1.5, 1.5);
  groundMaterial.opacity = 0.9;
  const ground = BABYLON.Mesh.CreateGround('ground1', 100000, 100000, 2, scene);
  ground.material = groundMaterial;
  ground.isPickable = false;
  ground.renderingGroupId = 0;
  return ground;
}

export function animate(scene: BABYLON.Scene, engine: BABYLON.Engine, ngZone: NgZone): void {
      ngZone.runOutsideAngular(() => {
        const rendererLoopCallback = () => {
          scene.render();
        };
        if (document.readyState !== 'loading') {
          engine.runRenderLoop(rendererLoopCallback);
        } else {
          window.addEventListener('DOMContentLoaded', () => {
            engine.runRenderLoop(rendererLoopCallback);
          });
        }
        window.addEventListener('resize', () => {
          engine.resize();
        });
      });
}

// disabled Ctrl+ and Ctrl-
export function DisabledZoom(scene: BABYLON.Scene, canvas: HTMLCanvasElement, camera) {
  scene.onPrePointerObservable.add( (evt) => {
    if (evt.event.ctrlKey) {
      evt.event.preventDefault();
    }
  }, BABYLON.PointerEventTypes.POINTERWHEEL);
}


export function zoomAfterAdd(mesh, camera, scene, engine) {
  if (!(mesh instanceof BABYLON.Mesh)) {
    const object = mesh;
    mesh = scene.meshes.find(m => m.uniqueId === object.uniqueId);
  }
  const radius = mesh.getBoundingInfo().boundingSphere.radiusWorld;
  const aspectRatio = engine.getAspectRatio(camera);
  let halfMinFov = camera.fov / 2;
  if (aspectRatio < 1) {
    halfMinFov = Math.atan(aspectRatio * Math.tan(camera.fov / 2));
  }
  camera.setPosition(new BABYLON.Vector3(mesh.position.x, mesh.position.y, camera.position.z));
  camera.target = new BABYLON.Vector3(mesh.position.x + 1, mesh.position.y + 1, mesh.position.z + 1);
  camera.beta = Math.PI / 3;
  switch (mesh.name) {
    case 'text':
      camera.radius = Math.abs(radius / Math.sin(halfMinFov)) + 500;
      break;
    case EditorElementType.surface.toString():
      camera.radius = Math.abs(radius / Math.sin(halfMinFov));
      break;
  }
}

