import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {
  FIRST_MENU_EDITOR_GEO_POSITION,
  FIRST_MENU_EDITOR_MARKER,
  FIRST_MENU_EDITOR_SURFACE,
} from '../../../../utils/actionsLabels/labels';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {VideoUrlDialogComponent} from '../../popups/video-url-dialog/video-url-dialog.component';
import {MarkerNameDialogComponent} from '../../popups/marker-name-dialog/marker-name-dialog.component';
import {extensionImageComponent} from '../../../../utils/global-constants/forHTML';

import {ImportAssetDialogComponent} from '../../popups/import-asset-dialog/import-asset-dialog.component';
import {AssetObject} from '../../../interfaces/assets-library';
import {LibraryService} from '../../../services/library.service';

import {TokenHelperService} from '../../../services/token-helper.service';
import {
  EditorElementType,
  IMPORT_ASSET_DIALOG_HEIGHT,
  IMPORT_ASSET_DIALOG_WIDTH,
  IMPORT_AUDIO_ASSET_DIALOG,
  IMPORT_IMAGE_ASSET_DIALOG,
  IMPORT_MARKER_DIALOG,
  IMPORT_OBJECT_3D_ASSET_DIALOG
} from '../../../../utils/global-constants/editor-const';
import {IMPORT_AUDIO, IMPORT_IMAGE, IMPORT_MARKER, IMPORT_OBJECT_3D} from '../../../../utils/actionsLabels/editorLabels';

@Component({
  selector: 'app-first-menu',
  templateUrl: './first-menu.component.html',
  styleUrls: ['./first-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FirstMenuComponent implements OnInit, OnChanges, OnDestroy {

  firstMenuEditor = null;
  isToggled = false;
  openElementFirstMenu = null;
  canOpenMarkerName = false;
  // ** ont même rôle:  contrôler l'élément qui doit être sélectionné****/
  selectedElementFirstMenu;
  @Output() selectedElementFirstMenuEvent = new EventEmitter(); // pour changer selectedElementFirstMenuEditor
  @Input() selectedElementFirstMenuEditor; // pour editor
  // **********/
  @Input() publish;
  @Input() videoValid;
  @Input() urlsValid;
  @Input() selectedTrigger;

  @Input() triggerType ;
  @Input() openTriggerType: boolean;

  @Output() addNew: EventEmitter<{  type?: string,
    asset?: AssetObject
    url?: string,
    isMenuToggled?: boolean,
    mediaType?: string,
    file?: any}> = new EventEmitter<any>();
  @Output() addMarker: EventEmitter<any> = new EventEmitter<any>();
  @Output() addGeoPosition: EventEmitter<any> = new EventEmitter<any>();
  @Output() publishEvent = new EventEmitter();
  dialogRef: MatDialogRef<any>; // popup affichée
  extensionImageComponent = extensionImageComponent;

  // connected user Id
  userId: string;
  // if component is destroyed
  destroyComponent = false;

  constructor(public dialog: MatDialog,
              private libraryService: LibraryService,
              private tokenHelperService: TokenHelperService) {
  }
  ngOnDestroy(): void {
    this.destroyComponent = true;
    if (this.dialogRef) {this.dialogRef.close(); }
  }
  ngOnInit(): void {
    this.dialog.closeAll();
    // if il y a  un marker selectionné => function != null (css class = disabled) => Components not disabled
    if (this.firstMenuEditor) {
      this.firstMenuEditor.find(element => element.title === 'Components').function = this.selectedTrigger;
    }
    this.userId = this.tokenHelperService.getTokenData().user_id;
    this.getFirstMenuEditor();

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedTrigger  ) {
      if (this.firstMenuEditor) {
        this.firstMenuEditor.find(element => element.title === 'Components').function = this.selectedTrigger;
      }
      if (this.selectedTrigger === null) {
        this.openElementFirstMenu = null;
      }
    }
    if (changes.videoValid || changes.urlsValid || changes.publish) {
      if (this.firstMenuEditor) {
        this.firstMenuEditor.find(element => element.title === 'Save').function = (this.publish && this.videoValid && this.urlsValid);
      }
      if (this.publish === false && this.videoValid === false && this.urlsValid === false) {
        // si on fait un changement et selectedPrincipalElement = publish => publish devient selected
        this.selectedElementFirstMenu = null;
      }
    }
    // if (changes.publish) {
    //   if (this.firstMenuEditor) {
    //     this.firstMenuEditor.find(element => element.title === 'Save').function = (this.publish);
    //   }
    //   if (this.publish === false) {
    //     // si on fait un changement et selectedPrincipalElement = publish => publish devient selected
    //     this.selectedElementFirstMenu = null;
    //   }
    // }
    if (changes.triggerType) {
      this.getFirstMenuEditor();
    }
    if (changes.selectedElementFirstMenuEditor) {
      this.selectedElementFirstMenu = this.selectedElementFirstMenuEditor;
    }
  }

  // get json to create first menu
  getFirstMenuEditor() {
    switch (this.triggerType) {
      case EditorElementType.marker.toString():
        this.firstMenuEditor = FIRST_MENU_EDITOR_MARKER;
        break;
      case EditorElementType.surface.toString():
        this.firstMenuEditor = FIRST_MENU_EDITOR_SURFACE;
        break;
      case EditorElementType.geo.toString():
        this.firstMenuEditor = FIRST_MENU_EDITOR_GEO_POSITION;
        break;
      case null :
        this.firstMenuEditor = null;
    }
  }



  // pour déterminer bouton sélectionné dans first menu (css)
  onElementSelect(element) {
    if (element.children.length === 0) {
      this.selectedElementFirstMenu = element.title;
      this.selectedElementFirstMenuEvent.emit(element.title);
    } else {
      this.selectedElementFirstMenu = null;
    }
    if (this.firstMenuEditor.indexOf(element) !== -1) { // element = element principal
      if (this.openElementFirstMenu === element) { // pour la fermeture et l'ouverture du sous menu
        this.openElementFirstMenu = null;
      } else {
        this.openElementFirstMenu = element;
      }
    }
  }
  // select save or marker
  onMenuClick(typeObj, id, element) {
    this.onElementSelect(element);
    switch (id) {
      // we want adding marker
      case EditorElementType.marker.toString():
        // to open popup "marker name"
        this.canOpenMarkerName = true;
        // Open Popups (create asset +emit result)
        this.addNewMarker();
        break;
      case EditorElementType.geo.toString():
        this.addNewGeoPosition();
        break;
      case 'Save':
        this.publishEvent.emit();
        this.selectedElementFirstMenu = null;
        break;
    }
  }

  addNewGeoPosition() {
    // if we still in the editor (this component not destroyed)
    if (this.destroyComponent !== true) {
      this.addGeoPosition.emit();
    }
  }

  addNewMarker() {
    // if we still in the editor (this component not destroyed)
    if (this.destroyComponent !== true) {
      // --------------------Open popup Import marker-----------------
      this.dialogRef = this.dialog.open(ImportAssetDialogComponent, {
        width: IMPORT_ASSET_DIALOG_WIDTH,
        height: IMPORT_ASSET_DIALOG_HEIGHT,
        data: {
          title: IMPORT_MARKER,
          info: IMPORT_MARKER_DIALOG,
          userId: this.userId}
      });
      // ---------------------close popup Import marker----------------
      this.dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // _______Open popup marker name______
          this.dialogRef = this.dialog.open(MarkerNameDialogComponent, {
            width: '500px',
            data: [this.canOpenMarkerName]
          });
          // _______Open popup marker name______
          this.dialogRef.afterClosed().subscribe(name => {
            this.canOpenMarkerName = false;
            if (name) {
              this.addMarker.emit({
                name,
                asset: result,
              });
            } else {
              this.selectedElementFirstMenu = null;
            }
          });
          // _________________________________________
        } else {
          this.selectedElementFirstMenu = null;
        }
      });
      // --------------------------------------------------------------
    }
  }
  // send event to editor to create the component
  addNewObjScene(typeObj, event, element) {
    this.onElementSelect(element);

    switch (typeObj) {
      case 'text':
        this.addNew.emit({type: typeObj, isMenuToggled: this.isToggled});
        break;
      /////// IMAGE /////////
      case EditorElementType.image.toString():
        if (this.destroyComponent !== true) {
          this.dialogRef = this.dialog.open(ImportAssetDialogComponent, {
            width: IMPORT_ASSET_DIALOG_WIDTH,
            height: IMPORT_ASSET_DIALOG_HEIGHT,
            data: {
              title: IMPORT_IMAGE,
              info: IMPORT_IMAGE_ASSET_DIALOG,
              userId: this.userId}
          });
          this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.addNew.emit({
                type: EditorElementType.image.toString(),
                isMenuToggled: this.isToggled,
                asset: result,

              });
            } else {
              this.selectedElementFirstMenu = null;
            }
          });
        }
        break;
      //////// OBJECT 3D ////////
      case EditorElementType.object3d.toString():
        if (this.destroyComponent !== true) {
          this.dialogRef = this.dialog.open(ImportAssetDialogComponent, {
            width: IMPORT_ASSET_DIALOG_WIDTH,
            height: IMPORT_ASSET_DIALOG_HEIGHT,
            data: {
              title: IMPORT_OBJECT_3D,
              info: IMPORT_OBJECT_3D_ASSET_DIALOG,
              userId: this.userId }
          });
          this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.addNew.emit({
                type: EditorElementType.object3d.toString(),
                isMenuToggled: this.isToggled,
                asset: result,
              });
            } else {
              this.selectedElementFirstMenu = null;
            }
          });
        }
        break;
      case 'video':
        this.dialogRef = this.dialog.open(VideoUrlDialogComponent, {
          width: '500px',
          data: {}
        });
        this.dialogRef.afterClosed().subscribe(result => {
          if (result && result.action) {
            if (result.action === 'create') {
              this.addNew.emit({type: 'video', isMenuToggled: this.isToggled, url: result.url});
            } else {
              this.selectedElementFirstMenu = null;
            }
          } else {
            this.selectedElementFirstMenu = null;
          }
        });

        break;
      //////// AUDIO ////////
      case EditorElementType.audio.toString():
        if (this.destroyComponent !== true) {
          this.dialogRef = this.dialog.open(ImportAssetDialogComponent, {
            width: IMPORT_ASSET_DIALOG_WIDTH,
            height: IMPORT_ASSET_DIALOG_HEIGHT,
            data: {
              title: IMPORT_AUDIO,
              info: IMPORT_AUDIO_ASSET_DIALOG,
              userId: this.userId }
          });
          this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.addNew.emit({
                type: EditorElementType.audio.toString(),
                isMenuToggled: this.isToggled,
                asset: result,
              });
            } else {
              this.selectedElementFirstMenu = null;
            }
          });
        }
        break;
    }
  }

  toggleMenu() {
    this.addNew.emit({type: 'toggleMenu', isMenuToggled: !this.isToggled});
    this.isToggled = !this.isToggled;
  }
}

