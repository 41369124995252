import * as BABYLON from 'babylonjs';
import {
  DEFAULT_OBJ_3D_GEO_POSITION_URL,
  EditorElementType
} from "../../../utils/global-constants/editor-const";
import {v4 as uuidv4} from 'uuid';
import {OBJFileLoader} from "babylonjs-loaders";

const urlSource = DEFAULT_OBJ_3D_GEO_POSITION_URL;
const scaling = {x: 150, y: 150, z: 150};

export class GeoPosition {
  public uniqueId;
  public width = 0;
  public height = 0;
  public name = EditorElementType.geo.toString();
  public type = EditorElementType.geo.toString();
  public visible = true;
  public position = {x: 0, y: 100, z: 1700};
  public latitude = 0;
  public longitude = 0;
  public url = 'assets/images/editor/mapPin.png';

  constructor(object?, recreate?, name?, latitude?, longitude?, position?) {
    if (object) {
      if (recreate) {
        this.name = object.name;
      } else {
        this.width = object.width;
        this.height = object.height;
        this.url = object.url;
      }
    }
    if (name) {
      this.name = name;
    }
    if (latitude) {
      this.latitude = latitude;
    }
    if (longitude) {
      this.longitude = longitude;
    }
    if (position) {
      this.position = position;
    }
  }

  configBabylonObjFileLoader() {
    OBJFileLoader.OPTIMIZE_WITH_UV = true;
  }

  addGeoPositionFromObjScene(scene: BABYLON.Scene) {
    // add pin 3d model as geoPosition origin
    let checkFile = false;
    const x = new XMLHttpRequest();
    x.timeout = 15000;
    x.open('GET', urlSource);
    x.onreadystatechange = ( event) => {
      if ( checkFile === false ) {
        checkFile = true;
        let concatMesh = null;
        const index = urlSource.lastIndexOf('/');
        this.configBabylonObjFileLoader();
        BABYLON.SceneLoader.ImportMesh('', urlSource.substring(0, index + 1),
          urlSource.substring(index + 1, urlSource.length),
          scene,
          (newMeshes) => {
            if (!newMeshes) {
              throw Error('can not import');
            }
            const concatMeshes = [];
            newMeshes.forEach((mesh) => {
              if (mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind) && mesh.getTotalVertices() > 0){
                concatMeshes.push(mesh as BABYLON.Mesh);
              }
            });
            try {
              concatMesh = BABYLON.Mesh.MergeMeshes(concatMeshes, true, true, undefined, false, true);
              concatMesh.scaling = new BABYLON.Vector3(scaling.x, scaling.y, scaling.z);
              concatMesh.position = new BABYLON.Vector3(this.position.x, this.position.y , this.position.z );
              concatMesh.rotation = new BABYLON.Vector3(0, 0 , 0);
              concatMesh.renderingGroupId = 2;
              concatMesh.id = EditorElementType.geo.toString();
              concatMesh.isVisible = this.visible;
              concatMesh.uniqueId = uuidv4();
              this.uniqueId = concatMesh.uniqueId;
            } catch (e) {
            }
          });
        return concatMesh;
      }
    };
    x.send();
  }

  recreate(scene) {
    this.addGeoPositionFromObjScene(scene);
  }

  outputJson() {
    return {
      id: this.uniqueId,
      name: this.name,
      latitude: this.latitude,
      longitude: this.longitude
    };
  }
}
