import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {COMING_SOON, COMING_SOON_PAGE_TITLE} from '../../utils/actionsLabels/labels';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {

  constructor(private titleService: Title) { }
  pageTitle = COMING_SOON;
  ngOnInit(): void {
    this.titleService.setTitle(COMING_SOON_PAGE_TITLE);
    window.scrollTo(0, 0);
  }

}
