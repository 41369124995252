import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TEMPLATES_PATH} from "../../../utils/actionsLabels/pathLabels";
import {DELETE_TEMPLATE_FROM_FAVORITES} from "../../../utils/actionsLabels/labels";
import {TemplateFavorite} from "../../interfaces/TemplateFavorite";

@Component({
  selector: 'app-listing-template-favorite-item',
  templateUrl: './listing-template-favorite-item.component.html',
  styleUrls: ['./listing-template-favorite-item.component.scss']
})
export class ListingTemplateFavoriteItemComponent implements OnInit {

  @Input() data: TemplateFavorite;
  @Input() disabledButton;
  customStyle = '';
  linkDetails = '';
  @Output() templateAction = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
      if (this.data.cover_picture !== '') {
        this.customStyle = 'background: url(' + this.data.cover_picture + ') center no-repeat';
      }
      this.linkDetails = TEMPLATES_PATH + '/' + this.data.template_id;
  }

  applyAction(id) {
    this.templateAction.emit({data: DELETE_TEMPLATE_FROM_FAVORITES, id:id});
  }
}
