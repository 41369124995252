<div class="experience-details-container" *ngIf="doneDetails">
  <section>
    <app-details-menu (scrollTo)="scrollTo($event)" [menu]="menu"
                      [activeSubMenu]="activeSubMenu"></app-details-menu>
  </section>
  <section class="pg-list-1" [ngStyle]="{'background': groupCover}">
    <app-group-details-header [details]="group"></app-group-details-header>
  </section>
  <section class="list-pg-bg">
    <div class="container">
      <div class="row">

        <div class="com-padd">

          <div class="row  waiting-get-data " *ngIf=" !pageStructure.left ||
                                                     pageStructure.left.length === 0 ||
                                                     !pageStructure.right ||
                                                     pageStructure.right.length === 0 ||
                                                     doneSuggestedGroups === false">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>

          <!---****************************** left side start **********************************************---->

          <div class="list-pg-lt list-page-com-p"
               *ngIf="pageStructure.left && pageStructure.left.length !== 0 && doneSuggestedGroups === true">
            <div *ngFor="let section of pageStructure.left">
              <!---------------------------------- Section About ------------------------------------------------------->
              <app-group-details-about *ngIf="section.type === 'about-section' && doneForGroupFavorite" id="about"
                                       [details]="group" [section]="section"
                                       (request)="sendData($event)"
                                       [etatGroupFavorite]="etatGroupFavorite"
                                       (shareOnFacebook)="shareFacebook($event)"
                                       (shareOnTwitter)="shareTwitter($event)"
                                       (shareOnLinkedin)="shareLinkedin($event)"
                                       [disabledButtonFavorite]="disabledButtonFavorite"></app-group-details-about>
              <!-------------------------------- Section Experiences ------------------------------------------------------->
              <app-group-details-experiences *ngIf="section.type === 'experiences-section'"
                                             [experiences]="experiences"
                                             [userId]="userId"
                                             [groupId]="id"
                                             [experiencesNbr]=experiencesNbr
                                             [section]="section"
                                             (onAddExperience)="onAddExperience($event)" id="experiences"
                                             [buttonsDisabled]="buttonsDisabled"
                                             (buttonDisabled)="getButtonsDisabled($event)"
              ></app-group-details-experiences>
            </div>
          </div>
          <!--********************************************** left side end **********************************************--->

          <!--********************************************** right side start **********************************************--->

          <div class="list-pg-rt"
               *ngIf="pageStructure.right && pageStructure.right.length !== 0 && doneSuggestedGroups === true">
            <div *ngFor="let section of pageStructure.right">
              <!---------------------------------- Section buttons --------------------------------------------------------->
              <div class="pglist-p3 pglist-bg pglist-p-com" *ngIf="section.type === 'buttons-section'">
                <div *ngFor="let button of section.buttons">
                  <!---   button red  start   -------->
                  <div class="list-pg-inn-sp" *ngIf="button.type === 'red-button'">
                    <div class="list-pg-upro" [class.isDisabled]="buttonsDisabled">
                      <a (click)="onButtonClick(button.click)"><span
                        class="waves-effect waves-light full-btn list-pg-btn">{{button.label | translate}}</span></a>
                    </div>
                  </div>
                  <!---   button red  end   -------->
                  <!---   button pink  start    -------->
                  <div class="list-pg-inn-sp" *ngIf="button.type === 'pink-button'">
                    <div class="list-pg-upro" [class.isDisabled]="buttonsDisabled">
                      <a (click)="onButtonClick(button.click)"><span
                        class="waves-effect waves-light full-btn-pink list-pg-btn ">{{button.label | translate}}</span></a>
                    </div>
                  </div>
                  <!---   button pink  end   -------->
                  <!---   button blue  start   -------->
                  <div class="list-pg-inn-sp" *ngIf="button.type === 'blue-button'">
                    <div class="list-pg-upro" [class.isDisabled]="buttonsDisabled">
                      <a [routerLink]="button.path" *ngIf="button.path"><span
                        [ngClass]="{'full-btn-blue-min': button.label.length>20} "
                        class="waves-effect waves-light full-btn-blue list-pg-btn ">{{button.label | translate}}</span></a>
                      <a *ngIf="!button.path" (click)="onButtonClick(button.click)"><span
                        [ngClass]="{'full-btn-blue-min': button.label.length>20} "
                        class="waves-effect waves-light full-btn-blue list-pg-btn ">{{button.label | translate}}</span></a>
                    </div>
                  </div>
                  <!---   button blue  end   -------->
                  <!---   button white  start   -------->
                  <div class="list-pg-inn-sp" *ngIf="button.type === 'white-button'">
                    <div class="list-pg-upro" [class.isDisabled]="disabledButton">
                      <a [routerLink]="button.path" *ngIf="button.path"><span
                        class="waves-effect waves-light full-btn-white list-pg-btn"> <i
                        class="{{button.icon}}"></i> {{button.label | translate}}</span></a>
                      <a *ngIf="!button.path" (click)="onButtonClick(button.click)"><span
                        class="waves-effect waves-light full-btn-white list-pg-btn"> <i
                        class="{{button.icon}}"></i> {{button.label | translate}}</span></a>
                    </div>
                  </div>
                  <!---   button white  end   -------->
                  <!---   button green  start   -------->
                  <div class="list-pg-inn-sp" *ngIf="button.type === 'green-button'">
                    <div class="list-pg-upro" [class.isDisabled]="disabledButton">
                      <a [routerLink]="button.path" *ngIf="button.path" ><span
                        [ngClass]="{'full-btn-green-min': button.label.length>20} "
                        class="waves-effect waves-light full-btn-green list-pg-btn ">{{button.label | translate}}</span></a>
                      <a *ngIf="!button.path" (click)="onButtonClick(button.click)" ><span
                        [ngClass]="{'full-btn-green-min': button.label.length>20} "
                        class="waves-effect waves-light full-btn-green list-pg-btn ">{{button.label | translate}}</span></a>
                    </div>
                  </div>
                  <!---   button green  end   -------->
                </div>
              </div>
              <!---------------------------------- Section groups-suggestions --------------------------------------------------------->
              <app-group-details-groups-suggestions *ngIf="section.type === 'suggested-groups-section'"
                                                    [suggestedGroups]="suggestedGroups"
                                                    (groupAction)="joinGroupAction($event)"></app-group-details-groups-suggestions>

              <!---------------------------------- Section groups-members --------------------------------------------------------->
              <app-group-details-members *ngIf="section.type === 'members-section'"
                                         [members]="members"
                                         [membersNbr]=membersNbr
                                         [groupId]="id"
                                         [section]="section" id="members"
                                         [buttonsDisabled]="buttonsDisabled"
                                         (buttonDisabled)="getButtonsDisabled($event)"
              ></app-group-details-members>
              <!---------------------------------- end Section groups-members ------------------------------------------------------>
              <br *ngIf="section.type === 'suggested-groups-section'">

            </div>
          </div>
          <!--********************************************** right side end **********************************************-->

        </div>
      </div>
    </div>
  </section>
</div>

<app-confirm-dialog [confirmText]="deleteText"></app-confirm-dialog>


<div class="row waiting_load" *ngIf="waitingLoad">
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
</div>
