import {RoleTemplate} from '../../utils/global-constants/templates-const';
import {
  SECTION_ABOUT_LABEL,
  SECTION_GALLERY_LABEL, SECTION_MY_REVIEW_LABEL,
  SECTION_RATING_REVIEWS_LABEL,
  SECTION_WRITE_REVIEW_LABEL
} from '../../utils/actionsLabels/labels';

export function getPageStructure(userRoleTemplate, TemplateStatus, id, sectionReview) {
  switch (userRoleTemplate) {
    case RoleTemplate.CREATOR:
      return getCreatorStructure();
      break;
    case RoleTemplate.NOT_CREATOR:
      switch (sectionReview) {
        case SECTION_WRITE_REVIEW_LABEL:
          return getNotCreatorWriteReviewStructure();
          break;
        case SECTION_MY_REVIEW_LABEL:
          return getNotCreatorOwnReviewStructure();
          break;
      }
      break;
  }
  return null;
}

function getCreatorStructure() {
  return {
    left: [
      {
        type: 'about-section',
        label: SECTION_ABOUT_LABEL
      },
      {
        type: 'gallery-section',
        label: SECTION_GALLERY_LABEL
      },
      {
        type: 'rating-reviews-section',
        label: SECTION_RATING_REVIEWS_LABEL
      },
    ],
    right: [
      {
        type: 'creator-section'
      },
      {
        type: 'used-section'
      },
      {
        type: 'suggested-groups-section',
      },
    ]
  };
}
function getNotCreatorWriteReviewStructure() {
  return {
    left: [
      {
        type: 'about-section',
        label: SECTION_ABOUT_LABEL
      },
      {
        type: 'gallery-section',
        label: SECTION_GALLERY_LABEL
      },
      {
        type: 'write-review-section',
        label: SECTION_WRITE_REVIEW_LABEL

      },
      {
        type: 'rating-reviews-section',
        label: SECTION_RATING_REVIEWS_LABEL
      },
    ],
    right: [
      {
        type: 'creator-section'
      },
      {
        type: 'used-section'
      },
      {
        type: 'suggested-groups-section',
      },
    ]
  };
}
function getNotCreatorOwnReviewStructure() {
  return {
    left: [
      {
        type: 'about-section',
        label: SECTION_ABOUT_LABEL
      },
      {
        type: 'gallery-section',
        label: SECTION_GALLERY_LABEL
      },
      {
        type: 'own-review-section',
        label: SECTION_MY_REVIEW_LABEL

      },
      {
        type: 'rating-reviews-section',
        label: SECTION_RATING_REVIEWS_LABEL
      },
    ],
    right: [
      {
        type: 'creator-section'
      },
      {
        type: 'used-section'
      },
      {
        type: 'suggested-groups-section',
      },
    ]
  };
}
