import {Component, OnInit} from '@angular/core';
import {
  BLANK_EXPERIENCE,
  CAN_NOT_ALLOWED_OS,
  CAN_NOT_ALLOWED_OS_TITLE,
  CREATE_EXPERIENCE_PAGE_TITLE,
  EDIT_EXPERIENCE_PAGE_TITLE,
  PAGE_TITLE_PREFIX
} from '../../utils/actionsLabels/labels';
import {EDITOR_EXPERIENCE_PATH, EXPERIENCE_PATH, MY_EXPERIENCES_PATH} from '../../utils/actionsLabels/pathLabels';
import {Experience} from '../../reusables/interfaces/experience';
import {ExperiencesService} from '../../reusables/services/experiences.service';
import {NotificationService} from '../../reusables/services/notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {User} from '../../reusables/interfaces/user';
import {UsersService} from '../../reusables/services/users.service';
import {Title} from '@angular/platform-browser';
import {GroupsService} from '../../reusables/services/groups.service';
import {ExperienceGroup, Group} from '../../reusables/interfaces/group';
import {HttpParams} from '@angular/common/http';
import {
  EXPERIENCE,
  LIMIT_FILTER,
  MEMBER_GROUP,
  RANDOM_FILTER,
  SEARCH_FOR_FILTER,
  SORT_FILTER
} from '../../utils/queryParams/groupsQueryParams';
import {AuthGuard} from '../../reusables/_helpers';
import {from} from 'rxjs';
import {groupTypes} from '../../utils/global-constants/groups-const';
import {Template} from '../../reusables/interfaces/templates';
import {OWNER_TEMPLATE, SOURCE, USER_TEMPLATE} from '../../utils/queryParams/templatesQueryParams';
import {OWNER_EXPERIENCE} from '../../utils/queryParams/experiencesQueryParams';
import {VisibleGlobally} from '../../utils/global-constants/experiences-const';

@Component({
  selector: 'app-form-experience',
  templateUrl: './form-experience.component.html',
  styleUrls: ['./form-experience.component.scss',
    '../../../assets/customCss/waiting-load.scss']
})
export class FormExperienceComponent implements OnInit {
  experience: Experience;
  currentExperienceId = '';
  open = false;
  doneForUserProfile = false;
  user: User;
  fileCoverPicture;
  groups: Group [];
  experienceGroups: ExperienceGroup[];
  waitingLoad = false;
  groupTypes = groupTypes;
  source = '';
  content = '';
  chooseCreate = true; // if false => add-edit component, if true choose-create component
  // variable for choose create experience
  userId: string; // current user id
  userExperiences: Experience [];
  doneForUserExperiences = false;
  userTemplates: Template [];
  doneForUserTemplates = false;
  publishedTemplates: Template [];
  doneForPublishedTemplates = false;
  carouselOptions = {
    dots: false,
    autoplay: true,
    autoplayTimeout: 6000,
    nav: true,
    navText: ['<div class=\'nav-btn prev-slide\'></div>', '<div class=\'nav-btn next-slide\'></div>'],
    responsiveClass: true,
    margin: 10,
    // rewind: true,
    responsive: {
      0: {
        items: 1,
        nav: true,
        // loop: true
      },
      1200: {
        items: 2,
        nav: true,
        // loop: true
      },
      1600: {
        items: 3,
        nav: true,
        // loop: true
      },
      1890: {
        items: 4,
        nav: true,
        // loop: true
      }
    }
  };


  constructor(private experiencesService: ExperiencesService,
              private notificationService: NotificationService,
              private router: Router,
              private route: ActivatedRoute,
              private usersService: UsersService,
              private groupsService: GroupsService,
              private titleService: Title,
              private authGuard: AuthGuard) {
    if (this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras.state) {
      this.source = this.router.getCurrentNavigation().extras.state.source;
      this.content = this.router.getCurrentNavigation().extras.state.idContent;
    }
  }

  ngOnInit(): void {
    this.experienceGroups = [];
    if (this.authGuard.canActivate) {
      this.route.paramMap.pipe(
        map(params => {
          this.currentExperienceId = params.get('id');
          if (this.currentExperienceId && this.currentExperienceId !== '') {
            // edit page
            this.chooseCreate = false;
            this.titleService.setTitle(PAGE_TITLE_PREFIX + EDIT_EXPERIENCE_PAGE_TITLE);
            this.getExperienceDetails(this.currentExperienceId);
            this.getGroupsExperience();
          } else {
            // create page
            this.titleService.setTitle(PAGE_TITLE_PREFIX + CREATE_EXPERIENCE_PAGE_TITLE);
            this.getProfile();
            if (this.source === '' && this.chooseCreate) {
              this.initValues();
              this.openForm();
            } else {
              this.openForm();
              this.chooseCreate = false;
            }
          }
        })
      ).subscribe(() => {
      });
    }
  }

  initValues() {
    this.open = false;
    this.doneForUserProfile = false;
    this.waitingLoad = false;
    this.chooseCreate = true;
    this.source = '';
    this.content = '';
    this.doneForUserExperiences = false;
    this.doneForUserTemplates = false;
    this.doneForPublishedTemplates = false;
  }

  getProfile() {
    this.usersService.getUserProfile().subscribe((data: User) => {
      this.user = data;
      this.userId = data.id;
      this.doneForUserProfile = true;
      this.getGroupsOfCurrentUser('');
      // methods for choose create experience
      this.getUserExperiences(this.userId);
      this.getUserTemplates(this.userId);
      this.getPublishedTemplates(this.userId);
    });
  }

  getGroupsOfCurrentUser(term) {
    let params = new HttpParams();
    params = params.append(MEMBER_GROUP, this.user.id);
    if (this.experience) {
      params = params.append(EXPERIENCE, this.experience.id);
    }
    params = params.append(SORT_FILTER, RANDOM_FILTER);
    params = params.append(LIMIT_FILTER, '5');
    if (term !== '') {
      params = params.append(SEARCH_FOR_FILTER, term);
    }
    this.groupsService.getGroups(params).subscribe((data: Group[]) => {
      this.groups = data;
      // this.openForm();
    });
  }

  getGroupsExperience() {
    this.experiencesService.getExperienceGroups(this.currentExperienceId)
      .subscribe((data: { groups: ExperienceGroup[] }) => {
        from(data.groups).pipe(map(group => {
          group.privacy = this.groupTypes.find((x) => x.name === group.type);
          return group;
        })).subscribe((mappedData) => {
          this.experienceGroups.push(mappedData);
        });
      });
  }

  openForm() {
    this.open = true;
  }

  readData(event) {
    this.waitingLoad = true;
    // TODO: replace cover Picture by the good value
    if (this.currentExperienceId !== '' && this.currentExperienceId !== null) {
      this.sendUpdateRequest(event);
    } else {
      this.sendAddRequest(event);
    }
  }

  searchForGroup(event) {
    // TODO: add expected group list
    if (event.term && event.term !== '') {
      this.getGroupsOfCurrentUser(event.term);
    }
  }

  createFormData(event) {
    const formData = new FormData();
    if (this.experience && this.currentExperienceId) {
      formData.append('status', event.data.status);
      if (event.data.mediaIds) {
        const oldMedia = [];
        for (let j = 0; j < event.data.mediaIds.length; j++) {
          oldMedia.push({id_media: event.data.mediaIds[j], displayName: event.data.galleryDisplayName[j]});
        }
        formData.append('oldMediaNames', JSON.stringify(oldMedia));
      }
      if (event.data.mediaToDelete) {
        formData.append('mediaToDelete', JSON.stringify(event.data.mediaToDelete));
      }
    }
    formData.append('name', event.data.name);
    // formData.append('creatorId', '' + this.userId);
    formData.append('analyticsId', event.data.analyticsId);
    formData.append('description', event.data.description);
    formData.append('coverPictureOperation', event.data.coverPictureOperation);
    formData.append('video', event.data.video);
    formData.append('coverPicture', event.coverPicture);
    formData.append('coverPictureDisplayName', event.data.coverPictureDisplayName);
    let visible = '';
    if (event.globally === true) {
      visible = VisibleGlobally.VISIBLE;
    } else {
      visible = VisibleGlobally.NOT_VISIBLE;
    }
    formData.append('visibleGlobally', visible);
    const groupsToAddIn = event.notDeletedGroups;
    if (event.groups && event.groups.length > 0) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < event.groups.length; i++) {
        groupsToAddIn.push(event.groups[i].id);
      }
    }
    formData.append('groups', JSON.stringify(groupsToAddIn));
    const galleryFiles = [];
    const galleryNames = [];
    // TODO: backend is waiting for files_gallery. but we must send files_gallery[]
    if (event.gallery && event.gallery.length > 0) {
      for (let i = 0; i < event.gallery.length; i++) {
        const file = event.gallery[i];
        let displayIndex = i;
        if (event.data.mediaIds && event.data.mediaIds.length > 0) {
          displayIndex = i + event.data.mediaIds.length;
        }
        galleryNames.push({
          index: i,
          filename: file.name,
          display: event.data.galleryDisplayName[displayIndex]
        });
        galleryFiles.push(event.gallery[i]);
        formData.append('files_gallery', event.gallery[i]);
      }
      formData.append('objects_gallery', JSON.stringify(galleryNames));
    }
    formData.append('content', this.content);
    return formData;
  }

  sendAddRequest(event) {
    let params = new HttpParams();
    params = params.append(SOURCE, this.source);
    this.experiencesService.addExperience(this.createFormData(event), params).subscribe((data: Experience) => {
      this.waitingLoad = true;
      if (window.innerWidth < 768) {
        this.notificationService.showInfo(CAN_NOT_ALLOWED_OS, CAN_NOT_ALLOWED_OS_TITLE);
        this.router.navigateByUrl(MY_EXPERIENCES_PATH).then();
      } else {
        if (event.groups) {
          this.shareExperienceInGroup(event.groups, data);
        }
        this.router.navigate([EDITOR_EXPERIENCE_PATH + '/' + data.id + '/content/' + data.content]).then();
        // issue redmine : 19348 open editor in new tab not activated : blocked by browsers
        // window.open(BaseURLLink + EDITOR_EXPERIENCE_PATH + '/' + data.id + '/content/' + data.content, '_blank').focus();
      }
    });
  }

  sendUpdateRequest(event) {
    this.experiencesService.updateExperience(this.createFormData(event), this.currentExperienceId)
      .subscribe((data) => {
        this.waitingLoad = true;
        this.router.navigateByUrl(EXPERIENCE_PATH + '/' + this.experience.id).then();
      }, (error => {
        this.waitingLoad = false;
      }));
  }


  shareExperienceInGroup(groupList: Group[], experience: Experience) {
    if (groupList && groupList.length > 0) {
      // tslint:disable-next-line:forin
      for (const item of groupList) {
        //  add experience to group
        const experiences = [{experience_id: experience.id}];
        this.groupsService.addExperiencesToGroup(item.id, experiences).subscribe(() => {
          const myUrl = EDITOR_EXPERIENCE_PATH + '/' + experience.id + '/content/' + experience.content;
          this.router.navigateByUrl(myUrl).then();
        }, (error => {
          this.waitingLoad = false;
        }));
      }
    }
  }


  getExperienceDetails(id) {
    this.experiencesService.getDetailExperiences(id).subscribe((data: Experience) => {
      this.experience = data;
      this.openForm();
      this.getProfile();
    });
  }

  goBack() {
    this.chooseCreate = true;
  }

  // methods for choose create experience
  getUserExperiences(id) {
    let params = new HttpParams();
    params = params.append(OWNER_EXPERIENCE, id);
    this.experiencesService.getExperiences(params).subscribe((data: Experience[]) => {
      this.userExperiences = data;
      this.doneForUserExperiences = true;
    });
  }

  getUserTemplates(id) {
    let params = new HttpParams();
    params = params.append(OWNER_TEMPLATE, id);
    this.experiencesService.getTemplates(params).subscribe((data: Template[]) => {
      this.userTemplates = data;
      this.doneForUserTemplates = true;
    });
  }

  getPublishedTemplates(id) {
    let params = new HttpParams();
    params = params.append(USER_TEMPLATE, id);
    this.experiencesService.getTemplates(params).subscribe((data: Template[]) => {
      this.publishedTemplates = data;
      this.doneForPublishedTemplates = true;
    });
  }

  openCreateExperiencePage(event) {
    if (event && event.type !== '') {
      if (event.type === BLANK_EXPERIENCE) {
        this.content = '';
        this.source = '';
      } else {
        this.content = event.content_id.replace('-', '');
        this.source = event.source;
      }
      this.chooseCreate = false;
    }
  }
}
