import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

import {AuthenticationService} from '../services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        const currentIdToken = this.authenticationService.currentUserIdTokenValue;
        const isLoggedIn = currentIdToken;
        if (isLoggedIn) {
            request = request.clone({
                setHeaders: {
                  token: `${currentIdToken}`,
                  'Access-Control-Allow-Origin': `*`,
                  'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, HEAD, OPTIONS',
                }
            });
        }

        return next.handle(request);
    }
}
