import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  EXPERIENCES_SECTION_TITLE,
  HOME_PAGE_TITLE,
  SUCCESS_JOIN_REQUEST_SENT_MESSAGE,
  SUCCESS_JOIN_REQUEST_SENT_TITLE
} from '../../utils/actionsLabels/labels';
import {Experience} from '../../reusables/interfaces/experience';
import {ExperiencesService} from '../../reusables/services/experiences.service';
import {Title} from '@angular/platform-browser';
import {HttpParams} from '@angular/common/http';
import {LIMIT_FILTER} from '../../utils/queryParams/experiencesQueryParams';
import {Template} from '../../reusables/interfaces/templates';
import {GroupsService} from '../../reusables/services/groups.service';
import {Group} from '../../reusables/interfaces/group';
import {NotificationService} from '../../reusables/services/notification.service';
import {LoginDialogComponent} from '../../reusables/components/popups/login-dialog/login-dialog.component';
import {LOGIN_PATH} from '../../utils/actionsLabels/pathLabels';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {TokenHelperService} from '../../reusables/services/token-helper.service';
import * as $ from 'jquery';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-home-profile',
  templateUrl: './home-profile.component.html',
  styleUrls: ['./home-profile.component.scss']
})
export class HomeProfileComponent implements OnInit, OnDestroy {

  sectionTitle = EXPERIENCES_SECTION_TITLE;
  experiences: Experience [];
  templates: Template [];
  groups: Group [] = [];
  doneForExperiences = false;
  doneForTemplates = false;
  doneForGroups = false;
  joinGroupReload = false;
  dialogRef;
  isLogged;
  noSuggestedGroup = false;
  disabledButton = false;
  destroyComponent = false;
  carouselOptions = {
    dots: false,
    autoplay: true,
    autoplayTimeout: 6000,
    nav: true,
    navText: ['<div class=\'nav-btn prev-slide\'></div>', '<div class=\'nav-btn next-slide\'></div>'],
    responsiveClass: true,
    margin: 10,
    // rewind: true,
    responsive: {
      0: {
        items: 1,
        nav: true,
        // loop: true
      },
      600: {
        items: 2,
        nav: true,
        // loop: true
      },
      900: {
        items: 3,
        nav: true,
        // loop: true
      },
      1200: {
        items: 4,
        nav: true,
        // loop: true
      },
      1700: {
        items: 5,
        nav: true,
        // loop: true
      }
    }
  };
  constructor(private experiencesService: ExperiencesService,
              private groupsService: GroupsService,
              private notificationService: NotificationService,
              private nameService: Title,
              public dialog: MatDialog,
              private router: Router,
              private translate: TranslateService,
              private tokenHelperService: TokenHelperService) { }

  ngOnInit(): void {
    //this.nameService.setTitle(HOME_PAGE_TITLE);
    this.translate.get(HOME_PAGE_TITLE).subscribe((res: string) => {
      this.nameService.setTitle(res);
    });
    this.isLogged = this.tokenHelperService.getConnectedUser();
    this.getExperiences();
    this.getTemplates();
    this.getGroups();
  }

  ngOnDestroy(): void {
    this.destroyComponent = true;
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  getExperiences() {
    let params = new HttpParams();
    params = params.append(LIMIT_FILTER, '10');
    this.experiencesService.getExperiences(params).subscribe((data: Experience[]) => {
      this.experiences = data;
      this.doneForExperiences = true;
    });
  }

  getTemplates() {
    let params = new HttpParams();
    params = params.append(LIMIT_FILTER, '10');
    this.experiencesService.getTemplates(params).subscribe((data: Template[]) => {
      this.templates = data;
      this.doneForTemplates = true;
    });
  }

  getGroups() {
    let params = new HttpParams();
    params = params.append(LIMIT_FILTER, '10');
    this.groupsService.getGroups(params).subscribe((data: Group[]) => {
      this.groups = data;
      this.doneForGroups = true;
      this.disabledButton = false;
    });
  }

  joinItemGroupClick(event) {
    if ( this.isLogged) {
      this.disabledButton = true;
      this.joinGroup(event.id, event.data);
    } else {this.openLoginPopup(); }
  }
  joinGroup(id, i) {
    this.doneForGroups = true;
    this.groupsService.askJoinGroup(id).subscribe(() => {
      this.notificationService.showSuccess(SUCCESS_JOIN_REQUEST_SENT_MESSAGE, SUCCESS_JOIN_REQUEST_SENT_TITLE );
      $('#suggested_group_item_' + i).hide();
      const item = document.getElementById('suggested_group_item_' + i);
      item.parentElement.parentElement.style.setProperty('display', 'none', 'important');
      if (this.groups.length === 1) {
        this.noSuggestedGroup = true;
      }
      this.disabledButton = false;
    });
  }
  openLoginPopup() {
    if (this.destroyComponent !== true) { // prevent popup from appearing on other pages
      this.dialogRef = this.dialog.open(LoginDialogComponent, {
        disableClose: false,
        autoFocus: true,
        panelClass: 'my-dialog',
        width: '800px',
        maxHeight: '500px',
      });
      this.dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.router.navigate([LOGIN_PATH], {queryParams: {returnUrl: this.router.url}}).then(); // queryParams pour stocker l'ancien url
        }
      });
    }
  }
}
