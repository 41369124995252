<h1 mat-dialog-title>{{"Import 3D model from" | translate}} </h1>

<div mat-dialog-actions class="row col-md-12">
  <button class="pc-button col-md-offset-1 col-md-5"  (click)="onNoClick('pc')"></button>
  <button  class="poly-button col-md-offset-1 col-md-5"(click)="onNoClick('poly')" [disabled]="true"></button>
</div>

<div mat-dialog-content class="row ">
  <p class=" label col-md-6 " >{{'your file system' | translate }}</p>
  <p class=" label col-md-6 " >{{'Poly' | translate }}</p>
</div>

