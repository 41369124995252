  <div class="cus-rev">
    <div class="pg-revi-re">
      <div class="left ">
        <div  class=" avatar-container">
          <img *ngIf="member.profilePicture && member.profilePicture != ''" src="{{member.profilePicture}}" alt="">
          <div *ngIf="!member.profilePicture && member.profilePicture == ''"> </div>
        </div>
      </div>
      <div class="left info-container">
        <div class="role-container">
          <span *ngIf="member.group_role === MANAGER" class="tz-msg-un-read">{{"Admin" | translate}}</span>
        </div>
        <p title="{{member.member_name}}" class="ellipsis-style">{{member.member_name}}</p>
<!--        TODO: display publications number -->
<!--        <p><span>{{member.publications_number}} {{"Experiences" | translate}}</span></p>-->
      </div>
    </div>
  </div>
