import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  MARKER,
  MARKER_TEXT_ON_HOVER,
  SURFACE,
  SURFACE_TEXT_ON_HOVER,
  TEMPLATE_FROM_EXPERIENCE,
  TEMPLATE_FROM_TEMPLATE
} from '../../../utils/actionsLabels/labels';
import {
  EXPERIENCE_PATH,
  TEMPLATES_PATH
} from '../../../utils/actionsLabels/pathLabels';
import {Router} from '@angular/router';

@Component({
  selector: 'app-experience-template-single',
  templateUrl: './experience-template-single.component.html',
  styleUrls: ['./experience-template-single.component.scss']
})
export class ExperienceTemplateSingleComponent implements OnInit {

  @Input() data: any;
  experience = 'experience';
  template = 'template';
  @Output() request: EventEmitter<{type: string, source: string, content_id: string}> = new EventEmitter<any>();
  fromTemplate = TEMPLATE_FROM_TEMPLATE;
  fromExperience = TEMPLATE_FROM_EXPERIENCE;
  linkTemplate = TEMPLATES_PATH + '/';
  linkExperience = EXPERIENCE_PATH + '/';
  createPath: string;
  marker = MARKER;
  surface = SURFACE;
  markerText = MARKER_TEXT_ON_HOVER;
  surfaceText = SURFACE_TEXT_ON_HOVER;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.createPath = this.router.url;
  }

  useTemplateOrExperience(typeAction, sourceAction, content) {
    // typeAction can be: from template or from experience
    // sourceAction can be: template or experience
    this.request.emit({type: typeAction, source: sourceAction, content_id: content});
  }

}
