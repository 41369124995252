import {Component, OnInit, Input} from '@angular/core';
import {Experience} from '../../interfaces/experience';
import {BaseURL} from '../../../utils/global-constants/baseUrl';
import {EXPERIENCE_PATH} from '../../../utils/actionsLabels/pathLabels';

@Component({
  selector: 'app-single-suggested-experience',
  templateUrl: './single-suggested-experience.component.html',
  styleUrls: ['./single-suggested-experience.component.scss']
})
export class SingleSuggestedExperienceComponent implements OnInit {

  constructor() {
  }
  coverExperience = '#233951';
  @Input() data: Experience;
  link;
  experienceLink = EXPERIENCE_PATH;

  ngOnInit(): void {
    if (this.data.coverPicture !== '') {
      this.coverExperience = 'url(' + this.data.coverPicture + ') no-repeat center bottom';
    }
    this.link =  '/' + this.experienceLink  + '/' + this.data.id;
  }

}
