import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  COMING_SOON,
  HEADER_DATE,
  HEADER_DATE_MAJ,
  HEADER_NAME,
  HEADER_RATING,
  HEADER_STATUS, HEADER_TIMES_USED,
  MY_TEMPLATE_PAGE_TITLE, STATUS_DRAFT, STATUS_IN_MODERATION, STATUS_PUBLISHED, STATUS_UNPUBLISHED
} from '../../../utils/actionsLabels/labels';
import {Template} from '../../interfaces/templates';
import {statusClass} from '../../../utils/global-constants/experienceStatusColors';
import {NotificationService} from '../../services/notification.service';
import {CREATE_EXPERIENCE_PATH, CREATE_TEMPLATE_PATH} from '../../../utils/actionsLabels/pathLabels';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {CreateTemplateDialogComponent} from '../popups/create-template-dialog/create-template-dialog.component';

@Component({
  selector: 'app-my-templates-section',
  templateUrl: './my-templates-section.component.html',
  styleUrls: ['./my-templates-section.component.scss']
})
export class MyTemplatesSectionComponent implements OnInit, OnDestroy {

  pageTitle = MY_TEMPLATE_PAGE_TITLE;
  tabHeaders = [HEADER_NAME, HEADER_DATE, HEADER_DATE_MAJ, HEADER_TIMES_USED , HEADER_RATING, HEADER_STATUS, '', '', ''];
  @Input() templates: Template[];
  @Input() disabledButton;
  statusClassTab = statusClass;
  @Output() deleteTemplateAction = new EventEmitter();
  createTempPath = CREATE_TEMPLATE_PATH;
  statusPublished = STATUS_PUBLISHED;
  statusUnpublished = STATUS_UNPUBLISHED;
  statusDraft = STATUS_DRAFT;
  statusInModeration = STATUS_IN_MODERATION;
  dialogRef;
  destroyComponent = false;
  constructor(private notificationService: NotificationService,
              private cookieService: CookieService,
              private router: Router,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    this.destroyComponent = true;
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
  openUseTemplate(id) {
    if (this.destroyComponent !== true) { // prevent popup from appearing on other pages
      this.dialogRef = this.dialog.open(CreateTemplateDialogComponent, {
        disableClose: false,
        autoFocus: true,
        panelClass: 'my-dialog',
        width: '800px',
        maxHeight: '500px',
      });
      this.dialogRef.afterClosed().subscribe((result) => {
        if (result === 'create template' || result === 'create experience') {
          if (result === 'create template') {
            // go to form create template
            this.router.navigate(
              [CREATE_TEMPLATE_PATH],
              {state: {source: 'template', idContent: id}}
            ).then();
          } else {
            // go to page create experience
            this.router.navigate(
              [CREATE_EXPERIENCE_PATH],
              {state: {source: 'template', idContent: id}}
            ).then();
          }
        }
      });
    }
  }

  deleteTemplate(id, name) {
    this.deleteTemplateAction.emit({id, name});
  }

  comingSoonAction() {
    this.notificationService.showWarning('', COMING_SOON);
  }
}
