import {Component, OnInit} from '@angular/core';
import {CREATE_GROUP_PATH} from '../../../utils/actionsLabels/pathLabels';

@Component({
  selector: 'app-create-first-group',
  templateUrl: './create-first-group.component.html',
  styleUrls: ['./create-first-group.component.scss']
})
export class CreateFirstGroupComponent implements OnInit {
  createGroupUrl = CREATE_GROUP_PATH;

  constructor() { }

  ngOnInit(): void {
  }

}
