import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Group} from '../../../interfaces/group';

@Component({
  selector: 'app-group-details-groups-suggestions',
  templateUrl: './group-details-groups-suggestions.component.html',
  styleUrls: ['./group-details-groups-suggestions.component.scss']
})
export class GroupDetailsGroupsSuggestionsComponent implements OnInit {

  @Input() suggestedGroups: Group [];
  @Output() groupAction = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }
  onItemGroupClick(event) {
    this.groupAction.emit(event);
    // if (event.toElement !== undefined && event.toElement === JOIN_GROUP) {
    //   this.groupAction.emit(event);
    // }
  }

}
