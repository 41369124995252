<div class="list-mig-like">
  <div class="list-ri-spec-tit">
    <h3>
      <span>{{'You might' | translate}}</span> {{'like this' | translate}}
    </h3>
  </div>
  <div *ngIf="source=='experience'">
    <div *ngFor="let item of suggestedExperiences">
      <app-single-suggested-experience [data]="item"></app-single-suggested-experience>
    </div>
  </div>
  <div *ngIf="source=='template'">
    <div *ngFor="let item of suggestedTemplates">
      <app-single-suggested-template [data]="item"></app-single-suggested-template>
    </div>
  </div>
</div>
