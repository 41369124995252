<div class="tz-2">
  <div class="tz-2-com tz-2-main">
    <div *ngIf="isFormPage">
      <app-add-edit-template-form *ngIf="open && doneForUserData"
                                  [template]="template"
                                  (request)="createData($event)"
                                  (goBack)="goBack()"
                                  [user]="user"
      ></app-add-edit-template-form>
    </div>
    <div *ngIf="!isFormPage">
      <app-choose-create-template-form
        *ngIf="doneForUserExperiences && doneForUserTemplates && doneForPublishedTemplates"
        [userExperiences]="userExperiences"
        [doneForUserExperiences]="doneForUserExperiences"
        [userTemplates]="userTemplates"
        [doneForUserTemplates]="doneForUserTemplates"
        [publishedTemplates]="publishedTemplates"
        [doneForPublishedTemplates]="doneForPublishedTemplates"
        (openCreateTemplatePage)="openCreateTemplatePage($event)"
        [carouselOptions]="carouselOptions">
      </app-choose-create-template-form>
    </div>
    <div class="row waiting_load" *ngIf="!isFormPage && waitingLoad && (!doneForUserExperiences || !doneForUserTemplates || !doneForPublishedTemplates)">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
    <div class="row waiting_load" *ngIf="isFormPage && waitingLoad">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
  </div>
</div>



