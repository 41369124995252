<section>
  <div class="tz" *ngIf="user && user.userId !== ''">
    <div class="tz-l"  >
      <app-profile-picture [user]="user"></app-profile-picture>
      <app-account-menu [user]="user"></app-account-menu>
    </div>
    <router-outlet></router-outlet>

    <div class="row waiting_load" *ngIf="waitingLoad">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
<!--    <app-notifications></app-notifications>-->
  </div>
</section>
