<div class="listing-experiences-item-home home-list-group home-list-pop">
  <div class="item-cover-picture" [style]="customStyle"></div>
  <div class="item-avatar">
    <img *ngIf="data.avatar&& data.avatar != ''" src="{{data.avatar}}">
  </div>
  <div class="item-name" title="{{data.name}}"> <a [routerLink]="linkDetails">{{data.name}}</a></div>
  <div class="item-description">{{data.description}}</div>
  <div class="item-description"><span *ngIf="showMembers">{{data.members}} {{"members" | translate}}</span></div>
  <div [class.isDisabled]="disabledButton">
    <a (click)="askJoinGroup()">
      <div class="item-action">
        <a>
          <i class="fa fa-user" aria-hidden="true"></i>{{'JOIN_GROUP' | translate}}
        </a>
      </div>
    </a>
  </div>
</div>
