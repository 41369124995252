<div class="experience-details-container">
  <app-experience-status [status]="experience.status" *ngIf="displayEditFixedMenu || displayValidateFixedMenu" ></app-experience-status>
  <section *ngIf="doneDetails">
    <app-details-menu (scrollTo)="scrollTo($event)" [menu]="menu" *ngIf="displayMenu"
                      [activeSubMenu]="activeSubMenu"></app-details-menu>
  </section>
  <section class="pg-list-1" [ngStyle]="{'background': coverExperience}" *ngIf="doneDetails">
    <app-details-header [details]="experience"></app-details-header>
    <div class="rating-content" *ngIf="doneDetails && experience.rating > 0">
      <app-rating [rating]="experience.rating"></app-rating>
    </div>
  </section>
  <section class="list-pg-bg">
    <div class="container">
      <div class="row">
        <div class="com-padd">
          <div class="list-pg-lt list-page-com-p">
            <app-details-about [source]="'experience'" (request)="sendData($event)"
                               [etatExperienceFavorite]="etatExperienceFavorite"
                               [details]="experience" id="about" *ngIf="doneDetails && doneForExpFavorite"
                               (shareOnFacebook)="shareFacebook($event)"
                               (shareOnTwitter)="shareTwitter($event)"
                               (shareOnLinkedin)="shareLinkedin($event)"
                               [disabledButtonFavorite]="disabledButtonFavorite"></app-details-about>
            <app-details-gallery id="gallery" [gallery]="gallery" [source]="'experience'"
                                 [video]="video" *ngIf="gallery && gallery.length !== 0 || video"
                                 (openVideo)="openVideo($event)"></app-details-gallery>
            <app-details-write-review id="write_review" [connectedUser]="connectedUser"
                                      (request)="sendData($event)"
                                      [singleReview]="singleReview"
                                      (cancelEditReviewEvent) = "cancelEditReview()"
                                      *ngIf="displayForm"></app-details-write-review>
            <app-own-review id="my_review" [connectedUser]="connectedUser" [review]="myReview"
                            *ngIf="ownReview" (reviewData)="sendData($event)"
                            [likes]="LikeMap" [dislikes]="DislikeMap" [etatLike]="etatLike" [etatDislike]="etatDislike"></app-own-review>
            <app-details-reviews id="user_review" [rating]="experience.rating" [reviews]="reviews"
                                 [connectedUser]="connectedUser" [reviewsCounter]="reviewsCounter"
                                 *ngIf="reviewsAreLoaded" (review)="sendData($event)"
                                 [likes]="LikeMap" [dislikes]="DislikeMap" [etatLike]="etatLike" [etatDislike]="etatDislike"></app-details-reviews>
          </div>
          <div class="list-pg-rt">
            <app-details-contact-creator
              [data]="experienceCreator"
              *ngIf="experienceCreator && experienceCreator.userId"></app-details-contact-creator>
            <br>
            <app-details-suggestions *ngIf="suggestedExperiences && suggestedExperiences.length > 0" [suggestedExperiences]="suggestedExperiences" [source]="'experience'"></app-details-suggestions>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-fixed-menu-of-connected-user *ngIf="displayEditFixedMenu || displayValidateFixedMenu" [id]="experience.id"
                                    [source]="'experience'" [displayValidateFixedMenu]="displayValidateFixedMenu"
                                    (request)="validateExp()" [disabledButton]="disabledButton"></app-fixed-menu-of-connected-user>
</div>

<app-popup-experience-video [isOpened]="videoStatus" [data]="videoData" (readingStatus)="refreshPopupStatus($event)"
                            *ngIf="videoData"></app-popup-experience-video>
<app-confirm-dialog [confirmText]="deleteText"></app-confirm-dialog>

<div class="row waiting_load" *ngIf="waitingLoad">
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
</div>
