import {
    EDIT_GROUP_PATH,
    MANAGE_EXPERIENCES_GROUPS_PATH,
    MANAGE_MEMBERS_GROUPS_PATH,
    MY_GROUPS_PATH
} from '../../utils/actionsLabels/pathLabels';
import {
    BUTTON_ACCEPT_LABEL,
    BUTTON_CONTACT_ADMINS_LABEL,
    BUTTON_DELETE_GROUP_LABEL,
    BUTTON_DISCARD_LABEL,
    BUTTON_EDIT_INFO_LABEL,
    BUTTON_JOIN_LABEL,
    BUTTON_LEAVE_LABEL,
    BUTTON_MANAGE_EXPERIENCES_LABEL,
    BUTTON_MANAGE_MEMBERS_LABEL,
    SECTION_ABOUT_LABEL,
    SECTION_EXPERIENCES_LABEL,
    SECTION_MEMBERS_LABEL
} from '../../utils/actionsLabels/labels';
import {GroupTypes, RoleGroup} from '../../utils/global-constants/groups-const';

/*
GroupTypes {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
  CLOSED = 'CLOSED',
}
RoleGroup {
  MANAGER = 'MANAGER',
  MEMBER = 'MEMBER',
  NOT_MEMBER = 'NOT_MEMBER'
}

ActionOnGroup {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}
* */
export function getPageStructure(userRole, groupType, id) {
    switch (userRole) {
        case RoleGroup.ANONYMOUS:
            switch (groupType) {
                case GroupTypes.PUBLIC:
                    return getAnonymousPublicStructure(id);
            }
            break;
        case RoleGroup.NOT_MEMBER:
            switch (groupType) {
                case GroupTypes.PUBLIC:
                    return getNotMemberPublicStructure(id);
                case GroupTypes.PRIVATE:
                    return getNotMemberPrivateStructure(id);
            }
            break;
        case RoleGroup.NOT_MEMBER_REQUEST:
        case RoleGroup.REQUESTED:
            switch (groupType) {
                case GroupTypes.PUBLIC:
                    return getNotMemberRequestPublicStructure(id);
                case GroupTypes.PRIVATE:
                    return getNotMemberRequestPrivateStructure(id);
            }
            break;
        case RoleGroup.INVITED:
            switch (groupType) {
                case GroupTypes.PUBLIC:
                    return getInvitedMemberPublicStructure(id);
                case GroupTypes.PRIVATE:
                    return getInvitedMemberPrivateStructure(id);
            }
            break;
        case RoleGroup.MEMBER:
            return getMemberStructure(id);
        case RoleGroup.MANAGER:
            return getAdminStructure(id);
    }

    return null;
}

//****************** MANAGER **********************************
function getAdminStructure(id) {
    return {
        left: [
            {
                type: 'about-section',
                label: SECTION_ABOUT_LABEL
            },
            {
                type: 'experiences-section',
                label: SECTION_EXPERIENCES_LABEL,
                experiencesList: true,
                addExperiencesButton: true,
            },
        ],
        right: [
            {
                type: 'buttons-section',
                buttons: [
                    {
                        type: 'blue-button',
                        label: BUTTON_EDIT_INFO_LABEL,
                        icon: 'fa fa-heart',
                        click: 'edit-group',
                        path: MY_GROUPS_PATH + '/' + id + EDIT_GROUP_PATH
                    },
                    {
                        type: 'white-button',
                        label: BUTTON_MANAGE_EXPERIENCES_LABEL,
                        icon: 'fa fa-pencil-square-o',
                        click: 'manage-experiences',
                        path: MY_GROUPS_PATH + '/' + id + MANAGE_EXPERIENCES_GROUPS_PATH
                    },
                    {
                        type: 'white-button',
                        label: BUTTON_MANAGE_MEMBERS_LABEL,
                        icon: 'fa fa-users',
                        click: 'manage-members',
                        path: MY_GROUPS_PATH + '/' + id + MANAGE_MEMBERS_GROUPS_PATH
                    },
                ]
            },
            {
                type: 'members-section',
                label: SECTION_MEMBERS_LABEL,
                membersList: true,
            },
            {
                type: 'suggested-groups-section',
            },
            {
                type: 'buttons-section',
                buttons: [
                    {
                        type: 'red-button',
                        label: BUTTON_DELETE_GROUP_LABEL,
                        click: 'delete-group',
                    },
                ]
            },

        ]
    };
}

//****************** MANAGER **********************************
function getMemberStructure(id) {
    return {
        left: [
            {
                type: 'about-section',
                label: SECTION_ABOUT_LABEL
            },
            {
                type: 'experiences-section',
                label: SECTION_EXPERIENCES_LABEL,
                experiencesList: true,
                addExperiencesButton: true,
            },
        ],
        right: [
            {
                type: 'buttons-section',
                buttons: [
                    {
                        type: 'pink-button',
                        label: BUTTON_LEAVE_LABEL,
                        click: 'leave-group',
                    },
                    {
                        type: 'white-button',
                        label: BUTTON_MANAGE_EXPERIENCES_LABEL,
                        icon: 'fa fa-pencil-square-o',
                        click: 'manage-experiences',
                        path: MY_GROUPS_PATH + '/' + id + MANAGE_EXPERIENCES_GROUPS_PATH
                    },
                    // {
                    //     type: 'red-button',
                    //     label: BUTTON_CONTACT_ADMINS_LABEL,
                    //     click: 'coming-soon',
                    // },
                ]
            },
            {
                type: 'members-section',
                label: SECTION_MEMBERS_LABEL,
                membersList: true,
            },
            {
                type: 'suggested-groups-section',
            },

        ]
    };
}

function getNotMemberPublicStructure(id) {
    return {
        left: [
            {
                type: 'about-section',
                label: SECTION_ABOUT_LABEL
            },
            {
                type: 'experiences-section',
                label: SECTION_EXPERIENCES_LABEL,
                experiencesList: true,
            },
        ],
        right: [
            {
                type: 'buttons-section',
                buttons: [
                    {
                        type: 'blue-button',
                        label: BUTTON_JOIN_LABEL,
                        click: 'join-group',
                    },
                    // {
                    //     type: 'red-button',
                    //     label: BUTTON_CONTACT_ADMINS_LABEL,
                    //     click: 'coming-soon',
                    // },
                ]
            },
            {
                type: 'members-section',
                label: SECTION_MEMBERS_LABEL,
                membersList: true,
            },
            {
                type: 'suggested-groups-section',
            },

        ]
    };
}

function getNotMemberPrivateStructure(id) {
    return {
        left: [
            {
                type: 'about-section',
                label: SECTION_ABOUT_LABEL
            },
            {
                type: 'experiences-section',
                label: SECTION_EXPERIENCES_LABEL,
                experiencesNbr: true,
            },
        ],
        right: [
            {
                type: 'buttons-section',
                buttons: [
                    {
                        type: 'blue-button',
                        label: BUTTON_JOIN_LABEL,
                        click: 'join-group',
                    },
                    // {
                    //     type: 'red-button',
                    //     label: BUTTON_CONTACT_ADMINS_LABEL,
                    //     click: 'coming-soon',
                    // },
                ]
            },
            {
                type: 'members-section',
                label: SECTION_MEMBERS_LABEL,
                membersNbr: true,
            },
            {
                type: 'suggested-groups-section',
            },

        ]
    };
}

function getNotMemberRequestPublicStructure(id) {
    return {
        left: [
            {
                type: 'about-section',
                label: SECTION_ABOUT_LABEL
            },
            {
                type: 'experiences-section',
                label: SECTION_EXPERIENCES_LABEL,
                experiencesList: true,
            },
        ],
        right: [
            {
                type: 'buttons-section',
                buttons: [
                    {
                        type: 'white-button',
                        label: BUTTON_DISCARD_LABEL,
                        icon: 'fas fa-eject',
                        click: 'discard-request',
                    },
                    // {
                    //     type: 'red-button',
                    //     label: BUTTON_CONTACT_ADMINS_LABEL,
                    //     click: 'coming-soon',
                    // },
                ]
            },
            {
                type: 'members-section',
                label: SECTION_MEMBERS_LABEL,
                membersList: true,
            },
            {
                type: 'suggested-groups-section',
            },

        ]
    };
}

function getNotMemberRequestPrivateStructure(id) {
    return {
        left: [
            {
                type: 'about-section',
                label: SECTION_ABOUT_LABEL
            },
            {
                type: 'experiences-section',
                label: SECTION_EXPERIENCES_LABEL,
                experiencesNbr: true,
            },
        ],
        right: [
            {
                type: 'buttons-section',
                buttons: [
                    {
                        type: 'white-button',
                        label: BUTTON_DISCARD_LABEL,
                        icon: 'fas fa-eject',
                        click: 'discard-request',
                    },
                    // {
                    //     type: 'red-button',
                    //     label: BUTTON_CONTACT_ADMINS_LABEL,
                    //     click: 'coming-soon',
                    // },
                ]
            },
            {
                type: 'members-section',
                label: SECTION_MEMBERS_LABEL,
                membersNbr: true,
            },
            {
                type: 'suggested-groups-section',
            },

        ]
    };
}


function getInvitedMemberPublicStructure(id) {
    return {
        left: [
            {
                type: 'about-section',
                label: SECTION_ABOUT_LABEL
            },
            {
                type: 'experiences-section',
                label: SECTION_EXPERIENCES_LABEL,
                experiencesList: true,
            },
        ],
        right: [
            {
                type: 'buttons-section',
                buttons: [
                    {
                        type: 'green-button',
                        label: BUTTON_ACCEPT_LABEL,
                        icon: 'fas fa-eject',
                        click: 'accept-invitation',
                    },
                    // {
                    //     type: 'red-button',
                    //     label: BUTTON_CONTACT_ADMINS_LABEL,
                    //     click: 'coming-soon',
                    // },
                ]
            },
            {
                type: 'members-section',
                label: SECTION_MEMBERS_LABEL,
                membersList: true,
            },
            {
                type: 'suggested-groups-section',
            },

        ]
    };
}

function getInvitedMemberPrivateStructure(id) {
    return {
        left: [
            {
                type: 'about-section',
                label: SECTION_ABOUT_LABEL
            },
            {
                type: 'experiences-section',
                label: SECTION_EXPERIENCES_LABEL,
                experiencesNbr: true,
            },
        ],
        right: [
            {
                type: 'buttons-section',
                buttons: [
                    {
                        type: 'green-button',
                        label: BUTTON_ACCEPT_LABEL,
                        click: 'accept-invitation',
                    },
                    // {
                    //     type: 'red-button',
                    //     label: BUTTON_CONTACT_ADMINS_LABEL,
                    //     click: 'coming-soon',
                    // },
                ]
            },
            {
                type: 'members-section',
                label: SECTION_MEMBERS_LABEL,
                membersNbr: true,
            },
            {
                type: 'suggested-groups-section',
            },

        ]
    };
}


function getAnonymousPublicStructure(id) {
    return {
        left: [
            {
                type: 'about-section',
                label: SECTION_ABOUT_LABEL
            },
            {
                type: 'experiences-section',
                label: SECTION_EXPERIENCES_LABEL,
                experiencesList: true,
            },
        ],
        right: [
            {
                type: 'buttons-section',
                buttons: [
                    {
                        type: 'blue-button',
                        label: BUTTON_JOIN_LABEL,
                        click: 'login-popup',
                    },
                    // {
                    //     type: 'red-button',
                    //     label: BUTTON_CONTACT_ADMINS_LABEL,
                    //     click: 'coming-soon',
                    //     icon: 'fas fa-eject'
                    // },
                ]
            },
            {
                type: 'members-section',
                label: SECTION_MEMBERS_LABEL,
                membersList: true,
            },
            {
                type: 'suggested-groups-section',
            },

        ]
    };
}
