import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {
  ASK_FOR_CONFIRM_DELETE_EXPERIENCES_ACTION,
  FAILED_ADD_EXPERIENCE_GROUP_MESSAGE_CONTENT,
  FAILED_ADD_EXPERIENCE_GROUP_MESSAGE_TITLE,
  FAILED_DELETE_EXPERIENCE_GROUP_MESSAGE_CONTENT,
  FAILED_DELETE_EXPERIENCE_GROUP_MESSAGE_TITLE,
  MANAGE_EXPERIENCES_GROUP_PAGE_TITLE,
  PAGE_TITLE_PREFIX_MANAGE_EXPERIENCES,
  SUCCESS_ADD_EXPERIENCE_GROUP_MESSAGE_TITLE,
  SUCCESS_DELETE_EXPERIENCES_MESSAGE_TITLE,
} from '../../../../utils/actionsLabels/labels';
import {GROUPS_PATH, MY_EXPERIENCES_PATH} from '../../../../utils/actionsLabels/pathLabels';
import {map} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {ExperiencesService} from '../../../services/experiences.service';
import {HttpParams} from '@angular/common/http';
import {TokenHelperService} from '../../../services/token-helper.service';
import {Experience, GetExperienceDeletedResponse, GetExperienceResponse} from '../../../interfaces/experience';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Group} from '../../../interfaces/group';
import {GroupsService} from '../../../services/groups.service';
import {NotificationService} from '../../../services/notification.service';
import {IS_IN_GROUP, OWNER_GROUP} from '../../../../utils/queryParams/groupsQueryParams';
import {ConfirmDialogService} from '../../confirm-dialog/confirm-dialog.service';
import {Title} from '@angular/platform-browser';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-manage-experiences',
  templateUrl: './manage-experiences.component.html',
  styleUrls: ['./manage-experiences.component.scss' , '../../../../../assets/customCss/waiting-load.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageExperiencesComponent implements OnInit {
  pageTitle = MANAGE_EXPERIENCES_GROUP_PAGE_TITLE;
  groupId: string; // current group id
  groupName: string; // current group id
  userId: string; // current user id
  showMyGroup = false;

  // add section
  addedExperiences = [];
  myExperiences: Experience[];
  showMyExperiences = false;
  createExperience = MY_EXPERIENCES_PATH;
  formAddExperiences: FormGroup;

  // delete section
  experiencesInGroup: Experience[];
  showMyExperiencesInGroup = false;
  deletedExperiences = [];

  constructor(private route: ActivatedRoute,
              private tokenHelperService: TokenHelperService,
              private groupsService: GroupsService,
              private titleService: Title,
              private experiencesService: ExperiencesService,
              private notificationService: NotificationService,
              private translate: TranslateService,
              private router: Router,
              private confirmDialogService: ConfirmDialogService) { }

  ngOnInit() {
    // this.titleService.setTitle(PAGE_TITLE_PREFIX_MANAGE_EXPERIENCES);
    this.translate.get(PAGE_TITLE_PREFIX_MANAGE_EXPERIENCES).subscribe((res: string) => {
      this.titleService.setTitle(res);
    });
    this.experiencesInGroup = [];
    this.formAddExperiences = new FormGroup({
      addedExperiences: new FormControl('', [Validators.required])
    });
    // get user id
    if (this.tokenHelperService.getConnectedUser()) {
      this.userId = this.tokenHelperService.getTokenData().user_id;
    }
    // get group
    this.route.paramMap.pipe(
      map(params => {
          this.groupId = params.get('id');
          this.getGroup();
        }
      )
    ).subscribe(() => {
    });
    // get my experiences
    this.getMyExperiences();
    // get  experiences in group
    this.getGroupExperiences();
  }

  getGroup() {
    this.groupsService.getGroupById(this.groupId).subscribe((data: Group) => {
      this.groupName = data.name;
      this.showMyGroup = true;
      this.translate.get(PAGE_TITLE_PREFIX_MANAGE_EXPERIENCES).subscribe((res: string) => {
        this.titleService.setTitle(res +  this.groupName);
      });
    });
  }
  getMyExperiences() {
    let params = new HttpParams();
    params = params.append(OWNER_GROUP, this.userId);
    params = params.append(IS_IN_GROUP, '0');
    // TODO get public experinces + not added to this group
    this.groupsService.getGroupExperiences(this.groupId, params).subscribe((data: { list_experiences: Experience[],
      number_experiences_group: number }) => {
      this.myExperiences = data.list_experiences;
      this.showMyExperiences = true;
    });
  }

  getGroupExperiences() {
    let params = new HttpParams();
    params = params.append('user', this.userId);
    this.groupsService.getGroupExperiences(this.groupId, params).subscribe((data: any) => {
      if (data && data.list_experiences && data.list_experiences.length > 0) {
        this.experiencesInGroup = data.list_experiences;
      }
      this.showMyExperiencesInGroup = true;
    });
  }

  onSubmit() {

    if (this.addedExperiences.length !== 0) {
      const experiences = [];
      this.addedExperiences.forEach((exp: Experience) => {
        experiences.push({experience_id: exp.id});
      });
      this.groupsService.addExperiencesToGroup(this.groupId, experiences).subscribe((response: GetExperienceResponse) => {
        response.list_added_experiences.forEach((exp) => this.notificationService.showSuccess('',
          SUCCESS_ADD_EXPERIENCE_GROUP_MESSAGE_TITLE));
        response.list_added_experiences_error.forEach((exp) => this.notificationService.showError(
          FAILED_ADD_EXPERIENCE_GROUP_MESSAGE_CONTENT, FAILED_ADD_EXPERIENCE_GROUP_MESSAGE_TITLE));
      });
    }
    if (this.deletedExperiences.length !== 0) {
      const getResponse = (exp) => { return {experienceId: exp.id}; };
      this.deletedExperiences = this.deletedExperiences.map(exp =>  getResponse(exp) );

      this.confirmDialogService.confirmThis(ASK_FOR_CONFIRM_DELETE_EXPERIENCES_ACTION +  '"?', () => {
        this.router.navigateByUrl(GROUPS_PATH + '/' + this.groupId).then();
        this.groupsService.deleteExperiences(this.deletedExperiences, this.groupId).subscribe((response: GetExperienceDeletedResponse) => {
          response.deleted_experiences.forEach((exp) => this.notificationService.showSuccess('',
            SUCCESS_DELETE_EXPERIENCES_MESSAGE_TITLE));
          response.invalid_experiences_ids.forEach((exp) => this.notificationService.showError(
            FAILED_DELETE_EXPERIENCE_GROUP_MESSAGE_CONTENT, FAILED_DELETE_EXPERIENCE_GROUP_MESSAGE_TITLE));
        });
      }, () => {
      });
    } else {
      this.router.navigateByUrl(GROUPS_PATH + '/' + this.groupId).then();
    }
  }


  onRemoveExperience(event: Experience) {
    this.addedExperiences = this.addedExperiences.filter(exp => exp !== event);
  }
  onDeleteExperience(event: Experience) {
    this.deletedExperiences.push(event);
  }
  onCancelDeleteExperience(event: Experience) {
    this.deletedExperiences = this.deletedExperiences.filter(exp => exp !== event);
  }
  isSeletedToBeDeleted(deletedExperience: Experience) {
    return this.deletedExperiences.filter(exp => exp === deletedExperience)[0];
  }

  cancel() {
    window.history.back();
  }
}
