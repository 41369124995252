import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from '../../reusables/services/users.service';
import {Title} from '@angular/platform-browser';
import {User} from '../../reusables/interfaces/user';
import {
  CANCEL_ACTION,
  EDIT_PASSWORD_ACTION,
  EDIT_PROFILE_ACTION,
  FAILED_CHANGE_PASSWORD_CONTENT,
  FAILED_CHANGE_PASSWORD_TITLE,
  PROFILE_PAGE_TITLE,
  SAVE_CHANGES_ACTION,
  SUCCESS_CHANGE_PASSWORD_TITLE,
  SUCCESS_EDIT_PROFILE_MESSAGE_CONTENT,
  SUCCESS_EDIT_PROFILE_MESSAGE_TITLE
} from '../../utils/actionsLabels/labels';
import {CookieService} from 'ngx-cookie-service';
import {NotificationService} from '../../reusables/services/notification.service';
import {TokenHelperService} from '../../reusables/services/token-helper.service';
import {auth} from 'firebase/app';
import {AngularFireAuth} from '@angular/fire/auth';
import {AuthGuard} from '../../reusables/_helpers';
import {AuthenticationService} from '../../reusables/services/authentication.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss',  '../../../assets/customCss/waiting-load.scss']
})
export class ProfileComponent implements OnInit {

  userId: string;
  user: User;
  doneForUserProfile = false;
  showEditPassword = false;
  showEditProfile = false;
  showInfo = true;
  waitingLoad = false;
  showProfileAfterEdit = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fireAuth: AngularFireAuth,
              private usersService: UsersService,
              private authenticationService: AuthenticationService,
              private titleService: Title,
              private cookieService: CookieService,
              private notificationService: NotificationService,
              private tokenHelperService: TokenHelperService,
              private authGuard: AuthGuard) {

  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    if (this.authGuard.canActivate) {
      this.getProfile();
    }
  }

  changeAction(event) {
    if (event.action && event.action !== '') {
      switch (event.action) {
        case CANCEL_ACTION:
          this.showEditPassword = false;
          this.showEditProfile = false;
          break;
        case EDIT_PASSWORD_ACTION:
          this.showEditPassword = !this.showEditPassword;
          break;
        case EDIT_PROFILE_ACTION:
          if (event.request) {
            this.waitingLoad = true;
            this.editProfile(event.request);
          } else {
            this.showEditProfile = !this.showEditProfile;
          }
          break;
        case SAVE_CHANGES_ACTION:
          this.editPassword(event.data);
          break;
      }
    }
  }

  editPassword(data) {
    const formData = new FormData();
    formData.append('email', this.user.email);
    formData.append('old_password', data.currentPassword);
    formData.append('new_password', data.password);
    this.usersService.changePassword(this.user.id, formData).subscribe(() => {
      this.fireAuth.signInWithEmailAndPassword(this.user.email, data.password)
        .then(() => {
          auth().currentUser.getIdToken(/* forceRefresh */ true).then((IdToken) => {
            this.authenticationService.login(IdToken);
            this.notificationService.showSuccess('', SUCCESS_CHANGE_PASSWORD_TITLE);
            this.showEditPassword = false;
            this.showEditProfile = false;
          });
        });
    }, () => {
      // this.notificationService.showError(FAILED_CHANGE_PASSWORD_CONTENT, FAILED_CHANGE_PASSWORD_TITLE);
    });
  }

  editProfile(data) {
    this.usersService.editProfile(this.user.id, data).subscribe((response: User) => {
      this.waitingLoad = false;
      this.showProfileAfterEdit = false;
      this.getProfile();
      this.showEditProfile = !this.showEditProfile;
      this.notificationService.showSuccess(SUCCESS_EDIT_PROFILE_MESSAGE_CONTENT, SUCCESS_EDIT_PROFILE_MESSAGE_TITLE);
      document.getElementById('connected-menu-change-profile-image').click()
    });
  }

  getProfile() {
    this.usersService.getUserProfile().subscribe((data: User) => {
      this.user = data;
      this.titleService.setTitle(PROFILE_PAGE_TITLE);
      this.doneForUserProfile = true;
      this.showProfileAfterEdit = true;
    });
  }
}
