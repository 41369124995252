import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import * as $ from 'jquery';
import {FormControl} from '@angular/forms';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {
  dayVariationTime,
  monthVariationTime,
  weekVariationTime,
  yearVariationTime
} from '../../../utils/global-constants/variationTimeConst';
import {ChartDataSets, ChartOptions} from 'chart.js';
import {Color, Label} from 'ng2-charts';
import {AnalyticsChart} from '../../interfaces/analyticsChart';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-analytics-chart',
  templateUrl: './analytics-chart.component.html',
  styleUrls: ['./analytics-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnalyticsChartComponent implements OnInit {
  readonly xLabelHours = 'Hours';
  readonly xLabelDays = 'Days';
  readonly xLabelMonths = 'Months';

  @Input() doneForAnalyticsChart: boolean;
  @Input() analyticsChart: AnalyticsChart[];
  @Output() chartDurationChangedEvent = new EventEmitter();
  endDateFormControl: FormControl;
  startDateFormControl: FormControl;
  variationTime: string;
  private startDate: Date = new Date();
  private endDate: Date = new Date();
  dayVariationTime = dayVariationTime;

  lineChartData: ChartDataSets[] = [];
  lineChartLabels: Label[] = [];

  // Define chart options
  lineChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: this.xLabelDays
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'visitors'
        }
      }]
    }
  };

  // Define colors of chart segments
  lineChartColors: Color[] = [
    { // dark grey
      backgroundColor: 'rgba(23,121,211,0.2)',
      borderColor: '#1779D3',
    }
  ];

  // Set true to show legends
  lineChartLegend = false;

  // Define type of chart
  lineChartType = 'line';

  lineChartPlugins = [];

  constructor() {
  }

  ngOnInit(): void {
    this.variationTime = monthVariationTime;
    this.updateDates(null, new Date());
    // this.updateData();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnChanges(changes: SimpleChanges): void {
    this.updateData();
  }

  changeSelection(selection: number) {
    $('.span-link').removeClass('active');
    $('.span-link.action-' + selection).addClass('active');
    switch (selection) {
      case 0:
        this.variationTime = dayVariationTime;
        this.lineChartOptions = {
          responsive: true,
          scales: {
            xAxes: [{
              scaleLabel: {
                display: true,
                labelString: this.xLabelHours
              }
            }],
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'visitors'
              }
            }]
          }
        };
        this.updateDates(null, new Date());
        // this.updateData();
        break;
      case 1:
        this.variationTime = weekVariationTime;
        this.lineChartOptions = {
          responsive: true,
          scales: {
            xAxes: [{
              scaleLabel: {
                display: true,
                labelString: this.xLabelDays
              }
            }],
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'visitors'
              }
            }]
          }
        };
        this.updateDates(null, new Date());
        // this.updateData();
        break;
      case 2:
        this.variationTime = monthVariationTime;
        this.lineChartOptions = {
          responsive: true,
          scales: {
            xAxes: [{
              scaleLabel: {
                display: true,
                labelString: this.xLabelDays
              }
            }],
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'visitors'
              }
            }]
          }
        };
        this.updateDates(null, new Date());
        // this.updateData();
        break;

      case 3:
        this.variationTime = yearVariationTime;
        this.lineChartOptions = {
          responsive: true,
          scales: {
            xAxes: [{
              scaleLabel: {
                display: true,
                labelString: this.xLabelMonths
              }
            }],
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'visitors'
              }
            }]
          }
        };
        this.updateDates(null, new Date());
        // this.updateData();
        break;
    }
  }

  updateData() {
    const dates = [];
    const values = [];
    this.analyticsChart.forEach(value => {
        switch (this.variationTime) {
          case dayVariationTime:
            // dates.push( formatDate(ZonedDate.fromLocalDate(new Date(value.date)), 'shortTime', navigator.language));
            dates.push(formatDate(value.date, 'shortTime', navigator.language));
            break;
          case weekVariationTime:
            dates.push(formatDate(value.date, 'shortDate', navigator.language));
            break;
          case monthVariationTime:
            dates.push(formatDate(value.date, 'shortDate', navigator.language));
            break;
          case yearVariationTime:
            dates.push(formatDate(value.date, 'shortDate', navigator.language));
            break;
        }
        values.push(value.value);
      }
    );

    this.lineChartLabels = dates;
    // tslint:disable-next-line:label-position
    this.lineChartData = [
      {data: values, lineTension: 0}
    ];
  }

  updateDates(startDate?: Date, endDate?: Date) {
    if (endDate != null) {
      this.startDate = new Date(endDate.getTime());
      this.endDate = new Date(endDate.getTime());
      switch (this.variationTime) {
        case dayVariationTime:
          break;
        case weekVariationTime:
          this.endDate = endDate;
          this.startDate.setDate(endDate.getDate() - 7);
          break;
        case monthVariationTime:
          this.endDate = endDate;
          this.startDate.setMonth(endDate.getMonth() - 1);
          break;
        case yearVariationTime:
          this.endDate = endDate;
          this.startDate.setFullYear(endDate.getFullYear() - 1);
          break;
      }
    } else if (startDate != null) {
      this.startDate = new Date(startDate.getTime());
      this.endDate = new Date(startDate.getTime());
      switch (this.variationTime) {
        case dayVariationTime:
          break;
        case weekVariationTime:
          this.startDate = startDate;
          this.endDate.setDate(startDate.getDate() + 7);
          break;
        case monthVariationTime:
          this.startDate = startDate;
          this.endDate.setMonth(startDate.getMonth() + 1);
          break;
        case yearVariationTime:
          this.startDate = startDate;
          this.endDate.setFullYear(startDate.getFullYear() + 1);
          break;
      }
    }
    this.startDateFormControl = new FormControl(this.startDate);
    this.endDateFormControl = new FormControl(this.endDate);

    const dataRequest = {
      from: this.startDate,
      to: this.endDate,
      type: this.variationTime
    };
    this.chartDurationChangedEvent.emit(dataRequest);
  }

  startDateChangedEvent(event: MatDatepickerInputEvent<Date>) {
    this.updateDates(event.value, null);
  }

  endDateChangedEvent(event: MatDatepickerInputEvent<Date>) {
    this.updateDates(null, event.value);
  }

  // chart events
  chartClicked({event, active}: { event: MouseEvent, active: {}[] }): void {
  }

  chartHovered({event, active}: { event: MouseEvent, active: {}[] }): void {
  }
}
