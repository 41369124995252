// page titles
import {EditorElementType} from '../global-constants/editor-const';

export const AR_WEB = 'Camlann';
export const PAGE_TITLE_PREFIX = 'Camlann - ';
export const EXPERIENCES_SECTION_TITLE = 'Experiences';
export const TEMPLATES_SECTION_TITLE = 'Templates';
export const INFORMATION_PAGE_TITLE = 'Informations';
export const ALL_EXPERIENCES_PAGE_TITLE = 'All experiences';
export const ALL_TEMPLATES_PAGE_TITLE = 'All templates';
export const SUGGESTED_GROUPS_PAGE_TITLE = 'Suggested groups';

export const HOME_PAGE_TITLE = 'HOME_PAGE_TITLE';
export const PAGE_TITLE_PREFIX_EDIT_MY_GROUP = 'PAGE_TITLE_PREFIX_EDIT_MY_GROUP';
export const PAGE_TITLE_PREFIX_MANAGE_EXPERIENCES = 'PAGE_TITLE_PREFIX_MANAGE_EXPERIENCES';
export const PAGE_TITLE_PREFIX_MANAGE_MEMBERS = 'PAGE_TITLE_PREFIX_MANAGE_MEMBERS';
export const EXPERIENCES_PAGE_TITLE = 'EXPERIENCES_PAGE_TITLE';
export const SIGNIN_PAGE_TITLE = 'SIGNIN_PAGE_TITLE';
export const SIGNUP_PAGE_TITLE = 'SIGNUP_PAGE_TITLE';
export const FORGET_PASSWORD_PAGE_TITLE = 'FORGET_PASSWORD_PAGE_TITLE';
export const RESET_PASSWORD_PAGE_TITLE = 'RESET_PASSWORD_PAGE_TITLE';
export const EDITOR_PAGE_TITLE = 'EDITOR_PAGE_TITLE';
export const NOT_FOUND_PAGE_TITLE = 'NOT_FOUND_PAGE_TITLE';
export const MY_ASSETS_LIBRARY_PAGE_TITLE = 'MY_ASSETS_LIBRARY_PAGE_TITLE';
export const INVITATION_PAGE_TITLE = 'INVITATION_PAGE_TITLE';
export const REQUEST_PAGE_TITLE = 'REQUEST_PAGE_TITLE';
export const MODERATE_PAGE_TITLE = 'MODERATE_PAGE_TITLE';

// TODO: translate

export const PROFILE_PAGE_TITLE = 'Camlann - Profile';
export const DASHBOARD_PAGE_TITLE = 'Camlann - Dashboard';
export const MY_EXPERIENCES_PAGE_TITLE = 'Camlann - My experiences';
export const MY_GROUPS_PAGE_TITLE = 'Camlann - My groups';
export const CREATE_ASSET_PAGE_TITLE = 'Camlann - Create asset';
export const COMING_SOON_PAGE_TITLE = 'Camlann - Coming Soon';
export const MY_FAVORITES_PAGE_TITLE = 'Camlann - My favorites';

// error message
export const ERROR_TEXT = 'An error has occurred';
export const ERROR_MESSAGE = 'please try again';
export const COMING_SOON = 'Coming Soon';
export const COMING_SOON_FEATURE_TITLE = 'Coming soon';

// error message popups
export const ERROR_POPUP = 'Error';
export const ERROR_POPUP_MESSAGE = 'something went wrong ...';

// cookies values
export const ACCESS_TOKEN = 'access_token';
export const COOKIES_CONNECTED_USER_REFRESH_TOKEN = 'refresh_token';
export const COOKIES_CONNECTED_USER_ID_TOKEN = 'id_token';
export const COOKIES_CONNECTED_USER_ID = 'connected_user_id';
export const COOKIES_CONNECTED_USER_EMAIL = 'connected_user_email';

// logout service
export const YOU_ARE_NOT_CONNECTED = 'Your session is closed';
export const CONNECT_TO_CREATE_EXPERIENCE = 'Login to enjoy our AR experiences to the fullest!';

// page: login
export const ACCOUNT_NOT_VERIFIED = 'This account is not verified';
export const ACCOUNT_NOT_VERIFIED_MSG = 'Please check your email and follow the steps to verify your account';
export const FAILED_LOGIN_MESSAGE = 'Email or password invalid';

// page: main menu connected
export const PROFILE1 = 'profile';
export const DASHBOARD1 = 'dashboard';

// my account menu
export const LABEL_DASHBOARD = 'My dashboard';
export const LABEL_MY_PROFILE = 'My profile';
export const LABEL_MY_EXPERIENCES = 'My experiences';
export const LABEL_CREATE_NEW_EXPERIENCE = 'Create experience';
export const LABEL_MY_TEMPLATES = 'My templates';
export const LABEL_CREATE_NEW_TEMPLATE = 'Create template';
export const LABEL_MY_GROUPS = 'My groups';
export const LABEL_MY_ASSETS_LIBRARY = 'My assets library';
export const LABEL_CREATE_NEW_ASSET = 'Add asset';
export const LABEL_CREATE_NEW_GROUP = 'Create group';
export const LABEL_SETTINGS = 'Settings';
export const LABEL_MY_FAVORITES = 'My favorites';
export const LABEL_MODERATE = 'Moderate';

// page:  experience details
export const SCROLL_TO_ABOUT = 'about';
export const SCROLL_TO_GALLERY = 'gallery';
export const SCROLL_TO_WRITE_REVIEW = 'write_review';
export const SCROLL_TO_MY_REVIEW = 'my_review';
export const SCROLL_TO_USER_REVIEW = 'user_review';
export const SCROLL_TO_OWN_REVIEW = 'owner';
export const WRITE_REVIEW_ADD = 'add_review';
export const WRITE_REVIEW_UPDATE = 'update_review';
export const WRITE_REVIEW_EDIT = 'edit_review';
export const WRITE_REVIEW_DELETE = 'delete_review';
export const RATING_5 = 'Excellent - 5 stars';
export const RATING_4 = 'Good - 4 stars';
export const RATING_3 = 'Fair - 3 stars';
export const RATING_2 = 'Poor  - 2 stars';
export const RATING_1 = 'Bad - 1 star';
export const ASK_FOR_CONFIRM_DELETE_ACTION = 'Do you really want to delete it ?';

export const SUCCESS_ADD_REVIEW_MESSAGE_TITLE = 'Your review was successfully added. It will be published when moderator validate it.';
export const SUCCESS_ADMIN_ADD_REVIEW_MESSAGE_TITLE = 'Your review was successfully added.';
export const SUCCESS_ADD_REVIEW_MESSAGE_CONTENT = 'Thank you for adding your review!';

export const SUCCESS_EDIT_REVIEW_MESSAGE_TITLE = 'Your review was successfully updated. It will be published when moderator validate it.';
export const SUCCESS_ADMIN_EDIT_REVIEW_MESSAGE_TITLE = 'Your review was successfully updated.';
export const SUCCESS_EDIT_REVIEW_MESSAGE_CONTENT = 'Thank you for adding your review!';

export const SUCCESS_DELETE_REVIEW_MESSAGE_TITLE = 'Your review was successfully deleted.';

export const FAILED_READ_REVIEW_MESSAGE_TITLE = 'There is a problem somewhere';
export const FAILED_READ_REVIEW_MESSAGE_CONTENT = 'Please check your internet connection';

// page: form experience
export const FAILED_RESPONSE_TITLE = 'Try again please';
export const FAILED_ACTION = 'Some problem is occurred';
export const FAILED_ADD_EXPERIENCE_MESSAGE_TITLE = 'Your experience couldn\'t be created.';
export const FAILED_ADD_EXPERIENCE_MESSAGE_CONTENT = 'Your experience couldn\'t be created. Please try again';
export  const ASK_FOR_CONFIRM_DELETE_EXPERIENCE_GROUP_ACTION = 'Do you really want to delete experience from this group ?';
export  const SUCCESS_DELETE_EXPERIENCE_GROUP_MESSAGE_TITLE = 'Your experience was successfully deleted from group.';
export const FAILED_DELETE_GROUP_MESSAGE_TITLE = 'This group couldn\'t be deleted.';
export const FAILED_DELETE_GROUP_MESSAGE_CONTENT = 'This group couldn\'t be deleted. Please try again';

// page: create/edit experience
export const CREATE_EXPERIENCE_BUTTON = 'Create';
export const CREATE_EXPERIENCE_BUTTON_TITLE = 'CLICK_TO_CREATE_YOUR_EXPERIENCE';
export const EDIT_EXPERIENCE_BUTTON = 'Update';
export const EDIT_EXPERIENCE_BUTTON_TITLE = 'CLICK_TO_UPDATE_YOUR_EXPERIENCE';
export const NEW_EXPERIENCE_EVENT = 'add';
export const EDIT_EXPERIENCE_EVENT = 'edit';
export const CREATE_EXPERIENCE_PAGE_TITLE = 'Create experience';
export const EDIT_EXPERIENCE_PAGE_TITLE = 'Edit experience';
export const YOU_HAVE_UPLOAD_8_IMAGES = 'You have already attached 8 images';
export const CAN_NOT_UPLOAD_MORE = 'You can no longer add images';
export const CAN_NOT_ALLOWED_OS = 'Please use your Desktop to edit an experience.';
export const CAN_NOT_ALLOWED_OS_TITLE = 'Open the editor on desktop';
export const BLANK_EXPERIENCE = 'blank experience';

// page: create/edit experience: cover picture opeartion
export const ADD_COVER_PICTURE_OPERATION = 'add';
export const REPLACE_COVER_PICTURE_OPERATION = 'replace';
export const DELETE_COVER_PICTURE_OPERATION = 'delete';
export const NONE_COVER_PICTURE_OPERATION = 'none';

// page: profile my experiences
export const EDIT_ACTION = 'edit';
export const DELETE_ACTION = 'delete';
export const PREVIEW_ACTION = 'preview';
export const ASK_FOR_CONFIRM_DELETE_EXPERIENCE_ACTION = 'Do you really want to delete the experience ';
export const FAILED_DELETE_EXPERIENCE_MESSAGE_TITLE = 'Your experience couldn\'t be deleted.';
export const FAILED_DELETE_EXPERIENCE_MESSAGE_CONTENT = 'Your experience couldn\'t be deleted. Please try again';
export const SUCCESS_DELETE_EXPERIENCE_MESSAGE_TITLE = 'Your experience was successfully deleted.';


// page: group details
export const SCROLL_TO_EXPERIENCES = 'experiences';
export const LEAVE_GROUP_MESSAGE_TITLE = 'You left the group!';
export const LEAVE_GROUP_MESSAGE_CONTENT = 'Group content may no longer be available for you!';
export const JOIN_GROUP_MESSAGE_TITLE = 'Congratulations!';
export const JOIN_GROUP_MESSAGE_CONTENT = 'You have now a new member in your group!';

export const SECTION_ABOUT_LABEL = 'About';
export const SECTION_EXPERIENCES_LABEL = 'Experiences';
export const SECTION_MEMBERS_LABEL = 'Members';
export const BUTTON_LEAVE_LABEL = 'Leave';
export const BUTTON_JOIN_LABEL = 'Join';
export const BUTTON_DISCARD_LABEL = 'Discard Request';
export const BUTTON_ACCEPT_LABEL = 'Accept Invitation';
export const BUTTON_CONTACT_ADMINS_LABEL = 'Contact admins';
export const BUTTON_EDIT_INFO_LABEL = 'Edit general informations';
export const BUTTON_MANAGE_EXPERIENCES_LABEL = 'Manage experiences';
export const BUTTON_MANAGE_MEMBERS_LABEL = 'Manage members';
export const BUTTON_DELETE_GROUP_LABEL = 'Delete group';
export const ASK_FOR_CONFIRM_DELETE_GROUP_ACTION = 'Do you really want to delete the group ';
export const SUCCESS_DELETE_GROUP_MESSAGE_TITLE = 'Your group was successfully deleted.';
export const SUCCESS_JOIN_REQUEST_SENT_TITLE = 'Send request to join group ';
export const SUCCESS_JOIN_REQUEST_SENT_MESSAGE = 'Your request to join this group was successfully sent';
export const SUCCESS_ADD_EXPERIENCE_GROUP_MESSAGE_TITLE = 'Your experience was successfully added.';
export const FAILED_ADD_EXPERIENCE_GROUP_MESSAGE_TITLE = 'Your experience couldn\'t be added.';
export const FAILED_ADD_EXPERIENCE_GROUP_MESSAGE_CONTENT = 'Your experience couldn\'t be added. Please try again';
export const ASK_FOR_CONFIRM_LEAVE_GROUP_ACTION = 'You will no longer allowed to see this group or the experiences shared in this group in your favorites. \n Do you really want to leave the group ';
export const SUCCESS_LEAVE_GROUP_TITLE = 'you have successfully left the group';
export const ASK_FOR_CONFIRM_ACCEPT_REQUEST_GROUP_ACTION = 'Do you really want to accept request to join the group ';
export const SUCCESS_ACCEPT_REQUEST_GROUP_TITLE = 'you have successfully accept request to join the group';

// page: suggested group
export const LABEL_SUGGESTED_GROUPS = 'Suggested groups';
export const JOIN_GROUP = 'joinGroup';
export const DETAILS_GROUP = 'detailsGroup';

// page: add edit form group
export const CREATE_GROUP_PAGE_TITLE = 'Create group';
export const EDIT_GROUP_PAGE_TITLE = 'Update group';
export const NEW_GROUP_EVENT = 'add';
export const EDIT_GROUP_EVENT = 'edit';
export const ADD_MEMBERS_TO_GROUP_EVENT = 'addMembers';
export const CREATE_GROUP_BUTTON = 'Create';
export const EDIT_GROUP_BUTTON = 'Update';
export const CREATE_GROUP_BUTTON_TITLE = 'CLICK_TO_CREATE_YOUR_GROUP';
export const EDIT_GROUP_BUTTON_TITLE = 'CLICK_TO_UPDATE_YOUR_GROUP';
// ----- Add --------
export const FAILED_ADD_GROUP_MESSAGE_TITLE = 'Your group couldn\'t be created.';
export const FAILED_ADD_GROUP_MESSAGE_CONTENT = 'Your group couldn\'t be created. Please try again';
export const SUCCESS_ADD_GROUP_MESSAGE_TITLE = 'Your group was successfully created.';
export const SUCCESS_INVITE_MEMBERS_TO_GROUP_MESSAGE_TITLE = 'Members invited successfully.';
export const FAILED_INVITE_MEMBERS_TO_GROUP_MESSAGE_TITLE = 'Failed to add members to group.';
export const FAILED_INVITE_MEMBERS_TO_GROUP_MESSAGE_CONTENT = 'Couldn\'t add members to your group. Please try again.';
// ------ Edit -------
export const FAILED_EDIT_GROUP_MESSAGE_TITLE = 'Your group couldn\'t be updated.';
export const FAILED_EDIT_GROUP_MESSAGE_CONTENT = 'Your group couldn\'t be updated. Please try again';
export const SUCCESS_EDIT_GROUP_MESSAGE_TITLE = 'Your group was successfully updated.';

// -------- Manage experiences ---------------
export const MANAGE_EXPERIENCES_GROUP_PAGE_TITLE = 'Manage experiences';
export  const ASK_FOR_CONFIRM_DELETE_EXPERIENCES_ACTION = 'Do you really want to delete experiences from group?';
export  const SUCCESS_DELETE_EXPERIENCES_MESSAGE_TITLE = 'Experiences was successfully deleted from your group.';
export const FAILED_DELETE_EXPERIENCE_GROUP_MESSAGE_TITLE = 'Your experience couldn\'t be deleted.';
export const FAILED_DELETE_EXPERIENCE_GROUP_MESSAGE_CONTENT = 'Your experience couldn\'t be deleted. Please try again';


// -------- Manage members ---------------
export const MANAGE_MEMBERS_GROUP_PAGE_TITLE = 'Manage members';
export  const ASK_FOR_CONFIRM_DELETE_MEMBERS_ACTION = 'Do you really want to delete members from group';
export  const SUCCESS_DELETE_MEMBERS_MESSAGE_TITLE = 'Member was successfully deleted from your group.';

// page: form group: groupsTypeColor
export const  PRIVACY_PUBLIC_GROUP = 'Public';
export const  PRIVACY_PRIVATE_GROUP = 'Private';
export const  PRIVACY_CLOSED_GROUP = 'Closed';

// page: profile
export const EDIT_PASSWORD_ACTION = 'editPassword';
export const EDIT_PROFILE_ACTION = 'editProfile';
export const SAVE_CHANGES_ACTION = 'saveChanges';
export const CANCEL_ACTION = 'cancel';
export const SUCCESS_CHANGE_PASSWORD_TITLE = 'Password changed successfully';
export const SUCCESS_CHANGE_PASSWORD_CONTENT = 'Password changed successfully';
export const FAILED_CHANGE_PASSWORD_TITLE = 'Error is occurred';
export const FAILED_CHANGE_PASSWORD_CONTENT = 'Password can not be changed';
export const FAILED_EDIT_PROFILE_MESSAGE_TITLE = 'Please try again';
export const FAILED_EDIT_PROFILE_MESSAGE_CONTENT = 'Couldn\'t update your profile: please enter valid data';
export const SUCCESS_EDIT_PROFILE_MESSAGE_TITLE = 'Thank you';
export const SUCCESS_EDIT_PROFILE_MESSAGE_CONTENT = 'Your profile was successfully updated.';

//My favorites actions
export const HEADER_EXPERIENCE_ID = 'ID';
export const DELETE_EXPERIENCE_FROM_FAVORITES = 'delete';
export const DELETE_TEMPLATE_FROM_FAVORITES = 'delete';
export const DELETE_GROUP_FROM_FAVORITES = 'delete';
export const GET_EXPERIENCE_DETAILS = 'details';
export const PREVIEW_TEMPLATE_ACTION = 'preview';
export const PREVIEW_GROUP_ACTION = 'preview';

// My experiences actions
export const EDIT_EXPERIENCE_ACTION = 'edit';
export const DELETE_EXPERIENCE_ACTION = 'delete';
export const PREVIEW_EXPERIENCE_ACTION = 'preview';
export const HEADER_NAME = 'NAME';
export const HEADER_DATE = 'DATE';
export const HEADER_DATE_MAJ = 'DATE_MAJ';
export const HEADER_RATING = 'RATING';
export const HEADER_STATUS = 'STATUS';
export const HEADER_SIZE = 'Size';
export const STATUS_PUBLISHED_EXP = 0;
export const STATUS_UNPUBLISHED_EXP = 1;
export const STATUS_DRAFT_EXP= 2;
export const STATUS_IN_MODERATION_EXP = 4;
export const HEADER_TIMES_USED = 'times used';

// const for json
export const COMPONENTS = 'Components';
export const ICON_PHOTO = 'fa fa-photo';
export const ICON_GROUP = 'fa fa-object-group';
export const MARKER_TITLE = 'Markers';
export const GEO_POSITION_TITLE = 'GeoPositions';
export const CHILDREN_LIST_MENU = [
    {title: 'Add text', icon: 'fa fa-edit fa-2x', function: 'text', children: []},
    {title: 'Add image', icon: 'fa fa-image fa-2x', function: EditorElementType.image.toString(), children: []},
    {title: 'Add 3D object', icon: 'fa fa-cube fa-2x', function: EditorElementType.object3d.toString(), children: []},
    {title: 'Add video', icon: 'fa fa-youtube fa-2x', function: 'video', children: []},
    {title: 'Add audio', icon: 'fa fa-music fa-2x', function: 'audio', children: []},
];
export const SAVE_MENU = {title: 'Save', icon: 'fas fa-cloud-upload-alt', id: 'Save', function: false, children: []};

// page: editor: first menu
export const FIRST_MENU_EDITOR_MARKER = [
  {title: MARKER_TITLE, icon: ICON_PHOTO, id: EditorElementType.marker.toString(), function: true, children: []},
  {
    title: COMPONENTS, icon: ICON_GROUP, function: false, children: CHILDREN_LIST_MENU
  },
  SAVE_MENU,
];
export const FIRST_MENU_EDITOR_GEO_POSITION = [
  {title: GEO_POSITION_TITLE, icon: ICON_PHOTO, id: EditorElementType.geo.toString(), function: true, children: []},
  {
    title: COMPONENTS, icon: ICON_GROUP, function: false, children: CHILDREN_LIST_MENU
  },
  SAVE_MENU,
];
export const FIRST_MENU_EDITOR_SURFACE = [
  {
    title: COMPONENTS, icon: ICON_GROUP, function: true, children: CHILDREN_LIST_MENU
  },
  SAVE_MENU,
];
export const ON_CLICK_TYPE = [
  {value: 'URL', label: 'Go to URL'},
  // {value:'Play audio', label:'Play audio'},
  // {value:'Pause audio', label:'Pause audio'},
];
export const MESSAGE_OPEN_EDITOR = 'Please start by adding your first trigger to enjoy creating an AR experience';
export const MESSAGE_OPEN_MARKER = 'Trigger';

// page: editor
export const SUCCESS_UPDATE_EXPERIENCE_TITLE = 'Save experience content';
export const SUCCESS_UPDATE_EXPERIENCE_CONTENT = 'Your new content has been successfully saved';
export const SUCCESS_UPDATE_TEMPLATE_TITLE = 'Save template content';
export const SUCCESS_UPDATE_TEMPLATE_CONTENT = 'Your new content has been successfully saved';

// page: editor: zoom + -
export const WARNING_ZOOM = 'Warning';
export const WARNING_ZOOM_TEXT = 'please don\'t use zoom in or out Browser';

// page: editor: popup
export const YES = 'Yes';
export const NO = 'No';
export const UNDERSTOOD = 'understood';
export const ASK_FOR_CONFIRM_PUBLISH_ACTION = 'Are you sure you want to save changes?';
export const ASK_FOR_PUBLISH_ACTION = 'Do you want to save your changes before leaving this page?';
export const FILE_TYPE_ERROR = 'File type error';
export const CHOOSE_AUDIO = 'Please choose audio file';
export const CHOOSE_IMAGE = 'Please choose one of this image file formats: PNG, JPG or JPEG';
export const CHOOSE_3D = 'The acceptable extensions: \n ".glb, .babylon, .gltf, .stl, .obj" ';
export const VALID_VIDEO_LINK = 'Please enter a valid link!';
export const VIDEO_LINK = 'Video link';
export const ERROR_IMPORT_3D = 'Error in importing object';
export const ERROR_MESSAGE_IMPORT_3D = 'Please check your object structure';

// confirm dialog
export const CONFIRM_BTN_MESSAGE = 'Submit';
export const CANCEL_BTN_MESSAGE = 'Cancel';

// useful words
export const EMAIL = 'Email';
export const FIRST_NAME = 'First name';
export const LAST_NAME = 'Last name';
export const DISPLAY_NAME = 'Display name';

// useful actions
export const DELETE = 'Delete';
export const REMOVE = 'Remove';

// Manage profile
export const EDIT_PASSWORD = 'Edit password';
export const EDIT_PROFILE = 'Edit profile';
export const MANAGE_PROFILE = 'Manage Profile';


// Buttons actions
export const SAVE_LABEL = 'SAVE_TEXT';
export const CANCEL_LABEL = 'CANCEL_TEXT';


// error firebase
export const TOKEN_IS_INVALID = 'Token is not valid';


// page: create/edit template
export const CREATE_TEMPLATE_PAGE_TITLE = 'Create template';
export const EDIT_TEMPLATE_PAGE_TITLE = 'Edit template';
export const CREATE_TEMPLATE_BUTTON = 'Create';
export const CREATE_TEMPLATE_BUTTON_TITLE = 'CLICK_TO_CREATE_YOUR_TEMPLATE';
export const EDIT_TEMPLATE_BUTTON = 'Update';
export const EDIT_TEMPLATE_BUTTON_TITLE = 'CLICK_TO_UPDATE_YOUR_TEMPLATE';
export const NEW_TEMPLATE_EVENT = 'add';
export const EDIT_TEMPLATE_EVENT = 'edit';
export const BLANK_TEMPLATE = 'blank template';
export const TEMPLATE_FROM_TEMPLATE = 'from template';
export const TEMPLATE_FROM_EXPERIENCE = 'from experience';
export const MARKER = EditorElementType.marker.toString();
export const SURFACE = EditorElementType.surface.toString();
export const MARKER_TEXT_ON_HOVER = 'Created with marker';
export const SURFACE_TEXT_ON_HOVER  = 'Created with surface';
// page: my templates
export const MY_TEMPLATE_PAGE_TITLE = 'My templates';
export const STATUS_PUBLISHED = 'PUBLISHED';
export const STATUS_UNPUBLISHED = 'UNPUBLISHED';
export const STATUS_DRAFT = 'DRAFT';
export const STATUS_IN_MODERATION = 'IN_MODERATION';
export const SUCCESS_DELETE_TEMPLATE_MESSAGE_TITLE = 'Your template was successfully deleted.';
export const ASK_FOR_CONFIRM_DELETE_TEMPLATE_ACTION = 'Do you really want to delete the template ';

// page: datails template
export const SECTION_GALLERY_LABEL = 'Gallery';
export const SECTION_RATING_REVIEWS_LABEL = 'Rating and reviews';
export const SECTION_MY_REVIEW_LABEL = 'My review';
export const SECTION_WRITE_REVIEW_LABEL = 'Write your review';


// page:  My assets library , Create asset
export const ASSET_TYPE_LABEL = 'ASSET_TYPE';
export const ASSET_TYPE_IMAGE = 'ASSET_TYPE_IMAGE';
export const ASSET_TYPE_OBJ_3D = 'ASSET_TYPE_OBJ_3D';
export const ASSET_TYPE_AUDIO = 'ASSET_TYPE_AUDIO';
export const CREATE_ASSET_TITLE = 'CREATE_ASSET_TITLE';
export const CREATE_NEW_ASSET = 'CREATE_NEW_ASSET';
export const MY_ASSETS_LIBRARY = 'MY_ASSETS_LIBRARY';
export const ALL_ASSET = 'ALL_ASSET';
export const LOCAL_STORAGE = 'LOCAL_STORAGE';
export const ASK_FOR_CONFIRM_DELETE_ASSET_ACTION = 'Do you really want to delete the asset';
export const FROM_YOUR_LIBRARY = 'from your library';
export const SUCCESS_DELETE_ASSET_MESSAGE_TITLE = 'Your asset was successfully deleted.';
export const SUCCESS_CREATE_ASSET_MESSAGE_TITLE = 'Your asset was successfully created.';
export const FAILED_CREATE_ASSET_MESSAGE_TITLE = 'Asset can not be created.';

// page: Edit analytics
export const EDIT_ANALYTICS_ID_PAGE_TITLE = 'Edit analytics ID';



// like/dislike & favorites
export const WRITE_LIKE_DISLIKE_EDIT = 'edit_like_dislike_review';
export const WRITE_EXPERIENCE_FAVORITE_ACTION = 'action_experience_favorite';
export const WRITE_TEMPLATE_FAVORITE_ACTION = 'action_template_favorite';
export const ASK_FOR_CONFIRM_DELETE_EXPERIENCE_FROM_FAVORITES = 'Do you really want to remove the experience from favorites';
export const SUCCESS_DELETE_EXPERIENCE_FROM_FAVORITES_MESSAGE_TITLE = 'The experience was successfully removed from favorites.';
export const ASK_FOR_CONFIRM_DELETE_TEMPLATE_FROM_FAVORITES = 'Do you really want to remove the template from favorites';
export const SUCCESS_DELETE_TEMPLATE_FROM_FAVORITES_MESSAGE_TITLE = 'The template was successfully removed from favorites.';

export const ASK_FOR_CONFIRM_DELETE_GROUP_FROM_FAVORITES = 'Do you really want to remove the group from favorites';
export const SUCCESS_DELETE_GROUP_FROM_FAVORITES_MESSAGE_TITLE = 'The group was successfully removed from favorites.';
export const WRITE_GROUP_FAVORITE_ACTION = 'action_group_favorite_review';
export const WRITE_LIKE_ACTION = 'action_like_review';
export const WRITE_DISLIKE_ACTION = 'action_dislike_review';

// moderate
export const MODERATE = 'MODERATE';
export const HEADER_CREATOR = 'CREATOR'
export const SUCCESS_VALIDATE_EXPERIENCE_MESSAGE_TITLE = 'The experience was successfully validated.';
export const VALIDATE = 'Validate';
export const SUCCESS_REJECT_EXPERIENCE_MESSAGE_TITLE = 'The experience was successfully rejected.';
export const SUCCESS_VALIDATE_TEMPLATE_MESSAGE_TITLE = 'The template was successfully validated.';
export const SUCCESS_REJECT_TEMPLATE_MESSAGE_TITLE = 'The template was successfully rejected.';

export const HEADER_REVIEW = 'REVIEW';
export const HEADER_EXP_NAME = 'EXPERIENCE_NAME';
export const HEADER_TEMP_NAME = 'TEMPLATE_NAME';
export const SUCCESS_VALIDATE_REVIEW_MESSAGE_TITLE = 'The review was successfully validated.';
export const SUCCESS_REJECT_REVIEW_MESSAGE_TITLE = 'The review was successfully rejected.';
export const SUCCESS_RESET_LIKE_DISLIKE_ACTION_MESSAGE_TITLE = 'The likes and dislikes of the review were successfully reset.';


// page invitations
export const ACCEPT_INVIT_JOIN_GROUP_MESSAGE_TITLE = 'Congratulations!';
export const ACCEPT_INVITATION_GROUP_MESSAGE_CONTENT = 'You are now a member! You can enjoy the group\'s experiences';
export const ACCEPT_JOIN_GROUP_MESSAGE_CONTENT = 'You have now a new member in your group!';
export const REFUSE_JOIN_GROUP_MESSAGE_TITLE = 'Refuse request to join group';
export const REFUSE_INVITATION_GROUP_MESSAGE_TITLE = 'Refuse invitation to group';
export const REFUSE_INVITATION_GROUP_MESSAGE_CONTENT = 'The invitation to the group was successfully refused';
export const REFUSE_JOIN_GROUP_MESSAGE_CONTENT = 'The request to join the group was successfully refused';
export const DISCARD_JOIN_GROUP_MESSAGE_TITLE = 'Discard request to join group';
export const DISCARD_JOIN_GROUP_MESSAGE_CONTENT = 'The request to join the group was successfully discarded';


// tslint:disable-next-line:max-line-length
export const ALREADY_INVITATION_GROUP_MESSAGE_CONTENT = 'you have already accepted this invitation! You can enjoy the group\'s experiences!';
export const ALREADY_JOIN_GROUP_MESSAGE_CONTENT = 'you have already accepted this request!';
export const INVITATION_MESSAGE_CONTENT = 'You can accept or refuse this invitation to become a member of the group.'
export const INVITATION_MESSAGE_TITLE = 'Camlann - Invitation';
export const REQUEST_MESSAGE_CONTENT = 'You can accept or refuse this request to join your group.'
export const REQUEST_MESSAGE_TITLE = 'Camlann - Join request';
export const ASK_FOR_CONFIRM_ACCEPT_INVITATION_ACTION = 'Do you really want to accept invitation to join the group?';
export const ASK_FOR_CONFIRM_ACCEPT_REQUEST_ACTION = 'Do you really want to accept request to join the group?';
export const ASK_FOR_CONFIRM_REFUSE_INVITATION_ACTION = 'Do you really want to refuse invitation to join the group?';
export const ASK_FOR_CONFIRM_REFUSE_REQUEST_ACTION = 'Do you really want to refuse request to join the group?';
export const INVITATION_NOT_AVAILABLE_MESSAGE_CONTENT = 'The group invitation is no longer available!';
export const REQUEST_NOT_AVAILABLE_MESSAGE_CONTENT = 'The request to join the group is no longer available!';






