import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Experience, GetExperienceResponse} from '../../../interfaces/experience';
import {TokenHelperService} from '../../../services/token-helper.service';
import {ExperiencesService} from '../../../services/experiences.service';
import {HttpParams} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {AddExperienceDialogComponent} from '../../popups/add-experience-dialog/add-experience-dialog.component';
import {GroupsService} from '../../../services/groups.service';
import {
  FAILED_ADD_EXPERIENCE_GROUP_MESSAGE_CONTENT,
  FAILED_ADD_EXPERIENCE_GROUP_MESSAGE_TITLE,
  SUCCESS_ADD_EXPERIENCE_GROUP_MESSAGE_TITLE,
} from '../../../../utils/actionsLabels/labels';
import {MY_EXPERIENCES_PATH} from '../../../../utils/actionsLabels/pathLabels';
import {NotificationService} from '../../../services/notification.service';
import {CreateExperienceDialogComponent} from '../../popups/create-experience-dialog/create-experience-dialog.component';
import {Router} from '@angular/router';
import {IS_IN_GROUP, OWNER_GROUP} from '../../../../utils/queryParams/groupsQueryParams';

@Component({
  selector: 'app-group-details-experiences',
  templateUrl: './group-details-experiences.component.html',
  styleUrls: ['./group-details-experiences.component.scss']
})
export class GroupDetailsExperiencesComponent implements OnInit, OnDestroy {

  @Input() experiences: Experience[];
  @Input() experiencesNbr: number;
  @Input() section;
  @Input() userId: string;
  @Input() groupId: string;
  @Input() buttonsDisabled;
  @Output() onAddExperience = new EventEmitter();
  @Output() buttonDisabled = new EventEmitter;
  e = 1; // initial page in pagination of experiences
  dialogRef;
  destroyComponent = false;

  constructor(private tokenHelperService: TokenHelperService,
              private experiencesService: ExperiencesService,
              private groupsService: GroupsService,
              private router: Router,
              private notificationService: NotificationService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    this.destroyComponent = true;
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
  onAdd() {
    this.buttonsDisabled = true;
    this.buttonDisabled.emit(this.buttonsDisabled);
    let params = new HttpParams();
    params = params.append(OWNER_GROUP, this.userId);
    params = params.append(IS_IN_GROUP, '0');
      // TODO get public experinces + not added to this group
    this.groupsService.getGroupExperiences(this.groupId, params).subscribe((data: { list_experiences: Experience[],
      number_experiences_group: number }) => {
      if (data.list_experiences.length === 0) {
        if ( this.destroyComponent !== true) { // prevent popup from appearing on other pages
          this.dialogRef = this.dialog.open(CreateExperienceDialogComponent, {
            disableClose: false,
            autoFocus: true,
            panelClass: 'my-dialog',
            width: '800px',
            maxHeight: '500px',
          });
          this.dialogRef.afterClosed().subscribe((result) => {
            this.buttonsDisabled = false;
            this.buttonDisabled.emit(this.buttonsDisabled);
            if (result) {
              this.router.navigate([MY_EXPERIENCES_PATH]);
            }
          });
        }
      } else {
        if (this.destroyComponent !== true) { // prevent popup from appearing on other pages
          this.dialogRef = this.dialog.open(AddExperienceDialogComponent, {
            disableClose: false,
            autoFocus: true,
            panelClass: 'my-dialog',
            width: '1000px',
            maxHeight: '600px',
            data: data.list_experiences
          });
          this.dialogRef.afterClosed().subscribe((result: Experience) => {
            this.buttonsDisabled = false;
            this.buttonDisabled.emit(this.buttonsDisabled);
            if (result) {
              const experiences = [{experience_id: result.id}];
              if (result.status === 0) { // public experience ,
                this.groupsService.addExperiencesToGroup(this.groupId, experiences).subscribe((response: GetExperienceResponse) => {
                  if (response.list_added_experiences && response.list_added_experiences.length === 1) {
                    this.onAddExperience.emit(result);
                    this.notificationService.showSuccess('', SUCCESS_ADD_EXPERIENCE_GROUP_MESSAGE_TITLE);
                  } else {
                    this.notificationService.showError(FAILED_ADD_EXPERIENCE_GROUP_MESSAGE_CONTENT, FAILED_ADD_EXPERIENCE_GROUP_MESSAGE_TITLE);
                  }
                }, () => {
                  this.notificationService.showError(FAILED_ADD_EXPERIENCE_GROUP_MESSAGE_CONTENT, FAILED_ADD_EXPERIENCE_GROUP_MESSAGE_TITLE);
                });
              } else {
                this.notificationService.showError(FAILED_ADD_EXPERIENCE_GROUP_MESSAGE_CONTENT, FAILED_ADD_EXPERIENCE_GROUP_MESSAGE_TITLE);
              }
            }
          });
        }
      }
    });
  }
}
