<div class=" sidebar" [ngClass]="{'openedSidebar': !isToggled, 'closedSidebar': isToggled }">

  <div class="menu">
    <div  *ngFor="let li of firstMenuEditor" >

      <div class="menu-element " *ngIf="li.function !== false && li.function !== null"
           id="{{li.title}}"
           (click)="onMenuClick('empty',li.id, li )"
           [ngClass]="{'menu-element-focus' : li.title === selectedElementFirstMenu}" >
        <div *ngIf="!isToggled" class="li-content">{{li.title}}</div>
        <i *ngIf="isToggled" class="{{li.icon}} fa-1x"></i>
      </div>
      <div class="menu-element-disabled " *ngIf="li.function === false || li.function === null">
        <div *ngIf="!isToggled" class="li-content">{{li.title}}</div>
        <i *ngIf="isToggled" class="{{li.icon}} fa-1x"></i>
      </div>

      <div  class="submenu" *ngIf="openElementFirstMenu === li">
        <div  class="submenu-element "
              *ngFor="let child of li.children"
              (click)="addNewObjScene(child.function, $event, child)"
              [ngClass]="{'submenu-element-focus' : child.title === selectedElementFirstMenu}" >
        <span class="tooltiptext">{{child.title}}</span>
              <i  class="{{child.icon}} fa-2x"></i>
              <!--<i *ngIf="!isToggled"  class="{{child.icon}} fa-2x"></i>
              <i *ngIf="isToggled"  class="{{child.icon}} fa-1x"></i>-->
        </div>
      </div>

    </div>
  </div>

  <div class="sidebar-toggled" (click)="toggleMenu()">
    <span *ngIf="!isToggled"><b> << </b> {{'Collapse' | translate}}</span>
    <span *ngIf="isToggled"><b> >> </b></span>
  </div>
</div>






