import {Component, OnInit, Input} from '@angular/core';
import {Creator, User} from '../../../interfaces/user';
import {COMING_SOON} from '../../../../utils/actionsLabels/labels';
import {NotificationService} from '../../../services/notification.service';

@Component({
  selector: 'app-details-contact-creator',
  templateUrl: './details-contact-creator.component.html',
  styleUrls: ['./details-contact-creator.component.scss']
})
export class DetailsContactCreatorComponent implements OnInit {

  constructor(
      private notificationService: NotificationService){
  }
  @Input() data: Creator;

  ngOnInit(): void {
  }

  comingSoonAction() {
    this.notificationService.showWarning('', COMING_SOON);
  }
}
