<div class="pglist-p1 pglist-bg pglist-p-com " id="ld-abour">
  <div class="pglist-p-com-ti">
    <div class="about-section">
      <div class="text-about"><span>{{section.label | translate}}</span> {{details.name}}</div>
      <div>
        <a (click)="ActionGroupFavorite()" [class.isDisabled]="disabledButtonFavorite">
          <a *ngFor="let et of etatGroupFavorite| keyvalue" >
            <i *ngIf="et.key == details.id && et.value==true"   class="fa fa-heart favorite-item-red" aria-hidden="true"></i>
            <i *ngIf="et.key == details.id && et.value==false"  class="fa fa-heart-o favorite-item-white" aria-hidden="true"></i>
          </a>
        </a>
      </div>
    </div>
  </div>
  <div class="list-pg-inn-sp">
    <div class="description-content inline">
      <div class="share-btn">
        <ul>
          <li><a (click)="shareFacebook()"><i class="fa fa-facebook fb1"></i> {{"Share On Facebook" | translate}}</a></li>
          <li><a (click)="shareTwitter()"><i class="fa fa-twitter tw1"></i> {{"Share On Twitter" | translate}}</a></li>
          <li><a (click)="shareLinkedin()"><i class="fa fa-linkedin li1"></i> {{"Share On Linkedin" | translate}}</a></li>
        </ul>
      </div>
      <p >{{details.description}}</p>
    </div>
  </div>
</div>
