<!--TOP SEARCH SECTION-->
<section class="bottomMenu dir-il-top-fix">
  <div class="container top-search-main">
    <div class="row">
      <div class="ts-menu">
        <!--SECTION: LOGO-->
        <div class="ts-menu-1">
          <a href=""><img src="assets/images/camlann.png" alt="{{ 'AR_Project' | translate }}">
          </a>
        </div>
        <!-- SECTION: BROWSE CATEGORY(NOTE:IT'S HIDE ON MOBILE & TABLET VIEW)-->
        <div class="ts-menu-2 ">
          <a [routerLink]="['/']" class="t-bb left" style="margin-right: 20px;"> {{ 'HOME' | translate }} </a>
          <!--SECTION: BROWSE CATEGORY-->
        </div>
        <!--SECTION: SEARCH BOX SHOWN WHEN WE ARE NOT IN EDITOR-->
        <div class="ts-menu-3" *ngIf="!isEditor">
          <div class="">
            <form class="tourz-search-form tourz-top-search-form">
              <div class="input-field"></div>
<!--              <div class="input-field"><input type="text" id="top-select-search" class="autocomplete"> <label-->
<!--                for="top-select-search" class="search-hotel-type">{{ 'Search_your_Experiences' | translate }}</label>-->
<!--              </div>-->
<!--              <div class="input-field custom-search-button">-->
<!--                <input type="submit" value=""></div>-->
            </form>
          </div>
        </div>
        <!--SECTION: EXPERIENCE NAME SHOWN WHEN WE ARE IN EDITOR-->
        <div class="ts-menu-3" *ngIf="isEditor">
          <div class="experience_name">
            {{Name}}
          </div>
        </div>
        <!----- SECTION: REGISTER,SIGNIN AND ADD YOUR BUSINESS ----->
        <div class="ts-menu-4" *ngIf="doneForUserProfile">
          <div class="v3-top-ri">
            <ul>
              <li><a class="v3-menu-sign waves-effect" routerLinkActive="active"
                     [routerLink]="[createExperience]"><i class="fa fa-plus"></i> {{ 'Create' | translate }}</a></li>
              <li><a *ngIf="isAdmin" class="v3-menu-sign waves-effect" routerLinkActive="active"
                     [routerLink]="[moderate]"><i class="fa fa-calendar-check-o"></i> {{ 'MODERATE' | translate }}</a></li>
              <li class="custom-my-account">
                <div ngbDropdown class="d-inline-block">
                  <a class='waves-effect dropdown-button top-user-pro'
                     ngbDropdownToggle>
                    <div   *ngIf="user"
                           class="image_connected_user_container-img"
                           [style.background]=" profilePictureBackground + ' center center ' ">
                    </div>
                    {{ 'My Account' | translate }} <i class="fa fa-angle-down" aria-hidden="true"></i> </a>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <ul class="top-menu-sty" ngbDropdownItem>
                      <li>
                        <a class="waves-effect" [routerLink]="[dashboardLink]" >
                          <i class="fa fa-tachometer" aria-hidden="true"></i>{{'My Dashboard' | translate }}
                        </a>
                      </li>
                      <li>
                        <a  [routerLink]="[profileLink]">
                          <i class="fa fa-user" aria-hidden="true"></i> {{'My Profile' | translate }}
                        </a>
                      </li>
                      <li>
                        <a  [routerLink]="[myExperiencesLink]">
                          <i class="fas fa-expand"></i> {{'My Experiences' | translate }}
                        </a>
                      </li>
                      <li>
                        <a  [routerLink]="[myTemplatesLink]">
                          <i class="fas fa-clipboard-list" aria-hidden="true"></i>{{'My Templates' | translate }}
                        </a>
                      </li>
                      <li>
                        <a  [routerLink]="[myGroupsLink]">
                          <i class="fa fa-users" aria-hidden="true"></i> {{'My Groups' | translate }}
                        </a>
                      </li>
                      <li>
                        <a  [routerLink]="[myFavoritesLink]">
                          <i class="fa fa-heart" aria-hidden="true"></i>{{'My favorites' | translate }}
                        </a>
                      </li>
                      <li>
                        <a  [routerLink]="[myAssetLibraryLink]">
                          <i class="fa fa-archive" aria-hidden="true"></i> {{'My assets Library' | translate }}
                        </a>
                      </li>
                      <!--<li>
                        <a class="waves-effect" (click)="comingSoonAction()" >
                          <i class="fa fa-cog" aria-hidden="true"></i> {{'Settings' | translate }}
                        </a>
                      </li>-->
                      <li class="divider"></li>
                      <li>
                        <a (click)="logout()" class="ho-dr-con-last waves-effect">
                          <i class="fa fa-sign-in" aria-hidden="true"></i>Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!------- MOBILE MENU ICON:IT'S ONLY SHOW ON MOBILE & TABLET VIEW ------>
        <div *ngIf="!shouldShowMenu && user" class="ts-menu-5" (click)="showMenuAction()">
          <div   *ngIf="user.profilePicture !== ''"
                 class="image_connected_user"
                 [style.background]=" profilePictureBackground + ' center center ' ">
          </div>
          <span *ngIf="user.profilePicture === ''"><i class="fa fa-bars" aria-hidden="true"></i></span>
        </div>         <!--MOBILE MENU CONTAINER:IT'S ONLY SHOW ON MOBILE & TABLET VIEW-->
        <div *ngIf="shouldShowMenu" class="mob-right-nav" data-wow-duration="0.5s">
          <div (click)="hideMenuAction()" class="mob-right-nav-close">
            <i class="fa fa-times" aria-hidden="true"></i>
          </div>
          <h5> {{ 'Navigation' | translate }}</h5>
          <ul class="mob-menu-icon">
            <li (click)="hideMenuAction()">
              <a [routerLink]="[createExperience]" [class.tz-lmaa]="currentUrl === createExperience" >
                <i class="fa fa-plus"></i> {{ 'Create' | translate }}
              </a>
            </li>
          </ul>
          <h5> {{ 'My Account' | translate }}</h5>
          <ul>
            <li (click)="hideMenuAction()" *ngIf="isAdmin">
              <a class="waves-effect" [routerLink]="[moderate]" [class.tz-lmaa]="currentUrl === moderate">
                <i class="fa fa-calendar-check-o" aria-hidden="true"> </i>{{'MODERATE' | translate }}
              </a>
            </li>
            <li (click)="hideMenuAction()">
              <a class="waves-effect" [routerLink]="[dashboardLink]" [class.tz-lmaa]="currentUrl === '/dashboard'">
                <i class="fa fa-tachometer" aria-hidden="true"> </i>{{'My Dashboard' | translate }}
              </a>
            </li>
            <li (click)="hideMenuAction()">
              <a [routerLink]="[profileLink]" [class.tz-lmaa]="currentUrl === '/profile'">
                <i class="fa fa-user" aria-hidden="true"></i> {{'My Profile' | translate }}
              </a>
            </li>
            <li (click)="hideMenuAction()">
              <a [routerLink]="[myExperiencesLink]" [class.tz-lmaa]="((currentUrl === '/my-experiences') ||
              (currentUrl.substring(0, 12)+currentUrl.substring(49,55) === '/experiences/edit'))">
                <i class="fas fa-expand"></i>{{'My Experiences' | translate }}
              </a>
            </li>
            <li (click)="hideMenuAction()">
              <a [routerLink]="[myTemplatesLink]" [class.tz-lmaa]="((currentUrl === '/my-templates') ||
              (currentUrl.substring(0, 20) === '/my-templates/create') ||
              (currentUrl.substring(0, 13)+currentUrl.substring(50,56) === '/my-templates/edit') )">
                <i class="fas fa-clipboard-list" aria-hidden="true"></i>{{'My Templates' | translate }}
              </a>
            </li>
            <li (click)="hideMenuAction()">
              <a [routerLink]="[myGroupsLink]" [class.tz-lmaa]="currentUrl === '/my-groups'">
                <i class="fa fa-users" aria-hidden="true"></i>{{'My Groups' | translate }}
              </a>
            </li>
            <li (click)="hideMenuAction()">
              <a [routerLink]="[myFavoritesLink]" [class.tz-lmaa]="((currentUrl === '/my-favorites'))">
                <i class="fa fa-heart" aria-hidden="true"></i>{{'My favorites' | translate }}
              </a>
            </li>

            <li (click)="hideMenuAction()">
              <a [routerLink]="[myAssetLibraryLink]" [class.tz-lmaa]="currentUrl === '/my-assets-library'">
                <i class="fa fa-users" aria-hidden="true"></i>{{'My assets Library' | translate }}
              </a>
            </li>

<!--            <li (click)="hideMenuAction()" >-->
<!--              <a class="waves-effect" (click)="comingSoonAction()" [class.tz-lmaa]="currentUrl === '/settings'">-->
<!--                <i class="fa fa-cog" aria-hidden="true"></i>{{'Settings' | translate }}-->
<!--              </a>-->
<!--            </li>-->
            <li><a (click)="logout()" class="ho-dr-con-last waves-effect">
              <i class="fa fa-sign-in" aria-hidden="true"></i> {{'Logout' | translate}}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<button id="connected-menu-change-profile-image" (click)="actionAfterEditProfile()" hidden></button>
<!--
<button id="action-after-save-popup" (click)="actionAfterSavePopup()" hidden></button>
-->
