  <div class="tz-2-com tz-2-main">
    <h4>{{pageTitle | translate }}</h4>
    <div class="db-list-com tz-db-table">
      <div class="hom-cre-acc-left hom-cre-acc-right">
         <form [formGroup]="myForm" (ngSubmit)="onSubmit(myForm.value)">
            <div>
              <!------------------- general inf + buttons ------------------->
              <div class="row">
                <div class="db-v2-list-form-inn-tit">
                  <!------------------- buttons ------------------->
                  <div *ngIf="template && template.id !== ''" class="editor_link_container in_mobile">
                    <!------------ buttons in desktop with changed screen --------------->
                    <a [routerLink]="['/templates/' + template.id]"
                       class="show_in_desktop waves-effect waves-light btn-large button-style show_desktop"
                       title="{{'PREVIEW' | translate}}">
                      {{'PREVIEW' | translate}}
                    </a>
                    <a (click)="openEditor()"
                       class="show_in_desktop waves-effect waves-light btn-large button-style"
                       title="{{'CLICK_TO_EDIT_YOUR_TEMPLATE_IN_EDITOR' | translate}}">
                      {{'Open_experience_in_editor' | translate}}
                    </a>
                    <!------------------- buttons in mobile ------------------->
                    <a [routerLink]="['/templates/' + template.id]"
                       class="show_in_mobile waves-effect waves-light btn-large"
                       title="{{'PREVIEW' | translate}}">
                      {{'PREVIEW' | translate}}
                    </a>
                    <br>
                    <a class="show_in_mobile" (click)="showMessage()">
                      <button type="submit" class="waves-effect waves-light btn-large" disabled="typeMobile"
                              title="{{'CLICK_TO_EDIT_YOUR_TEMPLATE_IN_EDITOR' | translate}}">
                        {{'Open_template_in_editor' | translate}}
                      </button>
                    </a>
                  </div>
                  <!------------ text general information --------------->
                  <h5>{{"TEMPLATE_GENERAL_INFORMATION" | translate}}</h5>
                  <!------------------ buttons in desktop ------------------->
                  <div *ngIf="template && template.id !== ''" class="editor_link_container in_desktop">
                    <ul class="additional_actions">
                      <li>
                        <a [routerLink]="['/templates/' + template.id]"
                           class="show_in_desktop waves-effect waves-light btn-large button-style"
                           title="{{'PREVIEW' | translate}}">
                          {{'PREVIEW' | translate}}
                        </a>
                      </li>
                      <li>
                        <a (click)="openEditor()" class="right waves-effect waves-light btn-large button-style"
                           title="{{'CLICK_TO_EDIT_YOUR_TEMPLATE_IN_EDITOR' | translate}}">
                          {{'Open_template_in_editor' | translate}}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!------------------- Name ------------------->
              <div class="row">
                <div class="input-field col s12">
                  <input type="text" id="name"
                         class="form-control" formControlName="name" maxlength="25" (keyup)="substrName()"
                         [ngClass]="myForm.get('name').invalid && myForm.get('name').touched ? 'invalid': 'validate'"
                  />
                  <label for="name" [class.active]="template && template.id !== ''">{{"NAME_TEMPLATE" | translate}} </label>
                  <span
                    class="v2-db-form-note">{{"NAME_TEMPLATE_INFO" | translate}}</span>
                </div>
              </div>
              <div class="row " *ngIf="myForm.get('name').invalid && myForm.get('name').touched">
                <div class="alert alert-danger">{{'TEMPLATE_NAME_IS_REQUIRED' | translate}}</div>
              </div>
              <!------------------- description ------------------->
              <div class="row">
                <div class="input-field col s12">
                  <textarea id="description"
                            class="materialize-textarea"
                            formControlName="description" autosize></textarea>
                  <label for="description" [class.active]="template && template.id !== '' && template.description !== ''">
                    {{"DESCRIPTION_TEMPLATE" | translate}}</label>
                </div>
              </div>
              <br/>
              <!------------------- Status for edit ------------------->
              <div class="row" *ngIf="template && template.id !== ''">
                <div class="chec-out-pay">
                  <div class="privacy-title db-v2-list-form-inn-tit">
                    <h5>{{"Status" | translate}} </h5>
                  </div>
                  <!-------------------- end status for user and admin (other template) --------->
                  <div *ngIf="!isAdmin">
                    <input class="privacy-input" name="status" type="radio" formControlName="status"  value="{{templateStatus[0].value}}"
                           id="{{templateStatus[0].label | translate}}"  disabled/>
                    <label class="privacy-label status_label_{{0}}" [ngClass]="{'active': templateStatus[0].value === template.status}"
                           for="{{templateStatus[0].label | translate}}">{{templateStatus[0].label | translate}}</label>
                    <input class="privacy-input" name="status" type="radio" id="{{templateStatus[1].label | translate}}"
                           formControlName="status" value="{{templateStatus[1].value}}" (change)="changeTemplateStatus($event)"
                           [checked]="templateStatus[1].value === templateStatus.status"/>
                    <label class="privacy-label status_label_{{1}}" [ngClass]="{'active': templateStatus[1].value === template.status}"
                           for="{{templateStatus[1].label | translate}}">{{templateStatus[1].label | translate}}</label>
                    <input class="privacy-input" name="status" type="radio" id="{{templateStatus[2].label | translate}}"
                           formControlName="status" value="{{templateStatus[2].value}}" (change)="changeTemplateStatus($event)"
                           [checked]="templateStatus[2].value === template.status"/>
                    <label class="privacy-label status_label_{{2}}" [ngClass]="{'active': templateStatus[2].value === template.status}"
                           for="{{templateStatus[2].label | translate}}">{{templateStatus[2].label | translate}}</label>
                    <input class="privacy-input" name="status" type="radio" id="{{templateStatus[3].label | translate}}"
                           formControlName="status" value="{{templateStatus[3].value}}" (change)="changeTemplateStatus($event)"
                           [checked]="templateStatus[3].value === template.status"/>
                    <label class="privacy-label status_label_{{3}}" [ngClass]="{'active': templateStatus[3].value === template.status}"
                           for="{{templateStatus[3].label | translate}}">{{templateStatus[3].label | translate}}</label>
                  </div>
                  <!-------------------- end status for user and admin (other template)--------->
                  <!--------------------------- status for admin (his template) --------------->
                  <div *ngIf="isAdmin">
                    <div *ngFor="let statusTemp of templateStatus; let i = index" class="status_of_experience">
                      <input class="privacy-input" name="status" type="radio" id="{{statusTemp.label | translate}}"
                             formControlName="status"
                             value="{{statusTemp.value}}" (change)="changeTemplateStatus($event)"
                             [checked]="statusTemp.value === template.status"/>
                      <label class="privacy-label status_label_{{i}}"
                             [ngClass]="{'active': statusTemp.value === template.status}"
                             for="{{statusTemp.label | translate}}"

                      >{{statusTemp.label | translate}}</label>
                    </div>
                  </div>
                  <!---------------------------- end status for admin (his template) ------------>
                </div>
              </div>
              <br/>
              <!------------------- cover image ------------------->
              <div class="row">
                <div class="db-v2-list-form-inn-tit">
                  <h5>{{"Cover Image" | translate}} </h5>
                </div>
              </div>
              <div class="row tz-file-upload">
                <div class="file-field input-field">
                  <div class="tz-up-btn" *ngIf="fileName === ''" title="{{'CLICK_TO_ADD_COVER_PHOTO' | translate}}">
                    <span (click)="addPhoto()">{{"File" | translate}}</span>
                    <input type="file" id="coverPicture"
                           class="form-control" formControlName="coverPicture"
                           accept="image/png, image/jpeg, image/jpg"
                           (change)="onCoverProfileChange($event)">
                  </div>

                  <div *ngIf="fileName !== ''" class="input-field col s12 ">
                    <div class="thumbnail-content pl-45">
                      <div class="item-number inline"></div>
                      <div class="img-thumbnail-content-cover-picture inline">
                        <img [src]="croppedImage" *ngIf="croppedImage && croppedImage !== ''" alt=""/>
                        <img [src]="url" *ngIf="url && url !== ''" alt=""/>
                      </div>
                      <input type="text" value="{{fileName}}" disabled class="inline"/>
                      <input type="text" class="inline"
                             placeholder="{{'OPTIONAL_DESCRIPTION' | translate}}"
                             formControlName="coverPictureDisplayName"/>
                      <button class="btn btn-danger inline" (click)="deleteProfileCover()"
                              title="{{'DELETE_COVER_EXPERIENCE' | translate}}"><i class="fa fa-trash"></i></button>
                    </div>
                    <image-cropper *ngIf="fileCoverPicture"
                                   [imageFile]="fileCoverPicture"
                                   [maintainAspectRatio]="true"
                                   [containWithinAspectRatio]="false"
                                   [aspectRatio]="1920/570"
                                   [resizeToWidth]="resizeToWidth"
                                   [cropperMinWidth]="100"
                                   [onlyScaleDown]="true"
                                   [roundCropper]="false"
                                   [canvasRotation]="0"
                                   [alignImage]="'center'"
                                   format="png"
                                   (imageCropped)="imageCropped($event)"
                                   (imageLoaded)="imageLoaded()"
                    ></image-cropper>
                  </div>
                </div>
              </div>
              <br/>
              <!------------------- gallery images ------------------->
              <div class="row">
                <div class="db-v2-list-form-inn-tit">
                  <h5>{{"Photo Gallery" | translate}} <span
                    class="v2-db-form-note">{{"GALLERY_TEMPLATE_INFO" | translate}}</span></h5>
                </div>
              </div>
              <div class="input-field col s12 " *ngIf="media && media.length > 0">
                <div class="thumbnail-content" *ngFor="let media of media; let i = index;">
                  <div *ngIf="!mediaScreenChanged">
                    <div class="item-number inline">{{i + 1}}</div>
                    <div class="img-thumbnail-content inline">
                      <img [src]="media.url" height="45"/>
                    </div>
                    <input type="text" value="{{media.name}}" disabled class="inline"/>
                    <input type="text" class="inline"
                           placeholder="{{'OPTIONAL_DESCRIPTION' | translate}}"
                           (change)="changeDisplayName($event, i)"/>
                    <button class="btn btn-danger inline" (click)="deleteThumbnail(i)"
                            title="{{'DELETE_GALLERY_PHOTO' | translate}}"><i class="fa fa-trash"></i></button>
                  </div>

                  <div *ngIf="mediaScreenChanged">
                    <div style="overflow: auto">
                      <div class="item-number inline">{{i + 1}}</div>
                      <div class="img-thumbnail-content inline">
                        <img [src]="media.url" height="45"/>
                      </div>
                      <button class="btn btn-danger left" (click)="deleteThumbnail(i)"
                              title="{{'DELETE_GALLERY_PHOTO' | translate}}"><i class="fa fa-trash"></i></button>

                    </div>
                    <div class="left"><input type="text" value="{{media.name}}" disabled/></div>
                    <div class="left"><input type="text"
                                             placeholder="{{'OPTIONAL_DESCRIPTION' | translate}}"
                                             (change)="changeDisplayName($event, i)"/></div>
                  </div>
                  <br/>
                </div>
              </div>

              <div class="row tz-file-upload">
                <div class="file-field input-field" *ngIf="media.length < 8"
                     title="{{'CLICK_TO_ADD_PHOTOS_GALLERY' | translate}}">
                  <div class="tz-up-btn"><span (click)="addMedias()">{{"File" | translate}}</span>
                    <input #galleryFile type="file" id="media"
                           class="form-control" formControlName="media" multiple
                           accept="image/png, image/jpeg, image/jpg" (change)="onMediaChange($event)"/>
                  </div>
                </div>
              </div>
              <br/>
              <!------------------- video youtube ------------------->
              <div class="row">
                <div class="db-v2-list-form-inn-tit">
                  <h5>{{"TEMPLATE_VIDEO" | translate}}</h5>
                </div>
              </div>
              <div class="row">
                <div class="input-field col s12">
                  <input type="text" id="video" formControlName="video"
                         class="form-control"
                         [ngClass]="myForm.get('video').invalid && myForm.get('video').touched ? 'invalid': 'validate'"
                  />
                  <label for="video" [class.active]="template && template.id !== '' && template.video !== ''">
                    {{"VIDEO_TEMPLATE" | translate}}</label>
                </div>
              </div>
              <div class="row " *ngIf="myForm.get('video').invalid && myForm.get('video').touched">
                <div class="alert alert-danger">{{'TEMPLATE_VIDEO_IS_REQUIRED' | translate}}</div>
              </div>
              <br/>
              <br/>
              <!------------------- buttons ------------------->
              <div class="actions-container row">
                <button type="submit" [disabled]="myForm.invalid" class="waves-effect waves-light btn-large "
                        title="{{buttonTitleText | translate}}">  {{buttonText | translate}}
                </button>
                <button type="button"
                        class="waves-effect waves-light btn-large"
                        title="{{'Cancel' | translate}}"
                        (click)="resetForm()">{{'Cancel' | translate}}
                </button>
              </div>
           </div>
         </form>
      </div>
    </div>
  </div>

