import { Component, OnInit } from '@angular/core';
import {MODERATE_PAGE_TITLE} from "../../utils/actionsLabels/labels";
import {User} from "../../reusables/interfaces/user";
import {UsersService} from "../../reusables/services/users.service";
import {Title} from "@angular/platform-browser";
import {AuthGuard} from "../../reusables/_helpers";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-profile-moderate',
  templateUrl: './profile-moderate.component.html',
  styleUrls: ['./profile-moderate.component.scss']
})
export class ProfileModerateComponent implements OnInit {
  doneForUserProfile = false;
  user: User;

  constructor(private usersService: UsersService,
              private titleService: Title,
              private authGuard: AuthGuard,
              private translate: TranslateService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    if (this.authGuard.canActivate) {
      this.translate.get(MODERATE_PAGE_TITLE).subscribe((res: string) => {
        this.titleService.setTitle(res);
      });
      this.getProfile();
    }
  }
  getProfile() {
    this.usersService.getUserProfile().subscribe((data: User) => {
      this.user = data;
      this.doneForUserProfile = true;
    });
  }

}
