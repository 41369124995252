<section *ngIf="doneForUserProfile">
  <div class="tz">
    <div class="tz-l">
      <app-profile-picture [user]="user"></app-profile-picture>
      <app-account-menu [user]="user"></app-account-menu>
    </div>
    <div class="tz-2">
      <app-my-experiences
        (experienceAction)="experienceAction($event)"
        [experiences]="experiences"
        [disabledButton]="disabledButton"
        *ngIf="experiencesAreLoaded && experiences.length > 0"></app-my-experiences>
      <app-add-new-element
        *ngIf="experiencesAreLoaded && experiences.length == 0"
        [url]="createExperience"></app-add-new-element>
    </div>
<!--    <app-notifications></app-notifications>-->
  </div>
</section>
<app-confirm-dialog [confirmText]="deleteText"></app-confirm-dialog>
