import {Component, Input, OnInit} from '@angular/core';
import {Experience} from '../../interfaces/experience';
import {EXPERIENCE_PATH} from '../../../utils/actionsLabels/pathLabels';

@Component({
  selector: 'app-listing-experience-item',
  templateUrl: './listing-experience-item.component.html',
  styleUrls: ['./listing-experience-item.component.scss', '../../../../assets/customCss/listingItem.css']
})
export class ListingExperienceItemComponent implements OnInit {

  @Input() data: Experience;
  @Input() homeSection;
  constructor() { }
  customStyle = '';
  linkDetails = '';
  ngOnInit(): void {
    if (this.data.coverPicture !== '') {
      this.customStyle = 'background: url(' + this.data.coverPicture + ') center no-repeat';
    }
    this.linkDetails = EXPERIENCE_PATH + '/' + this.data.id;
  }

}
