import {Component, Input, OnInit} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss']
})
export class QrcodeComponent implements OnInit {

  @Input() image: string;
  @Input() source: string;
  typeMobile = false;

  constructor(private deviceService: DeviceDetectorService) {
  }

  ngOnInit(): void {
    this.epicFunction();
  }
  epicFunction() {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    if (isMobile || isTablet) {
      this.typeMobile = true;
    }
  }

}
