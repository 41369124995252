import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { MY_LIBRARY_PATH } from "../../../../utils/actionsLabels/pathLabels";
import {CREATING_ASSET, SELECT_FILE} from "../../../../utils/actionsLabels/editorLabels";
import {ADD_ASSET, AssetType, LibraryType, maxFileSize} from "../../../../utils/global-constants/assets-library-const";
import {AssetObject, AssetsLibrary} from "../../../interfaces/assets-library";
import {HttpParams, HttpResponse} from "@angular/common/http";
import {ASSET_FILE,  CREATOR_ID, LIBRARY_TYPE, ASSET_TYPE} from "../../../../utils/queryParams/assetsQueryParams";
import {LibraryService} from "../../../services/library.service";
import {TokenHelperService} from "../../../services/token-helper.service";
import {CustomError} from "../../../interfaces/error";
import {ErrorCode} from "../../../../utils/global-constants/errorCode";
import {checkExtension, checkSize} from "../../../../utils/functions/fileFuctions";
import {
  ASSET_TYPE_AUDIO,
  ASSET_TYPE_IMAGE,
  ASSET_TYPE_OBJ_3D,
  CREATE_ASSET_TITLE,
  ASSET_TYPE_LABEL,
  CREATE_ASSET_PAGE_TITLE,
  SUCCESS_CREATE_ASSET_MESSAGE_TITLE,
  FAILED_CREATE_ASSET_MESSAGE_TITLE
} from "../../../../utils/actionsLabels/labels";
import {Title} from "@angular/platform-browser";
import {NotificationService} from "../../../services/notification.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-asset-form',
  templateUrl: './asset-form.component.html',
  styleUrls: ['./asset-form.component.scss',
    '../../../../../assets/customCss/waiting-load.scss']
})
export class AssetFormComponent implements OnInit {
  myAssetsLink = MY_LIBRARY_PATH;
  selectFile  = SELECT_FILE ;
  IMAGE = AssetType.IMAGE;
  OBJ_3D = AssetType.OBJ_3D;
  AUDIO = AssetType.AUDIO;
  creatingAsset = CREATING_ASSET;
  createAssetTitle = CREATE_ASSET_TITLE ;
  assetTypeLabel = ASSET_TYPE_LABEL;
  imageLabel = ASSET_TYPE_IMAGE;
  audioLabel = ASSET_TYPE_AUDIO;
  objLabel = ASSET_TYPE_OBJ_3D;
  @ViewChild('inputAddAssetObjectFile') fileElementRef: ElementRef;

  // *********** variables to show messages ***************//
  activateImport = false;
  showFile = false;
  showMaxSizeExceeded = false;
  showLibrarySizeExceeded = false;
  showInvalidExtension = false;
  showLoadingALibrary = true;
  showLoadingAddAssetsObject = false;
  showNoSpace = false;

  // ****** selected asset
  selectedFileName: string;
  selectedFile: File;
  assetType: AssetType = this.IMAGE ;
  myAssetsLibrary: AssetsLibrary;

  info: { assetType: AssetType, messages: any, input: any };
  userId: string;
  constructor(private titleService: Title,
              private tokenHelperService: TokenHelperService,
              private notificationService: NotificationService,
              private libraryService: LibraryService,
              private router: Router,

  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(CREATE_ASSET_PAGE_TITLE);
    this.info = ADD_ASSET.find(x => x.assetType === this.assetType);
    this.userId = this.tokenHelperService.getTokenData().user_id;
    this.showLoadingALibrary = true;
    // get connected user Assets Library
    this.getMyLibrary();
  }

  initValues() {
    this.activateImport = false;
    this.showMaxSizeExceeded = false;
    this.showLibrarySizeExceeded = false;
    this.showInvalidExtension = false;
    this.showFile = false;
    this.selectedFileName = null;
    this.selectedFile = null;
    this.showLoadingAddAssetsObject = false;
  }

  /****************************** WS: GET ASSETS , POST ASSET *********************************/
  // get connected user Assets Library
  getMyLibrary() {
    let paramsGroups = new HttpParams();
    paramsGroups = paramsGroups.append(CREATOR_ID, this.userId);
    this.libraryService.getAssetsLibraryByCreatorId(paramsGroups).subscribe((data: AssetsLibrary[]) => {
      this.showLoadingALibrary = false;
      if (data && data.length > 0 ) {
        this.myAssetsLibrary = data[0];
        this.showNoSpace = (this.myAssetsLibrary !== null && this.myAssetsLibrary.assignedSize === 0);
      }
    });
  }
  // add asset
  addAsset() {

    const formData = new FormData();
    formData.append(LIBRARY_TYPE, LibraryType.PRIVATE.toString());
    formData.append(ASSET_TYPE, this.info.assetType.toString());
    formData.append(ASSET_FILE, this.selectedFile);
    this.libraryService.addAsset(formData).subscribe((data: HttpResponse<AssetObject>) => {
      if (data.body) {
        this.showLoadingAddAssetsObject = false;
        this.notificationService.showSuccess("", SUCCESS_CREATE_ASSET_MESSAGE_TITLE);
        this.router.navigateByUrl(MY_LIBRARY_PATH).then();
      }
    }, (error: CustomError) => {
      this.notificationService.showError("", FAILED_CREATE_ASSET_MESSAGE_TITLE);
      switch (error.code) {
        case ErrorCode.aErD004.toString():
          this.initValues();
          this.showInvalidExtension = true;
          break;

        case ErrorCode.aErD002.toString():
          this.initValues();
          this.showInvalidExtension = true;
          break;

        case ErrorCode.aErD003.toString():
          this.initValues();
          this.showMaxSizeExceeded = true;
          break;
      }
    });
  }

  addFile() {
    document.getElementById(this.info.input.id).click();
  }


  onfileChange(event) {
    this.initValues();

    if (event.target.files && event.target.files.length > 0) {
      this.selectedFile = event.target.files[0];

      // check file size
      if (!checkSize(this.selectedFile, maxFileSize)) {
        this.showMaxSizeExceeded = true;
        return false;
      }
      if (this.myAssetsLibrary && !checkSize(this.selectedFile, this.myAssetsLibrary.assignedSize)) {
        this.showLibrarySizeExceeded = true;
        return false;
      }
      // check file extension
      if (!checkExtension(this.selectedFile, this.info.input.extensionList)) {
        this.showInvalidExtension = true;
        return false;
      }
      // valid file: show file name and activate import button
      this.showFile = true;
      this.selectedFileName = (event.target as HTMLInputElement).files[0].name;
      this.activateImport = true;
      this.fileElementRef.nativeElement.value = '';
    }
  }
  createAsset() {
    this.activateImport = false;
    this.showLoadingAddAssetsObject = true;
    this.addAsset();
  }

  onTypeChange(event: AssetType) {
    this.assetType = event;
    this.info = ADD_ASSET.find(x => x.assetType === this.assetType);
    this.initValues();
  }

  cancel() {
    window.history.back();
  }
}
