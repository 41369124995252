<h1 mat-dialog-title>{{VIDEO_LINK | translate}} </h1>

<form  [formGroup]="form"  class="example-form col-md-12" >

  <div mat-dialog-content class="row ">
    <div class="input-field">

      <input type="url"
             id="videoUrl"
             class="autocomplete"
             [formControl]="form.controls.url">
      <div class="error-form" *ngIf="form.controls.url.hasError('pattern') && form.controls.url.touched">
         {{ VALID_VIDEO_LINK | translate}}
      </div>
    </div>
  </div>

  <div mat-dialog-actions class="row col-md-12 actions-popup">
    <button type="submit" class="col-md-offset-6 col-sm-3 waves-effect waves-light btn-large save-popup-style " [disabled]="!form.valid " (click)="onNoClick('create')" >{{ 'Create' | translate }}</button>
    <button class="col-sm-3 button waves-effect waves-light btn-large"  (click)="onNoClick('cancel');">{{ 'Cancel' | translate }}</button>
  </div>
</form>
