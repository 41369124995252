import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {CONFIRMATION_ADD_GROUP, GroupTypes} from '../../utils/global-constants/groups-const';
import {GroupsService} from '../../reusables/services/groups.service';
import {
  INVITATION_MESSAGE_CONTENT,
  INVITATION_MESSAGE_TITLE,
  REQUEST_MESSAGE_CONTENT,
  REQUEST_MESSAGE_TITLE,
  ALREADY_INVITATION_GROUP_MESSAGE_CONTENT,
  ALREADY_JOIN_GROUP_MESSAGE_CONTENT,
  ASK_FOR_CONFIRM_ACCEPT_INVITATION_ACTION,
  ASK_FOR_CONFIRM_ACCEPT_REQUEST_ACTION,
  ASK_FOR_CONFIRM_REFUSE_INVITATION_ACTION,
  ASK_FOR_CONFIRM_REFUSE_REQUEST_ACTION,
  ACCEPT_INVITATION_GROUP_MESSAGE_CONTENT,
  INVITATION_PAGE_TITLE,
  ACCEPT_JOIN_GROUP_MESSAGE_CONTENT,
  ACCEPT_INVIT_JOIN_GROUP_MESSAGE_TITLE,
  NO,
  REQUEST_PAGE_TITLE,
  YES,
  REFUSE_INVITATION_GROUP_MESSAGE_CONTENT,
  REFUSE_JOIN_GROUP_MESSAGE_CONTENT,
  REFUSE_JOIN_GROUP_MESSAGE_TITLE,
  REFUSE_INVITATION_GROUP_MESSAGE_TITLE,
  INVITATION_NOT_AVAILABLE_MESSAGE_CONTENT,
  REQUEST_NOT_AVAILABLE_MESSAGE_CONTENT
} from '../../utils/actionsLabels/labels';
import {GroupInvitation} from '../../reusables/interfaces/group-invitation';
import {GroupRequest} from '../../reusables/interfaces/group-request';
import {GROUPS_PATH, MY_GROUPS_PATH} from '../../utils/actionsLabels/pathLabels';
import {TranslateService} from "@ngx-translate/core";
import {ConfirmDialogService} from "../../reusables/components/confirm-dialog/confirm-dialog.service";
import {NotificationService} from "../../reusables/services/notification.service";

@Component({
  selector: 'app-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.scss']
})
export class InvitationsComponent implements OnInit {

  constructor(private titleService: Title,
              private router: Router,
              private translate: TranslateService,
              private confirmDialogService: ConfirmDialogService,
              private notificationService: NotificationService,
              private groupsService: GroupsService) {
  }

  pageTitle = '';
  content = '';
  group = false;
  link = '';
  keyCodeValue = '';
  dataInvitation: GroupInvitation;
  dataRequest: GroupRequest;
  groupId = '';
  yesText = YES;
  noText = NO;
  showButton = false;
  memberId = '';
  showGroupLink = false;
  disabledButton = false;
  isClosedGroup = false;

  ngOnInit(): void {
    this.translate.get(INVITATION_PAGE_TITLE).subscribe((res: string) => {
      this.titleService.setTitle(res);
    });
    window.scrollTo(0, 0);
    const routerValue = this.router.url.substr(1);
    const urlValues = CONFIRMATION_ADD_GROUP.split('/', 1);
    if (routerValue && routerValue.split('/', 2)[0] === urlValues[0]) {
      this.keyCodeValue = routerValue.split('/', 2)[1];
      switch (this.keyCodeValue[0]) {
        case 'i':
          this.getInvitation();
          this.translate.get(INVITATION_PAGE_TITLE).subscribe((res: string) => {
            this.titleService.setTitle(res);
          });
          break;
        case 'j':
          this.getRequest();
          this.translate.get(REQUEST_PAGE_TITLE).subscribe((res: string) => {
            this.titleService.setTitle(res);
          });
          break;
        default:
          break;
      }
    }
  }

  getInvitation() {
    this.groupsService.getInvitationByCode(this.keyCodeValue).subscribe((data: GroupInvitation) => {
      this.dataInvitation = data;
      this.groupId = this.dataInvitation.group_id;
      this.memberId = this.dataInvitation.invited_member_id;
      this.showContent(this.dataInvitation );
    });
  }

  getRequest() {
    this.groupsService.getRequestJoinByCode(this.keyCodeValue).subscribe((data: GroupRequest) => {
      this.dataRequest = data;
      this.groupId = this.dataRequest.group_id;
      this.memberId = this.dataRequest.request_member_id;
      this.showContent(this.dataRequest);
    });
  }

  showContent(data) {
    this.link = GROUPS_PATH + '/' + this.groupId;
    this.group = true;
    switch (data.status) {
        case  'ACTIVE':
          this.showButton = true;
          switch (this.keyCodeValue[0]) {
            case 'i':
              this.pageTitle = INVITATION_MESSAGE_TITLE;
              if (data.group_type == GroupTypes.CLOSED) {
                  this.isClosedGroup = true;
                  this.showGroupLink = false;
                } else {
                  this.showGroupLink = true;
                }
              this.content = INVITATION_MESSAGE_CONTENT;
              break;
            case 'j':
              this.showGroupLink = true;
              this.pageTitle = REQUEST_MESSAGE_TITLE;
              this.content = REQUEST_MESSAGE_CONTENT;
              break;
          }
          break;
        case 'ACCEPTED':
          this.showGroupLink = true;
          this.showButton = false;
          switch (this.keyCodeValue[0]) {
            case 'i':
              this.pageTitle = INVITATION_MESSAGE_TITLE;
              this.content = ALREADY_INVITATION_GROUP_MESSAGE_CONTENT;
              break;
            case 'j':
              this.pageTitle = REQUEST_MESSAGE_TITLE;
              this.content = ALREADY_JOIN_GROUP_MESSAGE_CONTENT;
              break;
          }
          break;
        default:
          this.showGroupLink = false;
          this.showButton = false;
          switch (this.keyCodeValue[0]) {
            case 'i':
              this.pageTitle = INVITATION_MESSAGE_TITLE;
              this.content = INVITATION_NOT_AVAILABLE_MESSAGE_CONTENT;
              break;
            case 'j':
              this.pageTitle = REQUEST_MESSAGE_TITLE;
              this.content = REQUEST_NOT_AVAILABLE_MESSAGE_CONTENT;
              break;
          }
          break;
      }
  }

  addMember() {
    this.disabledButton = true;
    const formData = new FormData();
    formData.append('keyCode', this.keyCodeValue);
    switch (this.keyCodeValue[0]) {
      case 'i':
        this.confirmDialogService.confirmThis(ASK_FOR_CONFIRM_ACCEPT_INVITATION_ACTION, () => {
          this.groupsService.addGroupMember(formData, this.groupId).subscribe(() => {
            this.notificationService.showSuccess(ACCEPT_INVITATION_GROUP_MESSAGE_CONTENT, ACCEPT_INVIT_JOIN_GROUP_MESSAGE_TITLE);
            this.router.navigateByUrl(GROUPS_PATH + '/' + this.groupId).then();
            this.disabledButton = false;
          });
        }, () => {
          this.disabledButton = false;
        });
      break;
      case 'j':
        this.confirmDialogService.confirmThis(ASK_FOR_CONFIRM_ACCEPT_REQUEST_ACTION, () => {
          this.groupsService.addGroupMember(formData, this.groupId).subscribe(() => {
            this.notificationService.showSuccess(ACCEPT_JOIN_GROUP_MESSAGE_CONTENT, ACCEPT_INVIT_JOIN_GROUP_MESSAGE_TITLE);
            this.router.navigateByUrl(GROUPS_PATH + '/' + this.groupId).then();
            this.disabledButton = false;
          });
        }, () => {
          this.disabledButton = false;
        });
      break;
    }
  }

  refuseMember() {
    this.disabledButton = true;
    const formData = new FormData();
    formData.append('status', 'REFUSED');
    switch (this.keyCodeValue[0]) {
      case 'i':
        this.confirmDialogService.confirmThis(ASK_FOR_CONFIRM_REFUSE_INVITATION_ACTION, () => {
          this.groupsService.refuseInvitation(this.groupId, this.memberId, formData).subscribe(() => {
            this.notificationService.showSuccess(REFUSE_INVITATION_GROUP_MESSAGE_CONTENT, REFUSE_INVITATION_GROUP_MESSAGE_TITLE);
            this.router.navigateByUrl(MY_GROUPS_PATH).then();
            this.disabledButton = false;
          });
        }, () => {
          this.disabledButton = false;
        });
        break;
      case 'j':
        this.confirmDialogService.confirmThis(ASK_FOR_CONFIRM_REFUSE_REQUEST_ACTION, () => {
          this.groupsService.refuseJoinRequest(this.groupId, this.memberId, formData).subscribe(() => {
            this.notificationService.showSuccess(REFUSE_JOIN_GROUP_MESSAGE_CONTENT, REFUSE_JOIN_GROUP_MESSAGE_TITLE);
            this.router.navigateByUrl(MY_GROUPS_PATH).then();
            this.disabledButton = false;
          });
        }, () => {
          this.disabledButton = false;
        });
        break;
    }
  }
}
