import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output,} from '@angular/core';
import * as BABYLON from 'babylonjs';
import * as GUI from 'babylonjs-gui';
import {toNumbers} from '@angular/compiler-cli/src/diagnostics/typescript_version';
import {AudioObj} from '../../../model/audio/audio';
import {Router} from '@angular/router';
import {fromCmToPx, fromPxToCm, Inverse,} from '../../../../utils/functions/PositionFunction';
import {Surface} from '../../../model/surface/surface';
import {OnClickTypeDialogComponent} from '../../popups/on-click-type-dialog/on-click-type-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {VALID_VIDEO_LINK} from '../../../../utils/actionsLabels/labels';
import {rotationForSecondMenu, rotationFromSecondMenu} from '../../../../utils/functions/RotationFunction';
import {scaleGizmoColor, xGizmoColor, yGizmoColor, zGizmoColor} from '../../../../utils/global-constants/colors';
import * as $ from 'jquery';
import {urlForm, youtubeVideoForm} from '../../../../utils/global-constants/regExp';
import {extensionImageComponent} from '../../../../utils/global-constants/forHTML';
import {
  DEFAULT_AUDIO_URL, DEFAULT_FILE_NAME,
  EditorElementType,
  GizmoTypes,
  IMPORT_ASSET_DIALOG_HEIGHT,
  IMPORT_ASSET_DIALOG_WIDTH,
  IMPORT_AUDIO_ASSET_DIALOG,
  IMPORT_IMAGE_ASSET_DIALOG,
  IMPORT_MARKER_DIALOG,
  IMPORT_OBJECT_3D_ASSET_DIALOG
} from '../../../../utils/global-constants/editor-const';
import {ImportAssetDialogComponent} from '../../popups/import-asset-dialog/import-asset-dialog.component';
import {EDIT_AUDIO, EDIT_IMAGE, EDIT_MARKER, EDIT_OBJECT_3D} from '../../../../utils/actionsLabels/editorLabels';
import {AssetObject} from '../../../interfaces/assets-library';
import {fileNameWithoutExtension} from "../../../../utils/functions/fileFuctions";
import {GeoPosition} from "../../../model/geoPosition/geo-position";

@Component({
  selector: 'app-second-menu',
  templateUrl: './second-menu.component.html',
  styleUrls: ['./second-menu.component.scss'],
})

export class SecondMenuComponent implements OnInit, OnDestroy, OnChanges {
  VALID_LINK = VALID_VIDEO_LINK;

  @Output() upload = new EventEmitter(); // emit this event to edit component file
  @Output() changeMesh = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();
  @Output() cloneEvent = new EventEmitter();
  @Output() closeEvent = new EventEmitter();
  @Output() enablePublishEvent = new EventEmitter();
  @Output() videoUriValid = new EventEmitter<boolean>();
  @Output() urlValid = new EventEmitter<boolean>();
  @Output() activeAudio = new EventEmitter();
  @Input() connectedUserIdCookies;
  @Input() object;
  @Input() mesh;
  @Input() LeftPosition;
  @Input() scene: BABYLON.Scene;
  @Input() showedMenu;
  @Input() showGizmo;
  @Input() marker;
  @Input() userId: string;
  extensionImageComponent = extensionImageComponent;

  formUrl  = new FormGroup({
    url: new FormControl('', [Validators.pattern(urlForm), Validators.required])
  });

  formVideoLink  = new FormGroup({
    link: new FormControl('', [Validators.pattern(youtubeVideoForm), Validators.required])
  });
  private gizmo = null;
  /* gizmoType is the type of the gizmo in the scene,
   it changes when we click on Location Tab,
   it is a value of GizmoTypes Enum
   this type help us to recreate the correct gizmo when we click on button "show" in the right menu
   */
  gizmoType: GizmoTypes = GizmoTypes.POSITION;
  gizmoLocation = [];
  private utilLayer;
  lastmesh: BABYLON.AbstractMesh;
  selectedTabIndex = 0;
  dialogRef: MatDialogRef<any>; // popup affichée
  destroyComponent = false;
  canOpenMarkerName = false;
  constructor(private router: Router,
              public dialog: MatDialog) {
    router.events
      .subscribe((event) => {
        if (this.mesh && this.object instanceof AudioObj && this.mesh.isPlaying) {
          this.mesh.pause();
        }
      });
  }
  ngOnDestroy(): void {
    this.destroyComponent = true;
    if ( this.dialogRef) {this.dialogRef.close(); }
  }

  ngOnInit(): void {
    this.utilLayer = new BABYLON.UtilityLayerRenderer(this.scene);
    if (this.object && this.object.onClick) {
      const urlVal = this.object.onClick.find(x => x.type === 'URL');
      if (urlVal) {
        this.formUrl.controls.url.setValue(urlVal);
      }
    }
  }


  ngOnChanges(change) {
    if (change.showGizmo && this.showGizmo === false && this.gizmo) { // si HideEvent sur selected mesh ou on select (right menu) objet hide
      this.gizmo.dispose();
    }
    if (change.showGizmo && this.showGizmo === true && !change.object) {
      // show gizmo when showing mesh with right menu
      this.recreateGizmo(this.gizmoType);
    }
    // if (change.mesh && change.object) {
    // lors du clone du marker on a change object seulement
    if ( change.object) {
      if (this.object && this.object.onClick) {
        const urlVal = this.object.onClick.find(x => x.type === 'URL');
        if (urlVal) {
          this.formUrl.controls.url.setValue(urlVal.info[0]);
        }
      }
      if (this.gizmo) {
        this.gizmo.dispose();
        this.gizmo = null;
      }
      if (this.lastmesh) {
        this.lastmesh.isPickable = true;
      }
      if (!(this.object instanceof AudioObj) && !(this.object instanceof Surface) && !(this.object instanceof GeoPosition) && this.mesh && this.mesh.isVisible === true) {
        this.addPosition();
        this.gizmoType = GizmoTypes.POSITION;
      }
      this.lastmesh = this.mesh;
      if (document.getElementById('second-menu-content')) {
        document.getElementById('second-menu-content').scrollTo(0, 0);
      }
    }

  }

  selectedIndexChange(index: number) {
    setTimeout(() => this.selectedTabIndex = index);
  }

  deselectAction() {
    if (this.object instanceof AudioObj) {
      this.mesh.pause();
      this.object.isPlaying = false;
    } else {
      this.removeAction();
    }
  }
  removeAction() {
    this.gizmo.dispose();
    this.gizmo = null;
    this.mesh.isPickable = true;
  }
  afterAddAction() {
    if (this.gizmo) {
      this.gizmo.attachedMesh = null;
      this.gizmo.dispose();
      this.gizmo = null;
    }
  }
  toggleMenu() {
    this.closeEvent.emit();
  }


  // *********************** select element in 'Location' section **************************//

  recreateGizmo(gizmoType: GizmoTypes) {
    switch (gizmoType) {
      case GizmoTypes.POSITION:
        this.addPosition();
        break;
      case GizmoTypes.ROTATION:
        this.addRotation();
        break;
      case GizmoTypes.SCALE:
        this.addScale();
        break;
      case GizmoTypes.SIZE:
        this.addScale();
        break;
    }
  }
  tabClick(event: MouseEvent) {
    const tabIndex = $('.custom_tab_options:checked').val();
    this.gizmoType = tabIndex;

    if (tabIndex && this.mesh.isVisible) {
      this.recreateGizmo(tabIndex);
    }
  }

  // add rotation gizmo and add Observable for rotation events
  private addRotation() {
    this.afterAddAction();
    this.utilLayer.utilityLayerScene.autoClearDepthAndStencil = true;
    this.gizmo = new BABYLON.RotationGizmo(this.utilLayer, 100 );

    this.gizmo.xGizmo.dispose();
    this.gizmo.yGizmo.dispose();
    this.gizmo.zGizmo.dispose();
    this.gizmo.xGizmo = new BABYLON.PlaneRotationGizmo(new BABYLON.Vector3(1, 0, 0), BABYLON.Color3.FromHexString(xGizmoColor),
      this.gizmo.gizmoLayer, 100, this.gizmo);
    this.gizmo.yGizmo = new BABYLON.PlaneRotationGizmo(new BABYLON.Vector3(0, 1, 0), BABYLON.Color3.FromHexString(yGizmoColor),
      this.gizmo.gizmoLayer, 100, this.gizmo);
    this.gizmo.zGizmo = new BABYLON.PlaneRotationGizmo(new BABYLON.Vector3(0, 0, -1), BABYLON.Color3.FromHexString(zGizmoColor),
      this.gizmo.gizmoLayer, 100, this.gizmo);

    this.gizmo.attachedMesh = this.mesh;
    this.gizmo.updateGizmoRotationToMatchAttachedMesh = false;
    (this.gizmo as  BABYLON.Gizmo).scaleRatio = 2;

    let rotationMenu;
    if (this.showedMenu && this.showedMenu.find((e) => e.name === 'Location')) {
      // tslint:disable-next-line:max-line-length
      rotationMenu = this.showedMenu.find((e) => e.name === 'Location').content[0].html.element.tabgroup.find((e) => e.html.click === GizmoTypes.ROTATION);
    }

    /******************** On Drag ********************/
    const onDragRotation = () => {
      if (rotationMenu) {
        const euler = this.mesh.rotationQuaternion.toEulerAngles();
        if ( rotationForSecondMenu( euler.x, 'x') !== rotationForSecondMenu( this.object.rotation.x, 'x')) {
          this.gizmoLocation.push('rotationx') ;
          rotationMenu.html.content[0].html.value = rotationForSecondMenu( euler.x, 'x');
        }
        if ( rotationForSecondMenu( euler.y, 'y') !== rotationForSecondMenu( this.object.rotation.y, 'y')) {
          this.gizmoLocation.push('rotationy') ;
          rotationMenu.html.content[1].html.value = rotationForSecondMenu( euler.y , 'y');
        }
        if ( rotationForSecondMenu( euler.z, 'z') !== rotationForSecondMenu( this.object.rotation.z, 'z')) {
          this.gizmoLocation.push('rotationz') ;
          rotationMenu.html.content[2].html.value = rotationForSecondMenu( euler.z , 'z');
        }
        this.object.rotation = euler;
        this.mesh.rotation = new BABYLON.Vector3(euler.x, euler.y, euler.z);
      }
    };
    this.gizmo.xGizmo.dragBehavior.onDragObservable.add(() => {
      onDragRotation();
    });

    this.gizmo.yGizmo.dragBehavior.onDragObservable.add(() => {
      onDragRotation();
    });

    this.gizmo.zGizmo.dragBehavior.onDragObservable.add(() => {
      onDragRotation();
    });

    /**************** End Drag *************************/
    const dragEnd = () => {
      this.gizmoLocation = [];
      this.enablePublishEvent.emit();
    };
    this.gizmo.xGizmo.dragBehavior.onDragEndObservable.add(() => {
      dragEnd();
    });
    this.gizmo.yGizmo.dragBehavior.onDragEndObservable.add(() => {
      dragEnd();
    });
    this.gizmo.zGizmo.dragBehavior.onDragEndObservable.add(() => {
      dragEnd();
    });
    // end event rotate
  }
  // add position gizmo and add Observable for position events
  private addPosition() {
    this.afterAddAction();
    this.utilLayer.utilityLayerScene.autoClearDepthAndStencil = true;
    this.gizmo = new BABYLON.PositionGizmo();

    this.gizmo.xGizmo.dispose();
    this.gizmo.yGizmo.dispose();
    this.gizmo.zGizmo.dispose();

    this.gizmo.xGizmo = new BABYLON.AxisDragGizmo(new BABYLON.Vector3(1, 0, 0), BABYLON.Color3.FromHexString(xGizmoColor),
      this.gizmo.gizmoLayer, this.gizmo);
    this.gizmo.yGizmo = new BABYLON.AxisDragGizmo(new BABYLON.Vector3(0, 1, 0), BABYLON.Color3.FromHexString(yGizmoColor),
      this.gizmo.gizmoLayer, this.gizmo);
    this.gizmo.zGizmo = new BABYLON.AxisDragGizmo(new BABYLON.Vector3(0, 0, -1), BABYLON.Color3.FromHexString(zGizmoColor),
      this.gizmo.gizmoLayer, this.gizmo);
    this.gizmo.attachedMesh = this.mesh;
    (this.gizmo as  BABYLON.Gizmo).scaleRatio = 2;
    this.gizmo.updateGizmoRotationToMatchAttachedMesh = false;

    this.gizmo.xGizmo.dragBehavior.onDragEndObservable.add(() => {
      this.gizmoLocation = [];
      this.enablePublishEvent.emit();
    });
    this.gizmo.yGizmo.dragBehavior.onDragEndObservable.add(() => {
      this.gizmoLocation = [];
      this.enablePublishEvent.emit();
    });
    this.gizmo.zGizmo.dragBehavior.onDragEndObservable.add(() => {
      this.gizmoLocation = [];
      this.enablePublishEvent.emit();
    });
    this.gizmo.xGizmo.dragBehavior.onDragObservable.add((event) => {
      this.changeInputPosition();
    });
    this.gizmo.yGizmo.dragBehavior.onDragObservable.add(() => {
      this.changeInputPosition();
    });
    this.gizmo.zGizmo.dragBehavior.onDragObservable.add(() => {
      this.changeInputPosition();
    });
  }
  // add scale gizmo and add Observable for scale events ; for scale or size tab
  private addScale() {
    this.afterAddAction();
    this.utilLayer.utilityLayerScene.autoClearDepthAndStencil = false;
    // add color for scale gizmos
    this.gizmo = new BABYLON.BoundingBoxGizmo(
      BABYLON.Color3.FromHexString(scaleGizmoColor), this.utilLayer);
    this.gizmo.setEnabledRotationAxis('');
    this.gizmo.scaleBoxSize = 25;
    this.gizmo.attachedMesh = null;
    this.gizmo.attachedMesh = this.mesh;

    let ScaleMenu;
    let SizeMenu;
    if (this.showedMenu && this.showedMenu.find((e) => e.name === 'Location')) {
      // tslint:disable-next-line:max-line-length
      ScaleMenu = this.showedMenu.find((e) => e.name === 'Location').content[0].html.element.tabgroup.find((e) => e.html.click === GizmoTypes.SCALE);
      // tslint:disable-next-line:max-line-length
      SizeMenu = this.showedMenu.find((e) => e.name === 'Location').content[0].html.element.tabgroup.find((e) =>  e.html.click === GizmoTypes.SIZE);
    }
    this.gizmo.onScaleBoxDragEndObservable.add(() => {
      this.object.scale.x = this.mesh.scaling.x;
      this.object.scale.y = this.mesh.scaling.y;
      this.object.scale.z = this.mesh.scaling.z;
      this.enablePublishEvent.emit();
      this.gizmoLocation = [];
    });
    this.gizmo.onScaleBoxDragObservable.add(() => {
      this.gizmoLocation.push('scale');
      if (ScaleMenu) {
        ScaleMenu.html.content[0].html.value = Math.round(this.mesh.scaling.x * 100) / 100;
        this.changeInputPosition();
      }
      if (SizeMenu) {
        SizeMenu.html.content[0].html.value = Math.round(fromPxToCm(this.mesh.scaling.x * this.object.height ) * 100) / 100;
        SizeMenu.html.content[1].html.value = Math.round(fromPxToCm(this.mesh.scaling.x * this.object.width) * 100) / 100;
        this.object.position = this.mesh.position;
      }
    });
  }
  // changer la valeur de position d'un objet & les valeurs des inputs après change position or scale events
  changeInputPosition() {
    let positionMenu;
    if (this.showedMenu && this.showedMenu.find((e) => e.name === 'Location')) {
      // tslint:disable-next-line:max-line-length
      positionMenu = this.showedMenu.find((e) => e.name === 'Location').content[0].html.element.tabgroup.find((e) => e.html.click === GizmoTypes.POSITION);
    }
    if (this.object.position.x !== this.mesh.position.x) {
      this.object.position.x = this.mesh.position.x;
      if (positionMenu && positionMenu.html.content[0]) {
        this.gizmoLocation.push('positionx');
        positionMenu.html.content[0].html.value = Math.round(fromPxToCm(this.mesh.position.x - this.marker.position.x) * 10) / 10;
      }
    }
    if (this.object.position.y !== this.mesh.position.y) {
      this.object.position.y = this.mesh.position.y;
      if (positionMenu && positionMenu.html.content[1]) {
        this.gizmoLocation.push('positiony');
        positionMenu.html.content[1].html.value = Math.round(fromPxToCm(this.mesh.position.y - this.marker.position.y) * 10) / 10;
      }
    }
    if (this.object.position.z !== this.mesh.position.z) {
      this.object.position.z = this.mesh.position.z;
      if (positionMenu && positionMenu.html.content[2]) {
        this.gizmoLocation.push('positionz');
        positionMenu.html.content[2].html.value = Inverse(Math.round(fromPxToCm(this.mesh.position.z - this.marker.position.z) * 10) / 10);
      }
    }
  }
  // *********************** end select element in 'Location' section **************************//

  // ******************************** select element in 'Actions' section ************************//
  selectAction(action) {
    if (this.object instanceof AudioObj) {
      this.selectAudioAction(action);
    } else {
      this.selectMeshAction(action);
    }
  }
  /**** component === Audio ***/
  selectAudioAction(action) {
    switch (action) {
      case 'delete':
        this.delete();
        break;
      case 'clone':
        this.cloneEvent.emit(this.object);
        break;
      case 'play':
        this.activeAudio.emit();
        this.mesh.play();
        this.object.isPlaying = true;
        break;

    }
  }

  /*** component !== Audio ****/
  selectMeshAction(action) {
    switch (action) {
      case 'delete':
        this.delete();
        break;
      case 'clone':
        this.clone();
        break;
    }
  }

  private delete() {
    this.deleteEvent.emit(this.object);
  }
  private clone() {
    this.afterAddAction();
    this.cloneEvent.emit(this.object);
  }
  // **************************** end select element in 'Actions' section **************************//

  // ***************************** 'onClick' section ******************************//

  openOnClickTypePopup(action) {
    this.dialogRef = this.dialog.open(OnClickTypeDialogComponent, {
      width: '400px',
      data: action
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        switch (result.type) {
          case 'URL':
            this.onUrlClickCreate(result);
        }
      }
    });
  }

  onUrlClickCreate(result) {
    this.formUrl.controls.url.setValue(result.url);
    if (!this.object.onClick) {
      this.object.onClick = [];
    }
    const find = this.object.onClick.find(x => x.type === 'URL');
    if (find) {
      find.info = [result.url];
    } else {
      this.object.onClick.push({type: result.type, info: [result.url]});
    }
    if (this.showedMenu.find((e) => e.name === 'On click')) {
      // tslint:disable-next-line:max-line-length
      this.showedMenu.find((e) => e.name === 'On click').content.find((e) => e.html.element.type === 'list').html.value = this.object.onClick;
      // tslint:disable-next-line:max-line-length
      this.showedMenu.find((e) => e.name === 'On click').content.find((e) => e.html.element.type === 'addButton').html.value = this.object.onClick;
    }
    this.enablePublishEvent.emit();
  }

  deleteActionOnClick() {
    this.object.onClick = [];
    if (this.showedMenu.find((e) => e.name === 'On click')) {
      // tslint:disable-next-line:max-line-length
      this.showedMenu.find((e) => e.name === 'On click').content.find((e) => e.html.element.type === 'list').html.value = this.object.onClick;
      // tslint:disable-next-line:max-line-length
      this.showedMenu.find((e) => e.name === 'On click').content.find((e) => e.html.element.type === 'addButton').html.value = this.object.onClick;
    }
    this.enablePublishEvent.emit();

  }
  // change input url in second menu
  inputURL() {
    this.formUrl.controls.url.setValue(this.formUrl.controls.url.value);

    if (this.formUrl.status !== 'INVALID') {
      this.onUrlClickCreate({type: 'URL', url: this.formUrl.controls.url.value});
      this.urlValid.emit(true);
    } else {
      this.urlValid.emit(false);
    }
  }
  // ***************************** end 'onClick' section ******************************//

  // *************** change values in second menu (input, slide, range, color, select..) ***********//

  // change object and mesh attribut when changing value in second menu
  onChange(event, changeFunction) {
    this.enablePublishEvent.emit();
    if (this.object instanceof AudioObj) {
      this.onChangeAudio(event, changeFunction);
    } else {
      this.onChangeMesh(event, changeFunction);
    }
  }

  /**** component === Audio ***/
  onChangeAudio(event, changeFunction) {
    switch (changeFunction) {
      case 'audioName':
        this.object.name = (event.target as HTMLInputElement).value;
        break;
      case 'audioVolume':
        this.object.volume = toNumbers((event.target as HTMLInputElement).value)[0];
        if (toNumbers((event.target as HTMLInputElement).value)[1]) {
          this.object.volume = this.object.volume + toNumbers((event.target as HTMLInputElement).value)[1] * 0.1;
        }
        this.mesh.setVolume(this.object.volume);
        break;
      case 'audioRate':
        this.object.rate = toNumbers((event.target as HTMLInputElement).value)[0];
        if (toNumbers((event.target as HTMLInputElement).value)[1]) {
          this.object.rate = this.object.rate + toNumbers((event.target as HTMLInputElement).value)[1] * 0.1;
        }
        this.mesh.setPlaybackRate(this.object.rate);
        break;
      case 'audioLoop':
        this.object.loop = event.checked;
        this.mesh.loop = event.checked;
        break;
    }
  }

  /*** component !== Audio ****/
  onChangeMesh(event, changeFunction) {
    switch (changeFunction) {
      case 'name':
        this.object.name = (event.target as HTMLInputElement).value;
        break;
      // *************************************geoPosition***************************//
      case 'latitude':
        this.object.latitude = event;
        break;
      case 'longitude':
        this.object.longitude = event;
        break;
      // *************************************video***************************//
      case 'videoUri':
        if (this.formVideoLink.status !== 'INVALID') {
          this.object.uri = (event.target as HTMLInputElement).value;
          this.videoUriValid.emit(true);
        } else {
          this.videoUriValid.emit(false);
        }
        break;
      // *************************************texxt ***************************//
      case 'textContent':
        this.object.content = (event.target as HTMLInputElement).value;
        // tslint:disable-next-line:max-line-length
        ((((this.mesh.material as BABYLON.StandardMaterial).opacityTexture as GUI.AdvancedDynamicTexture).rootContainer.children[0] as GUI.Container).children[0] as GUI.TextBlock).text = (event.target as HTMLInputElement).value;
        break;
      case 'textFont':
        this.object.fontFamily = event.value;
        // tslint:disable-next-line:max-line-length
        ((((this.mesh.material as BABYLON.StandardMaterial).opacityTexture as GUI.AdvancedDynamicTexture).rootContainer.children[0] as GUI.Container).children[0] as GUI.TextBlock).fontFamily = event.value;
        break;
      case 'textAlign':
        this.object.align = (event.target as HTMLInputElement).value;
        // tslint:disable-next-line:max-line-length
        ((((this.mesh.material as BABYLON.StandardMaterial).opacityTexture as GUI.AdvancedDynamicTexture).rootContainer.children[0] as GUI.Container).children[0] as GUI.TextBlock).textHorizontalAlignment = toNumbers((event.target as HTMLInputElement).value)[0];
        break;
      case 'textFontSize':
        this.object.fontSize = (event.target as HTMLInputElement).value;
        // tslint:disable-next-line:max-line-length
        ((((this.mesh.material as BABYLON.StandardMaterial).opacityTexture as GUI.AdvancedDynamicTexture).rootContainer.children[0] as GUI.Container).children[0] as GUI.TextBlock).fontSize = toNumbers((event.target as HTMLInputElement).value)[0];
        break;
      case 'textColor':
        this.object.color = event;
        // tslint:disable-next-line:max-line-length
        ((((this.mesh.material as BABYLON.StandardMaterial).opacityTexture as GUI.AdvancedDynamicTexture).rootContainer.children[0] as GUI.Container).children[0] as GUI.TextBlock).color = event;
        break;
      case 'outlineWidth':
        this.object.outlineWidth = (event.target as HTMLInputElement).value;
        // tslint:disable-next-line:max-line-length
        ((((this.mesh.material as BABYLON.StandardMaterial).opacityTexture as GUI.AdvancedDynamicTexture).rootContainer.children[0] as GUI.Container).children[0] as GUI.TextBlock).outlineWidth = toNumbers((event.target as HTMLInputElement).value)[0];
        break;
      case 'outlineColor':
        this.object.outlineColor = event;
        // tslint:disable-next-line:max-line-length
        ((((this.mesh.material as BABYLON.StandardMaterial).opacityTexture as GUI.AdvancedDynamicTexture).rootContainer.children[0] as GUI.Container).children[0] as GUI.TextBlock).outlineColor = event;
        break;
      case 'backgroundWidth':
        this.object.width = fromCmToPx((event.target as HTMLInputElement).value);
        const newMesh = this.object.createNewPlane(this.scene, this.mesh);
        this.mesh = newMesh;
        if (this.gizmo !== null) {
          this.gizmo.attachedMesh = this.mesh;
        }
        this.changeMesh.emit(this.mesh);
        break;
      case 'backgroundHeight':
        this.object.height = fromCmToPx((event.target as HTMLInputElement).value);
        const newMesh2 = this.object.createNewPlane(this.scene, this.mesh);
        this.mesh = newMesh2;
        if (this.gizmo !== null) {
          this.gizmo.attachedMesh = this.mesh;
        }
        this.changeMesh.emit(this.mesh);
        break;
      case 'backgroundColor':
        this.object.background = event;
        // tslint:disable-next-line:max-line-length
        (((this.mesh.material as BABYLON.StandardMaterial).opacityTexture as GUI.AdvancedDynamicTexture).rootContainer.children[0] as GUI.Container).background = event;
        break;
      case 'radius':
        this.object.cornerRadius = (event.target as HTMLInputElement).value;
        // tslint:disable-next-line:max-line-length
        (((this.mesh.material as BABYLON.StandardMaterial).opacityTexture as GUI.AdvancedDynamicTexture).rootContainer.children[0] as GUI.Rectangle).cornerRadius = toNumbers((event.target as HTMLInputElement).value)[0];
        break;
      case 'borderWidth':
        this.object.thickness = (event.target as HTMLInputElement).value;
        // tslint:disable-next-line:max-line-length
        (((this.mesh.material as BABYLON.StandardMaterial).opacityTexture as GUI.AdvancedDynamicTexture).rootContainer.children[0] as GUI.Rectangle).thickness = toNumbers((event.target as HTMLInputElement).value)[0];
        break;
      case 'borderColor':
        this.object.borderColor = event;
        // tslint:disable-next-line:max-line-length
        (((this.mesh.material as BABYLON.StandardMaterial).opacityTexture as GUI.AdvancedDynamicTexture).rootContainer.children[0] as GUI.Container).color = event;
        break;
      case 'positionx':
        if (parseFloat(event) || parseFloat(event) === 0) {
          this.object.position.x = fromCmToPx(parseFloat(event)) + this.marker.position.x;
          this.mesh.position.x = fromCmToPx(parseFloat(event)) + this.marker.position.x;
        }
        break;
      case 'positiony':
        if (parseFloat(event) || parseFloat(event) === 0) {
          this.object.position.y = fromCmToPx(parseFloat(event)) + this.marker.position.y;
          this.mesh.position.y = fromCmToPx(parseFloat(event)) + this.marker.position.y;
        }
        break;
      case 'positionz':
        if (parseFloat(event)  || parseFloat(event) === 0) {
          this.object.position.z = Inverse((fromCmToPx(parseFloat(event)) - this.marker.position.z));
          this.mesh.position.z = Inverse((fromCmToPx(parseFloat(event)) - this.marker.position.z));
        }
        break;
      // rotate
      case 'rotationx':
        if (parseFloat(event) || parseFloat(event) === 0) {
          this.object.rotation.x = rotationFromSecondMenu(parseFloat(event), 'x');
          this.mesh.rotation.x = this.object.rotation.x;
        }
        break;
      case 'rotationy':
        if (parseFloat(event) || parseFloat(event) === 0) {
          this.object.rotation.y = rotationFromSecondMenu(parseFloat(event), 'y');
          this.mesh.rotation.y = this.object.rotation.y;
        }
        break;
      case 'rotationz':
        if (parseFloat(event) || parseFloat(event) === 0) {
          this.object.rotation.z = rotationFromSecondMenu(parseFloat(event), 'z');
          this.mesh.rotation.z = this.object.rotation.z;
        }
        break;
      // end rotate

      // scale
      case 'scale':
        if (parseFloat(event)) {
          this.object.scale = {
            x: parseFloat(event),
            y: parseFloat(event),
            z: parseFloat(event)
          };
          this.mesh.scaling = new BABYLON.Vector3(parseFloat(event), parseFloat(event), parseFloat(event));
        }
        break;
      // endscale

      // marker size
      case 'marker-height':
        if (parseFloat(event) ) {
          const scale = fromCmToPx(parseFloat(event)) / this.object.height;
          this.object.scale = {
            x: scale,
            y: scale,
            z: scale
          };
          this.mesh.scaling = new BABYLON.Vector3(scale, scale, scale);
          // tslint:disable-next-line:max-line-length
          this.showedMenu.find((e) => e.name === 'Location').content[0].html.element.tabgroup.find((e) => e.html.click === 'Size').html.content[1].html.value = Math.round(fromPxToCm(scale * this.object.width ) * 100) / 100;
        }
        break;
      case 'marker-width':
        if (parseFloat(event) ) {
          const scale = fromCmToPx(parseFloat(event)) / this.object.width;
          this.object.scale = {
            x: scale,
            y: scale,
            z: scale
          };
          this.mesh.scaling = new BABYLON.Vector3(scale, scale, scale);
          // tslint:disable-next-line:max-line-length
          this.showedMenu.find((e) => e.name === 'Location').content[0].html.element.tabgroup.find((e) => e.html.click === 'Size').html.content[0].html.value = Math.round(fromPxToCm(scale * this.object.height) * 100) / 100;
        }
        break;
        // endscale
// *************************************button ***************************//
      case 'buttonWidth':
        this.object.width = (event.target as HTMLInputElement).value;
        const newButton = this.object.createNewPlane(this.scene, this.mesh);
        this.mesh = newButton;
        if (this.gizmo !== null) {
          this.gizmo.attachedMesh = this.mesh;
          this.mesh.isPickable = false;
        }
        this.changeMesh.emit(this.mesh);
        break;
      case 'buttonHeight':
        this.object.height = (event.target as HTMLInputElement).value;
        const newButton2 = this.object.createNewPlane(this.scene, this.mesh);
        this.mesh = newButton2;
        if (this.gizmo !== null) {
          this.gizmo.attachedMesh = this.mesh;
          this.mesh.isPickable = false;
        }
        this.changeMesh.emit(this.mesh);
        break;
    }
    this.enablePublishEvent.emit();
  }
// ************************************* start CHANGE-FILES ***************************//
  // onClick edit file of component
  onChangeFile(changeFunction) {
    if (this.showGizmo) {
      let config;
      switch (this.object.type) {
        case EditorElementType.image.toString():
          config = {
            width: IMPORT_ASSET_DIALOG_WIDTH,
            height: IMPORT_ASSET_DIALOG_HEIGHT,
            data: {
              title: EDIT_IMAGE,
              info: IMPORT_IMAGE_ASSET_DIALOG,
              userId: this.userId}
          };
          break;
        case EditorElementType.audio.toString():
          config = {
            width: IMPORT_ASSET_DIALOG_WIDTH,
            height: IMPORT_ASSET_DIALOG_HEIGHT,
            data: {
              title: EDIT_AUDIO,
              info: IMPORT_AUDIO_ASSET_DIALOG,
              userId: this.userId}
          };
          break;
        case EditorElementType.marker.toString() :
          config = {
            width: IMPORT_ASSET_DIALOG_WIDTH,
            height: IMPORT_ASSET_DIALOG_HEIGHT,
            data: {
              title: EDIT_MARKER,
              info: IMPORT_MARKER_DIALOG,
              userId: this.userId}
          };

          break;
        case 'object':
          config = {
            width: IMPORT_ASSET_DIALOG_WIDTH,
            height: IMPORT_ASSET_DIALOG_HEIGHT,
            data: {
              title: EDIT_OBJECT_3D,
              info: IMPORT_OBJECT_3D_ASSET_DIALOG,
              userId: this.userId}
          };
          break;
      }
      this.openPopupEditAsset(config);
    }
  }

  openPopupEditAsset(config) {
    // if we still in the editor (this component not destroyed)
    if (this.destroyComponent !== true) {
      // --------------------Open popup Import marker-----------------
      this.dialogRef = this.dialog.open(ImportAssetDialogComponent, config);
      // ---------------------close popup Import marker----------------
      this.dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.interpretResponse({
            asset: result
          });
        }
      });
      // --------------------------------------------------------------
    }
  }

  interpretResponse(data: {asset: AssetObject }) {
      switch (this.object.type) {
        // ------------- edit marker file  -------------- //
        case EditorElementType.marker.toString() :
          this.object.url = data.asset.assetObjectUrl;
          this.object.fileName = data.asset.fileName;
          // delete current Mesh
          this.mesh.dispose();
          this.mesh = null;
          this.object.editFile(this.scene);
          this.waitCreateMesh(this.object);
          break;
       // ------------- edit image file  -------------- //
        case EditorElementType.image.toString():
          this.object.url = data.asset.assetObjectUrl;
          this.object.fileName = data.asset.fileName;
          // delete current Mesh
          this.mesh.dispose();
          this.mesh = null;
          this.object.editFile(this.scene);
          this.waitCreateMesh(this.object);
          break;
       // ------------- edit obj file  -------------- //
        case 'object':
          this.object.urlSource = data.asset.assetObjectUrl;
          this.object.urlZip = data.asset.assetObjectZipUrl;
          this.object.fileName = data.asset.fileName;
          const geometry = this.mesh.geometry.extend.maximum;
          // delete current Mesh
          this.mesh.dispose();
          this.mesh = null;
          this.object.editFile(this.scene, geometry);
          this.waitCreateMesh(this.object);
          break;
        // ------------- edit audio file  -------------- //
        case  EditorElementType.audio.toString():
          let exist = null;
          // tester si la mesh est créée
          // si la mesh est créée donc this.mesh.play() va modifier la valeur de this.mesh.isPlaying en true
          const wait = () => {
            setTimeout(() => {
              this.mesh.play();
              if (this.mesh.isPlaying) {
                // si l'audio précédent en pause en va mettre cet audio aussi en pause
                if ( !playing) {this.mesh.pause(); }
                this.showedMenu[0].content[1].html.value = this.object.fileName;
                this.upload.emit(false);
                this.enablePublishEvent.emit();
              } else {
                wait();
              }
            }, 500);
          };
          const waitCheckExistFile = () => {
            setTimeout(() => {
              if (exist === null) {
                waitCheckExistFile();
              } else {
                if (exist === false) {
                  this.object.url = DEFAULT_AUDIO_URL;
                  this.object.fileName = DEFAULT_FILE_NAME;
                } else {
                  this.object.url = data.asset.assetObjectUrl;
                  this.object.fileName = data.asset.fileName;
                }


                this.mesh = this.object.editFile(this.scene);
                wait();
              }
            }, 500);
          };
          // pause current Mesh if is playing
          const playing = this.mesh.isPlaying;
          if ( playing) {this.mesh.pause(); }
          let checkFile = false;
          const x = new XMLHttpRequest();
          x.timeout = 15000;
          x.open('GET', data.asset.assetObjectUrl);
          x.onreadystatechange = ( event) => {
            if ( checkFile === false ) {
              checkFile = true;
              exist = x.status !== 404;
            }
          };
          x.send();

          waitCheckExistFile();
          break;
    }
  }
  // attendre la création de la mesh dans la scéne( image/ marker/ objet3D)
  waitCreateMesh(newComponent) {
    setTimeout(() => {
      this.scene.meshes.forEach((mesh) => {
        if (mesh.uniqueId === newComponent.uniqueId) {
          this.mesh = mesh;
          if (this.gizmo !== null) {
            this.gizmo.attachedMesh = mesh;
          }
          this.showedMenu[0].content[1].html.value = this.object.fileName;
          if (this.object.type === 'object') {
            this.object.scale = {x: this.mesh.scaling.x, y: this.mesh.scaling.y, z: this.mesh.scaling.z};
            // tslint:disable-next-line:max-line-length
            this.showedMenu.find((e) => e.name === 'Location').content[0].html.element.tabgroup.find((e) => e.html.click === GizmoTypes.SCALE).html.content[0].html.value = Math.round(this.mesh.scaling.x * 100) / 100;
          }
          this.changeMesh.emit(mesh);
          this.upload.emit(false);
          this.enablePublishEvent.emit();
        }
      });
      if (!this.mesh) {
        this.waitCreateMesh(newComponent);
      }
    }, 300);
  }


  // ************************************* end CHANGE-FILES ***************************//

  // ************* end change values in second menu (input, slide, range, color, select..) ***********//

  // ********** input number: key events
  onKeydown(event, min) {
    if (event.key === '-' && (min >= 0)) {
      event.preventDefault();
    }
  }
  onKeyup(event, change, content) {
    if (event.key === '0' ) {
      this.onChange((document.getElementById(content) as HTMLInputElement).value, change);
    }
  }
  // ********** end input number: key events

  // ************ open section event: to show the totality of the section
  scroll($event, open, part, index) {
    if (open === true) {
      setTimeout(() => {
        if (this.showedMenu[index + 1]) {
          const y = document.getElementById(this.showedMenu[index + 1].name).getBoundingClientRect().y;
          const height = document.getElementById('second-menu-content').getBoundingClientRect().height;
          if (y > height) {
            document.getElementById(part).scrollIntoView(false);
          }
        } else {
          document.getElementById(part).scrollIntoView(false);
        }
      }, 10);
    }
  }


}
