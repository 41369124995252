import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../../interfaces/user';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss']
})
export class ProfilePictureComponent implements OnInit {

  constructor() { }
  @Input() user: User;

  ngOnInit(): void {
  }

}
