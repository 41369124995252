import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {NotificationService} from './notification.service';
import {CONNECT_TO_CREATE_EXPERIENCE, ERROR_TEXT, YOU_ARE_NOT_CONNECTED,} from '../../utils/actionsLabels/labels';
import {UsersService} from './users.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {AuthenticationService} from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(
    private cookieService: CookieService,
    private auth: AngularFireAuth,
    private router: Router,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
    private  usersService: UsersService
  ) { }

  logout(event) {
    if (event.action && event.action === 'logout') {
      this.auth.signOut().then(() => {
        this.usersService.changeNavBar(false);
        this.notificationService.showInfo(CONNECT_TO_CREATE_EXPERIENCE, YOU_ARE_NOT_CONNECTED);
        this.authenticationService.logout(event.data);
      }).catch((error) => {
        // An error happened.
        this.notificationService.showError(error, ERROR_TEXT);
      });

    }
  }
}
