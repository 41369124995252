<div class="tz-2-com tz-2-main">
  <div class="center reject-experience-text">
    <h4 *ngIf="!isTemplate">{{"ASK_FOR_CONFIRM_REJECT_EXP_REV_ACTION" | translate}}  {{"'" + data.name + "'" + "?"}}</h4>
    <h4 *ngIf="isTemplate">{{"ASK_FOR_CONFIRM_REJECT_TEMP_REV_ACTION" | translate}}  {{"'" + data.name + "'" + "?"}}</h4>
    <br/>
    <p> {{"WRITE_YOUR_REMARKS_REVIEW" | translate}}</p>

    <br/>
    <form  [formGroup]="form"  class="col-md-12" >
      <div class="row ">
        <div class="input-field col s12">
            <textarea type="text"
                      id="remarks"
                      formControlName="remarks"
                      [formControl]="form.controls.remarks"
                      class="materialize-textarea"
                      autosize></textarea>
          <label for="remarks" [class.active]="remarks !== ''"> {{"REMARKS_REJECT" | translate}}</label>
        </div>
      </div>
      <div class="row alert-required" *ngIf="form.get('remarks').invalid && form.get('remarks').touched">
        {{'REMARKS_IS_REQUIRED' | translate}}
      </div>
      <br/>
      <!----------------------------------  buttons   ------------------------->
      <div class="row actions-popup">
        <button type="submit" class="button waves-effect waves-light btn-large save-popup-style" [disabled]="!form.valid "
                (click)="reject()">{{'REJECT' | translate}}</button>
        <button class="button waves-effect waves-light btn-large" (click)="cancel()">{{'Cancel' | translate}}</button>
      </div>
      <br/>
    </form>
  </div>
</div>
