import * as GUI from 'babylonjs-gui';
import {toNumbers} from '@angular/compiler-cli/src/diagnostics/typescript_version';
import * as BABYLON from 'babylonjs';
import {addHttp, round} from '../../../utils/functions/GlobalFunctions';
import {OnClick} from '../../interfaces/on-click';
import {rotationForUnity,} from '../../../utils/functions/RotationFunction';
import {fromPxToM} from '../../../utils/functions/PositionFunction';
import {v4 as uuidv4} from 'uuid';
import {FOUR_DECIMALS, TOW_DECIMALS} from '../../../utils/global-constants/forModel';

export class Text {
  public uniqueId;
  public name = 'text';
  public type = 'text';
  public visible = true;
  public triggerVisible = true;

  public content = 'Text content \n here';
  public color = '#157ad1';
  public align = '2'; // 2 for center align, 1 for right align, 3for left align
  public fontFamily = 'Ubuntu';

  public outlineWidth = '0';
  public outlineColor = '#fff';

  public background = '#ffffff';
  public borderColor = '#c8d7e3';
  public thickness = '5';
  public cornerRadius = '0';

  public width = 150;
  public height = 100;
  public fontSize = '20';
  public nbrCopy = 0;

  public rotation = {x: 0, y: 0, z: 0};
  public position = {x: 0, y: 20, z: 30};
  public scale = {x: 1, y: 1, z: 1};

  public onClick: Array<OnClick>; //[{ type: 'URL', info:['https..........']}]

// recreate=true: si c'est un objet dans content (database)
  constructor(object?, recreate?, name?, position?) {
    if (object) {
      this.type = 'text';
      this.content = object.content;
      this.align = object.align;
      this.fontFamily = object.fontFamily;
      this.fontSize = object.fontSize;
      this.outlineWidth = object.outlineWidth;
      this.outlineColor = object.outlineColor;
      this.borderColor = object.borderColor;
      this.width = object.width;
      this.height = object.height;
      this.rotation = {x: object.rotation.x, y: object.rotation.y, z: object.rotation.z};
      this.onClick = object.onClick;
      if (recreate) {
        this.name = object.name;
        this.visible = true;
        this.color = object.textColor;
        this.background = object.backgroundColor;
        this.thickness = object.borderWidth;
        this.nbrCopy = 0;
        this.triggerVisible = true;
        this.position = {x: object.position.x, y: object.position.y, z: object.position.z};
      } else {
        this.visible = object.visible;
        this.color = object.color;
        this.thickness = object.thickness;
        this.background = object.background;
      }
    }
    if (name) {
      this.name = name;
    }
    if (position) {
      this.position = position;
    }
  }
  addTextFromObjScene(scene: BABYLON.Scene) {
    const plane = BABYLON.MeshBuilder.CreatePlane('text', {height: this.height, width: this.width}, scene);

    plane.position = new BABYLON.Vector3(this.position.x, this.position.y, this.position.z);
    plane.rotation = new BABYLON.Vector3(this.rotation.x, this.rotation.y, this.rotation.z);
    plane.scaling = new BABYLON.Vector3(this.scale.x, this.scale.y, this.scale.z);

    const advancedTexturetext = GUI.AdvancedDynamicTexture.CreateForMesh(plane, this.width, this.height);

    const rectangle = new GUI.Rectangle();
    rectangle.background = this.background;
    rectangle.color = this.borderColor;
    rectangle.thickness = toNumbers(this.thickness)[0];
    rectangle.cornerRadius = toNumbers(this.cornerRadius)[0];
    rectangle.width = this.width + 'px';
    rectangle.height = this.height + 'px';
    advancedTexturetext.addControl(rectangle);

    const text = new GUI.TextBlock();
    text.text = this.content;
    text.color = this.color;
    text.textHorizontalAlignment = toNumbers(this.align)[0];
    text.outlineWidth = toNumbers(this.outlineWidth)[0];
    text.outlineColor = this.outlineColor;
    rectangle.addControl(text);
    text.fontFamily = this.fontFamily;
    text.fontSize = toNumbers(this.fontSize)[0];
    plane.renderingGroupId = 2;
    plane.uniqueId = uuidv4();
    this.uniqueId = plane.uniqueId;
    plane.isVisible = this.visible;
    return plane;
  }
  recreate(scene) {
    this.addTextFromObjScene(scene);
  }
  createNewPlane(scene: BABYLON.Scene, plane) {

    // Background
    const rectangle = new GUI.Rectangle();
    rectangle.background = this.background;
    rectangle.color = this.borderColor;
    rectangle.thickness = toNumbers(this.thickness)[0];
    rectangle.cornerRadius = toNumbers(this.cornerRadius)[0];
    rectangle.width = this.width + 'px';
    rectangle.height = this.height + 'px';

    // Text
    const text = new GUI.TextBlock();
    text.text = this.content;
    text.color = this.color;
    text.outlineWidth = toNumbers(this.outlineWidth)[0];
    text.outlineColor = this.outlineColor;
    text.textHorizontalAlignment = toNumbers(this.align)[0];
    text.fontFamily = this.fontFamily;
    text.fontSize = toNumbers(this.fontSize)[0];

    // delete current Plane
    plane.dispose();

    // New Plane
    const newPlane = BABYLON.MeshBuilder.CreatePlane('text', {height: this.height, width: this.width}, scene);

    newPlane.rotation = new BABYLON.Vector3(this.rotation.x, this.rotation.y, this.rotation.z);
    newPlane.position = new BABYLON.Vector3(this.position.x, this.position.y, this.position.z);
    newPlane.scaling = new BABYLON.Vector3(this.scale.x, this.scale.y, this.scale.z);

    const advancedTexturetext = GUI.AdvancedDynamicTexture.CreateForMesh(newPlane, this.width, this.height);
    advancedTexturetext.addControl(rectangle);
    rectangle.addControl(text);

    newPlane.uniqueId = this.uniqueId;
    newPlane.isVisible = this.visible;
    newPlane.renderingGroupId = 2;
    return newPlane;
  }

  outputJson(trigger) {
    // round function : pour limiter le nombre des chiffres après la virgule
    return {
      id: this.uniqueId,
      type: this.type,
      name: this.name,
      content: this.content,
      visibility: this.visible,
      textColor: this.color,
      align: this.align,
      fontFamily: this.fontFamily,
      fontSize: this.fontSize,
      outlineWidth: this.outlineWidth,
      outlineColor: this.outlineColor,
      backgroundColor: this.background,
      borderWidth: this.thickness,
      borderColor: this.borderColor,
      cornerRadius: this.cornerRadius,
      width: round(this.width , FOUR_DECIMALS),
      height: round(this.height, FOUR_DECIMALS),
      position: {
        x: round(fromPxToM(this.position.x - trigger.position.x), FOUR_DECIMALS),
        y: round(fromPxToM(this.position.y - trigger.position.y), FOUR_DECIMALS),
        z: round(fromPxToM(this.position.z - trigger.position.z), FOUR_DECIMALS),
      },
      rotation: {
        x: round(rotationForUnity(this.rotation).x, TOW_DECIMALS),
        y: round(rotationForUnity(this.rotation).y, TOW_DECIMALS),
        z: round(rotationForUnity(this.rotation).z, TOW_DECIMALS),
      },
      onClick: addHttp(this.onClick)
    };
  }
}
