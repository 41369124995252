<section class="item-favorite">
  <div class="">
    <div class="tz-2-com tz-2-main">
      <h4>{{"My favorite groups" | translate}}</h4>
      <div class="db-list-com tz-db-table">

        <!--          <a [routerLink]="['/experiences/' + item.experienceId]"><span class="span-link span-link1">{{'VIEW_MORE' | translate}}</span></a>-->

        <!------------ no published templates --------->
        <div class="tz-2-com tz-2-main" *ngIf="listGroups.length == 0 || noFavoritesGroup">
          <div class="center no_data_message">
            <div class="icon-content"><i class="fas fa-cubes"></i></div>
            {{"NO_PUBLISHED_GROUP_MESSAGE" | translate}}
          </div>
        </div>
        <!------------ end no published templates  --------->


        <!------------ published templates  exist --------->
        <!----------------------------  carousel  ------------------------------>
        <owl-carousel *ngIf="listGroups.length != 0&& !noFavoritesGroup" [options]="carouselOptions" [carouselClasses]="['owl-theme', 'row', 'sliding']">
          <div *ngFor="let item of listGroups;let i= index">
            <app-listing-group-favorite-item id="suggested_group_item_{{i}}" (groupAction)="GroupFavoriteAction(item.group_id,i)"
                                             [data]="item" >
            </app-listing-group-favorite-item>
          </div>
        </owl-carousel>
        <!----------------------------  end carousel  ------------------------------>
        <!------------ end published templates  exist--------->

      </div>
    </div>
  </div>
</section>



