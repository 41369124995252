import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ERROR_POPUP, ERROR_POPUP_MESSAGE} from '../../../../utils/actionsLabels/labels';


class DialogData {
}

@Component({
  selector: 'app-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss']
})
export class ErrorPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}


  ngOnInit(): void {
    if (this.data[0] === '' && this.data[1] === '' ) {    this.data[0] = ERROR_POPUP; this.data[1] = ERROR_POPUP_MESSAGE  }
 }
  onClick(): void {
    this.dialogRef.close();
  }
}
