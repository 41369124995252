import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SUGGESTED_GROUPS_PAGE_TITLE} from '../../../../utils/actionsLabels/labels';
import {SUGGESTED_GROUPS_PATH} from '../../../../utils/actionsLabels/pathLabels';
import {Group} from '../../../interfaces/group';

@Component({
  selector: 'app-groups-section',
  templateUrl: './groups-section.component.html',
  styleUrls: ['./groups-section.component.scss']
})
export class GroupsSectionComponent implements OnInit {
  sectionTitle = SUGGESTED_GROUPS_PAGE_TITLE ;
  suggestedGroupsLink = SUGGESTED_GROUPS_PATH;
  @Input() groups: Group [];
  @Input() carouselOptions: any;
  @Input() disabledButton: boolean;
  @Input() noSuggestedGroup: boolean;
  @Output() joinItemClick = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  onItemGroupClick(event, i) {
    this.joinItemClick.emit({id: event.output, data: i});
  }
}
