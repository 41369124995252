
// ***************************** convert rotation ************************************//
export function fromDegreesToEuler(j) {
  return (j * Math.PI) / 180;

}
export function fromEulerToDegrees(j) {
  const angle = Math.round((((j + 2 * Math.PI) * 180) / Math.PI) % 360);
  if (angle === 360) { return 0; }
  return angle;
}

/** Second menu rotation input value **/
// on ajoute - car on travaille avec -z
export function rotationForSecondMenu(j, rotationAxis) {
  if (rotationAxis === 'z') {return fromEulerToDegrees(j);}
  return fromEulerToDegrees(-j);
}
export function rotationFromSecondMenu(j, rotationAxis) {
  if (rotationAxis === 'z') {return fromDegreesToEuler(j); }
  return -fromDegreesToEuler(j);
}

/** From unity **/
export function rotationFromUnityComponent(rotation) { // component !== 3DObject
  return {
    x: fromDegreesToEuler(rotation.x),
    y: fromDegreesToEuler(rotation.y),
    z: fromDegreesToEuler(rotation.z),
  }
}
export function rotationFromUnity3DObj(rotation) {
  return {
    x : rotationFromSecondMenu(-rotation.x , "x"),
    y : rotationFromSecondMenu(-rotation.y, "y") ,
    z : rotationFromSecondMenu(-rotation.z  ,"z") ,
  };
}
// used when uploading experience content from DB
export function rotationFromUnityEditor(rotation, composant){

    return rotationFromUnityComponent(rotation);

}
/** For Unity **/
// used when creating JSON (save experience content)
export function rotationForUnity3DObj(rotation) {
  return {
    x: fromEulerToDegrees(rotation.x),
    y: fromEulerToDegrees(rotation.y),
    z: fromEulerToDegrees(rotation.z),
  };
}
export function rotationForUnity(rotation) {
  return {
    x: fromEulerToDegrees(rotation.x),
    y: fromEulerToDegrees(rotation.y),
    z: fromEulerToDegrees(rotation.z),
  };
}
// ***************************** end convert rotation ************************************//
