import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {TokenHelperService} from '../../reusables/services/token-helper.service';

@Injectable({
  providedIn: 'root'
})
export class SecureInnerPagesService implements CanActivate {

  constructor(private router: Router,
              private tokenHelperService: TokenHelperService) { }


  canActivate() {
    if (this.tokenHelperService.getConnectedUser()) {
      this.router.navigate(['']);
      return false;
    } else {
      return true;
    }
  }
}
