import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {map} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {ExperiencesService} from '../../reusables/services/experiences.service';
import {UsersService} from '../../reusables/services/users.service';
import {Title} from '@angular/platform-browser';
import {
  ASK_FOR_CONFIRM_DELETE_ACTION,
  PAGE_TITLE_PREFIX,
  SCROLL_TO_ABOUT, SCROLL_TO_GALLERY, SCROLL_TO_MY_REVIEW, SCROLL_TO_USER_REVIEW, SCROLL_TO_WRITE_REVIEW,
  SECTION_MY_REVIEW_LABEL,
  SECTION_WRITE_REVIEW_LABEL,
  STATUS_DRAFT,
  STATUS_IN_MODERATION,
  STATUS_PUBLISHED,
  STATUS_UNPUBLISHED,
  SUCCESS_ADD_REVIEW_MESSAGE_CONTENT,
  SUCCESS_ADD_REVIEW_MESSAGE_TITLE,
  SUCCESS_ADMIN_ADD_REVIEW_MESSAGE_TITLE,
  SUCCESS_ADMIN_EDIT_REVIEW_MESSAGE_TITLE, SUCCESS_DELETE_REVIEW_MESSAGE_TITLE,
  SUCCESS_EDIT_REVIEW_MESSAGE_CONTENT,
  SUCCESS_EDIT_REVIEW_MESSAGE_TITLE,
  SUCCESS_VALIDATE_TEMPLATE_MESSAGE_TITLE, WRITE_DISLIKE_ACTION, WRITE_LIKE_ACTION, WRITE_LIKE_DISLIKE_EDIT,
  WRITE_REVIEW_ADD,
  WRITE_REVIEW_DELETE,
  WRITE_REVIEW_EDIT,
  WRITE_REVIEW_UPDATE,
  WRITE_TEMPLATE_FAVORITE_ACTION
} from '../../utils/actionsLabels/labels';
import {Gallery, Template, TemplateVideo} from '../../reusables/interfaces/templates';
import {
  menuAfterAddingReview,
  menuBeforeAddingReview, menuOfExperienceOwner,
  menuReviewTemplate
} from '../../utils/jsonData/detailsExperienceMenu';
import {Creator, User} from '../../reusables/interfaces/user';
import {getPageStructure} from './details-template-page-structure';
import {FORBIDDEN_PATH} from '../../utils/errorsReactions/errors-texts';
import {RoleTemplate, templateStatus, templateStatusEnum} from '../../utils/global-constants/templates-const';
import {CreateTemplateDialogComponent} from '../../reusables/components/popups/create-template-dialog/create-template-dialog.component';
import {CREATE_EXPERIENCE_PATH, CREATE_TEMPLATE_PATH} from '../../utils/actionsLabels/pathLabels';
import {MatDialog} from '@angular/material/dialog';
import {TokenHelperService} from '../../reusables/services/token-helper.service';
import {HttpParams} from '@angular/common/http';
import {
  FULL_RESPONSE,
  LIMIT_FILTER,
  RANDOM_FILTER,
  SORT_FILTER,
  USER_ID_IS,
  USER_IS_NOT
} from '../../utils/queryParams/experiencesQueryParams';
import {CURRENT_TEMPLATE_ID} from '../../utils/queryParams/templatesQueryParams';
import {FacebookService, UIParams, UIResponse} from 'ngx-facebook';
import {TemplateFavorite} from "../../reusables/interfaces/TemplateFavorite";
import {userRole} from "../../utils/global-constants/user-const";
import {NotificationService} from "../../reusables/services/notification.service";
import {ValidateItemDialogComponent} from "../../reusables/components/popups/validate-item-dialog/validate-item-dialog.component";
import {ReviewFormData} from "../../reusables/interfaces/review-form-data";
import {Review} from "../../reusables/interfaces/review";
import {ConfirmDialogService} from "../../reusables/components/confirm-dialog/confirm-dialog.service";
import {LikeDislikeReview} from "../../reusables/interfaces/like-dislike";
import {LikeDislike} from "../../utils/global-constants/Like-dislike-const";

@Component({
  selector: 'app-details-template',
  templateUrl: './details-template.component.html',
  styleUrls: ['./details-template.component.scss',
    '../../../assets/customCss/waiting-load.scss']
})
export class DetailsTemplateComponent implements OnInit, OnDestroy {

  id: string; // current template id
  template: Template;
  menu = menuReviewTemplate; // inner menu
  activeSubMenu = SCROLL_TO_ABOUT;
  doneDetails = false;
  coverTemplate = '#41809b';
  pageStructure = {left: [], right: []};
  userId: string;
  userRoleTemplate: string;
  dialogRef;
  templateCreator: Creator;
  gallery: Array<Gallery>;
  video: string;
  videoData: TemplateVideo;
  videoStatus = false;
  suggestedTemplates: Template[];
  doneSuggestedTemplates = false;
  halfPosition;
  valueToDisplay;
  isHalfExists = false;
  emptyStars;
  displayEditFixedMenu = false;
  statusNumber: number;
  isLogged = false;
  destroyComponent = false;
  templateFav: TemplateFavorite;
  etatTemplateFavorite =new Map<String, boolean>();
  waitingLoad = false;
  tempFavoriteExist = false;
  doneForTempFavorite = false;
  displayValidateFixedMenu = false;
  user: User;
  disabledButton = false;
  disabledButtonFavorite = false;
  singleReview: ReviewFormData;
  reviews: Review[] = [];
  myReview: any;
  sectionReviewToShow: string;
  reviewsCounter = 0;
  reviewsAreLoaded = false;
  ownReview = false;
  // like /dislike
  likeDislike: LikeDislikeReview;
  likes = 0;
  dislikes = 0;
  LikeMap = new Map<String, number>();
  DislikeMap = new Map<String, number>();
  etatLike =new Map<String, boolean>();
  etatDislike =new Map<String, boolean>();

  constructor(private route: ActivatedRoute,
              private tokenHelperService: TokenHelperService,
              private experiencesService: ExperiencesService,
              private usersService: UsersService,
              private titleService: Title,
              private router: Router,
              public dialog: MatDialog,
              private fbk: FacebookService,
              private notificationService: NotificationService,
              private confirmDialogService: ConfirmDialogService) {
    fbk.init({
        appId: '145889360804091', cookie: true, status: true, xfbml: true, version: 'v10.0'
      }).then();
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    // TODO: add group section
    this.route.paramMap.pipe(
      map(params => {
          this.initValues();
          this.id = params.get('id');
          this.isLogged = this.tokenHelperService.getConnectedUser();
          // if (this.isLogged) {
          //   this.userId = this.tokenHelperService.getTokenData().user_id;
          // }
          this.getProfile();
        }
      )
    ).subscribe(() => {
    });
  }
  ngOnDestroy(): void {
    this.destroyComponent = true;
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  initValues() {
    this.doneDetails = false;
    this.isHalfExists = false;
    this.menu = menuReviewTemplate;
    this.activeSubMenu = SCROLL_TO_ABOUT;
    this.displayEditFixedMenu = false;
    this.displayValidateFixedMenu = false;
    this.reviewsCounter = 0;
    this.reviewsAreLoaded = false;
    this.ownReview = false;
  }

  getProfile() {
    this.usersService.getUserProfile().subscribe((data: User) => {
      this.user = data;
      this.userId = data.id;
      this.getTemplate()
    });
  }

  getTemplate() {
    window.scrollTo(0, 0);
    this.experiencesService.getDetailTemplates(this.id).subscribe((data: Template) => {
      this.template = data;
      this.titleService.setTitle(PAGE_TITLE_PREFIX + this.template.name);
      if (this.template.creator.userId === this.userId) {
        this.userRoleTemplate = RoleTemplate.CREATOR;
        this.displayEditFixedMenu = true;
      } else {
        this.userRoleTemplate = RoleTemplate.NOT_CREATOR;
      }
      if ((this.user.role === userRole.ADMIN.valueOf() || this.user.role === userRole.SUPER_ADMIN.valueOf())
          && this.template.status === templateStatus.IN_MODERATION.valueOf()) {
        this.displayValidateFixedMenu = true;
      }
      this.getSuggestedTemplates(this.template.id);
      this.templateCreator = this.template.creator;
      this.gallery = this.template.gallery;
      this.video = this.template.video;
      if (this.template.cover_picture !== '') {
        this.coverTemplate = 'url(' + this.template.cover_picture + ') no-repeat center bottom';
      }
      switch (this.template.status) {
        case STATUS_PUBLISHED:
          this.statusNumber = 0;
          break;
        case STATUS_UNPUBLISHED:
          this.statusNumber = 1;
          break;
        case STATUS_DRAFT:
          this.statusNumber = 2;
          break;
        case STATUS_IN_MODERATION:
          this.statusNumber = 4;
          break;
      }
      this.applyRating();
      this.doneDetails = true;
      this.manageReviews();
    });
    //template favorite
    this.etatTemplateFavorite.set(this.id,false);
    this.experiencesService.getUserTemplateFavorite(this.id).subscribe(async (response: TemplateFavorite) => {
        this.templateFav = response;
        if (this.templateFav.template_id){
          this.etatTemplateFavorite.set(this.id,true);
          this.tempFavoriteExist = true;
        }
      this.doneForTempFavorite = true;
      });
  }

  getSuggestedTemplates(id) {
    let params = new HttpParams();
    params = params.append(CURRENT_TEMPLATE_ID, id);
    params = params.append(LIMIT_FILTER, '3');
    params = params.append(SORT_FILTER, RANDOM_FILTER);
    this.experiencesService.getTemplates(params).subscribe((data: Template []) => {
      this.suggestedTemplates = data;
      this.doneSuggestedTemplates = true;
    });
  }

  applyRating() {
    const stringOfData = this.template.rating.toString();
    this.halfPosition = Math.floor(this.template.rating);
    if (stringOfData.indexOf('.5') > 0) {
      this.isHalfExists = true;
      this.emptyStars = 4 - this.halfPosition;
      this.valueToDisplay = stringOfData;
    } else {
      this.valueToDisplay = stringOfData;
      this.emptyStars = 5 - this.template.rating;
    }
  }

  setPageStructure() {
    this.pageStructure = getPageStructure(this.userRoleTemplate, this.template.status, this.id, this.sectionReviewToShow);
    if (!this.pageStructure) {
      this.router.navigateByUrl(FORBIDDEN_PATH).then();
    }
  }

  scrollTo(event) {
    if (event.action && event.action === 'scroll') {
      this.scrollAction(event.target);
    }
  }

  scrollAction(el) {
    const item = document.getElementById(el);
    if (item) {
      window.scrollTo({top: item.offsetTop + 230, behavior: 'smooth'});
    }
  }

  // action button "use"
  useTemplate(event) {
     if (this.destroyComponent !== true) { // prevent popup from appearing on other pages
       this.dialogRef = this.dialog.open(CreateTemplateDialogComponent, {
         disableClose: false,
         autoFocus: true,
         panelClass: 'my-dialog',
         width: '800px',
         maxHeight: '500px',
       });
       this.dialogRef.afterClosed().subscribe((result) => {
         if (result === 'create template' || result === 'create experience') {
           if (result === 'create template') {
             // go to form create template
             this.router.navigate(
               [CREATE_TEMPLATE_PATH],
               {state: {source: 'template', idContent: event}}
             ).then();
           } else {
             // go to page create experience
             this.router.navigate(
               [CREATE_EXPERIENCE_PATH],
               {state: {source: 'template', idContent: event}}
             ).then();
           }
         }
       });
     }
  }

  // actions video
  openVideo(event) {
    this.videoData = {name: this.template.name, video: event.video};
    this.videoStatus = true;
  }

  refreshPopupStatus(event) {
    this.videoStatus = event.videoStatus;
  }

  shareFacebook(event) {
    const options: UIParams = {
      method: 'share',
      href: event
    };
    this.fbk.ui(options)
      .then((res: UIResponse) => {})
      .catch();
  }
  shareTwitter(event) {
    this.windowPopup("https://twitter.com/intent/tweet/?text=Visit%20and%20test%20the%20AR%20template!%0A&url="+event+
          "%0A&hashtags=AR,AugmentedReality%0A&via=Camlann10", 700, 400);
  }

  shareLinkedin(event) {
    this.windowPopup("https://www.linkedin.com/shareArticle?mini=true&url="+event, 700,400);
  }

  windowPopup(url, width, height) {
    // Calculate the position of the popup so it’s centered on the screen.
    const left = (screen.width / 2) - (width / 2), top = (screen.height / 2) - (height / 2);
    window.open(url,
        "",
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left
      );
  }

  sendData(event) {
    if (event.type && event.type !== '') {
      switch (event.type) {
        case WRITE_TEMPLATE_FAVORITE_ACTION:
          this.waitingLoad = true;
          this.TemplateFavoriteAction(event.data);
          break;
        case WRITE_REVIEW_ADD:
          this.waitingLoad = true;
          this.addReview(event.data);
          break;
        case WRITE_REVIEW_UPDATE:
          this.waitingLoad = true;
          this.updateReview(event.data);
          break;
        case WRITE_REVIEW_EDIT:
          this.waitingLoad = true;
          this.editReview(event.data);
          break;
        case WRITE_REVIEW_DELETE:
          this.deleteReview(event.data);
          this.reviewsCounter = 0;
          this.singleReview = {
            review: '',
            rating: 0,
            id: '0'
          };
          break;
        case WRITE_LIKE_ACTION:
          this.waitingLoad = true;
          this.LikeAction(event.data);
          break;
        case WRITE_DISLIKE_ACTION:
          this.waitingLoad = true;
          this.DislikeAction(event.data);
          break;
        case WRITE_LIKE_DISLIKE_EDIT:
          this.waitingLoad = true;
          this.editLikeDislike(event.data,event.action, event.data1);
          break;
      }
    }
  }
  //Template favorite

  addTemplateToFavorites(id) {
    this.disabledButtonFavorite = true;
    const requestBody = new FormData();
    requestBody.append('userId', this.userId);
    requestBody.append('templateId', id);
    this.experiencesService.addTemplateToFavorites(id, requestBody).subscribe(() => {
      this.waitingLoad = false;
      this.tempFavoriteExist = true;
      this.disabledButtonFavorite = false;
    });
  }

  deleteTemplateFromFavorites(id) {
    this.disabledButtonFavorite = true;
    this.experiencesService.deleteTemplateFromFavorites(id).subscribe(() => {
      this.waitingLoad = false;
      this.tempFavoriteExist = false;
      this.disabledButtonFavorite = false;
    });
  }

  TemplateFavoriteAction(id) {
    this.waitingLoad = true;
    if (this.tempFavoriteExist == true ) {
      this.deleteTemplateFromFavorites(id);
      this.etatTemplateFavorite.set(id, false);
    } else {
      this.addTemplateToFavorites(id);
      this.etatTemplateFavorite.set(id,true);
    }
  }

  validateTemp() {
    if (this.destroyComponent !== true) { // prevent popup from appearing on other pages
      this.dialogRef = this.dialog.open(ValidateItemDialogComponent, {
        disableClose: false,
        autoFocus: true,
        panelClass: 'my-dialog',
        width: '600px',
        maxHeight: '500px',
        data: {name: this.template.name, type: 'template'}
      });
      this.dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.disabledButton = true;
          const requestBody = new FormData();
          requestBody.append('status', String(templateStatusEnum[0].value));
          this.experiencesService.moderateTemplate(requestBody, this.template.id).subscribe(() => {
            this.notificationService.showSuccess('', SUCCESS_VALIDATE_TEMPLATE_MESSAGE_TITLE);
            this.getTemplate();
            this.displayValidateFixedMenu = false;
          }, (error => {
            this.getTemplate();
            this.disabledButton = true;
          }));
        }
      });
    }
  }

  // Template review
  manageReviews() {
    // * check if there are a connected user
    if (this.userId) { // there is a connected user
        if (this.userId === this.template.creator.userId) {
          this.getTemplateReviews();
          this.menu = menuOfExperienceOwner;
        } else { // connected user is not the creator of current experience
          this.getTemplateReviewOfConnectedUser();
        }
    } else { // no connected user
      this.getTemplateReviews();
      this.menu = menuOfExperienceOwner;
    }
  }

  getTemplateReviews() {
    const params = new HttpParams();
    this.experiencesService.getTemplateReviews(this.template.id, params).subscribe((response: Review[]) => {
        this.reviews = response;
        if (this.reviews && this.reviews.length) {
          this.reviewsCounter = this.reviews.length;
        }
        this.reviewsAreLoaded = true;
        //get likes and dislikes
        for(let i=0;i<this.reviews.length;i++) {
          this.LikeMap.set(this.reviews[i].id, this.reviews[i].number_likes);
          this.DislikeMap.set(this.reviews[i].id, this.reviews[i].number_dislikes);

          // button like/dislike color for user
          if(this.reviews[i].user_action==LikeDislike.LIKE){
            this.etatDislike.set(this.reviews[i].id,false);
            this.etatLike.set(this.reviews[i].id,true);
          } else if (this.reviews[i].user_action==LikeDislike.DISLIKE){
            this.etatDislike.set(this.reviews[i].id,true);
            this.etatLike.set(this.reviews[i].id,false);
          } else {
            this.etatDislike.set(this.reviews[i].id, false);
            this.etatLike.set(this.reviews[i].id, false);
          }
        }
        //end get likes and dislikes
        this.setPageStructure();
      });
  }

  getTemplateReviewOfConnectedUser() {
    let params = new HttpParams();
    params = params.append(USER_ID_IS, this.userId);
    this.experiencesService.getTemplateReviews(this.id, params).subscribe((data: Review[]) => {
      this.myReview = data;
      this.ownReview = true;
      if (this.myReview && this.myReview[0] && this.myReview[0].id !== '') {
        this.waitingLoad = false;
        this.reviewsCounter = 1;
        this.sectionReviewToShow = SECTION_MY_REVIEW_LABEL;
        this.menu = menuAfterAddingReview;
        //get likes and dislikes
        this.LikeMap.set(this.myReview[0].id,this.myReview[0].number_likes);
        this.DislikeMap.set(this.myReview[0].id, this.myReview[0].number_dislikes);

        this.etatDislike.set(this.myReview[0].id,false);
        this.etatLike.set(this.myReview[0].id,false);
        //end get likes and dislikes
      } else {
        this.sectionReviewToShow = SECTION_WRITE_REVIEW_LABEL;
        this.menu = menuBeforeAddingReview;
      }
      this.waitingLoad = false;
      this.getTemplateReviewsOfUsersNotConnected();
      this.setPageStructure();
    });
  }

  getTemplateReviewsOfUsersNotConnected() {
    let params = new HttpParams();
    if (this.userId) {
      params = params.append(USER_IS_NOT, this.userId);
      params = params.append(FULL_RESPONSE, String(true))
    }
    this.experiencesService.getTemplateReviews(this.template.id, params).subscribe( (response: Review[]) => {
      this.reviews = response;
      if (this.reviews && this.reviews.length > 0) {
        this.reviewsCounter += this.reviews.length;
      }
      for(let i=0;i<this.reviews.length;i++)
      {
        this.LikeMap.set(this.reviews[i].id, this.reviews[i].number_likes);
        this.DislikeMap.set(this.reviews[i].id, this.reviews[i].number_dislikes);

        // button like/dislike color for user
        if(this.reviews[i].user_action==LikeDislike.LIKE){
          this.etatDislike.set(this.reviews[i].id,false);
          this.etatLike.set(this.reviews[i].id,true);
        }else if (this.reviews[i].user_action==LikeDislike.DISLIKE){
          this.etatDislike.set(this.reviews[i].id,true);
          this.etatLike.set(this.reviews[i].id,false);
        } else {
          this.etatDislike.set(this.reviews[i].id,false);
          this.etatLike.set(this.reviews[i].id,false );
        }
      }
      this.reviewsAreLoaded = true;
      this.waitingLoad = false;
    });
  }

  addReview(data) {
    const requestBody = new FormData();
    requestBody.append('rating', data.rating);
    requestBody.append('review', data.review);
    this.experiencesService.addTemplateReview(this.template.id, requestBody).subscribe((response: Review) => {
        this.myReview = response;
        this.getTemplate();
        if (this.user.role == userRole.SUPER_ADMIN.valueOf() || this.user.role === userRole.ADMIN.valueOf()) {
          this.notificationService.showSuccess(SUCCESS_ADD_REVIEW_MESSAGE_CONTENT, SUCCESS_ADMIN_ADD_REVIEW_MESSAGE_TITLE);
        } else {
          this.notificationService.showSuccess(SUCCESS_ADD_REVIEW_MESSAGE_CONTENT, SUCCESS_ADD_REVIEW_MESSAGE_TITLE);
        }
        this.menu = menuAfterAddingReview;
      });
  }

  updateReview(data) {
    const requestBody = new FormData();
    requestBody.append('rating', data.rating);
    requestBody.append('review', data.review);
    this.experiencesService.updateTemplateReview(data.id, this.template.id, requestBody).subscribe(() => {
      this.getTemplate();
      if (this.user.role == userRole.SUPER_ADMIN.valueOf() || this.user.role === userRole.ADMIN.valueOf()) {
        this.notificationService.showSuccess(SUCCESS_EDIT_REVIEW_MESSAGE_CONTENT, SUCCESS_ADMIN_EDIT_REVIEW_MESSAGE_TITLE);
      } else {
        this.notificationService.showSuccess(SUCCESS_EDIT_REVIEW_MESSAGE_CONTENT, SUCCESS_EDIT_REVIEW_MESSAGE_TITLE);
      }
    });
  }

  deleteReview(data) {
    const id = data;
    this.confirmDialogService.confirmThis(ASK_FOR_CONFIRM_DELETE_ACTION, () => {
      this.waitingLoad = true;
      this.experiencesService.deleteTemplateReview(id, this.template.id).subscribe(() => {
        this.notificationService.showSuccess('', SUCCESS_DELETE_REVIEW_MESSAGE_TITLE);
        this.getTemplate();
      });
    }, () => {
    });
  }

  editReview(data) {
    // getReviewDetails
    this.getDetailsTemplateReview(data);
  }
  getDetailsTemplateReview(id) {
    this.experiencesService.getDetailsTemplateReview(id, this.template.id).subscribe((data: Review) => {
      this.waitingLoad = false;
      this.singleReview = {review: data.review, rating: data.rating, id: data.id};
      this.sectionReviewToShow = SECTION_WRITE_REVIEW_LABEL;
      this.setPageStructure();
    });
  }
  cancelEditReview() {
    this.sectionReviewToShow = SECTION_MY_REVIEW_LABEL;
    this.setPageStructure();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (document.querySelector('#' + SCROLL_TO_ABOUT)
      && this.isScrolledIntoView(document.querySelector('#' + SCROLL_TO_ABOUT))) {
      this.activeSubMenu = SCROLL_TO_ABOUT;
    } else if (document.querySelector('#' + SCROLL_TO_GALLERY)
      && this.isScrolledIntoView(document.querySelector('#' + SCROLL_TO_GALLERY))) {
      this.activeSubMenu = SCROLL_TO_GALLERY;
    } else if (document.querySelector('#' + SCROLL_TO_WRITE_REVIEW)
      && this.isScrolledIntoView(document.querySelector('#' + SCROLL_TO_WRITE_REVIEW))) {
      this.activeSubMenu = SCROLL_TO_WRITE_REVIEW;
    } else if (document.querySelector('#' + SCROLL_TO_MY_REVIEW)
      && this.isScrolledIntoView(document.querySelector('#' + SCROLL_TO_MY_REVIEW))) {
      this.activeSubMenu = SCROLL_TO_MY_REVIEW;
    } else if (document.querySelector('#' + SCROLL_TO_USER_REVIEW)
      && this.isScrolledIntoView(document.querySelector('#' + SCROLL_TO_USER_REVIEW))) {
      this.activeSubMenu = SCROLL_TO_USER_REVIEW;
    }
  }

  isScrolledIntoView(elem) {
      const rect = elem.getBoundingClientRect();
      const elemTop = rect.top;
      const elemBottom = rect.bottom;
      // Only completely visible elements return true:
      return (elemTop >= 0) && (elemBottom <= window.innerHeight);
  }

  // like_dislike

  addLike(id) {
    const requestBody = new FormData();
    const action = LikeDislike.LIKE ;
    requestBody.append('userId', this.userId);
    requestBody.append('reviewId', id);
    requestBody.append('action', action);
    this.experiencesService.addLikeDislikeTemplateReview(this.template.id, id, requestBody).subscribe(() => {
      this.getTemplateReviewsOfUsersNotConnected();
    });
  }

  addDislike(id) {
    const requestBody = new FormData();
    const action = LikeDislike.DISLIKE ;
    requestBody.append('user_id', this.userId);
    requestBody.append('review_id', id);
    requestBody.append('action', action);
    this.experiencesService.addLikeDislikeTemplateReview(this.template.id, id, requestBody).subscribe(() => {
      this.getTemplateReviewsOfUsersNotConnected();
    });
  }

  editLikeDislike(id, action, id_action) {
    const requestBody = new FormData();
    requestBody.append('user_id', this.userId);
    requestBody.append('review_id', id);
    requestBody.append('action',action);
    this.experiencesService.editLikeDislikeTemplateReview(this.template.id, id, id_action, requestBody).subscribe(() => {
      this.getTemplateReviewsOfUsersNotConnected();
    });
  }

  deleteLikeDislike(id, id_action) {
    this.experiencesService.deleteLikeDislikeTemplateReview(this.template.id, id, id_action).subscribe(() => {
      this.getTemplateReviewsOfUsersNotConnected();
    });
  }

  LikeAction(id) {
    for (let i = 0; i < this.reviews.length; i++) {
      if (this.reviews[i].id == id) {
        if (this.reviews[i].user_action == LikeDislike.DISLIKE) {
          this.editLikeDislike(id,LikeDislike.LIKE, this.reviews[i].id_action);
          this.DislikeMap.set(id,this.DislikeMap.get(id)-1);
          this.LikeMap.set(id,this.LikeMap.get(id)+1);
          this.etatLike.set(id,true);
          this.etatDislike.set(id,false);
        } else {
          if (this.reviews[i].user_action == LikeDislike.LIKE) {
            this.deleteLikeDislike(id, this.reviews[i].id_action);
            this.LikeMap.set(id, this.LikeMap.get(id) - 1);
            this.etatLike.set(id, false);
          } else {
            this.addLike(id);
            this.LikeMap.set(id, this.LikeMap.get(id) + 1);
            this.etatLike.set(id, true);
          }
        }
        break;
      }
    }
  }

  DislikeAction(id) {
    for (let i = 0; i < this.reviews.length; i++) {
      if (this.reviews[i].id == id) {
        if (this.reviews[i].user_action == LikeDislike.LIKE) {
          this.editLikeDislike(id,LikeDislike.DISLIKE, this.reviews[i].id_action);
          this.DislikeMap.set(id,this.DislikeMap.get(id)+1);
          this.LikeMap.set(id,this.LikeMap.get(id)-1);
          this.etatDislike.set(id,true);
          this.etatLike.set(id,false);
        } else {
          if (this.reviews[i].user_action == LikeDislike.DISLIKE) {
            this.deleteLikeDislike(id, this.reviews[i].id_action);
            this.DislikeMap.set(id,this.DislikeMap.get(id)-1);
            this.etatDislike.set(id,false);
          } else {
            this.addDislike(id);
            this.DislikeMap.set(id,this.DislikeMap.get(id)+1);
            this.etatDislike.set(id,true);
          }
        }
        break;
      }
    }
  }
}
