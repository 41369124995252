import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Review} from '../../../interfaces/review';
import {User} from '../../../interfaces/user';

@Component({
  selector: 'app-details-reviews',
  templateUrl: './details-reviews.component.html',
  styleUrls: ['./details-reviews.component.scss']
})
export class DetailsReviewsComponent implements OnInit {

  constructor() {
  }

  @Input() rating: number;
  @Input() connectedUser: User;
  @Input() reviews: Review[];
  @Input() reviewsCounter: number;
  @Output() review = new EventEmitter();

  @Input() likes: any;
  @Input() dislikes: any;
  @Input() etatLike: any;
  @Input() etatDislike: any;
  param = {value: 0};
  para = {value: 0};
  par = {value: 0};

  r = 1; // initial page in pagination of reviews
  doneForReviews = false;

  ngOnInit(): void {
    this.param = {value: this.reviewsCounter};
    this.para = {value: this.likes};
    this.par = {value: this.dislikes};

    if (this.reviews && this.reviews.length > 0) {
      this.doneForReviews = true;
    }
  }

  reviewData(event) {
    this.review.emit(event);
  }
}
