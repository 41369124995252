export enum RoleTemplate {
  CREATOR = 'CREATOR',
  NOT_CREATOR = 'NOT_CREATOR'
}

export enum templateStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED  = 'UNPUBLISHED ',
  DRAFT = 'DRAFT',
  IN_MODERATION = 'IN_MODERATION'
}

export const templateStatusEnum = [
  {
    label: 'PUBLISHED',
    value: 'PUBLISHED',
  },
  {
    label: 'UNPUBLISHED',
    value: 'UNPUBLISHED',
  },
  {
    label: 'DRAFT',
    value: 'DRAFT',
  },
  {
    label: 'IN_MODERATION',
    value: 'IN_MODERATION',
  }
];
export const editTemplateStatusAdmin = [
  {
    label: 'PUBLISHED',
    value: 'PUBLISHED',
  },
  {
    label: 'UNPUBLISHED',
    value: 'UNPUBLISHED',
  },
  {
    label: 'DRAFT',
    value: 'DRAFT',
  }
];
export const editTemplateStatusUser = [
  {
    label: 'PUBLISHED',
    value: 'PUBLISHED',
  },
  {
    label: 'SUBMIT_FOR_MODERATION',
    value: 'IN_MODERATION',
  },
  {
    label: 'UNPUBLISHED',
    value: 'UNPUBLISHED',
  },
  {
    label: 'DRAFT',
    value: 'DRAFT',
  }
];
