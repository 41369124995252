<a [routerLink]="link" >
  <div class="list-mig-like-com" [ngStyle]="{'background': coverExperience}">
    <div class="list-mig-lc-img">
      <!--      <img src="{{data.coverPicture}}" *ngIf="data.coverPicture" alt="{{data.name}}" />-->
<!--      <span class="home-list-pop-rat list-mi-pr">$1,99</span>-->
    </div>
    <div class="list-mig-lc-con">
      <div class="list-rat-ch list-room-rati">
        <app-rating [rating]="data.rating" *ngIf="data.rating > 0"></app-rating>
      </div>
      <h5 title="{{data.name}}" class="ellipsis-style">{{data.name}}</h5>
      <p>{{data.createdDate | date}}</p>
    </div>
  </div>
</a>
