<section class="item-favorite">
  <div >
    <div class="tz-2-com tz-2-main">
      <h4>{{"My favorite experiences" | translate}}</h4>
      <div class="db-list-com tz-db-table">

        <!------------ no published experiences --------->
        <div class="tz-2-com tz-2-main" *ngIf="listExperiences.length == 0 || noFavoritesExperiences">
          <div class="center no_data_message">
            <div class="icon-content"><i class="fas fa-cubes"></i></div>
            {{"NO_PUBLISEHD_EXPERIENCE_MESSAGE" | translate}}
          </div>
        </div>
        <!------------ end no published experiences  --------->

        <!------------ published experiences  exist --------->
        <!----------------------------  carousel  ------------------------------>
        <owl-carousel *ngIf="listExperiences.length != 0 && !noFavoritesExperiences" [options]="carouselOptions" [carouselClasses]="['owl-theme', 'row', 'sliding']">
          <div *ngFor="let item of listExperiences;let i= index">
            <app-listing-experience-favorite-item id="suggested_experience_item_{{i}}" (experienceAction)="applyAction(item.experience_id,i)"
                                                  [data]="item" >
            </app-listing-experience-favorite-item>
          </div>
        </owl-carousel>
        <!----------------------------  end carousel  ------------------------------>
        <!------------ end published experiences  exist--------->


      </div>
    </div>
  </div>
</section>








