import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-display-information-page',
  templateUrl: './display-information-page.component.html',
  styleUrls: ['./display-information-page.component.scss']
})
export class DisplayInformationPageComponent implements OnInit {

  constructor() {
  }
  deviceInfo = null;
  @Input() title;
  @Input() content;
  @Input() group;
  @Input() link;
  @Input() showButton;
  @Input() showGroupLink;
  @Input() disabledButton: boolean;
  @Input() downloadApp:boolean;
  @Input() isClosedGroup: boolean;
  @Output() acceptMember = new EventEmitter();
  @Output() refuseMember = new EventEmitter();

  ngOnInit(): void {
  }
  acceptMemberAction() {
    this.acceptMember.emit();
  }
  refuseMemberAction() {
    this.refuseMember.emit();
  }
}
