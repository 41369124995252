<div class="custom-popup" [ngClass]="isOpened? 'opened' : ''">
  <div class="popup-inner">
    <div class="popup-title">
      <h6>{{data.name}}</h6>
      <div class="popup-close-btn" (click)="closePopup()"><i class="fa fa-close"></i></div>
    </div>
    <div class="popup-content">

      <iframe
        id = "iframe-gallery-video" class="iframe-gallery-video"
        [attr.src]="urlSafe"
        frameborder="0"
        width="100%" height="500"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>

  </div>
</div>
