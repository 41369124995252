<div class="tz-2-com tz-2-main">
  <div class="center leave-text">
    <p >{{"ASK_FOR_CONFIRM_LEAVE_GROUP_ACTION" | translate}}  {{"'" + data.name + "'" + "?"}}</p>
  </div>
</div>
<div class="row actions-popup">
  <button class="button  waves-effect waves-light btn-large save-popup-style"
          (click)="leave()">{{'LEAVE_GROUP' | translate}}</button>
  <button class="button waves-effect waves-light btn-large"
          (click)="cancel()">{{'Cancel' | translate}}</button>
</div>
