<div class="qr1" *ngIf="!typeMobile">
  <img src="{{image}}" class="qrCodeImg"/>
  <div *ngIf="source=='experience'" class="qr-hint-mob">{{"SCAN_ME" | translate}}</div>
  <div *ngIf="source=='template'" class="qr-hint-mob">{{"SCAN_ME_TEMPLATE" | translate}}</div>
</div>
<div class="qr1Mob" *ngIf="typeMobile">
  <img   src="{{image}}" class="qrCodeImgMob"/>
  <div *ngIf="source=='experience'" class="qr-hint-mob">{{"SCAN_ME" | translate}}</div>
  <div *ngIf="source=='template'" class="qr-hint-mob">{{"SCAN_ME_TEMPLATE" | translate}}</div>
</div>
