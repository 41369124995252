import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CANCEL_ACTION, SAVE_CHANGES_ACTION} from '../../../../utils/actionsLabels/labels';
import {CustomValidators} from '../../../../pages/signup/custom-validators';
import {CookieService} from 'ngx-cookie-service';
import {specialCharacters} from '../../../../utils/global-constants/regExp';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  loginForm;
  changePasswordForm;
  formIsNotValid = false;

  constructor(private cookieService: CookieService,
              private formBuilder: FormBuilder) {
    this.changePasswordForm = this.formBuilder.group({
      password: ['', Validators.compose([
        Validators.required,
        CustomValidators.patternValidator(/\d/, {hasNumber: true}),
        CustomValidators.patternValidator(/[A-Z]/, {hasCapitalCase: true}),
        CustomValidators.patternValidator(/[a-z]/, {hasSmallCase: true}),
        CustomValidators.patternValidator(specialCharacters, {hasSpecialCharacters: true}),
        Validators.minLength(8),
      ])],
      currentPassword: ['', Validators.required],
      passwordConfirm: ['', Validators.required]
    });
  }

  @Output() changeAction = new EventEmitter();

  ngOnInit(): void {
  }
  password(formGroup: FormGroup) {
    const {value: password} = formGroup.get('password');
    const {value: confirmPassword} = formGroup.get('confirmPassword');
    return password === confirmPassword ? null : {passwordNotMatch: true};
  }

  onSubmit(data) {
    if (data.password !== data.passwordConfirm) {
      this.formIsNotValid = true;
    } else {
      this.formIsNotValid = false;
      this.changeAction.emit({action: SAVE_CHANGES_ACTION, data: data});
    }
  }

  resetForm() {
    this.changePasswordForm.reset();
    this.formIsNotValid = false;
    this.changeAction.emit({action: CANCEL_ACTION});
  }
}
