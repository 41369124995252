import {Component, OnInit} from '@angular/core';
import {User} from '../../reusables/interfaces/user';
import {Title} from '@angular/platform-browser';
import {UsersService} from '../../reusables/services/users.service';
import {ActivatedRoute} from '@angular/router';
import {LogoutService} from '../../reusables/services/logout.service';
import {MY_EXPERIENCES_PAGE_TITLE} from '../../utils/actionsLabels/labels';
import {Experience} from '../../reusables/interfaces/experience';
import {AnalyticsVisitors} from '../../reusables/interfaces/analytics-visitors';
import {ExperiencesService} from '../../reusables/services/experiences.service';
import {AnalyticsDuration} from '../../reusables/interfaces/analytics-duration';
import {AnalyticsChart} from '../../reusables/interfaces/analyticsChart';
import {dayVariationTime, monthVariationTime, weekVariationTime, yearVariationTime} from '../../utils/global-constants/variationTimeConst';
import {Periods} from '../../reusables/interfaces/periods';
import {AuthGuard} from '../../reusables/_helpers';

@Component({
  selector: 'app-experiences-analytic-page',
  templateUrl: './experiences-analytic-page.component.html',
  styleUrls: ['./experiences-analytic-page.component.scss']
})
export class ExperiencesAnalyticPageComponent implements OnInit {

  doneForUserProfile = false;
  doneForAnalyticsDuration = false;
  doneForExperiences = false;
  doneForAnalyticsVisitors = false;
  doneForAnalyticsChart = false;
  user: User;
  experienceId = null;
  experiences: Experience [];
  analyticsChart: AnalyticsChart [];
  analyticsVisitors: AnalyticsVisitors;
  analyticsDuration: AnalyticsDuration;
  initialSelection;

  constructor(
    private titleService: Title,
    private usersService: UsersService,
    private authGuard: AuthGuard,
    private route: ActivatedRoute,
    private logoutService: LogoutService,
    private experienceService: ExperiencesService) {
    if (this.route.snapshot.params.id != null) {
      this.experienceId = this.route.snapshot.params.id;
    }
  }

  ngOnInit(): void {
    this.initValues();
    if (this.authGuard.canActivate) {
      this.titleService.setTitle(MY_EXPERIENCES_PAGE_TITLE);
      this.getProfile();
    }
  }

  initValues() {
    this.doneForUserProfile = false;
    this.doneForExperiences = false;
    this.doneForAnalyticsVisitors = false;
    this.doneForAnalyticsDuration = false;
    this.doneForAnalyticsChart = false;
    this.experiences = [];
    this.analyticsChart = [];
  }

  getUserExperiences(id) {
      this.usersService.getUserExperiences(id).subscribe((data: any[]) => {
        this.experiences = data;
        this.doneForExperiences = true;
        this.initiateSelection();
      });
  }

  onSelectionChanged(experienceId: number) {
    // tslint:disable-next-line:triple-equals
    if (experienceId == 0) {
      this.experienceId = null;
    } else {
      this.experienceId = experienceId;
    }
    this.getVisitorsAnalytics();
    this.getDurationAnalytics();
  }

  initiateSelection() {
    if (this.experienceId == null) {
      this.initialSelection = 0;
    } else {
      this.initialSelection = this.experienceId;
    }
  }

  getProfile() {
      this.usersService.getUserProfile().subscribe((data: User) => {
        this.user = data;
        this.getUserExperiences(this.user.id);
        this.getVisitorsAnalytics();
        this.getDurationAnalytics();
        this.getChartAnalytics('2020-05-01T00:00:00+00:00', '2020-05-31T23:59:59+00:00', Periods.MONTHLY);
        this.titleService.setTitle(MY_EXPERIENCES_PAGE_TITLE);
        this.doneForUserProfile = true;
      });
  }

  logout(event) {
    this.logoutService.logout(event);
  }

  getVisitorsAnalytics() {
    if (this.experienceId == null) {
      this.experienceService.getAllExperiencesVisitorsAnalytics().subscribe((data: AnalyticsVisitors) => {
        this.analyticsVisitors = data;
        this.doneForAnalyticsVisitors = true;
      });
    } else {
      this.experienceService.getExperienceVisitorsAnalytics(this.experienceId).subscribe((data: AnalyticsVisitors) => {
        this.analyticsVisitors = data;
        this.doneForAnalyticsVisitors = true;
      });
    }
  }

  private getDurationAnalytics() {
    if (this.experienceId == null) {
      this.experienceService.getAllExperiencesDurationAnalytics().subscribe((data: AnalyticsDuration) => {
        this.analyticsDuration = data;
        this.doneForAnalyticsDuration = true;
      });
    } else {
      this.experienceService.getExperienceDurationAnalytics(this.experienceId).subscribe((data: AnalyticsDuration) => {
        this.analyticsDuration = data;
        this.doneForAnalyticsDuration = true;
      });
    }
  }

  private getChartAnalytics(from: string, to: string, type: string) {
    if (this.experienceId == null) {
      this.experienceService.getAllExperiencesChartAnalytics(from, to, type).subscribe((data: AnalyticsChart[]) => {
        this.analyticsChart = data;
        this.doneForAnalyticsChart = true;
      });
    } else {
      this.experienceService.getExperienceChartAnalytics(this.experienceId, from, to, type).subscribe((data: AnalyticsChart[]) => {
        this.analyticsChart = data;
        this.doneForAnalyticsChart = true;
      });
    }


  }

  updateChartData(data: any) {
    // todo : this function should be updated to call the getChartAnalytics function with the right params
    //  when the backend is ready
    switch (data.type) {
      case dayVariationTime:
        this.getChartAnalytics('2020-05-22T00:00:00+00:00', '2020-05-22T23:59:59+00:00', Periods.DAILY);
        break;
      case weekVariationTime:
        this.getChartAnalytics('2020-05-16T00:00:00+00:00', '2020-05-22T23:59:59+00:00', Periods.WEEKLY);
        break;
      case monthVariationTime:
        this.getChartAnalytics('2020-05-01T00:00:00+00:00', '2020-05-31T23:59:59+00:00', Periods.MONTHLY);
        break;
      case yearVariationTime:
        this.getChartAnalytics('2019-01-31T00:00:00+00:00', '2019-12-31T23:59:59+00:00', Periods.YEARLY);
        break;
    }
  }
}
