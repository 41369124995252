/**
 * This Labels are used on Editor :
 * First menu, Second Menu & Right Menu
 *
 * Each Label value is a Code in i18n/en.json file,
 * We use this labels in Html files but it must display the messages in i18n/en.json file
 * Example:
 *  export const MESSAGE = 'MESSAGE_CODE';
 *  in i18n/en.json file :  "MESSAGE_CODE": "Hello, this is my message! ",
 */
export const FILE = 'FILE';


/*****************Editor - first menu - Import Popup ********************/

export const MY_ASSETS_LIBRARY = 'MY_ASSETS_LIBRARY';
export const GLOBAL_LIBRARY = 'GLOBAL_LIBRARY';
export const  FORM_MY_COMPUTER = 'FORM_MY_COMPUTER';
// Marker
export const IMPORT_MARKER = 'IMPORT_MARKER';
export const EDIT_MARKER = 'EDIT_MARKER';
export const  YOU_DO_NOT_HAVE_SPACE_MARKER = 'YOU_DO_NOT_HAVE_SPACE_MARKER';
export const  YOU_DO_NOT_HAVE_ENOUGH_SPACE_MARKER = 'YOU_DO_NOT_HAVE_ENOUGH_SPACE_MARKER';
// Image
export const IMPORT_IMAGE = 'IMPORT_IMAGE';
export const EDIT_IMAGE = 'EDIT_IMAGE';
export const  NO_GLOBAL_ASSET_IMAGE = 'NO_GLOBAL_ASSET_IMAGE';
export const  YOU_HAVE_NOT_CREATE_ASSET_IMAGE = 'YOU_HAVE_NOT_CREATE_ASSET_IMAGE';
export const  PLEASE_IMPORT_FROM_COMPUTER_IMAGE = 'PLEASE_IMPORT_FROM_COMPUTER_IMAGE';
export const  YOU_DO_NOT_HAVE_SPACE_IMAGE = 'YOU_DO_NOT_HAVE_SPACE_IMAGE';
export const  YOU_DO_NOT_HAVE_ENOUGH_SPACE_IMAGE = 'YOU_DO_NOT_HAVE_ENOUGH_SPACE_IMAGE';
export const  OR_SELECT_ANOTHER_IMAGE = 'OR_SELECT_ANOTHER_IMAGE';
export const  INVALID_FILE_EXTENSION_IMAGE = 'INVALID_FILE_EXTENSION_IMAGE';
// objet3D
export const IMPORT_OBJECT_3D = 'IMPORT_OBJECT_3D';
export const EDIT_OBJECT_3D = 'EDIT_OBJECT_3D';
export const  NO_GLOBAL_ASSET_OBJECT_3D = 'NO_GLOBAL_ASSET_OBJECT_3D';
export const  YOU_HAVE_NOT_CREATE_ASSET_OBJECT_3D = 'YOU_HAVE_NOT_CREATE_ASSET_OBJECT_3D';
export const  PLEASE_IMPORT_FROM_COMPUTER_OBJECT_3D = 'PLEASE_IMPORT_FROM_COMPUTER_OBJECT_3D';
export const  YOU_DO_NOT_HAVE_SPACE_OBJECT_3D = 'YOU_DO_NOT_HAVE_SPACE_OBJECT_3D';
export const  YOU_DO_NOT_HAVE_ENOUGH_SPACE_OBJECT_3D = 'YOU_DO_NOT_HAVE_ENOUGH_SPACE_OBJECT_3D';
export const  OR_SELECT_ANOTHER_OBJECT_3D = 'OR_SELECT_ANOTHER_OBJECT_3D';
export const  INVALID_FILE_EXTENSION_OBJECT_3D = 'INVALID_FILE_EXTENSION_OBJECT_3D';
export const  MESSAGE_EXTENSION_OBJECT_3D = 'MESSAGE_EXTENSION_OBJECT_3D';
// Audio
export const IMPORT_AUDIO = 'IMPORT_AUDIO';
export const EDIT_AUDIO = 'EDIT_AUDIO';
export const  NO_GLOBAL_ASSET_AUDIO = 'NO_GLOBAL_ASSET_AUDIO';
export const  YOU_HAVE_NOT_CREATE_ASSET_AUDIO = 'YOU_HAVE_NOT_CREATE_ASSET_AUDIO';
export const  PLEASE_IMPORT_FROM_COMPUTER_AUDIO = 'PLEASE_IMPORT_FROM_COMPUTER_AUDIO';
export const  YOU_DO_NOT_HAVE_SPACE_AUDIO = 'YOU_DO_NOT_HAVE_SPACE_AUDIO';
export const  YOU_DO_NOT_HAVE_ENOUGH_SPACE_AUDIO = 'YOU_DO_NOT_HAVE_ENOUGH_SPACE_AUDIO';
export const  OR_SELECT_ANOTHER_AUDIO = 'OR_SELECT_ANOTHER_AUDIO';
export const  INVALID_FILE_EXTENSION_AUDIO = 'INVALID_FILE_EXTENSION_AUDIO';
export const  MESSAGE_EXTENSION_AUDIO = 'MESSAGE_EXTENSION_AUDIO';

// standard
export const  DELETE_FILES = 'DELETE_FILES';
export const  YOUR_ASSETS_LIBRARY = 'YOUR_ASSETS_LIBRARY';
export const  SUPPORTED_EXTENSIONS = 'SUPPORTED_EXTENSIONS';
export const  MAX_FILE_SIZE = 'MAX_FILE_SIZE';
export const  SELECT_FILE = 'SELECT_FILE';
export const  INVALID_FILE_SIZE = 'INVALID_FILE_SIZE';
export const  CREATING_ASSET = 'CREATING_ASSET';

