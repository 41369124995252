import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Experience} from "../../interfaces/experience";
import {EXPERIENCE_PATH} from "../../../utils/actionsLabels/pathLabels";
import {ExperienceFavorite} from "../../interfaces/ExperienceFavorite";
import {DELETE_EXPERIENCE_FROM_FAVORITES} from "../../../utils/actionsLabels/labels";

@Component({
  selector: 'app-listing-experience-favorite-item',
  templateUrl: './listing-experience-favorite-item.component.html',
  styleUrls: ['./listing-experience-favorite-item.component.scss']
})
export class ListingExperienceFavoriteItemComponent implements OnInit {

  @Input() data: ExperienceFavorite;
  @Input() disabledButton;
  customStyle = '';
  linkDetails = '';
  @Output() experienceAction = new EventEmitter();

  constructor() { }


  ngOnInit(): void {
      if (this.data.coverPicture !== '') {
        this.customStyle = 'background: url(' + this.data.coverPicture + ') center no-repeat';
      }
      this.linkDetails = EXPERIENCE_PATH + '/' + this.data.experience_id;
  }

  applyAction(id) {
    this.experienceAction.emit({data: DELETE_EXPERIENCE_FROM_FAVORITES, id:id});
  }

}

