<div class="tz-2">
  <div class="tz-2-com tz-2-main">
    <app-add-new-element *ngIf="doneForUserTemplates && userTemplates.length == 0"
                         [url]="createTemplate"
                         [source]="template"></app-add-new-element>
    <app-my-templates-section *ngIf="doneForUserTemplates && userTemplates.length != 0"
                              [templates]="userTemplates"
                              (deleteTemplateAction)="deleteTemplate($event)"
                              [disabledButton]="disabledButton"></app-my-templates-section>
  </div>
</div>
<app-confirm-dialog [confirmText]="deleteText"></app-confirm-dialog>

