import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UsersService} from '../../reusables/services/users.service';
import {Router} from '@angular/router';
import {COMING_SOON, SIGNUP_PAGE_TITLE} from '../../utils/actionsLabels/labels';
import {LOGIN_PATH} from '../../utils/actionsLabels/pathLabels';
import {NotificationService} from '../../reusables/services/notification.service';
import {Title} from '@angular/platform-browser';
import {CustomValidators} from './custom-validators';
import {emailForm} from '../../utils/global-constants/regExp';
import {auth} from 'firebase/app';
import {AngularFireAuth} from '@angular/fire/auth';
import {v4 as uuidv4} from 'uuid';
import {base64ToFile, ImageCroppedEvent} from 'ngx-image-cropper';
import {svgAsPngUri} from 'save-svg-as-png';
import {TranslateService} from "@ngx-translate/core";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class SignupComponent implements OnInit {
  signupForm: FormGroup;
  loginLink;
  formIsNotValid = false;
  captchaSolved = false;
  profileImage: File; // file sent on request
  profileImageFileName: string;
  profileIdenticon: string;
  croppedImage;
  fileCroppedImage: File; // big image
  uriIcon: string;
  disabled = false;
  isNotDesktop = false;

  constructor(private formBuilder: FormBuilder,
              // tslint:disable-next-line:no-shadowed-variable
              private auth: AngularFireAuth,
              private usersService: UsersService,
              private router: Router,
              private translate: TranslateService,
              private notificationService: NotificationService,
              private titleService: Title,
              private deviceService: DeviceDetectorService) {
    this.signupForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required,
        // tslint:disable-next-line:max-line-length
        Validators.pattern(emailForm)]],
      password: ['', Validators.compose([
        Validators.required, CustomValidators.patternValidator(/\d/, {hasNumber: true}),
        CustomValidators.patternValidator(/[A-Z]/, {hasCapitalCase: true}),
        CustomValidators.patternValidator(/[a-z]/, {hasSmallCase: true}),
        CustomValidators.patternValidator(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/, {hasSpecialCharacters: true}),
        Validators.minLength(8),
      ])],
      passwordConfirm: ['', [Validators.required]]
    }, {
      validator: this.MustMatchPasswords('password', 'passwordConfirm')
    });
  }

  MustMatchPasswords(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && matchingControl.errors?.required === true) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({mustMatchPasswords: true});
      } else {
        matchingControl.setErrors({mustMatchPasswords: false});
      }
    };
  }


  ngOnInit(): void {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    if (isMobile || isTablet) {
      this.isNotDesktop = true;
    }
    this.profileIdenticon = uuidv4().toString();
    // this.titleService.setTitle(SIGNUP_PAGE_TITLE);
    this.translate.get(SIGNUP_PAGE_TITLE).subscribe((res: string) => {
      this.titleService.setTitle(res);
    });
    this.loginLink = LOGIN_PATH;
    window.scrollTo(0, 0);
    this.signupForm.valueChanges.subscribe(() => {
    });
  }

  captchaResolved(captchaResponse: string) {
    this.captchaSolved = captchaResponse != null;
  }

  onSubmit(signupData) {
    this.disabled = true;
    if (this.profileImage == null) {
      const icon = document.getElementById('iconIdenticon');
      svgAsPngUri(icon, {scale: 5}).then((uri) => {
        this.uriIcon = uri;
        // generate blob from base64 data uri
        const blob = base64ToFile(this.uriIcon);
        // generate file object de type png from blob
        this.profileImage = new File([blob], 'profileImage.png', {type: 'image/png'});
        this.sendData(signupData);
      });
    } else {
      this.sendData(signupData);
    }
  }

  sendData(signupData) {
    // Process login data here
    if (signupData.password === signupData.passwordConfirm) {
      const formData = new FormData();
      formData.append('firstName', signupData.firstName);
      formData.append('lastName', signupData.lastName);
      formData.append('email', signupData.email);
      formData.append('password', signupData.password);
      formData.append('profilePictureFile', this.profileImage);
      this.usersService.signup(formData).subscribe((data) => {
        this.auth.signInWithEmailAndPassword(signupData.email, signupData.password).then(() =>
          auth().currentUser.sendEmailVerification().then(() => this.auth.signOut()));
        // TODO: translate messages
        this.notificationService.showSuccess('Please log into your account', 'Account created successfully');
        this.router.navigateByUrl(LOGIN_PATH).then();
      });
    } else {
      this.formIsNotValid = true;
    }
  }

  showMessage() {
    this.notificationService.showWarning(COMING_SOON, '');
  }

  // ********************************** CoverProfile image-cropper start ***********************//

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    fetch(this.croppedImage)
      .then(res => res.blob()).then(bl => {
      this.profileImage = new File([bl], this.fileCroppedImage.name);
    });
  }

  onCroppedImageChange(event: Event) {
    if ((event.target as HTMLInputElement).files.length > 0) {
      this.fileCroppedImage = (event.target as HTMLInputElement).files[0];
    }
  }

  refreshIdenticon() {
    this.profileIdenticon = uuidv4().toString();
    this.fileCroppedImage = null;
    this.profileImage = null;
    this.croppedImage = null;
  }
}
