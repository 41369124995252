import {Component, HostListener, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Text} from '../../reusables/model/text/text';
import * as BABYLON from 'babylonjs';
import * as SCENE from './scene.function';
import {ImageObj} from '../../reusables/model/image/image';
import {ThreeDObject} from '../../reusables/model/threeDObject/three-dobject';
import {Video} from '../../reusables/model/video/video';
import {ErrorPopupComponent} from '../../reusables/components/popups/error-popup/error-popup.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {AudioObj} from '../../reusables/model/audio/audio';
import {Marker} from '../../reusables/model/marker/marker';
import {DeviceDetectorService} from 'ngx-device-detector';
import {YesNoDialogComponent} from '../../reusables/components/popups/yes-no-dialog/yes-no-dialog.component';
import {
  ASK_FOR_CONFIRM_DELETE_ACTION,
  ASK_FOR_CONFIRM_PUBLISH_ACTION,
  COOKIES_CONNECTED_USER_ID,
  EDITOR_PAGE_TITLE,
  ERROR_IMPORT_3D,
  ERROR_MESSAGE_IMPORT_3D,
  MESSAGE_OPEN_EDITOR,
  NO,
  SUCCESS_UPDATE_EXPERIENCE_CONTENT,
  SUCCESS_UPDATE_EXPERIENCE_TITLE,
  SUCCESS_UPDATE_TEMPLATE_CONTENT,
  SUCCESS_UPDATE_TEMPLATE_TITLE,
  WARNING_ZOOM,
  WARNING_ZOOM_TEXT,
  YES
} from '../../utils/actionsLabels/labels';
import {getCompPosition, getMarkerPosition} from './position.function';
import {CookieService} from 'ngx-cookie-service';
import {map} from 'rxjs/operators';
import {ExperiencesService} from '../../reusables/services/experiences.service';
import {ExperienceContentResponse} from '../../reusables/interfaces/experience-content';
import {NotificationService} from '../../reusables/services/notification.service';
import {getSecondMenu} from './second-menu';
import {Surface} from 'src/app/reusables/model/surface/surface';
import {
  MarkerTypeDialogComponent
} from '../../reusables/components/popups/marker-type-dialog/marker-type-dialog.component';
import {rotationFromUnityEditor} from '../../utils/functions/RotationFunction';
import {convertPosition, getComponentPosition, getMarkerPositionFromJson} from '../../utils/functions/PositionFunction';
import {LogoutService} from '../../reusables/services/logout.service';
import {CanComponentDeactivate} from '../../utils/functions/deactivateguard';
import {TokenHelperService} from '../../reusables/services/token-helper.service';
import {TemplateContentResponse} from '../../reusables/interfaces/template-content';

import {AssetObject} from '../../reusables/interfaces/assets-library';
import {
  DEFAULT_AUDIO_URL, DEFAULT_FILE_NAME,
  EditorElementType,
  IMPORT_ASSET_DIALOG_HEIGHT,
  IMPORT_ASSET_DIALOG_WIDTH,
  IMPORT_MARKER_DIALOG
} from '../../utils/global-constants/editor-const';
import {
  ImportAssetDialogComponent
} from '../../reusables/components/popups/import-asset-dialog/import-asset-dialog.component';
import {IMPORT_MARKER} from '../../utils/actionsLabels/editorLabels';
import {
  MarkerNameDialogComponent
} from '../../reusables/components/popups/marker-name-dialog/marker-name-dialog.component';
import {fileNameWithoutExtension} from "../../utils/functions/fileFuctions";
import {TranslateService} from "@ngx-translate/core";
import {GeoPosition} from 'src/app/reusables/model/geoPosition/geo-position';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss', '../../../assets/customCss/waiting-load.scss'],

})
export class EditorComponent implements OnInit, CanComponentDeactivate, OnDestroy {
  deviceInfo = null;
  scene: BABYLON.Scene;
  canOpenMarkerName = false;
  selectedMesh = null;
  selectedObj = null;
  selectedTrigger = null;
  triggerType = null;
  // pour le stockage des objets créés
  allObjectType = []; // dela forma [{},{}...] : markers , audio , mesh (^m niveau)
  objInScene = []; // de le forme [ { trigger:{}, componentList :[{...}, {...}, ]}, ....}]
  // pour nom du composant lors de la creation (text (1))
  lastButtonId = 0;
  lastTextId = 0;
  lastImageId = 0;
  lastVideoId = 0;
  lastObjectId = 0;
  lastAudioId = 0;
  publish = false; // user can save changes if E component & marker
  videoValid = true;
  urlsValid = true;
  showGizmo = true;
  secondMenu = null; // second menu json
  showSecondMenu = false; // (delete mesh,audio... => fermeture du second menu)
  slectedElementfirstMenu; // principal || sous element
  upload = false; // pour circle-upload
  isMenuToggled = false;
  principalSceneWidth; // used to add dynamic width to principal scene
  secondMenuPositionStyle = 'left: 130px';
  innerWidth;
  connectedUserIdCookies; // read connected user id
  currentId = '';
  contentId = '';
  Name = '';
  destroyComponent = false;
  dialogRef; // popup affichée
  templateOrExperience: string;
  userId: string;
  private principalCanvas: HTMLCanvasElement;
  private engine: BABYLON.Engine;
  private camera;
  private ground: BABYLON.Mesh;

  constructor(private ngZone: NgZone,
              private title: Title,
              public dialog: MatDialog,
              private router: Router,
              private deviceService: DeviceDetectorService,
              private cookieService: CookieService,
              private experiencesService: ExperiencesService,
              private route: ActivatedRoute,
              private translate: TranslateService,
              private logoutService: LogoutService,
              private notificationService: NotificationService,
              private tokenHelperService: TokenHelperService) {
  }

  ngOnDestroy(): void {
    this.destroyComponent = true;
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  ngOnInit() {
    this.userId = this.tokenHelperService.getTokenData().user_id;
    this.innerWidth = window.innerWidth;
    if (this.epicFunction() || this.innerWidth <= 992) {
      this.doNotAllowEditor();
    } else {
      this.connectedUserIdCookies = this.cookieService.get(COOKIES_CONNECTED_USER_ID);
      this.route.paramMap.pipe(
        map(params => {
            this.currentId = params.get('id');
            this.contentId = params.get('contentId');
          }
        )
      ).subscribe(() => {
        if (this.router.url.includes('templates')) {
          this.templateOrExperience = 'template';
        }
        if (this.router.url.includes('experiences')) {
          this.templateOrExperience = 'experience';
        }
        // // check the permission before opening the editor,
        // // if the user has permission to see and update the content of the experience, we open the editor
        // // else error page appears
        // const formData = new FormData();
        // formData.append('content', '');
        // switch (this.templateOrExperience) {
        //   case 'template':
        //     this.experiencesService.editTemplateContent(this.currentId, this.contentId, formData).subscribe(() => {
        //     });
        //     break;
        //   case 'experience':
        //     this.experiencesService.editExperienceContent(this.currentId, this.contentId, formData).subscribe(() => {
        //     });
        //     break;
        // }
        // create scene
        this.createScene();
        this.addMouseEventScene();
        // this.title.setTitle(EDITOR_PAGE_TITLE);
        this.translate.get(EDITOR_PAGE_TITLE).subscribe((res: string) => {
          this.title.setTitle(res);
        });
      });
      // appears content

      this.getContent();
    }

  }

  /***** save before go to another page ******/
  confirm() {
    return (this.publish);
  }

  /***** check if logged in ******/
  loggedIn() {
    return (this.tokenHelperService.getConnectedUser());
  }

  /***** save before close window ******/
  @HostListener('window:beforeunload')
  onBeforeUnload() {
    return false;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 992) {
      this.doNotAllowEditor();
    }
  }

  // disable zoom + -
  @HostListener('document:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    const keyCodes = [61, 107, 173, 109, 187, 189];
    if (event.ctrlKey === true && (keyCodes.indexOf(event.which) !== -1)) {
      event.preventDefault();
      this.notificationService.showWarning(WARNING_ZOOM_TEXT, WARNING_ZOOM);
    }
  }

  createScene() {
    this.principalCanvas = document.getElementById('principalCanvas') as HTMLCanvasElement;
    this.engine = new BABYLON.Engine(this.principalCanvas, true);
    this.scene = new BABYLON.Scene(this.engine);
    this.camera = SCENE.addCamera(this.principalCanvas, this.scene, 'principalCamera');
    this.camera.buttons = [0, 1, 2];
    this.camera.angularSpeed = 0.10;
    SCENE.addlight(this.scene);
    this.scene.clearColor = new BABYLON.Color4(0.5, 0.5, 0.5);
    SCENE.addGround(this.scene);
    SCENE.animate(this.scene, this.engine, this.ngZone);
    // disabled zoom
    SCENE.DisabledZoom(this.scene, this.principalCanvas, this.camera);
  }

  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    if (isMobile || isTablet) {
      return true;
    }
  }

  doNotAllowEditor() {
    const myUrl = '/information?';
    this.router.navigateByUrl(myUrl).then();
  }

  hideAllSubMenus(menuStatus) {
    if (menuStatus) {
      this.showSecondMenu = false;
    }
  }

  resizeMenus(status) {
    this.isMenuToggled = status;
    if (status) {
      this.secondMenuPositionStyle = 'left: 64px';
    } else {
      this.secondMenuPositionStyle = 'left: 130px';
    }
    this.hideAllSubMenus(status);
  }

  activeAudio() { // pour prob de l'affichage du babylonUnmuteIconBtn(Audio) après F5/ back
    BABYLON.Engine.audioEngine.useCustomUnlockedButton = true;
    BABYLON.Engine.audioEngine.unlock();
  }

  // Create objs from database " get experience content "
  getContent() {
    switch (this.templateOrExperience) {
      case 'template':
        this.experiencesService.getTemplateContent(this.currentId).subscribe((data: TemplateContentResponse) => {
          this.Name = data.template_name;
          this.createSceneContent(data);
        });
        break;
      case 'experience':
        this.experiencesService.getExperienceContent(this.currentId).subscribe((data: ExperienceContentResponse) => {
          this.Name = data.experience_name;
          this.createSceneContent(data);
        });
        break;
    }
  }

  createSceneContent(data) {
    /************** Create components *************************/
    const recreateComponent = (comp, trigger, newComponent) => {
      convertPosition(trigger, comp.position);
      comp.rotation = rotationFromUnityEditor(comp.rotation, comp.type);
      return newComponent(comp, true);
    };
    const recreateComponents = (comp, elt, trigger) => {
      let component;
      switch (comp.type) {
        case 'text':
          component = recreateComponent(comp, trigger, (obj, recreate) => new Text(obj, recreate));
          break;
        case EditorElementType.image.toString():
          component = recreateComponent(comp, trigger, (obj, recreate) => new ImageObj(obj, recreate));
          break;
        case '3dmodel':
          component = recreateComponent(comp, trigger, (obj, recreate) => new ThreeDObject(obj, recreate));
          break;
        case 'video':
          component = recreateComponent(comp, trigger, (obj, recreate) => new Video(obj, recreate));
          break;
        case EditorElementType.audio.toString():
          component = new AudioObj(comp, true);
          component.uniqueId = this.lastAudioId;
          this.lastAudioId++;
          break;
      }
      component.recreate(this.scene);
      const wait = () => {
        setTimeout(() => {
          if (!component.uniqueId) {
            wait();
          } else {
            elt.componentList.push(component);
            this.allObjectType.push(component);
          }
        }, 50);
      };
      wait();
    };
    /************* Wait upload: upload dots*********************/
    const url = this.router.url;
    let countElement = 0;
    let i = 0;
    const waitUpload = () => {
      i = i + 1;
      setTimeout(() => {
        if (this.allObjectType.length === countElement || url !== this.router.url || i > 2000) {
          // si l'url change les dots doivent disparaitres
          this.upload = false;
        } else {
          waitUpload();
        }
      }, 1000);
    };
    /********************* create meshes & objects *******************/
    if (data && data.content && data.content.triggerType) {
      this.upload = true;
      this.triggerType = data.content.triggerType;
      // --------------------- trigger = marker -----------------//
      if (data.content.markers && data.content.markers.length !== 0) {
        data.content.markers.forEach((marker) => {
          countElement = countElement + 1;
          const elt = {trigger: null, componentList: []};
          /*** marker ***/
          const newMarker = new Marker(marker, true, null, getMarkerPositionFromJson(marker));
          newMarker.recreate(this.scene);
          this.allObjectType.push(newMarker);
          elt.trigger = newMarker;

          /*** components ***/
          if (marker.components && marker.components.length !== 0) {
            countElement = countElement + marker.components.length;
            marker.components.forEach((comp) => {
              recreateComponents(comp, elt, newMarker);
            });
          }
          this.objInScene.push(elt);

        });
      }
      // --------------------- trigger = surface -----------------//
      if (data.content.surface) {
        countElement = countElement + 1;
        const elt = {trigger: null, componentList: []};
        /*** surface ***/
        const newSurface = new Surface();
        newSurface.newMarkerObjFromContent(data.content.surface.name);
        newSurface.recreate(this.scene);
        elt.trigger = newSurface;
        /*** components ***/
        if (data.content.surface.components && data.content.surface.components.length !== 0) {
          countElement = countElement + data.content.surface.components.length;
          data.content.surface.components.forEach((comp) => {
            recreateComponents(comp, elt, newSurface);
          });
        }
        this.objInScene.push(elt);
        const wait = () => {
          setTimeout(() => {
            if (!newSurface.uniqueId) {
              wait();
            } else {
              this.scene.meshes.forEach((mesh) => {
                if (mesh.uniqueId === newSurface.uniqueId) {
                  this.selectedMesh = mesh;
                }
              });
              this.afterAddTrigger(newSurface);
              this.selectedObj = newSurface;
              this.selectedTrigger = newSurface;
              this.secondMenu = getSecondMenu(this.selectedObj, this.selectedTrigger, this.selectedMesh, this.showSecondMenu);
              SCENE.zoomAfterAdd(this.selectedMesh, this.camera, this.scene, this.engine);
              this.allObjectType.push(newSurface);
            }
          }, 200);
        };
        wait();
      }
      // --------------------- trigger = geoPosition -----------------//
      if (data.content.geospatials && data.content.geospatials.length !== 0) {
        data.content.geospatials.forEach((geo) => {
          countElement = countElement + 1;
          const elt = {trigger: null, componentList: []};
          /*** geoPosition ***/
          const newGeoPosition = new GeoPosition(geo, true, null, geo.latitude, geo.longitude);
          newGeoPosition.recreate(this.scene);
          this.allObjectType.push(newGeoPosition);
          elt.trigger = newGeoPosition;
          /*** components ***/
          if (geo.components && geo.components.length !== 0) {
            countElement = countElement + geo.components.length;
            geo.components.forEach((comp) => {
              recreateComponents(comp, elt, newGeoPosition);
            });
          }
          this.objInScene.push(elt);
        });
      }
      waitUpload();
    } else {
      if (this.destroyComponent !== true) {
        this.openPopupTrigger(MESSAGE_OPEN_EDITOR);
      }
    }
  }

// ******************************************* trigger events *******************************************/
  afterAddTrigger(newTrigger) {
    this.selectedObj = newTrigger;
    this.selectedTrigger = newTrigger;
    this.secondMenu = getSecondMenu(this.selectedObj, this.selectedTrigger, this.selectedMesh, this.showSecondMenu);
    SCENE.zoomAfterAdd(this.selectedMesh, this.camera, this.scene, this.engine);

  }

  openPopupTrigger(msg) {
    this.dialogRef = this.dialog.open(MarkerTypeDialogComponent, {
      width: '600px',
      data: msg,
      disableClose: true,
      panelClass: 'myapp-no-padding-dialog'
    });
    this.dialogRef.afterClosed().subscribe(result1 => {
      switch (result1) {
        case EditorElementType.marker.toString():
          this.triggerType = EditorElementType.marker.toString();
          this.canOpenMarkerName = true;
          this.openPopupAddNewMarker();
          break;
        case EditorElementType.surface.toString():
          this.upload = true;
          this.triggerType = EditorElementType.surface.toString();
          this.addSurfaceScene();
          break;
        case EditorElementType.geo.toString():
          this.upload = true;
          this.triggerType = EditorElementType.geo.toString();
          this.addGeoPositionScene();
          break;
      }
    });
  }

  addGeoPositionScene() {
    this.showGizmo = true;
    const newGeoPosition = new GeoPosition();
    newGeoPosition.addGeoPositionFromObjScene(this.scene);
    this.upload = false;
    const wait = () => {
      setTimeout(() => {
        if (!newGeoPosition.uniqueId) {
          wait();
        } else {
          this.scene.meshes.forEach((mesh) => {
            if (mesh.uniqueId === newGeoPosition.uniqueId) {
              this.selectedMesh = mesh;
            }
          });
          const element = {trigger: newGeoPosition, componentList: []};
          this.objInScene.push(element);
          this.allObjectType.push(newGeoPosition);
          this.afterAddTrigger(newGeoPosition);
          this.upload = false;
          this.canPublish(); // activate publish
        }
      }, 500);
    };
    wait();
  }

  addNewGeoPositionScene() {
    this.addGeoPositionScene();
    // zoom on selected geo and hide others
    const geoPositionListToHide = this.allObjectType.filter(obj => obj.type === EditorElementType.geo.toString());
    geoPositionListToHide.forEach(element => this.onHideTrigger(element));
  }

  addSurfaceScene() {
    this.showGizmo = true;
    const newSurface = new Surface();
    newSurface.addSurfaceFromObjScene(this.scene);
    const wait = () => {
      setTimeout(() => {
        if (!newSurface.uniqueId) {
          wait();
        } else {
          this.scene.meshes.forEach((mesh) => {
            if (mesh.uniqueId === newSurface.uniqueId) {
              this.selectedMesh = mesh;
            }
          });
          const element = {trigger: newSurface, componentList: []};
          this.objInScene.push(element);
          this.allObjectType.push(newSurface);
          this.canPublish(); // activate publish
          this.afterAddTrigger(newSurface);
          this.upload = false;
        }
      }, 500);
    };
    wait();
  }

// ******************************************* trigger end events *******************************************/

// ******************************************* first menu events *******************************************/
  // ******************************************* marker *******************************************/

  openPopupAddNewMarker() {
    // if we still in the editor (this component not destroyed)
    if (this.destroyComponent !== true) {
      // --------------------Open popup Import marker-----------------
      this.dialogRef = this.dialog.open(ImportAssetDialogComponent, {
        width: IMPORT_ASSET_DIALOG_WIDTH,
        height: IMPORT_ASSET_DIALOG_HEIGHT,
        data: {
          title: IMPORT_MARKER,
          info: IMPORT_MARKER_DIALOG,
          userId: this.userId
        }
      });
      // ---------------------close popup Import marker----------------
      this.dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // _______Open popup marker name______
          this.dialogRef = this.dialog.open(MarkerNameDialogComponent, {
            width: '500px',
            data: [this.canOpenMarkerName]
          });
          // _______Open popup marker name______
          this.dialogRef.afterClosed().subscribe(name => {
            this.canOpenMarkerName = false;
            if (name) {
              this.upload = true;
              this.addNewMarkerScene({
                name,
                asset: result,
              });
            }
          });
          // _________________________________________
        }
      });
      // --------------------------------------------------------------
    }
  }

  addNewMarkerScene(data) {
    this.showGizmo = true;
    const newMarker = new Marker(null, null, data.name, getMarkerPosition(this.objInScene), data.asset.assetObjectUrl, data.asset.fileName);
    newMarker.addMarkerFromObjScene(this.scene);
    const wait = () => {
      setTimeout(() => {
        if (!newMarker.uniqueId) {
          wait();
        } else {
          this.scene.meshes.forEach((mesh) => {
            if (mesh.uniqueId === newMarker.uniqueId) {
              this.selectedMesh = mesh;
            }
          });
          const element = {trigger: newMarker, componentList: []};
          this.objInScene.push(element);
          this.allObjectType.push(newMarker);
          this.afterAddTrigger(newMarker);
          this.upload = false;
          this.canPublish(); // activate publish
        }
      }, 500);
    };
    wait();
  }

  // *******************************************End marker *******************************************/

  // *******************************************Publish *******************************************/
  /****** first menu : publish button enabled or disabled *****/
  canPublish() {
    this.publish = this.objInScene.length !== 0;
  }
  videoUriValid(event) {
    this.videoValid = event;
  }
  initializeMenu() {
    this.videoValid = true;
    this.urlsValid = true;
  }
  urlValid(event) {
    this.urlsValid = event;
  }

  /****** first menu : publish button click event *****/
  onPublish() {
    // open popup confirm publish action
    this.dialogRef = new MatDialogConfig();
    this.dialogRef.disableClose = false;
    this.dialogRef.autoFocus = true;
    this.dialogRef.panelClass = 'my-dialog';
    this.dialogRef.width = '500px';
    this.dialogRef.data = {msg: ASK_FOR_CONFIRM_PUBLISH_ACTION, icon: 'fas fa-cloud-upload-alt', yes: YES, no: NO};
    this.dialogRef = this.dialog.open(YesNoDialogComponent, this.dialogRef);
    this.dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.save();
      }
      this.slectedElementfirstMenu = null;

    });
  }

  save() {
    // TODO: The ws post experience must return content id
    const content = JSON.stringify(this.getJsonOutput(), null, 2);
    const formData = new FormData();
    formData.append('content', content);
    switch (this.templateOrExperience) {
      case 'template':
        this.experiencesService.editTemplateContent(this.currentId, this.contentId, formData).subscribe(() => {
          this.notificationService.showSuccess(SUCCESS_UPDATE_TEMPLATE_CONTENT, SUCCESS_UPDATE_TEMPLATE_TITLE);
          this.publish = false;
        }, (error => {
          this.slectedElementfirstMenu = null;
        }));
        break;
      case 'experience':
        this.experiencesService.editExperienceContent(this.currentId, this.contentId, formData).subscribe(() => {
          this.notificationService.showSuccess(SUCCESS_UPDATE_EXPERIENCE_CONTENT, SUCCESS_UPDATE_EXPERIENCE_TITLE);
          this.publish = false;
        }, (error => {
          this.slectedElementfirstMenu = null;
        }));
        break;
    }
  }

  /****** json output ********/
  // TODO add attributes triggerType & surface
  // JSON de la forme {
  //       experienceName: '',
  //       triggerType: "marker" ou "surface"
  //       markers: [
  //       { name:...., components: [{}, {}, ....]},
  //       { name:...., components: [{}, {}, ....]},
  //       ],
  //       surface: { name:...., components: [{}, {}, ....]}
  //     };

  getJsonOutput() {
    const ts = [];
    this.objInScene.forEach((el) => {
      const t = el.trigger.outputJson();
      const cs = [];
      el.componentList.forEach(comp => cs.push(comp.outputJson(el.trigger)));
      t.components = cs;
      ts.push(t);
    });
    switch (this.triggerType) {
      case EditorElementType.marker.toString():
        return {
          triggerType: this.triggerType,
          markers: ts
        };
      case EditorElementType.surface.toString():
        return {
          triggerType: this.triggerType,
          surface: ts[0]
        };
      case EditorElementType.geo.toString():
        return {
          triggerType: this.triggerType,
          geospatials: ts
        };
    }
  }

  // *******************************************End Publish *******************************************/

  // ********************************* first menu events - components   *********************************/

  addObjTrigger(obj) {
    if (this.selectedTrigger !== null) {
      const e = this.objInScene.find(
        element => element.trigger.uniqueId === this.selectedTrigger.uniqueId
      );
      e.componentList.push(obj);
    }
    this.allObjectType.push(obj);
    this.canPublish();
  }

  // générer la position d'un élément lors de la création
  objPosition() {
    const e = this.objInScene.find(
      element => element.trigger.uniqueId === this.selectedTrigger.uniqueId
    );
    return getCompPosition(e.componentList, e.trigger.position, this.triggerType);
  }

  // création du nouveau objet selon le type du bouton selectionné dans le menu principale
  addNew(event) {
    if (event && event.type) {
      switch (event.type) {
        case 'video':
          this.addNewVideoScene(event);
          break;
        case 'text':
          this.showGizmo = true;
          this.addNewTextScene();
          break;
        case EditorElementType.image.toString():
          this.showGizmo = true;
          this.addNewImageScene(event.asset);
          break;
        case EditorElementType.object3d.toString():
          this.showGizmo = true;
          this.addNew3DObjectScene(event.asset);
          break;
        case EditorElementType.audio.toString():
          this.addNewAudioScene(event.asset);
          break;
        case 'toggleMenu':
          this.resizeMenus(event.isMenuToggled);
          break;
        default:
          this.hideAllSubMenus(event.isMenuToggled);
      }
    }
  }


  // opérations après la création d'un composant
  afterAdd(newObject) {
    this.addObjTrigger(newObject);
    this.selectedObj = newObject;
    this.secondMenu = getSecondMenu(this.selectedObj, this.selectedTrigger, this.selectedMesh, this.showSecondMenu);
    SCENE.zoomAfterAdd(this.selectedMesh, this.camera, this.scene, this.engine);
    this.upload = false;
  }

  afterAddAudio(newAudio) {
    this.activeAudio();
    this.addObjTrigger(newAudio);
  }

  // attendre la création de la mesh dans la scéne( image/ video/ objet3D)
  waitCreateMesh(newComponent, lastId?) {
    setTimeout(() => {
      if (newComponent && newComponent.url && newComponent.url === 'ERROR') { // pour un objet 3D
        this.upload = false;
        this.dialogRef = this.dialog.open(ErrorPopupComponent, {
          width: '500px',
          data: [ERROR_IMPORT_3D, ERROR_MESSAGE_IMPORT_3D]
        });
        this.dialogRef.afterClosed().subscribe(() => {
          this.slectedElementfirstMenu = null;
        });
        return false;
      }
      if (!newComponent.uniqueId) {
        this.waitCreateMesh(newComponent, lastId);
      } else {
        this.scene.meshes.forEach((mesh) => {
          if (mesh.uniqueId === newComponent.uniqueId) {
            this.selectedMesh = mesh;
          }
        });
        this.afterAdd(newComponent);

        if (lastId !== null) {
          lastId++;
        }
      }
    }, 300);
  }

  // création d’un nouveau texte suite à un clique sur le bouton ‘add Text’dans le menu principale
  addNewTextScene() {
    let name = 'text';
    if (this.lastTextId !== 0) {
      name = name + ' (' + this.lastTextId.toString() + ')';
    }
    const newText = new Text(null, null, name, this.objPosition());
    this.selectedMesh = newText.addTextFromObjScene(this.scene);
    this.afterAdd(newText);
    this.lastTextId++;
  }

  // création d’image suite à un clique sur le bouton ‘add Image’dans le menu principale
  addNewImageScene(data: AssetObject) {
    this.upload = true;
    const newImage = new ImageObj(null, null, fileNameWithoutExtension(data.fileName), this.objPosition(), data.assetObjectUrl, data.fileName);
    newImage.addImageFromObjScene(this.scene);
    this.waitCreateMesh(newImage, this.lastImageId);
  }

  // création d’un 3D Obj suite à un clique sur le bouton ‘add 3do ’dans le menu principale
  addNew3DObjectScene(data: AssetObject) {
    this.upload = true;
    const newObj = new ThreeDObject(null, null, fileNameWithoutExtension(data.fileName), this.objPosition(), data.assetObjectUrl, data.assetObjectZipUrl, data.fileName);
    newObj.addThreeDObjectFromObjScene(this.scene);
    this.waitCreateMesh(newObj, this.lastObjectId);
  }

  // création d’un video suite à un clique sur le bouton ‘add video’dans le menu principale
  addNewVideoScene(event) {
    this.upload = true;
    let name = 'video';
    if (this.lastVideoId !== 0) {
      name = name + ' (' + this.lastVideoId.toString() + ')';
    }
    const newVideo = new Video(null, null, name, this.objPosition(), event.url);
    newVideo.addVideoFromObjScene(this.scene);
    this.waitCreateMesh(newVideo, this.lastVideoId);
  }

  // création d’un audio suite à un clique sur le bouton ‘add Audio’dans le menu principale
  addNewAudioScene(data: AssetObject) {
    this.upload = true;
    let exist = null;
    let newAudio;
    const wait = () => {
      setTimeout(() => {
        this.selectedMesh.play();
        if (this.selectedMesh.isPlaying) {
          this.secondMenu = getSecondMenu(this.selectedObj, this.selectedTrigger, this.selectedMesh, this.showSecondMenu);
          this.allObjectType.push(newAudio);
          this.lastAudioId++;
          this.afterAddAudio(newAudio);
          this.upload = false;
        } else {
          wait();
        }
      }, 500);
    };
    const waitCheckExistFile = () => {
      setTimeout(() => {
        if (exist === null) {
          waitCheckExistFile();
        } else {
          if (exist === false) {
            newAudio = new AudioObj(null, null, fileNameWithoutExtension(data.fileName), DEFAULT_AUDIO_URL, null, null, DEFAULT_FILE_NAME);

          } else {
            newAudio = new AudioObj(null, null, fileNameWithoutExtension(data.fileName), data.assetObjectUrl, null, null, data.fileName);

          }
          this.selectedMesh = newAudio.addAudioFromObjScene(this.scene);
          this.selectedObj = null;
          this.selectedObj = newAudio;
          wait();
        }
      }, 500);
    };

    let checkFile = false;
    const x = new XMLHttpRequest();
    x.timeout = 15000;
    x.open('GET', data.assetObjectUrl);
    x.onreadystatechange = (event) => {
      if (checkFile === false) {
        checkFile = true;
        exist = x.status !== 404;
      }
    };
    x.send();

    waitCheckExistFile();

  }

  // ********************************* End first menu events - components   ********************************/
// *******************************************end first menu events *******************************************/


// ******************************************* second menu  *******************************************/
  // get index of object(component or trigger) in this.objInScene
  getIndex(object) {
    if (object.type === EditorElementType.marker.toString() || object.type === EditorElementType.surface.toString() ||
      object.type === EditorElementType.geo.toString()) {
      const element = this.objInScene.find(elt => elt.trigger.uniqueId === object.uniqueId);
      return this.objInScene.indexOf(element);
    } else {
      let index = 0;
      let comp = null;
      while (!comp) {
        comp = this.objInScene[index].componentList.find(component => component === object);
        index++;
      }
      return index - 1;
    }
  }


  onDelete(object) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'my-dialog';
    dialogConfig.width = '500px';
    dialogConfig.data = {msg: ASK_FOR_CONFIRM_DELETE_ACTION, icon: 'fa fa-trash', yes: YES, no: NO};
    this.dialogRef = this.dialog.open(YesNoDialogComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.onDeleteConf(object);
      }
    });
  }

  onDeleteConf(object) {
    const index = this.getIndex(object);
    // maker, geoPosition ou component
    if (object.type === EditorElementType.marker.toString()) {
      while (this.objInScene[index].componentList.length !== 0 && this.objInScene[index].componentList[0]) {
        this.onDeleteConf(this.objInScene[index].componentList[0]);
      }
      this.objInScene.splice(index, 1);
    } else if (object.type === EditorElementType.geo.toString()) {
      while (this.objInScene[index].componentList.length !== 0 && this.objInScene[index].componentList[0]) {
        this.onDeleteConf(this.objInScene[index].componentList[0]);
      }
      this.objInScene.splice(index, 1);
    } else {
      this.objInScene[index].componentList.splice(this.objInScene[index].componentList.indexOf(object), 1);
    }
    // selected or not
    if (this.selectedObj === object) {
      if (this.selectedMesh) {
        this.selectedMesh.dispose();
        this.selectedMesh = null;
      }
      this.selectedTrigger = null;
      this.selectedObj = null;
      this.secondMenu = null;
    } else {
      let mesh;
      if (object instanceof AudioObj) {
        mesh = this.scene.getSoundByName(object.soundName);
      } else {
        mesh = this.scene.meshes.find(obj => obj.uniqueId === object.uniqueId);
      }
      if (mesh) {
        mesh.dispose();
      }
    }
    this.allObjectType.splice(this.allObjectType.indexOf(object), 1);
    this.slectedElementfirstMenu = null;
    this.canPublish();
  }

  onClone(object, posMarkNew?, posMark?) {
    this.canPublish();
    let newPosition;
    if (object.type === EditorElementType.marker.toString()) {
      this.upload = true;
      const allObjectTypeLength = this.allObjectType.length;
      const positionMarker = getMarkerPosition(this.objInScene);
      const newMarker = new Marker(object, null, object.name + '-copy-' + object.nbrCopy.toString(), positionMarker);
      object.nbrCopy++;
      newMarker.addMarkerFromObjScene(this.scene, true);
      /************* Wait copy component: upload dots*********************/
      const url = this.router.url;
      const waitUpload = () => {
        setTimeout(() => {
          const markerInScene = this.objInScene[this.getIndex(newMarker)];
          if (markerInScene.componentList && this.objInScene[this.getIndex(object)].componentList.length ===
            markerInScene.componentList.length || url !== this.router.url) { // si l'url change les dots doivent disparaitres
            this.afterAddTrigger(newMarker);
            this.upload = false;
          } else {
            waitUpload();
          }
        }, 1000);
      };
      /************* Wait copy marker: upload dots*********************/
      const wait = () => {
        setTimeout(() => {
          if (!newMarker.uniqueId) {
            wait();
          } else {
            this.selectedTrigger = newMarker;
            const element = {trigger: newMarker, componentList: []};
            this.objInScene.push(element);
            this.allObjectType.push(newMarker);
            // clone all components
            this.objInScene[this.getIndex(object)].componentList.forEach((component) => {
              this.onClone(component, positionMarker, object.position);
            });
            // selected element = the marker
            this.scene.meshes.forEach((mesh) => {
              if (mesh.uniqueId === newMarker.uniqueId) {
                this.selectedMesh = mesh;
              }
            });

          }
        }, 500);
      };
      wait();
      waitUpload();
      return true;
    }
    if (object instanceof AudioObj) {
      this.upload = true;
      object.nbrCopy++;
      this.lastAudioId++;
      const newAudio = new AudioObj(object, null, object.name + '-copy-' + object.nbrCopy.toString(), null, null, false);
      this.selectedMesh = newAudio.addAudioFromObjScene(this.scene);
      if (this.selectedObj === object) { // if clone from component
        this.selectedObj = null;
        this.selectedObj = newAudio;
        this.secondMenu = getSecondMenu(this.selectedObj, this.selectedTrigger, this.selectedMesh, this.showSecondMenu);
      }
      this.afterAddAudio(newAudio);
      if (!posMarkNew || !posMark) {
        setTimeout(() => {
          this.upload = false;
        }, 200);
      } // clone not from marker
      return true;
    }

    if (object.position) {
      this.upload = true;
      // clone component
      let newComponent;
      const create = () => {
        switch (object.type) {
          case 'text':
            newComponent = new Text(object, null, object.name + '-copy-' + object.nbrCopy.toString(), newPosition);
            newComponent.addTextFromObjScene(this.scene);
            break;
          case EditorElementType.image.toString():
            newComponent = new ImageObj(object, null, object.name + '-copy-' + object.nbrCopy.toString(), newPosition);
            newComponent.addImageFromObjScene(this.scene, true);
            break;
          case 'object':
            newComponent = new ThreeDObject(object, null, object.name + '-copy-' + object.nbrCopy.toString(), newPosition);
            newComponent.addThreeDObjectFromObjScene(this.scene, true);
            break;
          case 'video':
            newComponent = new Video(object, null, object.name + '-copy-' + object.nbrCopy.toString(), newPosition);
            newComponent.addVideoFromObjScene(this.scene, true);
            break;
        }
      };
      const waitCreateComponent = () => {
        setTimeout(() => {
          if (!newComponent.uniqueId) {
            waitCreateComponent();
          } else {
            this.addObjTrigger(newComponent);
          }
        }, 50);
      };
      // calculate position
      if (posMarkNew && posMark) { // clone from marker
        newPosition = getComponentPosition(object.position, posMarkNew, posMark); // position dépend de la position du marker
        create();
        waitCreateComponent();
      } else {
        newPosition = this.objPosition();
        create();
        this.waitCreateMesh(newComponent);
      }
      object.nbrCopy++;
      return true;
    }
  }

  onClose() {
    this.secondMenu = null;
  }

// *******************************************end second menu *******************************************/

// **********************************  select element (mesh,audio ...)   **********************************/
  addMouseEventScene() {
    this.scene.onPointerObservable.add((pointerInfo) => {
      switch (pointerInfo.type) {
        case BABYLON.PointerEventTypes.POINTERDOWN:
          if (pointerInfo.pickInfo.pickedMesh && pointerInfo.pickInfo.pickedMesh !== this.ground) {
            const obj = this.allObjectType.find(object => object.uniqueId === pointerInfo.pickInfo.pickedMesh.uniqueId);
            if (this.selectedObj !== obj) {
              this.selectedMesh = pointerInfo.pickInfo.pickedMesh;
              this.selectedObj = obj;
              const index = this.getIndex(obj);
              this.selectedTrigger = this.objInScene[index].trigger;
              this.secondMenu = getSecondMenu(this.selectedObj, this.selectedTrigger, this.selectedMesh, this.showSecondMenu);
              this.slectedElementfirstMenu = null;
              this.onShowHideGizmo(obj);
            }
          }
          break;
      }
    });
  }

// *******************************************end select element *******************************************/
// *******************************************right menu events *******************************************/
  onGeoPositionChange(geo) {
    // zoom on selected geo and hide others
    const geoPositionListToHide = this.allObjectType.filter(obj => obj.uniqueId !== geo.uniqueId && obj.type === EditorElementType.geo.toString());
    geoPositionListToHide.forEach(element => this.onHideTrigger(element));
    this.onShowTrigger(geo);
    this.zoomAfterAdd(geo);
  }

  // select trigger
  onTriggerChange(trigger) {
    if (trigger !== this.selectedObj) {
      this.slectedElementfirstMenu = null;
    }
    if (trigger.type == EditorElementType.geo.toString()) {
      // zoom on selected geo and hide others
      this.onGeoPositionChange(trigger);
    }
    this.selectedMesh = this.scene.meshes.find(mesh => mesh.uniqueId === trigger.uniqueId);
    this.selectedObj = trigger;
    this.selectedTrigger = trigger;
    this.secondMenu = getSecondMenu(this.selectedObj, this.selectedTrigger, this.selectedMesh, this.showSecondMenu);
    this.onShowHideGizmo(trigger);

  }

  // select component when trigger === ( marker || surface)
  onChangeComponent(component) {
    if (component !== this.selectedObj) {
      this.slectedElementfirstMenu = null;
    }
    const index = this.getIndex(component);
    this.selectedTrigger = this.objInScene[index].trigger;

    if (component instanceof AudioObj) {
      this.onAudioSelect(component);
    } else {
      this.selectedMesh = this.scene.meshes.find(mesh => mesh.uniqueId === component.uniqueId);
      this.selectedObj = component;
      this.onShowHideGizmo(component);
      this.secondMenu = getSecondMenu(this.selectedObj, this.selectedTrigger, this.selectedMesh, this.showSecondMenu);
    }
  }

  // select audio component in right menu
  onAudioSelect(audio) {
    this.selectedMesh = this.scene.getSoundByName(audio.soundName);
    this.selectedObj = audio;
    this.secondMenu = getSecondMenu(this.selectedObj, this.selectedTrigger, this.selectedMesh, this.showSecondMenu);
    if (this.selectedObj.isPlaying === false) {
      this.secondMenu.find(e => e.name === 'Actions').selected = -1;
    }
  }

  onShowTrigger(object) {
    /***** show marker when trigger ===  marker or geoPosition ******/
    const mesh = this.scene.meshes.find(obj => obj.uniqueId === object.uniqueId);
    object.visible = true;
    mesh.isVisible = true;
    if (object === this.selectedObj && this.selectedObj.type !== EditorElementType.surface.toString()) {
      this.onShowHideGizmo(object);
    }
    /******* show components when trigger === ( marker || surface || geoPosition)******/
    const index = this.getIndex(object);
    this.objInScene[index].componentList.forEach((component) => {
      if (component.triggerVisible === false) {
        component.triggerVisible = true;
        if (component.visible === false) {
          component.visible = true;
          if (!(component instanceof AudioObj)) {
            const comp = this.scene.meshes.find(obj => obj.uniqueId === component.uniqueId);
            comp.isVisible = true;
          }
          if (component === this.selectedObj) {
            this.onShowHideGizmo(component);
          }
        }
      }
    });
  }

  onHideTrigger(object) {
    /***** hide marker when trigger ===  marker ******/
    const mesh = this.scene.meshes.find(obj => obj.uniqueId === object.uniqueId);
    object.visible = false;
    mesh.isVisible = false;
    if (object === this.selectedObj) {
      this.onShowHideGizmo(object);
    }
    /******* hide components when trigger === ( marker || surface)******/
    const index = this.getIndex(object);
    this.objInScene[index].componentList.forEach((component) => {
      if (component.visible === true) {
        if (!(component instanceof AudioObj)) {
          this.onHideComponent(component);
        } else {
          component.visible = false;
        }
        component.triggerVisible = false;
      }
    });
  }

  onShowComponent(object) {
    /***** show component when trigger === ( marker || surface || geoPosition) ****/
    const mesh = this.scene.meshes.find(obj => obj.uniqueId === object.uniqueId);
    object.visible = true;
    mesh.isVisible = true;
    if (object === this.selectedObj) {
      this.onShowHideGizmo(object);
    }
    /***** show parent ****/
    const index = this.getIndex(object);
    const parent = this.objInScene[index].trigger;
    if (parent.visible === false) {
      const parentmesh = this.scene.meshes.find(obj => obj.uniqueId === parent.uniqueId);
      parent.visible = true;
      parentmesh.isVisible = true;
      if (parent === this.selectedObj) {
        this.onShowHideGizmo(parent);
      }
      const indexParent = this.getIndex(parent);
      this.objInScene[indexParent].componentList.forEach((component) => {
        if (component.triggerVisible === false) {
          component.triggerVisible = false;
        }
      });
    }

  }

  onHideComponent(object) {
    const mesh = this.scene.meshes.find(obj => obj.uniqueId === object.uniqueId);
    object.visible = false;
    mesh.isVisible = false;
    if (object === this.selectedObj) { // si l'objet est selectionné on doit eliminer gizmo
      this.onShowHideGizmo(object);
    }
  }

  // used in second-menu
  onShowHideGizmo(object) {
    this.showGizmo = object.visible === true;
  }

  // drag and drop components in right menu
  onDropComponent(event) {
    /*markerIndex: toNumbers(event.container.id)[0],
      componentIndex: event.currentIndex,
      component: comp*/
    if (this.selectedObj === event.component) {
      this.selectedTrigger = this.objInScene[event.markerIndex].trigger;
    }
    this.publish = true;
  }

  // zoom trigger or component after click on zoom buttom of the right menu
  zoomAfterAdd(event) {
    SCENE.zoomAfterAdd(event, this.camera, this.scene, this.engine);
  }

// *******************************************end right menu events *******************************************/

  logout(event) {
    this.logoutService.logout(event);
  }
}

