import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {
  WRITE_EXPERIENCE_FAVORITE_ACTION,
  WRITE_TEMPLATE_FAVORITE_ACTION
} from '../../../../utils/actionsLabels/labels';

@Component({
  selector: 'app-details-about',
  templateUrl: './details-about.component.html',
  styleUrls: ['./details-about.component.scss']
})
export class DetailsAboutComponent implements OnInit {
  path = window.location.href;
  constructor(private deviceService: DeviceDetectorService) {
  }

  @Input() etatExperienceFavorite: any;
  @Input() etatTemplateFavorite:any;
  @Input() details: any;
  @Input() source;
  @Input() disabledButtonFavorite: boolean;
  typeMobile = false;
  url = '';
  title = '';
  description= '';
  image='';
  @Output() useTemplate = new EventEmitter();
  @Output() shareOnFacebook = new EventEmitter();
  @Output() shareOnTwitter = new EventEmitter();
  @Output() shareOnLinkedin = new EventEmitter();
  @Output() request = new EventEmitter();



  ngOnInit(): void {
    this.url = window.location.href;
    this.title = this.details.title;
    this.description = this.details.description;
    this.image = this.details.coverPicture;
    this.epicFunction();
  }
  epicFunction() {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    if (isMobile || isTablet) {
      this.typeMobile = true;
    }
  }
  navigate() {
    window.location.href = window.location.href + '/play';
  }
  UseTemplate(id) {
    this.useTemplate.emit(id);
  }

  shareFacebook(){
    this.shareOnFacebook.emit(window.location.href);
  }
  shareTwitter() {
    this.shareOnTwitter.emit(window.location.href);
  }
  shareLinkedin() {
    this.shareOnLinkedin.emit(window.location.href);
  }
  ActionExperienceFavorite(){
    this.request.emit({type: WRITE_EXPERIENCE_FAVORITE_ACTION, data: this.details.id});
  }
  ActionTemplateFavorite(){
    this.request.emit({type: WRITE_TEMPLATE_FAVORITE_ACTION, data: this.details.id});
  }

}
