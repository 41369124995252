import {
  SCROLL_TO_ABOUT,
  SCROLL_TO_GALLERY,
  SCROLL_TO_MY_REVIEW,
  SCROLL_TO_USER_REVIEW,
  SCROLL_TO_WRITE_REVIEW
} from '../actionsLabels/labels';

export const menuReview = [
  {
    scrollAction: SCROLL_TO_ABOUT,
    menuLabel: 'About',
    icon: 'fa-user'
  },
  {
    scrollAction: SCROLL_TO_GALLERY,
    menuLabel: 'Gallery',
    icon: 'fa-photo'
  },
  {
    scrollAction: SCROLL_TO_USER_REVIEW,
    menuLabel: 'Rating and reviews',
    icon: 'fa-star-half-o'
  }
];
export const menuReviewTemplate = [
  {
    scrollAction: SCROLL_TO_ABOUT,
    menuLabel: 'About',
    icon: 'fa-user'
  },
  {
    scrollAction: SCROLL_TO_GALLERY,
    menuLabel: 'Gallery',
    icon: 'fa-photo'
  }
];
export const menuBeforeAddingReview = [
  {
    scrollAction: SCROLL_TO_ABOUT,
    menuLabel: 'About',
    icon: 'fa-user'
  },
  {
    scrollAction: SCROLL_TO_GALLERY,
    menuLabel: 'Gallery',
    icon: 'fa-photo'
  },
  {
    scrollAction: SCROLL_TO_WRITE_REVIEW,
    menuLabel: 'Write Review',
    icon: 'fa-edit'
  },
  {
    scrollAction: SCROLL_TO_USER_REVIEW,
    menuLabel: 'Rating and reviews',
    icon: 'fa-star-half-o'
  }
];
export const menuAfterAddingReview = [
  {
    scrollAction: SCROLL_TO_ABOUT,
    menuLabel: 'About',
    icon: 'fa-user'
  },
  {
    scrollAction: SCROLL_TO_GALLERY,
    menuLabel: 'Gallery',
    icon: 'fa-photo'
  },
  {
    scrollAction: SCROLL_TO_MY_REVIEW,
    menuLabel: 'My Review',
    icon: 'fa-edit'
  },
  {
    scrollAction: SCROLL_TO_USER_REVIEW,
    menuLabel: 'Rating and reviews',
    icon: 'fa-star-half-o'
  }
];
export const menuOfExperienceOwner = [
  {
    scrollAction: SCROLL_TO_ABOUT,
    menuLabel: 'About',
    icon: 'fa-user'
  },
  {
    scrollAction: SCROLL_TO_GALLERY,
    menuLabel: 'Gallery',
    icon: 'fa-photo'
  },
  {
    scrollAction: SCROLL_TO_USER_REVIEW,
    menuLabel: 'Rating and reviews',
    icon: 'fa-star-half-o'
  }
];
