<div class="pglist-p3 pglist-bg pglist-p-com">
  <div class="pg-list-user-pro">
    <img *ngIf="data.userCoverPicture" src="{{data.userCoverPicture}}"/>
  </div>
  <div class="profile-creator-picture">
    <img *ngIf="data.userProfilePicture && data.userProfilePicture != ''" src="{{data.userProfilePicture}}">
  </div>
  <div class="list-pg-inn-sp">
    <div class="list-pg-upro">
      <h5>{{data.userDisplayName}}</h5>
      <p>{{'Member since '| translate}} {{data.userCreatedDate | date}}</p>
<!--      <a (click)="comingSoonAction()"><span-->
<!--        class="waves-effect waves-light btn-large full-btn list-pg-btn">{{'CONTACT-CREATOR' | translate}}</span></a>-->
    </div>
  </div>
</div>
