<div class="col-md-12 ">
  <h1 class="col-md-6" mat-dialog-title>{{"Select an experience" | translate}} </h1>
  <div class="input-field col-md-6">

<!--    <input type="text"-->
<!--           id="searchExperience"-->
<!--           class="autocomplete" placeholder="{{ 'Search_Experiences' | translate }}">-->

  </div>
</div>

<form  [formGroup]="form"  >

  <!----------------------------------  experiences list   ------------------------->
  <div class="row experieces-list">
    <div class="db-list-com tz-db-table">
      <br/>
      <div class="row span-none">
        <a *ngFor="let item of data" (click)="onSelect(item)">
          <app-experience-grid [selectedData]="form.controls.exp.value" [data]="item" [disabled]="true"></app-experience-grid>
        </a>

      </div>
    </div>

  </div>
</form>
  <!----------------------------------  buttons   ------------------------->
    <div class="row actions-popup ">
      <button type="submit" class=" button  waves-effect waves-light btn-large save-popup-style"
              (click)="onAdd()" [disabled]="form.invalid "
              [ngClass]="{disabled: form.invalid}"
      >{{'Add' | translate}}</button>
      <button class="  button waves-effect waves-light btn-large"
              (click)="cancel();">{{'Cancel' | translate}}</button>
    </div>





