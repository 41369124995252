import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {TemplateFavorite} from "../../../interfaces/TemplateFavorite";
import { DELETE_TEMPLATE_FROM_FAVORITES} from "../../../../utils/actionsLabels/labels";

@Component({
  selector: 'app-my-templates-favorites',
  templateUrl: './my-templates-favorites.component.html',
  styleUrls: ['./my-templates-favorites.component.scss']
})
export class MyTemplatesFavoritesComponent implements OnInit {
  @Input() listTemplates: TemplateFavorite[];
  @Input() Template: TemplateFavorite;
  @Input() noFavoritesTemplates: boolean;
  @Input() disabledButtonTemp: boolean;
  @Input() carouselOptions: any;
  @Output() templateAction = new EventEmitter();

  constructor(public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  TemplateFavoriteAction(id,i) {
    this.templateAction.emit({data: DELETE_TEMPLATE_FROM_FAVORITES, id:id, i:i});
  }

}

