import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {Group} from '../../../interfaces/group';
import {
  JOIN_GROUP_MESSAGE_CONTENT,
  JOIN_GROUP_MESSAGE_TITLE,
  LEAVE_GROUP_MESSAGE_CONTENT,
  LEAVE_GROUP_MESSAGE_TITLE,
  WRITE_GROUP_FAVORITE_ACTION
} from '../../../../utils/actionsLabels/labels';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {NotificationService} from '../../../services/notification.service';

@Component({
  selector: 'app-group-details-about',
  templateUrl: './group-details-about.component.html',
  styleUrls: ['./group-details-about.component.scss']
})
export class GroupDetailsAboutComponent implements OnInit {

  @Input() details: Group;
  @Input() doneMembers: boolean;
  @Input() isLogged: boolean;
  @Input() isMember: boolean;
  @Input() isCreator: boolean;
  @Input() section: any;
  @Input() disabledButtonFavorite: boolean;
  isMobileVersion = false;
  @Output() shareOnFacebook = new EventEmitter();
  @Output() shareOnTwitter = new EventEmitter();
  @Output() shareOnLinkedin = new EventEmitter();

  @Input() etatGroupFavorite:any;
  @Output() request = new EventEmitter();

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    if (innerWidth < 992) {
      this.isMobileVersion = true;
    }
  }

  leaveGroup() {
    this.notificationService.showSuccess(LEAVE_GROUP_MESSAGE_CONTENT, LEAVE_GROUP_MESSAGE_TITLE);
  }

  joinGroup() {
    if (!this.isLogged) {
      this.router.navigate(['/login']);
    } else {
      this.notificationService.showSuccess(JOIN_GROUP_MESSAGE_CONTENT, JOIN_GROUP_MESSAGE_TITLE);
    }
  }
  @HostListener('window:resize', ['$event'])

  onResize(event) {
    if (innerWidth <= 992) {
      this.isMobileVersion = true;
    }
  }

   shareFacebook(){
    this.shareOnFacebook.emit(window.location.href);
  }
  shareTwitter() {
    this.shareOnTwitter.emit(window.location.href);
  }
  shareLinkedin() {
    this.shareOnLinkedin.emit(window.location.href);
  }
  ActionGroupFavorite(){
    this.request.emit({type: WRITE_GROUP_FAVORITE_ACTION, data: this.details.id});
  }

}
