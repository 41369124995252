import {Component, HostListener, OnInit, ViewEncapsulation} from '@angular/core';
import {
  ASK_FOR_CONFIRM_DELETE_MEMBERS_ACTION,
  MANAGE_MEMBERS_GROUP_PAGE_TITLE,
  PAGE_TITLE_PREFIX_MANAGE_MEMBERS,
  SUCCESS_DELETE_MEMBERS_MESSAGE_TITLE,
  SUCCESS_INVITE_MEMBERS_TO_GROUP_MESSAGE_TITLE
} from '../../../../utils/actionsLabels/labels';
import {GROUPS_PATH} from '../../../../utils/actionsLabels/pathLabels';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpParams} from '@angular/common/http';
import {UsersService} from '../../../services/users.service';
import {TokenHelperService} from '../../../services/token-helper.service';
import {groupRoles, RoleGroup} from '../../../../utils/global-constants/groups-const';
import {ActivatedRoute, Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {GroupsService} from '../../../services/groups.service';
import {NotificationService} from '../../../services/notification.service';
import {Group} from '../../../interfaces/group';
import {ConfirmDialogService} from '../../confirm-dialog/confirm-dialog.service';
import {CREATOR_GROUP, IS_MEMBERS} from '../../../../utils/queryParams/groupsQueryParams';
import {Title} from '@angular/platform-browser';
import {MemberInGroup} from '../../../interfaces/Member';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-manage-members',
  templateUrl: './manage-members.component.html',
  styleUrls: ['./manage-members.component.scss', '../../../../../assets/customCss/waiting-load.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageMembersComponent implements OnInit {
  pageTitle = MANAGE_MEMBERS_GROUP_PAGE_TITLE;
  groupName = 'groupName';
  screenSizeChanged = false;
  groupId: string; // current group id
  userId: string; // current user id
  formManageMembers: FormGroup;
  groupRoles = groupRoles;
  // section add members
  allUsers: MemberInGroup[] = []; // list users not members
  addedMembers: MemberInGroup[] = [];
  members: MemberInGroup[] = [];
  groupMembers: any = [];
  selectedIds: string[];
  addMembers: any = [];
  showMyGroup = false;
  showUsers = false;
  showMembers = false;
  noMembers = false;
  membersToDelete: MemberInGroup[] = [];
  deletedMember: MemberInGroup[];

  constructor(private route: ActivatedRoute,
              private usersService: UsersService,
              private groupsService: GroupsService,
              private tokenHelperService: TokenHelperService,
              private notificationService: NotificationService,
              private titleService: Title,
              private translate: TranslateService,
              private router: Router,
              private confirmDialogService: ConfirmDialogService) {
  }

  ngOnInit(): void {
    // this.titleService.setTitle(PAGE_TITLE_PREFIX_MANAGE_MEMBERS);
    this.translate.get(PAGE_TITLE_PREFIX_MANAGE_MEMBERS).subscribe((res: string) => {
      this.titleService.setTitle(res);
    });
    this.formManageMembers = new FormGroup({
      addedMembers: new FormControl('', [Validators.required])
    });

    if (window.innerWidth <= 1200) {
      this.screenSizeChanged = true;
    }
    if (this.tokenHelperService.getConnectedUser()) {
      this.userId = this.tokenHelperService.getTokenData().user_id;
    }
    // get group
    this.route.paramMap.pipe(
      map(params => {
          this.groupId = params.get('id');
          this.groupName = params.get('name');
          this.getGroup();
        }
      )
    ).subscribe(() => {
    });
    // get users
    this.getUsersNotMembers();
  }

  getGroup() {
    this.groupsService.getGroupById(this.groupId).subscribe((data: Group) => {
      this.groupName = data.name;
      this.showMyGroup = true;
      // get members
      // this.titleService.setTitle(PAGE_TITLE_PREFIX_MANAGE_MEMBERS +  this.groupName);
      this.translate.get(PAGE_TITLE_PREFIX_MANAGE_MEMBERS).subscribe((res: string) => {
        this.titleService.setTitle(res +  this.groupName);
      });
      this.getMembers(data.creator);
    });
  }

  getUsersNotMembers() {
    let params = new HttpParams();
    params = params.append(IS_MEMBERS, '0');
    this.groupsService.getGroupMembers(this.groupId, params).subscribe((data: { list_members: any[], number_members_group: number }) => {
      this.allUsers = data.list_members;
      this.showUsers = true;
    });
  }

  getMembers(groupCreator) {
    let params = new HttpParams();
    params = params.append(CREATOR_GROUP, groupCreator);
    this.groupsService.getGroupMembers(this.groupId, params).subscribe((data: { list_members: MemberInGroup[],
      number_members_group: number }) => {
      this.members = data.list_members;
      if (data && data.list_members && data.list_members.length > 0) {
        this.showMembers = true;
      } else {
        this.noMembers = true;
      }
    });
  }


  onSubmit(data) {
    let addAndDelete = false;
    if (this.addedMembers.length !== 0 && this.membersToDelete.length !== 0) {
      addAndDelete = true;
      this.deleteMembers(addAndDelete);
    } else {
      if (this.addedMembers.length !== 0) {
      this.addMembersGroup();
      }
      if (this.membersToDelete.length !== 0) {
        this.deleteMembers(addAndDelete);
      }
    }
  }

  addMembersGroup() {
      this.addMembers = [];
       // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.addedMembers.length; i++) {
        const id = this.addedMembers[i].member_id;
        let roleMember;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < this.groupMembers.length; j++) {
          // look for the entry with a matching `code` value
          if (this.groupMembers[j].id === id) {
            // we found it
            roleMember = this.groupMembers[j].role;
            break;
          }
        }
        this.addMembers.push({id, role: roleMember});
      }
      this.groupsService.inviteGroupMembers(this.addMembers, this.groupId).subscribe(() => {
        this.notificationService.showSuccess('', SUCCESS_INVITE_MEMBERS_TO_GROUP_MESSAGE_TITLE);
        this.router.navigateByUrl(GROUPS_PATH + '/' + this.groupId).then();
      });
  }

  deleteMembers(addAndDelete) {
     const listMembersToDelete = [];
     // tslint:disable-next-line:prefer-for-of
     for (let i = 0; i < this.membersToDelete.length; i++) {
      listMembersToDelete.push({id: this.membersToDelete[i].member_id});
     }
     this.confirmDialogService.confirmThis(ASK_FOR_CONFIRM_DELETE_MEMBERS_ACTION +  '"?', () => {
      this.groupsService.deleteMembers(listMembersToDelete, this.groupId).subscribe(() => {
        this.notificationService.showSuccess('', SUCCESS_DELETE_MEMBERS_MESSAGE_TITLE);
        if (addAndDelete) {
          this.addMembersGroup();
        } else {
          this.router.navigateByUrl(GROUPS_PATH + '/' + this.groupId).then();
        }
      });
    }, () => {
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth <= 1200) {
      this.screenSizeChanged = true;
    } else {
      this.screenSizeChanged = false;
    }
  }

  /**
   * on change select members items, update members in bottom
   */
  onChange(event) {
    this.addedMembers = event;
    this.groupMembers = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.addedMembers.length; i++) {
      const item = this.addedMembers[i];
      this.groupMembers.push({id: item.member_id, role: RoleGroup.MEMBER});
    }
  }

  changeMemberRole(event, id) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.groupMembers.length; i++) {
      // look for the entry with a matching `code` value
      if (this.groupMembers[i].id === id) {
        // we found it
        this.groupMembers[i].role = event.value;
      }
    }
  }

  onSearch(event) {
    let membersList = '';
    if (event.added && event.added.length > 0) {
      membersList = this.selectedIds.toString();
    }
    this.groupsService.searchMembersLike(event.term, membersList).subscribe((data: MemberInGroup []) => {
      this.addedMembers = data;
    }, () => {
    });
  }

  /**
   * delete member from the list in bottom
   */
  deleteMemberFromList(item) {
    const aux = this.addedMembers.indexOf(item);
    this.addedMembers.splice(aux, 1);
    this.groupMembers.splice(this.groupMembers.indexOf(item));
    this.selectedIds = this.selectedIds.filter(id => id !== item.member_id);
  }

  cancelAddMembers() {
    window.history.back();
  }

  deleteMember(item: MemberInGroup) {
    this.membersToDelete.push(item);
  }

  cancelSelect(item: MemberInGroup) {
    this.membersToDelete = this.membersToDelete.filter(member => member !== item);
  }

  selectedToBeDeleted(deletedMember: MemberInGroup) {
    if (this.membersToDelete.length !== 0) {
      return this.membersToDelete.filter(member => member === deletedMember)[0];
    } else {
      return false;
    }
  }
}
