import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {COOKIES_CONNECTED_USER_ID_TOKEN} from '../../utils/actionsLabels/labels';
import {LOGIN_PATH} from '../../utils/actionsLabels/pathLabels';
import {NotificationService} from './notification.service';
import 'firebase/auth';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {TokenHelperService} from './token-helper.service';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private currentUserIdTokenSubject: BehaviorSubject<string>;
  public currentUserIdToken: Observable<string>;
  private refreshTokenTimeout;

  constructor(private http: HttpClient,
              private notificationService: NotificationService,
              private cookieService: CookieService,
              private tokenHelperService: TokenHelperService,
              private router: Router) {
    this.currentUserIdTokenSubject = new BehaviorSubject<string>(this.cookieService.get(COOKIES_CONNECTED_USER_ID_TOKEN));
    this.currentUserIdToken = this.currentUserIdTokenSubject.asObservable();
  }

  public get currentUserIdTokenValue(): string {
    return this.currentUserIdTokenSubject.value;
  }

  login(idToken) {
    this.cookieService.set(COOKIES_CONNECTED_USER_ID_TOKEN, idToken);
    this.currentUserIdTokenSubject.next(idToken);
    this.startRefreshTokenTimer();
  }

  logout(queryParams?) {
    this.cookieService.delete(COOKIES_CONNECTED_USER_ID_TOKEN);
    this.currentUserIdTokenSubject.next(null);
    this.stopRefreshTokenTimer();
    if (queryParams) {
      this.router.navigate([LOGIN_PATH], {queryParams}).then(); // queryParams pour stocker l'ancien url
    } else {
      this.router.navigateByUrl(LOGIN_PATH).then();
    }
  }

  refreshToken() {
    this.tokenHelperService.GetToken().then(idToken => {
      this.login(idToken);
    });

  }

  refreshTokenAfterError(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.tokenHelperService.GetToken().then(idToken => {
        this.login(idToken);
        resolve(idToken);
      });
    });
  }

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = this.tokenHelperService.getTokenData();
    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    // auto refresh the JWT token in the background (silent refresh) one minute before it expires
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
