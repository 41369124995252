<div>
  <div class="img-group-container" [ngStyle]="{'background': backgroundGroup}"></div>
  <div>
    <p><a [routerLink]="linkDetails" title="{{data.name}}">
      <h5 class="ellipsis-style">{{data.name}}</h5></a>
      <span class="groupType {{privacy.class}}">{{privacy.htmlValue}}</span></p>
    <p class="info-members" *ngIf="showMembers"><span>{{data.members}} {{"members" | translate}}</span></p>
  </div>
</div>
<p class="ellipsis-style-description">{{data.description}}</p>
<div class="actions">
  <div class="inline">
    <a [routerLink]="linkDetails"><span class="db-list-edit">{{"View" | translate}}</span></a>
  </div>
  <div class="inline" *ngIf="ownerActions">
    <a [routerLink]="linkEdit"><span class="db-list-edit">{{"Edit" | translate}}</span></a>
  </div>
  <div class="inline"  *ngIf="ownerActions" [class.isDisabled]="disabledButton">
    <a (click)="deleteGroupAction()"><span class="db-list-edit">{{"Delete" | translate}}</span></a>
  </div>
  <div class="inline" *ngIf="memberActions" [class.isDisabled]="disabledButton">
    <a (click)="leaveAction()"><span class="db-list-edit">{{"Leave" | translate}}</span></a>
  </div>
</div>

