import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import { DELETE_EXPERIENCE_FROM_FAVORITES} from "../../../../utils/actionsLabels/labels";
import {ExperienceFavorite} from "../../../interfaces/ExperienceFavorite";

@Component({
  selector: 'app-my-favorites',
  templateUrl: './my-favorites.component.html',
  styleUrls: ['./my-favorites.component.scss']
})
export class MyFavoritesComponent implements OnInit {

  constructor(public dialog: MatDialog) {
  }


  @Input() listExperiences: ExperienceFavorite[];
  @Input() noFavoritesExperiences: boolean;
  @Input() disabledButton: boolean;
  @Input() carouselOptions: any;
  @Output() experienceAction = new EventEmitter();

  ngOnInit(): void {
  }

  applyAction(id,i) {
    this.experienceAction.emit({data: DELETE_EXPERIENCE_FROM_FAVORITES, id:id, i:i});
  }

}
