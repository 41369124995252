<section>
  <div class="tz" *ngIf="user && user.userId !== ''">
    <div class="tz-l"  >
      <app-profile-picture [user]="user"></app-profile-picture>
      <app-account-menu [user]="user"></app-account-menu>
    </div>
    <router-outlet></router-outlet>
<!--    <app-notifications></app-notifications>-->
  </div>
</section>
<app-confirm-dialog [confirmText]="deleteText"></app-confirm-dialog>
