import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MemberInGroup} from '../../../interfaces/Member';
import * as $ from 'jquery';
import {COMING_SOON} from '../../../../utils/actionsLabels/labels';
import {NotificationService} from '../../../services/notification.service';
import {HttpParams} from '@angular/common/http';
import {GroupsService} from '../../../services/groups.service';
import {MatDialog} from '@angular/material/dialog';
import {GroupMembersDialogComponent} from '../../popups/group-members-dialog/group-members-dialog.component';

@Component({
  selector: 'app-group-details-members',
  templateUrl: './group-details-members.component.html',
  styleUrls: ['./group-details-members.component.scss']
})
export class GroupDetailsMembersComponent implements OnInit, OnDestroy  {


  showMembers = false;
  @Input() members: MemberInGroup [];

  allMembers = [];
  g = 1; // initial page in pagination of groups
  itemsPerPage = 3;
  innerWidth = window.innerWidth;
  minHeightContainer = '340px';
  @Input() membersNbr: number;
  @Input() section;
  @Input() buttonsDisabled;
  dialogRef;
  @Input() groupId: string;
  @Output() buttonDisabled = new EventEmitter;
  destroyComponent = false;

  constructor(private notificationService: NotificationService,
              public dialog: MatDialog,
              private groupsService: GroupsService
  ) { }

  ngOnDestroy(): void {
    this.destroyComponent = true;
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
  ngOnInit(): void {
    // if (this.members.length > this.itemsPerPage && $('.members-group-container')) {
    //   this.minHeightContainer = $('#getHeight').height + 'px';
    // }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.members.length > this.itemsPerPage) {
      this.minHeightContainer = ($('.members-group-container').height()) + 'px';

    } else {
      this.minHeightContainer = '340px';
    }
  }

  showNotification() {
    this.notificationService.showWarning(COMING_SOON, '');
  }


  onClick() {
    this.buttonsDisabled = true;
    this.buttonDisabled.emit(this.buttonsDisabled);
    this.allMembers = [];
    const params = new HttpParams();
    this.groupsService.getGroupMembers(this.groupId, params).subscribe((data: any) => {
      this.allMembers = data.list_members;

      if (this.destroyComponent !== true) { // prevent popup from appearing on other pages
        this.dialogRef = this.dialog.open(GroupMembersDialogComponent, {
          disableClose: false,
          autoFocus: true,
          panelClass: 'my-dialog',
          width: '1000px',
          maxHeight: '600px',
          data: this.allMembers,
        });
        this.dialogRef.afterClosed().subscribe(() => {
          this.buttonsDisabled = false;
          this.buttonDisabled.emit(this.buttonsDisabled);
        });
      }
    });
  }
}
