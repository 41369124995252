<section class="tz-register">
  <div class="log-in-pop">
    <div class="log-in-pop-left inDesktop">
      <h1>{{"HELLO" | translate}} !</h1>
      <br>
      <p>{{"DONT_HAVE_ACCOUNT" | translate}} <a [routerLink]="[signUpLink]">{{"CREATE_ACCOUNT" | translate}}
      </a> {{"MINUTE" | translate}} </p>
      <h4>{{"Log in with social media" | translate}}</h4>
      <br>
      <ul>
        <li><a (click)="showMessage()">
          <div class="social-icon"><img src="assets/images/facebook_logo.png" width="17" alt=""/></div>
          {{"Log in with Facebook" | translate}}</a></li>
        <li><a (click)="showMessage()">
          <div class="social-icon"><img src="assets/images/google_logo.png" width="16" alt=""/></div>
          {{"Log in with Google" | translate}}</a></li>
        <li><a (click)="showMessage()">
          <div class="social-icon"><i class="fa fa-twitter tw1"></i></div>
          {{"Log in with Twitter" | translate}}</a></li>
      </ul>
    </div>
    <div class="log-in-pop-right">
      <h4>{{"Forgot your password ?" | translate}}</h4>
      <br>
      <p>{{"ENTER_EMAIL_TO_RESET_PASSWORD" | translate}}</p>
      <form class="s12" (ngSubmit)="onSubmitForm(resetPasswordForm.value)" [formGroup]="resetPasswordForm">
        <div class="form-group input-field s12">
          <label for="pass">{{'PASSWORD' | translate}}</label>
          <input type="password"
                 name="txtPassword"
                 id="pass" formControlName="password"
                 [ngClass]="(resetPasswordForm.controls['password'].touched
                     && resetPasswordForm.controls['password'].errors
                     && resetPasswordForm.controls['password'].errors.required)? 'invalid': 'validate'">
          <div
            *ngIf="resetPasswordForm.controls['password'].invalid && resetPasswordForm.controls['password'].touched"
            class="alert alert-danger text-left">
            {{'Please enter a valid password!' | translate}}
            <div
              *ngIf="resetPasswordForm.controls['password'].hasError('minlength')">
              {{'- Must have at least 8 characters!' | translate}} </div>
            <div
              *ngIf="resetPasswordForm.controls['password'].hasError('hasNumber')">
              {{'- Must have at least 1 number!' | translate}} </div>
            <div
              *ngIf="resetPasswordForm.controls['password'].hasError('hasCapitalCase')">
              {{'- Must contain at least 1 Letter in Capital Case!' | translate}} </div>
            <div
              *ngIf="resetPasswordForm.controls['password'].hasError('hasSmallCase')">
              {{'- Must contain at least 1 Letter in Small Case!' | translate}} </div>
            <div
              *ngIf="resetPasswordForm.controls['password'].hasError('hasSpecialCharacters')">
              {{'- Must contain at least 1 Special Character!' | translate}} </div>
          </div>
        </div>
        <div class="form-group input-field s12">
          <label for="confirm">{{'CONFIRM_PASSWORD' | translate}}</label>
          <input
            [ngClass]="(resetPasswordForm.controls['passwordConfirm'].touched
                && resetPasswordForm.controls['passwordConfirm'].invalid)? 'invalid': 'validate'"
            type="password" formControlName="passwordConfirm"
            id="confirm" />
          <div *ngIf="resetPasswordForm.controls['passwordConfirm'].touched
                   && resetPasswordForm.controls['passwordConfirm'].invalid" class="alert alert-danger text-left">
            <div
              *ngIf="resetPasswordForm.controls['passwordConfirm'].errors.required">{{'Password is required' | translate}}</div>
            <div *ngIf="resetPasswordForm.controls['passwordConfirm'].hasError('confirmedValidator')">
              {{'Password and Confirm Password must be match' | translate}}
            </div>
          </div>

          <div class="input-field s4" [ngClass]="{'disabledSubmit': resetPasswordForm.invalid}"
               class="waves-effect waves-light log-in-btn">
            <input type="submit" [disabled]="resetPasswordForm.invalid" value="Send">
          </div>
          <div>
            <div class="input-field s12"><a [routerLink]="[loginLink]" class="login">{{'Login' | translate}}</a> | <a
              [routerLink]="[signUpLink]" class="login">{{'Create a new account' | translate}}</a></div>
          </div>
        </div>
      </form>
    </div>
    <div class="log-in-pop-left inMobile">
      <h4>{{"Log in with social media" | translate}}</h4>
      <br>
      <ul>
        <li><a (click)="showMessage()">
          <div class="social-icon"><img src="assets/images/facebook_logo.png" width="17" alt=""/></div>
          {{"Log in with Facebook" | translate}}</a></li>
        <li><a (click)="showMessage()">
          <div class="social-icon"><img src="assets/images/google_logo.png" width="16" alt=""/></div>
          {{"Log in with Google" | translate}}</a></li>
        <li><a (click)="showMessage()">
          <div class="social-icon"><i class="fa fa-twitter tw1"></i></div>
          {{"Log in with Twitter" | translate}}</a></li>
      </ul>
    </div>
  </div>
</section>
