import {Component, OnInit} from '@angular/core';
import {ALL_EXPERIENCES_PAGE_TITLE, PAGE_TITLE_PREFIX} from '../../utils/actionsLabels/labels';
import {Title} from '@angular/platform-browser';
import {TokenHelperService} from '../../reusables/services/token-helper.service';
import {HttpParams} from '@angular/common/http';
import {ExperiencesService} from '../../reusables/services/experiences.service';
import {Experience} from '../../reusables/interfaces/experience';

@Component({
  selector: 'app-all-experiences-page',
  templateUrl: './all-experiences-page.component.html',
  styleUrls: ['./all-experiences-page.component.scss', '../../../assets/customCss/listingPages.css']
})
export class AllExperiencesPageComponent implements OnInit {
  pageTitle = ALL_EXPERIENCES_PAGE_TITLE;
  isLogged = false;
  isOk = false;
  userId: string;
  experiences: Experience[];
  p = 1; // initial page in pagination of experiences
  constructor(private titleService: Title,
              private tokenHelperService: TokenHelperService,
              private experiencesService: ExperiencesService) { }

  ngOnInit(): void {
    this.titleService.setTitle(PAGE_TITLE_PREFIX + ALL_EXPERIENCES_PAGE_TITLE);
    this.isLogged = this.tokenHelperService.getConnectedUser();
    if (this.isLogged) {
      this.userId = this.tokenHelperService.getTokenData().user_id;
    }
    window.scrollTo(0, 0);
    this.getExperiencesList();
  }
  getExperiencesList() {
    const params = new HttpParams();
    this.experiencesService.getExperiences(params).subscribe((data: Experience[]) => {
      this.experiences = data;
      this.isOk = true;
    });
  }

}
