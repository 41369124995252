/**  --------- Summary ------------
 *
 *    LABELS:
 *      - Pages
 *      - Resources
 *      - Sub-resources
 *      - Actions
 *      - Params
 *      - Compound Labels
 *
 *    PATHS:
 *      - Selfcare
 *      - Experience
 *      - Group
 *      - Template
 *      - Editor
 *      - Library
 *
 *   ------------------------------
 **/



// ***************** LABELS **************** //   (used in routing module)

// Pages
export const PROFILE_LABEL = 'profile';
export const DASHBOARD_LABEL = 'dashboard';
export const EDITOR_LABEL = 'editor';
export const LOGIN_LABEL = 'login';
// export const SIGNUP_LABEL = 'signup';
export const SIGNUP_LABEL = 'iZaX26zpzwhrEb21e0e8';
export const FORGOT_PASSWORD_LABEL = 'forgot-password';
export const RESET_PASSWORD_LABEL = 'reset-password';
export const INFORMATION_LABEL = 'information';
export const COMING_SOON_LABEL = 'coming-soon';

// Resources
export const MY_TEMPLATES_LABEL = 'my-templates';
export const MY_EXPERIENCES_LABEL = 'my-experiences';
export const MY_GROUPS_LABEL = 'my-groups';
export const GROUPS_LABEL = 'groups';
export const EXPERIENCES_LABEL = 'experiences';
export const TEMPLATES_LABEL = 'templates';
export const MY_LIBRARY_LABEL = 'my-assets-library';
export const MY_FAVORITES_LABEL = 'my-favorites';
export const FAVORITES_LABEL = 'favorites';
export const MODERATE_LABEL = 'moderate';


// Sub-resources
export const ANALYTICS_LABEL = 'analytics';
export const CONTENT_LABEL = 'content';
export const SUGGESTED_LABEL = 'suggested';
export const ALL_EXPERIENCES_PATH_LABEL = 'all-experiences';


// Actions
export const CREATE_LABEL = 'create';
export const EDIT_LABEL = 'edit';
export const EDIT_DETAILS_LABEL = 'edit-details';
export const MANAGE_EXPERIENCES_LABEL = 'manage-experiences';
export const MANAGE_MEMBERS_LABEL = 'manage-members';
export const MOBILE_LINK_PLAY_LABEL = ':mobileLink/play';
export const EDIT_ANALYTICS_LABEL = 'edit-analytics';


// Params
export const ID_LABEL = ':id';
export const CONTENT_ID_LABEL = ':contentId';


// Compound Labels
export const ID_EDIT_LABEL = ID_LABEL + '/' + EDIT_LABEL;
export const ID_EDIT_DETAILS_LABEL = ID_LABEL + '/' + EDIT_DETAILS_LABEL;
export const ID_MANAGE_EXPERIENCES_LABEL = ID_LABEL + '/' + MANAGE_EXPERIENCES_LABEL;
export const ID_MANAGE_MEMBERS_LABEL = ID_LABEL + '/' + MANAGE_MEMBERS_LABEL;
export const ID_ANALYTICS_LABEL = ID_LABEL + '/' + ANALYTICS_LABEL;
export const EDITOR_EXPERIENCE_LABEL = EXPERIENCES_LABEL + '/' + ID_LABEL + '/' + CONTENT_LABEL + '/' + CONTENT_ID_LABEL;
export const EDITOR_TEMPLATE_LABEL = TEMPLATES_LABEL + '/' + ID_LABEL + '/' + CONTENT_LABEL + '/' + CONTENT_ID_LABEL;
export const TEMPLATE_DETAILS_LABEL = TEMPLATES_LABEL + '/' + ID_LABEL;
export const TEMPLATES_PATH_LABEL = TEMPLATES_LABEL;
export const EXPERIENCES_ALL_PATH_LABEL = EXPERIENCES_LABEL + '/' + ALL_EXPERIENCES_PATH_LABEL;
export const TEMPLATES_ALL_PATH_LABEL = TEMPLATES_LABEL;
export const ID_EDIT_ANALYTICS_LABEL = ID_LABEL + '/' + EDIT_ANALYTICS_LABEL;


// ************** PATHS ****************** //
// Selfcare
export const COMING_SOON_PATH = '/' + COMING_SOON_LABEL;
export const LOGIN_PATH = '/' + LOGIN_LABEL;
export const SIGNUP_PATH = '/' + SIGNUP_LABEL;
export const FORGOT_PASSWORD_PATH = '/' + FORGOT_PASSWORD_LABEL;
export const PROFILE_PATH = '/' + PROFILE_LABEL;
export const DASHBOARD_PATH = '/' + DASHBOARD_LABEL;
export const MODERATE_PATH = '/' + MODERATE_LABEL;


// Experience
export const EXPERIENCE_PATH = '/' + EXPERIENCES_LABEL;
export const MY_EXPERIENCES_PATH = '/' + MY_EXPERIENCES_LABEL;
export const CREATE_EXPERIENCE_PATH = '/' + EXPERIENCES_LABEL + '/' + CREATE_LABEL;
export const EDIT_PATH = '/' + EDIT_LABEL; // this is used for edit experiences

// Group
export const MY_GROUPS_PATH = '/' + MY_GROUPS_LABEL;
export const CREATE_GROUP_PATH = '/' + GROUPS_LABEL + '/' + CREATE_LABEL;
export const GROUPS_PATH = '/' + GROUPS_LABEL;
export const SUGGESTED_GROUPS_PATH = '/' + GROUPS_LABEL + '/' + SUGGESTED_LABEL;
export const EDIT_GROUP_PATH = '/' + EDIT_DETAILS_LABEL;
export const MANAGE_EXPERIENCES_GROUPS_PATH = '/' + MANAGE_EXPERIENCES_LABEL;
export const MANAGE_MEMBERS_GROUPS_PATH = '/' + MANAGE_MEMBERS_LABEL;

// Template
export const MY_TEMPLATES_PATH = '/' + MY_TEMPLATES_LABEL;
export const CREATE_TEMPLATE_PATH = '/' + MY_TEMPLATES_LABEL + '/' + CREATE_LABEL;
export const TEMPLATES_PATH = '/' + TEMPLATES_LABEL;

// Editor
export const EDITOR_PATH = '/' + EDITOR_LABEL;
export const EDITOR_EXPERIENCE_PATH = '/' + EDITOR_LABEL + '/' + EXPERIENCES_LABEL;
export const EDITOR_TEMPLATE_PATH = '/' + EDITOR_LABEL + '/' + TEMPLATES_LABEL;

// Library
export const MY_LIBRARY_PATH = '/' + MY_LIBRARY_LABEL;
export const CREATE_MY_LIBRARY_PATH = '/' + MY_LIBRARY_LABEL + '/' + CREATE_LABEL;


// Favorite
export const FAVORITE_PATH = '/' + FAVORITES_LABEL;
export const MY_FAVORITE_PATH = '/' + MY_FAVORITES_LABEL;

