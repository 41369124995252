import {v4 as uuidv4} from 'uuid';
import {
  DEFAULT_AUDIO_URL,
  DEFAULT_FILE_NAME,
  DEFAULT_IMAGE_URL,
  EditorElementType
} from '../../../utils/global-constants/editor-const';

export class AudioObj {
  public uniqueId;
  public soundName: string;
  public name = EditorElementType.audio.toString();
  public type = EditorElementType.audio.toString();
  public url: string;
  public fileName: string;
  public volume = 0.1;
  public rate = 1.0;
  public loop = false;
  public isPlaying = true;
  public nbrCopy = 0;
  public visible = true;
  public triggerVisible = true;

// recreate=true: si c'est un objet dans content (database)
  constructor(object?, recreate?, name?, url?, uniqueId?, isPlaying?, fileName?) {
    if (object ) {
      this.fileName = object.fileName;
      this.rate = object.rate ;
      this.loop = object.loop ;
      this.volume = object.volume ;
      this.type = EditorElementType.audio.toString();
      if( recreate) {
        this.name = object.name;
        this.url = object.uri;
        this.nbrCopy = 0;
        this.isPlaying = false;
      } else { // copy component
        this.url = object.url;
      }
    }
    if (name) {
      this.name = name;
    }
    if (url) {
      this.url = url;
    }
    if (uniqueId) {
      this.uniqueId = uniqueId;
    }
    if (fileName) { // create new
      this.fileName = fileName;
    }
    if (isPlaying || isPlaying === false) {
      this.isPlaying = isPlaying;
    }
  }
  addAudioFromObjScene(scene) {
    if (this.name === '') {
      const first = this.url.lastIndexOf( '/' );
      const last = this.url.lastIndexOf( '.' );
      this.name = this.url.substring(first + 1 , last);
    }
    this.uniqueId = uuidv4();
    this.soundName = 'audio-' + this.uniqueId;
    const audio = new BABYLON.Sound(
      this.soundName,
      this.url,
      scene);
    audio.play();
    audio.loop = this.loop;
    audio.setVolume(this.volume);
    audio.setPlaybackRate(this.rate);
    return audio;

  }

  editFile(scene) {
    this.uniqueId = uuidv4();
    this.soundName = 'audio-' + this.uniqueId;
    const audio = new BABYLON.Sound(
      this.soundName,
      this.url,
      scene);
    audio.loop = this.loop;
    audio.setVolume(this.volume);
    audio.setPlaybackRate(this.rate);
    return audio;
  }

  recreate(scene) {
    let checkFile = false;
    const x = new XMLHttpRequest();
    x.timeout = 15000;
    x.open('GET', this.url);
    x.onreadystatechange = ( event) => {
      if ( checkFile === false ) {
        checkFile = true;
        if ( x.status === 404 ) {
          this.url = DEFAULT_AUDIO_URL;
          this.fileName = DEFAULT_FILE_NAME;
        }
        this.uniqueId = uuidv4();
        this.soundName = 'audio-' + this.uniqueId;
        const audio = new BABYLON.Sound(
          this.soundName,
          this.url,
          scene);
        audio.loop = this.loop;
        audio.setVolume(this.volume);
        audio.setPlaybackRate(this.rate);
        return audio;
      }
    };
    x.send();
  }


  outputJson() {
    return {
      id: this.uniqueId,
      type: this.type,
      name: this.name,
      uri: this.url,
      fileName: this.fileName,
      volume: this.volume,
      rate: this.rate,
      loop: this.loop,
    };
  }
}

