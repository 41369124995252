<div class="tz-2-com tz-2-main">
  <div class="center msg-content">
    <p>{{"Do you really want to accept the invitation to join group" | translate}}</p>
    <br/>
  </div>
</div>
<div class="row actions-popup">
  <button class="button  waves-effect waves-light btn-large save-popup-style"
          (click)="accept()">{{'ACCEPT' | translate}}</button>
  <button class="button waves-effect waves-light btn-large"
          (click)="cancel()">{{'Cancel' | translate}}</button>
</div>
