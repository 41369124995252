<section *ngIf="doneForUserProfile">
  <div class="tz">
    <div class="tz-l">
      <app-profile-picture [user]="user"></app-profile-picture>
      <app-account-menu [user]="user"></app-account-menu>
    </div>
    <div *ngIf="!showEditProfile && !showEditPassword && showProfileAfterEdit" class="tz-2">
      <app-manage-profile [user]="user" (changeAction)="changeAction($event)"></app-manage-profile>
    </div>
    <div *ngIf="showEditPassword" class="tz-2">
      <app-change-password (changeAction)="changeAction($event)"></app-change-password>
    </div>
    <div *ngIf="showEditProfile" class="tz-2">
      <app-edit-profile (changeAction)="changeAction($event)" [user]="user" ></app-edit-profile>
      <div class="row waiting_load" *ngIf="waitingLoad">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>
<!--   <app-notifications></app-notifications>-->
  </div>
</section>
