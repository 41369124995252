import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-reject-experience-dialog',
  templateUrl: './reject-experience-dialog.component.html',
  styleUrls: ['./reject-experience-dialog.component.scss']
})
export class RejectExperienceDialogComponent implements OnInit {
  form: FormGroup;
  remarks = '';
  isTemplate = false;

  constructor(public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    if (this.data.type == 'template') {
      this.isTemplate = true;
    }
    this.form = new FormGroup({
      remarks: new FormControl(this.remarks, Validators.required)
    });
  }

  reject() {
    this.dialogRef.close(this.form.controls.remarks.value);
  }

  cancel() {
    this.dialogRef.close();
  }

}
