/* tslint:disable:no-string-literal */
// Configuration used in Docker  image
export const environment = {
  production: true,
  BASE_URL: window['env']['apiUrl'] + 'api/ms-experiences/',
  BASE_URL_USER: window['env']['apiUrl'] + 'api/ms-users/',
  BASE_URL_Media: window['env']['apiUrl'] + 'api/ms-media/',
  BASE_URL_GROUP: window['env']['apiUrl'] + 'api/ms-groups/',
  BASE_URL_LIBRARY: window['env']['apiUrl'] + 'api/ms-assets-libraries/',
  BASE_URL_LINK: window['env']['apiUrl'],

  // Editor: default assets url
  DEFAULT_IMAGE_URL: 'https://camlann.squeezer-software.com/camlann-assets/ms-assets-libraries/global-library-assets/kGUGngJuyuYflNcfCCXrvSCBGf4Xq69BHoUMk1HAgtpIY5NnpKSIgX3trd7706MhqeKZZjPqNyOzprBzb2CRZvBEsVxRIGj6ezTp.png',
  DEFAULT_AUDIO_URL: 'https://camlann.squeezer-software.com/camlann-assets/ms-assets-libraries/global-library-assets/dmDFZpvijVWl3g3ODWCudFG6V6DRChe9Zo8nQKZXth6FQGHxNElZvRGYITGauo8AO8LZNmdUzFLUShDMgqKflJYht4qKABfYMtWZ.ogg',
  DEFAULT_OBJ_3D_URL: 'https://camlann.squeezer-software.com/camlann-assets/ms-assets-libraries/global-library-assets/xgp9F6rwvzaDf5YaIzzj2qee4NXLtbiNtr0FVIcuvQXBjdVGQ1L6wL5f78xh3mvlLiQ9ojKE0c1xmvE1cDgAZsshMLh5iXpjRu7g/cube.gltf',
  DEFAULT_OBJ_3D_ZIP_URL: 'https://camlann.squeezer-software.com/camlann-assets/ms-assets-libraries/global-library-assets/xgp9F6rwvzaDf5YaIzzj2qee4NXLtbiNtr0FVIcuvQXBjdVGQ1L6wL5f78xh3mvlLiQ9ojKE0c1xmvE1cDgAZsshMLh5iXpjRu7g/mPLDDPwwxIvH1ubYvh7UkC4DfiGQGEHD9i8TpvdBoUCrfnjU2vxAGo2nn6ECDfvk3R22nMmlr7w65x6OGEvwi0yBV4FcVZPBS1uo.zip',
  DEFAULT_OBJ_3D_GEO_POSITION_URL: 'https://camlann.squeezer-software.com/camlann-assets/ms-assets-libraries/global-library-assets/IWMvNEjycOejPR3qraAryjIp6lwzTaCWA2G1CHRHVcnj2h3r42Xd5cDFMyAR2z1jzL3y9frBEfJMboQXcBiupWEZKEcwL5vvPqtk/scene.gltf',

  // Firebase config
  firebase: window['env']['firebase']
};

