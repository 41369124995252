<div class="list-mig-like" *ngIf="suggestedGroups.length > 0">
  <div class="list-ri-spec-tit">
    <h3>
      <span>{{'You might' | translate}}</span> {{'like this' | translate}}
    </h3>
  </div>

  <div *ngFor="let item of suggestedGroups" class="suggested-group">
    <app-suggested-group [data]="item" (groupClickAction)="onItemGroupClick($event)"></app-suggested-group>
  </div>
</div>
