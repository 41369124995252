import {Component, OnInit} from '@angular/core';
import {User} from '../../reusables/interfaces/user';

import {DELETE, LABEL_MY_GROUPS} from '../../utils/actionsLabels/labels';
import {Group} from '../../reusables/interfaces/group';
import {UsersService} from '../../reusables/services/users.service';
import {AuthGuard} from '../../reusables/_helpers';


@Component({
  selector: 'app-my-groups',
  templateUrl: './my-groups.component.html',
  styleUrls: ['./my-groups.component.scss']
})
export class MyGroupsComponent implements OnInit {

  deleteText = DELETE;
  user: User;
  pageTitle = LABEL_MY_GROUPS;
  groupsIManage: Group[];
  groupsIAmMember: Group[];
  doneGroupsIManage = false;
  doneGroupsIAmMember = false;
  userId: string;
  constructor(private authGuard: AuthGuard,
              private usersService: UsersService) {
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    if (this.authGuard.canActivate) {
      this.getProfile();
    }

  }

  getProfile() {
    this.usersService.getUserProfile().subscribe((data: User) => {
      this.user = data;
    });
  }

}
