import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {CANCEL_ACTION, EDIT_PROFILE_ACTION} from '../../../../utils/actionsLabels/labels';
import {User} from '../../../interfaces/user';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  submitted = false;
  editProfileForm;
  formIsNotValid = false;
  @Input() user: User;
  @Output() changeAction = new EventEmitter();

  profileImageURL: string; // old value (from user)
  croppedImage; // cropped image (base64)
  fileCroppedImage: File; // big image
  profileImage: File; // file sent on request (from cropped image)
  @ViewChild('inputCroppedImage') fileElementRef: ElementRef;
  isNotDesktop = false;

  constructor(private formBuilder: FormBuilder,
              private deviceService: DeviceDetectorService) {
    this.editProfileForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      displayName: ['', Validators.required]
    }, undefined);
  }

  ngOnInit(): void {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    if (isMobile || isTablet) {
      this.isNotDesktop = true;
    }
    if (this.user.profilePicture && this.user.profilePicture !== '') {
      this.profileImageURL = this.user.profilePicture;
    }
    this.editProfileForm.controls.firstName.setValue(this.user.firstName);
    this.editProfileForm.controls.lastName.setValue(this.user.lastName);
    if (this.user.displayName !== '') {
      this.editProfileForm.controls.displayName.setValue(this.user.displayName);
    } else {
      this.editProfileForm.controls.displayName.setValue(this.user.firstName + ' ' + this.user.lastName);
    }
  }

  resetForm() {
    this.editProfileForm.reset();
    this.formIsNotValid = false;
    this.changeAction.emit({action: CANCEL_ACTION});
  }

  onSubmit(data) {
    this.submitted = true;
    const formData = new FormData();
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('displayName', data.displayName);
    formData.append('email', this.user.email);
    formData.append('profilePicture', this.profileImageURL);
    formData.append('coverPicture', '');
    if (this.profileImage) {
      formData.append('profilePictureFile', this.profileImage);
    }
    this.changeAction.emit({action: EDIT_PROFILE_ACTION, request: formData});
  }
  // ********************************** CoverProfile image-cropper start ***********************//

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.profileImageURL = '';
    fetch(this.croppedImage)
      .then(res => res.blob()).then(bl => {
      this.profileImage = new File([bl], this.fileCroppedImage.name);
    });
  }
  // icon edit
  onCroppedImageChange(event: Event) { // new image
    if ((event.target as HTMLInputElement).files.length > 0) {
      // old image
      this.profileImageURL = '';
      // new image
      this.fileCroppedImage = (event.target as HTMLInputElement).files[0];
    }
    this.fileElementRef.nativeElement.value = '';
  }

  // icon My Image
  getMyProfileImage() {
    // old image
    this.profileImageURL = this.user.profilePicture;
    // new image
    this.fileCroppedImage = null;
    this.croppedImage = null;
    this.profileImage = null;
  }
}
