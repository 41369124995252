<div class ="list-spac">
  <div class="lr-user-wr-img my-image">
    <img *ngIf="data.review_creator.userProfilePicture && data.review_creator.userProfilePicture !== ''" src="{{data.review_creator.userProfilePicture}}" alt="">
    <div *ngIf="!data.review_creator.userProfilePicture && data.review_creator.userProfilePicture == ''"> </div>
  </div>
  <div class="lr-user-wr-con">
    <h6>{{data.review_creator.userDisplayName}}<span>{{data.rating}} <i class="fa fa-star" aria-hidden="true"></i></span></h6>
    <span class="lr-revi-date">{{data.createdDate | date}}</span>
    <p>{{data.review}}</p>
    <ul>
  <!--    <li><a ><span>{{"Like" | translate}}</span><i class="fa fa-thumbs-o-up" aria-hidden="true"></i></a> </li>-->
  <!--    <li><a ><span>{{"DisLike" | translate}}</span><i class="fa fa-thumbs-o-down" aria-hidden="true"></i></a> </li>-->
  <!--    <li><a ><span>{{"Report" | translate}}</span> <i class="fa fa-flag-o" aria-hidden="true"></i></a> </li>-->
  <!--    <li><a ><span>{{"Comments" | translate}}</span> <i class="fa fa-commenting-o" aria-hidden="true"></i></a> </li>-->
  <!--    <li><a ><span>{{"Share Now" | translate}}</span>  <i class="fa fa-facebook" aria-hidden="true"></i></a> </li>-->
  <!--    <li><a ><i class="fa fa-twitter" aria-hidden="true"></i></a> </li>-->
  <!--    <li><a ><i class="fa fa-linkedin" aria-hidden="true"></i></a> </li>-->
  <!--    <li><a ><i class="fa fa-youtube" aria-hidden="true"></i></a> </li>-->
      <li><a (click)="actionLikeReview()"><span>Like</span>
        <a *ngFor="let et of etatLike| keyvalue" >
          <i *ngIf="et.key == data.id && et.value==true"   class="fa fa-thumbs-up" aria-hidden="true"></i>
          <i *ngIf="et.key == data.id && et.value==false"  class="fa fa-thumbs-o-up" aria-hidden="true"></i>
        </a>
      </a></li>
      <li><a *ngFor="let item of likes | keyvalue"><span *ngIf="item.key == data.id">{{item.value}}</span></a></li>

      <li><a (click)="actionDislikeReview()"><span>Dislike</span>
        <a *ngFor="let et of etatDislike| keyvalue">
          <i *ngIf="et.key == data.id && et.value==true"   class="fa fa-thumbs-down" aria-hidden="true"></i>
          <i *ngIf="et.key == data.id && et.value==false"  class="fa fa-thumbs-o-down" aria-hidden="true"></i>
        </a>
      </a></li>
      <li><a *ngFor="let items of dislikes | keyvalue"><span *ngIf="items.key == data.id">{{items.value}}</span></a></li>
      <li *ngIf="connectedUser == data.review_creator.userId"><a (click)="editReview()"><i class="fa fa-edit" aria-hidden="true"></i></a></li>
      <li *ngIf="connectedUser == data.review_creator.userId"><a (click)="deleteReview()"><i class="fa fa-trash" aria-hidden="true"></i></a></li>
    </ul>
  </div>
</div>
