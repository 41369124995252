<div class="tz-2-com tz-2-main">
  <h4>{{pageTitle + ' - Step 2' | translate }}</h4>

  <div class="row waiting-get-data "  *ngIf="showUsers===false">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>

  <div class="db-list-com tz-db-table" *ngIf="showUsers===true">
    <div class="hom-cre-acc-left hom-cre-acc-right">
      <div class="">

        <form [formGroup]="myForm" (ngSubmit)="onSubmit(myForm.value)">
          <div class="row">
            <div class="db-v2-list-form-inn-tit">
              <h5><span>{{groupName}} </span>{{"Add Members" | translate}}</h5>
            </div>
          </div>
          <div class="row margin-bot">
            <div class="input-field col s12">
              <div class="select-members">
                <ng-select [items]="allUsers"
                           bindLabel="member_name"
                           bindValue="member_id"
                           (change)="onChange($event)"
                           (search)="onSearch($event)"
                           [multiple]="true"
                           [clearable]="true"
                           formControlName="users"
                           [(ngModel)]="selectedIds">
                </ng-select>
              </div>
            </div>
          </div>
          <br>

          <div class="input-field col s12 " *ngIf="users && users.length > 0">
            <div class="thumbnail-content" *ngFor="let user of users; let i = index;">
              <div *ngIf="!screenSizeChanged">
                <div class="item-number inline">{{i + 1}}</div>
                <div class="img-thumbnail-content inline">
                  <img *ngIf="user.profilePicture && user.profilePicture != ''" src="{{user.profilePicture}}" height="45"/>
                  <div *ngIf="!user.profilePicture && user.profilePicture == ''"> </div>
                </div>
                <input type="text" value="{{user.member_name}}" disabled class="inline"/>
                <div >

                  <mat-select (selectionChange)="changeMemberRole($event, user.member_id)" value="{{groupRoles[1].value}}" style="width: 120px">
                    <mat-option value="{{groupRoles[1].value}}">
                      {{groupRoles[1].htmlValue | translate}}
                    </mat-option>
                    <mat-option disabled="disabled" value="{{groupRoles[0].value}}">
                      {{groupRoles[0].htmlValue  | translate}}
                    </mat-option>
                  </mat-select>
                </div>
                <button class="btn btn-danger inline" (click)="deleteMemberFromList(user)"
                        title="{{'DELETE_MEMBER' | translate}}"><i class="fa fa-trash"></i></button>
              </div>


              <div *ngIf="screenSizeChanged">
                <div style="overflow: auto">
                  <div class="item-number inline">{{i + 1}}</div>
                  <div class="img-thumbnail-content inline">
                    <img src="{{user.profilePicture}}" height="45"/>
                    <div *ngIf="!user.profilePicture && user.profilePicture == ''"> </div>
                  </div>
                  <button class="btn btn-danger left" (click)="deleteMemberFromList(user)"
                          title="{{'DELETE_MEMBER' | translate}}"><i class="fa fa-trash"></i></button>

                </div>
                <div class="left"><input type="text" value="{{user.member_name}}" disabled/></div>
                <div class="inline custom-select" style="width:120px;">
                  <mat-select (selectionChange)="changeMemberRole($event, user.member_id)" value="{{groupRoles[1].value}}" style="width: 120px">
                    <mat-option value="{{groupRoles[1].value}}">
                      {{groupRoles[1].htmlValue | translate}}
                    </mat-option>
                    <mat-option disabled="disabled" value="{{groupRoles[0].value}}">
                      {{groupRoles[0].htmlValue  | translate}}
                    </mat-option>
                  </mat-select></div>
              </div>
            </div>
          </div>

          <br/>

          <div class="row margin-bot">
            <div class="input-field col s6 v2-mar-top-40">
              <button type="submit" class="waves-effect waves-light btn-large full-btn submit-tbn">
                {{'Submit' | translate}}
              </button>
            </div>
            <div class="input-field col s6 v2-mar-top-40">
              <button class="waves-effect waves-light btn-large full-btn skip-btn" (click)="skipAddUsers()">
                {{'Skip' | translate}}
              </button>
            </div>
          </div>
          <br >
          <br >
          <br >
        </form>
      </div>
    </div>
  </div>
</div>
