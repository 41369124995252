import {Component, Input, OnInit} from '@angular/core';
import {ConfirmDialogService} from '../confirm-dialog.service';
import {CANCEL_BTN_MESSAGE, CONFIRM_BTN_MESSAGE} from '../../../../utils/actionsLabels/labels';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: 'confirm-dialog.component.html',
  styleUrls: ['confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  message: any;
  confirmBtn = CONFIRM_BTN_MESSAGE;
  cancelBtn = CANCEL_BTN_MESSAGE;
  @Input() confirmText;
  @Input() cancelText;
  constructor(
    private confirmDialogService: ConfirmDialogService
  ) {}

  ngOnInit() {
    if (this.confirmText && this.confirmText !== '') {
      this.confirmBtn = this.confirmText;
    }
    if (this.cancelText && this.cancelText!== '') {
      this.cancelBtn = this.cancelText;
    }
    this.confirmDialogService.getMessage().subscribe(message => {
      this.message = message;
    });
  }
}
