import {SCROLL_TO_ABOUT, SCROLL_TO_EXPERIENCES} from '../actionsLabels/labels';

export const initialMenuGroupDetails = [
  {
    scrollAction: '',
    menuLabel: '',
    icon: ''
  }
];

// export const nonConnectedMenuGroupDetails = [
//   {
//     scrollAction: SCROLL_TO_ABOUT,
//     menuLabel: 'About',
//     icon: 'fa-user'
//   }
// ];

export const connectedMenuGroupDetails = [
  {
    scrollAction: SCROLL_TO_ABOUT,
    menuLabel: 'About',
    icon: 'fa-user'
  },
  {
    scrollAction: SCROLL_TO_EXPERIENCES,
    menuLabel: 'Experiences',
    icon: 'fa-heart'
  }
];
export const publicMenuGroupDetails = [
  {
    scrollAction: SCROLL_TO_ABOUT,
    menuLabel: 'About',
    icon: 'fa-user'
  },
  {
    scrollAction: SCROLL_TO_EXPERIENCES,
    menuLabel: 'Experiences',
    icon: 'fa-heart'
  }
];
