<div class="tz-2-com tz-2-main">
  <a [routerLink]="suggestedGroupsPath">
    <div class="center create-first-experience-content">
      <div class="icon-experience-content"><i class="fas fa-cubes"></i></div>
      <div class="center">
        <h3>{{"not_member_in_any_group_msg" | translate}}</h3>
        <br/>
        <p>{{"go_to_suggested_groups" | translate}}</p>
        <br/>
      </div>
      <div class="waves-effect waves-light btn-large full-btn">{{"JOIN_GROUP" | translate}}</div>

    </div>
  </a>
</div>
