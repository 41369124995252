import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthenticationService} from '../services/authentication.service';
import {
  BAD_REQUEST_TITLE,
  CONFLICT_CONTENT,
  CONFLICT_TITLE,
  FORBIDDEN_PATH,
  NOT_FOUND_PATH,
  OOPS_PATH
} from '../../utils/errorsReactions/errors-texts';
import {Router} from '@angular/router';
import {NotificationService} from '../services/notification.service';
import {LOGIN_PATH} from '../../utils/actionsLabels/pathLabels';
import {TokenHelperService} from '../services/token-helper.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService,
              private router: Router,
              private notificationService: NotificationService,
              private tokenHelperService: TokenHelperService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      // code from error-manager
      if (err.status) {
        switch (err.status) {
          case 401:
            // refresh the token if 401 response returned from api
            this.authenticationService.refreshTokenAfterError().then(
              idToken => {
                // this.userToken = idToken;
                if (idToken) {
                } else {
                  // if no token is returned, go to login
                  this.authenticationService.logout();
                }
                // reload page in both cases: refresh token or logout
                location.reload();
              });

            break;
          case 403:
            // console.log('user connected', this.tokenHelperService.getConnectedUser());
            if (this.tokenHelperService.getConnectedUser()) {
              this.router.navigateByUrl(FORBIDDEN_PATH).then();
            } else {
              this.router.navigateByUrl(LOGIN_PATH).then();
            }
            break;
          case 404:
            this.router.navigateByUrl(NOT_FOUND_PATH).then();
            break;
          case 409:
            this.notificationService.showError(CONFLICT_CONTENT, CONFLICT_TITLE);
            break;
          case 400:
            if (err.error.code  && err.error.message) {
              // return throwError(err.error);
              this.notificationService.showError(err.error.message, BAD_REQUEST_TITLE);
            } else {
              this.router.navigateByUrl(OOPS_PATH).then();
            }
            break;
          default:
            this.router.navigateByUrl(OOPS_PATH).then();
            break;
        }
      }
      // add "err.error &&" because err.error can be null
      const error = (err.error && err.error.message) || err.statusText;
      return throwError(error);
    }));
  }
}
