import * as GUI from 'babylonjs-gui';
import * as BABYLON from 'babylonjs';
import {v4 as uuidv4} from 'uuid';
import {EditorElementType} from '../../../utils/global-constants/editor-const';

export class Surface {
  public uniqueId;
  public url = 'assets/images/editor/triggerSurface.png';
  public name = EditorElementType.surface.toString();
  public type = EditorElementType.surface.toString();
  public visible = true;

  public width = 200;
  public height = 100;
  public nbrCopy = 0;

  public rotation = {x: 0, y: 0, z: 0};
  public position = {x: 0, y: 100, z: 1700};
  public scale = {x: 1, y: 1, z: 1};


  constructor(object?) {
    if (object) {
      this.type = EditorElementType.surface.toString();
      this.url = object.url;
      this.visible = object.visible;

      this.width = object.width;
      this.height = object.height;

      this.rotation = {x: object.rotation.x, y: object.rotation.y, z: object.rotation.z};
      this.scale = {x: object.scale.x, y: object.scale.y, z: object.scale.z};
    }
  }

  newMarkerObjFromContent(name) {
    this.name = name;
  }

  addSurfaceFromObjScene(scene: BABYLON.Scene) {
    const image = new GUI.Image(' ', this.url);
    image.onImageLoadedObservable.add(() => {
      image.heightInPixels = image.domImage.naturalHeight ;
      image.widthInPixels = image.domImage.naturalWidth  ;
      this.height = image.heightInPixels;
      this.width = image.widthInPixels;

      const plane = BABYLON.MeshBuilder.CreateGround(EditorElementType.surface.toString(), {
        width: this.width,
        height: this.height
      }, scene);

      plane.position = new BABYLON.Vector3(this.position.x, this.position.y, this.position.z);
      const advancedTextureImage = GUI.AdvancedDynamicTexture.CreateForMesh(plane , this.width , this.height);
      advancedTextureImage.addControl(image);
      plane.renderingGroupId = 1;
      plane.isVisible = this.visible;
      plane.uniqueId = uuidv4();
      this.uniqueId = plane.uniqueId;
      return plane;
    });
  }

  recreate(scene) {
    this.addSurfaceFromObjScene(scene);
  }


  outputJson() {
    return {
      id: this.uniqueId,
      name: this.name,
    };
  }
}

