import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Review} from '../../../interfaces/review';
import {
  COOKIES_CONNECTED_USER_ID, WRITE_DISLIKE_ACTION,
  WRITE_LIKE_ACTION, WRITE_LIKE_DISLIKE_EDIT,
  WRITE_REVIEW_DELETE,
  WRITE_REVIEW_EDIT
} from '../../../../utils/actionsLabels/labels';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-single-review',
  templateUrl: './single-review.component.html',
  styleUrls: ['./single-review.component.scss']
})
export class SingleReviewComponent implements OnInit {

  constructor(private cookieService: CookieService) {
  }

  @Input() data: Review;
  @Output() reviewData = new EventEmitter();
  @Input() likes: any;
  @Input() dislikes: any;
  @Input() etatLike: any;
  @Input() etatDislike: any;

  connectedUser;
  ngOnInit(): void {
    this.connectedUser = this.cookieService.get(COOKIES_CONNECTED_USER_ID);
  }

  editReview() {
    this.reviewData.emit({type: WRITE_REVIEW_EDIT, data: this.data.id});
  }
  deleteReview() {
    this.reviewData.emit({type: WRITE_REVIEW_DELETE, data: this.data.id});
  }
  // like_dislike
  actionLikeReview() {
    this.reviewData.emit({type: WRITE_LIKE_ACTION, data: this.data.id, data1: this.data.id_action});
  }
  actionDislikeReview() {
    this.reviewData.emit({type: WRITE_DISLIKE_ACTION, data: this.data.id, data1: this.data.id_action});
  }

  editLikeDislikeReview() {
    this.reviewData.emit({type: WRITE_LIKE_DISLIKE_EDIT, data: this.data.id, data1: this.data.id_action});
  }

}
