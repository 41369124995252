import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {myAccountMenu, myAccountMenuAdmin} from '../../../../utils/jsonData/myAccountMenu';
import {COMING_SOON} from '../../../../utils/actionsLabels/labels';
import {COMING_SOON_PATH, CREATE_EXPERIENCE_PATH, EDIT_PATH} from '../../../../utils/actionsLabels/pathLabels';
import {NotificationService} from '../../../services/notification.service';
import {LogoutService} from '../../../services/logout.service';
import {User} from "../../../interfaces/user";
import {userRole} from "../../../../utils/global-constants/user-const";

@Component({
  selector: 'app-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss']
})
export class AccountMenuComponent implements OnInit {

  constructor(private router: Router,
              private logoutService: LogoutService,
              private notificationService: NotificationService) {
    this.router.events.subscribe(() => {
      this.currentUrl = this.router.url;
    });
  }

  @Input() user: User;

  myMenu: any;
  currentUrl = '';
  comingSoon = COMING_SOON_PATH;
  createExpPath = CREATE_EXPERIENCE_PATH;
  editPath = EDIT_PATH;

  ngOnInit(): void {
    if (this.user.role == userRole.ADMIN || this.user.role == userRole.SUPER_ADMIN) {
      this.myMenu = myAccountMenuAdmin;
    } else {
      this.myMenu = myAccountMenu;
    }
    this.currentUrl = this.router.url;
  }

  comingSoonAction() {
    this.notificationService.showWarning('', COMING_SOON);
  }
  logout() {
    this.logoutService.logout({action: 'logout'});
  }
}
