<section *ngIf="doneForUserProfile">
  <div class="tz">
    <div class="tz-l">
      <app-profile-picture [user]="user"></app-profile-picture>
      <app-account-menu [user]="user"></app-account-menu>
    </div>
    <div class="tz-2">
      <app-add-edit-experience-form *ngIf="!chooseCreate && open && doneForUserProfile" [experience]="experience"
                                    [groups]="groups"
                                    [experienceGroups]="experienceGroups"
                                    (request)="readData($event)"
                                    (searchGroupsRequest)="searchForGroup($event)"
                                    (goBack)="goBack()"
                                    [user]="user"
                                    [chooseCreate]="chooseCreate">
      </app-add-edit-experience-form>
      <div *ngIf="chooseCreate">
      <app-choose-create-experience-form
        *ngIf="doneForUserExperiences && doneForUserTemplates && doneForPublishedTemplates"
        [userExperiences]="userExperiences"
        [doneForUserExperiences]="doneForUserExperiences"
        [userTemplates]="userTemplates"
        [doneForUserTemplates]="doneForUserTemplates"
        [publishedTemplates]="publishedTemplates"
        [doneForPublishedTemplates]="doneForPublishedTemplates"
        (openCreateExperiencePage)="openCreateExperiencePage($event)"
        [carouselOptions]="carouselOptions">
      </app-choose-create-experience-form>
      </div>
    </div>
<!--    <app-notifications></app-notifications>-->
  </div>
</section>

<div class="row waiting_load" *ngIf="waitingLoad">
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
</div>
