import {Component, Inject, OnInit} from '@angular/core';
import {CREATING_ASSET, SELECT_FILE} from '../../../../utils/actionsLabels/editorLabels';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LibraryService} from '../../../services/library.service';
import {HttpParams, HttpResponse} from '@angular/common/http';
import {ASSET_FILE, ASSET_TYPE, ASSETS_LIBRARY_ID, CREATOR_ID, LIBRARY_TYPE} from '../../../../utils/queryParams/assetsQueryParams';
import {AssetObject, AssetsLibrary} from '../../../interfaces/assets-library';
import {AssetType, LibraryType, maxFileSize} from '../../../../utils/global-constants/assets-library-const';
import {checkExtension, checkSize, fromBitToKoString} from '../../../../utils/functions/fileFuctions';
import {IMPORT_ASSET_DIALOG_TAB_MENU} from '../../../../utils/global-constants/editor-const';
import * as $ from 'jquery';
import {MY_LIBRARY_PATH} from '../../../../utils/actionsLabels/pathLabels';
import {CustomError} from '../../../interfaces/error';
import {ErrorCode} from '../../../../utils/global-constants/errorCode';

@Component({
  selector: 'app-import-asset-dialog',
  templateUrl: './import-asset-dialog.component.html',
  styleUrls: ['./import-asset-dialog.component.scss']
})
export class ImportAssetDialogComponent implements OnInit {
  myAssetsLink = MY_LIBRARY_PATH;
  selectFile  = SELECT_FILE ;
  popupTitle: string;
  IMAGE = AssetType.IMAGE;
  OBJ_3D = AssetType.OBJ_3D;
  AUDIO = AssetType.AUDIO;
  creatingAsset = CREATING_ASSET;

  // ******** Image path  ***************//
  audioImagePath = '../../../../../assets/images/editor/audio.png';
  object3DImagePath = '../../../../../assets/images/editor/obj.png';
  // ******** Tab menu  ***************//
  tabMenu = IMPORT_ASSET_DIALOG_TAB_MENU;
  selectedMenu = this.tabMenu[0].value;

  // *********** popup DATA ***************//
  myAssets: AssetObject[] = [];
  globalAssets: AssetObject[] = [];


  // *********** variables to show messages ***************//
  showNoAssetsLibraryOrNoAssetsObject = false;
  showNoGlobalAssetsLibrary = false;
  showLoadingALibrary = true;
  showLoadingAssetsObject = true;
  showLoadingGlobalAssetsObject = true;
  showLoadingAddAssetsObject = false;
  activateImport = false;
  showNoSpace = false;
  showFile = false;
  showMaxSizeExceeded = false;
  showLibrarySizeExceeded = false;
  showInvalidExtension = false;
  // ****** selected asset
  selectedAsset: AssetObject = null;
  selectedFileName: string;
  selectedFile: File;
  myAssetsLibrary: AssetsLibrary;

  constructor(public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) public data: {
                title: string,
                info: { assetType: AssetType, messages: any, input: any },
                userId: string },
              private libraryService: LibraryService) {}


  ngOnInit(): void {
    this.popupTitle = this.data.title;
    this.showLoadingALibrary = true;
    this.showLoadingAssetsObject = true;
    this.showLoadingGlobalAssetsObject = true;
    // get connected user Assets Library
    this.getMyLibrary();
    // get global assets for second menu
    this.getGlobalAssetsWithType();
  }


  sizeToKoString(size) {
    return fromBitToKoString(size);
  }

  initValues() {
    this.activateImport = false;
    this.selectedAsset = null;
    this.showMaxSizeExceeded = false;
    this.showLibrarySizeExceeded = false;
    this.showInvalidExtension = false;
    this.showFile = false;
    this.selectedFileName = null;
    this.selectedFile = null;
    this.showLoadingAddAssetsObject = false;
    this.dialogRef.disableClose = false;
  }

  /****************************** WS: GET ASSETS , POST ASSET *********************************/
  // get connected user Assets Library
  getMyLibrary() {
    let paramsGroups = new HttpParams();
    paramsGroups = paramsGroups.append(CREATOR_ID, this.data.userId);
    this.libraryService.getAssetsLibraryByCreatorId(paramsGroups).subscribe((data: AssetsLibrary[]) => {
      this.showLoadingALibrary = false;
      if (data && data.length > 0 ) {
        this.myAssetsLibrary = data[0];
      }
      if (this.myAssetsLibrary == null) {
        this.showNoAssetsLibraryOrNoAssetsObject = true;
        this.showLoadingAssetsObject = false;
        this.showNoSpace = false;
      } else {
        // get my assets for first menu
        this.getMyAssetsWithType();
        // third menu: check my library assigned size
        this.showNoSpace = (this.myAssetsLibrary.assignedSize === 0);
      }
    });
  }
  // get connected user Assets
  getMyAssetsWithType() {
    let paramsGroups = new HttpParams();
    paramsGroups = paramsGroups.append(ASSETS_LIBRARY_ID, this.myAssetsLibrary.libraryId);
    paramsGroups = paramsGroups.append(ASSET_TYPE, this.data.info.assetType.toString());
    this.libraryService.getAssets(paramsGroups).subscribe((data: AssetObject[]) => {
      this.showLoadingAssetsObject = false;
      this.showNoAssetsLibraryOrNoAssetsObject = !data || data.length === 0;
      this.myAssets = data;
    });
  }

  // get connected user Assets
  getGlobalAssetsWithType() {
    let paramsGroups = new HttpParams();
    paramsGroups = paramsGroups.append(LIBRARY_TYPE, LibraryType.GLOBAL);
    paramsGroups = paramsGroups.append(ASSET_TYPE, this.data.info.assetType.toString());
    this.libraryService.getAssets(paramsGroups).subscribe((data: AssetObject[]) => {
      this.showLoadingGlobalAssetsObject = false;
      this.showNoGlobalAssetsLibrary = !data || data.length === 0;
      this.globalAssets = data;
    });
  }

  // add asset
  addAsset(file: File) {

    const formData = new FormData();
    formData.append(LIBRARY_TYPE, LibraryType.PRIVATE.toString());
    formData.append(ASSET_TYPE, this.data.info.assetType.toString());
    formData.append(ASSET_FILE, file);
    this.libraryService.addAsset(formData).subscribe((data: HttpResponse<AssetObject>) => {
      if (data.body) {
        this.selectedAsset = data.body;
        this.showLoadingAddAssetsObject = false;
        this.dialogRef.close(this.selectedAsset);
      }
    }, (error: CustomError) => {
      switch (error.code) {
        case ErrorCode.aErD004.toString():
          this.initValues();
          this.showInvalidExtension = true;
          break;

        case ErrorCode.aErD002.toString():
          this.initValues();
          this.showInvalidExtension = true;
          break;

        case ErrorCode.aErD003.toString():
          this.initValues();
          this.showMaxSizeExceeded = true;
          break;
      }
    });
  }




  /****************************** USER ACTIONS *********************************/

  selectAsset(asset: AssetObject) {
    this.selectedAsset = asset;
    this.activateImport = true;
  }

  importAsset() {
    this.activateImport = false;
    switch (this.selectedMenu) {
        case this.tabMenu[0].value:
          this.dialogRef.close(this.selectedAsset);
          break;
        case this.tabMenu[1].value:
          this.dialogRef.close(this.selectedAsset);
          break;
        case this.tabMenu[2].value:
          this.showLoadingAddAssetsObject = true;
          this.dialogRef.disableClose = true;
          this.addAsset(this.selectedFile);
          break;
    }
  }

  close() {
    this.dialogRef.close();
  }

  tabClick() {
    const val = $('.custom_menu_options:checked').val();
    if (this.selectedMenu !== val ) {
      this.selectedMenu = val;
      this.initValues();
    }
  }

  addFile() {
    document.getElementById(this.data.info.input.id).click();
  }

  onfileChange(event) {
    this.initValues();

    if (event.target.files && event.target.files.length > 0) {
      this.selectedFile = event.target.files[0];

      // check file size
      if (!checkSize(this.selectedFile, maxFileSize)) {
        this.showMaxSizeExceeded = true;
        return false;
      }
      if (this.myAssetsLibrary && !checkSize(this.selectedFile, this.myAssetsLibrary.assignedSize)) {
        this.showLibrarySizeExceeded = true;
        return false;
      }
      // check file extension
      if (!checkExtension(this.selectedFile, this.data.info.input.extensionList)) {
        this.showInvalidExtension = true;
        return false;
      }
      // valid file: show file name and activate import button
      this.showFile = true;
      this.selectedFileName = (event.target as HTMLInputElement).files[0].name;
      this.activateImport = true;
    }
  }
}
