import {Component, OnInit} from '@angular/core';
import {
  BLANK_TEMPLATE,
  CAN_NOT_ALLOWED_OS,
  CAN_NOT_ALLOWED_OS_TITLE,
  CREATE_TEMPLATE_PAGE_TITLE,
  EDIT_TEMPLATE_PAGE_TITLE,
  PAGE_TITLE_PREFIX,
} from '../../../../utils/actionsLabels/labels';
import {Title} from '@angular/platform-browser';
import {HttpParams} from '@angular/common/http';
import {OWNER_TEMPLATE, SOURCE, USER_TEMPLATE} from '../../../../utils/queryParams/templatesQueryParams';
import {Template} from '../../../interfaces/templates';
import {EDITOR_TEMPLATE_PATH, MY_TEMPLATES_PATH, TEMPLATES_PATH} from '../../../../utils/actionsLabels/pathLabels';
import {ExperiencesService} from '../../../services/experiences.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../../services/notification.service';
import {TokenHelperService} from '../../../services/token-helper.service';
import {OWNER_EXPERIENCE} from '../../../../utils/queryParams/experiencesQueryParams';
import {Experience} from '../../../interfaces/experience';
import {map} from 'rxjs/operators';
import {AuthGuard} from '../../../_helpers';
import {User} from "../../../interfaces/user";
import {UsersService} from "../../../services/users.service";


@Component({
  selector: 'app-form-template',
  templateUrl: './form-template.component.html',
  styleUrls: ['./form-template.component.scss', '../../../../../assets/customCss/waiting-load.scss']
})
export class FormTemplateComponent implements OnInit {

  source = '';
  content = '';
  waitingLoad = true;
  isFormPage = false; // if is true, display add edit form
  open = false;
  // variable for choose create template
  userId: string; // current user id
  userExperiences: Experience [] = [];
  doneForUserExperiences = false;
  userTemplates: Template [] = [];
  doneForUserTemplates = false;
  publishedTemplates: Template [] = [];
  doneForPublishedTemplates = false;
  // variable for edit page
  currentTemplateId = '';
  template: Template;
  carouselOptions = {
    dots: false,
    autoplay: true,
    autoplayTimeout: 6000,
    nav: true,
    navText: ['<div class=\'nav-btn prev-slide\'></div>', '<div class=\'nav-btn next-slide\'></div>'],
    responsiveClass: true,
    margin: 10,
    // rewind: true,
    responsive: {
      0: {
        items: 1,
        nav: true,
        // loop: true
      },
      1200: {
        items: 2,
        nav: true,
        // loop: true
      },
      1600: {
        items: 3,
        nav: true,
        // loop: true
      },
      1890: {
        items: 4,
        nav: true,
        // loop: true
      }
    }
  };
  user: User;
  doneForUserData = false;

  constructor(private titleService: Title,
              private experiencesService: ExperiencesService,
              private router: Router,
              private notificationService: NotificationService,
              private tokenHelperService: TokenHelperService,
              private authGuard: AuthGuard,
              private route: ActivatedRoute,
              private usersService: UsersService
  ) {
    if (this.router.getCurrentNavigation()) {
      if (this.router.getCurrentNavigation().extras.state) {
        this.source = this.router.getCurrentNavigation().extras.state.source;
        this.content = this.router.getCurrentNavigation().extras.state.idContent;
      }
    } else {
      this.source = history.state.source;
      this.content = history.state.idContent;
    }
  }

  ngOnInit(): void {
    if (this.authGuard.canActivate) {
      this.route.paramMap.pipe(
        map(params => {
          this.currentTemplateId = params.get('id');
          if (this.currentTemplateId && this.currentTemplateId !== '') {
            // edit page
            this.titleService.setTitle(PAGE_TITLE_PREFIX + EDIT_TEMPLATE_PAGE_TITLE);
            this.getProfile();
            this.getTemplateDetails(this.currentTemplateId);
          } else {
            this.getProfile();
            // create page or choose page
            this.openFormPage();
          }
        })
      ).subscribe(() => {
      });
    }
  }

  initValues() {
    this.open = false;
    this.source = '';
    this.content = '';
    this.waitingLoad = true;
    this.doneForUserExperiences = false;
    this.doneForUserTemplates = false;
    this.doneForPublishedTemplates = false;
    this.isFormPage = false;
  }

  getProfile() {
    this.usersService.getUserProfile().subscribe((data: User) => {
      this.user = data;
      this.doneForUserData = true;
      this.waitingLoad = false;
    });
  }

  getTemplateDetails(id) {
    this.experiencesService.getDetailTemplates(id).subscribe((data: Template) => {
      this.template = data;
      this.open = true;
      this.isFormPage = true;
    });
  }

  // choose create template
  getUserExperiences(id) {
    let params = new HttpParams();
    params = params.append(OWNER_EXPERIENCE, id);
    this.experiencesService.getExperiences(params).subscribe((data: Experience[]) => {
      this.userExperiences = data;
      this.doneForUserExperiences = true;
    });
  }

  getUserTemplates(id) {
    let params = new HttpParams();
    params = params.append(OWNER_TEMPLATE, id);
    this.experiencesService.getTemplates(params).subscribe((data: Template[]) => {
      this.userTemplates = data;
      this.doneForUserTemplates = true;
    });
  }

  getPublishedTemplates(id) {
    let params = new HttpParams();
    params = params.append(USER_TEMPLATE, id);
    this.experiencesService.getTemplates(params).subscribe((data: Template[]) => {
      this.publishedTemplates = data;
      this.doneForPublishedTemplates = true;
    });
  }

  openCreateTemplatePage(event) {
    if (event && event.type !== '') {
      if (event.type === BLANK_TEMPLATE) {
        this.content = '';
        this.source = '';
      } else {
        this.content = event.content_id.replace('-', '');
        this.source = event.source;
      }
      this.isFormPage = true;
    }
  }

  // add template
  createData(event) {
    this.waitingLoad = true;
    if (this.currentTemplateId !== '' && this.currentTemplateId !== null) {
      this.sendUpdateRequest(event);
    } else {
      this.sendAddRequest(event);
    }
  }

  createFormData(event) {
    const formData = new FormData();
    if (this.template && this.currentTemplateId) {
      formData.append('status', event.status);
      if (event.mediaIds) {
        const oldMedia = [];
        for (let j = 0; j < event.mediaIds.length; j++) {
          oldMedia.push({id_media: event.mediaIds[j], display_name: event.galleryDisplayName[j]});
        }
        formData.append('oldMediaNames', JSON.stringify(oldMedia));
      }
      if (event.mediaToDelete) {
        formData.append('mediaToDelete', JSON.stringify(event.mediaToDelete));
      }
    }
    formData.append('name', event.name);
    formData.append('description', event.description);
    formData.append('video', event.video);
    formData.append('cover_picture', event.coverPicture);
    formData.append('cover_picture_display_name', event.coverPictureDisplayName);
    formData.append('coverPictureOperation', event.coverPictureOperation);
    const galleryFiles = [];
    const galleryNames = [];
    // TODO: backend is waiting for files_gallery. but we must send files_gallery[]
    if (event.gallery && event.gallery.length > 0) {
      for (let i = 0; i < event.gallery.length; i++) {
        const file = event.gallery[i];
        let displayIndex = i;
        if (event.mediaIds && event.mediaIds.length > 0) {
          displayIndex = i + event.mediaIds.length;
        }
        galleryNames.push({
          index: i,
          filename: file.name,
          display: event.galleryDisplayName[displayIndex]
        });
        galleryFiles.push(event.gallery[i]);
        formData.append('files_gallery', event.gallery[i]);
      }
      formData.append('objects_gallery', JSON.stringify(galleryNames));
    }
    formData.append('content', this.content);
    return formData;
  }

  sendAddRequest(event) {
    let params = new HttpParams();
    params = params.append(SOURCE, this.source);
    this.experiencesService.addTemplate(this.createFormData(event.data), params).subscribe((data: Template) => {
      if (window.innerWidth < 768) {
        this.notificationService.showInfo(CAN_NOT_ALLOWED_OS, CAN_NOT_ALLOWED_OS_TITLE);
        this.router.navigateByUrl(MY_TEMPLATES_PATH).then();
      } else {
        this.router.navigate([EDITOR_TEMPLATE_PATH + '/' + data.id + '/content/' + data.content]).then();
        // issue redmine : 19348 open editor in new tab not activated : blocked by browsers
        // window.open(BaseURLLink + EDITOR_TEMPLATE_PATH + '/' + data.id + '/content/' + data.content, '_blank').focus();

      }
    });
  }

  goBack() {
    this.isFormPage = false;
    this.source = '';
    this.content = '';
    this.openFormPage();
  }

  sendUpdateRequest(event) {
    this.experiencesService.updateTemplate(this.createFormData(event.data), this.currentTemplateId)
      .subscribe((data) => {
        this.router.navigateByUrl(TEMPLATES_PATH + '/' + this.template.id).then();
      }, (error => {
        this.waitingLoad = false;
      }));
  }

  openFormPage() {
    this.titleService.setTitle(PAGE_TITLE_PREFIX + CREATE_TEMPLATE_PAGE_TITLE);
    if ((this.source === '' || this.source === undefined) && !this.isFormPage) { // choose page
      // get user id
      if (this.tokenHelperService.getConnectedUser()) {
        this.userId = this.tokenHelperService.getTokenData().user_id;
        this.getUserExperiences(this.userId);
        this.getUserTemplates(this.userId);
        this.getPublishedTemplates(this.userId);
        this.open = true;
      }
    } else {
      this.open = true;
      this.isFormPage = true;
      this.waitingLoad = false;
    }

  }
}
