import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Group} from '../../interfaces/group';
import {GROUPS_PATH} from '../../../utils/actionsLabels/pathLabels';

@Component({
  selector: 'app-listing-group-item',
  templateUrl: './listing-group-item.component.html',
  styleUrls: ['./listing-group-item.component.scss', '../../../../assets/customCss/listingItem.css']
})
export class ListingGroupItemComponent implements OnInit {
  @Input() data: Group;
  @Input() disabledButton;
  linkDetails = '';
  customStyle = '';
  showMembers = false;
  @Output() groupClickAction: EventEmitter<{ toElement?: string, output?: any}> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    if (this.data.coverPicture !== '') {
      this.customStyle = 'background: url(' + this.data.coverPicture + ') center';
    }
    if (this.data.members > 1) {
      this.showMembers = true;
    }
    this.linkDetails = GROUPS_PATH + '/' + this.data.id;
  }

  askJoinGroup() {
    this.groupClickAction.emit({output: this.data.id});
  }

}
