<section>
  <div class="tz">
    <div class="tz-2">
      <div class="tz-2-com tz-2-main" *ngIf="isOk">
        <h4>{{pageTitle | translate }}</h4>
         <!------------ no published templates --------->
        <div class="tz-2-com tz-2-main" *ngIf="templates.length == 0">
          <div class="center no_data_message">
            <div class="icon-content"><i class="fas fa-cubes"></i></div>
            {{"NO_PUBLISEHD_TEMPLATE_MESSAGE" | translate}}
          </div>
        </div>
        <!------------ end no published templates --------->
        <div class="tz-mess db-list-com tz-db-table" >
          <div *ngIf="templates.length > 9" class="listing-container">
            <app-listing-template-item
              *ngFor="let item of templates   | paginate: { itemsPerPage: 9, currentPage: p , id: 'templatesPage'}"
              [data]="item" >
            </app-listing-template-item>
          </div>
          <div *ngIf="templates.length <= 9" class="listing-container">
            <app-listing-template-item
              *ngFor="let item of templates"
              [data]="item" >
            </app-listing-template-item>
          </div>
        </div>
        <div class="row center-align"  *ngIf="templates.length > 9">
          <pagination-controls (pageChange)="p = $event" id="templatesPage"
                               previousLabel=""
                               nextLabel=""
                               directionLinks="true"
                               autoHide="false">
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>
</section>
