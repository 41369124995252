import {Component, Input, OnInit} from '@angular/core';
import {MY_EXPERIENCES_PATH, MY_GROUPS_PATH} from '../../../../utils/actionsLabels/pathLabels';
import {Experience} from '../../../interfaces/experience';
import {Group} from '../../../interfaces/group';

@Component({
  selector: 'app-general-informations',
  templateUrl: './general-informations.component.html',
  styleUrls: ['./general-informations.component.scss']
})
export class GeneralInformationsComponent implements OnInit {

  @Input() doneForUserExperiences;
  @Input() doneForUserGroups;
  @Input() experiences: Experience[];
  @Input() groups: Group[];
  myGroupsLink;
  myExperiencesLink;

  constructor() {
  }

  ngOnInit(): void {
    this.myExperiencesLink = MY_EXPERIENCES_PATH;
    this.myGroupsLink = MY_GROUPS_PATH;
  }

}
