<div class="v3-list-ql">
  <div class="container">
    <div class="row">
      <div class="v3-list-ql-inn">
        <ul>
          <li *ngFor="let item of menu; let i = index">
            <a (click)="scrollAction(item.scrollAction, i)"
               class="inner_menu link_{{i}}"
               [class.active]="activeSubMenu === item.scrollAction"><i
              class="fa {{item.icon}}"></i> {{item.menuLabel | translate}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
