import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SecondMenuComponent} from '../../editor-menu/second-menu/second-menu.component';
import {Import3DFilesComponent} from '../import3-dfiles/import3-dfiles.component';


@Component({
  selector: 'app-import-tree-dobject-dialog',
  templateUrl: './import-tree-dobject-dialog.component.html',
  styleUrls: ['./import-tree-dobject-dialog.component.scss']
})
export class ImportTreeDObjectDialogComponent implements OnInit, OnDestroy {
  dialogRef1;
  constructor(
    public dialogRef: MatDialogRef<SecondMenuComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
  ) {}

  ngOnDestroy(): void {
    if (this.dialogRef1) { this.dialogRef1.close(); }
  }

  onNoClick(source): void {
    if(source === 'pc') {
      this.dialogRef1 = this.dialog.open(Import3DFilesComponent, {
        width: '500px',
        data: '',
        disableClose: true
      });
      this.dialogRef1.afterClosed().subscribe(result => {
        this.dialogRef.close(result);
      });
    }
  }

  ngOnInit(): void {
  }

}
