import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Group} from '../../interfaces/group';
import {CREATE_GROUP_PATH} from '../../../utils/actionsLabels/pathLabels';

@Component({
  selector: 'app-groups-i-manage',
  templateUrl: './groups-i-manage.component.html',
  styleUrls: ['./groups-i-manage.component.scss']
})
export class GroupsIManageComponent implements OnInit {
  createGroupeUrl = CREATE_GROUP_PATH;
  @Input() groups: Group[];
  @Input() disabledButton;
  @Output() DeleteGroupData = new EventEmitter();
  p = 1;
  constructor() {
  }

  ngOnInit(): void {
  }
  deleteGroupAction(event) {
    this.DeleteGroupData.emit(event);
  }
}
