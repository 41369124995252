import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  RATING_1,
  RATING_2,
  RATING_3,
  RATING_4,
  RATING_5,
  WRITE_REVIEW_ADD,
  WRITE_REVIEW_UPDATE
} from '../../../../utils/actionsLabels/labels';
import {LOGIN_PATH} from '../../../../utils/actionsLabels/pathLabels';
import {User} from '../../../interfaces/user';
import * as $ from 'jquery';
import {ReviewFormData} from '../../../interfaces/review-form-data';

@Component({
  selector: 'app-details-write-review',
  templateUrl: './details-write-review.component.html',
  styleUrls: ['./details-write-review.component.scss']
})
export class DetailsWriteReviewComponent implements OnInit {

  login = LOGIN_PATH;
  ratingValue = 0;
  newReview = WRITE_REVIEW_ADD;
  updateReview = WRITE_REVIEW_UPDATE;
  ratingMsg = [RATING_1, RATING_2, RATING_3, RATING_4, RATING_5];
  model: ReviewFormData;
  typ: any;
  singleReviewRating: any;
  singleReviewReview: any;
  @Output() request = new EventEmitter();
  @Output() cancelEditReviewEvent = new EventEmitter();
  @Input() singleReview;

  constructor() {
  }

  @Input() connectedUser: User;

  ngOnInit(): void {

    if (this.singleReview && this.singleReview.review && this.singleReview.review !== '') {
      this.model = {
        review: this.singleReview.review,
        rating: this.singleReview.rating,
        id: this.singleReview.id
      };
      this.typ = this.updateReview;
      this.ratingValue = this.singleReview.rating;
      this.singleReviewRating = this.singleReview.rating;
      this.singleReviewReview = this.singleReview.review;
    } else {
      this.model = {
        review: '',
        rating: 0,
        id: '0'
      };
      this.typ = this.newReview;
    }
  }

  onSubmit(data) {
    if (this.connectedUser) {
      if (data.value.review.length > 3000){
        data.value.review = data.value.review.substring(0, 3000)
      }
      if (this.model.id !== '0') {
        this.request.emit({
          type: this.updateReview,
          data: {review: data.value.review, rating: this.ratingValue, id: this.model.id}
        });
      } else {
        this.request.emit({
          type: this.newReview,
          data: {review: data.value.review, rating: this.ratingValue}
        });
      }
    }
  }

  ratingValueChange(val) {
    this.ratingValue = val;
    $('label.full').removeClass('coloring-star-clicked');
    for (let i = 1; i <= val; i++) {
      $('#labelStar' + i).addClass('coloring-star-clicked');
    }
  }

  arrayOne(n: number): any[] {
    return Array(n);
  }

  initialColor(test, index) {
    if (test) {
      for (let i = 1; i <= index + 1; i++) {
        $('#labelStar' + i).removeClass('coloring-star');
      }
    } else {
      for (let i = 1; i <= index + 1; i++) {
        $('#labelStar' + i).addClass('coloring-star');
      }
    }
  }

  cancelEditReview() {
    this.cancelEditReviewEvent.emit();
  }

  // On some Android devices, input name allows you to write more than the maximum length
  // Force maxlength input on web mobile
  substrName(data){
    if (data.value.review.length > 3000){
      $('#re_msg').val(data.value.review.substring(0, 3000));
    }
  }
}

