import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Group} from '../../interfaces/group';
import {COMING_SOON} from '../../../utils/actionsLabels/labels';
import {NotificationService} from '../../services/notification.service';
import {GROUPS_PATH} from '../../../utils/actionsLabels/pathLabels';

@Component({
  selector: 'app-suggested-group',
  templateUrl: './suggested-group.component.html',
  styleUrls: ['./suggested-group.component.scss']
})
export class SuggestedGroupComponent implements OnInit {

  link: string;
  @Input() data: Group;
  @Output() groupClickAction: EventEmitter<{ toElement?: string, output?: any}> = new EventEmitter<any>();
  disabled = false;
  showMembers = false;
  constructor(private notificationService: NotificationService) {
  }


  ngOnInit(): void {
    this.link =  GROUPS_PATH  + '/' + this.data.id;
    if (this.data.members > 1) {
      this.showMembers = true;
    }
  }

  discardRequest() {
    this.showNotification();
  }
  showNotification() {
    this.notificationService.showWarning(COMING_SOON, '');
  }

  askJoinGroup() {
    if (this.disabled === false) {
      this.groupClickAction.emit({output: this.data.id});
    }
    this.disabled = true;
  }

}
