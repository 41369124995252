import {Component, Input, OnInit} from '@angular/core';
import {EXPERIENCES_SECTION_TITLE} from '../../../../utils/actionsLabels/labels';
import {Experience} from '../../../interfaces/experience';
import {EXPERIENCES_ALL_PATH_LABEL} from '../../../../utils/actionsLabels/pathLabels';

@Component({
  selector: 'app-experiences-section',
  templateUrl: './experiences-section.component.html',
  styleUrls: ['./experiences-section.component.scss']
})
export class ExperiencesSectionComponent implements OnInit {
  sectionTitle = EXPERIENCES_SECTION_TITLE;
  experiencesLink = EXPERIENCES_ALL_PATH_LABEL;
  @Input() experiences: Experience [];
  @Input() carouselOptions: any;

  constructor() { }

  ngOnInit(): void {
  }

}
