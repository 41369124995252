<div class="tz-3">
  <h4>Notifications(18)</h4>
  <ul>
    <li>
      <a> <img src="assets/images/icon/dbr1.jpg" alt=""/>
        <h5>Joseph, write a review</h5>
        <p>All the Lorem Ipsum generators on the</p>
      </a>
    </li>
    <li>
      <a> <img src="assets/images/icon/dbr2.jpg" alt=""/>
        <h5>14 New Messages</h5>
        <p>All the Lorem Ipsum generators on the</p>
      </a>
    </li>
    <li>
      <a> <img src="assets/images/icon/dbr3.jpg" alt=""/>
        <h5>Ads expairy soon</h5>
        <p>All the Lorem Ipsum generators on the</p>
      </a>
    </li>
    <li>
      <a> <img src="assets/images/icon/dbr4.jpg" alt=""/>
        <h5>Post free ads - today only</h5>
        <p>All the Lorem Ipsum generators on the</p>
      </a>
    </li>
    <li>
      <a> <img src="assets/images/icon/dbr5.jpg" alt=""/>
        <h5>listing limit increase</h5>
        <p>All the Lorem Ipsum generators on the</p>
      </a>
    </li>
    <li>
      <a> <img src="assets/images/icon/dbr6.jpg" alt=""/>
        <h5>mobile app launch</h5>
        <p>All the Lorem Ipsum generators on the</p>
      </a>
    </li>
    <li>
      <a> <img src="assets/images/icon/dbr7.jpg" alt=""/>
        <h5>Setting Updated</h5>
        <p>All the Lorem Ipsum generators on the</p>
      </a>
    </li>
    <li>
      <a> <img src="assets/images/icon/dbr8.jpg" alt=""/>
        <h5>Increase listing viewers</h5>
        <p>All the Lorem Ipsum generators on the</p>
      </a>
    </li>
  </ul>
</div>
