import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';

export interface CanComponentDeactivate {
  confirm();
  loggedIn();
}

@Injectable()
export class DeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  canDeactivate(
    component: CanComponentDeactivate,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (!component.loggedIn()) {
      return true;
    }
    if (!component.confirm()) {
      // no changes add to editor => ok: go to another page / cancel: stay in editor
      return confirm('are you sure you want to leave this page?');
    } else {
      // changes add to editor => please save before leave
      return confirm('You have unsaved changes! If you leave, your changes will be lost.');
    }
  }
}
