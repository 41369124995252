<div class="db-list-com tz-db-table">
  <div class="ds-boar-title in_desktop">
      <h2 class="section-title">{{"Groups I manage"| translate}}</h2>
      <div class="ds-boar-title">
        <div class="button-translate">
          <button type="submit" class="waves-effect waves-light btn-large create-button ">
            <a [routerLink]="createGroupeUrl" class="show_in_desktop right create-group ">
              {{'LABEL_CREATE_NEW_GROUP' | translate}}
            </a>
          </button>
        </div>
      </div>
  </div>
  <div class="tz-mess">
    <div *ngIf="groups.length > 3">
      <ul class="list-container">
        <li class="view-msg" *ngFor="let item of groups  | paginate: { itemsPerPage: 3, currentPage: p}">
          <app-single-group-owner
            [data]="item" [isOwner]="true"
            (deleteGroup)="deleteGroupAction($event)" [disabledButton]="disabledButton"></app-single-group-owner>
        </li>
      </ul>
      <pagination-controls (pageChange)="p = $event"
                           previousLabel=""
                           nextLabel=""
                           directionLinks="true"
                           autoHide="false"></pagination-controls>
    </div>
    <ul *ngIf="groups.length <= 3">
      <li *ngFor="let item of groups">
      <app-single-group-owner [disabledButton]="disabledButton" [data]="item" [isOwner]="true" (deleteGroup)="deleteGroupAction($event)"></app-single-group-owner>
      </li>
    </ul>
  </div>
</div>

