<div class="right-menu" *ngIf="triggers && triggers.length !== 0 && showRightMenu">

  <div class="right-menu-content ">
    <div *ngFor="let element of triggers" class="col-md-12">

      <div class=" part-title">
        <div class="row " (mouseover)="hoverElementMenuRight = element"  [ngClass]="{'hidden-component':element.trigger.visible === false}">

          <i *ngIf="!(element.componentList.length && openedelement.indexOf(element.trigger) >= 0)"
             (click)="openTrigger(element.trigger)"
             class=" icon-action col-md-1 fa fa-angle-right  ">
          </i>
          <i *ngIf="element.componentList.length && openedelement.indexOf(element.trigger) >= 0"
             (click)="closeTrigger(element.trigger)"
             class="icon-action col-md-1 fa fa-angle-down"> </i>
          <h4 class="col-md-7"
              (click)="onSelectTrigger(element.trigger)"
              [ngClass]="{'selected-element' : element.trigger === focusTrigger}">
            {{getTriggerNameInRightMenu(element.trigger.name)}} ({{element.componentList.length}})
          </h4>

          <i  class="col-md-1 fa fa-crosshairs icon-action icon-action-hidden" (click)="zoom(element.trigger)" *ngIf=" element.trigger === focusTrigger ">
            <span class="tooltiptext">{{ 'ZOOM' | translate }}</span>
          </i>
          <i  class="col-md-1 fa fa-eye icon-action icon-action-hidden" (click)="hideTrigger(element.trigger)" *ngIf="element.trigger.visible === true && element.trigger === focusTrigger && element.trigger.type != geoType">
            <span class="tooltiptext">{{ 'HIDE' | translate }}</span>
          </i>
          <i  class="col-md-1 fa fa-eye-slash icon-action " (click)="showTrigger(element.trigger)" *ngIf=" element.trigger.visible === false && element.trigger === focusTrigger && element.trigger.type != geoType ">
            <span class="tooltiptext">{{ 'SHOW' | translate }}</span>
          </i>
          <i  class="col-md-1 fa fa-trash icon-action icon-action-hidden" (click)="delete(element.trigger)" *ngIf=" element.trigger === focusTrigger && element.trigger.type !== surface">
            <span class="tooltiptext">{{ 'DELETE' | translate }}</span>
          </i>

          <i  class="col-md-1 fa fa-crosshairs icons icon-action icon-action-hidden" (click)="zoom(element.trigger)" *ngIf=" !(element.trigger === focusTrigger) && element === hoverElementMenuRight ">
            <span class="tooltiptext">{{ 'ZOOM' | translate }}</span>
          </i>
          <i  class="col-md-1 fa fa-eye icons icon-action icon-action-hidden" (click)="hideTrigger(element.trigger)" *ngIf=" element.trigger.visible === true &&!(element.trigger === focusTrigger) && element === hoverElementMenuRight && element.trigger.type != geoType ">
            <span class="tooltiptext">{{ 'HIDE' | translate }}</span>
          </i>
          <i  class="col-md-1 fa fa-eye-slash icons icon-action " (click)="showTrigger(element.trigger)" *ngIf=" element.trigger.visible === false &&!(element.trigger === focusTrigger) && element === hoverElementMenuRight && element.trigger.type != geoType ">
            <span class="tooltiptext">{{ 'SHOW' | translate }}</span>
          </i>
          <i  class="col-md-1 fa fa-trash icons icon-action icon-action-hidden" (click)="delete(element.trigger)" *ngIf=" !(element.trigger === focusTrigger) && element === hoverElementMenuRight && element.trigger.type !== surface">
            <span class="tooltiptext">{{ 'DELETE' | translate }}</span>
          </i>

        </div>
        <hr class="col-md-11">
      </div>

      <div class="part-content col-md-offset-1"
           id="{{triggers.indexOf(element).toString()}}"
           cdkDropList

           (cdkDropListDropped)="drop($event)"
           [cdkDropListData]="element.componentList"
           [cdkDropListConnectedTo]="range(triggers.indexOf(element))">
        <div *ngIf="(element.componentList.length && openedelement.indexOf(element.trigger) >= 0)">
          <ul *ngFor="let component of element.componentList" cdkDragBoundary=".right-menu-content" cdkDrag>
            <br>
            <li class=" row "
                [ngClass]="{'selected-component' : component === focusComponent, 'hidden-component':component.visible === false}"
                (mouseover)="hoverComponentMenuRight = component"
            >
              <i class="col-md-1 fa fa-edit" *ngIf="component.type === 'text'"></i>
              <i class="col-md-1 far fa-hand-point-up " *ngIf="component.type === 'button'"></i>
              <i class="col-md-1 fa fa-music" *ngIf="component.type === 'audio'"></i>
              <i class="col-md-1 fa fa-youtube" *ngIf="component.type === 'video'"></i>
              <i class="col-md-1 fa fa-cube" *ngIf="component.type === 'object'"></i>
              <i class="col-md-1 fa fa-image" *ngIf="component.type === 'image'"></i>

              <span (click)="onSelectComponent(component)" class="col-md-6">{{getComponentNameInRightMenu(component.name)}}</span>
              <div *ngIf="component.type !== 'audio'">
                <i  class="col-md-1 fa fa-crosshairs icon-action icon-action-hidden"  (click)="zoom(component)" *ngIf=" component === focusComponent ">
                  <span class="tooltiptext">{{ 'ZOOM' | translate }}</span>
                </i>
                <i  class="col-md-1 fa fa-eye icon-action icon-action-hidden"  (click)="hideComponent(component)" *ngIf="component.visible === true &&  component === focusComponent && element.trigger.type != geoType">
                  <span class="tooltiptext">{{ 'HIDE' | translate }}</span>
                </i>
                <i  class="col-md-1 fa fa-eye-slash icon-action"  (click)="showComponent(component)" *ngIf="component.visible === false &&  component === focusComponent && element.trigger.type != geoType">
                  <span class="tooltiptext">{{ 'SHOW' | translate }}</span>
                </i>
                <i  class="col-md-1 fa fa-trash icon-action icon-action-hidden" (click)="delete(component)" *ngIf=" component === focusComponent">
                  <span class="tooltiptext">{{ 'DELETE' | translate }}</span>
                </i>

                <i  class="col-md-1 fa fa-crosshairs icons icon-action icon-action-hidden"  (click)="zoom(component)" *ngIf="!(component === focusComponent) && component === hoverComponentMenuRight ">
                  <span class="tooltiptext">{{ 'ZOOM' | translate }}</span>
                </i>
                <i  class="col-md-1 fa fa-eye icons icon-action icon-action-hidden" (click)="hideComponent(component)" *ngIf="component.visible === true && !(component === focusComponent) && component === hoverComponentMenuRight && element.trigger.type != geoType">
                  <span class="tooltiptext">{{ 'HIDE' | translate }}</span>
                </i>
                <i  class="col-md-1 fa fa-eye-slash icons icon-action " (click)="showComponent(component)" *ngIf="component.visible === false && !(component === focusComponent) && component === hoverComponentMenuRight && element.trigger.type != geoType">
                  <span class="tooltiptext">{{ 'SHOW' | translate }}</span>
                </i>
                <i  class="col-md-1 fa fa-trash icons icon-action icon-action-hidden" (click)="delete(component)"*ngIf=" !(component === focusComponent) && component === hoverComponentMenuRight">
                  <span class="tooltiptext">{{ 'DELETE' | translate }}</span>
                </i>
              </div>
              <div *ngIf="component.type === 'audio'">
                <i  class="col-md-1  " ></i>
                <i  class="col-md-1  " ></i>

                <i  class="col-md-1 fa fa-trash icon-action center icon-action-hidden" (click)="delete(component)" *ngIf=" component === focusComponent">
                  <span class="tooltiptext">{{ 'DELETE' | translate }}</span>
                </i>
                <i  class="col-md-1  fa fa-trash icons icon-action center icon-action-hidden" (click)="delete(component)"*ngIf=" !(component === focusComponent) && component === hoverComponentMenuRight">
                  <span class="tooltiptext">{{ 'DELETE' | translate }}</span>
                </i>
              </div>
            </li>
          </ul>
        </div>
        <ul  *ngIf="!(element.componentList.length && openedelement.indexOf(element.trigger) >= 0)"><li class="hide-element"></li></ul>
      </div>
    </div>
  </div>



  <button class="close-button" (click)="showRightMenu = !showRightMenu"> <i class="fa fa-times"></i> {{'Close' | translate}}</button>
</div>
<button *ngIf="triggers && triggers.length !== 0 && !showRightMenu" class="show-button" (click)="showRightMenu = !showRightMenu">
  <i class="fa fa-bars fa-2x"></i>
</button>

