<section class="tz-register">
  <div class="log-in-pop">
    <div class="log-in-pop-left inDesktop">
      <h1>{{"HELLO" | translate}} !</h1>
      <br>
      <h4>{{"Log in with social media" | translate}}</h4>
      <br>
      <ul>
        <li><a (click)="showMessage()">
          <div class="social-icon"><img src="assets/images/facebook_logo.png" width="17" alt="" /></div>
          {{"Log in with Facebook" | translate}}</a> </li>
        <li><a (click)="showMessage()">
          <div class="social-icon"><img src="assets/images/google_logo.png" width="16" alt="" /></div>
          {{"Log in with Google" | translate}}</a> </li>
        <li><a (click)="showMessage()">
          <div class="social-icon"><i class="fa fa-twitter tw1"></i></div>
          {{"Log in with Twitter" | translate}}</a> </li>
      </ul>
    </div>
    <div class="log-in-pop-right">
      <h4>{{"Forgot your password ?" | translate}}</h4>
      <br>
      <p>{{"ENTER_EMAIL_TO_RESET_PASSWORD" | translate}}</p>
      <form class="s12" [formGroup]="forgetPasswordForm" (ngSubmit)="onSubmit(forgetPasswordForm.value)">
        <div class="form-group input-field s12">
          <label for="email">{{'EMAIL' | translate}}</label>
          <input [class.invalid]="forgetPasswordForm.get('email').invalid && forgetPasswordForm.get('email').touched"
                 type="email" class="form-control" formControlName="email" id="email"/>

          <div *ngIf="forgetPasswordForm.controls['email'].invalid && forgetPasswordForm.controls['email'].touched"
               class="alert alert-danger">
            <div *ngIf="forgetPasswordForm.controls['email'].errors.required">
              {{'Please enter your email!' | translate}}
            </div>
            <div *ngIf="forgetPasswordForm.controls['email'].errors.pattern">
              {{'Please enter a valid email!' | translate}}
            </div>
          </div>
        </div>

        <div class="input-field s4 waves-effect waves-light log-in-btn"
             [ngClass]="{'disabledSubmit': forgetPasswordForm.invalid}" >
          <input type="submit" [disabled]="forgetPasswordForm.invalid"  value="Send">
        </div>
        <div>
          <div class="input-field s12"><a [routerLink]="[loginLink]" class="login">{{'Login' | translate}}</a></div>
        </div>
      </form>
    </div>
    <div class="log-in-pop-left inMobile" >
      <h4>{{"Log in with social media" | translate}}</h4>
      <br>
      <ul>
        <li><a (click)="showMessage()">
          <div class="social-icon"><img src="assets/images/facebook_logo.png" width="17" alt="" /></div>
          {{"Log in with Facebook" | translate}}</a> </li>
        <li><a (click)="showMessage()">
          <div class="social-icon"><img src="assets/images/google_logo.png" width="16" alt=""/></div>
          {{"Log in with Google" | translate}}</a> </li>
        <li><a (click)="showMessage()">
          <div class="social-icon"><i class="fa fa-twitter tw1"></i></div>
          {{"Log in with Twitter" | translate}}</a> </li>
      </ul>
    </div>
  </div>
</section>
