import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Group} from '../../../interfaces/group';

@Component({
  selector: 'app-group-details-delete-group',
  templateUrl: './group-details-delete-group.component.html',
  styleUrls: ['./group-details-delete-group.component.scss']
})
export class GroupDetailsDeleteGroupComponent implements OnInit {
  @Input() data: Group;
  @Output() GroupData = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  deleteGroup() {
    this.GroupData.emit({id: this.data.id, name: this.data.name});
  }
}
