import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TEMPLATES_PATH} from '../../../utils/actionsLabels/pathLabels';
import {DELETE_GROUP_FROM_FAVORITES,} from '../../../utils/actionsLabels/labels';
import {GroupFavorite} from '../../interfaces/GroupFavorite';

@Component({
  selector: 'app-listing-group-favorite-item',
  templateUrl: './listing-group-favorite-item.component.html',
  styleUrls: ['./listing-group-favorite-item.component.scss']
})
export class ListingGroupFavoriteItemComponent implements OnInit {

  @Input() data: GroupFavorite;
  @Input() disabledButton;
  customStyle = '';
  linkDetails = '';
  @Output() groupAction = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
      if (this.data.coverPicture !== '') {
        this.customStyle = 'background: url(' + this.data.coverPicture + ') center no-repeat';
      }
      this.linkDetails = TEMPLATES_PATH + '/' + this.data.group_id;
  }

  applyAction(id) {
    this.groupAction.emit({data: DELETE_GROUP_FROM_FAVORITES, id:id});
  }

}
