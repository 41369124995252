import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ExperienceVideo} from '../../../interfaces/experience-video';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as $ from 'jquery';
@Component({
  selector: 'app-popup-experience-video',
  templateUrl: './popup-experience-video.component.html',
  styleUrls: ['./popup-experience-video.component.scss']
})
export class PopupExperienceVideoComponent implements OnInit {


  @Input() isOpened: boolean;
  @Input() data: ExperienceVideo;
  @Output() readingStatus = new EventEmitter();

  name = 'Set iframe source';
  urlSafe: SafeResourceUrl;

  constructor(public sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    const urlSafe ='https://www.youtube.com/embed/'+ this.data.video.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop() ;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(urlSafe);
  }

  closePopup() {
    this.isOpened = false;
    this.readingStatus.emit({videoStatus: false});
    this.urlSafe = '';
    const urlSafe ='https://www.youtube.com/embed/'+ this.data.video.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop() ;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(urlSafe);
  }
}
