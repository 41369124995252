<section>
  <div class="tz">
    <div class="tz-2">
      <div class="tz-2-com tz-2-main">
        <h4>{{pageTitle | translate }}</h4>
        <!------------ no suggested groups --------->
        <div class="tz-2-com tz-2-main" *ngIf="doneForSuggestedGroups && groups.length == 0">
          <div class="center no_data_message">
            <div class="icon-content"><i class="fas fa-cubes"></i></div>
            {{"no_suggested_groups_message" | translate}}
          </div>
        </div>
        <!------------ end no suggested groups --------->
        <div *ngIf="doneForSuggestedGroups && groups.length != 0" class="tz-mess db-list-com tz-db-table">
          <ul *ngIf="groups.length > 9">
            <app-suggested-group-item
              *ngFor="let item of groups   | paginate: { itemsPerPage: 9, currentPage: p , id: 'groupsPage'}"
              [data]="item" (askJoinClickAction)="onItemGroupClick($event)">
            </app-suggested-group-item>
          </ul>
          <ul *ngIf="groups.length <= 9">
            <app-suggested-group-item
              *ngFor="let item of groups"
              [data]="item" (askJoinClickAction)="onItemGroupClick($event)">
            </app-suggested-group-item>
          </ul>
        </div>
        <div class="row center-align"  *ngIf="groups.length > 9">
          <pagination-controls (pageChange)="p = $event" id="groupsPage"
                               previousLabel=""
                               nextLabel=""
                               directionLinks="true"
                               autoHide="false">
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>
</section>
