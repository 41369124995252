<div class="group-item" >
  <div class="list-left-near lln1 avatar-container">
    <img *ngIf="data.avatar && data.avatar != ''" src="{{data.avatar}}" alt="{{data.name}}">
  </div>
  <div class="list-left-near lln2">
    <a [routerLink]="link" title="{{data.name}}"><h5 class="ellipsis-style">{{data.name}}</h5></a>
    <p class="ellipsis-style-description">{{data.description}}</p>
    <p class="info-members" *ngIf="showMembers"><span>{{data.members}} {{"members" | translate}}</span></p>
    <span class="custom-btn"  (click)="askJoinGroup()">
      {{'JOIN_GROUP' | translate}}
    </span>
  </div>
</div>
