import {Component, OnInit} from '@angular/core';
import {COMING_SOON} from '../../../../utils/actionsLabels/labels';
import {NotificationService} from '../../../services/notification.service';

@Component({
  selector: 'app-group-details-contact-admins',
  templateUrl: './group-details-contact-admins.component.html',
  styleUrls: ['./group-details-contact-admins.component.scss']
})
export class GroupDetailsContactAdminsComponent implements OnInit {

  constructor(private notificationService: NotificationService) {
  }

  ngOnInit(): void {
  }

  comingSoonAction() {
    this.notificationService.showWarning('', COMING_SOON);
  }
}
