<section>
  <div class="tz">
    <div class="tz-2">
      <div class="tz-2-com tz-2-main">
        <h4>{{title | translate}}</h4>
        <div class="db-list-com tz-db-table" *ngIf="!group">
          <!----- GO_TO_PLAY_STORE ------->
          <h3 *ngIf="content=='' && downloadApp">Please download the application from <a href="https://play.google.com/store/apps?hl=fr" target="_blank">Here! </a></h3>
          <!----- end GO_TO_PLAY_STORE ------->
          <h3 *ngIf="content!=''">{{content | translate}}</h3>
        </div>
        <!----- group invitation/request ------->
        <div class="db-list-com tz-db-table" *ngIf="group">
          <h3 >{{content | translate}}</h3>
          <br>
          <h3 *ngIf="showGroupLink && !isClosedGroup"> You can visit the group from <a [routerLink]="link">Here! </a></h3>
          <h3 *ngIf="!showGroupLink && isClosedGroup"> You have been invited to a closed group.</h3>
           <br *ngIf="showGroupLink">
           <div class="row actions-popup" *ngIf="showButton">
             <button class="button  waves-effect waves-light btn-large save-popup-style" [class.isDisabled]="disabledButton"
                     (click)="acceptMemberAction()">{{'ACCEPT' | translate}}</button>
             <button class="button waves-effect waves-light btn-large"
                     (click)="refuseMemberAction()" [class.isDisabled]="disabledButton" >{{'REFUSE' | translate}}</button>
           </div>
        </div>
        <!----- end group invitation/request ------->
      </div>
    </div>
  </div>
</section>
