import {Component, OnInit} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {TokenHelperService} from '../../reusables/services/token-helper.service';
import {ExperiencesService} from '../../reusables/services/experiences.service';
import {ALL_TEMPLATES_PAGE_TITLE, PAGE_TITLE_PREFIX} from '../../utils/actionsLabels/labels';
import {Template} from '../../reusables/interfaces/templates';

@Component({
  selector: 'app-all-templates-page',
  templateUrl: './all-templates-page.component.html',
  styleUrls: ['./all-templates-page.component.scss', '../../../assets/customCss/listingPages.css']
})
export class AllTemplatesPageComponent implements OnInit {

  pageTitle = ALL_TEMPLATES_PAGE_TITLE;
  isLogged = false;
  isOk = false;
  userId: string;
  templates: Template[];
  p = 1; // initial page in pagination of templates
  constructor(private titleService: Title,
              private tokenHelperService: TokenHelperService,
              private experiencesService: ExperiencesService) { }

  ngOnInit(): void {
    const params = new HttpParams();
    this.titleService.setTitle(PAGE_TITLE_PREFIX + this.pageTitle);
    this.experiencesService.getTemplates(params).subscribe((data: Template[]) => {
      this.templates = data;
      this.isOk = true;
    });
  }

}
