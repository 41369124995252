<section *ngIf="doneForUserProfile">
  <div class="tz">
    <div class="tz-l">
      <app-profile-picture [user]="user"></app-profile-picture>
      <app-account-menu [user]="user"></app-account-menu>
    </div>
    <div class="tz-2">
      <app-general-informations [experiences]="experiences" [groups]="groups"
                                [doneForUserExperiences]="doneForUserExperiences"
                                [doneForUserGroups] = "doneForUserGroups"></app-general-informations>
    </div>
<!--    <app-notifications></app-notifications>-->
  </div>
</section>
