<section>
  <div class="tz">
    <app-experiences-section
      *ngIf="doneForExperiences"
      [experiences]="experiences"
      [carouselOptions]="carouselOptions">
    </app-experiences-section>
    <app-groups-section *ngIf="doneForGroups" [groups]="groups" [noSuggestedGroup]="noSuggestedGroup"
                        [disabledButton]="disabledButton" [carouselOptions]="carouselOptions"
                        (joinItemClick)="joinItemGroupClick($event)"></app-groups-section>
    <app-templates-section *ngIf="doneForTemplates" [templates]="templates" [carouselOptions]="carouselOptions">
    </app-templates-section>
  </div>
</section>
