/*************************** Media *********************************/
/*
input = nbrMarkerCreated = div * 3 + mod
position des markers dans la scene =    3   1   2
                                        6   4   5
                                        9   7   8 ...
position = (x,y,z) => { x = 1300 * mod
                        y = 100
                        z = div * 1000 ( si mod=0 => div=div-1)  z0=2000
taille marker = 1000*500
*/

// position du 1er marker
import {EditorElementType} from '../../utils/global-constants/editor-const';

const x0 = 0;
const y0 = 100;
const z0 = 500;
// distance entre les markers
const dx = 2000;
const dy = 0;
const dz = 1000;
// espace réservé à un marker
const lmx = 1000;
const lmy = 500;
const lmz = 900;
const lmcx = 1000;
const lmcy = 500;
const lmcz = 50;

// position des composants par rapport au marker
const cx = 250;
const cy = 100;
const cz = 125;
// espace réservé à un composant (marker)
const lcx = 100;
const lcy = 100;
const lcz = 100;

// position des composants par rapport au marker
const cxs = 200;
const cys = 100;
const czs = 150;
// espace réservé à un composant (marker)
const lcxs = 130;
const lcys = 130;
const lczs = 130;


export function getMarkerPosition(tab) {
  let exist = true;
  let nbrMarkerCreated = 0;
  let position;
  while (exist === true) {
    nbrMarkerCreated ++;
    position = markerPosition(nbrMarkerCreated);
    exist = usedMarkerPosition(position, tab);
  }
  return position;
}
export function markerPosition(nbrMarkerCreated) {
  const mod = nbrMarkerCreated % 3;
  let div = (nbrMarkerCreated - mod) / 3;
  let X;
  switch (mod) {
    case 1:
      X = x0;
      break;
    case 2:
      X = dx;
      break;
    case 0:
      X = -dx;
      div = div - 1;
  }
  const Z = z0 - dz * div;
  return {x: X, y: y0, z: Z};
}
export function usedMarkerPosition(position, tab): boolean {
  let exist = false;
  let n = 0;
  while (!exist && n < tab.length) {
    const element = tab[n];
    /********** marker ************/
    if (
      position.x - lmx <= element.trigger.position.x  &&
      element.trigger.position.x <= position.x + lmx  &&

      position.z - lmz <= element.trigger.position.z  &&
      element.trigger.position.z <= position.z + lmz  &&

      position.y - lmy <= element.trigger.position.y  &&
      element.trigger.position.y <= position.y + lmy
    ) { exist = true; }

    /*********** component ********/
    let i = 0;
    while (!exist && i < element.componentList.length) {
      const comp = element.componentList[i];
      if (comp.position &&
        position.x - lmcx <= comp.position.x  &&
        comp.position.x <= position.x + lmcx  &&

        position.z - lmcz <= comp.position.z  &&
        comp.position.z <= position.z + lmcz   &&

        position.y - lmcy <= comp.position.y  &&
        comp.position.y <= position.y + lmcy
      ) { exist = true; }
      i++;
    }

    n++;
  }
  return exist;
}

/*************************** composant *********************************/
/*
input = nbrCompCreated = div * 4 + mod
position des comp / marker  =    1 2   5 6   9 10
                                 3 4   7 8   11...
position = (x,y,z) => {
        x =  si  (nbrCompCreated mod 2 === 0) => x = marker.x + 250   sinon  x = marker.x - 250
        y =  si  (nbrCompCreated mod 4 === 1 ou 2) => y = marker.y + 125   sinon   y = marker.y - 125
        z = marker.z - div * 100 ( si mod=0 => div=div-1)
        }
*/
export function getCompPosition(tab, mPosition, triggerType) {
  if ( triggerType === EditorElementType.marker.toString() || triggerType === EditorElementType.geo.toString()) {
    return getCompPositionMarker(tab, mPosition);
  } else {
    return getCompPositionSurface(tab, mPosition);
  }
}
// *************************** triggerType === marker *********************************/
export function getCompPositionMarker(tab, mPosition) {
  let exist = true;
  let nbrCompCreated = 0;
  let position;
  while (exist === true) {
    nbrCompCreated ++;
    position = compPositionMarker(nbrCompCreated, mPosition);
    exist = usedCompPositionMarker(position, tab);
  }
  return position;
}
export function compPositionMarker(nbrCompCreated , mPosition) {
  const mod4 = nbrCompCreated % 4;
  const mod2 = nbrCompCreated % 2;
  let div4 = (nbrCompCreated - mod4) / 4 + 1;
  let X;
  let Z;
  switch (mod2) {
    case 1:
      X = mPosition.x - cx;
      break;
    case 0:
      X = mPosition.x + cx;
      break;
  }
  switch (mod4) {
    case 1:
      Z = mPosition.z + cz;
      break;
    case 2:
      Z = mPosition.z + cz;
      break;
    case 3:
      Z = mPosition.z - cz;
      break;
    case 0:
      Z = mPosition.z - cz;
      div4 = div4 - 1;
      break;
  }
  const Y = mPosition.y + cy + cy * div4;
  return {x: X, y: Y, z: Z};
}
export function usedCompPositionMarker(position, tab): boolean {
  let exist = false;
  let n = 0;
  while (!exist && n < tab.length) {
    const element = tab[n];
    if (element.position &&
      position.x - lcx <= element.position.x  &&
      element.position.x <= position.x + lcx  &&

      position.z - lcz <= element.position.z  &&
      element.position.z <= position.z + lcz  &&

      position.y - lcy <= element.position.y  &&
      element.position.y <= position.y + lcy
    ) { exist = true; }
    n++;
  }
  return exist;
}
// *************************** end triggerType === marker *********************************/

// *************************** triggerType === surface *********************************/
export function getCompPositionSurface(tab, mPosition) {
  let exist = true;
  let nbrCompCreated = 0;
  let position;
  while (exist === true) {
    nbrCompCreated ++;
    position = compPositionSurface(nbrCompCreated, mPosition);
    exist = usedCompPositionSurface(position, tab);
  }
  return position;
}
export function compPositionSurface(nbrCompCreated , mPosition) {
  const mod16 = nbrCompCreated % 16;
  const mod4 = nbrCompCreated % 4;
  const div16 = (nbrCompCreated - mod16) / 16 + 1;
  let X;
  let Y;
  let Z;
  switch (mod4) {
    case 1:
      X = mPosition.x - cxs;
      break;
    case 2:
      X = mPosition.x + cxs;
      break;
    case 3:
      X = mPosition.x + 3 * cxs;
      break;
    case 0:
      X = mPosition.x - 3 * cxs;
      break;
  }
  if (mod16 <= 4) {Z = mPosition.z + czs; }
  if (5 <= mod16 && mod16 <= 8) {Z = mPosition.z - czs; }
  if (9 <= mod16 && mod16 <= 12) {Z = mPosition.z - 3 * czs; }
  if (mod16 >= 13 || mod16 === 0) {Z = mPosition.z + 3 * czs; }

  if (mod16 === 0) {Y = mPosition.y + cys * div16 ; } else { Y = mPosition.y + cys * (1 + div16); }
  return {x: X, y: Y, z: Z};
}
export function usedCompPositionSurface(position, tab): boolean {
  let exist = false;
  let n = 0;
  while (!exist && n < tab.length) {
    const element = tab[n];
    if (element.position &&
      position.x - lcxs <= element.position.x  &&
      element.position.x <= position.x + lcxs  &&

      position.z - lczs <= element.position.z  &&
      element.position.z <= position.z + lczs  &&

      position.y - lcys <= element.position.y  &&
      element.position.y <= position.y + lcys
    ) { exist = true; }
    n++;
  }
  return exist;
}
// *************************** end triggerType === surface *********************************/

/************************* clone marker's component ******************/
export function getCloneCompPosition(compPos, mPos, selectedMarkerPos) {
  const X = selectedMarkerPos.x + (compPos.x - mPos.x);
  const Y = selectedMarkerPos.y + (compPos.y - mPos.y);
  const Z = selectedMarkerPos.z + (compPos.z - mPos.z);
  return {x: X, y: Y, z: Z};
}
