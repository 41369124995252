<div class="tz-2">
  <div class="tz-2-com tz-2-main">
    <h4>{{pageTitle | translate }}</h4>
    <div class="row waiting-get-data " *ngIf="!getGroupIAmMemberResponse || !getGroupIManageResponse" >
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
    <div *ngIf="getGroupIAmMemberResponse && getGroupIManageResponse">
      <app-groups-i-manage [groups]="groupsIManage" [disabledButton]="disabledButton"
                           (DeleteGroupData)="DeleteGroup($event)"
                           *ngIf="doneGroupsIManage && groupsIManage.length > 0"></app-groups-i-manage>
      <app-create-first-group  *ngIf="doneGroupsIManage && groupsIManage.length == 0"></app-create-first-group>
      <app-groups-i-am-in [data]="groupsIAmMember" [disabledButton]="disabledButton"
                          (leaveGroupData)="leaveGroup($event)"
                          *ngIf="doneGroupsIAmMember && groupsIAmMember.length > 0"></app-groups-i-am-in>
      <app-join-first-group *ngIf="doneGroupsIAmMember && groupsIAmMember.length == 0"></app-join-first-group>
    </div>
  </div>
</div>
