<div class="tz-l-1">
  <ul>
    <li>
      <div class="profile-picture-content">
        <img *ngIf="user.profilePicture && user.profilePicture != ''" src="{{user.profilePicture}}" alt=""/>
        <div *ngIf="!user.profilePicture && user.profilePicture == ''"> </div>
      </div>
    </li>
<!--    <li><span>100</span> {{'LIKES' | translate}}</li>-->
<!--    <li><span>18</span> {{'NOTIFICATIONS' | translate}}</li>-->
  </ul>
</div>
