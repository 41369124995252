<div class="tz-2">
  <div class="tz-2-com tz-2-main">
    <h4>{{pageTitle | translate }}</h4>
    <div class="row waiting-get-data "
         *ngIf="showMyGroup===false || showUsers===false || (showMembers===false && noMembers===false)">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>

    <div class="db-list-com tz-db-table">
      <div class="hom-cre-acc-left hom-cre-acc-right">
        <div class="ds-boar-title">
          <form [formGroup]="formManageMembers" (ngSubmit)="onSubmit(formManageMembers.value)"
                *ngIf="showMyGroup===true && showUsers===true && (showMembers===true || noMembers=== true)">
            <!-------------------------------- add members section -------------------------------------------->
                        <!----------- add members title ------------------------>
            <div class="row">
            <div class="db-v2-list-form-inn-tit">
              <h5><span>{{groupName}} </span>{{"Add Members" | translate}}</h5>
            </div>
            </div>
                        <!----------- add members select ------------------------>
            <div class="row">
              <div class="input-field col s12">
                <div class="select-members">
                  <ng-select [items]="allUsers"
                             bindLabel="member_name"
                             bindValue="member_id"
                             (change)="onChange($event)"
                             (search)="onSearch($event)"
                             [multiple]="true"
                             [clearable]="true"
                             formControlName="addedMembers"
                             [(ngModel)]="selectedIds">
                  </ng-select>
                </div>
              </div>
            </div>
            <br>
                           <!----------- add members list ------------------------>
            <div class="input-field col s12 " *ngIf="addedMembers && addedMembers.length > 0">
              <div class="thumbnail-content" *ngFor="let addedMember of addedMembers; let i = index;">
                <div *ngIf="!screenSizeChanged">
                  <div class="item-number inline">{{i + 1}}</div>
                  <div class="img-thumbnail-content inline">
                    <img *ngIf="addedMember.profilePicture && addedMember.profilePicture != ''"
                         src="{{addedMember.profilePicture}}" height="45"/>
                    <div *ngIf="!addedMember.profilePicture && addedMember.profilePicture == ''"> </div>

                  </div>
                  <input type="text" value="{{addedMember.member_name}}" disabled class="inline"/>
                  <div >
                    <mat-select (selectionChange)="changeMemberRole($event, addedMember.member_id)"
                                value="{{groupRoles[1].value}}" style="width: 120px">
                      <mat-option value="{{groupRoles[1].value}}">
                        {{groupRoles[1].htmlValue | translate}}
                      </mat-option>
                      <mat-option disabled="disabled" value="{{groupRoles[0].value}}">
                        {{groupRoles[0].htmlValue  | translate}}
                      </mat-option>
                    </mat-select>
                  </div>
                  <button type="button"
                          class="btn btn-add inline"
                          (click)="deleteMemberFromList(addedMember)"
                          title="{{'DELETE_MEMBER' | translate}}"><i class="fas fa-times"></i></button>
                </div>

                <div *ngIf="screenSizeChanged">
                  <div style="overflow: auto">
                    <div class="item-number inline">{{i + 1}}</div>
                    <div class="img-thumbnail-content inline">
                      <img src="{{addedMember.profilePicture}}" height="45"/>
                      <div *ngIf="!addedMember.profilePicture && addedMember.profilePicture == ''"> </div>
                    </div>
                    <button class="btn btn-add  left" (click)="deleteMemberFromList(addedMember)"
                            title="{{'DELETE_MEMBER' | translate}}"><i class="fas fa-times"></i></button>

                  </div>
                  <div class="left"><input type="text" value="{{addedMember.member_name}}" disabled/></div>
                  <div class="inline custom-select" style="width:120px;">
                    <mat-select (selectionChange)="changeMemberRole($event, addedMember.member_id)"
                                value="{{groupRoles[1].value}}" style="width: 120px">
                      <mat-option value="{{groupRoles[1].value}}">
                        {{groupRoles[1].htmlValue | translate}}
                      </mat-option>
                      <mat-option disabled="disabled" value="{{groupRoles[0].value}}">
                        {{groupRoles[0].htmlValue  | translate}}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
              </div>
            </div>
                                <!----------- End add members list ------------------------>
            <br>
            <br>
            <br>
          <!-------------------------------- delete members section -------------------------------------------->
                                  <!----------- delete members title ------------------------>
            <div class="row">
            <div class="db-v2-list-form-inn-tit">
              <h5><span>{{groupName}} </span>{{"Members" | translate}}</h5>
            </div>
            </div>
                           <!----------- delete members list ------------------------>
            <div class="input-field col s12 " *ngIf="members && members.length > 0 && noMembers===false">
              <div class="thumbnail-content" *ngFor="let member of members; let i = index;">
                <div *ngIf="!screenSizeChanged">
                  <div class="item-number inline">{{i + 1}}</div>
                  <div class="img-thumbnail-content inline">
                    <img *ngIf="member.profilePicture && member.profilePicture != ''"
                         src="{{member.profilePicture}}" height="45"/>
                    <div *ngIf="!member.profilePicture && member.profilePicture== ''"> </div>
                  </div>
                  <input type="text" value="{{member.member_name}}" disabled class="inline"/>
                  <div >
                    <mat-select (selectionChange)="changeMemberRole($event, member.member_id)"
                                value="{{member.group_role}}" style="width: 120px">
                      <mat-option value="{{groupRoles[1].value}}">
                        {{groupRoles[1].htmlValue | translate}}
                      </mat-option>
                      <mat-option disabled="disabled" value="{{groupRoles[0].value}}">
                        {{groupRoles[0].htmlValue  | translate}}
                      </mat-option>
                    </mat-select>
                  </div>
                  <div [ngClass]="{'selected-member': member ===  selectedToBeDeleted(member)}">
                    <div *ngIf="selectedToBeDeleted(member) === false || !selectedToBeDeleted(member)">
                        <button class="btn btn-danger inline delete"
                                (click)="deleteMember(member)"
                                title="{{'DELETE_MEMBER' | translate}}"><i class="fa fa-trash"></i></button>
                    </div>
                    <div *ngIf="selectedToBeDeleted(member)">
                         <button class="btn inline cancel"
                                 (click)="cancelSelect(member)"
                                 title="{{'CANCEL_DELETE_MEMBER' | translate}}"><i class="fa fa-trash"></i></button>
                    </div>
                  </div>
                </div>

                <div *ngIf="screenSizeChanged">
                  <div style="overflow: auto">
                    <div class="item-number inline">{{i + 1}}</div>
                    <div class="img-thumbnail-content inline">
                      <img src="{{member.profilePicture}}" height="45"/>
                      <div *ngIf="!member.profilePicture && member.profilePicture== ''"> </div>
                    </div>
                    <div [ngClass]="{'selected-member': member ===  selectedToBeDeleted(member)}">
                      <div *ngIf="selectedToBeDeleted(member) === false || !selectedToBeDeleted(member)">
                          <button class="btn btn-danger inline not-selected "
                                  (click)="deleteMember(member)"
                                  title="{{'DELETE_MEMBER' | translate}}"><i class="fa fa-trash"></i></button>
                      </div>
                      <div *ngIf="selectedToBeDeleted(member)">
                           <button class="btn selected-member inline"
                                   (click)="cancelSelect(member)"
                                   title="{{'CANCEL_DELETE_MEMBER' | translate}}"><i class="fa fa-trash"></i></button>
                      </div>
                    </div>
                  </div>
                  <div class="left"><input type="text" value="{{member.member_name}}" disabled/></div>
                  <div class="inline custom-select" style="width:120px;">
                    <mat-select (selectionChange)="changeMemberRole($event, member.member_id)"
                                value="{{member.group_role}}" style="width: 120px">
                      <mat-option value="{{groupRoles[1].value}}">
                        {{groupRoles[1].htmlValue | translate}}
                      </mat-option>
                      <mat-option disabled="disabled" value="{{groupRoles[0].value}}">
                        {{groupRoles[0].htmlValue  | translate}}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
              </div>
            </div>
                                <!----------- delete members section if no member in group ------------------------>
            <div class="tz-2-com tz-2-main" *ngIf="noMembers===true">
                <div class="center member-content">
                  <div class="icon-member-content"><i class="fas fa-users"></i></div>
                  <div class="center">
                    <h5>{{"do_not_have_members_msg" | translate}}</h5>
                  </div>
                </div>
            </div>
                                <!----------- End delete members list ------------------------>

            <!----------------------------------- Buttons section--------------------------------------------------->
            <div class="row">
              <div class="input-field col s6 v2-mar-top-40">
                <button type="submit" [disabled]="formManageMembers.invalid && membersToDelete.length===0"
                        class="waves-effect waves-light btn-large full-btn submit-tbn">
                  {{'Submit' | translate}}
                </button>
              </div>
              <div class="input-field col s6 v2-mar-top-40">
                <button type="button"
                        class="waves-effect waves-light btn-large full-btn skip-btn"
                        (click)="cancelAddMembers()">
                  {{'Cancel' | translate}}
                </button>
              </div>
            </div>
            <!-----------------------------------------  END  ------------------------------------------------>
            <br >
            <br >
            <br >
          </form>

        </div>
      </div>
    </div>
  </div>
</div>
