/******************* enum ***********************/
export enum GroupTypes {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
  CLOSED = 'CLOSED',
}
export enum RoleGroup {
  MANAGER = 'MANAGER',
  MEMBER = 'MEMBER',
  NOT_MEMBER_REQUEST = 'NOT_MEMBER_REQUEST',
  NOT_MEMBER = 'NOT_MEMBER',
  ANONYMOUS = 'ANONYMOUS',
  REQUESTED = 'REQUESTED',
  INVITED = 'INVITED'
}

export enum ActionOnGroup {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}
/******************** const ***************************/
export const groupTypes = [
  {name: GroupTypes.PUBLIC, value: GroupTypes.PUBLIC, htmlValue: 'Public', class: 'public-group'},
  {name: GroupTypes.PRIVATE, value: GroupTypes.PRIVATE, htmlValue: 'Private', class: 'private-group'},
  {name: GroupTypes.CLOSED, value: GroupTypes.CLOSED, htmlValue: 'Closed', class: 'closed-group'}

];
export const groupTypeOrder = { PUBLIC: 0, PRIVATE: 1, CLOSED: 2};
export const groupRoles = [
  {name: RoleGroup.MANAGER, value: RoleGroup.MANAGER, htmlValue: 'Admin'},
  {name: RoleGroup.MEMBER, value: RoleGroup.MEMBER, htmlValue: 'Member'},
  {name: RoleGroup.NOT_MEMBER, value: RoleGroup.NOT_MEMBER, htmlValue: 'Not member'},
];

// confirmation add group path
export const CONFIRMATION_ADD_GROUP = 'invitations/:keyCode';
