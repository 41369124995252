<div class="listing-experiences-item-home home-list-pop" >
  <div class="list-ser-img">
    <img *ngIf="data.coverPicture && data.coverPicture != ''" src="{{data.coverPicture}}">
  </div>
  <div class="item-avatar">
    <img *ngIf="data.avatar&& data.avatar != ''" src="{{data.avatar}}">
  </div>
  <div>
      <div class="item-name">
        <a [routerLink]="['/groups/' + data.group_id]">{{data.name}}</a>
      </div>
      <p class="item-description">{{data.description}}</p>
  </div>
  <div class="list-enqu-btn">
      <ul >
        <li>
          <a [routerLink]="['/groups/' + data.group_id]" class="see_exp_button">
            <i class="fa fa-play" aria-hidden="true"></i>{{'SEE-EXPERIENCE-DETAILS' | translate}}
          </a>
        </li>
        <li>
          <a (click)="applyAction(data.group_id)" [class.isDisabled]="disabledButton" class="remove_exp_button">
            <i class="fa fa-trash" aria-hidden="true"></i>{{'Remove' | translate}}
          </a>
        </li>
      </ul>
  </div>
</div>
