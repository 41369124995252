export const CURRENT_GROUP_ID = 'current';
export const ROLE_GROUP = 'role'; // 0: admin | 1:member
export const CREATOR_GROUP = 'creator';
export const MEMBER_GROUP = 'user';
export const EXPERIENCE= 'experience';
export const LIMIT_FILTER = 'limit';
export const SORT_FILTER = 'sort';
export const RANDOM_FILTER = 'random';
export const SEARCH_FOR_FILTER = 'term';
export const IS_MEMBERS = 'isMembers';
export const OWNER_GROUP = 'owner';
export const IS_IN_GROUP = 'isInGroup';
