// 401 error messages
export const UNAUTHORIZED_TITLE = 'UNAUTHORIZED_TITLE';
export const UNAUTHORIZED_CONTENT = 'UNAUTHORIZED_CONTENT';
export const UNAUTHORISED_PATH = 'unauthorized';

// 403 error messages
export const FORBIDDEN_TITLE = 'FORBIDDEN_TITLE';
export const FORBIDDEN_CONTENT = 'FORBIDDEN_CONTENT';
export const FORBIDDEN_PATH = 'forbidden';

// 404 error messages
export const NOT_FOUND_TITLE = 'NOT_FOUND_TITLE';
export const NOT_FOUND_PAGE_TITLE = 'Not found page';
export const NOT_FOUND_CONTENT = 'NOT_FOUND_CONTENT';
export const NOT_FOUND_PATH = 'not-found';

// 409 error messages
export const CONFLICT_TITLE = 'CONFLICT_TITLE';
export const CONFLICT_CONTENT = 'CONFLICT';

// 400 error messages
export const BAD_REQUEST_TITLE = 'Camlann - Bad request';

// Oops error
export const OOPS_TITLE = 'OOPS_TITLE';
export const OOPS_CONTENT = 'OOPS_ERROR';
export const OOPS_PATH = 'error';
