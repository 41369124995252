import * as GUI from 'babylonjs-gui';
import * as BABYLON from 'babylonjs';
import {addHttp, round} from '../../../utils/functions/GlobalFunctions';
import {OnClick} from '../../interfaces/on-click';
import {rotationForUnity,} from '../../../utils/functions/RotationFunction';
import {fromMToPx, fromPxToM} from '../../../utils/functions/PositionFunction';
import {v4 as uuidv4} from 'uuid';
import {FOUR_DECIMALS, TOW_DECIMALS} from '../../../utils/global-constants/forModel';
import {changeScale} from '../../../utils/functions/sizeFunction';

import {DEFAULT_FILE_NAME, DEFAULT_IMAGE_URL, EditorElementType} from '../../../utils/global-constants/editor-const';

export class ImageObj {
  public uniqueId;
  public url: string;
  public fileName: string;
  public name = EditorElementType.image.toString();
  public type = EditorElementType.image.toString();
  public visible = true;
  public triggerVisible = true;

  public width = 0;
  public height = 0;
  public nbrCopy = 0;

  public rotation = {x: 0, y: 0, z: 0};
  public position = {x: 0, y: 20, z: 30};
  public scale = {x: 1, y: 1, z: 1};
  public onClick: Array<OnClick>; // [{ type: 'URL', info:['https..........']}]


  // recreate=true: si c'est un objet dans content (database)
  constructor(object?, recreate?, name?, position?, url?, fileName?) {
    if (object ) {  // used on clone or recreate from content
      this.type = EditorElementType.image.toString();
      this.rotation = {x: object.rotation.x, y: object.rotation.y, z: object.rotation.z};
      this.position = {x: object.position.x, y: object.position.y, z: object.position.z};
      this.fileName = object.fileName;

      if (recreate) { // recreate from content
        this.name = object.name;
        this.visible = true;
        this.url = object.uri;
        this.nbrCopy = 0;
        this.triggerVisible = true;
        this.onClick = object.onClick;
        this.scale = {x: object.scale, y: object.scale, z: object.scale};
        this.width = fromMToPx(object.size.width / object.scale);
        this.height = fromMToPx(object.size.height / object.scale);
      } else {// used on clone (recreate === null)
        this.width = object.width;
        this.height = object.height;
        this.url = object.url;
        this.scale = object.scale;
        this.visible = object.visible;
        this.onClick = object.onClick;
      }
    }
    if (name) { // create new or clone
      this.name = name;
    }
    if (position) { // create new or clone
      this.position = position;
    }
    if (fileName) { // create new
      this.fileName = fileName;
    }
    if (url) { // create new
      this.url = url;
    }
  }

  addImageFromObjScene(scene: BABYLON.Scene, recreate?) {
    let checkFile = false;
    const x = new XMLHttpRequest();
    x.timeout = 15000;
    x.open('GET', this.url);
    x.onreadystatechange = ( event) => {
      if ( checkFile === false ) {
        checkFile = true;
        if ( x.status === 404 ) {
          this.url = DEFAULT_IMAGE_URL;
          this.height = 0 ;
          this.width = 0;
          this.fileName = DEFAULT_FILE_NAME;
        }
        const image = new GUI.Image(' ', this.url);

        image.onImageLoadedObservable.add(() => {
          if (this.height === 0 && this.width === 0) {
            image.heightInPixels = image.domImage.naturalHeight;
            image.widthInPixels = image.domImage.naturalWidth;
            this.height = image.heightInPixels;
            this.width = image.widthInPixels;
          }

          const plane = BABYLON.MeshBuilder.CreatePlane(EditorElementType.image.toString(), {
            width: this.width,
            height: this.height
          }, scene);
          // if size > 50cm
          if (!recreate) { this.scale = changeScale(this.width, this.height, 0, this.scale); }

          plane.position = new BABYLON.Vector3(this.position.x, this.position.y, this.position.z);
          plane.rotation = new BABYLON.Vector3(this.rotation.x, this.rotation.y, this.rotation.z);
          plane.scaling = new BABYLON.Vector3(this.scale.x, this.scale.y, this.scale.z);

          const advancedTextureImage = GUI.AdvancedDynamicTexture.CreateForMesh(plane, this.width, this.height);
          advancedTextureImage.addControl(image);
          plane.renderingGroupId = 2;
          plane.isVisible = this.visible;
          plane.uniqueId = uuidv4();
          this.uniqueId = plane.uniqueId;
          return plane;
        });
      }
    };
    x.send();

  }

  recreate(scene) {
    this.addImageFromObjScene(scene, true);
  }

  editFile(scene) {
    let checkFile = false;
    const x = new XMLHttpRequest();
    x.timeout = 15000;
    x.open('GET', this.url);
    x.onreadystatechange = ( event) => {
      if ( checkFile === false ) {
        checkFile = true;
        if ( x.status === 404 ) {
          this.url = DEFAULT_IMAGE_URL;
          this.height = 0 ;
          this.width = 0;
          this.fileName = DEFAULT_FILE_NAME;
        }
        const image = new GUI.Image(' ', this.url);
        image.onImageLoadedObservable.add(() => {
          image.heightInPixels = image.domImage.naturalHeight;
          image.widthInPixels = image.domImage.naturalWidth;
          this.height = image.heightInPixels;
          this.width = image.widthInPixels;
          const plane = BABYLON.MeshBuilder.CreatePlane(EditorElementType.image.toString(), {
            width: this.width,
            height: this.height
          }, scene);

          plane.position = new BABYLON.Vector3(this.position.x, this.position.y, this.position.z);
          plane.rotation = new BABYLON.Vector3(this.rotation.x, this.rotation.y, this.rotation.z);
          plane.scaling = new BABYLON.Vector3(this.scale.x, this.scale.y, this.scale.z);

          const advancedTextureImage = GUI.AdvancedDynamicTexture.CreateForMesh(plane, this.width, this.height);
          advancedTextureImage.addControl(image);
          plane.renderingGroupId = 2;
          plane.isVisible = this.visible;
          plane.uniqueId = this.uniqueId;
          return plane;
        });
      }
    };
    x.send();

}

  // valeur des z et y sont inversées dans la position si le trigger est un marker
  outputJson(trigger) {
    // round function : pour limiter le nombre des chiffres après la virgule
    return {
      id: this.uniqueId,
      type: this.type,
      name: this.name,
      uri: this.url,
      fileName: this.fileName,
      visibility: this.visible,
      position: {
        x: round(fromPxToM(this.position.x - trigger.position.x), FOUR_DECIMALS),
        y: round(fromPxToM(this.position.y - trigger.position.y), FOUR_DECIMALS),
        z: round(fromPxToM(this.position.z - trigger.position.z), FOUR_DECIMALS),
      },
      rotation: {
        x: round(rotationForUnity(this.rotation).x, TOW_DECIMALS),
        y: round(rotationForUnity(this.rotation).y, TOW_DECIMALS),
        z: round(rotationForUnity(this.rotation).z, TOW_DECIMALS),
      },
      size: {
        width: round(fromPxToM(this.width * this.scale.x), FOUR_DECIMALS),
        height: round(fromPxToM(this.height * this.scale.x), FOUR_DECIMALS),
      },
      scale: round(this.scale.x, TOW_DECIMALS),
      onClick: addHttp(this.onClick)

    };
  }

}

