import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {map} from 'rxjs/operators';
import {Experience} from '../../reusables/interfaces/experience';
import {GroupsService} from '../../reusables/services/groups.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Group} from '../../reusables/interfaces/group';
import {Title} from '@angular/platform-browser';
import {MemberInGroup} from '../../reusables/interfaces/Member';
import {
  connectedMenuGroupDetails,
  initialMenuGroupDetails,
  publicMenuGroupDetails
} from '../../utils/jsonData/detailsGroupMenu';
import {
  ASK_FOR_CONFIRM_DELETE_GROUP_ACTION,
  COMING_SOON,
  DELETE, DISCARD_JOIN_GROUP_MESSAGE_CONTENT, DISCARD_JOIN_GROUP_MESSAGE_TITLE,
  PAGE_TITLE_PREFIX,
  SCROLL_TO_ABOUT,
  SCROLL_TO_EXPERIENCES, SUCCESS_ACCEPT_REQUEST_GROUP_TITLE,
  SUCCESS_DELETE_GROUP_MESSAGE_TITLE,
  SUCCESS_JOIN_REQUEST_SENT_MESSAGE,
  SUCCESS_JOIN_REQUEST_SENT_TITLE,
  SUCCESS_LEAVE_GROUP_TITLE,
  WRITE_GROUP_FAVORITE_ACTION
} from '../../utils/actionsLabels/labels';
import {
  EDIT_GROUP_PATH,
  LOGIN_PATH,
  MANAGE_EXPERIENCES_GROUPS_PATH,
  MANAGE_MEMBERS_GROUPS_PATH,
  MY_GROUPS_PATH
} from '../../utils/actionsLabels/pathLabels';
import {HttpParams} from '@angular/common/http';
import {CURRENT_GROUP_ID, LIMIT_FILTER, RANDOM_FILTER, SORT_FILTER} from '../../utils/queryParams/groupsQueryParams';
import {ExperiencesService} from '../../reusables/services/experiences.service';
import {UsersService} from '../../reusables/services/users.service';
import {TokenHelperService} from '../../reusables/services/token-helper.service';
import {NotificationService} from '../../reusables/services/notification.service';
import {GroupTypes, groupTypes, RoleGroup} from '../../utils/global-constants/groups-const';
import {ConfirmDialogService} from '../../reusables/components/confirm-dialog/confirm-dialog.service';
import {getPageStructure} from './group-page-structure';
import {FORBIDDEN_PATH} from '../../utils/errorsReactions/errors-texts';
import {MatDialog} from '@angular/material/dialog';
import {LoginDialogComponent} from '../../reusables/components/popups/login-dialog/login-dialog.component';
import {FacebookService, UIParams, UIResponse} from 'ngx-facebook';
import {GroupFavorite} from '../../reusables/interfaces/GroupFavorite';
import {AcceptInGroupDialogComponent} from "../../reusables/components/popups/accept-in-group-dialog/accept-in-group-dialog.component";
import {DiscardGroupDialogComponent} from "../../reusables/components/popups/discard-group-dialog/discard-group-dialog.component";
import {LeaveGroupDialogComponent} from "../../reusables/components/popups/leave-group-dialog/leave-group-dialog.component";


@Component({
  selector: 'app-details-groupe',
  templateUrl: './details-groupe.component.html',
  styleUrls: ['./details-groupe.component.scss', '../../../assets/customCss/waiting-load.scss']
})


export class DetailsGroupeComponent implements OnInit, OnDestroy {

  deleteText = DELETE;
  privateTypeGroup = GroupTypes.PRIVATE;
  menu = initialMenuGroupDetails;
  activeSubMenu = SCROLL_TO_ABOUT;
  doneDetails = false;
  doneSuggestedGroups = false;
  doneMembers = false;
  doneExperiences = false;
  id: string; // current group id
  group: Group;
  groupCover = '#41809b';
  suggestedGroups: Group[];
  members: MemberInGroup[];
  membersNbr: number;
  experiences: Experience[];
  experiencesNbr: number;
  isLogged = false;
  isMember = null;
  isCreator = false;
  userId: string;
  dialogRef;
  // to get json :
  userRole = RoleGroup.ANONYMOUS;
  groupType: string;
  pageStructure = {left: [], right: []};
  buttonsDisabled = false;
  destroyComponent = false;
  waitingLoad = false;
  groupFav: GroupFavorite;
  etatGroupFavorite = new Map<String, boolean>();
  groupFavoriteExist = false;
  doneForGroupFavorite = false;
  disabledButtonFavorite = false;
  disabledButton = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private titleService: Title,
              private groupsService: GroupsService,
              private experiencesService: ExperiencesService,
              private usersService: UsersService,
              private tokenHelperService: TokenHelperService,
              private notificationService: NotificationService,
              private confirmDialogService: ConfirmDialogService,
              public dialog: MatDialog,
              private fbk: FacebookService) {
    fbk.init({
      appId: '145889360804091', cookie: true, status: true, xfbml: true, version: 'v10.0'
    }).then();
  }

  ngOnDestroy(): void {
    this.destroyComponent = true;
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  ngOnInit(): void {
    this.route.paramMap.pipe(
      map(params => {
          this.id = params.get('id');
          this.initValues();
          this.isLogged = this.tokenHelperService.getConnectedUser();
          if (this.isLogged) {
            this.userId = this.tokenHelperService.getTokenData().user_id;
            this.userRole = RoleGroup.NOT_MEMBER;
          }
          this.getGroup();
        }
      )
    ).subscribe(() => {
    });
  }

  getButtonsDisabled(event) {
    this.buttonsDisabled = event;
  }

  initValues() {
    this.isMember = null;
    this.doneDetails = false;
    this.doneMembers = false;
    this.isLogged = false;
    this.isCreator = false;
    this.doneSuggestedGroups = false;
    this.suggestedGroups = [];
    this.members = [];
    this.experiences = [];

    this.userRole = RoleGroup.ANONYMOUS;
    this.groupType = null;
    this.pageStructure = {left: [], right: []};
  }

  getGroup() {
    window.scrollTo(0, 0);
    /**************** get group info *********************/
    this.groupsService.getGroupById(this.id).subscribe((data: Group) => {
      this.group = data;
      if (this.group.id) {
        this.titleService.setTitle(PAGE_TITLE_PREFIX + this.group.name);
        this.doneDetails = true;
        // get group type
        this.groupType = this.group.type;

        /**************** get group members *********************/
        const params = new HttpParams();
        this.groupsService.getGroupMembers(this.id, params).subscribe((data2: any) => {
          this.membersNbr = data2.number_members_group;
          this.members = data2.list_members;
        }, (() => {
          this.setPageStructure();
        }));

        // get user status
        // todo : optimize this
        switch (this.group.userStatus) {
          case RoleGroup.ANONYMOUS:
            this.userRole = this.group.userStatus;
            break;
          case RoleGroup.NOT_MEMBER:
            this.userRole = this.group.userStatus;
            break;
          case RoleGroup.INVITED:
            this.userRole = this.group.userStatus;
            break;
          case RoleGroup.REQUESTED:
            this.userRole = this.group.userStatus;
            break;
          case RoleGroup.MEMBER:
            this.userRole = this.group.userStatus;
            break;
          case RoleGroup.MANAGER:
            this.userRole = this.group.userStatus;
            break;
        }

        this.setPageStructure();
        // get suggestedGroups
        this.getSuggestedGroups(this.id);
        // get exp
        this.getGroupExperiences(this.id);
        if (this.group.coverPicture !== '') {
          this.groupCover = 'url(' + this.group.coverPicture + ') no-repeat center bottom';
        } else {
          this.groupCover = '#41809b';
        }
        this.disabledButton = false;
      } else {
        this.router.navigate(['/app-informations']).then();
      }
    }, (() => {
      // TODO delete this methode if the redirection is in the interceptor
      this.setPageStructure(); // if 403 il faut faire redirection (/forbidden)
    }));
    //group favorite
    this.etatGroupFavorite.set(this.id,false);
    this.groupsService.getUserGroupFavorite(this.id).subscribe(async (response: GroupFavorite) => {
        this.groupFav = response;
        if (this.groupFav.group_id){
          this.etatGroupFavorite.set(this.id,true);
          this.groupFavoriteExist = true;
        }
        this.doneForGroupFavorite = true;
      });
  }

  setPageStructure() {
    this.pageStructure = getPageStructure(this.userRole, this.groupType, this.id);
    if (!this.pageStructure) {
      this.router.navigateByUrl(FORBIDDEN_PATH).then();
    }
  }

  getSuggestedGroups(id) {
    let paramsGroups = new HttpParams();
    paramsGroups = paramsGroups.append(CURRENT_GROUP_ID, id);
    paramsGroups = paramsGroups.append(SORT_FILTER, RANDOM_FILTER);
    paramsGroups = paramsGroups.append(LIMIT_FILTER, '5');
    this.groupsService.getGroups(paramsGroups).subscribe((data: Group []) => {
      this.suggestedGroups = data;
      this.doneSuggestedGroups = true;
    });
  }

  getGroupExperiences(id: string) {
    this.groupsService.getGroupExperiences(id).subscribe((data: any) => {
      this.experiencesNbr = data.number_experiences_group;
      this.experiences = data.list_experiences;
      this.doneExperiences = true;
      if (this.group.type === groupTypes.find((x) => x.name === GroupTypes.PUBLIC).value) {
        this.menu = publicMenuGroupDetails;
      }
      if (this.isLogged) {
        this.menu = connectedMenuGroupDetails;
      }
    }, (() => {
    }));
  }

  scrollTo(event) {
    if (event.action && event.action === 'scroll') {
      this.scrollAction(event.target);
    }
  }

  scrollAction(el) {
    const item = document.getElementById(el);
    window.scrollTo({top: item.offsetTop + 230, behavior: 'smooth'});
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (document.querySelector('#' + SCROLL_TO_ABOUT)
      && this.isScrolledIntoView(document.querySelector('#' + SCROLL_TO_ABOUT))) {
      this.activeSubMenu = SCROLL_TO_ABOUT;
    } else if (document.querySelector('#' + SCROLL_TO_EXPERIENCES)
      && this.isScrolledIntoView(document.querySelector('#' + SCROLL_TO_EXPERIENCES))) {
      this.activeSubMenu = SCROLL_TO_EXPERIENCES;
    }
  }

  isScrolledIntoView(elem) {
    const rect = elem.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;

    // Only completely visible elements return true:
    return (elemTop >= 0) && (elemBottom <= window.innerHeight);
  }

  onButtonClick(click) {
    switch (click) {
      case 'delete-group':
        this.deleteGroup(this.group.id, this.group.name);
        break;
      case 'join-group':
        this.joinGroup(this.group.id);
        break;
      case 'discard-request':
        this.discardRequest(this.group.id, this.userId);
        break;
      case 'accept-invitation':
        this.acceptInvitation(this.group.id, this.group.userKeyCode);
        break;
      case 'coming-soon':
        this.comingSoonAction();
        break;
      case 'edit-group':
        this.router.navigateByUrl(MY_GROUPS_PATH + '/' + this.id + EDIT_GROUP_PATH).then();
        break;
      case 'manage-experiences':
        this.router.navigateByUrl(MY_GROUPS_PATH + '/' + this.id + MANAGE_EXPERIENCES_GROUPS_PATH).then();
        break;
      case 'manage-members':
        this.router.navigateByUrl(MY_GROUPS_PATH + '/' + this.id + MANAGE_MEMBERS_GROUPS_PATH).then();
        break;
      case 'leave-group':
        this.leaveGroup(this.group.id, this.userId, this.group.name);
        break;
      case 'login-popup':
        this.openLoginPopup();
        break;
      case '':
        this.comingSoonAction();
        break;
    }
  }

  comingSoonAction() {
    this.notificationService.showWarning('', COMING_SOON);
  }

  openLoginPopup() {
    if (this.destroyComponent !== true) { // prevent popup from appearing on other pages
      this.dialogRef = this.dialog.open(LoginDialogComponent, {
        disableClose: false,
        autoFocus: true,
        panelClass: 'my-dialog',
        width: '800px',
        maxHeight: '500px',
      });
      this.dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.router.navigate([LOGIN_PATH], {queryParams: {returnUrl: this.router.url}}).then(); // queryParams pour stocker l'ancien url
        }
      });
    }
  }

  deleteGroup(id, name) {
    this.confirmDialogService.confirmThis(ASK_FOR_CONFIRM_DELETE_GROUP_ACTION + '"' + name + '"?', () => {
      this.groupsService.deleteGroup(id).subscribe(() => {
        this.notificationService.showSuccess('', SUCCESS_DELETE_GROUP_MESSAGE_TITLE);
        this.router.navigateByUrl('/my-groups').then();
      });
    }, () => {
      this.buttonsDisabled = false;
    });
  }

  onAddExperience(event: Experience) {
    // get exp
    this.experiences.push(event);
    /*this.experiences = [];
    this.getGroupExperiences(this.id);*/
  }

  joinGroupAction(event) {
    if (event && this.isLogged) {
      this.groupsService.askJoinGroup(event.output).subscribe(() => {
        this.notificationService.showSuccess(SUCCESS_JOIN_REQUEST_SENT_MESSAGE, SUCCESS_JOIN_REQUEST_SENT_TITLE);
        // get suggestedGroups
        this.getSuggestedGroups(this.id);
      });
    } else {
      this.openLoginPopup();
    }
  }

  joinGroup(id) {
    this.buttonsDisabled = true;
    this.groupsService.askJoinGroup(id).subscribe(() => {
      this.buttonsDisabled= false;
      this.notificationService.showSuccess(SUCCESS_JOIN_REQUEST_SENT_MESSAGE, SUCCESS_JOIN_REQUEST_SENT_TITLE);
      this.userRole = RoleGroup.NOT_MEMBER_REQUEST;
      this.setPageStructure();
    });
  }

  acceptInvitation(groupId, userKeyCode) {
    if (this.destroyComponent !== true) { // prevent popup from appearing on other pages
      this.dialogRef = this.dialog.open(AcceptInGroupDialogComponent, {
        disableClose: false,
        autoFocus: true,
        panelClass: 'my-dialog',
        width: '550px',
        maxHeight: '500px',
      });
      this.dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.disabledButton = true;
          const requestBody = new FormData();
          requestBody.append('keyCode', userKeyCode);
          this.groupsService.addGroupMember(requestBody, groupId).subscribe(() => {
              this.notificationService.showSuccess('', SUCCESS_ACCEPT_REQUEST_GROUP_TITLE);
              this.getGroup();
          });
        }
      });
    }
  }

  discardRequest(groupId, userId) {
    if (this.destroyComponent !== true) { // prevent popup from appearing on other pages
      this.dialogRef = this.dialog.open(DiscardGroupDialogComponent, {
        disableClose: false,
        autoFocus: true,
        panelClass: 'my-dialog',
        width: '550px',
        maxHeight: '500px',
      });
      this.dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.disabledButton = true;
          const formData = new FormData();
          formData.append('status', 'DECLINED');
          this.groupsService.refuseJoinRequest(groupId, userId, formData).subscribe(() => {
            this.notificationService.showSuccess(DISCARD_JOIN_GROUP_MESSAGE_CONTENT, DISCARD_JOIN_GROUP_MESSAGE_TITLE);
            this.getGroup();
          });
        }
      });
    }

  }

  leaveGroup(groupId, memberId, nameGroup) {
    if (this.destroyComponent !== true) { // prevent popup from appearing on other pages
      this.dialogRef = this.dialog.open(LeaveGroupDialogComponent, {
        disableClose: false,
        autoFocus: true,
        panelClass: 'my-dialog',
        width: '750px',
        maxHeight: '500px',
        data: {name: nameGroup}
      });
      this.dialogRef.afterClosed().subscribe((result) => {
        if (result) {
            this.buttonsDisabled = true;
            this.groupsService.leaveGroup(groupId, memberId).subscribe(() => {
              this.notificationService.showSuccess('', SUCCESS_LEAVE_GROUP_TITLE);
              this.router.navigateByUrl(MY_GROUPS_PATH).then();
              this.buttonsDisabled = false;
            });
        }
      });
    }
  }
  sendData(event) {
    if (event.type && event.type !== '') {
      switch (event.type) {
        case WRITE_GROUP_FAVORITE_ACTION:
          this.waitingLoad = true;
          this.GroupFavoriteAction(event.data);
          break;
      }
    }
  }


  shareFacebook(event) {
    const options: UIParams = {
      method: 'share',
      href: event
    };
    this.fbk.ui(options)
      .then((res: UIResponse) => {
      })
      .catch();
  }

  shareTwitter(event) {
    this.windowPopup("https://twitter.com/intent/tweet/?text=Visit%20the%20AR%20group!%0A&url=" + event +
      "%0A&hashtags=AR,AugmentedReality%0A&via=Camlann10", 700, 400);
  }

  shareLinkedin(event) {
    this.windowPopup("https://www.linkedin.com/shareArticle?mini=true&url=" + event, 700, 400);
  }

  windowPopup(url, width, height) {
    // Calculate the position of the popup so it’s centered on the screen.
    const left = (screen.width / 2) - (width / 2), top = (screen.height / 2) - (height / 2);
    window.open(url,
      "",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left
    );
  }

  //Group favorite

  addGroupToFavorites(id) {
    this.disabledButtonFavorite = true;
    const requestBody = new FormData();
    requestBody.append('userId', this.userId);
    requestBody.append('templateId', id);
    this.groupsService.addGroupToFavorites(id, requestBody).subscribe(() => {
      this.waitingLoad = false;
      this.groupFavoriteExist= true;
      this.disabledButtonFavorite = false;
    });
  }

  deleteGroupFromFavorites(id) {
    this.disabledButtonFavorite = true;
    this.groupsService.deleteGroupFromFavorites(id).subscribe(() => {
      this.waitingLoad = false;
      this.groupFavoriteExist= false;
      this.disabledButtonFavorite = false;
    });
  }

  GroupFavoriteAction(id) {
     this.waitingLoad = true;
     if (this.groupFavoriteExist == true) {
       this.deleteGroupFromFavorites(id);
       this.etatGroupFavorite.set(id,false);
     }
     else {

       this.addGroupToFavorites(id);
       this.etatGroupFavorite.set(id,true);
     }
  }

}
