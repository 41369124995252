import {
  DELETE_FILES, FORM_MY_COMPUTER, GLOBAL_LIBRARY, INVALID_FILE_EXTENSION_AUDIO,
  INVALID_FILE_EXTENSION_IMAGE,
  INVALID_FILE_EXTENSION_OBJECT_3D,
  INVALID_FILE_SIZE,
  MAX_FILE_SIZE,
  MESSAGE_EXTENSION_OBJECT_3D, MY_ASSETS_LIBRARY,
  NO_GLOBAL_ASSET_AUDIO,
  NO_GLOBAL_ASSET_IMAGE,
  NO_GLOBAL_ASSET_OBJECT_3D, OR_SELECT_ANOTHER_AUDIO,
  OR_SELECT_ANOTHER_IMAGE,
  OR_SELECT_ANOTHER_OBJECT_3D,
  PLEASE_IMPORT_FROM_COMPUTER_AUDIO,
  PLEASE_IMPORT_FROM_COMPUTER_IMAGE,
  PLEASE_IMPORT_FROM_COMPUTER_OBJECT_3D,
  SUPPORTED_EXTENSIONS, YOU_DO_NOT_HAVE_ENOUGH_SPACE_AUDIO,
  YOU_DO_NOT_HAVE_ENOUGH_SPACE_IMAGE,
  YOU_DO_NOT_HAVE_ENOUGH_SPACE_OBJECT_3D, YOU_DO_NOT_HAVE_SPACE_AUDIO,
  YOU_DO_NOT_HAVE_SPACE_IMAGE,
  YOU_DO_NOT_HAVE_SPACE_OBJECT_3D,
  YOUR_ASSETS_LIBRARY
} from '../actionsLabels/editorLabels';

export enum AssetType {
  IMAGE = 'IMAGE',
  AUDIO  = 'AUDIO',
  OBJ_3D = 'OBJ_3D'
}

export enum LibraryType {
  ALL = 'ALL',
  GLOBAL  = 'GLOBAL',
  PRIVATE = 'PRIVATE'
}

export const inputAcceptExtensionImage = 'image/png, .jpg, .jpeg';
export const messageExtensionImage = '.PNG, .JPG, .JPEG';
export const extensionImageList = ['PNG', 'JPG', 'JPEG'];

export const inputAcceptExtensionObject3D = '.zip';
export const messageExtensionObject3D = MESSAGE_EXTENSION_OBJECT_3D;
export const extensionObject3DList = ['ZIP'];

export const inputAcceptExtensionAudio = 'audio/wav, .mp3, .ogg';
export const messageExtensionAudio = '.MP3, .WAV, .OGG';
export const extensionAudioList = ['WAV', 'MP3', 'OGG'];

export const maxFileSize = 60000000;
export const messageMaxFileSize = '60 Mo';


// Add asset: type Image
export const ADD_IMAGE_ASSET = { assetType: AssetType.IMAGE,
  messages: {
    noSpace: YOU_DO_NOT_HAVE_SPACE_IMAGE,
    noEnoughSpace: YOU_DO_NOT_HAVE_ENOUGH_SPACE_IMAGE,
    invalidFileExtension: INVALID_FILE_EXTENSION_IMAGE,
    orSelectAnotherFile: OR_SELECT_ANOTHER_IMAGE,
    supportedExtensionsValue: messageExtensionImage,

    deleteFiles: DELETE_FILES,
    yourAssetsLibrary: YOUR_ASSETS_LIBRARY,
    supportedExtensions: SUPPORTED_EXTENSIONS,
    maxFileSize: MAX_FILE_SIZE,
    maxFileSizeValue: messageMaxFileSize,
    invalidFileSize: INVALID_FILE_SIZE,

  },
  input: {
    id: 'imageAssetObjectFile',
    accept : inputAcceptExtensionImage,
    extensionList: extensionImageList
  }
};

// Add asset: type Object 3D
export const ADD_OBJECT_3D_ASSET = { assetType: AssetType.OBJ_3D,
  messages: {
    noSpace: YOU_DO_NOT_HAVE_SPACE_OBJECT_3D,
    noEnoughSpace: YOU_DO_NOT_HAVE_ENOUGH_SPACE_OBJECT_3D,
    invalidFileExtension: INVALID_FILE_EXTENSION_OBJECT_3D,
    orSelectAnotherFile: OR_SELECT_ANOTHER_OBJECT_3D,

    deleteFiles: DELETE_FILES,
    yourAssetsLibrary: YOUR_ASSETS_LIBRARY,
    supportedExtensions: messageExtensionObject3D,
    supportedExtensionsValue: '',
    maxFileSize: MAX_FILE_SIZE,
    maxFileSizeValue: messageMaxFileSize,
    invalidFileSize: INVALID_FILE_SIZE,

  },
  input: {
    id: 'object3DAssetObjectFile',
    accept : inputAcceptExtensionObject3D,
    extensionList: extensionObject3DList
  }
};

// Add asset: type Audio
export const ADD_AUDIO_ASSET = { assetType: AssetType.AUDIO,
  messages: {
    noSpace: YOU_DO_NOT_HAVE_SPACE_AUDIO,
    noEnoughSpace: YOU_DO_NOT_HAVE_ENOUGH_SPACE_AUDIO,
    invalidFileExtension: INVALID_FILE_EXTENSION_AUDIO,
    orSelectAnotherFile: OR_SELECT_ANOTHER_AUDIO,
    supportedExtensionsValue: messageExtensionAudio,

    deleteFiles: DELETE_FILES,
    yourAssetsLibrary: YOUR_ASSETS_LIBRARY,
    supportedExtensions: SUPPORTED_EXTENSIONS,
    maxFileSize: MAX_FILE_SIZE,
    maxFileSizeValue: messageMaxFileSize,
    invalidFileSize: INVALID_FILE_SIZE,

  },
  input: {
    id: 'audioAssetObjectFile',
    accept : inputAcceptExtensionAudio,
    extensionList: extensionAudioList
  }
};

export const ADD_ASSET = [ADD_IMAGE_ASSET, ADD_OBJECT_3D_ASSET, ADD_AUDIO_ASSET];

export const MY_ASSETS_IMAGE = 'MY_ASSETS_IMAGE';
export const MY_ASSETS_3D = 'MY_ASSETS_3D';
export const MY_ASSETS_AUDIO = 'MY_ASSETS_AUDIO';

export const MY_ASSETS_TAB_MENU = [
  {label: MY_ASSETS_IMAGE, id: 'menu0', checked: true, value: MY_ASSETS_IMAGE},
  {label: MY_ASSETS_3D, id: 'menu1', checked: false, value: MY_ASSETS_3D},
  {label: MY_ASSETS_AUDIO, id: 'menu2', checked: false, value: MY_ASSETS_AUDIO},
];
