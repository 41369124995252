import {Component, OnInit} from '@angular/core';
import {
  ASK_FOR_CONFIRM_DELETE_TEMPLATE_ACTION,
  DELETE,
  MY_TEMPLATE_PAGE_TITLE,
  PAGE_TITLE_PREFIX,
  SUCCESS_DELETE_TEMPLATE_MESSAGE_TITLE
} from '../../../../utils/actionsLabels/labels';
import {HttpParams} from '@angular/common/http';
import {OWNER_TEMPLATE} from '../../../../utils/queryParams/templatesQueryParams';
import {Template} from '../../../interfaces/templates';
import {CREATE_TEMPLATE_PATH} from '../../../../utils/actionsLabels/pathLabels';
import {TokenHelperService} from '../../../services/token-helper.service';
import {Title} from '@angular/platform-browser';
import {ExperiencesService} from '../../../services/experiences.service';
import {ConfirmDialogService} from '../../confirm-dialog/confirm-dialog.service';
import {NotificationService} from '../../../services/notification.service';

@Component({
  selector: 'app-my-templates-profile',
  templateUrl: './my-templates-profile.component.html',
  styleUrls: ['./my-templates-profile.component.scss']
})
export class MyTemplatesProfileComponent implements OnInit {

  pageTitle = MY_TEMPLATE_PAGE_TITLE;
  userId: string; // current user id
  userTemplates: Template [];
  doneForUserTemplates = false;
  createTemplate = CREATE_TEMPLATE_PATH;
  template = 'template';
  deleteText = DELETE;
  disabledButton = false;

  constructor(private tokenHelperService: TokenHelperService,
              private titleService: Title,
              private experiencesService: ExperiencesService,
              private confirmDialogService: ConfirmDialogService,
              private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.titleService.setTitle(PAGE_TITLE_PREFIX + MY_TEMPLATE_PAGE_TITLE);
    // get user id
    if (this.tokenHelperService.getConnectedUser()) {
      this.userId = this.tokenHelperService.getTokenData().user_id;
    }
    this.getUserTemplates(this.userId);
  }

  getUserTemplates(id) {
    let params = new HttpParams();
    params = params.append(OWNER_TEMPLATE, id);
    this.experiencesService.getTemplates(params).subscribe((data: Template[]) => {
      this.userTemplates = data;
      this.doneForUserTemplates = true;
      this.disabledButton = false;
    });
  }

  deleteTemplate(event) {
    this.confirmDialogService.confirmThis(ASK_FOR_CONFIRM_DELETE_TEMPLATE_ACTION + '"' + event.name+ '"?', () => {
      this.disabledButton = true;
      this.experiencesService.deleteTemplate(event.id).subscribe(() => {
        this.notificationService.showSuccess('', SUCCESS_DELETE_TEMPLATE_MESSAGE_TITLE);
        this.getUserTemplates(this.userId);
      });
    }, () => {
    });
  }

}
