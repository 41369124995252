import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseURLUser} from '../../utils/global-constants/baseUrl';
import {TokenHelperService} from './token-helper.service';


@Injectable({
  providedIn: 'root'
})
export class UsersService {
  public navbar: EventEmitter<boolean>;

  constructor(private httpClient: HttpClient,
              private tokenHelperService: TokenHelperService) {
    this.navbar = new EventEmitter<boolean>();
  }

  getUserProfile(id?) {
    let userId = id;
    if (!id) {
      userId = this.tokenHelperService.getTokenData().user_id;
    }
    return this.httpClient.get(BaseURLUser + 'users/' + userId + '/profile');
  }

  getUserExperiences(userId) {
    return this.httpClient.get(BaseURLUser + 'users/' + userId + '/experiences');
  }

  signup(parameters) {
    return this.httpClient.post(BaseURLUser + 'users', parameters);
  }

  editProfile(userId, data) {
    return this.httpClient.put(BaseURLUser + 'users/' + userId + '/profile', data);
  }

  changePassword(userId, data) {
    return this.httpClient.put(BaseURLUser + 'users/' + userId, data);
  }

  forgetPassword(email) {
    return this.httpClient.post(BaseURLUser + 'users/reset-password', email);
  }

  changeNavBar(val: boolean) {
    this.navbar.emit(val);
  }

}
