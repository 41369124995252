import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NotificationService} from '../../../services/notification.service';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {LibraryService} from '../../../services/library.service';
import {TokenHelperService} from '../../../services/token-helper.service';
import {AssetObject, AssetsLibrary} from '../../../interfaces/assets-library';
import {statusClass} from '../../../../utils/global-constants/experienceStatusColors';
import {HttpParams} from '@angular/common/http';
import {ASSETS_LIBRARY_ID, CREATOR_ID} from '../../../../utils/queryParams/assetsQueryParams';
import {
  ALL_ASSET,
  ASK_FOR_CONFIRM_DELETE_ASSET_ACTION,
  CREATE_NEW_ASSET,
  DELETE,
  DELETE_ACTION,
  EDIT_ACTION,
  FROM_YOUR_LIBRARY,
  HEADER_DATE,
  HEADER_DATE_MAJ,
  HEADER_NAME,
  HEADER_SIZE,
  LOCAL_STORAGE,
  MY_ASSETS_LIBRARY, MY_ASSETS_LIBRARY_PAGE_TITLE,
  PREVIEW_ACTION,
  SUCCESS_DELETE_ASSET_MESSAGE_TITLE
} from '../../../../utils/actionsLabels/labels';
import {fromBitToGo, fromBitToKoString, fromBitToMo} from '../../../../utils/functions/fileFuctions';
import {ConfirmDialogService} from '../../confirm-dialog/confirm-dialog.service';
import {CREATE_MY_LIBRARY_PATH} from "../../../../utils/actionsLabels/pathLabels";
import {TranslateService} from "@ngx-translate/core";
import {Title} from "@angular/platform-browser";
import {DeviceDetectorService} from "ngx-device-detector";
import {AssetType, MY_ASSETS_TAB_MENU} from "../../../../utils/global-constants/assets-library-const";

@Component({
  selector: 'app-assets-listing',
  templateUrl: './assets-listing.component.html',
  styleUrls: ['./assets-listing.component.scss']
})
export class AssetsListingComponent implements OnInit {

  constructor(private notificationService: NotificationService,
              public dialog: MatDialog,
              private router: Router,
              private confirmDialogService: ConfirmDialogService,
              private translate: TranslateService,
              private titleService: Title,
              private libraryService: LibraryService,
              private tokenHelperService: TokenHelperService,
              private deviceService: DeviceDetectorService) {
  }

  assetObjects: AssetObject[] = [];
  disabledButton = false;
  @Output() assetAction = new EventEmitter();
  createAssetPath = CREATE_MY_LIBRARY_PATH;
  EDIT_ACTION;
  DELETE_ACTION;
  deleteText = DELETE;
  PREVIEW_ACTION;
  statusClassTab = statusClass;
  addAsset = CREATE_NEW_ASSET;
  localStorage = LOCAL_STORAGE;
  myAssetsLibraryLabel = MY_ASSETS_LIBRARY;
  labelAllAsset = ALL_ASSET;
  tabHeaders = ['', HEADER_NAME, HEADER_DATE, HEADER_DATE_MAJ, HEADER_SIZE];
  dialogRef;
  // Connected user Library
  myAssetsLibrary: AssetsLibrary;
  // connected user Id
  userId: string;
  showLoadingAssetsObject = true;
  progressbarValue = 0;
  url = CREATE_MY_LIBRARY_PATH;
  typeMobile = false;

  // ******** Tab menu  ***************//
  tabMenu = MY_ASSETS_TAB_MENU;
  imageType = AssetType.IMAGE;
  obj3DType = AssetType.OBJ_3D;
  audioType = AssetType.AUDIO;
  imageList = [];
  obj3DList = [];
  audioList = [];

  ngOnInit(): void {

    this.EDIT_ACTION = EDIT_ACTION;
    this.PREVIEW_ACTION = PREVIEW_ACTION;
    this.DELETE_ACTION = DELETE_ACTION;
    this.showLoadingAssetsObject = true;
    this.userId = this.tokenHelperService.getTokenData().user_id;
    this.getMyLibrary();
    this.epicFunction();
  }
  epicFunction() {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    if (isMobile || isTablet) {
      this.typeMobile = true;
    }
  }

// / get connected user Assets Library
  getMyLibrary() {

    this.translate.get(MY_ASSETS_LIBRARY_PAGE_TITLE).subscribe((res: string) => {
      this.titleService.setTitle(res);
    });
    let paramsGroups = new HttpParams();
    paramsGroups = paramsGroups.append(CREATOR_ID, this.userId);
    this.libraryService.getAssetsLibraryByCreatorId(paramsGroups).subscribe((data: AssetsLibrary[]) => {
      if (data && data.length > 0) {
        this.myAssetsLibrary = data[0];
        this.getMyAssets(this.myAssetsLibrary.libraryId);
        this.progressbarValue = ((this.myAssetsLibrary.usedSize * 100) /
          (this.myAssetsLibrary.assignedSize + this.myAssetsLibrary.usedSize));

      } else {
        this.showLoadingAssetsObject = false;
      }
    });
  }
  initialList(){
    this.imageList = [];
    this.obj3DList = [];
    this.audioList = [];
  }

// get connected user Assets
  getMyAssets(libraryID: string) {
    let paramsGroups = new HttpParams();
    paramsGroups = paramsGroups.append(ASSETS_LIBRARY_ID, libraryID);
    this.libraryService.getAssets(paramsGroups).subscribe((data: AssetObject[]) => {
      this.showLoadingAssetsObject = false;
      this.assetObjects = data;
      this.initialList();
      for (let i = 0; i < this.assetObjects.length; i++) {
        switch (this.assetObjects[i]["assetType"]) {
          case this.imageType:
            this.imageList.push(this.assetObjects[i]);
            break;
          case this.obj3DType:
            this.obj3DList.push(this.assetObjects[i]);
            break;
          case this.audioType:
            this.audioList.push(this.assetObjects[i]);
            break;
        }
      }
    });
  }

  applyAction(val, idLibrary, idAsset, nameAsset) {
    this.deleteAssets(idLibrary , idAsset , nameAsset);
  }

  sizeToKo(size) {
    return fromBitToKoString(size);
  }

  sizeToMo(size) {
    return fromBitToMo(size);
  }

  sizeToGo(size) {
    return fromBitToGo(size);
  }

  deleteAssets(libraryId, assetId, name) {
    this.confirmDialogService.confirmThis(ASK_FOR_CONFIRM_DELETE_ASSET_ACTION + ' "' + name  + '" ' + FROM_YOUR_LIBRARY + '?', () => {
      this.disabledButton = true;
      this.libraryService.deleteAsset(libraryId, assetId).subscribe(() => {
        this.notificationService.showSuccess('', SUCCESS_DELETE_ASSET_MESSAGE_TITLE);
        this.getMyAssets(this.myAssetsLibrary.libraryId);
        this.disabledButton = false;
      });
    }, () => {
    });
  }

}

