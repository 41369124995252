<div class="tz-2-com tz-2-main">
  <section>
    <div class="">
      <div class="">
        <div class="tz-2-com tz-2-main">
          <h4>{{myAssetsLibraryLabel | translate}}</h4>
          <!--  test if user have already assets   -->
          <div class="db-list-com tz-db-table" *ngIf="!showLoadingAssetsObject && (myAssetsLibrary && assetObjects.length>0)">
            <!--  custom progress bar and section title in mobile version -->
            <div class="ds-boar-title" *ngIf="typeMobile">
              <h2 class="section-title">{{labelAllAsset| translate}}</h2>
              <div class="ds-boar-title">
                <div>
                  <a [routerLink]="createAssetPath" class="show_in_desktop right">
                    <button type="submit" class="waves-effect waves-light btn-large create-button">
                      {{addAsset | translate}}
                    </button>
                  </a>
                </div>
              </div>
              <div class="custom-progress-bar">
                <h5>{{localStorage | translate}} : {{sizeToMo(myAssetsLibrary.usedSize)}} Mo
                  / {{sizeToGo(myAssetsLibrary.assignedSize + myAssetsLibrary.usedSize)}} Go </h5>
                <p><br>
                  <ngb-progressbar type="info" [value]="progressbarValue" [striped]="true"></ngb-progressbar>
                </p>
              </div>
            </div>
            <!--  custom progress bar and section title in desktop version -->
            <div class="ds-boar-title in_desktop" *ngIf="!typeMobile">
              <h2 class="section-title">{{labelAllAsset| translate}}</h2>
              <div class="custom-progress-bar">
                <h5>{{localStorage | translate}} : {{sizeToMo(myAssetsLibrary.usedSize)}} Mo
                  / {{sizeToGo(myAssetsLibrary.assignedSize + myAssetsLibrary.usedSize)}} Go </h5>
                <p><br>
                  <ngb-progressbar type="info" [value]="progressbarValue" [striped]="true"></ngb-progressbar>
                </p>

              </div>
              <div class="ds-boar-title">
                <div>
                    <button type="submit" class="waves-effect waves-light btn-large create-button">
                      <a [routerLink]="createAssetPath" class="show_in_desktop right add_asset">
                      {{addAsset | translate}}
                        </a>
                    </button>

                </div>
              </div>
            </div>

            <div class="pc-menu" >
              <input
                [checked]=tabMenu[0].checked
                id="{{tabMenu[0].id}}"
                type="radio"
                name="m1"
                class="custom_menu_options"
                value="{{tabMenu[0].value}}"
              />
              <input
                [checked]=tabMenu[1].checked
                id="{{tabMenu[1].id}}"
                type="radio"
                name="m1"
                class="custom_menu_options"
                value="{{tabMenu[1].value}}"
              />
              <input
                [checked]=tabMenu[2].checked
                id="{{tabMenu[2].id}}"
                type="radio"
                name="m1"
                class="custom_menu_options"
                value="{{tabMenu[2].value}}"
              />
              <nav>
                <ul>
                  <li class="menu0">
                    <label for="{{tabMenu[0].id}}" class="label-content">{{tabMenu[0].label | translate}}</label>
                  </li>
                  <li class="menu1">
                    <label for="{{tabMenu[1].id}}" class="label-content">{{tabMenu[1].label | translate}}</label>
                  </li>
                  <li class="menu2">
                    <label for="{{tabMenu[2].id}}" class="label-content">{{tabMenu[2].label | translate}}</label>
                  </li>
                </ul>
              </nav>
              <section>
                <!----------------------------------------------- MY ASSETS LIBRARY IMAGE : start ----------------------------------------------------->
                <div class="menu0" >
                  <!------------- if the user doesn't have Image Assets in his AssetsLibrary -------------->
                  <div class="tz-2-com tz-2-main" *ngIf="imageList.length==0">
                    <div class="center ">
                      <div class="icon-experience-content"><i class="fas fa-cubes"></i></div>
                      <div class="center">
                        <h4>{{"YOU_HAVE_NOT_CREATE_ASSET_IMAGE" | translate}}</h4>
                        <br/>
                        <p>{{"PLEASE_IMPORT_FROM_COMPUTER_IMAGE" | translate}}</p>
                        <br/>
                      </div>
                    </div>
                  </div>
                  <!------------------------- if the user has IMAGE Assets: Loading assets ---------------------------->
                  <div class="row waiting-get-data-popup " *ngIf="showLoadingAssetsObject">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                  </div>
                  <!------------------------- if the user has Image Assets  in his AssetsLibrary ---------------------------->
                  <div  class="row assets-list" *ngIf="!showLoadingAssetsObject && imageList.length!=0">
                    <div class="scroll">
                       <table class="responsive-table bordered">
                          <thead>
                            <tr>
                              <th scope="col" *ngFor="let item of tabHeaders">{{item| translate}}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of imageList">
                              <td>
                                <img src='{{ item.assetObjectUrl }}' alt=""/>
                              </td>
                              <td>{{item.fileName}}</td>
                              <td>{{item.creationDate | date}}</td>
                              <td>{{item.updateDate | date}}</td>
                              <td>{{sizeToKo(item.size)}}</td>
                              <td>
                                <a (click)="applyAction(DELETE_ACTION,myAssetsLibrary.libraryId ,item.assetObjectId, item.fileName)"
                                   [class.isDisabled]="disabledButton">
                                  <span class="tooltiptext">{{'DELETE' | translate}}</span>
                                  <span class="db-list-edit">
                                    <em class="fa fa-trash">  </em>
                                  </span>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                       </table>
                  </div>
                  </div>
                </div>
                <!----------------------------------------------- MY ASSETS LIBRARY IMAGE : end ----------------------------------------------------->

                <!----------------------------------------------- MY ASSETS LIBRARY OBJ 3D : start ----------------------------------------------------->
                <div class="menu1" >
                  <!------------- if the user doesn't have OBJ 3D Assets in his AssetsLibrary -------------->
                  <div class="tz-2-com tz-2-main" *ngIf="obj3DList.length==0">
                    <div class="center ">
                      <div class="icon-experience-content"><i class="fas fa-cubes"></i></div>
                      <div class="center">
                        <h4>{{"YOU_HAVE_NOT_CREATE_ASSET_OBJECT_3D" | translate}}</h4>
                        <br/>
                        <p>{{"PLEASE_IMPORT_FROM_COMPUTER_OBJECT_3D" | translate}}</p>
                        <br/>
                      </div>
                    </div>
                  </div>
                  <!------------------------- if the user has OBJ 3D Assets: Loading assets ---------------------------->
                  <div class="row waiting-get-data-popup " *ngIf="showLoadingAssetsObject">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                  </div>
                  <!------------------------- if the user has OBJ 3D Assets in his AssetsLibrary ---------------------------->
                  <div  class="row assets-list" *ngIf="!showLoadingAssetsObject && obj3DList.length!=0">
                    <div class="scroll">
                      <table class="responsive-table bordered">
                        <thead>
                        <tr>
                          <th scope="col" *ngFor="let item of tabHeaders">{{item| translate}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of obj3DList">
                          <td>
                            <img src="../../../../../assets/images/editor/obj.png" alt=""/>
                          </td>
                          <td>{{item.fileName}}</td>
                          <td>{{item.creationDate | date}}</td>
                          <td>{{item.updateDate | date}}</td>
                          <td>{{sizeToKo(item.size)}}</td>
                          <td>
                            <a (click)="applyAction(DELETE_ACTION,myAssetsLibrary.libraryId ,item.assetObjectId, item.fileName)"
                               [class.isDisabled]="disabledButton">
                              <span class="tooltiptext">{{'DELETE' | translate}}</span>
                              <span class="db-list-edit">
                                    <em class="fa fa-trash">  </em>
                                  </span>
                            </a>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!----------------------------------------------- MY ASSETS LIBRARY OBJ 3D : end ----------------------------------------------------->
                <!----------------------------------------------- MY ASSETS LIBRARY audio : start ----------------------------------------------------->
                <div class="menu2" >
                  <!------------- if the user doesn't have OBJ 3D Assets in his AssetsLibrary -------------->
                  <div class="tz-2-com tz-2-main" *ngIf="audioList.length==0">
                    <div class="center ">
                      <div class="icon-experience-content"><i class="fas fa-cubes"></i></div>
                      <div class="center">
                        <h4>{{"YOU_HAVE_NOT_CREATE_ASSET_AUDIO" | translate}}</h4>
                        <br/>
                        <p>{{"PLEASE_IMPORT_FROM_COMPUTER_AUDIO" | translate}}</p>
                        <br/>
                      </div>
                    </div>
                  </div>
                  <!------------------------- if the user has audio Assets: Loading assets ---------------------------->
                  <div class="row waiting-get-data-popup " *ngIf="showLoadingAssetsObject">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                  </div>
                  <!------------------------- if the user has audio Assets in his AssetsLibrary ---------------------------->
                  <div  class="row assets-list" *ngIf="!showLoadingAssetsObject && audioList.length!=0">
                    <div class="scroll">
                      <table class="responsive-table bordered">
                        <thead>
                        <tr>
                          <th scope="col" *ngFor="let item of tabHeaders">{{item| translate}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of audioList">
                          <td>
                            <img src="../../../../../assets/images/editor/audio.png" alt=""/>
                          </td>
                          <td>{{item.fileName}}</td>
                          <td>{{item.creationDate | date}}</td>
                          <td>{{item.updateDate | date}}</td>
                          <td>{{sizeToKo(item.size)}}</td>
                          <td>
                            <a (click)="applyAction(DELETE_ACTION,myAssetsLibrary.libraryId ,item.assetObjectId, item.fileName)"
                               [class.isDisabled]="disabledButton">
                              <span class="tooltiptext">{{'DELETE' | translate}}</span>
                              <span class="db-list-edit">
                                    <em class="fa fa-trash">  </em>
                                  </span>
                            </a>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!----------------------------------------------- MY ASSETS LIBRARY audio : end ----------------------------------------------------->
              </section>
            </div>
          </div>

          <!--  test if user not create any asset yet -->
          <div *ngIf="!showLoadingAssetsObject && (!myAssetsLibrary || assetObjects.length==0)">
            <a [routerLink]="url">
              <div class="center create-first-experience-content">
                 <div class="icon-experience-content"><i class="fas fa-cubes"></i></div>
                <div class="center">
                  <h3>{{"do_not_have_assets_msg" | translate}}</h3>
                  <br/>
                  <p>{{"go_to_add_asset" | translate}}</p>
                  <br/>
                </div>
                <div class="waves-effect waves-light btn-large full-btn">{{"Add" | translate}}</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row waiting-get-data-popup " *ngIf="showLoadingAssetsObject">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
  </section>
  <app-confirm-dialog [confirmText]="deleteText"></app-confirm-dialog>
</div>
