export function Inverse(j) {
  return -j;
}
export function fromPxToCm(px) {
  return px * 0.0264583333;
}
export function fromCmToPx(cm) {
  return 37.7952755906 * cm;
}
export function fromPxToM(px) {
  return px * 0.0264583333 / 100;
}
export function fromMToPx(m) {
  return 37.7952755906 * m * 100;
}

// pour obtenir les positions reelles des composant (les positions dans content sont par rapport marker)
export function  convertPositionSurface(triggerPosition, position) {
  position.x = fromMToPx(position.x + fromPxToM(triggerPosition.x));
  position.y = fromMToPx(position.y + fromPxToM(triggerPosition.y));
  position.z = fromMToPx(position.z + fromPxToM(triggerPosition.z));
}
export function  convertPositionMarker(triggerPosition, position) {
  position.x = fromMToPx(position.x + triggerPosition.x);
  position.y = fromMToPx(position.y + triggerPosition.y);
  position.z = fromMToPx(position.z + triggerPosition.z);
}
export function setPosition(position1, position2) {
  position1.y = position2.y;
  position1.z = position2.z;
  position1.x = position2.x;
}
export function  convertPosition(trigger , position) {
  position.x = fromMToPx(position.x + fromPxToM(trigger.position.x));
  position.y = fromMToPx(position.y + fromPxToM(trigger.position.y));
  position.z = fromMToPx(position.z + fromPxToM(trigger.position.z));
  // if (trigger.type === 'marker') { return convertPositionMarker(trigger.position, position); } else
  // { return convertPositionSurface(trigger.position, position); }
}

export function getMarkerPositionFromJson(marker) {
  return {x: fromMToPx(marker.position.x), y: fromMToPx(marker.position.y), z: fromMToPx(marker.position.z)};
}

export function getComponentPosition(PosObj, PosMarkNew, PosMark) {
  return {
    x: PosObj.x - PosMark.x + PosMarkNew.x ,
    y: PosObj.y - PosMark.y + PosMarkNew.y ,
    z: PosObj.z - PosMark.z + PosMarkNew.z ,
  };
}
