import {Component, OnInit} from '@angular/core';
import {Experience} from "../../interfaces/experience";
import {HttpParams} from "@angular/common/http";
import {IN_MODERATION} from "../../../utils/queryParams/experiencesQueryParams";
import {ExperiencesService} from "../../services/experiences.service";
import {
  HEADER_CREATOR,
  HEADER_DATE,
  HEADER_DATE_MAJ,
  HEADER_EXP_NAME,
  HEADER_NAME,
  HEADER_RATING,
  HEADER_REVIEW, HEADER_TEMP_NAME,
  MODERATE,
  SUCCESS_REJECT_EXPERIENCE_MESSAGE_TITLE,
  SUCCESS_REJECT_REVIEW_MESSAGE_TITLE,
  SUCCESS_REJECT_TEMPLATE_MESSAGE_TITLE,
  SUCCESS_RESET_LIKE_DISLIKE_ACTION_MESSAGE_TITLE,
  SUCCESS_VALIDATE_EXPERIENCE_MESSAGE_TITLE,
  SUCCESS_VALIDATE_REVIEW_MESSAGE_TITLE,
  SUCCESS_VALIDATE_TEMPLATE_MESSAGE_TITLE,
  VALIDATE
} from "../../../utils/actionsLabels/labels";
import {MODERATE_TAB_MENU} from "../../../utils/global-constants/moderate-const";
import {BaseURLLink} from "../../../utils/global-constants/baseUrl";
import {
  EDITOR_EXPERIENCE_PATH,
  EDITOR_TEMPLATE_PATH,
  EXPERIENCE_PATH,
  TEMPLATES_PATH
} from "../../../utils/actionsLabels/pathLabels";
import {MatDialog} from "@angular/material/dialog";
import {NotificationService} from "../../services/notification.service";
import {experienceStatus} from "../../../utils/global-constants/experienceStatusColors";
import {RejectExperienceDialogComponent} from "../popups/reject-experience-dialog/reject-experience-dialog.component";
import {Template} from "../../interfaces/templates";
import {templateStatusEnum} from "../../../utils/global-constants/templates-const";
import {ReviewExperienceModerate, ReviewTemplateModerate} from "../../interfaces/review";
import {ReviewStatusEnum} from "../../../utils/global-constants/Reviews-const";
import {RejectReviewDialogComponent} from "../popups/reject-review-dialog/reject-review-dialog.component";
import {ResetLikeDislikeDialogComponent} from "../popups/reset-like-dislike-dialog/reset-like-dislike-dialog.component";
import {ValidateItemDialogComponent} from "../popups/validate-item-dialog/validate-item-dialog.component";

@Component({
  selector: 'app-moderate',
  templateUrl: './moderate.component.html',
  styleUrls: ['./moderate.component.scss', '../../../../assets/customCss/waiting-load.scss']
})
export class ModerateComponent implements OnInit {
  moderateLabel = MODERATE;
  experiences: Experience [];
  experiencesAreLoaded = false;
  editorLink = '';
  dialogRef;
  destroyComponent = false;
  disabledButton = false;
  validateText = VALIDATE;
  experiencesLink = EXPERIENCE_PATH;
  templates: Template [];
  templatesAreLoaded = false;
  disabledButtonTemp = false;
  templatesLink = TEMPLATES_PATH;
  expReviews: ReviewExperienceModerate []
  expReviewsAreLoaded = false;
  disabledButtonExpReviews = false;
  disabledButtonReset = false;
  p = 1; // initial page in pagination of experience reviews
  z = 1; // initial page in pagination of template reviews
  // ******** Tab menu  ***************//
  tabMenu = MODERATE_TAB_MENU;
  tabHeaders = [HEADER_NAME, HEADER_DATE, HEADER_DATE_MAJ, HEADER_CREATOR, '', '', '', ''];
  tabHeadersExpReviews = [HEADER_REVIEW, HEADER_RATING, HEADER_DATE, HEADER_DATE_MAJ, HEADER_EXP_NAME, HEADER_CREATOR, '', '', '', ''];
  // template reviews
  tempReviews: ReviewTemplateModerate []
  tabHeadersTempReviews = [HEADER_REVIEW, HEADER_RATING, HEADER_DATE, HEADER_DATE_MAJ, HEADER_TEMP_NAME, HEADER_CREATOR, '', '', '', ''];
  tempReviewsAreLoaded = false;
  disabledButtonTempReviews = false;
  disabledButtonResetTemp = false;

  constructor(private experiencesService: ExperiencesService,
              private notificationService: NotificationService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getExperiencesInModeration();
    this.getTemplatesInModeration();
    this.getExpReviewsInModeration();
    this.getTempReviewsInModeration();
  }

  ngOnDestroy(): void {
    this.destroyComponent = true;
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
  // experiences
  getExperiencesInModeration() {
    let params = new HttpParams();
    params = params.append(IN_MODERATION, String(true));
    this.experiencesService.getExperiences(params).subscribe((data: Experience[]) => {
      this.experiences = data;
      this.experiencesAreLoaded = true;
      this.disabledButton = false;
    });
  }

  openEditor(id, contentId) {
    this.editorLink = EDITOR_EXPERIENCE_PATH + '/' + id + '/content/' + contentId;
    window.open(BaseURLLink + this.editorLink, '_blank').focus();
  }

  validateExperienceAction(id, name) {
    this.openDialogValidate('experience', id, '', name);
  }

  validateExperience(id){
    this.disabledButton = true;
    const requestBody = new FormData();
    requestBody.append('status', String(experienceStatus[0].value));
    this.experiencesService.moderateExperience(requestBody, id).subscribe(() => {
      this.notificationService.showSuccess('', SUCCESS_VALIDATE_EXPERIENCE_MESSAGE_TITLE);
      this.getExperiencesInModeration();
    }, (error => {
      this.getExperiencesInModeration();
      this.disabledButton = true;
    }));
  }

  openDialogValidate(type, id, rev_id, name) {
    if (this.destroyComponent !== true) { // prevent popup from appearing on other pages
      this.dialogRef = this.dialog.open(ValidateItemDialogComponent, {
        disableClose: false,
        autoFocus: true,
        panelClass: 'my-dialog',
        width: '600px',
        maxHeight: '500px',
        data: {name: name , type: type}
      });
      this.dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (type == 'experience') {
            this.validateExperience(id);
          }
          if (type == 'template') {
            this.validateTemplate(id);
          }
          if (type == 'review') {
            this.validateExpReview(id, rev_id);
          }
          if (type == 'reviewTemplate') {
            this.validateTempReview(id, rev_id);
          }
        }
      });
    }
  }

  rejectExperience(exp_id, remarks) {
    this.disabledButton = true;
    const requestBody = new FormData();
    requestBody.append('status', String(experienceStatus[2].value));
    requestBody.append('remarks', remarks);
    this.experiencesService.moderateExperience(requestBody, exp_id).subscribe(() => {
      this.notificationService.showSuccess('', SUCCESS_REJECT_EXPERIENCE_MESSAGE_TITLE);
      this.getExperiencesInModeration();
    }, (error => {
      this.getExperiencesInModeration();
      this.disabledButton = true;
    }));
  }

  rejectExperienceAction(id, name) {
    this.openDialog('experience', id, name);
  }

  openDialog(type, id, name) {
    if (this.destroyComponent !== true) { // prevent popup from appearing on other pages
      this.dialogRef = this.dialog.open(RejectExperienceDialogComponent, {
        disableClose: false,
        autoFocus: true,
        panelClass: 'my-dialog',
        width: '800px',
        maxHeight: '500px',
        data: {name: name, type: type}
      });
      this.dialogRef.afterClosed().subscribe((remarks) => {
        if (remarks) {
          if (type == 'template') {
            this.rejectTemplate(id, remarks);
          } else {
            this.rejectExperience(id, remarks);
          }
        }
      });
    }
  }
  // templates
  getTemplatesInModeration() {
    let params = new HttpParams();
    params = params.append(IN_MODERATION, String(true));
    this.experiencesService.getTemplates(params).subscribe((data: Template[]) => {
      this.templates = data;
      this.templatesAreLoaded = true;
      this.disabledButtonTemp = false;
    });
  }
  openTemplateEditor(id, contentId) {
    this.editorLink = EDITOR_TEMPLATE_PATH + '/' + id + '/content/' + contentId;
    window.open(BaseURLLink + this.editorLink, '_blank').focus();
  }
  validateTemplateAction(id, name) {
    this.openDialogValidate('template', id, '', name);
  }
  validateTemplate(id) {
    this.disabledButtonTemp = true;
    const requestBody = new FormData();
    requestBody.append('status', String(templateStatusEnum[0].label));
    this.experiencesService.moderateTemplate(requestBody, id).subscribe(() => {
      this.notificationService.showSuccess('', SUCCESS_VALIDATE_TEMPLATE_MESSAGE_TITLE);
      this.getTemplatesInModeration();
    }, (error => {
      this.getTemplatesInModeration();
      this.disabledButtonTemp = true;
    }));
  }

  rejectTemplateAction(id, name) {
    this.openDialog('template', id, name);
  }
  rejectTemplate(exp_id, remarks) {
    this.disabledButtonTemp = true;
    const requestBody = new FormData();
    requestBody.append('status', String(templateStatusEnum[2].value));
    requestBody.append('remarks', remarks);
    this.experiencesService.moderateTemplate(requestBody, exp_id).subscribe(() => {
      this.notificationService.showSuccess('', SUCCESS_REJECT_TEMPLATE_MESSAGE_TITLE);
      this.getTemplatesInModeration();
    }, (error => {
      this.getTemplatesInModeration();
      this.disabledButtonTemp = true;
    }));
  }

  // experiences reviews
  getExpReviewsInModeration() {
    let params = new HttpParams();
    params = params.append(IN_MODERATION, String(true));
    this.experiencesService.getExperiencesReviews(params).subscribe((data: ReviewExperienceModerate[]) => {
      this.expReviews = data;
      this.expReviewsAreLoaded = true;
      this.disabledButtonExpReviews = false;
      this.disabledButtonReset = false;
    });
  }

  validateExpReviewAction(id, exp_id, exp_name) {
    this.openDialogValidate('review', exp_id, id, exp_name);
  }

  validateExpReview(exp_id, id) {
    this.disabledButtonExpReviews = true;
    const requestBody = new FormData();
    requestBody.append('status', String(ReviewStatusEnum[0].value));
    this.experiencesService.moderateExpReview(requestBody, exp_id, id).subscribe(() => {
      this.notificationService.showSuccess('', SUCCESS_VALIDATE_REVIEW_MESSAGE_TITLE);
      this.getExpReviewsInModeration();
    }, (error => {
      this.getExpReviewsInModeration();
      this.disabledButtonExpReviews= true;
    }));
  }

  rejectExpReviewAction(id, exp_id, exp_name) {
    this.openDialogReview('experience', id, exp_id, exp_name);
  }

  openDialogReview(type, rev_id, id, name) {
    if (this.destroyComponent !== true) { // prevent popup from appearing on other pages
      this.dialogRef = this.dialog.open(RejectReviewDialogComponent, {
        disableClose: false,
        autoFocus: true,
        panelClass: 'my-dialog',
        width: '800px',
        maxHeight: '500px',
        data: {name: name, type: type}
      });
      this.dialogRef.afterClosed().subscribe((remarks) => {
        if (remarks) {
          if (type == 'experience') {
            this.rejectExpReview(id, rev_id, remarks);
          } else {
            this.rejectTempReview(id, rev_id, remarks);
          }
        }
      });
    }
  }

  rejectExpReview(exp_id, rev_id, remarks) {
    this.disabledButtonExpReviews= true;
    const requestBody = new FormData();
    requestBody.append('status', String(ReviewStatusEnum[1].value));
    requestBody.append('remarks', remarks);
    this.experiencesService.moderateExpReview(requestBody, exp_id, rev_id).subscribe(() => {
      this.notificationService.showSuccess('', SUCCESS_REJECT_REVIEW_MESSAGE_TITLE);
      this.getExpReviewsInModeration();
    }, (error => {
      this.getExpReviewsInModeration();
      this.disabledButtonExpReviews= true;
    }));
  }

  resetLikesDislikes(rev_id, exp_id) {
    if (this.destroyComponent !== true) { // prevent popup from appearing on other pages
      this.dialogRef = this.dialog.open(ResetLikeDislikeDialogComponent, {
        disableClose: false,
        autoFocus: true,
        panelClass: 'my-dialog',
        width: '500px',
        maxHeight: '500px'
      });
      this.dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.disabledButtonReset = true;
          this.experiencesService.resetLikeDislikeExpReview('', exp_id, rev_id).subscribe(() => {
            this.notificationService.showSuccess('', SUCCESS_RESET_LIKE_DISLIKE_ACTION_MESSAGE_TITLE);
            this.getExpReviewsInModeration();
          });
        }
      });
    }
  }

  // templates reviews
  getTempReviewsInModeration() {
    let params = new HttpParams();
    params = params.append(IN_MODERATION, String(true));
    this.experiencesService.getTemplatesReviews(params).subscribe((data: ReviewTemplateModerate[]) => {
      this.tempReviews = data;
      this.tempReviewsAreLoaded = true;
      this.disabledButtonTempReviews = false;
      this.disabledButtonResetTemp = false;
    });
  }

  validateTempReviewAction(id, temp_id, temp_name) {
    this.openDialogValidate('reviewTemplate', temp_id, id, temp_name);
  }

  validateTempReview(temp_id, id) {
    this.disabledButtonTempReviews = true;
    const requestBody = new FormData();
    requestBody.append('status', String(ReviewStatusEnum[0].value));
    this.experiencesService.moderateTempReview(requestBody, temp_id, id).subscribe(() => {
      this.notificationService.showSuccess('', SUCCESS_VALIDATE_REVIEW_MESSAGE_TITLE);
      this.getTempReviewsInModeration();
    }, (error => {
      this.getTempReviewsInModeration();
      this.disabledButtonTempReviews= true;
    }));
  }

  rejectTempReviewAction(id, temp_id, temp_name) {
    this.openDialogReview('template', id, temp_id, temp_name);
  }

  rejectTempReview(temp_id, rev_id, remarks) {
    this.disabledButtonTempReviews= true;
    const requestBody = new FormData();
    requestBody.append('status', String(ReviewStatusEnum[1].value));
    requestBody.append('remarks', remarks);
    this.experiencesService.moderateTempReview(requestBody, temp_id, rev_id).subscribe(() => {
      this.notificationService.showSuccess('', SUCCESS_REJECT_REVIEW_MESSAGE_TITLE);
      this.getTempReviewsInModeration();
    }, (error => {
      this.getTempReviewsInModeration();
      this.disabledButtonTempReviews= true;
    }));
  }

  resetTempLikesDislikes(rev_id, temp_id) {
    if (this.destroyComponent !== true) { // prevent popup from appearing on other pages
      this.dialogRef = this.dialog.open(ResetLikeDislikeDialogComponent, {
        disableClose: false,
        autoFocus: true,
        panelClass: 'my-dialog',
        width: '500px',
        maxHeight: '500px'
      });
      this.dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.disabledButtonResetTemp = true;
          this.experiencesService.resetLikeDislikeTempReview('', temp_id, rev_id).subscribe(() => {
            this.notificationService.showSuccess('', SUCCESS_RESET_LIKE_DISLIKE_ACTION_MESSAGE_TITLE);
            this.getTempReviewsInModeration();
          });
        }
      });
    }
  }
}
