<section class="tz-register" >
  <div class="log-in-pop" [class.isDisabled]="disabled">
    <div class="log-in-pop-left inDesktop">
      <h1>{{"HELLO" | translate}} !</h1>
      <br>

      <p>{{"DONT_HAVE_ACCOUNT" | translate}} {{"CREATE_ACCOUNT" | translate}} {{"MINUTE" | translate}} </p>
      <h4>{{"Log in with social media" | translate}}</h4>
      <br>
      <ul>
        <li><a (click)="showMessage()">
          <div class="social-icon">
            <img src="assets/images/facebook_logo.png" width="17" alt="{{'Log in with Facebook' | translate}}"/></div>
          {{"Log in with Facebook" | translate}}</a></li>
        <li><a (click)="showMessage()">
          <div class="social-icon">
            <img src="assets/images/google_logo.png" width="16" alt="{{'Log in with Google' | translate}}"/></div>
          {{"Log in with Google" | translate}}</a></li>
        <li><a (click)="showMessage()">
          <div class="social-icon"><i class="fa fa-twitter tw1"></i></div>
          {{"Log in with Twitter" | translate}}</a></li>
      </ul>
    </div>
    <div class="log-in-pop-right">
      <h4>{{"Create an Account" | translate}}</h4>
      <br>
      <p>{{"DONT_HAVE_ACCOUNT" | translate}} {{"CREATE_ACCOUNT" | translate}} {{"MINUTE" | translate}} </p>

      <br>
      <form class="s12" [formGroup]="signupForm" (ngSubmit)="onSubmit(signupForm.value)">
        <!----------------------------- PROFOLE - IMAGE - START ---------------------------------------->
        <label class=" identicon-label" >{{'Profile Image' | translate}}</label>
        <a class="identicon-image-icon" (click)="inputCropprdImage.click()">
          <i class="fas fa-pen">
            <span class="tooltiptext" *ngIf="!isNotDesktop">{{'Edit Image' | translate}}</span>
          </i>
        </a>
        <a class="identicon-image-icon" (click)="refreshIdenticon()">
          <i class="fas fa-refresh ">
            <span class="tooltiptext" *ngIf="!isNotDesktop">{{'New Identicon' | translate}}</span>
          </i> </a>
        <br>
        <svg *ngIf="!fileCroppedImage" class=" identicon-image"  id="iconIdenticon" [data-jdenticon-value] = "profileIdenticon">
        </svg>
        <img class="coppred-image"  [src]="croppedImage" *ngIf="fileCroppedImage" alt=""/>
        <image-cropper *ngIf="fileCroppedImage"
                       class="image-cropper"
                       [imageFile]="fileCroppedImage"
                       [maintainAspectRatio]="true"
                       [containWithinAspectRatio]="false"
                       [aspectRatio]="1"
                       [cropperMinWidth]="100"
                       [onlyScaleDown]="true"
                       [roundCropper]="false"
                       [canvasRotation]="0"
                       [alignImage]="'center'"
                       format="png"
                       (imageCropped)="imageCropped($event)"
        ></image-cropper>



        <!----------------------------- PROFOLE - IMAGE - END ---------------------------------------->

        <div>
          <div class="input-field s12">
            <input
              [ngClass]="(signupForm.get('firstName').invalid && signupForm.get('firstName').touched)?'invalid':'validate'"
              type="text" formControlName="firstName" id="first_name"/>
            <label for="first_name">{{'First Name' | translate}}</label>
            <div *ngIf="signupForm.controls['firstName'].invalid && signupForm.controls['firstName'].touched"
                 class="alert alert-danger">
              {{'Please enter your first name !' | translate}}
            </div>
          </div>
          <div class="input-field s12">
            <input
              [ngClass]="(signupForm.get('lastName').invalid && signupForm.get('lastName').touched)?'invalid':'validate'"
              type="text" formControlName="lastName" id="last_name"/>
            <label for="last_name">{{'Last Name' | translate}}</label>
            <div *ngIf="signupForm.controls['lastName'].invalid && signupForm.controls['lastName'].touched"
                 class="alert alert-danger">
              {{'Please enter your last name !' | translate}}
            </div>
          </div>
          <div class="input-field s12">
            <input type="email" formControlName="email" id="email"
                   [ngClass]="(signupForm.get('email').invalid && signupForm.get('email').touched)?'invalid':'validate'">
            <label for="email">{{'EMAIL' | translate}}</label>
            <div *ngIf="signupForm.controls['email'].invalid && signupForm.controls['email'].touched"
                 class="alert alert-danger">
              <div *ngIf="signupForm.controls['email'].errors.required">
                {{'Please enter your email!' | translate}}
              </div>
              <div *ngIf="signupForm.controls['email'].errors.pattern">
                {{'Please enter a valid email!' | translate}}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="input-field s12">
            <input type="password" formControlName="password" id="password"
                   [ngClass]="(signupForm.get('password').invalid && signupForm.get('password').touched)?'invalid':'validate'">
            <label for="password">{{'PASSWORD' | translate}}</label>
            <div *ngIf="signupForm.controls['password'].invalid && signupForm.controls['password'].touched"
                 class="alert alert-danger">
              {{'Please enter a valid password!' | translate}}
              <div
                *ngIf="signupForm.controls['password'].hasError('minlength')">
                {{'- Must have at least 8 characters!' | translate}} </div>
              <div
                *ngIf="signupForm.controls['password'].hasError('hasNumber')">
                {{'- Must have at least 1 number!' | translate}} </div>
              <div
                *ngIf="signupForm.controls['password'].hasError('hasCapitalCase')">
                {{'- Must contain at least 1 Letter in Capital Case!' | translate}} </div>
              <div
                *ngIf="signupForm.controls['password'].hasError('hasSmallCase')">
                {{'- Must contain at least 1 Letter in Small Case!' | translate}} </div>
              <div
                *ngIf="signupForm.controls['password'].hasError('hasSpecialCharacters')">
                {{'- Must contain at least 1 Special Character!' | translate}} </div>
            </div>
          </div>
        </div>
        <div>
          <div class="input-field s12">
            <input id="confirm_password"
                   type="password" formControlName="passwordConfirm"
                   [ngClass]="(signupForm.controls['passwordConfirm'].errors.mustMatchPasswords &&
            signupForm.controls['passwordConfirm'].errors.mustMatchPasswords === true && signupForm.get('passwordConfirm').touched)?'invalid':'validate'"
            />
            <label for="confirm_password">{{"CONFIRM_PASSWORD" | translate}}</label>
            <div *ngIf="signupForm.controls['passwordConfirm'].errors.mustMatchPasswords &&
            signupForm.controls['passwordConfirm'].errors.mustMatchPasswords === true" class="alert alert-danger">
              {{'Your password and confirmation password do not match' | translate}}
            </div>
          </div>
        </div>
        <br/>



        <div class="captcha-container">
          <re-captcha (resolved)="captchaResolved($event)"></re-captcha>
        </div>
        <br/>
        <div class="input-field s4 waves-effect waves-light log-in-btn"
             [ngClass]="{'disabledSubmit': ((signupForm.get('firstName').invalid) || (signupForm.get('lastName').invalid) ||
                        (signupForm.get('email').invalid) || (signupForm.get('password').invalid) || (signupForm.controls['passwordConfirm'].errors.mustMatchPasswords !== false) ||
                        (signupForm.get('passwordConfirm').value.length === '0'))}">
          <input type="submit" [disabled]="(((signupForm.get('firstName').invalid) || (signupForm.get('lastName').invalid) ||
                        (signupForm.get('email').invalid) || (signupForm.get('password').invalid) || (signupForm.controls['passwordConfirm'].errors.mustMatchPasswords !== false) ||
                        (signupForm.get('passwordConfirm').value.length === '0')) || !this.captchaSolved)" value="Register">
        </div>
        <div>
          <div class="input-field s12"><a [routerLink]="[loginLink]" class="login">{{"Are_you_already_a_member" | translate}}</a>&nbsp;
            <a [routerLink]="[loginLink]" class="login">{{"Login" | translate}} </a> </div>
        </div>
      </form>
    </div>


    <div class="log-in-pop-left inMobile">
      <h4>{{"Log in with social media" | translate}}</h4>
      <br>
      <ul>
        <li><a (click)="showMessage()">
          <div class="social-icon">
            <img src="assets/images/facebook_logo.png" width="17" alt="{{'Log in with Facebook' | translate}}"/></div>
          {{"Log in with Facebook" | translate}}</a></li>
        <li><a (click)="showMessage()">
          <div class="social-icon">
            <img src="assets/images/google_logo.png" width="16" alt="{{'Log in with Google' | translate}}"/></div>
          {{"Log in with Google" | translate}}</a></li>
        <li><a (click)="showMessage()">
          <div class="social-icon"><i class="fa fa-twitter tw1"></i></div>
          {{"Log in with Twitter" | translate}}</a></li>
      </ul>
    </div>
  </div>
</section>


<input type="file" class="hidden"  #inputCropprdImage [(ngModel)]="fileCroppedImage"
       accept="image/png, image/jpeg, image/jpg"
       (change)="onCroppedImageChange($event)">
