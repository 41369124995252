import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SecondMenuComponent} from '../../editor-menu/second-menu/second-menu.component';


class DialogData {
}

@Component({
  selector: 'app-marker-name-dialog',
  templateUrl: './marker-name-dialog.component.html',
  styleUrls: ['./marker-name-dialog.component.scss', '../../../../../assets/scss/dialog-popup.scss']
})
export class MarkerNameDialogComponent implements OnInit {

  name = '';
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<SecondMenuComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl(this.name, Validators.required)
    });
    //if (this.data[0] === false ){this.dialogRef.close()}
  }

  create(): void {
    this.dialogRef.close(this.form.controls.name.value);
  }
  cancel(): void {
    this.dialogRef.close();
  }
}
