import {DEFAULT_FILE_NAME, DEFAULT_IMAGE_URL} from "../global-constants/editor-const";

export function fromBitToKoString(size) {
  return (Math.round((size / 8 / 1024) * 100) / 100) + ' Ko';
}

export function fromBitToMo(size) {
  return (Math.round((size / (8 * 1000000) )));
}

export function fromBitToGo(size) {
  return (Math.round((size / (8 * 1000000000) )));
}
export function checkSize(file, maxSize) {
  return file.size < maxSize;
}
export function checkExtension(file, extensionsList) {
  const list = file.name.split('.');
  return (list.length > 1) && extensionsList.includes(file.name.split('.')[list.length - 1].toUpperCase());
}
export function fileNameWithoutExtension(fileName) {
  return fileName.substr(0, fileName.lastIndexOf('.'));
}

