<div class="tz-2-com tz-2-main">
  <section>
        <div class="tz-2-com tz-2-main">
          <h4>{{moderateLabel  | translate}}</h4>
          <div class="db-list-com tz-db-table" *ngIf="experiencesAreLoaded && templatesAreLoaded && expReviewsAreLoaded && tempReviewsAreLoaded">
            <div class="pc-menu" >
              <input
                [checked]=tabMenu[0].checked
                id="{{tabMenu[0].id}}"
                type="radio"
                name="m1"
                class="custom_menu_options"
                value="{{tabMenu[0].value}}"
              />
              <input
                [checked]=tabMenu[1].checked
                id="{{tabMenu[1].id}}"
                type="radio"
                name="m1"
                class="custom_menu_options"
                value="{{tabMenu[1].value}}"
              />
              <input
                [checked]=tabMenu[2].checked
                id="{{tabMenu[2].id}}"
                type="radio"
                name="m1"
                class="custom_menu_options"
                value="{{tabMenu[2].value}}"
              />
              <nav>
                <ul>
                  <li class="menu0">
                    <label for="{{tabMenu[0].id}}" class="label-content">{{tabMenu[0].label | translate}}</label>
                  </li>
                  <li class="menu1">
                    <label for="{{tabMenu[1].id}}" class="label-content">{{tabMenu[1].label | translate}}</label>
                  </li>
                  <li class="menu2">
                    <label for="{{tabMenu[2].id}}" class="label-content">{{tabMenu[2].label | translate}}</label>
                  </li>
                </ul>
              </nav>
              <section>
                <!----------------------------------------------- Experiences : start ----------------------------------------------------->
                <div class="menu0" >
                  <!------------- if the user doesn't have experiences to moderate -------------->
                  <div class="tz-2-com tz-2-main" *ngIf="experiences.length==0">
                    <div class="center ">
                      <div class="icon-experience-content icon-content"><i class="fas fa-cubes"></i></div>
                      <div class="center">
                        <h4>{{"YOU_HAVE_NOT_EXPERIENCE_TO_MODERATE" | translate}}</h4>
                        <br/>
                      </div>
                    </div>
                  </div>
                  <!------------------------- if the user has experiences to moderate : Loading experiences ---------------------------->
                  <div class="row waiting-get-data-popup " *ngIf="!experiencesAreLoaded">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                  </div>
                  <!------------------------- if the user has experiences to moderate ---------------------------->
                  <div class="scroll" *ngIf="experiencesAreLoaded && experiences.length!=0">
                      <table class="responsive-table bordered">
                        <thead>
                        <tr>
                          <th scope="col" *ngFor="let item of tabHeaders">{{item| translate}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of experiences">
                          <td>{{item.name}}</td>
                          <td>{{item.createdDate| date}}</td>
                          <td>{{item.updatedDate | date}}</td>
                          <td>{{item.creator.userDisplayName}}</td>
                          <td>
                            <a [routerLink]="[experiencesLink + '/' + item.id]">
                              <span class="tooltiptext">{{'PREVIEW' | translate}}</span>
                              <span class="db-list-edit"> <em class="fa fa-eye"> </em> </span>
                            </a>
                          </td>
                          <td>
                            <a (click)="openEditor(item.id, item.content)">
                              <span class="tooltiptext">{{'Open_experience_in_editor' | translate}}</span>
                              <span class="db-list-edit"> <em class="fa fa-th-large"> </em> </span>
                            </a>
                          </td>
                          <td>
                            <a (click)="validateExperienceAction(item.id, item.name)" [class.isDisabled]="disabledButton">
                              <span class="tooltiptext">{{'VALIDATE' | translate}}</span>
                              <span class="db-list-edit"> <em class="fa fa-check">  </em> </span>
                            </a>
                          </td>
                          <td>
                            <a (click)="rejectExperienceAction(item.id, item.name)" [class.isDisabled]="disabledButton">
                              <span class="tooltiptext">{{'REJECT' | translate}}</span>
                              <span class="db-list-edit"> <em class="fa fa-ban">  </em> </span>
                            </a>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                <!----------------------------------------------- Experiences : end ----------------------------------------------------->
                <!----------------------------------------------- Templates : start ----------------------------------------------------->
                <div class="menu1" >
                  <!------------- if the user doesn't have templates to moderate -------------->
                  <div class="tz-2-com tz-2-main" *ngIf="templates.length==0">
                    <div class="center ">
                      <div class="icon-experience-content icon-content"><i class="fas fa-cubes"></i></div>
                      <div class="center">
                        <h4>{{"YOU_HAVE_NOT_TEMPLATE_TO_MODERATE" | translate}}</h4>
                        <br/>
                      </div>
                    </div>
                  </div>
                  <!------------------------- if the user has templates to moderate : Loading templates ---------------------------->
                  <div class="row waiting-get-data-popup " *ngIf="!templatesAreLoaded">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                  </div>
                  <!------------------------- if the user has templates to moderate ---------------------------->
                  <div class="scroll" *ngIf="templatesAreLoaded && templates.length!=0">
                    <table class="responsive-table bordered">
                      <thead>
                      <tr>
                        <th scope="col" *ngFor="let item of tabHeaders">{{item| translate}}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let item of templates">
                        <td>{{item.name}}</td>
                        <td>{{item.createdDate| date}}</td>
                        <td>{{item.updatedDate | date}}</td>
                        <td>{{item.creator.userDisplayName}}</td>
                        <td>
                          <a [routerLink]="[templatesLink + '/' + item.id]">
                            <span class="tooltiptext">{{'PREVIEW' | translate}}</span>
                            <span class="db-list-edit"> <em class="fa fa-eye"> </em> </span>
                          </a>
                        </td>
                        <td>
                          <a (click)="openTemplateEditor(item.id, item.content)">
                            <span class="tooltiptext">{{'Open_experience_in_editor' | translate}}</span>
                            <span class="db-list-edit"> <em class="fa fa-th-large"> </em> </span>
                          </a>
                        </td>
                        <td>
                          <a (click)="validateTemplateAction(item.id, item.name)" [class.isDisabled]="disabledButtonTemp">
                            <span class="tooltiptext">{{'VALIDATE' | translate}}</span>
                            <span class="db-list-edit"> <em class="fa fa-check">  </em> </span>
                          </a>
                        </td>
                        <td>
                          <a (click)="rejectTemplateAction(item.id, item.name)" [class.isDisabled]="disabledButtonTemp">
                            <span class="tooltiptext">{{'REJECT' | translate}}</span>
                            <span class="db-list-edit"> <em class="fa fa-ban">  </em> </span>
                          </a>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!----------------------------------------------- Templates: end ----------------------------------------------------->
                <!----------------------------------------------- Reviews : start ----------------------------------------------------->
                <div class="menu2" >
                  <!------------- if the user doesn't have exp and temp reviews to moderate -------------->
                  <div class="tz-2-com tz-2-main" *ngIf="expReviews.length==0 && tempReviews.length==0">
                    <div class="center ">
                      <div class="icon-experience-content icon-content"><i class="fas fa-cubes"></i></div>
                      <div class="center">
                        <h4>{{"YOU_HAVE_NOT_REVIEWS_TO_MODERATE" | translate}}</h4>
                        <br/>
                      </div>
                    </div>
                  </div>
                  <!------------------------- if the user has exp reviews to moderate : Loading reviews  ---------------------------->
                  <div *ngIf="expReviews.length!=0 || tempReviews.length!=0">
                    <!---------------- experience reviews ---------------------->
                      <h4 class="text-content">{{"EXPERIENCE_REVIEW" | translate}}</h4>
                      <!------------- if the user doesn't have exp reviews to moderate -------------->
                      <div class="tz-2-com tz-2-main" *ngIf="expReviews.length==0">
                        <div class="center ">
                          <div class="icon-experience-content"><i class="fas fa-cubes"></i></div>
                          <div class="center">
                            <h4>{{"YOU_HAVE_NOT_EXP_REVIEWS_TO_MODERATE" | translate}}</h4>
                            <br/>
                          </div>
                        </div>
                      </div>
                      <!------------------------- if the user has exp reviews to moderate : Loading reviews  ---------------------------->
                      <div class="row waiting-get-data-popup" *ngIf="!expReviewsAreLoaded">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                      </div>
                      <!------------------------- if the user has reviews to moderate ---------------------------->
                      <div *ngIf="expReviewsAreLoaded && expReviews.length!=0">
                        <div >
                          <!------- reviews with pagination-------->
                          <div *ngIf="expReviews.length > 5" class="listing-container">
                            <table class="responsive-table bordered">
                              <thead>
                              <tr>
                                <th scope="col" *ngFor="let item of tabHeadersExpReviews">{{item| translate}}</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr *ngFor="let item of expReviews   | paginate: { itemsPerPage: 5, currentPage: z , id: 'reviewExperiencesPage'}">
                                <td class="item-review">{{item.review}}</td>
                                <td>{{item.rating}}</td>
                                <td>{{item.created_date| date}}</td>
                                <td>{{item.updated_date | date}}</td>
                                <td>{{item.experience_name}}</td>
                                <td>{{item.review_creator.userDisplayName}}</td>
                                <td>
                                  <a [routerLink]="[experiencesLink + '/' + item.experience_id]">
                                    <span class="tooltiptext">{{'PREVIEW' | translate}}</span>
                                    <span class="db-list-edit"> <em class="fa fa-eye"> </em> </span>
                                  </a>
                                </td>
                                <td>
                                  <a *ngIf="item.number_dislikes!=0 || item.number_likes!=0" [class.isDisabled]="disabledButtonReset"
                                     (click)="resetLikesDislikes(item.id, item.experience_id)">
                                    <span class="tooltiptext">{{'RESET_LIKES_DISLIKES' | translate}}</span>
                                    <span class="db-list-edit"> <em class="fa fa-undo"> </em> </span>
                                  </a>
                                  <a *ngIf="item.number_dislikes==0 && item.number_likes==0" class="cursor-type">
                                    <span class="tooltiptext">{{'NO_RESET_LIKES_DISLIKES' | translate}}</span>
                                    <span class="db-list-edit"> <em class="fa fa-undo"> </em> </span>
                                  </a>
                                </td>
                                <td>
                                  <a (click)="validateExpReviewAction(item.id, item.experience_id, item.experience_name)" [class.isDisabled]="disabledButtonExpReviews">
                                    <span class="tooltiptext">{{'VALIDATE' | translate}}</span>
                                    <span class="db-list-edit"> <em class="fa fa-check">  </em> </span>
                                  </a>
                                </td>
                                <td>
                                  <a (click)="rejectExpReviewAction(item.id, item.experience_id, item.experience_name)" [class.isDisabled]="disabledButtonExpReviews">
                                    <span class="tooltiptext">{{'REJECT' | translate}}</span>
                                    <span class="db-list-edit"> <em class="fa fa-ban">  </em> </span>
                                  </a>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="row center-align paginate-rev"  *ngIf="expReviews.length > 5">
                            <pagination-controls (pageChange)="z = $event" id="reviewExperiencesPage"
                                                 previousLabel=""
                                                 nextLabel=""
                                                 directionLinks="true"
                                                 autoHide="false">
                            </pagination-controls>
                          </div>
                          <!------- end reviews with pagination-------->
                          <!------- reviews without pagination-------->
                          <div *ngIf="expReviews.length <= 5">
                            <table class="responsive-table bordered">
                              <thead>
                              <tr>
                                <th scope="col" *ngFor="let item of tabHeadersExpReviews">{{item| translate}}</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr *ngFor="let item of expReviews">
                                <td class="item-review">{{item.review}}</td>
                                <td>{{item.rating}}</td>
                                <td>{{item.created_date| date}}</td>
                                <td>{{item.updated_date | date}}</td>
                                <td>{{item.experience_name}}</td>
                                <td>{{item.review_creator.userDisplayName}}</td>
                                <td>
                                  <a [routerLink]="[experiencesLink + '/' + item.experience_id]">
                                    <span class="tooltiptext">{{'PREVIEW' | translate}}</span>
                                    <span class="db-list-edit"> <em class="fa fa-eye"> </em> </span>
                                  </a>
                                </td>
                                <td>
                                  <a *ngIf="item.number_dislikes!=0 || item.number_likes!=0" [class.isDisabled]="disabledButtonReset"
                                     (click)="resetLikesDislikes(item.id, item.experience_id)">
                                    <span class="tooltiptext">{{'RESET_LIKES_DISLIKES' | translate}}</span>
                                    <span class="db-list-edit"> <em class="fa fa-undo"> </em> </span>
                                  </a>
                                  <a *ngIf="item.number_dislikes==0 && item.number_likes==0" class="cursor-type">
                                    <span class="tooltiptext">{{'NO_RESET_LIKES_DISLIKES' | translate}}</span>
                                    <span class="db-list-edit"> <em class="fa fa-undo"> </em> </span>
                                  </a>
                                </td>
                                <td>
                                  <a (click)="validateExpReviewAction(item.id, item.experience_id, item.experience_name)" [class.isDisabled]="disabledButtonExpReviews">
                                    <span class="tooltiptext">{{'VALIDATE' | translate}}</span>
                                    <span class="db-list-edit"> <em class="fa fa-check">  </em> </span>
                                  </a>
                                </td>
                                <td>
                                  <a (click)="rejectExpReviewAction(item.id, item.experience_id, item.experience_name)" [class.isDisabled]="disabledButtonExpReviews">
                                    <span class="tooltiptext">{{'REJECT' | translate}}</span>
                                    <span class="db-list-edit"> <em class="fa fa-ban">  </em> </span>
                                  </a>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                          <!------- end reviews without pagination-------->
                        </div>
                      </div>
                      <!---------------- end experience reviews ---------------------->
                      <!---------------- template reviews ---------------------->
                      <h4 class="text-content">{{"TEMPLATE_REVIEW" | translate}}</h4>
                      <!------------- if the user doesn't have exp reviews to moderate -------------->
                      <div class="tz-2-com tz-2-main" *ngIf="tempReviews.length==0">
                        <div class="center ">
                          <div class="icon-experience-content"><i class="fas fa-cubes"></i></div>
                          <div class="center">
                            <h4>{{"YOU_HAVE_NOT_TEMP_REVIEWS_TO_MODERATE" | translate}}</h4>
                            <br/>
                          </div>
                        </div>
                      </div>
                      <!------------------------- if the user has exp reviews to moderate : Loading reviews  ---------------------------->
                      <div class="row waiting-get-data-popup " *ngIf="!tempReviewsAreLoaded">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                      </div>
                      <!------------------------- if the user has reviews to moderate ---------------------------->
                      <div *ngIf="tempReviewsAreLoaded && tempReviews.length!=0">
                        <div >
                          <!------- reviews with pagination-------->
                          <div *ngIf="tempReviews.length > 5" class="listing-container">
                            <table class="responsive-table bordered">
                              <thead>
                              <tr>
                                <th scope="col" *ngFor="let item of tabHeadersTempReviews">{{item| translate}}</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr *ngFor="let item of tempReviews   | paginate: { itemsPerPage: 5, currentPage: p , id: 'reviewTemplatesPage'}">
                                <td class="item-review">{{item.review}}</td>
                                <td>{{item.rating}}</td>
                                <td>{{item.created_date| date}}</td>
                                <td>{{item.updated_date | date}}</td>
                                <td>{{item.template_id}}</td>
                                <td>{{item.review_creator.userDisplayName}}</td>
                                <td>
                                  <a [routerLink]="[templatesLink + '/' + item.template_id]">
                                    <span class="tooltiptext">{{'PREVIEW' | translate}}</span>
                                    <span class="db-list-edit"> <em class="fa fa-eye"> </em> </span>
                                  </a>
                                </td>
                                <td>
                                  <a *ngIf="item.number_dislikes!=0 || item.number_likes!=0" [class.isDisabled]="disabledButtonResetTemp"
                                     (click)="resetTempLikesDislikes(item.id, item.template_id)">
                                    <span class="tooltiptext">{{'RESET_LIKES_DISLIKES' | translate}}</span>
                                    <span class="db-list-edit"> <em class="fa fa-undo"> </em> </span>
                                  </a>
                                  <a *ngIf="item.number_dislikes==0 && item.number_likes==0" class="cursor-type">
                                    <span class="tooltiptext">{{'NO_RESET_LIKES_DISLIKES' | translate}}</span>
                                    <span class="db-list-edit"> <em class="fa fa-undo"> </em> </span>
                                  </a>
                                </td>
                                <td>
                                  <a (click)="validateTempReviewAction(item.id, item.template_id, item.template_name)" [class.isDisabled]="disabledButtonTempReviews">
                                    <span class="tooltiptext">{{'VALIDATE' | translate}}</span>
                                    <span class="db-list-edit"> <em class="fa fa-check">  </em> </span>
                                  </a>
                                </td>
                                <td>
                                  <a (click)="rejectTempReviewAction(item.id, item.template_id, item.template_name)" [class.isDisabled]="disabledButtonTempReviews">
                                    <span class="tooltiptext">{{'REJECT' | translate}}</span>
                                    <span class="db-list-edit"> <em class="fa fa-ban">  </em> </span>
                                  </a>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="row center-align paginate-temp-rev"  *ngIf="tempReviews.length > 5">
                            <pagination-controls (pageChange)="p = $event" id="reviewTemplatesPage"
                                                 previousLabel=""
                                                 nextLabel=""
                                                 directionLinks="true"
                                                 autoHide="false">
                            </pagination-controls>
                          </div>
                          <!------- end reviews with pagination-------->
                          <!------- reviews without pagination-------->
                          <div *ngIf="tempReviews.length <= 5">
                            <table class="responsive-table bordered">
                              <thead>
                              <tr>
                                <th scope="col" *ngFor="let item of tabHeadersTempReviews">{{item| translate}}</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr *ngFor="let item of tempReviews">
                                <td class="item-review">{{item.review}}</td>
                                <td>{{item.rating}}</td>
                                <td>{{item.created_date| date}}</td>
                                <td>{{item.updated_date | date}}</td>
                                <td>{{item.template_name}}</td>
                                <td>{{item.review_creator.userDisplayName}}</td>
                                <td>
                                  <a [routerLink]="[templatesLink + '/' + item.template_id]">
                                    <span class="tooltiptext">{{'PREVIEW' | translate}}</span>
                                    <span class="db-list-edit"> <em class="fa fa-eye"> </em> </span>
                                  </a>
                                </td>
                                <td>
                                  <a *ngIf="item.number_dislikes!=0 || item.number_likes!=0" [class.isDisabled]="disabledButtonResetTemp"
                                     (click)="resetTempLikesDislikes(item.id, item.template_id)">
                                    <span class="tooltiptext">{{'RESET_LIKES_DISLIKES' | translate}}</span>
                                    <span class="db-list-edit"> <em class="fa fa-undo"> </em> </span>
                                  </a>
                                  <a *ngIf="item.number_dislikes==0 && item.number_likes==0" class="cursor-type">
                                    <span class="tooltiptext">{{'NO_RESET_LIKES_DISLIKES' | translate}}</span>
                                    <span class="db-list-edit"> <em class="fa fa-undo"> </em> </span>
                                  </a>
                                </td>
                                <td>
                                  <a (click)="validateTempReviewAction(item.id, item.template_id, item.template_name)" [class.isDisabled]="disabledButtonTempReviews">
                                    <span class="tooltiptext">{{'VALIDATE' | translate}}</span>
                                    <span class="db-list-edit"> <em class="fa fa-check">  </em> </span>
                                  </a>
                                </td>
                                <td>
                                  <a (click)="rejectTempReviewAction(item.id, item.template_id, item.template_name)" [class.isDisabled]="disabledButtonTempReviews">
                                    <span class="tooltiptext">{{'REJECT' | translate}}</span>
                                    <span class="db-list-edit"> <em class="fa fa-ban">  </em> </span>
                                  </a>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                          <!------- end reviews without pagination-------->
                        </div>
                      <!---------------- end template reviews ---------------------->
                    </div>
                  </div>
                </div>
                <!----------------------------------------------- Reviews: end ----------------------------------------------------->

                </section>
          </div>

          </div>

        </div>

    <div class="row waiting-get-data-popup " *ngIf="!experiencesAreLoaded || !templatesAreLoaded || !expReviewsAreLoaded || !tempReviewsAreLoaded">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
  </section>
</div>
