import {Component, OnInit} from '@angular/core';
import {INFORMATION_PAGE_TITLE} from '../../utils/actionsLabels/labels';
import {Title} from '@angular/platform-browser';
import {map} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-informations',
  templateUrl: './informations.component.html',
  styleUrls: ['./informations.component.scss']
})
export class InformationsComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private titleService: Title) {
  }

  informationPage = INFORMATION_PAGE_TITLE;
  source = '';
  content = 'editor do not allow mobile';
  downloadApp = false;

  ngOnInit(): void {
    // mobileLink

    this.route.paramMap.pipe(
      map(params => {
          this.titleService.setTitle(this.informationPage);
          window.scrollTo(0, 0);
          this.source = params.get('mobileLink');
          if (this.source !== null) {
            this.content = '';
            this.downloadApp = true;
          }
        }
      )
    ).subscribe(() => {
    });
  }

}
