<div class="db-list-com tz-db-table">
  <div class="ds-boar-title">
    <h2>{{"Groups I am in" | translate}}</h2>
  </div>
  <div class="tz-mess">
    <div *ngIf="data.length > 3">
    <ul class="list-container">
      <li *ngFor="let item of data  | paginate: { itemsPerPage: 3, currentPage: p}">
        <app-single-group-owner [disabledButton]="disabledButton"
                                [data]="item" [isOwner]="false"
                                (leaveGroup)="leaveGroupAction($event)"></app-single-group-owner>
      </li>
    </ul>
    <pagination-controls (pageChange)="p = $event"
                         previousLabel=""
                         nextLabel=""
                         directionLinks="true"
                         autoHide="false"></pagination-controls>
  </div>
    <ul *ngIf="data.length <= 3">
      <li *ngFor="let item of data" >
      <app-single-group-owner [disabledButton]="disabledButton" [data]="item" [isOwner]="false" (leaveGroup)="leaveGroupAction($event)"></app-single-group-owner>
      </li>
    </ul>
  </div>
</div>
