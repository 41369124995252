import {Component, OnInit} from '@angular/core';
import {User} from '../../reusables/interfaces/user';
import {AuthGuard} from '../../reusables/_helpers';
import {UsersService} from '../../reusables/services/users.service';

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit {

  user: User;
  constructor(private authGuard: AuthGuard,
              private usersService: UsersService) { }
  ngOnInit(): void {
    window.scrollTo(0, 0);
    if (this.authGuard.canActivate) {
      this.getProfile();
    }
  }
  getProfile() {
    this.usersService.getUserProfile().subscribe((data: User) => {
      this.user = data;
    });
  }

}
