<div class="tz-2-com tz-2-main">
  <h4>{{'DASHBOARD' | translate }}</h4>
<!--  <div class="tz-2-main-com">-->
<!--    <div class="tz-2-main-1">-->
<!--      <div class="tz-2-main-2"><img src="http://int.squeezer-software.com/camlann-int/assets/images/follower.png"-->
<!--                                    alt=""/><span>{{'Follower' | translate }}</span>-->
<!--        <p>{{'Total no of followers' | translate }}</p>-->
<!--        <h2>50</h2></div>-->
<!--    </div>-->
<!--    <div class="tz-2-main-1">-->
<!--      <div class="tz-2-main-2"><img src="http://int.squeezer-software.com/camlann-int/assets/images/review.png"-->
<!--                                    alt=""/><span>{{'Review' | translate }}</span>-->
<!--        <p>{{'Total no of reviews' | translate }}</p>-->
<!--        <h2>70</h2></div>-->
<!--    </div>-->
<!--    <div class="tz-2-main-1">-->
<!--      <div class="tz-2-main-2"><img src="http://int.squeezer-software.com/camlann-int/assets/images/like.png"-->
<!--                                    alt=""/><span>{{'Like' | translate }}</span>-->
<!--        <p>{{'Total no of likes' | translate }}</p>-->
<!--        <h2>100</h2></div>-->
<!--    </div>-->
<!--  </div>-->
  <div *ngIf="doneForUserExperiences && experiences.length > 0">
    <div class="db-list-com tz-db-table">
      <div class="ds-boar-title">
        <h2>{{'RECENT_EXPERIENCES' | translate }}</h2>
      </div>
      <br/>
      <div class="row span-none">
        <app-experience-grid
          *ngFor="let item of experiences" [data]="item"></app-experience-grid>
      </div>
      <div class="db-mak-pay-bot">
        <a [routerLink]="[myExperiencesLink]"
          class="waves-effect waves-light btn-large">{{'VIEW_MORE' | translate }}</a>
      </div>
    </div>
  </div>
  <div *ngIf="doneForUserGroups && groups.length > 0">
    <div class="db-list-com tz-db-table">
      <div class="ds-boar-title">
        <h2>{{'Recent Groups' | translate }}</h2>
      </div>
<!--      <br/>-->
      <div class="row span-none">
        <app-group-grid *ngFor="let item of groups" [data]="item"></app-group-grid>
      </div>
      <div class="db-mak-pay-bot">
        <a [routerLink]="[myGroupsLink]"
          class="waves-effect waves-light btn-large">{{'VIEW_MORE' | translate }}</a>
      </div>
    </div>
  </div>

  <!------------ no experiences and no groups --------->
  <div class="tz-2-com tz-2-main" *ngIf="experiences.length == 0 && groups.length == 0">
    <div class="center no_data_message">
      <div class="icon-content"><i class="fas fa-cubes"></i></div>
      {{"NO_EXPERIENCE_GROUP_MESSAGE" | translate}}
    </div>
  </div>
  <!------------ end no experiences and no groups  --------->
</div>
