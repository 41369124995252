import {Component, Input, OnInit} from '@angular/core';
import {Group} from '../../interfaces/group';

@Component({
  selector: 'app-group-grid',
  templateUrl: './group-grid.component.html',
  styleUrls: ['./group-grid.component.scss']
})
export class GroupGridComponent implements OnInit {

  @Input() data: Group;
  link;

  constructor() {
  }

  ngOnInit(): void {
    this.link = '/groups/' + this.data.id;
  }

}
