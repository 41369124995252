import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {
  ADD_MEMBERS_TO_GROUP_EVENT,
  CREATE_GROUP_PAGE_TITLE
} from '../../../utils/actionsLabels/labels';
import {groupRoles, RoleGroup} from '../../../utils/global-constants/groups-const';
import {UserNotMember} from '../../interfaces/user-not-member';


@Component({
  selector: 'app-manage-members-group',
  templateUrl: './manage-members-group.component.html',
  styleUrls: ['./manage-members-group.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageMembersGroupComponent implements OnInit {
  groupRoles = groupRoles;
  myForm: FormGroup;
  users: UserNotMember[] = [];
  members: any = [];
  groupMembers: any = [];
  usersRequest: any = [];
  screenSizeChanged = false;
  selectedIds: string[];
  defaultTypeMember = '1'; // member
  pageTitle = CREATE_GROUP_PAGE_TITLE;
  @Input() allUsers: any[];
  @Input() createdGroupId: string;
  @Input() groupName: string;
  @Input() groupId: string;
  @Input() showUsers: boolean;
  @Output() membersRequest = new EventEmitter();
  @Output() searchMembersRequest = new EventEmitter();

  constructor(private formBuilder: FormBuilder,
              private router: Router) {
    this.myForm = this.formBuilder.group({
      users: ['']
    });
  }

  ngOnInit(): void {
    if (window.innerWidth <= 1200) {
      this.screenSizeChanged = true;
    }
  }

  onSubmit(data) {
    this.members = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.users.length; i++) {
      const id = this.users[i]['member_id'];
      let roleMember;
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < this.groupMembers.length; j++) {
        // look for the entry with a matching `code` value
        if (this.groupMembers[j]['id'] === id) {
          // we found it
          roleMember = this.groupMembers[j]['role'];
          break;
        }
      }
      this.members.push({id: id, role: roleMember});
    }
    this.membersRequest.emit({action: ADD_MEMBERS_TO_GROUP_EVENT, data: this.members});
  }

  /**
   * skip add members step
   */
  skipAddUsers() {
    this.router.navigate(['/groups/' + this.groupId]);
  }

  /**
   * delete member from the list in bottom
   */
  deleteMemberFromList(item) {
    const aux = this.users.indexOf(item);
    this.users.splice(aux, 1);
    this.selectedIds = this.selectedIds.filter(id => id !== item.member_id);
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth <= 1200) {
      this.screenSizeChanged = true;
    } else {
      this.screenSizeChanged = false;
    }
  }


  /**
   * on change select members items, update members in bottom
   */
  onChange(event) {
    this.users = event;
    this.groupMembers = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.users.length; i++) {
      const item = this.users[i];
      this.groupMembers.push({id: item['member_id'], role: RoleGroup.MEMBER});
    }
  }
  changeMemberRole(event, id) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.groupMembers.length; i++) {
      // look for the entry with a matching `code` value
      if (this.groupMembers[i].id === id) {
        // we found it
        this.groupMembers[i].role = event.value;
      }
    }
  }

  /**
   * on search, send to parent component "term" to search and added members list
   */
  onSearch(event) {
    this.searchMembersRequest.emit({term: event.term, added: this.selectedIds});
  }
}
