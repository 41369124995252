<div class="gallery">
  <a target="_blank" >
    <img src="assets/images/camlann.png" alt="Cinque Terre">
  </a>
  <div class="desc">Add a description of the image here</div>
</div>

<div class="gallery">
  <a target="_blank" >
    <img src="assets/images/camlann.png" alt="Cinque Terre">
  </a>
  <div class="desc">Add a description of the image here</div>
</div>

<div class="gallery">
  <a target="_blank" >
    <img src="assets/images/camlann.png" alt="Cinque Terre">
  </a>
  <div class="desc">Add a description of the image here</div>
</div>

<div class="gallery">
  <a target="_blank" >
    <img src="assets/images/camlann.png" alt="Cinque Terre">
  </a>
  <div class="desc">Add a description of the image here</div>
</div>
<div class="gallery">
  <a target="_blank" >
    <img src="assets/images/camlann.png" alt="Cinque Terre">
  </a>
  <div class="desc">Add a description of the image here</div>
</div>
<div class="gallery">
  <a target="_blank" >
    <img src="assets/images/Video2.png" alt="Cinque Terre">
  </a>
  <div class="desc">Add a description of the image here</div>
</div>

<div class="gallery">
  <a target="_blank" >
    <img src="assets/images/Video2.png" alt="Cinque Terre">
  </a>
  <div class="desc">Add a description of the image here</div>
</div>
<div class="gallery">
  <a target="_blank" >
    <img src="assets/images/Video2.png" alt="Cinque Terre">
  </a>
  <div class="desc">Add a description of the image here</div>
</div>
