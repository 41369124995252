<div class="tz-2-com tz-2-main">
  <div class="center msg-content">
    <p>{{"ASK_FOR_CONFIRM_RESET_LIKE_DISLIKE_ACTION" | translate}}</p>
    <br/>
  </div>
</div>
<div class="row actions-popup">
  <button class="button  waves-effect waves-light btn-large save-popup-style"
          (click)="reset()">{{'RESET' | translate}}</button>
  <button class="button waves-effect waves-light btn-large"
          (click)="cancel()">{{'Cancel' | translate}}</button>
</div>
