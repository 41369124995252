import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Group} from '../../interfaces/group';

@Component({
  selector: 'app-groups-i-am-in',
  templateUrl: './groups-i-am-in.component.html',
  styleUrls: ['./groups-i-am-in.component.scss']
})
export class GroupsIAmInComponent implements OnInit {

  @Input() data: Group[];
  @Input() disabledButton;
  @Output() leaveGroupData = new EventEmitter();
  constructor() { }
  p = 1;
  ngOnInit(): void {
  }
  leaveGroupAction(event) {
    this.leaveGroupData.emit(event);
  }

}
