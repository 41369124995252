<div class="tz-2-com tz-2-main">
  <h4>{{pageTitle | translate }}</h4>
  <div class="db-list-com tz-db-table">
    <div class="hom-cre-acc-left hom-cre-acc-right">


        <form   [formGroup]="myForm" (ngSubmit)="onSubmit(myForm.value)">

          <div class="row waiting-get-data " *ngIf="!submitAction" >
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
          <div *ngIf="submitAction">
          <div class="row">
            <div class="db-v2-list-form-inn-tit">
              <h5>{{"GROUP_GENERAL_INFORMATION" | translate}}</h5>
            </div>
          </div>
          <!-------------------Name------------------->
          <div class="row">
            <div class="input-field col s12">
              <input type="text" id="name"
                     class="form-control" formControlName="name" maxlength="25" (keyup)="substrName()"
                     [ngClass]="myForm.get('name').invalid && myForm.get('name').touched ? 'invalid': 'validate'"
              />
              <label *ngIf="myForm.get('name').value === ''" for="name">{{"NAME_GROUP" | translate}}</label>
              <span class="v2-db-form-note">{{"name_group_info" | translate}}</span>
            </div>
          </div>
          <div class="row "  *ngIf="myForm.get('name').invalid && myForm.get('name').touched">
            <div class="alert alert-danger">{{'GROUP_NAME_IS_REQUIRED' | translate}}</div>
          </div>
          <!-------------------description------------------->
          <div class="row">
            <div class="input-field col s12">
              <textarea id="description"
                        class="materialize-textarea"
                        formControlName="description"
                        autosize></textarea>
              <label *ngIf="myForm.get('description').value === ''"
                     for="description">{{"DESCRIPTION_GROUP" | translate}}</label>
            </div>
          </div>
          <br>
          <!-------------------Privacy------------------->
          <div class="row">
            <div class="chec-out-pay">
              <div class="privacy-title db-v2-list-form-inn-tit">
                <h5>{{"Privacy" | translate}}</h5>
              </div>
              <input class="privacy-input" name="privacy" type="radio" formControlName="privacy"
                     value= "{{privacy[0].value}}"
                     *ngIf="group && groupTypeOrders[group.type] > groupTypeOrders[privacy[0].value]"
                     disabled
                      />
              <input class="privacy-input" name="privacy" type="radio" id="public"  formControlName="privacy"
                     value= "{{privacy[0].value}}" (change)="changePrivacy($event)"
                     *ngIf="!group || (group && groupTypeOrders[group.type] <= groupTypeOrders[privacy[0].value])"
                      />
              <label class="privacy-label"
                     [ngClass]="privacy[0].value === myForm.get('privacy').value? activeRadioLabel : notActiveRadioLabel"
                     for="public">{{privacy[0].htmlValue | translate}}</label>

              <input class="privacy-input" name="privacy" type="radio" formControlName="privacy"
                     value= "{{privacy[1].value}}"
                     *ngIf="group && groupTypeOrders[group.type] > groupTypeOrders[privacy[1].value]"
                     disabled
              />
              <input class="privacy-input" name="privacy" type="radio" id="private" formControlName="privacy"
                     value= "{{privacy[1].value}}" (change)="changePrivacy($event)"
                     *ngIf="!group || (group && groupTypeOrders[group.type] <= groupTypeOrders[privacy[1].value])" />
              <label class="privacy-label"
                     [ngClass]="privacy[1].value === myForm.get('privacy').value ? activeRadioLabel : notActiveRadioLabel"
                     for="private">{{privacy[1].htmlValue | translate}}</label>

              <input class="privacy-input" name="privacy" type="radio" id="closed" formControlName="privacy"
                     value= "{{privacy[2].value}}" (change)="changePrivacy($event)"  />
              <label class="privacy-label"
                     [ngClass]="privacy[2].value === myForm.get('privacy').value ? activeRadioLabel : notActiveRadioLabel"
                     for="closed">{{privacy[2].htmlValue | translate}}</label>

            </div>
          </div>
          <br>
          <!-------------------Icon Image ------------------->
          <div class="row">
            <div class="db-v2-list-form-inn-tit">
              <h5>{{"Icon Image" | translate}} </h5>
            </div>
          </div>
          <div class="row tz-file-upload">
            <div class="file-field input-field">
              <div class="tz-up-btn" *ngIf="iconUrl === ''" title="{{'CLICK_TO_ADD_ICON_PHOTO' | translate}}">
                <span (click)="addIconPhoto()">{{"File" | translate}}</span>
                <input type="file" id="iconPicture"
                       class="form-control"
                       accept="image/png, image/jpeg, image/jpg"
                       (change)="onIconProfileChange($event)">
              </div>
              <div *ngIf="iconUrl !== ''" class="input-field col s12 ">
                <div class="thumbnail-content pl-45">
                  <div class="item-number inline"></div>
                  <div class="img-thumbnail-content inline">
                    <img [src]="iconUrl" *ngIf="iconUrl && iconUrl !== ''"  height="45"/>
                    <img [src]="iconCroppedImage" *ngIf="iconCroppedImage && iconCroppedImage !== ''" alt=""  height="45"/>

                  </div>
                  <input type="text" value="{{iconFileName}}" disabled class="inline"/>
                  <input type="text" name="cover-profile-name" class="inline"
                         placeholder="{{'OPTIONAL_DESCRIPTION' | translate}}" (change)="changeIconPicture($event)"/>
                  <button class="btn btn-danger inline" (click)="deleteIconPicture()"
                          title="{{'DELETE_COVER_EXPERIENCE' | translate}}"><i class="fa fa-trash"></i></button>
                </div>
                <image-cropper *ngIf="fileAvatar"
                               [imageFile]="fileAvatar"
                               [maintainAspectRatio]="true"
                               [containWithinAspectRatio]="false"
                               [aspectRatio]="1"
                               [cropperMinWidth]="100"
                               [onlyScaleDown]="true"
                               [roundCropper]="false"
                               [canvasRotation]="0"
                               [alignImage]="'center'"
                               format="png"
                               (imageCropped)="iconImageCropped($event)"
                ></image-cropper>
              </div>
            </div>
          </div>
          <br/>
          <!-------------------Cover Image ------------------->
          <div class="row">
            <div class="db-v2-list-form-inn-tit">
              <h5>{{"Cover Image" | translate}} </h5>
            </div>
          </div>
          <div class="row tz-file-upload">
            <div class="file-field input-field">
              <div class="tz-up-btn" *ngIf="coverUrl === ''" title="{{'CLICK_TO_ADD_COVER_PHOTO' | translate}}">
                <span (click)="addCoverPhoto()">{{"File" | translate}}</span>
                <input type="file" id="coverPicture"
                       class="form-control"
                       accept="image/png, image/jpeg, image/jpg"
                       (change)="onCoverProfileChange($event)">
              </div>
              <div *ngIf="coverUrl !== ''" class="input-field col s12 ">
                <div class="thumbnail-content pl-45">
                  <div class="item-number inline"></div>
                  <div class="img-thumbnail-content inline">
                    <img [src]="coverUrl" *ngIf="coverUrl && coverUrl !== ''" alt=""  />
                    <img [src]="coverCroppedImage" *ngIf="coverCroppedImage && coverCroppedImage !== ''" alt=""  />
                  </div>
                  <input type="text" value="{{coverFileName}}" disabled class="inline"/>
                  <input type="text" name="cover-profile-name" class="inline"
                         placeholder="{{'OPTIONAL_DESCRIPTION' | translate}}" (change)="changeCoverPicture($event)"/>
                  <button class="btn btn-danger inline" (click)="deleteProfileCover()"
                          title="{{'DELETE_COVER_EXPERIENCE' | translate}}"><i class="fa fa-trash"></i></button>
                </div>
                <image-cropper *ngIf="fileCoverPicture"
                               [imageFile]="fileCoverPicture"
                               [maintainAspectRatio]="true"
                               [containWithinAspectRatio]="false"
                               [aspectRatio]="1920/570"
                               [cropperMinWidth]="100"
                               [onlyScaleDown]="true"
                               [roundCropper]="false"
                               [canvasRotation]="0"
                               [alignImage]="'center'"
                               format="image/png"
                               (imageCropped)="coverImageCropped($event)"
                ></image-cropper>
              </div>
            </div>
          </div>
          <br/>
          <!-------------------buttons ------------------->
          <div class="actions-container row">
            <button type="submit" [disabled]="myForm.invalid" class="waves-effect waves-light btn-large "
                    title="{{buttonTextTitle | translate}}">
              {{buttonText | translate}}
            </button>
            <button type="button"
                    class="waves-effect waves-light btn-large"
                    title="{{'Cancel' | translate}}"
                    (click)="resetForm()">{{'Cancel' | translate}}</button>
          </div>
        </div>
        </form>
    </div>
  </div>
</div>
