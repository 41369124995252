<div class="tz-2-com tz-2-main">
  <a [routerLink]="createGroupUrl">
    <div class="center create-first-experience-content">
      <div class="icon-experience-content"><i class="fas fa-cubes"></i></div>
      <div class="center">
        <h3>{{"do_not_have_group_msg" | translate}}</h3>
        <br/>
        <p>{{"go_to_add_group" | translate}}</p>
        <br/>
      </div>
      <div class="waves-effect waves-light btn-large full-btn">{{"Create" | translate}}</div>

    </div>
  </a>
</div>
