import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-reset-like-dislike-dialog',
  templateUrl: './reset-like-dislike-dialog.component.html',
  styleUrls: ['./reset-like-dislike-dialog.component.scss']
})
export class ResetLikeDislikeDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<any>) { }

  ngOnInit(): void {
  }

  reset() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close();
  }

}
