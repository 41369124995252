<div class="tz-2-com tz-2-main">
  <div class="center create-template">
    <div class="icon-template-content"><i class="far fa-clone"></i></div>
    <div class="center">
      <h3>{{"from this template" | translate}}</h3>
      <br/>
      <!----------------------------------  buttons   ------------------------->
      <div class="row button-content">
        <button class="button waves-effect waves-light btn-large" (click)="createTemplate()">{{'create another template' | translate}}</button>
        <button class="button waves-effect waves-light btn-large" (click)="createExperience()">{{'create experience' | translate}}</button>
      </div>
    </div>
  </div>
</div>


