import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {UsersService} from '../../reusables/services/users.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../reusables/services/notification.service';
import {Title} from '@angular/platform-browser';
import {COMING_SOON, ERROR_POPUP_MESSAGE, FORGET_PASSWORD_PAGE_TITLE} from '../../utils/actionsLabels/labels';
import {LOGIN_PATH, SIGNUP_PATH} from '../../utils/actionsLabels/pathLabels';
import {emailForm} from '../../utils/global-constants/regExp';
import {AngularFireAuth} from '@angular/fire/auth';
import {RESET_PASSWORD_RESPONSE, RESET_PASSWORD_RESPONSE_TITLE} from '../../utils/actionsLabels/firebaseCommuniaction';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  forgetPasswordForm;
  loginLink = LOGIN_PATH;
  signUpLink = SIGNUP_PATH;
  isSubmitted = false;

  constructor(private formBuilder: FormBuilder,
              private  usersService: UsersService,
              private router: Router,
              private notificationService: NotificationService,
              private translate: TranslateService,
              private titleService: Title,
              private afAuth: AngularFireAuth) {
    this.forgetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required,
        Validators.pattern(emailForm)]]
    });
  }

  ngOnInit(): void {
    // this.titleService.setTitle(FORGET_PASSWORD_PAGE_TITLE);
    this.translate.get(FORGET_PASSWORD_PAGE_TITLE).subscribe((res: string) => {
      this.titleService.setTitle(res);
    });
    window.scrollTo(0, 0);
  }

  onSubmit(emailData) {
    this.isSubmitted = true;
    // TODO correct WS implementation
    // this.usersService.forgetPassword(emailData.email).subscribe(() => {
    //   this.notificationService.showSuccess('An email has been sent to your address, please follow the instructions to ' +
    //     'reset your password', 'Please check your email !');
    //   const myUrl = LOGIN_PATH;
    //   this.router.navigateByUrl(myUrl).then();
    // });

    const email = emailData.email;
    this.afAuth.sendPasswordResetEmail(email).then(
      () => {
        // success, show some message
        this.router.navigateByUrl(LOGIN_PATH).then();
        this.notificationService.showSuccess(RESET_PASSWORD_RESPONSE, RESET_PASSWORD_RESPONSE_TITLE);
      },
      err => {
        this.notificationService.showSuccess(ERROR_POPUP_MESSAGE, '');
      }
    );
  }
  showMessage() {
    this.notificationService.showWarning(COMING_SOON, '');
  }
}
