import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as $ from 'jquery';

@Component({
  selector: 'app-import3-dfiles',
  templateUrl: './import3-dfiles.component.html',
  styleUrls: ['./import3-dfiles.component.scss']
})
export class Import3DFilesComponent implements OnInit {
  form: FormGroup;
  textureFiles = [];

  constructor(public dialogRef: MatDialogRef<any>) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      objFile: new FormControl(null, [Validators.required]),
    });
  }

  onImport(): void {
    this.dialogRef.close({
      file: this.form.controls.objFile.value,
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  addObjFile() {
    $('#objFile').click();
  }

  addTextureFiles() {
    $('#textureFiles').click();
  }

  onObjfileChange(event) {
    if (event.target.files.length > 0) {
      const uri = event.target.value;
      const extIndex = uri.lastIndexOf('.');
      const ext = uri.substring(extIndex + 1, uri.length);
      if ('zip' === ext) {
        const file = (event.target as HTMLInputElement).files[0];
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (e) => { // called once readAsDataURL is completed
          this.form.controls.objFile.setValue(file);
        };
      }

    }
  }


  deleteObjFile() {
    this.form.controls.objFile.setValue(null);
  }

  deleteTextureFiles(index) {
    this.textureFiles.splice(index, 1);
    this.form.controls.textureFiles.setValue(this.textureFiles);
  }

}
