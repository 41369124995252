import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FirstMenuComponent} from '../../editor-menu/first-menu/first-menu.component';
import {EditorElementType} from '../../../../utils/global-constants/editor-const';

@Component({
  selector: 'app-marker-type-dialog',
  templateUrl: './marker-type-dialog.component.html',
  styleUrls: ['./marker-type-dialog.component.scss']
})
export class MarkerTypeDialogComponent implements OnInit {
  marker = EditorElementType.marker.toString();
  surface = EditorElementType.surface.toString();
  geo = EditorElementType.geo.toString();

  constructor(
    public dialogRef: MatDialogRef<FirstMenuComponent>,
    @Inject(MAT_DIALOG_DATA) public msg) {
  }

  onNoClick(source): void {
    this.dialogRef.close(source);
  }

  ngOnInit(): void {
  }
}
