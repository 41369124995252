import {Component, Input, OnChanges, OnInit} from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit, OnChanges {

  constructor() { }
  @Input() rating;
  half = false;
  intValue: number;
  emptyStars = 0;
  ngOnInit(): void {
    this.prepareRating();
  }
  ngOnChanges(): void {
    this.prepareRating();
  }
  prepareRating() {
    this.half = false;
    // tslint:disable-next-line:radix
    this.intValue = parseInt(this.rating);
    this.emptyStars = 5 - this.intValue;
    // tslint:disable-next-line:radix
    if ((+this.rating - parseInt(this.rating)) > 0) {
      this.half = true;
      this.emptyStars --;
    }
  }

  arrayOne(n: number): any[] {
    return Array(n);
  }

}
