<div class="home-list-pop list-spac col-experience">
    <!------------------- single template--------------------------->
  <a *ngIf="data.type == template" [routerLink]="linkTemplate + data.id">
    <div class="list-ser-img">
      <img *ngIf="data.cover_picture && data.cover_picture != ''" src="{{data.cover_picture}}">
    </div>
    <div class="home-list-pop-desc inn-list-pop-desc">
       <div class="bloc">
         <div class="inline-trigger img-trigger item-name">
           <a class="">{{data.name}}</a>
            <!------------ trigger for template --------------->
<!--            <img *ngIf="data.trigger == surface" src="assets/images/surface.png" [title]="surfaceText">-->
            <i *ngIf="data.trigger == surface" class="fa fa-th" [title]="surfaceText"></i>
            <i *ngIf="data.trigger == marker" class="far fa-image" [title]="markerText"></i>
            <!------------ end trigger for template --------------->
         </div>
          <p class="ellipsis-style-description">{{data.description}}</p>
       </div>
      <div class="list-enqu-btn">
        <!------------ buttons for template --------------->
        <ul *ngIf="data.type == template">
          <li>
            <a (click)="useTemplateOrExperience(fromTemplate, template, data.content)" [routerLink]="createPath">
              <i class="fa fa-plus" aria-hidden="true"></i> {{'USE' | translate}}
            </a>
          </li>
          <li>
            <a>
              <i class="fa fa-play" aria-hidden="true"></i>{{'SEE-TEMPLATE-DETAILS' | translate}}
            </a>
          </li>
        </ul>
        <!--------- end buttons for template -------------->
      </div>
    </div>
  </a>
  <!------------------- end single template--------------------------->
  <!------------------- single experience --------------------------->
  <a *ngIf="data.type == experience" [routerLink]="linkExperience + data.id">
    <div class="list-ser-img">
      <img *ngIf="data.coverPicture && data.coverPicture != ''" src="{{data.coverPicture}}">
    </div>
    <div class="home-list-pop-desc inn-list-pop-desc">
       <div class="bloc">
         <div class="inline-trigger img-trigger">
             {{data.name}}
         </div>
          <p class="ellipsis-style-description">{{data.description}}</p>
       </div>
      <div class="list-enqu-btn">
        <!------------ buttons for experience --------------->
        <ul *ngIf="data.type == experience">
          <li>
            <a (click)="useTemplateOrExperience(fromExperience, experience, data.content)" [routerLink]="createPath">
              <i class="fa fa-plus" aria-hidden="true"></i> {{'USE' | translate}}
            </a>
          </li>
          <li>
            <a>
              <i class="fa fa-play" aria-hidden="true"></i>{{'SEE-EXPERIENCE-DETAILS' | translate}}
            </a>
          </li>
        </ul>
        <!--------- end buttons for experience ------------>
      </div>
    </div>
  </a>
  <!------------------- end single experience --------------------------->
</div>

