import * as GUI from 'babylonjs-gui';
import * as BABYLON from 'babylonjs';
import {fromMToPx, fromPxToM} from '../../../utils/functions/PositionFunction';
import {v4 as uuidv4} from 'uuid';
import {round} from '../../../utils/functions/GlobalFunctions';
import {FOUR_DECIMALS, TOW_DECIMALS} from '../../../utils/global-constants/forModel';
import {changeScale} from '../../../utils/functions/sizeFunction';
import {DEFAULT_FILE_NAME, DEFAULT_IMAGE_URL, EditorElementType} from '../../../utils/global-constants/editor-const';

export class Marker {
  public uniqueId;
  public url: string;
  public fileName: string;
  public name = EditorElementType.marker.toString();
  public type = EditorElementType.marker.toString();
  public visible = true;

  public width = 0;
  public height = 0;
  public nbrCopy = 0;

  public rotation = {x: 0, y: 0, z: 0};
  public position = {x: 0, y: 100, z: 1700};
  public scale = {x: 1, y: 1, z: 1};

  // recreate=true: si c'est un objet dans content (database)
  constructor(object?, recreate?, name?, position?,  url?, fileName?) {
    if (object) {
      this.fileName = object.fileName;
      if (recreate) {
        this.width = fromMToPx(object.width / object.scale);
        this.height = fromMToPx(object.height / object.scale);
        this.scale = {x: object.scale, y: object.scale, z: object.scale};
        this.url = object.uri;
        this.name = object.name;
      } else {
        this.width = object.width;
        this.height = object.height;
        this.url = object.url;
        this.visible = object.visible;
        this.scale = {x: object.scale.x, y: object.scale.y, z: object.scale.z};
      }
    }
    if (name) {
      this.name = name;
    }
    if (position) {
      this.position = position;
    }
    if (fileName) {
      this.fileName = fileName;
    }
    if (url) {
      this.url = url;
    }
  }

  addMarkerFromObjScene(scene: BABYLON.Scene, recreate?) {
    let checkFile = false;
    const x = new XMLHttpRequest();
    x.timeout = 15000;
    x.open('GET', this.url);
    x.onreadystatechange = ( event) => {
      if ( checkFile === false ) {
        checkFile = true;
        if ( x.status === 404 ) {
          this.url = DEFAULT_IMAGE_URL;
          this.height = 0 ;
          this.width = 0;
          this.fileName = DEFAULT_FILE_NAME;
        }
        const image = new GUI.Image(' ', this.url);
        image.onImageLoadedObservable.add(() => {
      if (this.height === 0 && this.width === 0) {
        image.heightInPixels = image.domImage.naturalHeight;
        image.widthInPixels = image.domImage.naturalWidth;
        this.height = image.heightInPixels;
        this.width = image.widthInPixels;
      }
      const plane = BABYLON.MeshBuilder.CreateGround(EditorElementType.marker.toString(), {
        width: this.width,
        height: this.height
      }, scene);
      // if size > 50cm
      if (!recreate) {this.scale = changeScale(this.width, this.height, 0, this.scale); }
      plane.position = new BABYLON.Vector3(this.position.x, this.position.y, this.position.z);
      plane.scaling = new BABYLON.Vector3(this.scale.x, this.scale.y, this.scale.z);


      const advancedTextureImage = GUI.AdvancedDynamicTexture.CreateForMesh(plane , this.width , this.height);
      advancedTextureImage.addControl(image);
      plane.renderingGroupId = 1;
      plane.isVisible = this.visible;
      plane.uniqueId = uuidv4();
      this.uniqueId = plane.uniqueId;
      return plane;

    });

      }
    };
    x.send();
  }

  recreate(scene) {
    this.addMarkerFromObjScene(scene, true);
  }

  editFile(scene) {
    let checkFile = false;
    const x = new XMLHttpRequest();
    x.timeout = 15000;
    x.open('GET', this.url);
    x.onreadystatechange = ( event) => {
      if ( checkFile === false ) {
        checkFile = true;
        if ( x.status === 404 ) {
          this.url = DEFAULT_IMAGE_URL;
          this.height = 0 ;
          this.width = 0;
          this.fileName = DEFAULT_FILE_NAME;
        }
        const image = new GUI.Image(' ', this.url);
        image.onImageLoadedObservable.add(() => {
      image.heightInPixels = image.domImage.naturalHeight;
      image.widthInPixels = image.domImage.naturalWidth;
      this.height = image.heightInPixels;
      this.width = image.widthInPixels;
      const plane = BABYLON.MeshBuilder.CreateGround(EditorElementType.marker.toString(), {
        width: this.width,
        height: this.height
      }, scene);

      plane.position = new BABYLON.Vector3(this.position.x, this.position.y, this.position.z);
      plane.scaling = new BABYLON.Vector3(this.scale.x, this.scale.y, this.scale.z);

      const advancedTextureImage = GUI.AdvancedDynamicTexture.CreateForMesh(plane, this.width, this.height);
      advancedTextureImage.addControl(image);
      plane.renderingGroupId = 1;
      plane.isVisible = this.visible;
      plane.uniqueId = this.uniqueId;
      return plane;
    });
      }
    };
    x.send();
  }

  outputJson() {
    // round function : pour limiter le nombre des chiffres après la virgule
    return {
      id: this.uniqueId,
      name: this.name,
      uri: this.url,
      fileName: this.fileName,
      position: {
        x: round(fromPxToM(this.position.x ), FOUR_DECIMALS),
        y: round(fromPxToM(this.position.y), FOUR_DECIMALS),
        z: round(fromPxToM(this.position.z), FOUR_DECIMALS),
      },
      scale: round(this.scale.x, TOW_DECIMALS),
      width: round(fromPxToM(this.width * this.scale.x), FOUR_DECIMALS),
      height: round(fromPxToM(this.height * this.scale.x), FOUR_DECIMALS),
    };
  }

  /*changeScale() {
    if (fromPxToCm(this.width) * this.scale.x > 50 || fromPxToCm(this.height) > 50) {
      const scale = 50 / Math.max(fromPxToCm(this.width), fromPxToCm(this.height))
      this.scale = {x: scale, y: scale, z: scale};
    }
  }*/
}

