import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-validate-item-dialog',
  templateUrl: './validate-item-dialog.component.html',
  styleUrls: ['./validate-item-dialog.component.scss']
})
export class ValidateItemDialogComponent implements OnInit {
  form: FormGroup;
  isTemplate = false;
  isReview = false;
  isReviewTemp = false;

  constructor(public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    if (this.data.type == 'template') {
      this.isTemplate = true;
    }
    if (this.data.type == 'review') {
      this.isReview = true;
    }
    if (this.data.type == 'reviewTemplate') {
      this.isReviewTemp = true;
    }
  }

  validate() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close();
  }

}
