import {Injectable} from '@angular/core';
import {errorsManager} from '../../utils/errorsReactions/errors-status';
import {NotificationService} from './notification.service';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {COOKIES_CONNECTED_USER_EMAIL, COOKIES_CONNECTED_USER_ID} from '../../utils/actionsLabels/labels';

@Injectable({
  providedIn: 'root'
})
export class ErrorManagerService {
  constructor(private router: Router,
              private notificationService: NotificationService,
              private cookieService: CookieService) {
  }

  // handleError(error) {
  //   console.log(error.status)
  //   if (error.status) {
  //     for (const aux of errorsManager) {
  //       if (aux.status && aux.status === error.status) {
  //         switch (aux.action) {
  //           case actionsErrorsManager[0]:
  //             this.router.navigateByUrl(aux.path).then();
  //             break;
  //           case actionsErrorsManager[1]:
  //             let message = '';
  //             if (error.description) {
  //               message = error.description;
  //             }
  //             this.notificationService.showError(message, aux.title);
  //             break;
  //           case actionsErrorsManager[2]:
  //             this.removeCookies();
  //             break;
  //           default:
  //             this.router.navigateByUrl(OOPS_PATH).then();
  //             break;
  //         }
  //       }
  //     }
  //   }
  // }

  removeCookies() {
    this.cookieService.delete(COOKIES_CONNECTED_USER_ID);
    this.cookieService.delete(COOKIES_CONNECTED_USER_EMAIL);
  }

  getErrorTitle(pathValue): string {
    for (const aux of errorsManager) {
      if ( aux.path && aux.path === pathValue) {
          return aux.title;
      }
    }
  }
  getErrorContent(pathValue): string {
    for (const aux of errorsManager) {
      if ( aux.path && aux.path === pathValue) {
        return aux.content;
      }
    }
  }
}
