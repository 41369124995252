import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-nearby-listing',
  templateUrl: './nearby-listing.component.html',
  styleUrls: ['./nearby-listing.component.scss']
})
export class NearbyListingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
