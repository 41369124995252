import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  LABEL_SUGGESTED_GROUPS,
  PAGE_TITLE_PREFIX,
  SUCCESS_JOIN_REQUEST_SENT_MESSAGE,
  SUCCESS_JOIN_REQUEST_SENT_TITLE,
  SUGGESTED_GROUPS_PAGE_TITLE
} from '../../utils/actionsLabels/labels';
import {GroupsService} from '../../reusables/services/groups.service';
import {Group} from '../../reusables/interfaces/group';
import {CookieService} from 'ngx-cookie-service';
import {Title} from '@angular/platform-browser';
import {NotificationService} from '../../reusables/services/notification.service';
import {HttpParams} from '@angular/common/http';
import {RANDOM_FILTER, SORT_FILTER} from '../../utils/queryParams/groupsQueryParams';
import {TokenHelperService} from '../../reusables/services/token-helper.service';
import {LoginDialogComponent} from '../../reusables/components/popups/login-dialog/login-dialog.component';
import {LOGIN_PATH} from '../../utils/actionsLabels/pathLabels';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-suggested-groups-page',
  templateUrl: './suggested-groups-page.component.html',
  styleUrls: ['./suggested-groups-page.component.scss', '../../../assets/customCss/listingPages.css']
})
export class SuggestedGroupsPageComponent implements OnInit, OnDestroy {
  pageTitle = LABEL_SUGGESTED_GROUPS;
  doneForSuggestedGroups = false;
  groups: Group[] = [];
  p = 1; // initial page in pagination of groups
  isLogged;
  userId;
  dialogRef;
  destroyComponent = false;
  constructor(private cookieService: CookieService,
              private groupsService: GroupsService,
              private titleService: Title,
              private notificationService: NotificationService,
              private tokenHelperService: TokenHelperService,
              private router: Router,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.titleService.setTitle(PAGE_TITLE_PREFIX + SUGGESTED_GROUPS_PAGE_TITLE);
    this.isLogged = this.tokenHelperService.getConnectedUser();
    if (this.isLogged) {
      this.userId = this.tokenHelperService.getTokenData().user_id;
    }
    window.scrollTo(0, 0);
    this.getSuggestedGroups();

  }
  ngOnDestroy(): void {
    this.destroyComponent = true;
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  private getSuggestedGroups() {
    let paramsGroups = new HttpParams();
    paramsGroups = paramsGroups.append(SORT_FILTER, RANDOM_FILTER);
    this.groupsService.getGroups(paramsGroups).subscribe((data: Group[]) => {
      this.groups = data;
      this.doneForSuggestedGroups = true;
    });
  }

  onItemGroupClick(event) {
    if (this.isLogged) {
      this.joinGroup(event.output);
    } else {
      this.openLoginPopup();
    }
  }

  openLoginPopup() {
    if (this.destroyComponent !== true) { // prevent popup from appearing on other pages
      this.dialogRef = this.dialog.open(LoginDialogComponent, {
        disableClose: false,
        autoFocus: true,
        panelClass: 'my-dialog',
        width: '800px',
        maxHeight: '500px',
      });
      this.dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.router.navigate([LOGIN_PATH], {queryParams: {returnUrl: this.router.url}}).then(); // queryParams pour stocker l'ancien url
        }
      });
    }
  }

  joinGroup(id) {
    this.groupsService.askJoinGroup(id).subscribe(() => {
      this.notificationService.showSuccess(SUCCESS_JOIN_REQUEST_SENT_MESSAGE, SUCCESS_JOIN_REQUEST_SENT_TITLE );
      this.getSuggestedGroups();
      });
  }
}
