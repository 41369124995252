export const statusClass = [
  {class: 'db-list-status', label: 'PUBLISHED'},
  {class: 'db-list-status-unpublished', label: 'UNPUBLISHED'},
  {class: 'db-list-status-na', label: 'DRAFT'},
  {class: '', label: 'DELETE'},
  {class: 'db-list-status-in-moderation', label: 'IN_MODERATION'}
  ];
export const experienceStatus = [
  {
    label: 'PUBLISHED',
    value: 0,
  },
  {
    label: 'UNPUBLISHED',
    value: 1,
  },
  {
    label: 'DRAFT',
    value: 2,
  },
  {
    label: 'DELETE',
    value: 3,
  },
  {
    label: 'IN_MODERATION',
    value: 4,
  }
];

export const editExperienceStatusAdmin = [
  {
    label: 'PUBLISHED',
    value: 0,
  },
  {
    label: 'UNPUBLISHED',
    value: 1,
  },
  {
    label: 'DRAFT',
    value: 2,
  }
];
export const editExperienceStatusUser = [
  {
    label: 'PUBLISHED',
    value: 0,
  },
  {
    label: 'SUBMIT_FOR_MODERATION',
    value: 4,
  },
  {
    label: 'UNPUBLISHED',
    value: 1,
  },
  {
    label: 'DRAFT',
    value: 2,
  }
];
