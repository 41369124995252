<!--<a [routerLink]="[linkDetails]" *ngIf="!homeSection">-->
  <div class="listing-experiences-item home-list-pop" *ngIf="!homeSection">
    <div class="item-cover-picture" [style]="customStyle"></div>
    <div class="item-name" title="{{data.name}}"><a [routerLink]="[linkDetails]">{{data.name}}</a></div>
    <div class="item-description">{{data.description}}</div>
    <div class="item-action">
      <a [routerLink]="[linkDetails]">
        <i class="fa fa-play" aria-hidden="true"></i>{{'SEE-EXPERIENCE-DETAILS' | translate}}
      </a>
    </div>
  </div>
<!--</a>-->

<!--<a [routerLink]="[linkDetails]" *ngIf="homeSection">-->
  <div class="listing-experiences-item-home home-list-pop" *ngIf="homeSection">
    <div class="item-cover-picture" [style]="customStyle"></div>
    <div class="item-name" title="{{data.name}}"><a [routerLink]="[linkDetails]">{{data.name}}</a></div>
    <div class="item-description">{{data.description}}</div>
    <div class="item-action">
      <a [routerLink]="[linkDetails]">
        <i class="fa fa-play" aria-hidden="true"></i>{{'SEE-EXPERIENCE-DETAILS' | translate}}
      </a>
    </div>
  </div>
<!--</a>-->
