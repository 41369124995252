import {Component, Input, OnInit} from '@angular/core';
import {MemberInGroup} from '../../../interfaces/Member';
import {RoleGroup} from '../../../../utils/global-constants/groups-const';

@Component({
  selector: 'app-group-details-member',
  templateUrl: './group-details-member.component.html',
  styleUrls: ['./group-details-member.component.scss']
})
export class GroupDetailsMemberComponent implements OnInit {

  @Input() member: MemberInGroup;
  innerWidth = window.innerWidth;
  MANAGER = RoleGroup.MANAGER;
  constructor() {
  }

  ngOnInit(): void {
  }
}
