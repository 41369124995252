import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ExperiencesComponent} from './pages/experiences/experiences.component';
import {DetailsExperienceComponent} from './pages/details-experience/details-experience.component';
import {NotFountComponent} from './pages/not-fount/not-fount.component';
import {ProfileComponent} from './pages/profile/profile.component';
import {LoginComponent} from './pages/login/login.component';
import {SignupComponent} from './pages/signup/signup.component';
import {ComingSoonComponent} from './pages/coming-soon/coming-soon.component';
import {EditorComponent} from './pages/editor/editor.component';
import {SecureInnerPagesService} from './utils/authGuards/secure-inner-pages.service';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {InformationsComponent} from './pages/informations/informations.component';
import {FormExperienceComponent} from './pages/form-experience/form-experience.component';
import {ForgetPasswordComponent} from './pages/forget-password/forget-password.component';
import {ProfileMyExperiencesComponent} from './pages/profile-my-experiences/profile-my-experiences.component';
import {DetailsGroupeComponent} from './pages/details-groupe/details-groupe.component';
import {FormGroupComponent} from './pages/form-group/form-group.component';
import {MyGroupsComponent} from './pages/my-groups/my-groups.component';
import {SuggestedGroupsPageComponent} from './pages/suggested-groups-page/suggested-groups-page.component';
import {ExperiencesAnalyticPageComponent} from './pages/experiences-analytic-page/experiences-analytic-page.component';
import {AnalyticsComponent} from './pages/analytics/analytics.component';
import {DeactivateGuard} from './utils/functions/deactivateguard';
import {ResetPasswordComponent} from './pages/reset-password/reset-password.component';
import {EditGroupSectionComponent} from './reusables/components/my-groups/edit-group-section/edit-group-section.component';
import {MyGroupsSectionComponent} from './reusables/components/my-groups/my-groups-section/my-groups-section.component';
import {UNAUTHORISED_PATH, FORBIDDEN_PATH, NOT_FOUND_PATH, OOPS_PATH} from './utils/errorsReactions/errors-texts';
import {CONFIRMATION_ADD_GROUP} from './utils/global-constants/groups-const';
import {InvitationsComponent} from './pages/invitations/invitations.component';
import {AuthGuard} from './reusables/_helpers';
import {ManageExperiencesComponent} from './reusables/components/my-groups/manage-experiences/manage-experiences.component';
import {ManageMembersComponent} from './reusables/components/my-groups/manage-members/manage-members.component';
import {
  ID_EDIT_ANALYTICS_LABEL,
  ANALYTICS_LABEL,
  CREATE_LABEL,
  EXPERIENCES_LABEL,
  GROUPS_LABEL,
  ID_LABEL,
  ID_EDIT_LABEL,
  MY_EXPERIENCES_LABEL,
  MY_TEMPLATES_LABEL,
  SUGGESTED_LABEL,
  ID_ANALYTICS_LABEL,
  PROFILE_LABEL,
  EDITOR_LABEL,
  DASHBOARD_LABEL,
  LOGIN_LABEL,
  SIGNUP_LABEL,
  FORGOT_PASSWORD_LABEL,
  MY_GROUPS_LABEL,
  ID_MANAGE_EXPERIENCES_LABEL,
  ID_MANAGE_MEMBERS_LABEL,
  ID_EDIT_DETAILS_LABEL,
  INFORMATION_LABEL,
  COMING_SOON_LABEL,
  RESET_PASSWORD_LABEL,
  MOBILE_LINK_PLAY_LABEL,
  EDITOR_TEMPLATE_LABEL,
  EDITOR_EXPERIENCE_LABEL,
  MY_LIBRARY_LABEL, ALL_EXPERIENCES_PATH_LABEL, TEMPLATES_PATH_LABEL, MY_FAVORITES_LABEL, MODERATE_LABEL
} from './utils/actionsLabels/pathLabels';
import {MyTemplatesComponent} from './pages/my-templates/my-templates.component';
import {FormTemplateComponent} from './reusables/components/my-templates/form-template/form-template.component';
import {MyTemplatesProfileComponent
} from './reusables/components/my-templates/my-templates-profile/my-templates-profile.component';
import {DetailsTemplateComponent} from './pages/details-template/details-template.component';
import {LibraryComponent} from './pages/library/library.component';
import {AllExperiencesPageComponent} from './pages/all-experiences-page/all-experiences-page.component';
import {AllTemplatesPageComponent} from './pages/all-templates-page/all-templates-page.component';
import {HomeProfileComponent} from './pages/home-profile/home-profile.component';
import {FacebookService} from "ngx-facebook";
import {AssetsListingComponent} from "./reusables/components/assets-library/assets-listing/assets-listing.component";
import {AssetFormComponent} from "./reusables/components/assets-library/asset-form/asset-form.component";
import {EditAnalyticsComponent} from './pages/edit-analytics/edit-analytics.component';
import { ProfileMyFavoritesComponent } from './pages/profile-my-favorites/profile-my-favorites.component';
import {ProfileModerateComponent} from "./pages/profile-moderate/profile-moderate.component";
import {ModerateComponent} from "./reusables/components/moderate/moderate.component";

const routes: Routes = [
  {path: '', component: HomeProfileComponent},
  {
    path: EXPERIENCES_LABEL,
    children: [
      {
        path: ALL_EXPERIENCES_PATH_LABEL,
        component: AllExperiencesPageComponent
      },
      {
        path: CREATE_LABEL,
        component: FormExperienceComponent, canActivate: [AuthGuard]
      },
      {
        path: ID_EDIT_LABEL,
        component: FormExperienceComponent, canActivate: [AuthGuard]
      },
      {
        path: ID_EDIT_ANALYTICS_LABEL,
        component: EditAnalyticsComponent, canActivate: [AuthGuard]
      },
      {
        path: MOBILE_LINK_PLAY_LABEL,
        component: InformationsComponent, canActivate: [AuthGuard]
      },
      {
        path: ID_LABEL,
        component: DetailsExperienceComponent
      }
    ]
  },
  {
    path: GROUPS_LABEL,
    children: [
      {
        path: CREATE_LABEL,
        component: FormGroupComponent, canActivate: [AuthGuard]
      },
      {
        path: SUGGESTED_LABEL,
        component: SuggestedGroupsPageComponent
      },
      {
        path: ID_LABEL,
        component: DetailsGroupeComponent
      }
    ]
  },
  {
    path: MY_EXPERIENCES_LABEL,
    children: [
      {
        path: '',
        component: ProfileMyExperiencesComponent, canActivate: [AuthGuard]
      },
      {
        path: ANALYTICS_LABEL,
        component: ExperiencesAnalyticPageComponent, canActivate: [AuthGuard]
      },
      {
        path: ID_ANALYTICS_LABEL,
        component: ExperiencesAnalyticPageComponent, canActivate: [AuthGuard]
      }
    ]
  },
  {path: PROFILE_LABEL, component: ProfileComponent, canActivate: [AuthGuard]},
  {path: DASHBOARD_LABEL, component: DashboardComponent, canActivate: [AuthGuard]},
  {
    path: EDITOR_LABEL,
    children: [
      {
        path: EDITOR_TEMPLATE_LABEL,
        component: EditorComponent,
        canActivate: [AuthGuard],
        canDeactivate: [DeactivateGuard]
      },
      {
        path: EDITOR_EXPERIENCE_LABEL,
        component: EditorComponent,
        canActivate: [AuthGuard],
        canDeactivate: [DeactivateGuard]
      }
    ]
  },
  {path: LOGIN_LABEL, component: LoginComponent, canActivate: [SecureInnerPagesService]},
  // activated signup page event user is connected
  // {path: SIGNUP_LABEL, component: SignupComponent, canActivate: [SecureInnerPagesService]},
  {path: SIGNUP_LABEL, component: SignupComponent},
  {path: FORGOT_PASSWORD_LABEL, component: ForgetPasswordComponent, canActivate: [SecureInnerPagesService]},
  {
    path: MY_GROUPS_LABEL,
    component: MyGroupsComponent,
    children: [
      {
        path: '',
        component: MyGroupsSectionComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ID_EDIT_DETAILS_LABEL,
        component: EditGroupSectionComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ID_MANAGE_EXPERIENCES_LABEL,
        component: ManageExperiencesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ID_MANAGE_MEMBERS_LABEL,
        component: ManageMembersComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: TEMPLATES_PATH_LABEL,
    children: [
      {
        path: '',
        component: AllTemplatesPageComponent
      },
      {
        path: ID_LABEL,
        component: DetailsTemplateComponent
      },
      {
        path: MOBILE_LINK_PLAY_LABEL,
        component: InformationsComponent, canActivate: [AuthGuard]
      }
    ]},
  {
    path: MY_TEMPLATES_LABEL,
    component: MyTemplatesComponent,
    children: [
      {
        path: '',
        component: MyTemplatesProfileComponent,
        canActivate: [AuthGuard],
      },
      {
        path: CREATE_LABEL,
        component: FormTemplateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: ID_EDIT_LABEL,
        component: FormTemplateComponent,
        canActivate: [AuthGuard],
      }
    ]
  },
  {
    path: MY_LIBRARY_LABEL,
    component: LibraryComponent,
    children: [
      {
        path: '',
        component: AssetsListingComponent,
        canActivate: [AuthGuard]
      },
      {
        path: CREATE_LABEL,
        component: AssetFormComponent,
        canActivate: [AuthGuard],
      }
    ]
  },
  {
    path: MY_FAVORITES_LABEL,
    children: [
      {
        path: '',
        component: ProfileMyFavoritesComponent, canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: MODERATE_LABEL,
    component: ProfileModerateComponent,
    children: [
      {
        path: '',
        component: ModerateComponent,
        canActivate: [AuthGuard]
      }
    ]
  },

  {path: ANALYTICS_LABEL, component: AnalyticsComponent, canActivate: [AuthGuard]},
  {path: COMING_SOON_LABEL, component: ComingSoonComponent},
  {path: INFORMATION_LABEL, component: InformationsComponent},
  // {path: RESET_PASSWORD_LABEL, component: ResetPasswordComponent, canActivate: [SecureInnerPagesService]},
  {path: UNAUTHORISED_PATH, component: NotFountComponent},
  {path: FORBIDDEN_PATH, component: NotFountComponent},
  {path: NOT_FOUND_PATH, component: NotFountComponent},
  {path: OOPS_PATH, component: NotFountComponent},
  {path: CONFIRMATION_ADD_GROUP, component: InvitationsComponent, canActivate: [AuthGuard]},
  {path: '**', component: NotFountComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
  providers: [DeactivateGuard, FacebookService],
})

export class AppRoutingModule {
}
