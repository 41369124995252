import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-details-gallery',
  templateUrl: './details-gallery.component.html',
  styleUrls: ['./details-gallery.component.scss']
})
export class DetailsGalleryComponent implements OnInit {

  constructor() {
  }

  @Input() gallery: Array<any>;
  @Input() video: string;
  @Input() source: string;
  @Output() openVideo = new EventEmitter();
  thumbnail;

  ngOnInit(): void {
    this.getVideoThumbnail();
  }

  addAction(idImg) {
    this.openVideo.emit({action: 'openVideo', id: idImg , video: this.video});
  }
  getVideoThumbnail() {
    if (this.video) {
      this.thumbnail = 'https://img.youtube.com/vi/' + this.video.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop() + '/0.jpg';
    }
  }
}
