import {Component, Input, OnInit} from '@angular/core';
import {TEMPLATES_SECTION_TITLE} from '../../../../utils/actionsLabels/labels';
import {TEMPLATES_ALL_PATH_LABEL} from '../../../../utils/actionsLabels/pathLabels';
import {Template} from '../../../interfaces/templates';

@Component({
  selector: 'app-templates-section',
  templateUrl: './templates-section.component.html',
  styleUrls: ['./templates-section.component.scss']
})
export class TemplatesSectionComponent implements OnInit {
  sectionTitle = TEMPLATES_SECTION_TITLE;
  templatesLink = TEMPLATES_ALL_PATH_LABEL ;
  @Input() templates: Template [];
  @Input() carouselOptions;
  constructor() { }

  ngOnInit(): void {
  }

}
