<div class="pglist-p1 pglist-bg pglist-p-com" id="ld-abour">
  <div class="pglist-p-com-ti">
    <div class="about-section">
      <div class="text-about">
        <span>{{"About" | translate}}</span> {{details.name}}
      </div>
      <div class="favorite-item">
        <a (click)="ActionExperienceFavorite()" *ngIf="source=='experience'" [class.isDisabled]="disabledButtonFavorite">
          <a *ngFor="let et of etatExperienceFavorite| keyvalue" >
            <i *ngIf="et.key == details.id && et.value==true"   class="fa fa-heart favorite-item-red" aria-hidden="true"></i>
            <i *ngIf="et.key == details.id && et.value==false"  class="fa fa-heart-o favorite-item-white"  aria-hidden="true"></i>
          </a>
        </a>
        <a (click)="ActionTemplateFavorite()" *ngIf="source=='template'" [class.isDisabled]="disabledButtonFavorite" >
          <a *ngFor="let et of etatTemplateFavorite| keyvalue" >
            <i *ngIf="et.key == details.id && et.value==true"   class="fa fa-heart favorite-item-red" aria-hidden="true"></i>
            <i *ngIf="et.key == details.id && et.value==false"  class="fa fa-heart-o favorite-item-white"  aria-hidden="true"></i>
          </a>
        </a>
        <button *ngIf="source=='template'"
              class=" section-button waves-effect waves-light "
              (click)="UseTemplate(details.content)">
         <i class="fa fa-plus"> </i> {{'Use' | translate}}
        </button>
      </div>
    </div>
  </div>
  <div class="list-pg-inn-sp">
    <div class="description-content left">
      <div class="share-btn">
        <ul>
          <li><a (click)="shareFacebook()"><i class="fa fa-facebook fb1"></i> {{"Share On Facebook" | translate}}</a>
          </li>
          <li><a (click)="shareTwitter()"><i class="fa fa-twitter tw1"></i> {{"Share On Twitter" | translate}}</a>
          </li>
          <li><a (click)="shareLinkedin()"><i class="fa fa-linkedin li1"></i> {{"Share On Linkedin" | translate}}</a>
          </li>
        </ul>
      </div>
      <p *ngIf="source=='template' && details.trigger!=''" class="trigger-section">
        {{'trigger template' | translate}} {{details.trigger}} {{'trigger' | translate}}
      </p>
      <p class="only_on_desktop">{{details.description}}</p>
    </div>
    <div class="qr-code-content right">
      <div *ngIf="typeMobile" class="share-btn-mobile">
        <ul>
          <li><a (click)="navigate()" >{{"Play On Mobile" | translate}}</a></li>
        </ul>
      </div>
      <app-qrcode *ngIf="source=='experience'" [source]="'experience'" [image]="details.qrCode"></app-qrcode>
      <app-qrcode *ngIf="source=='template'" [source]="'template'" [image]="details.qr_code"></app-qrcode>
    </div>
  </div>
  <div>
    <p class="only_on_mobile">{{details.description}}</p>
  </div>
</div>

