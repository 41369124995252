<section>
  <div class="tz">
    <!--    this section appears only on desktop version -->
    <div class="tz-3 justInDesktop">
      <h4>{{'Suggested_Groups' | translate }}</h4>
      <ul *ngIf="groups && groups.length > 0">
        <li *ngFor="let item of groups ">
          <app-suggested-group [data]="item" (groupClickAction)="onItemGroupClick($event)"></app-suggested-group>
        </li>
        <li><a [routerLink]="[suggestedGroupsLink]"><span class="span-link">{{'VIEW_MORE' | translate}}</span></a></li>
      </ul>
      <div *ngIf="groups && groups.length == 0" class="no_data_message">
        {{'no_suggested_groups_message' | translate}}
      </div>
    </div>
    <!-- end of desktop section -->
    <div class="tz-2 custom-experiences">
      <div class="tz-2-com tz-2-main">
        <h4>{{sectionTitle}}</h4>
        <div class="db-list-com tz-db-table">
          <div class="tz-mess">
            <div class="row experiences-container" *ngIf="doneForExperiences && experiences.length <= 3">
              <app-experience *ngFor="let item of experiences" [data]="item"></app-experience>
            </div>
            <div class="row experiences-container" *ngIf="doneForExperiences && experiences.length > 3">
              <app-experience
                *ngFor="let item of experiences | paginate: { itemsPerPage: 3, currentPage: p , id: 'pagesExperiences'}"
                [data]="item"></app-experience>
            </div>
            <div class="row center-align" *ngIf="doneForExperiences && experiences.length > 3">
              <pagination-controls (pageChange)="p = $event" id="pagesExperiences"
                                   previousLabel=""
                                   nextLabel=""
                                   directionLinks="true"
                                   autoHide="false"></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    this section appears only on mobile version -->
    <div class="tz-3 justInMobile">
      <h4>{{'Suggested_Groups' | translate }}</h4>
      <ul *ngIf="groups && groups.length > 0">
        <li *ngFor="let item of groups ">
          <app-suggested-group [data]="item" (groupClickAction)="onItemGroupClick($event)"></app-suggested-group>
        </li>
        <li><a [routerLink]="[suggestedGroupsLink]"><span class="span-link">{{'VIEW_MORE' | translate}}</span></a></li>
      </ul>
      <div *ngIf="groups && groups.length == 0" class="no_data_message">
        {{'no_suggested_groups_message' | translate}}
      </div>
    </div>
    <!--    end of mobile section -->
  </div>
</section>
