<div class="tz-2-com tz-2-main">
  <div class="center validate-text">
    <p *ngIf="!isTemplate && !isReview && !isReviewTemp">{{"ASK_FOR_CONFIRM_VALIDATE_EXPERIENCE_ACTION" | translate}}  {{"'" + data.name + "'" + "?"}}</p>
    <p *ngIf="isTemplate && !isReview && !isReviewTemp">{{"ASK_FOR_CONFIRM_VALIDATE_TEMPLATE_ACTION" | translate}}  {{"'" + data.name + "'" + "?"}}</p>
    <p *ngIf="isReview && !isTemplate && !isReviewTemp">{{"ASK_FOR_CONFIRM_VALIDATE_REVIEW_ACTION" | translate}}  {{"'" + data.name + "'" + "?"}}</p>
    <p *ngIf="isReviewTemp && !isTemplate && !isReview">{{"ASK_FOR_CONFIRM_VALIDATE_TEMP_REVIEW_ACTION" | translate}}  {{"'" + data.name + "'" + "?"}}</p>
  </div>
</div>
<div class="row actions-popup">
  <button class="button  waves-effect waves-light btn-large save-popup-style"
          (click)="validate()">{{'Validate' | translate}}</button>
  <button class="button waves-effect waves-light btn-large"
          (click)="cancel()">{{'Cancel' | translate}}</button>
</div>
