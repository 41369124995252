<section>
  <div class="">
    <div class="">
      <div class="tz-2-com tz-2-main">
        <h4>{{"My experiences" | translate}}</h4>
        <div class="db-list-com tz-db-table">
          <div class="ds-boar-title">
            <h2 class="section-title">{{"All experiences" | translate}}</h2>
<!--            <a class="global-analytics-btn" (click)="comingSoonAction()">-->
<!--              &lt;!&ndash;     [routerLink]="['/my-experiences/analytics']"&ndash;&gt;-->
<!--              <i class="fa fa-bar-chart" aria-hidden="true"></i> {{'ANALYTICS' | translate}}-->
<!--            </a>-->
          </div>
          <table class="responsive-table bordered">
            <thead>
              <tr>
                <th *ngFor="let item of tabHeaders">{{item| translate}}</th>
              </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of experiences">
              <td>{{item.name}}</td>
              <td>{{item.createdDate | date}}</td>
              <td>{{item.updatedDate | date}}</td>
              <td>
                <span class="db-list-rat" *ngIf="item.rating != 0">{{item.rating}}</span>
                <span class="db-list-rat" *ngIf="item.rating == 0">-</span>
              </td>
              <td>
                <span class="bd-list-custom-status"
                      [ngClass]="statusClassTab[item.status].class"
                      *ngIf="item.status == statusPublished">{{statusClassTab[item.status].label | translate}}</span>
                <span class="bd-list-custom-status"
                      [ngClass]="statusClassTab[item.status].class"
                      *ngIf="item.status == statusUnpublished">{{statusClassTab[item.status].label | translate}}</span>
                <span class="bd-list-custom-status"
                      [ngClass]="statusClassTab[item.status].class"
                      *ngIf="item.status == statusDraft">{{statusClassTab[item.status].label | translate}}</span>
                <span class="bd-list-custom-status"
                      [ngClass]="statusClassTab[item.status].class"
                      *ngIf="item.status == statusInModeration">{{statusClassTab[item.status].label | translate}}</span>
              </td>

              <td>
                <a [routerLink]="['/experiences/' + item.id]">
                  <span class="tooltiptext">{{'PREVIEW' | translate}}</span>
                  <span class="db-list-edit">
                  <i class="fa fa-eye">
                  </i>
                </span>
                </a>
              </td>
              <td>
                <a [routerLink]="['/experiences/' + item.id + '/edit']">
                  <span class="tooltiptext">{{'EDIT' | translate}}</span>
                  <span class="db-list-edit">
                  <i class="fa fa-edit">
                  </i>
                </span>
                </a>
              </td>
              <td>
                <a (click)="analytics(item)">
                  <span class="tooltiptext">{{'ANALYTICS' | translate}}</span>
                  <span class="db-list-edit">
                    <i class="fa fa-bar-chart">
                    </i>
                  </span>
                </a>
              </td>
              <td>
                <a (click)="openUseExperience(item.content)">
                  <span class="tooltiptext">{{'USE_EXPERIENCE' | translate}}</span>
                  <span class="db-list-edit">
                    <i class="far fa-clone"></i>
                  </span>
                </a>
              </td>
              <td>
                <a (click)="applyAction(DELETE_ACTION, item.id, item.name)" [class.isDisabled]="disabledButton">
                  <span class="tooltiptext">{{'DELETE' | translate}}</span>
                  <span class="db-list-edit">
                    <i class="fa fa-trash">

                    </i>
                  </span>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>

