import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DELETE_GROUP_FROM_FAVORITES} from "../../../../utils/actionsLabels/labels";
import {GroupFavorite} from '../../../interfaces/GroupFavorite';

@Component({
  selector: 'app-my-groups-favorites',
  templateUrl: './my-groups-favorites.component.html',
  styleUrls: ['./my-groups-favorites.component.scss']
})
export class MyGroupsFavoritesComponent implements OnInit {

  constructor(public dialog: MatDialog) {
  }

  @Input() listGroups: GroupFavorite[];
  @Input() Groups: GroupFavorite;
  @Input() disabledButtonG: boolean;
  @Input() carouselOptions: any;
  @Input() noFavoritesGroup: boolean;
  @Output() groupAction = new EventEmitter();

  ngOnInit(): void {
  }

  GroupFavoriteAction(id,i) {
    this.groupAction.emit({data: DELETE_GROUP_FROM_FAVORITES, id:id, i:i});
  }

}

