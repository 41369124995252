<h1 mat-dialog-title>{{"Import a ZIP file to create 3D object" | translate}} </h1>


  <form  [formGroup]="form"  >

  <!----------------------------------  .obj file   ------------------------->


  <div class="row  import-file">
    <h4 > {{"Import a ZIP file" | translate}} </h4>
    <br>

    <h5 class="row col-md-10"> {{"Import OBJ or glTF file" | translate}} </h5>

      <div class="row col-md-12" *ngIf="!form.controls.objFile.value" >
        <button  class=" file-button " (click)="addObjFile()" >{{"File" | translate}}</button>
      </div>

      <div *ngIf="form.controls.objFile.value" >

        <div class="row col-md-12 ">
          <input type="text" value="{{form.controls.objFile.value.name}}" disabled class="col-md-5 input-text"/>
          <button class="btn btn-danger delete-file-button" (click)="deleteObjFile()">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>

  </div>

  <!----------------------------------  texture files   ------------------------->

<!--    <div class="row  import-file">
      <h4>{{"Import files for texture" | translate}} </h4>

      <div class=" file-list " *ngIf="form.controls.textureFiles.value.length !== 0" >
        <div class="  row col-md-12 " *ngFor="let file of form.controls.textureFiles.value; let i = index; ">
          <input type="text"  value="{{file.name}}" disabled class="col-md-5 input-text" />
          <button class="btn btn-danger delete-file-button" (click)="deleteTextureFiles(i)">
                  <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>
      <div>
        <button  class=" file-button " (click)="addTextureFiles()" >{{"Files" | translate}}</button>
      </div>
    </div>-->
  <!----------------------------------  buttons   ------------------------->
  <div class="row col-md-12 actions-popup">
    <div class="col-md-1"></div>
    <button type="submit" class=" button  col-md-offset-2 col-sm-3 waves-effect waves-light btn-large save-popup-style"
            (click)="onImport()" [disabled]="!form.valid ">{{'Import' | translate}}</button>
    <button class="col-md-offset-1 col-sm-3 button waves-effect waves-light btn-large"
            (click)="cancel();">{{'Cancel' | translate}}</button>
  </div>


</form>

<input  type="file" id="objFile"
        accept="application/zip"
        (change)="onObjfileChange($event)"
        style="visibility: hidden;">

<!--<input  type="file" id="textureFiles" multiple
        accept=".mtl, image/png, image/jpeg, image/jpg"
        (change)="onTextureFilesChange($event)"
        style="visibility: hidden;">-->
