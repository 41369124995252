<div class="import-popup">
  <h1 mat-dialog-title>  {{popupTitle | translate}} </h1>
  <div class="pc-menu" (click)="tabClick()" [ngClass]="{'menu-disable':showLoadingAddAssetsObject}">
    <input
      [checked]=tabMenu[0].checked
      id="{{tabMenu[0].id}}"
      type="radio"
      name="m1"
      class="custom_menu_options"
      value="{{tabMenu[0].value}}"
    />
    <input
      [checked]=tabMenu[1].checked
      id="{{tabMenu[1].id}}"
      type="radio"
      name="m1"
      class="custom_menu_options"
      value="{{tabMenu[1].value}}"
    />
    <input
      [checked]=tabMenu[2].checked
      id="{{tabMenu[2].id}}"
      type="radio"
      name="m1"
      class="custom_menu_options"
      value="{{tabMenu[2].value}}"
    />
    <nav>
      <ul>
        <li
            class="menu0">
          <label for="{{tabMenu[0].id}}" class="label-content">{{tabMenu[0].label | translate}}</label>
        </li><li
            class="menu1">
          <label for="{{tabMenu[1].id}}" class="label-content">{{tabMenu[1].label | translate}}</label>
        </li><li
            class="menu2">
          <label for="{{tabMenu[2].id}}" class="label-content">{{tabMenu[2].label | translate}}</label>
        </li>
      </ul>
    </nav>
    <section>
    <!----------------------------------------------- MY ASSETS LIBRARY : start ----------------------------------------------------->
      <div class="menu0" >
        <!------------- if the user doesn't have AssetsLibrary or Assets Object in her AssetsLibrary -------------->
        <div class="tz-2-com tz-2-main" *ngIf="showNoAssetsLibraryOrNoAssetsObject">
            <div class="center ">
              <div class="icon-experience-content"><i class="fas fa-cubes"></i></div>
              <div class="center">
                <h4>{{data.info.messages.youHaveNoAsset | translate}}</h4>
                <br/>
                <p>{{data.info.messages.pleaseImportFromComputer | translate}}</p>
                <br/>
              </div>
            </div>
        </div>

        <!------------------------- if the user has AssetsLibrary: Loading assets ---------------------------->
          <div class="row waiting-get-data-popup " *ngIf="showLoadingAssetsObject">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>

        <!------------------------- if the user has Assets Object in her AssetsLibrary ---------------------------->
        <div  class="row assets-list" *ngIf="!showLoadingAssetsObject && !showNoAssetsLibraryOrNoAssetsObject">

              <a class="asset-element"
                 *ngFor="let asset of myAssets"
                 (click)="selectAsset(asset)"
                 [class.selected-asset-element] = "asset === selectedAsset"
                 [ngClass]="asset === selectedAsset ? 'selected-asset-element' : 'not-selected-asset-element'"
              >
                <div class="asset-image">
                  <img src="{{asset.assetObjectUrl}}" *ngIf="data.info.assetType === IMAGE">
                  <img src="{{object3DImagePath}}" *ngIf="data.info.assetType === OBJ_3D">
                  <img src="{{audioImagePath}}" *ngIf="data.info.assetType === AUDIO">
                </div>
                <div class="asset-title"> {{asset.fileName}} </div>
                <div class="asset-size">{{sizeToKoString(asset.size)}} </div>
            </a>
        </div>

      </div>
    <!----------------------------------------------- MY ASSETS LIBRARY : end ----------------------------------------------------->


    <!----------------------------------------------- GLOBAL ASSETS LIBRARY : start ----------------------------------------------------->
      <div class="menu1" >
        <!------------- if the user doesn't have AssetsLibrary or Assets Object in her AssetsLibrary -------------->
        <div class="tz-2-com tz-2-main" *ngIf="showNoGlobalAssetsLibrary">
          <div class="center">
            <div class="icon-experience-content"><i class="fas fa-cubes"></i></div>
            <div class="center">
              <h4>{{data.info.messages.noGlobalAsset | translate}}</h4>
            </div>
          </div>
        </div>

        <!------------------------- if the user has AssetsLibrary: Loading assets ---------------------------->
        <div class="row waiting-get-data-popup " *ngIf="showLoadingGlobalAssetsObject">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>

        <!------------------------- if we have Assets Object in GlobalAssetsLibrary ---------------------------->
        <div  class="row assets-list" *ngIf="!showLoadingGlobalAssetsObject && !showNoGlobalAssetsLibrary">

        <a class="asset-element"
             *ngFor="let asset of globalAssets"
             (click)="selectAsset(asset)"
             [class.selected-asset-element] = "asset === selectedAsset"
             [ngClass]="asset === selectedAsset ? 'selected-asset-element' : 'not-selected-asset-element'"
          >
            <div class="asset-image">
              <img src="{{asset.assetObjectUrl}}" *ngIf="data.info.assetType === IMAGE">
              <img src="{{object3DImagePath}}" *ngIf="data.info.assetType === OBJ_3D">
              <img src="{{audioImagePath}}" *ngIf="data.info.assetType === AUDIO">
            </div>
            <div class="asset-title">{{asset.fileName}} </div>
            <div class="asset-size">{{sizeToKoString(asset.size)}} </div>
          </a>
        </div>
      </div>
    <!----------------------------------------------- GLOBAL ASSETS LIBRARY : end ----------------------------------------------------->


    <!----------------------------------------------- FROM MY COMPUTER : start ----------------------------------------------------->

      <div class="menu2" >
        <!------------- if  assetsLibrary.assignedSize === 0 -------------->
        <div class="tz-2-com tz-2-main" *ngIf="showNoSpace">
          <div class="center">
            <div class=" custom-progress-bar">
              <h3>100%</h3>
              <ngb-progressbar [value]="100" [striped]="true"> </ngb-progressbar>
            </div>
            <div class="center">
              <h4 >{{data.info.messages.noSpace | translate}}</h4>
              <br/>
              <p>{{data.info.messages.deleteFiles | translate}} <a href="{{myAssetsLink}}"> {{data.info.messages.yourAssetsLibrary | translate}} </a> </p>
              <br/>
            </div>
          </div>

        </div>

        <!------------- if  assetsLibrary.assignedSize !== 0 -------------->
        <div *ngIf="!showNoSpace && !showLoadingAddAssetsObject && !showLoadingALibrary">
          <!------------- Messages  -------------->
          <div class="center import-file-output">
              <!------------- show selected file  -------------->
              <input type="text" disabled="" class="inline" value="{{selectedFileName}}" *ngIf="showFile">
              <!------------- ERROR:  LibrarySizeExceeded -------------->
              <div class="center margin-top1" *ngIf="showLibrarySizeExceeded">
                <h4 class="red-h4">{{data.info.messages.noEnoughSpace | translate}}</h4>
                <br/>
                <p>{{data.info.messages.deleteFiles | translate}} <a href="{{myAssetsLink}}"> {{data.info.messages.yourAssetsLibrary | translate}} </a> </p>
                <p>{{data.info.messages.orSelectAnotherFile | translate}}</p>
                <br/>
              </div>
              <!------------- ERROR:  MaxSizeExceeded -------------->
              <div class="center margin-top2" *ngIf="showMaxSizeExceeded">
                <h4 class="red-h4">{{data.info.messages.invalidFileSize | translate}}{{data.info.messages.maxFileSizeValue}}!</h4>
              </div>
              <!------------- ERROR:  InvalidExtension -------------->
              <div class="center margin-top2" *ngIf="showInvalidExtension">
                <h4 class="red-h4">{{data.info.messages.invalidFileExtension | translate}}</h4>
              </div>
            </div>

          <!------------- input file  -------------->
            <div class=" center import-file-input">
              <button  class=" file-button " (click)="addFile()" >{{selectFile | translate}}</button>
              <div class="center">
                <h4 class="dark-h4">{{data.info.messages.supportedExtensions | translate}}{{data.info.messages.supportedExtensionsValue}} </h4>
                <br/>
                <p>{{data.info.messages.maxFileSize | translate}}{{data.info.messages.maxFileSizeValue}}</p>
                <br/>
              </div>
            </div>


          <input  type="file" id="{{data.info.input.id}}"
                  accept="{{data.info.input.accept}}"
                  (change)="onfileChange($event)"
                  style="visibility: hidden;">
        </div>

        <!------------------------- if the user has Assets: Loading add Asset ---------------------------->
        <div *ngIf="showLoadingAddAssetsObject || showLoadingALibrary">
          <div class="row waiting-get-data-popup " >
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
          <div class="center" *ngIf="showLoadingAddAssetsObject">
            <h4>{{creatingAsset | translate}}</h4>
          </div>
        </div>
      </div>
    <!----------------------------------------------- FROM MY COMPUTER : end ----------------------------------------------------->

    </section>
  </div>


  <div class="  actions-popup">
    <button type="submit" class=" button   waves-effect waves-light btn-large save-popup-style"
             [disabled]="!activateImport" (click)="importAsset()">{{'Import' | translate}}</button>
    <button class=" button waves-effect waves-light btn-large"
            (click)="close()" [ngClass]="{'menu-disable':showLoadingAddAssetsObject}">{{'Cancel' | translate}}</button>
  </div>

</div>



