import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Group} from '../../interfaces/group';
import {GROUPS_PATH, EDIT_GROUP_PATH} from '../../../utils/actionsLabels/pathLabels';
import {groupTypes} from '../../../utils/global-constants/groups-const';
import {MY_GROUPS_PATH} from '../../../utils/actionsLabels/pathLabels';

@Component({
  selector: 'app-single-group-owner',
  templateUrl: './single-group-owner.component.html',
  styleUrls: ['./single-group-owner.component.scss']
})

export class SingleGroupOwnerComponent implements OnInit {

  @Input() data: Group;
  @Input() disabledButton;
  @Input() isOwner: boolean;
  @Output() deleteGroup = new EventEmitter();
  @Output() leaveGroup = new EventEmitter();
  linkDetails = '';
  linkEdit = '';
  backgroundGroup = '#233951';
  ownerActions = false;
  memberActions = false;
  groupTypes = groupTypes;
  privacy: {name: string, value: string, htmlValue: string, class: string };
  showMembers = false;

  constructor() { }

  ngOnInit(): void {
    this.linkDetails = GROUPS_PATH + '/' + this.data.id;
    this.linkEdit = MY_GROUPS_PATH + '/' + this.data.id + EDIT_GROUP_PATH;
    if (this.data.avatar !== '') {
      this.backgroundGroup = 'url(' + this.data.avatar + ') no-repeat center bottom';
    }
    if (this.isOwner) {
      this.ownerActions = true;
    } else {
      this.memberActions = true;
    }
    this.privacy = this.groupTypes.find((x) => x.name === this.data.type);
    if (this.data.members > 1) {
      this.showMembers = true;
    }
  }

  deleteGroupAction() {
    this.deleteGroup.emit({id: this.data.id, name: this.data.name});
  }
  leaveAction() {
    this.leaveGroup.emit({id_group: this.data.id, name_group: this.data.name});
  }

}
