import {Component, HostListener, OnInit} from '@angular/core';
import {LOGIN_PATH, SIGNUP_PATH} from '../../../utils/actionsLabels/pathLabels';
import {Location} from '@angular/common';
import {Router} from '@angular/router';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

  constructor(private location: Location, private router: Router) {
  }
  currentUrl = '';
  login = LOGIN_PATH;
  signup = SIGNUP_PATH;
  shouldShowMenu = false;
  wasInside = false;

  ngOnInit(): void {
    this.currentUrl = this.location.path();
  }

  showMenuAction() {
    this.shouldShowMenu = true;
  }

  hideMenuAction() {
    this.shouldShowMenu = false;
    this.currentUrl = this.location.path();
  }

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click', ['$event'])
  clickOut(event) {
    if (!this.wasInside) {
      this.hideMenuAction();
    }
    this.wasInside = false;
  }

  goToLoginPage() {
    this.router.navigate([LOGIN_PATH], {queryParams: { returnUrl:  this.currentUrl }}).then(); // queryParams pour stocker l'ancien url
  }

  // hide menu after click button back
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.hideMenuAction();
  }
}

