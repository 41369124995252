<footer id="colophon" class="site-footer clearfix">
  <div id="quaternary" class="sidebar-container " role="complementary">
    <div class="sidebar-inner">
      <div class="widget-area clearfix">
        <div id="azh_widget-2" class="widget widget_azh_widget">
          <div data-section="section">
            <div class="container">
              <div class="row">
                <div class="logo-footer footer_component"><h4>
                  <img src="assets/images/camlann.png" width="50" alt="logo"> {{'CAMLANN' | translate}}</h4>
                </div>
                <div class="footer_component">
                  <div class="foot-social">
                    <ul>
                      <li><a href="https://www.facebook.com/Camlannio-106781364828512"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                      <li><a href="https://twitter.com/Camlann10"> <i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                      <li><a href="https://www.linkedin.com/in/squeezertechnologies/"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                      <li><a href="https://instagram.com/camlann.ar?igshid=1pbp5toga49qo"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- .widget-area -->
    </div>
    <!-- .sidebar-inner -->
  </div>
  <!-- #quaternary -->
</footer>
<!--COPY RIGHTS-->
<section class="copy">
  <div class="container">
    <p>{{'copyrights © ' | translate}}<span id="cryear"></span> {{'All rights reserved.' | translate }} </p>
  </div>
</section>
