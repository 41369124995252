<div class="home-list-pop list-spac col-experience" >
  <div class="list-ser-img">
    <img *ngIf="data.cover_picture && data.cover_picture != ''" src="{{data.cover_picture}}">
  </div>
  <div class="home-list-pop-desc inn-list-pop-desc">
    <div class="bloc">
      <div class="inline-trigger item-name">
        <a [routerLink]="['/templates/' + data.template_id]">{{data.name}}</a>
      </div>
      <p class="ellipsis-style-description">{{data.description}}</p>
    </div>
    <div class="list-enqu-btn">
      <ul >
        <li>
          <a [routerLink]="['/templates/' + data.template_id]" class="see_exp_button">
            <i class="fa fa-play" aria-hidden="true"></i>{{'SEE-EXPERIENCE-DETAILS' | translate}}
          </a>
        </li>
        <li>
          <a (click)="applyAction(data.template_id)" [class.isDisabled]="disabledButton" class="remove_exp_button">
            <i class="fa fa-trash" aria-hidden="true"></i>{{'Remove' | translate}}
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
