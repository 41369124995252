import {Component, ElementRef, HostListener, EventEmitter, OnInit, Output, ViewChild, Input} from '@angular/core';
import {
  ADD_COVER_PICTURE_OPERATION, CAN_NOT_ALLOWED_OS, CAN_NOT_ALLOWED_OS_TITLE,
  CAN_NOT_UPLOAD_MORE,
  CREATE_TEMPLATE_BUTTON,
  CREATE_TEMPLATE_BUTTON_TITLE,
  CREATE_TEMPLATE_PAGE_TITLE,
  DELETE_COVER_PICTURE_OPERATION, EDIT_TEMPLATE_BUTTON, EDIT_TEMPLATE_EVENT,
  EDIT_TEMPLATE_PAGE_TITLE, NEW_EXPERIENCE_EVENT,
  NEW_TEMPLATE_EVENT,
  NONE_COVER_PICTURE_OPERATION, REPLACE_COVER_PICTURE_OPERATION,
  YOU_HAVE_UPLOAD_8_IMAGES
} from '../../../utils/actionsLabels/labels';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotificationService} from '../../services/notification.service';
import {youtubeVideoForm} from '../../../utils/global-constants/regExp';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import * as $ from 'jquery';
import {Template} from '../../interfaces/templates';
import {EDITOR_TEMPLATE_PATH} from '../../../utils/actionsLabels/pathLabels';
import {
  editTemplateStatusAdmin,
  editTemplateStatusUser,
} from '../../../utils/global-constants/templates-const';
import {BaseURLLink} from "../../../utils/global-constants/baseUrl";
import {userRole} from "../../../utils/global-constants/user-const";
import {User} from "../../interfaces/user";

@Component({
  selector: 'app-add-edit-template-form',
  templateUrl: './add-edit-template-form.component.html',
  styleUrls: ['./add-edit-template-form.component.scss',
    '../../../../assets/customCss/waiting-load.scss',
    '../../../../assets/customCss/customTextarea.css']
})
export class AddEditTemplateFormComponent implements OnInit {

  pageTitle = CREATE_TEMPLATE_PAGE_TITLE;
  buttonText = CREATE_TEMPLATE_BUTTON;
  buttonTitleText = CREATE_TEMPLATE_BUTTON_TITLE;
  myForm: FormGroup;
  fileName = '';
  coverPictureOperation: string = NONE_COVER_PICTURE_OPERATION;
  fileCoverPicture;
  url: string;
  imageWidth;
  resizeToWidth = null;
  croppedImage: any;
  media: any = [];
  mediaScreenChanged = false;
  displayNamesGallery: string[] = [];
  fileList: any = [];
  mediaRequest: any = [];
  @ViewChild('galleryFile') galleryFile: ElementRef;
  waitingLoad = false;
  typeMobile = false;
  editorLink = '';
  oldMediaImages: string[] = [];
  toDeleteFromGallery: string[] = [];
  templateStatus: any;
  aspectRatio = 1;
  actionEmit = NEW_TEMPLATE_EVENT;
  @Output() item = new EventEmitter();
  @Output() request = new EventEmitter();
  @Output() goBack = new EventEmitter();

  @Input() chooseCreate;
  @Input() template: Template;
  @Input() user: User;
  isAdmin = false;

  constructor(private formBuilder: FormBuilder,
              private notificationService: NotificationService) {
    this.myForm = this.formBuilder.group({
      coverPicture: [''],
      name: ['', [Validators.required]],
      description: [''],
      status: [],
      coverPictureDisplayName: [''],
      video: ['', [Validators.pattern(youtubeVideoForm)]],
      media: ['']
    });
  }

  ngOnInit(): void {
    if (window.innerWidth <= 1200) {
      this.mediaScreenChanged = true;
    }
    if (window.innerWidth <= 768) {
      this.typeMobile = true;
    }
    if (this.template && this.template.id !== '') {
      this.initForm(this.template);
      this.buttonText = EDIT_TEMPLATE_BUTTON;
      this.actionEmit = EDIT_TEMPLATE_EVENT;
      if (this.user.id === this.template.creator.userId && (this.user.role === userRole.SUPER_ADMIN.valueOf() ||
        this.user.role === userRole.ADMIN.valueOf())) {
        this.templateStatus = editTemplateStatusAdmin;
        this.isAdmin = true;
      } else {
        this.templateStatus = editTemplateStatusUser;
        this.isAdmin = false;
      }
    }
    this.aspectRatio = window.innerWidth / (window.innerHeight * 0.4); // 160 = height du cover image
  }

  // initialize form for edit
  initForm(temp: Template) {
    this.myForm.get('name').setValue(temp.name);
    this.myForm.get('video').setValue(temp.video);
    this.myForm.get('status').setValue(temp.status);
    $('.privacy-label.status_label_' + temp.status).addClass('active');
    this.myForm.get('description').setValue(temp.description);
    this.fileName = temp.cover_picture_display_name;
    this.url = temp.cover_picture;
    this.pageTitle = EDIT_TEMPLATE_PAGE_TITLE;
    this.editorLink = EDITOR_TEMPLATE_PATH + '/' + temp.id + '/content/' + temp.content;
    this.media = [];
    this.displayNamesGallery = [];
    if (temp.gallery.length > 0) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < temp.gallery.length; i++) {
        const elem = temp.gallery[i];
        this.media.push({
          url: elem.uri,
          name: elem.display_name
        });
        this.displayNamesGallery.push(elem.display_name);
        this.oldMediaImages.push(elem.id);
      }
    }
  }
  openEditor() {
    window.open(BaseURLLink + this.editorLink, '_blank').focus();

  }
  get f() {
    return this.myForm.controls;
  }

  showMessage() {
    this.notificationService.showInfo(CAN_NOT_ALLOWED_OS, CAN_NOT_ALLOWED_OS_TITLE);
  }

  changeTemplateStatus(event) {
    $('.privacy-label').removeClass('active');
  }

  onSubmit(data) {
    // dataRequest is variable that contains all data of the template
    const dataRequest = {
      name: data.name,
      video: data.video,
      status: data.status,
      description: data.description,
      media: this.mediaRequest,
      galleryDisplayName: this.displayNamesGallery,
      coverPictureDisplayName: data.coverPictureDisplayName,
      coverPictureOperation: this.coverPictureOperation,
      gallery: this.fileList
    };
    if (this.actionEmit === NEW_EXPERIENCE_EVENT) {// create new experience
      this.submitAction(dataRequest, data);
    } else {       // edit experience
      if (this.template && this.template.id !== '') {
        dataRequest['id'] = this.template.id;
        dataRequest['mediaIds'] = this.oldMediaImages;
        dataRequest['mediaToDelete'] = this.toDeleteFromGallery;
      }
      this.submitAction(dataRequest, data);
    }
  }

  submitAction(dataRequest, data) {
    if (this.croppedImage) {
      fetch(this.croppedImage)
        .then(res => res.blob()).then(bl => {
        const file = new File([bl], this.fileName);
        dataRequest['coverPicture'] = file;
        if (data.coverPictureDisplayName !== '') {
          dataRequest['coverPictureDisplayName'] = data.coverPictureDisplayName;
        } else {
          dataRequest['coverPictureDisplayName'] = this.fileName;
        }
        dataRequest['coverPictureOperation'] = ADD_COVER_PICTURE_OPERATION;
        this.request.emit({data: dataRequest});
      });
    } else {
      dataRequest['coverPicture'] = this.myForm.get('coverPicture').value;
      this.request.emit({data: dataRequest});
    }
  }

  addPhoto() {
    this.coverPictureOperation = ADD_COVER_PICTURE_OPERATION;
    $('#coverPicture').click();
  }

  addMedias() {
    $('#media').click();
  }

  /*************************** CoverProfile ***********************/
  onCoverProfileChange(event) {
    if (event.target.files.length > 0) {
      this.fileCoverPicture = (event.target as HTMLInputElement).files[0];
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      // tslint:disable-next-line:no-shadowed-variable
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = null;
        this.fileName = this.fileCoverPicture.name;
        this.myForm.get('coverPicture').setValue(this.fileCoverPicture);
        // resize width if > 1920px
        const image = new Image();
        image.src = event.target.result.toString();
        image.onload = () => {
          this.imageWidth = image.width;
          if (this.imageWidth > 1920) {
            this.resizeToWidth = 1920;
          } else {
            this.resizeToWidth = this.imageWidth;
          }
        };
      };
    }
  }

  // ********************************** CoverProfile image-cropper start ***********************//
  imageLoaded() {
    if (this.template && this.template.id !== '') {
      if (this.template.cover_picture !== '') {
        this.coverPictureOperation = REPLACE_COVER_PICTURE_OPERATION;
      } else {
        this.coverPictureOperation = ADD_COVER_PICTURE_OPERATION;
      }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    if (event.width > 1920) {
      this.resizeToWidth = 1920;
    } else {
      this.resizeToWidth = event.width;
    }
  }

  // ********************************** CoverProfile image-cropper end  ***********************//

  deleteProfileCover() {
    this.url = '';
    this.fileName = '';
    this.coverPictureOperation = DELETE_COVER_PICTURE_OPERATION;
    this.myForm.get('coverPicture').setValue('');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mediaScreenChanged = window.innerWidth <= 1200;
  }

  onMediaChange(event) {
    if (event.target.files.length > 0) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        const reader = new FileReader();
        this.fileList.push(event.target.files[i]);
        reader.readAsDataURL(event.target.files[i]); // read file as data url
        // tslint:disable-next-line:no-shadowed-variable
        reader.onload = (event) => {
          const media = {url: event.target.result, name: file.name};
          if (this.media.length < 8) {
            this.media.push(media);
            // this.addDisplayNameGallery();
            this.mediaRequest.push(file);
            this.displayNamesGallery.push(file.name);
          } else {
            this.notificationService.showWarning(YOU_HAVE_UPLOAD_8_IMAGES, CAN_NOT_UPLOAD_MORE);
          }
        };
      }
      this.galleryFile.nativeElement.value = '';
      this.myForm.get('media').setValue(this.mediaRequest);
    }
  }

  changeDisplayName(event, index) {
    this.displayNamesGallery[index] = event.target.value;
  }

  deleteThumbnail(index) {
    if (index > -1) {
      this.media.splice(index, 1);
      this.displayNamesGallery.splice(index, 1);
      if (this.template && this.template.id !== '') {
        const toDelete = this.oldMediaImages[index];
        this.toDeleteFromGallery.push(toDelete);
      }
      if (this.oldMediaImages && this.oldMediaImages.length > 0) {
        this.oldMediaImages.splice(index, 1);
      }
      this.mediaRequest.splice(index, 1);
      this.fileList.splice(index, 1);
    }
  }

  resetForm() {
    if (this.template && this.template.id !== '') {
      this.initForm(this.template);
      window.history.back();
    } else {
      this.myForm.reset();
      this.url = '';
      this.fileName = '';
      this.media = [];
      this.displayNamesGallery = [];
      this.goBack.emit();
    }
  }

  // On some Android devices, input name allows you to write more than the maximum length
  // Force maxlength input on web mobile
  substrName(){
    if (this.myForm.get('name').value.length > 25){
      this.myForm.get('name').setValue(this.myForm.get('name').value.substring(0, 25));
    }
  }
}
