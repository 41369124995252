<button class="close" (click)="onNoClick('close')" mat-button> x</button>
<div mat-dialog-title>
  <div class="row col-md-12">
    <i class="col-md-2 {{data.icon}} fa-3x"></i>
    <h4 class=" col-md-offset-1 col-md-9">{{data.msg}}</h4>
  </div>
  <div class="row col-md-12 actions-popup">
    <div class="col-md-1"></div>
    <button class=" button  col-md-offset-6 col-sm-3 waves-effect waves-light btn-large save-popup-style " (click)="onNoClick('yes')">{{data.yes}}</button>
    <button class="col-sm-3 button waves-effect waves-light btn-large" (click)="onNoClick('no');">{{data.no}}</button>
  </div>
</div>


