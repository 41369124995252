<section *ngIf="doneForUserProfile">
  <div class="tz">
    <div class="tz-l">
      <app-profile-picture [user]="user"></app-profile-picture>
      <app-account-menu [user]="user"></app-account-menu>
    </div>

    <div class="tz-2">
      <div class="tz-2-com tz-2-main">
        <h4>{{name +" - "+pageTitle | translate }}</h4>
        <div class="db-list-com tz-db-table">
          <div class="hom-cre-acc-left hom-cre-acc-right">
            <div class="">
              <form [formGroup]="myForm" >
                <!----------- add analyticsId  ------------------------>
                <div class="row">
                  <div class="db-v2-list-form-inn-tit">
                    <h5>{{"ANALYTICA_ID" | translate}}</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="input-field col s12">
                    <input type="text" id="analyticsId"
                           class="form-control" formControlName="analyticsId"
                           [ngClass]="myForm.get('analyticsId').invalid && myForm.get('analyticsId').touched ? 'invalid': 'validate'"
                    />
                    <label for="analyticsId"
                           [class.active]="experience && experience.id  !== ''">{{"ANALYTICA_ID" | translate}} </label>
                  </div>
                </div>
                <br/>
                <br/>
                <!----------- End analyticsId ------------------------>

                <div class="actions-container row">
                  <button type="submit" [disabled]="myForm.invalid" class="waves-effect waves-light btn-large "
                          title="{{buttonTextTitle | translate}}" (click)="onSubmit(myForm.value)">
                    {{buttonText | translate}}
                  </button>
                  <button type="button"
                          class="waves-effect waves-light btn-large"
                          title="{{'Cancel' | translate}}"
                          (click)="resetForm()">{{'Cancel' | translate}}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    <app-notifications></app-notifications>-->
  </div>
</section>

<div class="row waiting_load" *ngIf="waitingLoad">
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
</div>
