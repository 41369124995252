
<div class="tz-2-com tz-2-main">
  <h4>{{createAssetTitle | translate}}</h4>
  <div class="db-list-com tz-db-table">
    <div class="hom-cre-acc-left hom-cre-acc-right" >
      <form>
      <!----------------------------------------------- ADD ASSET : start ----------------------------------------------------->
        <!------------- if  assetsLibrary.assignedSize === 0 -------------->
        <div class="tz-2-com tz-2-main" *ngIf="showNoSpace">
          <div class="center">
            <div class=" custom-progress-bar">
              <h3>100%</h3>
              <ngb-progressbar [value]="100" [striped]="true"> </ngb-progressbar>
            </div>
            <div class="center">
              <h4 class="blue-h4">{{info.messages.noSpace | translate}}</h4>
              <br/>
              <p class="center">{{info.messages.deleteFiles | translate}} <a href="{{myAssetsLink}}"> {{info.messages.yourAssetsLibrary | translate}} </a> </p>
              <br/>
            </div>
          </div>

        </div>

        <!------------- if  assetsLibrary.assignedSize !== 0 -------------->
        <div *ngIf="!showNoSpace && !showLoadingAddAssetsObject && !showLoadingALibrary">

          <!----------------------------- asset type: START ------------------------->
          <div class=" row ">
              <div class="  asset-type-title db-v2-list-form-inn-tit">
                <h5>{{assetTypeLabel | translate}}</h5>
              </div>

              <input name="assetType" type="radio" id="assetTypeImage"
                     value= "{{IMAGE}}" (click)="onTypeChange(IMAGE)"
              />
              <label class="asset-type-label "
                     [ngClass]="assetType === IMAGE ? 'active' : 'not-active'"
                     for="assetTypeImage">{{imageLabel | translate}}</label>

              <input name="assetType" type="radio" id="assetTypeObject3D"
                     value= "{{OBJ_3D}}" (click)="onTypeChange(OBJ_3D)"
              />
              <label class="asset-type-label "
                     [ngClass]="assetType === OBJ_3D ? 'active' : 'not-active'"
                     for="assetTypeObject3D">{{objLabel | translate}}</label>

              <input name="assetType" type="radio" id="assetTypeAudio"
                     value= "{{AUDIO}}" (click)="onTypeChange(AUDIO)"
              />
              <label class="asset-type-label "
                     [ngClass]="assetType === AUDIO ? 'active' : 'not-active'"
                     for="assetTypeAudio">{{audioLabel | translate}}</label>
            </div>
          <!----------------------------- asset type: END ---------------------------->


          <!------------------------------ asset file: START ------------------------------------>
          <br>

          <!------------- asset file input label  --------------------------------------->
          <div class=" row ">
            <div class=" db-v2-list-form-inn-tit">
              <h5>{{'Asset File' | translate}}</h5>
            </div>
          </div>

          <!--------------------------- Start  Messages  -------------------------------->
          <!------------- show selected file  -------------->
          <div class=" row ">
            <div class="input-field">
              <input type="text" disabled="" class="inline row " value=" {{selectedFileName}}" *ngIf="showFile">
            </div>
          </div>
          <!------------- ERROR:  LibrarySizeExceeded -------------->
          <div  class="row" *ngIf="showLibrarySizeExceeded">
            <h4 class="red-h4">{{info.messages.noEnoughSpace | translate}}</h4>
          </div>
          <div  class="row p-message-error" *ngIf="showLibrarySizeExceeded">
            <p >{{info.messages.deleteFiles | translate}} <a href="{{myAssetsLink}}"> {{info.messages.yourAssetsLibrary | translate}} </a> {{info.messages.orSelectAnotherFile | translate}} </p>
          </div>
          <!------------- ERROR:  MaxSizeExceeded -------------->
          <div class="row " *ngIf="showMaxSizeExceeded">
            <h4 class="red-h4">{{info.messages.invalidFileSize | translate}}{{info.messages.maxFileSizeValue}}!</h4>
          </div>
          <!------------- ERROR:  InvalidExtension -------------->
          <div class="row " *ngIf="showInvalidExtension">
            <h4 class=" red-h4">{{info.messages.invalidFileExtension | translate}}</h4>
          </div>
          <!---------------------------End Messages  ------------------------------------->

          <!------------- input file  ---------------------------------------------------->
            <div class="  import-file-input">
              <div class="row tz-file-upload">
                <div class="file-field input-field">
                  <div (click)="addFile()" class="tz-up-btn"  title="{{'CLICK_TO_ADD_ASSET' | translate}}">
                    <span >{{"File" | translate}}</span>
                  </div>
                </div>
              </div>

              <div>
                <h4 class="h4-message dark-h4">{{info.messages.supportedExtensions | translate}}{{info.messages.supportedExtensionsValue}} </h4>
                <p class="" >{{info.messages.maxFileSize | translate}}{{info.messages.maxFileSizeValue}}</p>
              </div>
              <input  type="file" id="{{info.input.id}}"
                      #inputAddAssetObjectFile
                    accept="{{info.input.accept}}"
                    (change)="onfileChange($event)"
                    style="visibility: hidden;">
            </div>

          <!------------------------------- asset file: END ---------------------------------------->


          <!-------------------buttons ------------------->
          <div class="actions-container row" *ngIf="!showLoadingALibrary">
          <button type="submit" [disabled]="activateImport == false" class="waves-effect waves-light btn-large "
                  title="{{'Create' | translate}}" (click)="createAsset()">
            {{'Create' | translate}}
          </button>
          <button type="button"
                  class="waves-effect waves-light btn-large"
                  title="{{'Cancel' | translate}}"
                  (click)="cancel()">{{'Cancel' | translate}}</button>
        </div>

        </div>

      <!------------------------- if the user has Assets: Loading add Asset ---------------------------->
        <div *ngIf="showLoadingAddAssetsObject || showLoadingALibrary">
          <div class="row waiting-get-data " >
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
          <div class="center" *ngIf="showLoadingAddAssetsObject">
            <h4 class="blue-h4">{{creatingAsset | translate}}</h4>
          </div>
        </div>
      <!----------------------------------------------- ADD ASSET : end ----------------------------------------------------->
      </form>
    </div>
  </div>
</div>

