import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {NgxPaginationModule} from 'ngx-pagination';
import {ExperiencesComponent} from './experiences/experiences.component';
import {ReusablesModule} from '../reusables/reusables.module';
import {EditorComponent} from './editor/editor.component';
import {NotFountComponent} from './not-fount/not-fount.component';
import {DetailsExperienceComponent} from './details-experience/details-experience.component';
import {ProfileComponent} from './profile/profile.component';
import {RouterModule} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {SignupComponent} from './signup/signup.component';
import {ComingSoonComponent} from './coming-soon/coming-soon.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {InformationsComponent} from './informations/informations.component';
import {FormExperienceComponent} from './form-experience/form-experience.component';

import {ForgetPasswordComponent} from './forget-password/forget-password.component';
import {ProfileMyExperiencesComponent} from './profile-my-experiences/profile-my-experiences.component';
import {DetailsGroupeComponent} from './details-groupe/details-groupe.component';
import {FormGroupComponent} from './form-group/form-group.component';
import {MyGroupsComponent} from './my-groups/my-groups.component';
import {SuggestedGroupsPageComponent} from './suggested-groups-page/suggested-groups-page.component';
import {ExperiencesAnalyticPageComponent} from './experiences-analytic-page/experiences-analytic-page.component';
import {AnalyticsComponent} from './analytics/analytics.component';
import {MatSelectModule} from '@angular/material/select';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {ChartsModule} from 'ng2-charts';
import {RecaptchaModule} from 'ng-recaptcha';
import {MatTabsModule} from '@angular/material/tabs';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {InvitationsComponent} from './invitations/invitations.component';
import {NgxJdenticonModule} from 'ngx-jdenticon';
import {ImageCropperModule} from 'ngx-image-cropper';

import { MyTemplatesComponent } from './my-templates/my-templates.component';
import { DetailsTemplateComponent } from './details-template/details-template.component';
import { LibraryComponent } from './library/library.component';
import { AllExperiencesPageComponent } from './all-experiences-page/all-experiences-page.component';
import { AllTemplatesPageComponent } from './all-templates-page/all-templates-page.component';
import { HomeProfileComponent } from './home-profile/home-profile.component';
import {ShareButtonModule} from "ngx-sharebuttons/button";
import { EditAnalyticsComponent } from './edit-analytics/edit-analytics.component';
import { ProfileMyFavoritesComponent } from './profile-my-favorites/profile-my-favorites.component';
import { ProfileModerateComponent } from './profile-moderate/profile-moderate.component';



@NgModule({
  declarations: [
    ExperiencesComponent,
    EditorComponent,
    NotFountComponent,
    DetailsExperienceComponent,
    ProfileComponent,
    LoginComponent,
    SignupComponent,
    ComingSoonComponent,
    InformationsComponent,
    FormExperienceComponent,
    ForgetPasswordComponent,
    ProfileMyExperiencesComponent,
    DetailsGroupeComponent,
    FormGroupComponent,
    MyGroupsComponent,
    SuggestedGroupsPageComponent,
    ExperiencesAnalyticPageComponent,
    AnalyticsComponent,
    ResetPasswordComponent,
    InvitationsComponent,
    MyTemplatesComponent,
    DetailsTemplateComponent,
    LibraryComponent,
    AllExperiencesPageComponent,
    AllTemplatesPageComponent,
    HomeProfileComponent,
    EditAnalyticsComponent,
    ProfileMyFavoritesComponent,
    ProfileModerateComponent
  ],
    imports: [
        CommonModule,
        ReusablesModule,
        TranslateModule,
        NgxPaginationModule,
        RouterModule,
        ReactiveFormsModule,
        RouterModule,
        NgCircleProgressModule.forRoot({
            maxPercent: 290,
            outerStrokeColor: 'rgba(37, 61, 82, 0.8)',
            innerStrokeColor: 'rgba(9, 254, 149, 0.2)',
            showTitle: false,
            showUnits: false,
            showSubtitle: false,
        }),
        MatSelectModule,
        MDBBootstrapModule,
        ChartsModule,
        RecaptchaModule,
        FormsModule,
        BrowserAnimationsModule,
        MatTabsModule,
        NgxJdenticonModule,
        ImageCropperModule,
        ShareButtonModule
    ],
  exports: [
    ExperiencesComponent,
    NotFountComponent,
    ComingSoonComponent,
    EditorComponent,
    MatTabsModule
  ]
})
export class PagesModule {
}
