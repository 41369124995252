import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-details-menu',
  templateUrl: './details-menu.component.html',
  styleUrls: ['./details-menu.component.scss']
})
export class DetailsMenuComponent implements OnInit {

  constructor() {
  }

  @Input() menu;
  @Input() activeSubMenu;
  @Output() scrollTo: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
  }

  scrollAction(event, index) {
    $('.inner_menu').removeClass('active');
    $('.link_' + index).addClass('active');
    this.scrollTo.emit({action: 'scroll', target: event});
  }
}
