import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Review} from '../../../interfaces/review';
import {User} from '../../../interfaces/user';
import {WRITE_REVIEW_DELETE, WRITE_REVIEW_EDIT} from '../../../../utils/actionsLabels/labels';
import {ReviewStatusEnum} from "../../../../utils/global-constants/Reviews-const";

@Component({
  selector: 'app-own-review',
  templateUrl: './own-review.component.html',
  styleUrls: ['./own-review.component.scss']
})
export class OwnReviewComponent implements OnInit {

  constructor() { }

  @Input() review: Review[];
  @Input() connectedUser: User;
  @Output() reviewData = new EventEmitter();
  @Input() type: string;
  @Input() likes: any;
  @Input() dislikes: any;
  @Input() etatLike: any;
  @Input() etatDislike: any;
  reviewStatus = ReviewStatusEnum;
  ownReview: Review;

  ngOnInit(): void {
    this.ownReview = this.review[0];
  }
  editReview() {
    this.reviewData.emit({type: WRITE_REVIEW_EDIT, data: this.ownReview.id});
  }
  deleteReview() {
    this.reviewData.emit({type: WRITE_REVIEW_DELETE, data: this.ownReview.id});
  }
}
