import {Component, HostListener, Input, OnInit} from '@angular/core';
import {
  CREATE_EXPERIENCE_PATH,
  MY_EXPERIENCES_PATH,
  MY_GROUPS_PATH, PROFILE_PATH, DASHBOARD_PATH, MY_TEMPLATES_PATH, MY_LIBRARY_PATH, MY_FAVORITE_PATH, MODERATE_PATH
} from '../../../utils/actionsLabels/pathLabels';
import {
  COMING_SOON
} from '../../../utils/actionsLabels/labels';
import {Router} from '@angular/router';
import {User} from '../../interfaces/user';
import {UsersService} from '../../services/users.service';
import {CookieService} from 'ngx-cookie-service';
import {MatDialog} from '@angular/material/dialog';
import {Location} from '@angular/common';
import {NotificationService} from '../../services/notification.service';
import {LogoutService} from '../../services/logout.service';
import {TokenHelperService} from '../../services/token-helper.service';
import {userRole} from "../../../utils/global-constants/user-const";

@Component({
  selector: 'app-main-menu-connected',
  templateUrl: './main-menu-connected.component.html',
  styleUrls: ['./main-menu-connected.component.scss']
})
export class MainMenuConnectedComponent implements OnInit {

  currentUrl = '';
  doNotSaveEditor = null;
  profileLink = PROFILE_PATH;
  dashboardLink = DASHBOARD_PATH;
  myExperiencesLink = MY_EXPERIENCES_PATH;
  myTemplatesLink = MY_TEMPLATES_PATH;
  myGroupsLink = MY_GROUPS_PATH;
  myFavoritesLink= MY_FAVORITE_PATH;
  myAssetLibraryLink = MY_LIBRARY_PATH;
  createExperience = CREATE_EXPERIENCE_PATH;
  moderate = MODERATE_PATH;
  shouldShowMenu = false;
  user: User;
  doneForUserProfile = false;
  profilePictureBackground = '#3c657f';
  askToOpen = false;
  wasInside = false;
  @Input() isEditor: boolean;
  @Input() Name: string;
  center = 'center';
  isAdmin = false;

  constructor(private router: Router,
              private usersService: UsersService,
              private cookieService: CookieService,
              public dialog: MatDialog,
              private location: Location,
              private logoutService: LogoutService,
              private notificationService: NotificationService,
              private tokenHelperService: TokenHelperService) {
  }


  ngOnInit(): void {
    this.currentUrl = this.location.path();
    if (this.tokenHelperService.getConnectedUser()) {
      this.getProfile();
    }
  }

  showMenuAction() {
    this.shouldShowMenu = true;
  }

  hideMenuAction() {
    this.shouldShowMenu = false;
    this.currentUrl = this.location.path();
  }

  logout() {
    this.doNotSaveEditor = 'logoutClick';
    const button = document.getElementById('save-before-create-new');
    if (button) {
      button.click();
    } else {
      this.logoutService.logout({action: 'logout', data: {returnUrl: this.router.url}});
    }
  }
  getProfile() {
      this.usersService.getUserProfile().subscribe((data: User) => {
        this.user = data;
        if (this.user.profilePicture !== '') {
          this.profilePictureBackground = 'url(' + this.user.profilePicture + ') no-repeat';
        }
        if (this.user.role == userRole.ADMIN || this.user.role == userRole.SUPER_ADMIN) {
          this.isAdmin = true;
        }
        this.doneForUserProfile = true;
      });
  }

  comingSoonAction() {
    this.notificationService.showWarning('', COMING_SOON);
  }

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click', ['$event'])
  clickOut() {
    if (!this.wasInside) {
      this.hideMenuAction();
    }
    this.wasInside = false;
  }

  actionAfterEditProfile() {
    if (this.tokenHelperService.getConnectedUser()) {
      this.getProfile();
    }
  }
}
