<div class="home-list-pop list-spac col-experience" >
  <div class="list-ser-img">
    <img *ngIf="data.coverPicture && data.coverPicture != ''" src="{{data.coverPicture}}">
  </div>
  <div class="home-list-pop-desc inn-list-pop-desc">
    <div class="bloc">
      <div class="inline-trigger item-name">
        <a [routerLink]="['/experiences/' + data.experience_id]">{{data.name}}</a>
      </div>
      <p class="ellipsis-style-description">{{data.description}}</p>
    </div>
    <div class="list-enqu-btn">
      <ul >
        <li>
          <a [routerLink]="['/experiences/' + data.experience_id]" class="see_exp_button">
            <i class="fa fa-play" aria-hidden="true"></i>{{'SEE-EXPERIENCE-DETAILS' | translate}}
          </a>
        </li>
        <li>
          <a (click)="applyAction(data.experience_id)" [class.isDisabled]="disabledButton" class="remove_exp_button">
            <i class="fa fa-trash" aria-hidden="true"></i>{{'Remove' | translate}}
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

