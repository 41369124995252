<div class="tz-l-2 custom-display">
  <ul>
    <li *ngFor="let item of myMenu">
      <a *ngIf="item.link !== comingSoon"
         [routerLink]="currentUrl === item.link ? [] : [item.link]"
         [class.tz-lma]="((currentUrl === item.link) ||
         ((item.activeLink.includes(currentUrl.substr(currentUrl.lastIndexOf('/')))) && (
         currentUrl.substr(currentUrl.lastIndexOf('/')) === editPath) && ((
         currentUrl.substr(0, 12) === item.activeLink.slice(0, 1)+item.activeLink.slice(4, 15)) || (
         currentUrl.substr(0,13) == item.link))))">
        <div class="custom-icon-container">
          <i class="{{item.icon}}" aria-hidden="true"></i>
        </div>
        {{item.label | translate }}
      </a>
      <a *ngIf="item.link === comingSoon"
         (click)="comingSoonAction()">
        <div class="custom-icon-container">
          <i class="{{item.icon}}" aria-hidden="true"></i>
        </div>
        {{item.label | translate }}
      </a>
    </li>
    <li>
      <a (click)="logout()">
        <div class="custom-icon-container">
          <i class="fa fa-sign-in" aria-hidden="true"></i>
        </div>
        {{'LOGOUT' | translate }}
      </a>
    </li>
  </ul>
</div>
