<section *ngIf="doneForAnalyticsVisitors">
  <div class="">
    <div class="">
      <div class="tz-2-com tz-2-main">
<!--        <h4 *ngIf="analyticsVisitors.experience_name != null">" {{analyticsVisitors.experience_name}} " {{"visitors" | translate}}</h4>-->
        <h4 >{{"visitors" | translate}}</h4>
        <div class="db-list-com tz-db-table">

          <table class="table table-striped">
            <tbody>
            <tr>
              <td>{{"All visits" | translate}}</td>
              <td class="value">{{analyticsVisitors.all_visits}}</td>
            </tr>
            <tr>
              <td>{{"Unique visitors" | translate}}</td>
              <td class="value">{{analyticsVisitors.unique_visitors}}</td>
            </tr>
            <tr>
              <td>{{"Last 24 hours" | translate}}</td>
              <td class="value">{{analyticsVisitors.last_24_hours}}</td>
            </tr>
            <tr>
              <td>{{"Last week" | translate}}</td>
              <td class="value">{{analyticsVisitors.last_week}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>

