<section *ngIf="doneForUserProfile">
  <div class="tz">
    <div class="tz-l">
      <app-profile-picture [user]="user"></app-profile-picture>
      <app-account-menu [user]="user"></app-account-menu>
    </div>
    <div class="tz-2">
      <app-add-edit-group-form *ngIf="!showMembers" (request)="readData($event)"></app-add-edit-group-form>
      <app-manage-members-group *ngIf="showMembers" [allUsers]="users"
                                [createdGroupId]="createdGroupId"
                                [groupName]="groupName"
                                [groupId]="group.id"
                                [showUsers]="showUsers"
                                (membersRequest)="addMembersEvent($event)"></app-manage-members-group>
    </div>
<!--    <app-notifications></app-notifications>-->
  </div>
</section>

<div class="row waiting_load" *ngIf="waitingLoad">
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
</div>
