<section class="bottomMenu dir-il-top-fix">
  <div class="container top-search-main">
    <div class="row">
      <div class="ts-menu">
        <div class="ts-menu-1">
          <a href=""><img src="assets/images/camlann.png" alt="{{ 'CAMLANN' | translate }}"> </a>
        </div>
        <div class="ts-menu-2 ">
          <a href="" class="t-bb"> {{ 'HOME' | translate }} </a>
        </div>
        <div class="ts-menu-3">
          <div class="">
            <form class="tourz-search-form tourz-top-search-form">
              <div class="input-field"></div>
              <div class="input-field">
<!--                <input type="text" id="top-select-search" class="autocomplete">-->
<!--                <label for="top-select-search"-->
<!--                       class="search-hotel-type">{{ 'Search_your_Experiences' | translate }}</label>-->
              </div>

<!--              <div class="input-field custom-search-button">-->
<!--                <input type="submit" value="">-->
<!--              </div>-->
            </form>
          </div>
        </div>
        <div class="ts-menu-4 right">
          <div class="v3-top-ri">
<!--            <ul>-->
<!--              <li><a [routerLink]="[signup]" class="v3-menu-sign waves-effect" routerLinkActive="active"><i-->
<!--                class="fa fa-user-plus"></i> {{ 'Sign_Up' | translate }}</a></li>-->
<!--            </ul>-->
            <ul>
              <li><a (click)="goToLoginPage()" class="v3-menu-sign waves-effect" routerLinkActive="active"><i
                class="fa fa-sign-in"></i> {{ 'Sign_In' | translate }}</a></li>
            </ul>
          </div>
        </div>

        <!--MOBILE MENU ICON:IT'S ONLY SHOW ON MOBILE & TABLET VIEW-->
        <div *ngIf="!shouldShowMenu" class="ts-menu-5 right" (click)="showMenuAction()">
          <span><i class="fa fa-bars" aria-hidden="true"></i></span>
        </div>
        <!--MOBILE MENU CONTAINER:IT'S ONLY SHOW ON MOBILE & TABLET VIEW-->
        <div *ngIf="shouldShowMenu" class="mob-right-nav" data-wow-duration="0.5s">
          <div (click)="hideMenuAction()" class="mob-right-nav-close"><i class="fa fa-times" aria-hidden="true"></i>
          </div>
          <h5> {{ 'Navigation' | translate }}</h5>
          <ul class="mob-menu-icon">
<!--            <li (click)="hideMenuAction()" ><a [routerLink]="[signup]" class="v3-menu-sign" [class.tz-lmaa]="currentUrl === '/signup'"><i-->
<!--              class="fa fa-user-plus"></i> {{ 'Sign_Up' | translate }}</a></li>-->
            <li (click)="hideMenuAction()" ><a [routerLink]="[login]" class="v3-menu-sign" [class.tz-lmaa]="currentUrl.substr(0,6) === '/login'"><i
              class="fa fa-sign-in"></i> {{ 'Sign_In' | translate }}</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>




