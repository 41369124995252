<div class="second-menu" *ngIf="showedMenu" [style]="LeftPosition">

  <div class="second-menu-content " id="second-menu-content">
    <div *ngFor="let part of showedMenu" class="col-md-12" id="{{part.name}}">

      <div class="part-title row">
        <div class="row"
             (click)="part.show = !part.show; scroll($event, part.show,part.name, showedMenu.indexOf(part))">
          <h4 class="col-md-10">{{part.name}}</h4>
          <i *ngIf="!part.show" class="col-md-1 fa fa-angle-down"></i>
          <i *ngIf="part.show" class="col-md-1 fa fa-angle-up"> </i>
        </div>
        <hr class="col-md-11">
      </div>

      <div class="part-content col-md-offset-1" *ngIf="part.show">
        <div class="sub-name " *ngIf="part.subname">
          <h6>{{part.subname}}</h6>
        </div>
        <div *ngIf="part.content">
          <div *ngFor="let htmlElement of part.content">
            <!---------------------------- Start textarea ----------------------------->
            <div *ngIf="htmlElement.html.element.type === 'textarea'">
              <div class="row ">
                <h6 class="col-md-11"> {{htmlElement.html.label}} </h6>
              </div>
              <textarea class="col-md-11" [disabled]="!showGizmo"
                        value="{{htmlElement.html.value}}"
                        (input)="onChange($event, htmlElement.change)">
              </textarea>
              <hr class="col-md-11">
            </div>
            <!---------------------------- End textarea ----------------------------->
            <!---------------------------- Start input-number ----------------------------->
            <div *ngIf="htmlElement.html.element.type === 'input-number'">
              <div class="row ">
                <h6 class="col-md-11"> {{htmlElement.html.label}}</h6>
              </div>
              <div class="row col-md-11">
                  <input class="input-number" required
                         value="{{htmlElement.html.value}}"
                         id = "{{htmlElement.html.label}}"
                         [imask]="htmlElement.html.element.mask"
                         (accept)="onChange($event,htmlElement.change)"
                         [disabled]="!showGizmo"
                         (keydown) = "onKeydown($event, htmlElement.html.element.min)"
                         (keyup) = "onKeyup($event, htmlElement.change, htmlElement.html.label)"
                  />
              </div>
              <hr class="col-md-11">
            </div>
            <!---------------------------- End input-number----------------------------->
            <!---------------------------- Start input-video-link ----------------------------->
            <div *ngIf="htmlElement.html.element.type === 'input-video-link'">
              <div class="row ">
                <h6 class="col-md-11"> {{htmlElement.html.label}}</h6>
              </div>
              <div class="row ">
                <form [formGroup]="formVideoLink" class="col-md-11 ">
                  <input type="text"
                         [(ngModel)]="htmlElement.html.value"
                         [formControl]="formVideoLink.controls.link"
                         (input)="onChange($event,htmlElement.change)"
                         [disabled]="!showGizmo" />
                  <div class="error-form" *ngIf="formVideoLink.get('link').invalid">
                    {{ VALID_LINK | translate}}
                  </div>
                </form>

<!--                <div class="col-md-11">-->
<!--                  <input type="text"-->
<!--                         value="{{htmlElement.html.value}}"-->
<!--                         (input)="onChange($event,htmlElement.change)"-->
<!--                         [disabled]="!showGizmo"-->
<!--                  />-->
<!--                </div>-->
              </div>
              <hr class="col-md-11">
            </div>
            <!---------------------------- End input-video-link----------------------------->
            <!---------------------------- Start select ----------------------------->
            <div *ngIf="htmlElement.html.element.type === 'select'">
              <div class="row ">
                <h6 class="col-md-11"> {{htmlElement.html.label}}</h6>
              </div>
              <div class="row ">
                <mat-form-field class="col-md-11">
                  <mat-select [(ngModel)]="object.fontFamily" [disabled]="!showGizmo"
                              (selectionChange)="onChange($event,htmlElement.change); htmlElement.html.element.changed = true">
                    <div *ngFor="let option of htmlElement.html.element.options">
                      <mat-option value="{{option.value}}">{{option.label}}</mat-option>
                    </div>
                  </mat-select>
                </mat-form-field>
              </div>
              <hr class="col-md-11">
            </div>
            <!---------------------------- End select ----------------------------->
            <!---------------------------- Start radio ----------------------------->
            <div *ngIf="htmlElement.html.element.type === 'radio'">
              <div class="row ">
                <h6 class="col-md-11"> {{htmlElement.html.label}}</h6>
              </div>
              <div class="row col-md-12">
                <div class="range text-align" *ngFor="let option of htmlElement.html.element.options">
                  <div class="pretty p-icon p-toggle p-plain p-pulse col-md-1 right">
                    <input *ngIf="htmlElement.html.value === option.value" type="radio" [disabled]="!showGizmo"
                           name="{{htmlElement.html.label}}" (click)="onChange($event,htmlElement.change)"
                           value="{{option.value}}" checked/>
                    <input *ngIf=" htmlElement.html.value !== option.value" type="radio"
                           name="{{htmlElement.html.label}}" (click)="onChange($event,htmlElement.change)"
                           [disabled]="!showGizmo"
                           value="{{option.value}}"/>
                    <div class="state p-on">
                      <i class="{{option.icon}} "></i>
                      <label></label>
                    </div>
                    <div class="state  p-off">
                      <div class="">
                        <i class="{{option.icon}} "></i>
                      </div>
                      <label></label>
                    </div>
                    <span class="tooltiptext">{{option.label}}</span>
                  </div>
                </div>
              </div>
              <hr class="col-md-11">
            </div>
            <!---------------------------- End radio ----------------------------->

            <!---------------------------- Start range ----------------------------->
            <div *ngIf="htmlElement.html.element.type === 'range'">
              <div class="row ">
                <h6 class="col-md-9"> {{htmlElement.html.label}}</h6>
              </div>
              <div class="row ">
                <div class="col-md-9">
                  <input type="range" class="range-input" name="range" id="range" #range
                         min="{{htmlElement.html.element.min}}"
                         max="{{htmlElement.html.element.max}}"
                         step="{{htmlElement.html.element.step}}"
                         value="{{htmlElement.html.value}}"
                         (input)="onChange($event,htmlElement.change)"
                         [disabled]="!showGizmo"
                  />
                </div>
                <label class="col-md-2 range-input-label" for="range">{{range.value}}</label>
              </div>
              <hr class="col-md-11">
            </div>
            <!---------------------------- End range ----------------------------->

            <!---------------------------- Start color ----------------------------->
            <div *ngIf="htmlElement.html.element.type === 'color'">
              <div class="row ">
                <h6 class="col-md-11"> {{htmlElement.html.label}}</h6>
              </div>
              <div class="row ">
                <div class="col-md-1">
                  <button class="color-input"
                          [(colorPicker)]="htmlElement.html.value"
                          [cpOutputFormat]="'hex'"
                          [style.background]="htmlElement.html.value"
                          (colorPickerChange)="onChange($event,htmlElement.change)"
                          [disabled]="!showGizmo"></button>
                </div>
              </div>
              <hr class="col-md-11">
            </div>
            <!---------------------------- End color ----------------------------->

            <!---------------------------- Start input ----------------------------->
            <div *ngIf="htmlElement.html.element.type === 'input'">
              <div class="row ">
                <h6 class="col-md-11"> {{htmlElement.html.label}}</h6>
              </div>
              <div class="row ">
                <div class="col-md-11">
                  <input type="text" class="input-text-disabled-light"
                         value="{{htmlElement.html.value}}"
                         (input)="onChange($event,htmlElement.change)"
                         [disabled]="!showGizmo"
                  />
                </div>
              </div>
              <hr class="col-md-11">
            </div>
            <!---------------------------- End input ----------------------------->
            <!---------------------------- Start input-file ----------------------------->
            <div *ngIf="htmlElement.html.element.type === 'input-file'">
              <div class="row ">
                <h6 class="col-md-8">
                  {{htmlElement.html.label | translate }}
                </h6>
                <i class="col-md-2  {{htmlElement.html.element.editIcon}}" (click)="onChangeFile(htmlElement.change)" [ngClass]="{'edit-file-button':showGizmo}" ></i>
              </div>
              <div class="row ">
                <div class="col-md-11">
                  <input type="text" class="input-text-disabled"
                         value="{{htmlElement.html.value }}"
                         (input)="onChange($event,htmlElement.change)"
                         [disabled]="htmlElement.html.element.disabled"
                  />
                </div>
              </div>
              <hr class="col-md-11">
            </div>
            <!---------------------------- End input-file ----------------------------->
            <!---------------------------- Start slide ----------------------------->
            <div *ngIf="htmlElement.html.element.type === 'slide'">
              <div class="row ">
                <h6 class="col-md-11"> {{htmlElement.html.label}}</h6>
              </div>
              <div class="row ">
                <div class="col-md-3 slide">
                  <mat-slide-toggle color="primary"
                                    class="example-margin"
                                    [checked]="htmlElement.html.value"
                                    (change)="onChange($event,htmlElement.change)" [disabled]="!showGizmo"
                  >
                  </mat-slide-toggle>
                </div>
              </div>
              <hr class="col-md-11">
            </div>
            <!---------------------------- End slide ----------------------------->

            <!---------------------------- start location slide ----------------------------->
            <div *ngIf="htmlElement.html.element.type === 'tab-group'">
              <div class="pc-tab" (click)="tabClick($event)">
                <input  *ngFor=" let letgroup of htmlElement.html.element.tabgroup; let i=index;"
                        [checked]="i == 0"
                        id="tab{{i}}"
                        type="radio"
                        name="pct"
                        class="custom_tab_options"
                        value="{{letgroup.html.label}}"
                />
                <nav>
                  <ul>
                    <li *ngFor=" let letgroup of htmlElement.html.element.tabgroup; let i=index;"
                        class="tab{{i}}">
                      <label for="tab{{i}}" class="label-content">{{letgroup.html.label}}</label>
                    </li>
                  </ul>
                </nav>
                <section>
                  <div *ngFor=" let letgroup of htmlElement.html.element.tabgroup; let i=index;"
                       class="tab{{i}}" >
                    <div class="sub-name " *ngIf="letgroup.html.subname">
                      <h6>{{letgroup.html.subname}}</h6>
                    </div>
                    <div *ngFor=" let content of letgroup.html.content">
                      <div *ngIf="content.html.element.type === 'input-number'" class="col-md-12">
                        <div class="row input-number-row">
                          <h6 class="left"
                              style="color: {{content.html.color}}"> {{content.html.label}}</h6>
                          <div class="right"
                               [ngClass]="{'change-input': ( gizmoLocation.indexOf(content.change) !== -1)}">
                            <input class="input-number" required
                                   value="{{content.html.value}}"
                                   id = "{{content.html.label}}"
                                   [imask]="content.html.element.mask"
                                   (accept)="onChange($event,content.change)"
                                   [disabled]="!showGizmo"
                                   (keydown) = "onKeydown($event, content.html.element.min)"
                                   (keyup) = "onKeyup($event, content.change, content.html.label)"
                            />
                          </div>
                        </div>
                      </div>
                      <hr class="col-md-11">
                    </div>
                  </div>
                </section>
                <!--------------------------------------------------------------------this is the end of my test -- sana -------->
              </div>
            </div>
            <!---------------------------- end  location slide ----------------------------->

            <!------------------------------- start add button -------------------------->
            <div *ngIf="htmlElement.html.element.type === 'addButton'">
              <!-------------------------------  add button -------------------------->
              <div *ngIf="!htmlElement.html.value || htmlElement.html.value.length === 0"
                   class=" action-button-click  col-md-offset-2 col-md-8">
                <button
                  (click)=" openOnClickTypePopup(null)"
                  class=" action-button">
                  <h5> {{htmlElement.html.label}}</h5>
                </button>
              </div>
              <!-------------------------------  edit delete button -------------------------->
              <div *ngIf="htmlElement.html.value && htmlElement.html.value.length != 0"
                   class="  action-button-click  ">
                <div class=" col-md-offset-2 col-md-5 action-button-edit">
                  <button
                    (click)=" openOnClickTypePopup(object.onClick)"
                    class="  action-button  ">
                    <i class="fas fa-edit"></i>
                    <span class="tooltiptext">{{htmlElement.html.element.editLabel}}</span>
                  </button>
                </div>
                <div class="col-md-5">
                  <button
                    (click)=" deleteActionOnClick()"
                    class=" action-button action-button-delete">
                    <i class="fa fa-trash"></i>
                    <span class="tooltiptext">{{htmlElement.html.element.deleteLabel}}</span>
                  </button>
                </div>
              </div>
            </div>

            <!-------------------------------  end add button  -------------------------->

            <!------------------------------- start on click list -------------------------->
            <div *ngIf="htmlElement.html.element.type === 'list'">
              <div *ngFor="let l of htmlElement.html.value">

                <div class="row ">
                  <h5 class="col-md-11"> {{l.type}}</h5>
                </div>
                <!--------------------------------------- URL ---------------------->
                <div *ngIf="l.type === 'URL'">
                  <div class="row ">
                    <form [formGroup]="formUrl" class="   col-md-12 ">
                      <input type="text"
                             [formControl]="formUrl.controls.url"
                             (input)="inputURL()" />
                      <div class="error-form" *ngIf="formUrl.get('url').invalid">
                        {{ VALID_LINK | translate}}
                      </div>
                    </form>
                  </div>
                </div>
                <!--------------------------------------- URL ---------------------->

              </div>
              <div class="row ">
                <h6 class="col-md-11"> {{htmlElement.html.label}}</h6>
              </div>

            </div>

            <!-------------------------------  end on click list  -------------------------->

          </div>
        </div>

        <div *ngIf="part.actions" id="action-div">
          <div *ngFor="let action of part.actions" class=" div-button">
            <div *ngIf="action.type === 'button'">
              <button *ngIf="part.actions.indexOf(action) !== part.selected" [disabled]="!showGizmo"
                      class="action-button"
                      (click)="part.selected = part.actions.indexOf(action);selectAction(action.click)">
                <i class="{{action.icon}}"></i>
                <span class="tooltiptext">{{action.label}}</span>
              </button>
              <button *ngIf="part.actions.indexOf(action) === part.selected" [disabled]="!showGizmo"
                      (click)="part.selected = -1; deselectAction()"
                      class="action-button action-button-selected">
                <i class="{{action.icon}}"></i>
                <span class="tooltiptext">{{action.label}}</span>
              </button>
            </div>

            <div class="col-md-2" *ngIf="action.type === 'dbButton'">
              <button *ngIf="part.actions.indexOf(action) !== part.selected"
                      class="action-button"
                      (click)="part.selected = part.actions.indexOf(action);selectAction(action.click)">
                <i class="{{action.icon1}}"></i>
                <span class="tooltiptext">{{action.label1}}</span>
              </button>
              <button *ngIf="part.actions.indexOf(action) === part.selected"
                      (click)="part.selected = -1; deselectAction()"
                      class="action-button ">
                <i class="{{action.icon2}}"></i>
                <span class="tooltiptext">{{action.label2}}</span>
              </button>
            </div>


          </div>
        </div>

    </div>
    </div>
  </div>
  <div class="sidebar-close" (click)="toggleMenu()">
    <span> <i class="fa fa-times"></i> {{'Close' | translate}}</span>
  </div>
</div>

