import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {toNumbers} from '@angular/compiler-cli/src/diagnostics/typescript_version';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {EditorElementType} from '../../../../utils/global-constants/editor-const';

@Component({
  selector: 'app-right-menu',
  templateUrl: './right-menu.component.html',
  styleUrls: ['./right-menu.component.scss']
})
export class RightMenuComponent implements OnInit, OnChanges {
  openedelement = []; // class = merker

  hoverElementMenuRight = null; // class = { trigger: {}, componentList : []}
  hoverComponentMenuRight = null; // class = text, vdo..

  @Input() focusComponent; // class = text,audio... , html: composant=focusComponent => css : class = selected-component
  @Input() focusTrigger; // class = Trigger , html: Trigger=focusTrigger => css : class = selected-Trigger

  @Output() selectedTrigger = new EventEmitter(); // pour informer editor : select un Trigger (class=Trigger) =>
  // editor va envoyer focusTrigger & focusComponent = null + selection du mesh + menu secondaire
  @Output() selectedComponent = new EventEmitter(); // pour informer editor : select un composant (class: text audio ..) =>
  // editor va envoyer focusTrigger & focusComponent + selection du mesh + menu secondaire
  @Output() deleteEvent = new EventEmitter(); // pour informer editor : delete objet ( class: Trigger text audio ...)
  @Output() zoomEvent = new EventEmitter(); // pour informer editor : zoom objet ( class: Trigger text audio ...)
  @Output() showTriggerEvent = new EventEmitter(); // pour informer editor : show objet ( class: Trigger )
  @Output() showComponentEvent = new EventEmitter(); // pour informer editor : show objet ( class: text audio ...)
  @Output() hideTriggerEvent = new EventEmitter(); // pour informer editor : hide objet ( class: Trigger )
  @Output() hideComponentEvent = new EventEmitter(); // pour informer editor : hide objet ( class: text audio ...)
  @Output() dropComponentEvent = new EventEmitter(); // pour informer editor : drop objet ( class: text audio ...)
  @Output() initializeMenu = new EventEmitter();
  @Input() triggers;
  geoType = EditorElementType.geo.toString();

  surface = EditorElementType.surface.toString();
  showRightMenu = true; // pour button close||open
  constructor() {
  }

  ngOnInit(): void {
    if (this.triggers && this.triggers.length > 0) {
      this.triggers.forEach((el) => {
        this.openedelement.push(el.trigger);
      });
    }
  }

  ngOnChanges(changes): void {
    if (changes.focusComponent) {  // ajout+creation  d'un composant/delete => changes.focusComponent = null (pas undefined)
      if (this.openedelement.indexOf(this.focusTrigger) === -1) {
        this.openedelement.push(this.focusTrigger);
      }
      // it's used to initialize the url valid boolean when user select a component
      this.initializeMenu.emit();
    }
  }

  getComponentNameInRightMenu(name: string) {
    if (name.length > 8) {
      return name.substring(0, 7) + '...';
    } else {
      return name;
    }
  }

  getTriggerNameInRightMenu(name: string) {
    if (name.length > 8) {
      return name.substring(0, 8) + '...';
    } else {
      return name;
    }
  }

  openTrigger(trigger) {
    this.openedelement.push(trigger);
    this.selectedTrigger.emit(trigger);
  }

  closeTrigger(trigger) {
    this.openedelement.splice(this.openedelement.indexOf(trigger), 1);
  }

  onSelectTrigger(trigger) {
    this.selectedTrigger.emit(trigger);
    if (this.openedelement.indexOf(trigger) === -1) {
      this.openTrigger(trigger);
    } else {
      this.closeTrigger(trigger);
    }
  }

  onSelectComponent(component) { // component = class text, vdo , audio ...
    this.selectedComponent.emit(component);
  }

  delete(object) { // object = trigger || component
    if (object.visible === true) {
      this.deleteEvent.emit(object);
    }
  }

  zoom(object) {
    if (object.visible === true) {
      this.zoomEvent.emit(object);
    }
  }

  hideComponent(component) {
    this.hideComponentEvent.emit(component);
  }

  showComponent(component) {
    this.showComponentEvent.emit(component);
  }

  hideTrigger(trigger) {
    this.hideTriggerEvent.emit(trigger);
  }

  showTrigger(trigger) {
    this.showTriggerEvent.emit(trigger);
  }

  drop(event: CdkDragDrop<string[]>) {
    const comp = this.triggers[toNumbers(event.previousContainer.id)[0]].componentList[event.previousIndex];
    this.triggers[toNumbers(event.previousContainer.id)[0]].componentList.splice(event.previousIndex, 1);
    this.triggers[toNumbers(event.container.id)[0]].componentList.splice(event.currentIndex, 0, comp);
    this.dropComponentEvent.emit({
      triggerIndex: toNumbers(event.container.id)[0],
      componentIndex: event.currentIndex,
      component: comp
    });
    if (event.container.id !== event.previousContainer.id) {
      comp.triggerVisible = true;
    }
    this.openedelement.push(this.triggers[toNumbers(event.container.id)[0]].trigger);

  }

  range(x) {
    const tab = [];
    let i = 0;
    while (i < this.triggers.length) {
      if (x !== i) {
        tab.push(i.toString());
      }
      i++;
    }
    return tab;
  }

}

