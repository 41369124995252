import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-create-experience-dialog',
  templateUrl: './create-experience-dialog.component.html',
  styleUrls: ['./create-experience-dialog.component.scss']
})
export class CreateExperienceDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<any>) { }

  ngOnInit(): void {
  }

  onCreate() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close();
  }
}
