<!--<a [routerLink]="[linkDetails]" *ngIf="!homeSection">-->
  <div class="listing-experiences-item home-list-pop" *ngIf="!homeSection">
    <div class="item-cover-picture" [style]="customStyle"></div>
    <div class="inline-trigger img-trigger item-name item-name-home" title="{{data.name}}">
      <a [routerLink]="[linkDetails]">{{data.name}}</a>
      <!------------ trigger for template --------------->
      <!--    <img *ngIf="data.trigger == surface" src="assets/images/surface.png" [title]="surfaceText">-->

      <i *ngIf="data.trigger == surface" class="fa fa-th" [title]="surfaceText"></i>
      <i *ngIf="data.trigger == marker" class="far fa-image" [title]="markerText"></i>
      <!------------ end trigger for template --------------->
    </div>
    <div class="item-description">{{data.description}}</div>
    <div class="item-action">
      <a [routerLink]="[linkDetails]">
        <i class="fa fa-play" aria-hidden="true"></i>{{'SEE-TEMPLATE-DETAILS' | translate}}
      </a>
    </div>
  </div>
<!--</a>-->

<!--<a [routerLink]="[linkDetails]" *ngIf="homeSection">-->
  <div class="listing-experiences-item-home home-list-pop" *ngIf="homeSection">
    <div class="item-cover-picture" [style]="customStyle"></div>
    <div class="inline-trigger img-trigger" title="{{data.name}}">
      <div class="item-name"><a [routerLink]="[linkDetails]">{{data.name}}</a></div>
      <!------------ trigger for template --------------->
      <!--    <img *ngIf="data.trigger == surface" src="assets/images/surface.png" [title]="surfaceText">-->

      <i *ngIf="data.trigger == surface" class="fa fa-th" [title]="surfaceText"></i>
      <i *ngIf="data.trigger == marker" class="far fa-image" [title]="markerText"></i>
      <!------------ end trigger for template --------------->
    </div>
    <div class="item-description">{{data.description}}</div>
    <div class="item-action">
      <a [routerLink]="[linkDetails]">
        <i class="fa fa-play" aria-hidden="true"></i>{{'SEE-TEMPLATE-DETAILS' | translate}}
      </a>
    </div>
  </div>
<!--</a>-->
