import {BrowserModule, Title} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {LocationStrategy, PathLocationStrategy, registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {PagesModule} from './pages/pages.module';
import {ReusablesModule} from './reusables/reusables.module';
import {CookieService} from 'ngx-cookie-service';
// Firebase imports
import { AngularFireModule } from '@angular/fire';


import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { EmbedVideo } from 'ngx-embed-video';
import {ConfirmDialogService} from './reusables/components/confirm-dialog/confirm-dialog.service';
import { DeviceDetectorModule } from 'ngx-device-detector';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {environment} from '../environments/environment';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaFormsModule } from 'ng-recaptcha';
import {ErrorInterceptor, JwtInterceptor} from './reusables/_helpers';
import {NgxJdenticonModule} from 'ngx-jdenticon';
import {NgxCarouselModule} from 'ngx-light-carousel';
import { OwlModule } from 'ngx-owl-carousel';
import {FacebookModule} from "ngx-facebook";
import { ShareButtonModule } from 'ngx-sharebuttons/button';
import {appInitializer} from "./reusables/_helpers/app.initializer";

registerLocaleData(localeFr, 'fr-FR');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent
  ],
  imports: [
    BrowserModule,
    NgxJdenticonModule,
    AngularFireModule.initializeApp(environment.firebase), // Initialize Firebase
    // AngularFireAuthModule, // Firebase auth
    RecaptchaModule, // for reCaptcha (signup page)
    RecaptchaFormsModule,
    ReusablesModule,
    PagesModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AutocompleteLibModule,
    ToastrModule.forRoot(),
    EmbedVideo.forRoot(),
    DeviceDetectorModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxCarouselModule,
    OwlModule,
    FacebookModule.forRoot(),
    ShareButtonModule
  ],
  exports: [
    TranslateModule,
    ReusablesModule,
    PagesModule,
    AppComponent
  ],

  providers: [
    Title,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {provide: APP_INITIALIZER, useFactory: () => appInitializer, multi: true},
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LeaDqwZAAAAAHeMxYFmcVCbzIMKJsrgy8s79vwV',
      } as RecaptchaSettings,
    },
     {provide: LocationStrategy, useClass: PathLocationStrategy},
    ConfirmDialogService
  ],
  bootstrap: [AppComponent]

})
export class AppModule {
}
