import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {AuthenticationService} from '../services/authentication.service';
import {TokenHelperService} from '../services/token-helper.service';
import {UsersService} from '../services/users.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private tokenHelperService: TokenHelperService,
    private  usersService: UsersService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentIdToken = this.authenticationService.currentUserIdTokenValue;
    if (this.tokenHelperService.getConnectedUser()
      && currentIdToken
      && Date.now() < this.tokenHelperService.getTokenData().exp * 1000) {
      // token exist with not expire date
      return true;
    } else {
      if (this.tokenHelperService.getConnectedUser()
        && currentIdToken && Date.now() > this.tokenHelperService.getTokenData().exp * 1000){
        // Date token expire => refresh
        this.authenticationService.refreshToken();
        return true;
      }
    }
    this.usersService.changeNavBar(false);
    const queryParams = {returnUrl: state.url};
    this.authenticationService.logout(queryParams);
    return false;
  }
}
