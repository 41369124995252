import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-analytics-filter',
  templateUrl: './analytics-filter.component.html',
  styleUrls: ['./analytics-filter.component.scss']
})
export class AnalyticsFilterComponent implements OnInit {

  @Input() experiences;
  @Input() initialSelection;
  @Output() selectionChanged = new EventEmitter<number>();

  selectedValue = '';
  constructor() {
  }

  ngOnInit(): void {
    this.selectedValue = '' + this.initialSelection;
  }

  onSelectionChanged(value) {
    this.selectionChanged.emit(value);
  }
}
