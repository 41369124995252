import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BLANK_EXPERIENCE, CREATE_EXPERIENCE_PAGE_TITLE} from '../../../utils/actionsLabels/labels';
import {Experience} from '../../interfaces/experience';
import {Template} from '../../interfaces/templates';
import {Router} from '@angular/router';

@Component({
  selector: 'app-choose-create-experience-form',
  templateUrl: './choose-create-experience-form.component.html',
  styleUrls: ['./choose-create-experience-form.component.scss']
})
export class ChooseCreateExperienceFormComponent implements OnInit {
  pageTitle = CREATE_EXPERIENCE_PAGE_TITLE;
  blankExperience =  BLANK_EXPERIENCE;
  @Input() userExperiences: Experience[];
  @Input() doneForUserExperiences: boolean;
  @Input() userTemplates: Template [];
  @Input() carouselOptions: any;
  @Input() doneForUserTemplates: boolean;
  @Input() publishedTemplates: Template [];
  @Input() doneForPublishedTemplates: boolean;
  @Output() openCreateExperiencePage: EventEmitter<{type: string, source: string, content_id: string}> = new EventEmitter<any>();
  myLibrary: any[];

  constructor() { }

  ngOnInit(): void {
    // add attribute 'type' (used in the carousel to know the type of the item)
    this.publishedTemplates.forEach(item => {
     item['type'] = 'template';
    });
    this.userTemplates.forEach(item => {
     item['type'] = 'template';
    });
    this.userExperiences.forEach(item => {
     item['type'] = 'experience';
    });
    // construct an array for my library (my templates + my experiences)
    this.myLibrary = [].concat(this.userTemplates, this.userExperiences);
  }

  onSelect(typeAction, sourceAction, content) {
    this.openCreateExperiencePage.emit({type: typeAction, source: sourceAction, content_id: content});
  }

  useTemplateOrExperience(event) {
    this.openCreateExperiencePage.emit({type: event.type, source: event.source, content_id: event.content_id});
  }

}
