import {
  Component, ElementRef,
  EventEmitter,
  HostListener,
  Input, OnChanges,
  OnInit,
  Output, ViewChild,
  ViewEncapsulation,
  SimpleChanges
} from '@angular/core';
import {Experience, GetExperienceDeletedResponse} from '../../interfaces/experience';
import * as $ from 'jquery';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  CAN_NOT_UPLOAD_MORE,
  ADD_COVER_PICTURE_OPERATION,
  CREATE_EXPERIENCE_BUTTON,
  CREATE_EXPERIENCE_BUTTON_TITLE,
  CREATE_EXPERIENCE_PAGE_TITLE,
  EDIT_EXPERIENCE_BUTTON,
  EDIT_EXPERIENCE_EVENT,
  EDIT_EXPERIENCE_PAGE_TITLE,
  NEW_EXPERIENCE_EVENT,
  YOU_HAVE_UPLOAD_8_IMAGES,
  CAN_NOT_ALLOWED_OS,
  CAN_NOT_ALLOWED_OS_TITLE,
  NONE_COVER_PICTURE_OPERATION,
  DELETE_COVER_PICTURE_OPERATION,
  REPLACE_COVER_PICTURE_OPERATION,
  DELETE,
  ASK_FOR_CONFIRM_DELETE_EXPERIENCE_GROUP_ACTION,
  SUCCESS_DELETE_EXPERIENCES_MESSAGE_TITLE,
  FAILED_DELETE_EXPERIENCE_GROUP_MESSAGE_CONTENT, FAILED_DELETE_EXPERIENCE_GROUP_MESSAGE_TITLE
} from '../../../utils/actionsLabels/labels';
import {EDITOR_EXPERIENCE_PATH} from '../../../utils/actionsLabels/pathLabels';
import {NotificationService} from '../../services/notification.service';
import {youtubeVideoForm} from '../../../utils/global-constants/regExp';
import {
  editExperienceStatusAdmin,
  editExperienceStatusUser,
  experienceStatus
} from '../../../utils/global-constants/experienceStatusColors';
import {ExperienceGroup, Group} from '../../interfaces/group';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {VisibleGlobally} from '../../../utils/global-constants/experiences-const';
import {RoleGroup} from '../../../utils/global-constants/groups-const';
import {ConfirmDialogService} from '../confirm-dialog/confirm-dialog.service';
import {GroupsService} from '../../services/groups.service';
import {BaseURLLink} from "../../../utils/global-constants/baseUrl";
import {userRole} from "../../../utils/global-constants/user-const";
import {User} from "../../interfaces/user";

@Component({
  selector: 'app-add-edit-experience-form',
  templateUrl: './add-edit-experience-form.component.html',
  styleUrls: ['./add-edit-experience-form.component.scss',
    '../../../../assets/customCss/customTextarea.css'],
  encapsulation: ViewEncapsulation.None
})
export class AddEditExperienceFormComponent implements OnInit, OnChanges {
  deleteText = DELETE;
  myForm: FormGroup;
  editorLink = '';
  experienceStatus: any;
  buttonText = CREATE_EXPERIENCE_BUTTON;
  buttonTextTitle = CREATE_EXPERIENCE_BUTTON_TITLE;
  RoleGroupNotMember = RoleGroup.NOT_MEMBER;
  RoleGroupMember = RoleGroup.MEMBER;
  RoleGroupManager = RoleGroup.MANAGER;

  // ***** image-Cropper ******/
  croppedImage: any;
  aspectRatio = 1;
  resizeToWidth = null;
  fileCoverPicture;
  imageWidth;
  coverPictureOperation: string = NONE_COVER_PICTURE_OPERATION;
  @Input() experience: Experience;
  @Input() groups: Group[];
  @Input() experienceGroups: ExperienceGroup[];
  @Output() request = new EventEmitter();
  @Output() searchGroupsRequest = new EventEmitter();
  @ViewChild('galleryFile') galleryFile: ElementRef;
  visibleGlobally = true;
  url: string;
  fileName = '';
  media: any = [];
  mediaRequest: any = [];
  pageTitle = CREATE_EXPERIENCE_PAGE_TITLE;
  mediaScreenChanged = false;
  fileList: any = [];
  displayNamesGallery: string[] = [];
  toDeleteFromGallery: string[] = [];
  oldMediaImages: string[] = [];
  activeRadioLabel = 'active';
  selectedGroupsIds: string[];
  selectedGroups: Group[];
  actionEmit = NEW_EXPERIENCE_EVENT;
  typeMobile = false;
  privacy: { name: string, value: string, htmlValue: string, class: string };
  @Output() goBack = new EventEmitter();
  @Input() chooseCreate;
  @Input() user: User;
  isAdmin = false;

  constructor(private formBuilder: FormBuilder,
              private confirmDialogService: ConfirmDialogService,
              private groupsService: GroupsService,
              private notificationService: NotificationService) {
    this.myForm = this.formBuilder.group({
      coverPicture: [''],
      name: ['', [Validators.required]],
      description: [''],
      analyticsId: [''],
      status: [],
      coverPictureDisplayName: [''],
      video: ['', [Validators.pattern(youtubeVideoForm)]],
      media: [''],
      categories: [],
      groups_of_experience: []
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    this.chooseCreate = true;
    if (window.innerWidth <= 1200) {
      this.mediaScreenChanged = true;
    }
    if (window.innerWidth <= 768) {
      this.typeMobile = true;
    }
    if (this.experience && this.experience.id !== '') {
      this.initForm(this.experience);
      this.buttonText = EDIT_EXPERIENCE_BUTTON;
      this.actionEmit = EDIT_EXPERIENCE_EVENT;
      if ((this.user.role === userRole.SUPER_ADMIN.valueOf() || this.user.role === userRole.ADMIN.valueOf()) &&
        this.user.id === this.experience.creator.userId) {
        this.experienceStatus = editExperienceStatusAdmin;
        this.isAdmin = true;
      } else {
        this.experienceStatus = editExperienceStatusUser;
        this.isAdmin = false;
      }
    }
    this.aspectRatio = window.innerWidth / (window.innerHeight * 0.4); // 160 = height du cover image
  }


  initForm(exp: Experience) {
    this.myForm.get('name').setValue(exp.name);
    this.myForm.get('video').setValue(exp.video);
    this.myForm.get('analyticsId').setValue(exp.analyticsId);
    this.myForm.get('status').setValue(exp.status);
    $('.privacy-label.status_label_' + exp.status).addClass('active');
    this.myForm.get('description').setValue(exp.description);
    this.fileName = exp.coverPictureDisplayName;
    this.url = exp.coverPicture;
    this.pageTitle = EDIT_EXPERIENCE_PAGE_TITLE;
    this.editorLink = EDITOR_EXPERIENCE_PATH + '/' + exp.id + '/content/' + exp.content;
    this.media = [];
    this.visibleGlobally = (exp.visible_globally === VisibleGlobally.VISIBLE);
    this.displayNamesGallery = [];
    if (exp.gallery.length > 0) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < exp.gallery.length; i++) {
        const elem = exp.gallery[i];
        this.media.push({
          url: elem.url,
          name: elem.displayName
        });
        this.displayNamesGallery.push(elem.displayName);
        this.oldMediaImages.push(elem.id);
      }
    }
  }

  openEditor() {
    window.open(BaseURLLink + this.editorLink, '_blank').focus();

  }
  get f() {
    return this.myForm.controls;
  }

  addPhoto() {
    this.coverPictureOperation = ADD_COVER_PICTURE_OPERATION;
    $('#coverPicture').click();
  }

  addMedias() {
    $('#media').click();
  }

  /********************************** CoverProfile ***********************/
  onCoverProfileChange(event) {
    if (event.target.files.length > 0) {
      this.fileCoverPicture = (event.target as HTMLInputElement).files[0];
      const reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      // tslint:disable-next-line:no-shadowed-variable
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = null;
        this.fileName = this.fileCoverPicture.name;
        this.myForm.get('coverPicture').setValue(this.fileCoverPicture);

        // pour resize width s'il est > 1920px
        const image = new Image();
        image.src = event.target.result.toString();
        image.onload = () => {
          this.imageWidth = image.width;
          if (this.imageWidth > 1920) {
            this.resizeToWidth = 1920;
          } else {
            this.resizeToWidth = this.imageWidth;
          }
        };
      };
    }
  }

  // ********************************** CoverProfile image-cropper start ***********************//


  imageLoaded() {
    if (this.experience && this.experience.id !== '') {
      if (this.experience.coverPicture !== '') {
        this.coverPictureOperation = REPLACE_COVER_PICTURE_OPERATION;
      } else {
        this.coverPictureOperation = ADD_COVER_PICTURE_OPERATION;
      }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    if (event.width > 1920) {
      this.resizeToWidth = 1920;
    } else {
      this.resizeToWidth = event.width;
    }
  }

  // ********************************** CoverProfile image-cropper end  ***********************//


  deleteProfileCover() {
    this.url = '';
    this.fileName = '';
    this.coverPictureOperation = DELETE_COVER_PICTURE_OPERATION;
    this.myForm.get('coverPicture').setValue('');
  }

  onMediaChange(event) {
    if (event.target.files.length > 0) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        const reader = new FileReader();
        this.fileList.push(event.target.files[i]);
        reader.readAsDataURL(event.target.files[i]); // read file as data url

        // tslint:disable-next-line:no-shadowed-variable
        reader.onload = (event) => {
          const media = {url: event.target.result, name: file.name};
          if (this.media.length < 8) {
            this.media.push(media);
            // this.addDisplayNameGallery();
            this.mediaRequest.push(file);
            this.displayNamesGallery.push(file.name);
          } else {
            this.notificationService.showWarning(YOU_HAVE_UPLOAD_8_IMAGES, CAN_NOT_UPLOAD_MORE);
          }
        };
      }
      this.galleryFile.nativeElement.value = '';
      this.myForm.get('media').setValue(this.mediaRequest);
    }
  }

  changeDisplayName(event, index) {
    this.displayNamesGallery[index] = event.target.value;
  }

  deleteThumbnail(index) {
    if (index > -1) {
      this.media.splice(index, 1);
      // this.removeDisplayNameGallery(index);
      this.displayNamesGallery.splice(index, 1);
      if (this.experience && this.experience.id !== '') {
        const toDelete = this.oldMediaImages[index];
        this.toDeleteFromGallery.push(toDelete);
      }
      if (this.oldMediaImages && this.oldMediaImages.length > 0) {
        this.oldMediaImages.splice(index, 1);
      }
      this.mediaRequest.splice(index, 1);
      this.fileList.splice(index, 1);
    }
  }


  croppedImg(dataRequest, data) {
    // emit request
    if (this.croppedImage) {
      fetch(this.croppedImage)
        .then(res => res.blob()).then(bl => {
        const file = new File([bl], this.fileName);
        dataRequest['coverPicture'] = file;
        if (data.coverPictureDisplayName !== '') {
          dataRequest['coverPictureDisplayName'] = data.coverPictureDisplayName;
        } else {
          dataRequest['coverPictureDisplayName'] = this.fileName;
        }
        dataRequest['coverPictureOperation'] = ADD_COVER_PICTURE_OPERATION;
        this.request.emit({
          action: NEW_EXPERIENCE_EVENT,
          data: dataRequest,
          coverPicture: file,
          gallery: this.fileList,
          globally: this.visibleGlobally,
          groups: this.selectedGroups,
          notDeletedGroups: this.experienceGroups.map((g) => g.id)
        });
      });
    } else {
      this.request.emit({
        action: NEW_EXPERIENCE_EVENT,
        data: dataRequest,
        coverPicture: this.myForm.get('coverPicture').value,
        gallery: this.fileList,
        globally: this.visibleGlobally,
        groups: this.selectedGroups,
        notDeletedGroups: this.experienceGroups.map((g) => g.id)
      });
    }
  }

  onSubmit(data) {
    const dataRequest = {
      name: data.name,
      video: data.video,
      analyticsId: data.analyticsId,
      status: data.status,
      description: data.description,
      media: this.mediaRequest,
      galleryDisplayName: this.displayNamesGallery,
      coverPictureDisplayName: data.coverPictureDisplayName,
      coverPictureOperation: this.coverPictureOperation
    };
    if (this.actionEmit === NEW_EXPERIENCE_EVENT) {// create new experience
      this.croppedImg(dataRequest, data);
    } else {       // edit experience
      if (this.experience && this.experience.id !== '') {
        dataRequest['id'] = this.experience.id;
        dataRequest['mediaIds'] = this.oldMediaImages;
        dataRequest['mediaToDelete'] = this.toDeleteFromGallery;
      }
      this.croppedImg(dataRequest, data);
    }
  }


  resetForm() {
    if (this.experience && this.experience.id !== '') {
      this.initForm(this.experience);
      window.history.back();
    } else {
      this.myForm.reset();
      this.url = '';
      this.fileName = '';
      this.media = [];
      this.displayNamesGallery = [];
      this.goBack.emit();
    }
  }

  changeExperienceStatus(event) {
    $('.privacy-label').removeClass('active');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mediaScreenChanged = window.innerWidth <= 1200;
  }

  changeVisibleGlobally() {
    this.visibleGlobally = !this.visibleGlobally;
  }

  /**
   * on change select groups items, update groups in bottom
   */
  onChange(event) {
    this.selectedGroups = event;
  }

  /**
   * on search, send to parent component "term" to search and added group list
   */
  onSearch(event) {
    this.searchGroupsRequest.emit({term: event.term, added: this.selectedGroupsIds});
  }

  /**
   * delete member from the list in bottom
   */
  deleteGroupFromList(item) {
    const aux = this.selectedGroups.indexOf(item);
    this.selectedGroups.splice(aux, 1);
    this.selectedGroupsIds = this.selectedGroupsIds.filter(id => id !== item.id);
  }

  showMessage() {
    this.notificationService.showInfo(CAN_NOT_ALLOWED_OS, CAN_NOT_ALLOWED_OS_TITLE);
  }


  deleteGroup(expGroup: ExperienceGroup) {
    const deletedExperience = []
    deletedExperience.push({'experienceId': this.experience.id})
    this.confirmDialogService.confirmThis(ASK_FOR_CONFIRM_DELETE_EXPERIENCE_GROUP_ACTION, () => {
      this.groupsService.deleteExperiences(deletedExperience, expGroup.id).subscribe((response: GetExperienceDeletedResponse) => {
          response.deleted_experiences.forEach((exp) => this.notificationService.showSuccess('',
            SUCCESS_DELETE_EXPERIENCES_MESSAGE_TITLE)), this.experienceGroups.splice(this.experienceGroups.indexOf(expGroup), 1);
          response.invalid_experiences_ids.forEach((exp) => this.notificationService.showError(
            FAILED_DELETE_EXPERIENCE_GROUP_MESSAGE_CONTENT, FAILED_DELETE_EXPERIENCE_GROUP_MESSAGE_TITLE));
        });
    }, () => {
    });
  }

  // On some Android devices, input name allows you to write more than the maximum length
  // Force maxlength input on web mobile
  substrName(){
    if (this.myForm.get('name').value.length > 25){
      this.myForm.get('name').setValue(this.myForm.get('name').value.substring(0, 25));
    }
  }

}
