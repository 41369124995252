<div class="tz-2-com tz-2-main">
  <h4>{{pageTitle | translate }}</h4>
  <div class="db-list-com tz-db-table">
    <div class="hom-cre-acc-left hom-cre-acc-right">
      <div class="">
        <form [formGroup]="myForm" >
          <div class="row">
            <div class="db-v2-list-form-inn-tit">
              <div *ngIf="experience && experience.id !== ''" class="editor_link_container in_mobile">
                <a (click)="openEditor()"
                   class="show_in_desktop waves-effect waves-light btn-large button-style"
                   title="{{'CLICK_TO_EDIT_YOUR_EXPERIENCE_IN_EDITOR' | translate}}">
                  {{'Open_experience_in_editor' | translate}}
                </a>
                <a [routerLink]="['/experiences/' + experience.id]"
                   class="show_in_mobile waves-effect waves-light btn-large"
                   title="{{'PREVIEW' | translate}}">
                  {{'PREVIEW' | translate}}
                </a>
                <br>
                <a class="show_in_mobile" (click)="showMessage()">
                  <button type="submit" class="waves-effect waves-light btn-large" disabled="typeMobile"
                          title="{{'CLICK_TO_EDIT_YOUR_EXPERIENCE_IN_EDITOR' | translate}}">
                    {{'Open_experience_in_editor' | translate}}
                  </button>
                </a>
              </div>
              <h5>{{"EXPERIENCE_GENERAL_INFORMATION" | translate}}</h5>
              <div *ngIf="experience && experience.id !== ''" class="editor_link_container in_desktop">
                <ul class="additional_actions">
                  <li>
                    <a [routerLink]="['/experiences/' + experience.id]"
                       class="show_in_desktop waves-effect waves-light btn-large button-style"
                       title="{{'PREVIEW' | translate}}">
                      {{'PREVIEW' | translate}}
                    </a>
                  </li>
                  <li>
                    <a (click)="openEditor()" class="right waves-effect waves-light btn-large button-style"
                       title="{{'CLICK_TO_EDIT_YOUR_EXPERIENCE_IN_EDITOR' | translate}}">
                      {{'Open_experience_in_editor' | translate}}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="input-field col s12">
              <input type="text" id="name"
                     class="form-control" formControlName="name" maxlength="25" (keyup)="substrName()"
                     [ngClass]="myForm.get('name').invalid && myForm.get('name').touched ? 'invalid': 'validate'"
              />
              <label for="name"
                     [class.active]="experience && experience.id  !== ''">{{"NAME_EXPERIENCE" | translate}} </label>
              <span
                class="v2-db-form-note">{{"name_experience_info" | translate}}</span>
            </div>
          </div>
          <div class="row " *ngIf="myForm.get('name').invalid && myForm.get('name').touched">
            <div class="alert alert-danger">{{'EXPERIENCE_NAME_IS_REQUIRED' | translate}}</div>
          </div>
          <div class="row">
            <div class="input-field col s12">
              <textarea id="description"
                        class="materialize-textarea"
                        formControlName="description"
                        autosize></textarea>
              <label for="description"
                     [class.active]="experience && experience.id !== '' && experience.description !== ''">
                {{"DESCRIPTION_EXPERIENCE" | translate}}</label>
            </div>
          </div>
          <br/>
          <div class="row" *ngIf="experience && experience.id !== ''">
            <div class="chec-out-pay">
              <div class="privacy-title db-v2-list-form-inn-tit">
                <h5>{{"Status" | translate}} </h5>
              </div>
              <!-------------------- end status for user and admin (other experience) --------->
              <div *ngIf="!isAdmin">
                <input class="privacy-input" name="status" type="radio" formControlName="status"  value="{{experienceStatus[0].value}}"
                       id="{{experienceStatus[0].label | translate}}"  disabled/>
                <label class="privacy-label status_label_{{0}}" [ngClass]="{'active': experienceStatus[0].value === experience.status}"
                       for="{{experienceStatus[0].label | translate}}">{{experienceStatus[0].label | translate}}</label>
                <input class="privacy-input" name="status" type="radio" id="{{experienceStatus[1].label | translate}}"
                       formControlName="status" value="{{experienceStatus[1].value}}" (change)="changeExperienceStatus($event)"
                       [checked]="experienceStatus[1].value === experience.status"/>
                <label class="privacy-label status_label_{{1}}" [ngClass]="{'active': experienceStatus[1].value === experience.status}"
                       for="{{experienceStatus[1].label | translate}}">{{experienceStatus[1].label | translate}}</label>
                <input class="privacy-input" name="status" type="radio" id="{{experienceStatus[2].label | translate}}"
                       formControlName="status" value="{{experienceStatus[2].value}}" (change)="changeExperienceStatus($event)"
                       [checked]="experienceStatus[2].value === experience.status"/>
                <label class="privacy-label status_label_{{2}}" [ngClass]="{'active': experienceStatus[2].value === experience.status}"
                       for="{{experienceStatus[2].label | translate}}">{{experienceStatus[2].label | translate}}</label>
                <input class="privacy-input" name="status" type="radio" id="{{experienceStatus[3].label | translate}}"
                       formControlName="status" value="{{experienceStatus[3].value}}" (change)="changeExperienceStatus($event)"
                       [checked]="experienceStatus[3].value === experience.status"/>
                <label class="privacy-label status_label_{{3}}" [ngClass]="{'active': experienceStatus[3].value === experience.status}"
                       for="{{experienceStatus[3].label | translate}}">{{experienceStatus[3].label | translate}}</label>
              </div>
              <!-------------------- end status for user and admin (other experience)--------->
              <!--------------------------- status for admin (his experience) --------------->
              <div *ngIf="isAdmin">
                <div *ngFor="let statusExp of experienceStatus; let i = index" class="status_of_experience">
                  <input class="privacy-input" name="status" type="radio" id="{{statusExp.label | translate}}"
                         formControlName="status"
                         value="{{statusExp.value}}" (change)="changeExperienceStatus($event)"
                         [checked]="statusExp.value === experience.status"/>
                  <label class="privacy-label status_label_{{i}}"
                         [ngClass]="{'active': statusExp.value === experience.status}"
                         for="{{statusExp.label | translate}}">{{statusExp.label | translate}}</label>
                </div>
              </div>
              <!---------------------------- end status for admin (his experience) ------------>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="db-v2-list-form-inn-tit">
              <h5>{{"Cover Image" | translate}} </h5>
            </div>
          </div>
          <div class="row tz-file-upload">
            <div class="file-field input-field">
              <div class="tz-up-btn" *ngIf="fileName === ''" title="{{'CLICK_TO_ADD_COVER_PHOTO' | translate}}">
                <span (click)="addPhoto()">{{"File" | translate}}</span>
                <input type="file" id="coverPicture"
                       class="form-control" formControlName="coverPicture"
                       accept="image/png, image/jpeg, image/jpg"
                       (change)="onCoverProfileChange($event)">
              </div>

              <div *ngIf="fileName !== ''" class="input-field col s12 ">
                <div class="thumbnail-content pl-45">
                  <div class="item-number inline"></div>
                  <div class="img-thumbnail-content-cover-picture inline">
                    <img [src]="croppedImage" *ngIf="croppedImage && croppedImage !== ''" alt=""/>
                    <img [src]="url" *ngIf="url && url !== ''" alt=""/>
                  </div>
                  <input type="text" value="{{fileName}}" disabled class="inline"/>
                  <input type="text" class="inline"
                         placeholder="{{'OPTIONAL_DESCRIPTION' | translate}}"
                         formControlName="coverPictureDisplayName"/>
                  <button class="btn btn-danger inline" (click)="deleteProfileCover()"
                          title="{{'DELETE_COVER_EXPERIENCE' | translate}}"><i class="fa fa-trash"></i></button>
                </div>
                <image-cropper *ngIf="fileCoverPicture"
                               [imageFile]="fileCoverPicture"
                               [maintainAspectRatio]="true"
                               [containWithinAspectRatio]="false"
                               [aspectRatio]="1920/570"
                               [resizeToWidth]="resizeToWidth"
                               [cropperMinWidth]="100"
                               [onlyScaleDown]="true"
                               [roundCropper]="false"
                               [canvasRotation]="0"
                               [alignImage]="'center'"
                               format="png"
                               (imageCropped)="imageCropped($event)"
                               (imageLoaded)="imageLoaded()"
                ></image-cropper>
              </div>

            </div>
          </div>
          <br/>
          <div class="row">
            <div class="db-v2-list-form-inn-tit">
              <h5>{{"Photo Gallery" | translate}} <span
                class="v2-db-form-note">{{"gallery_experience_info" | translate}}</span></h5>
            </div>
          </div>
          <div class="input-field col s12 " *ngIf="media && media.length > 0">
            <div class="thumbnail-content" *ngFor="let media of media; let i = index;">
              <div *ngIf="!mediaScreenChanged">
                <div class="item-number inline">{{i + 1}}</div>
                <div class="img-thumbnail-content inline">
                  <img [src]="media.url" height="45"/>
                </div>
                <input type="text" value="{{media.name}}" disabled class="inline"/>
                <input type="text" class="inline"
                       placeholder="{{'OPTIONAL_DESCRIPTION' | translate}}"
                       (change)="changeDisplayName($event, i)"/>
                <button class="btn btn-danger inline" (click)="deleteThumbnail(i)"
                        title="{{'DELETE_GALLERY_PHOTO' | translate}}"><i class="fa fa-trash"></i></button>
              </div>

              <div *ngIf="mediaScreenChanged">
                <div style="overflow: auto">
                  <div class="item-number inline">{{i + 1}}</div>
                  <div class="img-thumbnail-content inline">
                    <img [src]="media.url" height="45"/>
                  </div>
                  <button class="btn btn-danger left" (click)="deleteThumbnail(i)"
                          title="{{'DELETE_GALLERY_PHOTO' | translate}}"><i class="fa fa-trash"></i></button>

                </div>
                <div class="left"><input type="text" value="{{media.name}}" disabled/></div>
                <div class="left"><input type="text"
                                         placeholder="{{'OPTIONAL_DESCRIPTION' | translate}}"
                                         (change)="changeDisplayName($event, i)"/></div>
              </div>
              <br/>
            </div>
          </div>


          <div class="row tz-file-upload">
            <div class="file-field input-field" *ngIf="media.length < 8"
                 title="{{'CLICK_TO_ADD_PHOTOS_GALLERY' | translate}}">
              <div class="tz-up-btn"><span (click)="addMedias()">{{"File" | translate}}</span>
                <input #galleryFile type="file" id="media"
                       class="form-control" formControlName="media" multiple
                       accept="image/png, image/jpeg, image/jpg" (change)="onMediaChange($event)"/></div>
            </div>

          </div>
          <br/>
          <div class="row">
            <div class="db-v2-list-form-inn-tit">
              <h5>{{"EXPERIENCE_VIDEO" | translate}}</h5>
            </div>
          </div>
          <div class="row">
            <div class="input-field col s12">
              <input type="text" id="video" formControlName="video"
                     class="form-control"
                     [ngClass]="myForm.get('video').invalid && myForm.get('video').touched ? 'invalid': 'validate'"
              />
              <label for="video"
                     [class.active]="experience && experience.id !== '' && experience.video !== ''">{{"VIDEO_EXPERIENCE" | translate}}</label>
            </div>
          </div>
          <div class="row " *ngIf="myForm.get('video').invalid && myForm.get('video').touched">
            <div class="alert alert-danger">{{'EXPERIENCE_VIDEO_IS_REQUIRED' | translate}}</div>
          </div>
          <br/>
          <br/>
          <div class="row">
            <div class="db-v2-list-form-inn-tit">
              <h5>{{"Visible globally" | translate}}</h5>
            </div>
          </div>
          <div class="row">
            <div class="input-field col s12">
              <div class="switch">
                <label> {{"No" | translate}}
                  <input type="checkbox" [checked]="visibleGlobally" (change)="changeVisibleGlobally()"> <span
                    class="lever"></span> {{"Yes" | translate}} </label>
              </div>
            </div>
          </div>
          <br/>
          <br/>
          <div>
            <br/>
            <div class="db-v2-list-form-inn-tit">
              <h5>{{"Share_in_your_groups" | translate}}</h5>
            </div>
          </div>
          <div class="row">
            <div class="input-field col s12">
              <div class="select-groups">
                <ng-select [items]="groups"
                           bindLabel="name"
                           bindValue="id"
                           (change)="onChange($event)"
                           (search)="onSearch($event)"
                           [multiple]="true"
                           [clearable]="true"
                           formControlName="groups_of_experience"
                           [(ngModel)]="selectedGroupsIds">
                </ng-select>
              </div>
            </div>
            <!----------- add groups list ------------------------>
            <br/>
            <br/>
            <br/>
            <div class="input-field col s12 " *ngIf="experienceGroups && experienceGroups.length > 0">
              <div class="thumbnail-content" *ngFor="let expGroup of experienceGroups; let i = index;">
                <div class="group-item">
                  <div class=" group-item-left img-group-container inline" [ngClass]="{'img-group-container-default': !expGroup.avatar || expGroup.avatar === '' }" >
                    <img [src]="expGroup.avatar"/>
                  </div>
                  <div class="inline group-item-left group-item-info col left">
                    <p >
                      {{expGroup.name}}
                      <i *ngIf="expGroup.memberRole === RoleGroupNotMember" class="fas fa-user-slash success group-item-left"></i>
                      <i *ngIf="expGroup.memberRole === RoleGroupMember" class="fa fa-user success group-item-left"></i>
                      <i *ngIf="expGroup.memberRole === RoleGroupManager" class="fas fa-user-tie success group-item-left"></i>
                    </p>
                    <span class="groupType {{expGroup.privacy.class}}">{{expGroup.privacy.htmlValue}}</span>
                  </div>
                  <button class="group-item-left btn btn-danger right"  (click)="deleteGroup(expGroup)"
                          title="{{'DELETE_EXPERIENCE_GROUP' | translate}}"><i class="fa fa-trash"></i></button>
                </div>
              </div>
            </div>
            <!----------- End add groups list ------------------------>
            <br/>
          </div>
          <!----------- add analyticsId  ------------------------>
          <div class="row">
            <div class="db-v2-list-form-inn-tit analytics-style">
              <h5>{{"ANALYTICA_ID" | translate}}</h5>
            </div>
          </div>
          <div class="row">
            <div class="input-field col s12">
              <input type="text" id="analyticsId"
                     class="form-control" formControlName="analyticsId"
                     [ngClass]="myForm.get('analyticsId').invalid && myForm.get('analyticsId').touched ? 'invalid': 'validate'"
              />
              <label for="analyticsId"
                     [class.active]="experience && experience.id  !== ''">{{"ANALYTICA_ID" | translate}} </label>
            </div>
          </div>
          <br/>
          <br/>
          <!----------- End analyticsId ------------------------>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>


          <div class="actions-container row">
            <button type="submit" [disabled]="myForm.invalid" class="waves-effect waves-light btn-large "
                    title="{{buttonTextTitle | translate}}" (click)="onSubmit(myForm.value)">
              {{buttonText | translate}}
            </button>
            <button type="button"
                    class="waves-effect waves-light btn-large"
                    title="{{'Cancel' | translate}}"
                    (click)="resetForm()">{{'Cancel' | translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<app-confirm-dialog [confirmText]="deleteText"></app-confirm-dialog>
