<div class="tz-2 ">
  <div class="tz-2-com tz-2-main ">
    <h4>{{pageTitle | translate }}</h4>

    <div class="row waiting-get-data "  *ngIf="showMyGroup=== false || showMyExperiences === false || showMyExperiencesInGroup === false">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
    <div class="db-list-com tz-db-table" *ngIf="showMyGroup=== true && showMyExperiences === true && showMyExperiencesInGroup === true">
      <div class="hom-cre-acc-left hom-cre-acc-right">
        <div class=" card-content-height">


          <!-------------------------------- form  section -------------------------------------------->
          <form class=" card-content-height"
                [formGroup]="formAddExperiences"
                >
            <!-------------------------------- add experiences section -------------------------------------------->

            <!----------- add experiences title ------------------------>
            <div class="row" >
              <div class="db-v2-list-form-inn-tit">
                <h2>{{"Add Experiences to" | translate}} <span>{{groupName}} </span>  {{"group" | translate}}</h2>
              </div>
            </div>

            <!----------------------------if my experiences --------------------------------->
            <div *ngIf="myExperiences.length !== 0">
              <!----------- add experiences select ------------------------>
              <div class="row"  >
                <div class="input-field col s12">
                  <div class="select-members">
                    <ng-select [items]="myExperiences"
                              bindLabel="name"
                              [multiple]="true"
                              [clearable]="true"
                              formControlName="addedExperiences"
                              [(ngModel)]="addedExperiences">
                    </ng-select>
                  </div>
                </div>
              </div>
              <!----------- add experiences list ------------------------>
              <div class="row">
                <br>
                <app-experience-grid *ngFor="let addedExperience of addedExperiences "
                                     [data]="addedExperience"
                                     [disabled]="true"
                                     [remove]="true"
                                     [notSelected]="true"
                                     (onRemove)="onRemoveExperience($event)"></app-experience-grid>
                <br>
              </div>

              <!----------------------------------- Buttons section 1/2 --------------------------------------------------->
              <div class="row" *ngIf="experiencesInGroup.length === 0 ">
                <br>
                <div class="input-field col s6 v2-mar-top-40">
                  <button [disabled]="formAddExperiences.invalid" (click)="onSubmit()"  class="waves-effect waves-light btn-large full-btn submit-tbn">
                    {{'Submit' | translate}}
                  </button>
                </div>
                <div class="input-field col s6 v2-mar-top-40">
                  <button class="waves-effect waves-light btn-large full-btn skip-btn" (click)="cancel()">
                    {{'Cancel' | translate}}
                  </button>
                </div>
              </div>
              <br>
              <br>
              <br>
              <br>
            </div>

            <!----------------------------if not my experiences --------------------------------->
            <div  *ngIf="myExperiences.length === 0">
              <br>
              <br>
              <p>{{"go_to_my_experience" | translate}}</p>
              <br>
              <div class="row" >
                <a  [routerLink]="createExperience" class=" lowercase-button col-md-offset-4 col-md-4 waves-effect waves-light btn-large full-btn">{{"My Experiences" | translate}}</a>
              </div>
              <br>
              <br>
              <br>
              <br>
            </div>



            <!-------------------------------- delete experiences section -------------------------------------------->
            <!----------- delete experiences title ------------------------>
            <div class="row">
              <div class="db-v2-list-form-inn-tit">
                <h2>{{"Experiences in" | translate}} <span>{{groupName}} </span> {{"group" | translate}}</h2>
              </div>
            </div>
            <!----------- add experiences select ------------------------>
            <div *ngIf="experiencesInGroup.length === 0 ">
              <br>
              <br>
              <p>{{"no_experience_in_group" | translate}}</p>
              <br>
            </div>
            <!----------- add experiences list ------------------------>
            <div class="row" *ngIf="experiencesInGroup.length !== 0 ">
              <br>
              <br>
              <app-experience-grid *ngFor="let deletedExperience of experiencesInGroup "
                                   [data]="deletedExperience"
                                   [disabled]="true"
                                   [notSelected]="true"
                                   [delete]="!isSeletedToBeDeleted(deletedExperience)"
                                   [cancelDelete]="isSeletedToBeDeleted(deletedExperience)"
                                   [selectedData]="isSeletedToBeDeleted(deletedExperience)"
                                   (onDelete)="onDeleteExperience($event)"
                                   (onCancelDelete)="onCancelDeleteExperience($event)"
              ></app-experience-grid>
              <br>
            </div>




            <!----------------------------------- Buttons section 2/2 --------------------------------------------------->
            <div class="row" *ngIf="experiencesInGroup.length !== 0">
              <br>
              <div class="input-field col s6 v2-mar-top-40">
                <button [disabled]="formAddExperiences.invalid && deletedExperiences.length === 0" (click)="onSubmit()"   class="waves-effect waves-light btn-large full-btn submit-tbn">
                  {{'Submit' | translate}}
                </button>
              </div>
              <div class="input-field col s6 v2-mar-top-40">
                <button class="waves-effect waves-light btn-large full-btn skip-btn" (click)="cancel()">
                  {{'Cancel' | translate}}
                </button>
              </div>
            </div>
            <!-----------------------------------------  END  ------------------------------------------------>
          </form>
         </div>
      </div>
    </div>
  </div>
</div>
