<div class="tz-2-com tz-2-main">
  <div *ngIf="source!=template">
    <h4>{{'Create your first experience' | translate }}</h4>
    <a [routerLink]="url">
      <div class="center create-first-experience-content">
        <div class="icon-experience-content"><i class="fas fa-cubes"></i></div>
        <div class="center">
          <h3>{{"do_not_have_experiences_msg" | translate}}</h3>
          <br/>
          <p>{{"go_to_add_experience" | translate}}</p>
          <br/>
        </div>
        <div class="waves-effect waves-light btn-large full-btn">{{"Create" | translate}}</div>

      </div>
    </a>
  </div>
  <div *ngIf="source==template">
    <h4>{{'Create your first template' | translate }}</h4>
    <a [routerLink]="url">
      <div class="center create-first-experience-content">
        <div class="icon-experience-content"><i class="fas fa-cubes"></i></div>
        <div class="center">
          <h3>{{"do_not_have_templates_msg" | translate}}</h3>
          <br/>
          <p>{{"go_to_add_template" | translate}}</p>
          <br/>
        </div>
        <div class="waves-effect waves-light btn-large full-btn">{{"Create" | translate}}</div>

      </div>
    </a>
  </div>
</div>
