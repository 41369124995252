<div class="pglist-p3 pglist-bg pglist-p-com" *ngIf="ownReview && connectedUser">
  <div class="pglist-p-com-ti my-review-title">
    <div class="text-review">
      <span>{{"My review" | translate}}</span>
    </div>
    <div >
      <a class="icon-item" *ngIf="ownReview.status==reviewStatus[1].value">
        <i class="fa fa-ban icon-rejected" aria-hidden="true"></i>
        <span class="tooltiptext">{{'REVIEW_REJECTED' | translate}}</span>
      </a>
      <a class="icon-item" *ngIf="ownReview.status==reviewStatus[2].value">
        <i class="fa fa-recycle icon-in-moderation" aria-hidden="true"></i>
        <span class="tooltiptext">{{'REVIEW_IN_MODERATION' | translate}}</span>
      </a>
      <a class="icon-item" *ngIf="ownReview.status==reviewStatus[0].value">
        <i class="fa fa-globe icon-published" aria-hidden="true"></i>
        <span class="tooltiptext">{{'REVIEW_PUBLISHED' | translate}}</span>
      </a>
    </div>
  </div>
  <div class="list-pg-inn-sp">
    <div class="lp-ur-all-rat">
      <div class="lr-user-wr-img my-image">
        <img *ngIf="connectedUser.profilePicture && connectedUser.profilePicture !== ''"
             src="{{connectedUser.profilePicture}}" alt="">
        <div *ngIf="!connectedUser.profilePicture && connectedUser.profilePicture == ''"> </div>
      </div>
      <div class="lr-user-wr-con">
        <h6>{{connectedUser.firstName + ' ' + connectedUser.lastName}} <span>{{ownReview.rating}} <i class="fa fa-star"
                                                                                                     aria-hidden="true"></i></span>
        </h6>
        <span class="lr-revi-date">{{ownReview.createdDate | date}}</span>
        <p>{{ownReview.review}}</p>
        <ul >
          <li *ngIf="type != 'template'"><a class="cursor-default" ><span>Like</span>
            <a *ngFor="let et of etatLike| keyvalue" class="cursor-default" >
              <i *ngIf="et.key == ownReview.id && et.value==true"   class="fa fa-thumbs-up" aria-hidden="true"></i>
              <i *ngIf="et.key == ownReview.id && et.value==false"  class="fa fa-thumbs-o-up" aria-hidden="true"></i>
            </a>
          </a></li>
          <li><a *ngFor="let item of likes | keyvalue" class="cursor-default" ><span *ngIf="item.key == ownReview.id">{{item.value}}</span></a></li>

          <li *ngIf="type != 'template'"><a class="cursor-default" ><span>Dislike</span>
            <a *ngFor="let et of etatDislike| keyvalue" class="cursor-default">
              <i *ngIf="et.key == ownReview.id && et.value==true"   class="fa fa-thumbs-down" aria-hidden="true"></i>
              <i *ngIf="et.key == ownReview.id && et.value==false"  class="fa fa-thumbs-o-down" aria-hidden="true"></i>
            </a>
          </a></li>
          <li *ngIf="type != 'template'"><a *ngFor="let items of dislikes | keyvalue" class="cursor-default" ><span *ngIf="items.key == ownReview.id">{{items.value}}</span></a></li>


          <li><a (click)="editReview()"><i class="fa fa-edit" aria-hidden="true"></i></a></li>
          <li><a (click)="deleteReview()"><i class="fa fa-trash" aria-hidden="true"></i></a></li>
        </ul>
      </div>

    </div>
  </div>
</div>
