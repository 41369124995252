import {Component, OnInit} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {UsersService} from '../../reusables/services/users.service';
import {ExperiencesService} from '../../reusables/services/experiences.service';
import {GroupsService} from '../../reusables/services/groups.service';
import {Experience} from '../../reusables/interfaces/experience';
import {Group} from '../../reusables/interfaces/group';
import {User} from '../../reusables/interfaces/user';
import {LIMIT_FILTER, OWNER_EXPERIENCE} from '../../utils/queryParams/experiencesQueryParams';
import {DASHBOARD_PAGE_TITLE,} from '../../utils/actionsLabels/labels';
import {CREATOR_GROUP, ROLE_GROUP} from '../../utils/queryParams/groupsQueryParams';
import {TokenHelperService} from '../../reusables/services/token-helper.service';
import {NotificationService} from '../../reusables/services/notification.service';
import {RoleGroup} from '../../utils/global-constants/groups-const';
import {AuthGuard} from '../../reusables/_helpers';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  user: User;
  experiences: Experience [];
  groups: Group [];
  doneForUserProfile = false;
  doneForUserExperiences = false;
  doneForUserGroups = false;

  constructor(private router: Router,
              private titleService: Title,
              private usersService: UsersService,
              private groupsService: GroupsService,
              private experiencesService: ExperiencesService,
              private tokenHelperService: TokenHelperService,
              private notificationService: NotificationService,
              private authGuard: AuthGuard) {
  }

  ngOnInit(): void {
    this.titleService.setTitle(DASHBOARD_PAGE_TITLE);
    if (this.authGuard.canActivate) {
      this.getProfile();
    }
  }

  getProfile() {
    this.usersService.getUserProfile().subscribe((data: User) => {
      this.user = data;
      this.getUserExperiences(this.user.id);
      this.getUserRecentGroups(this.user.id);
      this.doneForUserProfile = true;
    });
  }

  getUserExperiences(id) {
    let params = new HttpParams();
    params = params.append(OWNER_EXPERIENCE, id);
    params = params.append(LIMIT_FILTER, '3');
    this.experiencesService.getExperiences(params).subscribe((data: Experience[]) => {
      this.experiences = data;
      this.doneForUserExperiences = true;
    });
  }

  getUserRecentGroups(id) {
    let params = new HttpParams();
    params = params.append(CREATOR_GROUP, id);
    params = params.append(ROLE_GROUP, RoleGroup.MANAGER);
    params = params.append(LIMIT_FILTER, '3');
    this.groupsService.getGroups(params).subscribe((data: Group[]) => {
      this.groups = data;
      this.doneForUserGroups = true;
    });
  }

}
