import {environment} from "../../../environments/environment";

export const DEFAULT_IMAGE_URL = environment.DEFAULT_IMAGE_URL;
export const DEFAULT_AUDIO_URL = environment.DEFAULT_AUDIO_URL;
export const DEFAULT_OBJ_3D_URL = environment.DEFAULT_OBJ_3D_URL;
export const DEFAULT_OBJ_3D_ZIP_URL = environment.DEFAULT_OBJ_3D_ZIP_URL;
export const DEFAULT_OBJ_3D_GEO_POSITION_URL = environment.DEFAULT_OBJ_3D_GEO_POSITION_URL;
export const DEFAULT_FILE_NAME = '######';

/******************* enum ***********************/
import {
  DELETE_FILES,
  FORM_MY_COMPUTER,
  GLOBAL_LIBRARY,
  INVALID_FILE_EXTENSION_AUDIO,
  INVALID_FILE_EXTENSION_IMAGE,
  INVALID_FILE_EXTENSION_OBJECT_3D,
  INVALID_FILE_SIZE,
  MAX_FILE_SIZE,
  MY_ASSETS_LIBRARY,
  NO_GLOBAL_ASSET_AUDIO,
  NO_GLOBAL_ASSET_IMAGE,
  NO_GLOBAL_ASSET_OBJECT_3D,
  OR_SELECT_ANOTHER_AUDIO,
  OR_SELECT_ANOTHER_IMAGE,
  OR_SELECT_ANOTHER_OBJECT_3D,
  PLEASE_IMPORT_FROM_COMPUTER_AUDIO,
  PLEASE_IMPORT_FROM_COMPUTER_IMAGE,
  PLEASE_IMPORT_FROM_COMPUTER_OBJECT_3D,
  SUPPORTED_EXTENSIONS,
  YOU_DO_NOT_HAVE_ENOUGH_SPACE_AUDIO,
  YOU_DO_NOT_HAVE_ENOUGH_SPACE_IMAGE,
  YOU_DO_NOT_HAVE_ENOUGH_SPACE_MARKER,
  YOU_DO_NOT_HAVE_ENOUGH_SPACE_OBJECT_3D,
  YOU_DO_NOT_HAVE_SPACE_AUDIO,
  YOU_DO_NOT_HAVE_SPACE_IMAGE,
  YOU_DO_NOT_HAVE_SPACE_MARKER,
  YOU_DO_NOT_HAVE_SPACE_OBJECT_3D,
  YOU_HAVE_NOT_CREATE_ASSET_AUDIO,
  YOU_HAVE_NOT_CREATE_ASSET_IMAGE,
  YOU_HAVE_NOT_CREATE_ASSET_OBJECT_3D,
  YOUR_ASSETS_LIBRARY
} from '../actionsLabels/editorLabels';
import {
  AssetType,
  extensionAudioList,
  extensionImageList,
  extensionObject3DList,
  inputAcceptExtensionAudio,
  inputAcceptExtensionImage,
  inputAcceptExtensionObject3D,
  messageExtensionAudio,
  messageExtensionImage,
  messageExtensionObject3D,
  messageMaxFileSize
} from './assets-library-const';


export enum GizmoTypes {
  POSITION = 'Position',
  ROTATION = 'Rotation',
  SCALE = 'Scale',
  SIZE = 'Size',
}

export enum EditorElementType {
  image = 'image',
  object3d = 'object3d',
  audio = 'audio',
  marker = 'marker',
  surface = 'surface',
  geo = 'geoPosition'
}


/******************* Const ***********************/

export const IMPORT_ASSET_DIALOG_WIDTH = '800px';
export const IMPORT_ASSET_DIALOG_HEIGHT = '600px';
export const IMPORT_ASSET_DIALOG_TAB_MENU = [
  {label: MY_ASSETS_LIBRARY, id: 'menu0', checked: true, value: MY_ASSETS_LIBRARY},
  {label: GLOBAL_LIBRARY, id: 'menu1', checked: false, value: GLOBAL_LIBRARY},
  {label: FORM_MY_COMPUTER, id: 'menu2', checked: false, value: FORM_MY_COMPUTER},
];
// Popup Import component: type Image
export const IMPORT_IMAGE_ASSET_DIALOG = { assetType: AssetType.IMAGE,
    messages: {
      youHaveNoAsset: YOU_HAVE_NOT_CREATE_ASSET_IMAGE,
      noGlobalAsset: NO_GLOBAL_ASSET_IMAGE,
      pleaseImportFromComputer: PLEASE_IMPORT_FROM_COMPUTER_IMAGE,
      noSpace: YOU_DO_NOT_HAVE_SPACE_IMAGE,
      noEnoughSpace: YOU_DO_NOT_HAVE_ENOUGH_SPACE_IMAGE,
      invalidFileExtension: INVALID_FILE_EXTENSION_IMAGE,
      orSelectAnotherFile: OR_SELECT_ANOTHER_IMAGE,
      supportedExtensionsValue: messageExtensionImage,

      deleteFiles: DELETE_FILES,
      yourAssetsLibrary: YOUR_ASSETS_LIBRARY,
      supportedExtensions: SUPPORTED_EXTENSIONS,
      maxFileSize: MAX_FILE_SIZE,
      maxFileSizeValue: messageMaxFileSize,
      invalidFileSize: INVALID_FILE_SIZE,

    },
    input: {
      id: 'imageAssetObjectFile',
      accept : inputAcceptExtensionImage,
      extensionList: extensionImageList
    }
  };

// Popup Import component: type Object 3D
export const IMPORT_OBJECT_3D_ASSET_DIALOG = { assetType: AssetType.OBJ_3D,
    messages: {
      youHaveNoAsset: YOU_HAVE_NOT_CREATE_ASSET_OBJECT_3D,
      noGlobalAsset: NO_GLOBAL_ASSET_OBJECT_3D,
      pleaseImportFromComputer: PLEASE_IMPORT_FROM_COMPUTER_OBJECT_3D,
      noSpace: YOU_DO_NOT_HAVE_SPACE_OBJECT_3D,
      noEnoughSpace: YOU_DO_NOT_HAVE_ENOUGH_SPACE_OBJECT_3D,
      invalidFileExtension: INVALID_FILE_EXTENSION_OBJECT_3D,
      orSelectAnotherFile: OR_SELECT_ANOTHER_OBJECT_3D,

      deleteFiles: DELETE_FILES,
      yourAssetsLibrary: YOUR_ASSETS_LIBRARY,
      supportedExtensions: messageExtensionObject3D,
      supportedExtensionsValue: '',
      maxFileSize: MAX_FILE_SIZE,
      maxFileSizeValue: messageMaxFileSize,
     invalidFileSize: INVALID_FILE_SIZE,

    },
    input: {
      id: 'object3DAssetObjectFile',
      accept : inputAcceptExtensionObject3D,
      extensionList: extensionObject3DList
    }
  };

// Popup Import component: type Audio
export const IMPORT_AUDIO_ASSET_DIALOG = { assetType: AssetType.AUDIO,
  messages: {
    youHaveNoAsset: YOU_HAVE_NOT_CREATE_ASSET_AUDIO,
    noGlobalAsset: NO_GLOBAL_ASSET_AUDIO,
    pleaseImportFromComputer: PLEASE_IMPORT_FROM_COMPUTER_AUDIO,
    noSpace: YOU_DO_NOT_HAVE_SPACE_AUDIO,
    noEnoughSpace: YOU_DO_NOT_HAVE_ENOUGH_SPACE_AUDIO,
    invalidFileExtension: INVALID_FILE_EXTENSION_AUDIO,
    orSelectAnotherFile: OR_SELECT_ANOTHER_AUDIO,
    supportedExtensionsValue: messageExtensionAudio,

    deleteFiles: DELETE_FILES,
    yourAssetsLibrary: YOUR_ASSETS_LIBRARY,
    supportedExtensions: SUPPORTED_EXTENSIONS,
    maxFileSize: MAX_FILE_SIZE,
    maxFileSizeValue: messageMaxFileSize,
    invalidFileSize: INVALID_FILE_SIZE,

  },
  input: {
    id: 'audioAssetObjectFile',
    accept : inputAcceptExtensionAudio,
    extensionList: extensionAudioList
  }
};

// Popup Import component: type Image
export const IMPORT_MARKER_DIALOG = { assetType: AssetType.IMAGE,
  messages: {
    youHaveNoAsset: YOU_HAVE_NOT_CREATE_ASSET_IMAGE,
    noGlobalAsset: NO_GLOBAL_ASSET_IMAGE,
    pleaseImportFromComputer: PLEASE_IMPORT_FROM_COMPUTER_IMAGE,
    noSpace: YOU_DO_NOT_HAVE_SPACE_MARKER,
    noEnoughSpace: YOU_DO_NOT_HAVE_ENOUGH_SPACE_MARKER,
    invalidFileExtension: INVALID_FILE_EXTENSION_IMAGE,
    orSelectAnotherFile: OR_SELECT_ANOTHER_IMAGE,
    supportedExtensionsValue: messageExtensionImage,

    deleteFiles: DELETE_FILES,
    yourAssetsLibrary: YOUR_ASSETS_LIBRARY,
    supportedExtensions: SUPPORTED_EXTENSIONS,
    maxFileSize: MAX_FILE_SIZE,
    maxFileSizeValue: messageMaxFileSize,
    invalidFileSize: INVALID_FILE_SIZE,

  },
  input: {
    id: 'imageAssetObjectFile',
    accept : inputAcceptExtensionImage,
    extensionList: extensionImageList
  }
};
