import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  CREATE_GROUP_BUTTON,
  CREATE_GROUP_BUTTON_TITLE,
  CREATE_GROUP_PAGE_TITLE,
  EDIT_GROUP_BUTTON,
  EDIT_GROUP_BUTTON_TITLE,
  EDIT_GROUP_EVENT,
  EDIT_GROUP_PAGE_TITLE,
  NEW_GROUP_EVENT, PAGE_TITLE_PREFIX_EDIT_MY_GROUP
} from '../../../utils/actionsLabels/labels';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as $ from 'jquery';
import {ActivatedRoute, Router} from '@angular/router';
import {GroupTypes, groupTypes} from '../../../utils/global-constants/groups-const';
import {Group} from '../../interfaces/group';
import {GroupsService} from '../../services/groups.service';
import {TokenHelperService} from '../../services/token-helper.service';
import {Title} from '@angular/platform-browser';
import {groupTypeOrder} from '../../../utils/global-constants/groups-const';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-add-edit-group-form',
  templateUrl: './add-edit-group-form.component.html',
  styleUrls: ['./add-edit-group-form.component.scss',
    '../../../../assets/customCss/waiting-load.scss',
    '../../../../assets/customCss/customTextarea.css'
  ]
})
export class AddEditGroupFormComponent implements OnInit {

  myForm: FormGroup;
  @Output() request = new EventEmitter();

  // ********************** image-Cropper icon and cover ***********************//
  coverFileName = ''; // used to show image file name or previous display name
  iconFileName = '';  // used to show image file name or previous display name
  coverPictureDisplayName = '';
  iconPictureDisplayName = '';
  coverUrl = ''; // used to show image
  iconUrl = ''; // used to show image
  iconCroppedImage: any; // base64 : user in <img> in attribut src
  coverCroppedImage: any; // base64 : user in <img> in attribut src
  fileCoverPicture: File; // big image
  fileAvatar: File;  // big image
  requestFileCoverPicture: File; // file sent on request
  requestFileIconPicture: File; // file sent on request

  // ***************************************************************************** //

  mediaScreenChanged = false;
  activeRadioLabel = 'active';
  notActiveRadioLabel = 'not-active';
  privacy = groupTypes;

  // dépend de l'url => edit or add
  pageTitle;
  buttonText;
  buttonTextTitle;
  submitAction;
  groupTypeOrders = groupTypeOrder;
  @Input() groupId: string;
  group: Group;


  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private groupsService: GroupsService,
              private tokenHelperService: TokenHelperService,
              private titleService: Title,
              private translate: TranslateService,
  ) {
    this.myForm = this.formBuilder.group({
      coverPicture: [''],
      iconPicture: [''],
      name: ['', [Validators.required]],
      description: [''],
      privacy: [GroupTypes.PUBLIC],
    });
  }

  ngOnInit(): void {
    if (window.innerWidth <= 1200) {
      this.mediaScreenChanged = true;
    }
    if (!this.groupId) {
      this.buttonText = CREATE_GROUP_BUTTON;
      this.buttonTextTitle = CREATE_GROUP_BUTTON_TITLE;
      this.submitAction = NEW_GROUP_EVENT;
      this.pageTitle = CREATE_GROUP_PAGE_TITLE;
    } else {
      // this.titleService.setTitle(PAGE_TITLE_PREFIX_EDIT_MY_GROUP);
      this.translate.get(PAGE_TITLE_PREFIX_EDIT_MY_GROUP).subscribe((res: string) => {
        this.titleService.setTitle(res);
      });
      this.pageTitle = EDIT_GROUP_PAGE_TITLE;
      this.buttonText = EDIT_GROUP_BUTTON;
      this.buttonTextTitle = EDIT_GROUP_BUTTON_TITLE;
      this.groupsService.getGroupById(this.groupId).subscribe((data: Group) => {
        this.group = data;
        // set form data
        this.myForm.get('name').setValue(data.name);
        this.myForm.get('description').setValue(data.description);
        this.myForm.get('privacy').setValue(data.type);
        // to save pravious url
        this.myForm.get('coverPicture').setValue(data.coverPicture);
        this.myForm.get('iconPicture').setValue(data.avatar);
        // to show file name
        this.coverFileName = data.coverPictureDisplayName;
        this.iconFileName = data.avatarDisplayName;
        // to save new display name
        this.coverPictureDisplayName = data.coverPictureDisplayName;
        this.iconPictureDisplayName = data.avatarDisplayName;
        // to show image
        this.coverUrl = data.coverPicture;
        this.iconUrl = data.avatar;
        this.submitAction = EDIT_GROUP_EVENT;
        // this.titleService.setTitle(PAGE_TITLE_PREFIX_EDIT_MY_GROUP + data.name);
        this.translate.get(PAGE_TITLE_PREFIX_EDIT_MY_GROUP).subscribe((res: string) => {
          this.titleService.setTitle(res + data.name);
        });
      });

    }

  }

  onSubmit(data) {
    const dataRequest = {
      coverPicture: data.coverPicture,
      iconPicture: data.iconPicture,
      coverPictureDisplayName: this.coverPictureDisplayName,
      iconPictureDisplayName: this.iconPictureDisplayName,
      name: data.name,
      description: data.description,
      privacy: data.privacy,
    };
    this.request.emit({
      action: this.submitAction,
      data: dataRequest,
      coverPicture: this.requestFileCoverPicture,
      avatar: this.requestFileIconPicture
    });
  }


  // ******************************* COVER PHOTO START **************************************** //
  addCoverPhoto() {
    $('#coverPicture').click();
  }

  onCoverProfileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileCoverPicture = (event.target as HTMLInputElement).files[0];
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (eventFle) => { // called once readAsDataURL is completed
        // this.coverUrl = eventFle.target.result.toString();
        this.coverUrl = null;
        this.coverFileName = file.name;
      };
    }
  }

  deleteProfileCover() {
    this.coverUrl = '';
    this.coverFileName = '';
    this.coverPictureDisplayName = '';
    this.requestFileCoverPicture = null;
    this.coverCroppedImage = null;
    this.myForm.get('coverPicture').setValue('');
  }

  changeCoverPicture(event) {
    this.coverPictureDisplayName = event.target.value;
  }

  coverImageCropped(event: ImageCroppedEvent) {
    this.coverCroppedImage = event.base64;
    fetch(this.coverCroppedImage)
      .then(res => res.blob()).then(bl => {
      this.requestFileCoverPicture = new File([bl], this.fileCoverPicture.name);
    });
  }

  // ******************************* COVER PHOTO END **************************************** //


  // ******************************* ICON PHOTO START **************************************** //

  addIconPhoto() {
    $('#iconPicture').click();
  }

  onIconProfileChange(event) {
    if (event.target.files.length > 0) {
      this.fileAvatar = (event.target as HTMLInputElement).files[0];
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (eventFle) => { // called once readAsDataURL is completed
        this.iconUrl = null;
        this.iconFileName = file.name;
      };
    }
  }

  deleteIconPicture() {
    this.iconUrl = '';
    this.iconFileName = '';
    this.iconPictureDisplayName = '';
    this.requestFileIconPicture = null;
    this.iconCroppedImage = null;
    this.myForm.get('iconPicture').setValue('');
  }

  changeIconPicture(event) {
    this.iconPictureDisplayName = event.target.value;
  }

  iconImageCropped(event: ImageCroppedEvent) {
    this.iconCroppedImage = event.base64;
    fetch(this.iconCroppedImage)
      .then(res => res.blob()).then(bl => {
      this.requestFileIconPicture = new File([bl], this.fileAvatar.name);
    });
  }

  // ******************************* ICON PHOTO END **************************************** //


  changePrivacy(event) {
    if (event.target.value === GroupTypes.PUBLIC) {
      this.activeRadioLabel = 'active';
    } else {
      this.activeRadioLabel = '';
    }
  }

  resetForm() {
    this.coverUrl = '';
    this.coverFileName = '';
    this.iconFileName = '';
    this.myForm.reset();
    window.history.back();
  }

  // On some Android devices, input name allows you to write more than the maximum length
  // Force maxlength input on web mobile
  substrName(){
    if (this.myForm.get('name').value.length > 25){
      this.myForm.get('name').setValue(this.myForm.get('name').value.substring(0, 25));
    }
  }
}
