<div class="tz-2-com tz-2-main">
  <div class="center create-first-experience-content">
    <div class="icon-experience-content"><i class="fa fa-sign-in"></i></div>
    <div class="center">
      <h3>{{"login and join" | translate}}</h3>
      <br/>
      <p>{{"DONT_HAVE_ACCOUNT" | translate}}
        <a class="a-popup" [routerLink]="signUpLink" >{{"CREATE_ACCOUNT" | translate}}</a>
        {{"MINUTE" | translate}} </p>
      <br/>
    </div>

  </div>

</div>
<div class="row actions-popup ">
  <button class=" button  waves-effect waves-light btn-large save-popup-style"
          (click)="onLogin()" >{{'Login' | translate}}</button>
  <button class="  button waves-effect waves-light btn-large"
          (click)="cancel();">{{'Cancel' | translate}}</button>
</div>
