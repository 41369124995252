<div class="tz-2-com tz-2-main">
  <h4>{{pageTitle| translate }}</h4>
  <div class="db-list-com tz-db-table">
     <div class="ds-boar-title in_desktop">
       <h2 class="section-title">{{"All templates" | translate}}</h2>
       <div class="ds-boar-title">
         <div class="button-translate">
           <button type="submit" class="waves-effect waves-light btn-large create-button ">
             <a [routerLink]="createTempPath" class="show_in_desktop right create-temp ">
               {{'LABEL_CREATE_NEW_TEMPLATE' | translate}}
             </a>
           </button>
         </div>
       </div>
<!--            <a [routerLink]="createTempPath" class="show_in_desktop right" >-->
<!--              <button type="submit" class="waves-effect waves-light btn-large create-button">-->
<!--                     {{'LABEL_CREATE_NEW_TEMPLATE' | translate}}-->
<!--              </button>-->
<!--            </a>-->
     </div>
    <br>
     <table class="responsive-table bordered">
        <thead>
           <tr>
              <th *ngFor="let item of tabHeaders">{{item| translate}}</th>
           </tr>
        </thead>
        <tbody>
           <tr *ngFor="let item of templates">
              <td>{{item.name}}</td>
              <td>{{item.createdDate | date}}</td>
              <td>{{item.updatedDate | date}}</td>
             <td class="padding-used">{{item.number_uses}}</td>
              <td>
                <span class="db-list-rat" *ngIf="item.rating != 0">{{item.rating}}</span>
                <span class="db-list-rat" *ngIf="item.rating == 0">-</span>
              </td>
              <td>
                <span class="bd-list-custom-status"
                      [ngClass]="statusClassTab[0].class"
                      *ngIf="item.status == statusPublished">{{item.status | translate}}</span>
                <span class="bd-list-custom-status"
                      [ngClass]="statusClassTab[1].class"
                      *ngIf="item.status == statusUnpublished">{{item.status | translate}}</span>
                <span class="bd-list-custom-status"
                      [ngClass]="statusClassTab[2].class"
                      *ngIf="item.status == statusDraft">{{item.status | translate}}</span>
                <span class="bd-list-custom-status"
                      [ngClass]="statusClassTab[4].class"
                      *ngIf="item.status == statusInModeration">{{item.status | translate}}</span>
              </td>

              <td>
                <a [routerLink]="['/templates/' + item.id]">
                  <span class="tooltiptext">{{'PREVIEW' | translate}}</span>
                  <span class="db-list-edit">
                    <i class="fa fa-eye"></i>
                  </span>
                </a>
              </td>
              <td>
                <a [routerLink]="['/my-templates/' + item.id + '/edit']">
                  <span class="tooltiptext">{{'EDIT' | translate}}</span>
                  <span class="db-list-edit">
                  <i class="fa fa-edit"></i>
                </span>
                </a>
              </td>
<!--              <td>-->
<!--                <a (click)="comingSoonAction()">-->
<!--                  &lt;!&ndash; <a [routerLink]="['/my-templates/' + item.id + '/analytics']">&ndash;&gt;-->
<!--                  <span class="tooltiptext">{{'ANALYTICS' | translate}}</span>-->
<!--                  <span class="db-list-edit">-->
<!--                    <i class="fa fa-bar-chart"></i>-->
<!--                  </span>-->
<!--                </a>-->
<!--              </td>-->
             <td>
                <a (click)="openUseTemplate(item.content)">
                  <span class="tooltiptext">{{'USE_TEMPLATE' | translate}}</span>
                  <span class="db-list-edit">
                    <i class="far fa-clone"></i>
                  </span>
                </a>
              </td>
              <td>
                <a (click)="deleteTemplate(item.id, item.name)" [class.isDisabled]="disabledButton">
                  <span class="tooltiptext">{{'DELETE' | translate}}</span>
                  <span class="db-list-edit">
                    <i class="fa fa-trash"></i>
                  </span>
                </a>
              </td>
            </tr>
        </tbody>
     </table>
  </div>
</div>
