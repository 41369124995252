<app-main-menu-connected [isEditor]="true" [Name]="Name" ></app-main-menu-connected>
<app-first-menu (publishEvent)="onPublish()"
                (addNew)="addNew($event)"
                (addMarker)="addNewMarkerScene($event)"
                (addGeoPosition)="addNewGeoPositionScene()"
                (selectedElementFirstMenuEvent)="slectedElementfirstMenu = $event"
                [selectedElementFirstMenuEditor]="slectedElementfirstMenu"
                [selectedTrigger]="selectedTrigger"
                [publish]="publish"
                [videoValid]="videoValid"
                [urlsValid]="urlsValid"
                [triggerType]="triggerType"
></app-first-menu>
<app-second-menu
  [userId]="userId"
  [connectedUserIdCookies]="connectedUserIdCookies"
  [scene]="scene"
  [mesh]="selectedMesh"
  [object]="selectedObj"
  [showedMenu]="secondMenu"
  [showGizmo]="showGizmo"
  [LeftPosition]="secondMenuPositionStyle"
  [marker]="selectedTrigger"
  (changeMesh)="selectedMesh = $event"
  (deleteEvent)="onDelete($event)"
  (cloneEvent)="onClone($event)"
  (closeEvent)="onClose()"
  (enablePublishEvent)="canPublish()"
  (videoUriValid)="videoUriValid($event)"
  (urlValid)="urlValid($event)"
  (activeAudio)="activeAudio()"
  (upload)="upload = $event"
></app-second-menu>
<div class="principal-scene" [style]="principalSceneWidth">
  <canvas class="scene"  id="principalCanvas"></canvas>
</div>
<app-right-menu
  [triggers]="objInScene"
  [focusComponent]="selectedObj"
  [focusTrigger]="selectedTrigger"
  (selectedComponent)="onChangeComponent($event)"
  (selectedTrigger)="onTriggerChange($event)"
  (deleteEvent)="onDelete($event)"
  (zoomEvent)="zoomAfterAdd($event)"
  (showTriggerEvent)="onShowTrigger($event)"
  (showComponentEvent)="onShowComponent($event)"
  (hideTriggerEvent)="onHideTrigger($event)"
  (hideComponentEvent)="onHideComponent($event)"
  (dropComponentEvent)="onDropComponent($event)"
  (initializeMenu)="initializeMenu()"
></app-right-menu>

<!--
<button id="save-before-create-new" (click)="onNavbarCreate()" hidden></button>
-->

<div class="row loading" *ngIf="upload === true">
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
</div>

