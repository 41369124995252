<div class="tz-2-com tz-2-main">
    <div class="center create-first-experience-content">
      <div class="icon-experience-content"><i class="fas fa-cubes"></i></div>
      <div class="center">
        <h3>{{"do_not_have_public_experiences_msg" | translate}}</h3>
        <br/>
        <p>{{"go_to_my_experience" | translate}}</p>
        <br/>
      </div>

    </div>

</div>
<div class="row actions-popup ">
  <button class=" button  waves-effect waves-light btn-large save-popup-style"
          (click)="onCreate()" >{{'My Experiences' | translate}}</button>
  <button class="  button waves-effect waves-light btn-large"
          (click)="cancel();">{{'Cancel' | translate}}</button>
</div>
