import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseURLLibrary} from '../../utils/global-constants/baseUrl';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {

  assetsLibraries = 'assets-libraries';
  assetObjects = 'asset-objects';




  constructor(private httpClient: HttpClient) {
  }


  /**************************** ASSETS LIBRARY ****************************/
  getAssetsLibraryByCreatorId(parameters) {
    return this.httpClient.get(BaseURLLibrary + this.assetsLibraries,
      {params: parameters});
  }


  /**************************** ASSET OBJECT ****************************/

  getAssets(parameters) {
    return this.httpClient.get(BaseURLLibrary + this.assetObjects,
      {params: parameters});
  }
  deleteAsset(libraryId , assetId ) {
    return this.httpClient.delete(BaseURLLibrary + this.assetsLibraries + '/' + libraryId + '/' + this.assetObjects + '/' + assetId,
      {responseType: 'text'});
  }

  addAsset(parameters) {
    return this.httpClient.post( BaseURLLibrary + this.assetObjects, parameters, {
      reportProgress: true,
      observe: 'events'
    });
  }


  /**************************** GLOBAL ASSET OBJECT ****************************/

}
