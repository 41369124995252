import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CustomValidators} from '../signup/custom-validators';
import {specialCharacters} from '../../utils/global-constants/regExp';
import {ConfirmedValidator} from '../../reusables/directives/confirmed.validator';
import {CANCEL_LABEL, COMING_SOON, SAVE_LABEL} from '../../utils/actionsLabels/labels';
import {LOGIN_PATH, SIGNUP_PATH} from '../../utils/actionsLabels/pathLabels';
import {AngularFireAuth} from '@angular/fire/auth';
import {NotificationService} from '../../reusables/services/notification.service';
import {FirebaseErrors} from '../../reusables/_helpers/firebaseErrors';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm;
  formIsNotValid: false;
  saveButtonLabel = SAVE_LABEL;
  cancelButtonLabel = CANCEL_LABEL;

  loginLink = LOGIN_PATH;
  signUpLink = SIGNUP_PATH;

  constructor(private formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private auth: AngularFireAuth,
              private notificationService: NotificationService,
              private firebaseErrors: FirebaseErrors,
              private router: Router) {
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', Validators.compose([
        Validators.required,
        CustomValidators.patternValidator(/\d/, {hasNumber: true}),
        CustomValidators.patternValidator(/[A-Z]/, {hasCapitalCase: true}),
        CustomValidators.patternValidator(/[a-z]/, {hasSmallCase: true}),
        CustomValidators.patternValidator(specialCharacters, {hasSpecialCharacters: true}),
        Validators.minLength(8),
      ])],
      passwordConfirm: ['', Validators.required]
    }, {
      validator: ConfirmedValidator('password', 'passwordConfirm')
    });
  }

  mode: string;
  actionCode: string;

  ngOnInit(): void {
    this.activatedRoute.paramMap.pipe(
      map(params => {
          if (!params) {
            this.router.navigate(['/']);
          }

          this.mode = params['mode'];
          this.actionCode = params['oobCode'];

          if (this.mode === 'resetPassword') {
            this.auth.verifyPasswordResetCode(this.actionCode).then(email => {
            }).catch(e => {
              // Invalid or expired action code. Ask user to try to reset the password
              // again.
              alert(e);
              this.router.navigate(['/auth/login']);
            });
          }


        }
      )
    ).subscribe(() => {
    });


    // this.sentToken = this.activatedRoute.snapshot.queryParams['oobCode'];
    // this.auth.applyActionCode(this.sentToken).then(() => {
    //   // do something after successful verification
    // }).catch(() => {
    //   // show error message
    //   this.notificationService.showError(ERROR_POPUP_MESSAGE, '');
    //   this.router.navigateByUrl(LOGIN_PATH).then();
    // });


    // this.auth.verifyPasswordResetCode(this.sentToken).then(email => {
    //   // do something after successful verification
    //   console.log('email ' + email);
    // }).catch(() => {
    //   this.notificationService.showError(ERROR_POPUP_MESSAGE, '');
    //   this.router.navigateByUrl(LOGIN_PATH).then();
    // });


  }

  onSubmitForm(data) {
    this.auth.confirmPasswordReset(this.actionCode, data.password).then(response => {
      this.router.navigateByUrl(LOGIN_PATH).then();
    }).catch(error => {
      const errorMessage = this.firebaseErrors.Parse(error.code);
    });
  }

  resetForm() {
    this.resetPasswordForm.reset();
    this.formIsNotValid = false;
  }

  showMessage() {
    this.notificationService.showWarning(COMING_SOON, '');
  }
}

