<div class="tz-2-com tz-2-main" xmlns="http://www.w3.org/1999/html">
  <h4>{{'Edit profile' | translate }}</h4>
  <div class="db-list-com tz-db-table">
    <form class="s12 ng-pristine ng-valid" [formGroup]="editProfileForm"
          (ngSubmit)="onSubmit(editProfileForm.value)">
      <div class="form-group">

        <!----------------------------- PROFOLE - IMAGE - START ---------------------------------------->
        <label class="active" >{{'Profile Image' | translate}} :</label>
        <a class="identicon-image-icon" (click)="inputCroppedImage.click()">
          <i class="fas fa-pen ">
            <span class="tooltiptext" *ngIf="!isNotDesktop">{{'Edit Image' | translate}}</span>
          </i>
        </a>
        <br>
        <img class="coppred-image" [src]="croppedImage" *ngIf="fileCroppedImage" alt=""/>
        <img class="coppred-image" [src]="profileImageURL" *ngIf="profileImageURL && profileImageURL !== ''" alt=""/>
        <image-cropper *ngIf="fileCroppedImage"
                       class="image-cropper"
                       [imageFile]="fileCroppedImage"
                       [maintainAspectRatio]="true"
                       [containWithinAspectRatio]="false"
                       [aspectRatio]="1"
                       [cropperMinWidth]="100"
                       [onlyScaleDown]="true"
                       [roundCropper]="false"
                       [canvasRotation]="0"
                       [alignImage]="'center'"
                       format="png"
                       (imageCropped)="imageCropped($event)"
        ></image-cropper>



        <!----------------------------- PROFOLE - IMAGE - END ---------------------------------------->

        <div class="manage-profile-content">


          <div class="info-item">
            <div class="info-label">{{'First Name' | translate }} :</div>
            <div class="info-content">
              <div class="input-field s12">
                <input type="text" class="validate" formControlName="firstName" id="firstName">
                <label for="firstName" class="active">{{'First Name' | translate }}</label>
              </div>
            </div>
          </div>

          <div class="info-item">
            <br>
            <div class="info-label">{{'Last Name' | translate }} :</div>
            <div class="info-content">
              <div class="input-field s12">
                <input type="text" class="validate" formControlName="lastName" id="lastName">
                <label for="lastName" class="active">{{'Last Name' | translate }}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="info-item">
          <br>
          <div class="info-label">{{'Display Name' | translate }} :</div>
          <div class="info-content">
            <div class="input-field s12">
              <input type="text" class="validate" formControlName="displayName" id="displayName">
              <label for="displayName" class="active">{{'Display Name' | translate }}</label>
            </div>
          </div>
        </div>


      </div>

      <div class="actions-container">
        <div class="input-field s4" [ngClass]="{'disabledSubmit': editProfileForm.invalid && editProfileForm.touched || submitted}">
          <input type="submit" [disabled]="editProfileForm.invalid && editProfileForm.touched || submitted" value="Save"
                 class="waves-effect waves-light btn-large">
        </div>
        <div class="input-field s4">
          <input type="button" value="Cancel" class="waves-effect waves-light btn-large" [disabled]="submitted" (click)="resetForm()">
        </div>
      </div>


    </form>
  </div>
</div>

<input  type="file" class="input-file" [hidden]="true" #inputCroppedImage
       accept="image/png, image/jpeg, image/jpg"
       (change)="onCroppedImageChange($event)" [(ngModel)]="fileCroppedImage">

