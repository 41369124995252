import {Component, Input, OnInit} from '@angular/core';
import {Template} from '../../interfaces/templates';
import {TEMPLATES_PATH} from '../../../utils/actionsLabels/pathLabels';
import {MARKER, MARKER_TEXT_ON_HOVER, SURFACE, SURFACE_TEXT_ON_HOVER} from '../../../utils/actionsLabels/labels';

@Component({
  selector: 'app-listing-template-item',
  templateUrl: './listing-template-item.component.html',
  styleUrls: ['./listing-template-item.component.scss', '../../../../assets/customCss/listingItem.css']
})
export class ListingTemplateItemComponent implements OnInit {

  @Input() data: Template;
  @Input() homeSection;
  customStyle = '';
  linkDetails = '';
  marker = MARKER;
  surface = SURFACE;
  markerText = MARKER_TEXT_ON_HOVER;
  surfaceText = SURFACE_TEXT_ON_HOVER;
  constructor() {
  }

  ngOnInit(): void {
    if (this.data.cover_picture !== '') {
      this.customStyle = 'background: url(' + this.data.cover_picture + ') center';
    }
    this.linkDetails = TEMPLATES_PATH + '/' + this.data.id;
  }

}
