export const CURRENT_EXPERIENCE_ID = 'current_id';
export const OWNER_EXPERIENCE = 'owner';
export const LIMIT_FILTER = 'limit';
export const SORT_FILTER = 'sort';
export const RANDOM_FILTER = 'random';
export const IN_MODERATION = "inModeration"
// reviews
export const USER_IS_NOT = 'creatorIsNot';
export const USER_ID_IS = 'userId';
export const FULL_RESPONSE = 'fullResponse'
//like_dislike
export const CREATOR = 'creator';
export const USER_ID = 'id_user';
export const ACTION = 'action';
