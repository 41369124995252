import {
  LABEL_CREATE_NEW_ASSET,
  LABEL_CREATE_NEW_EXPERIENCE,
  LABEL_CREATE_NEW_GROUP,
  LABEL_CREATE_NEW_TEMPLATE,
  LABEL_DASHBOARD, LABEL_MODERATE,
  LABEL_MY_ASSETS_LIBRARY,
  LABEL_MY_EXPERIENCES, LABEL_MY_FAVORITES,
  LABEL_MY_GROUPS,
  LABEL_MY_PROFILE,
  LABEL_MY_TEMPLATES,
  LABEL_SETTINGS
} from '../actionsLabels/labels';

import {
  CREATE_EXPERIENCE_PATH,
  CREATE_GROUP_PATH, CREATE_MY_LIBRARY_PATH,
  CREATE_TEMPLATE_PATH,
  DASHBOARD_PATH,
  EDIT_GROUP_PATH,
  EDIT_PATH,
  EXPERIENCE_PATH, FAVORITE_PATH,
  MANAGE_EXPERIENCES_GROUPS_PATH,
  MANAGE_MEMBERS_GROUPS_PATH, MODERATE_PATH,
  MY_EXPERIENCES_PATH, MY_FAVORITE_PATH,
  MY_GROUPS_PATH,
  MY_LIBRARY_PATH,
  MY_TEMPLATES_PATH,
  PROFILE_PATH
} from '../actionsLabels/pathLabels';

export const myAccountMenu = [
  {
    label: LABEL_DASHBOARD,
    link: DASHBOARD_PATH,
    icon: 'fa fa-tachometer',
    activeLink: [DASHBOARD_PATH]
  },
  {
    label: LABEL_MY_PROFILE,
    link: PROFILE_PATH,
    icon: 'fa fa-user',
    activeLink: [PROFILE_PATH]
  },
  {
    label: LABEL_MY_EXPERIENCES,
    link: MY_EXPERIENCES_PATH,
    icon: 'fas fa-expand',
    activeLink: [EXPERIENCE_PATH, EDIT_PATH]
  },
  {
    label: LABEL_CREATE_NEW_EXPERIENCE,
    link: CREATE_EXPERIENCE_PATH,
    icon: 'fa fa-plus',
    activeLink: 'rien'
  },
  {
    label: LABEL_MY_TEMPLATES,
    link: MY_TEMPLATES_PATH,
    icon: 'fas fa-clipboard-list',
    activeLink: [EDIT_PATH]
  },
  {
    label: LABEL_CREATE_NEW_TEMPLATE,
    link: CREATE_TEMPLATE_PATH ,
    icon: 'fa fa-plus',
    activeLink: 'rien'
  },
  {
    label: LABEL_MY_GROUPS,
    link: MY_GROUPS_PATH,
    icon: 'fa fa-users',
    activeLink: [MY_GROUPS_PATH, MANAGE_EXPERIENCES_GROUPS_PATH, MANAGE_MEMBERS_GROUPS_PATH, EDIT_GROUP_PATH]
  },
  {
    label: LABEL_CREATE_NEW_GROUP,
    link: CREATE_GROUP_PATH,
    icon: 'fa fa-plus',
    activeLink: [CREATE_GROUP_PATH]
  },
  {
    label: LABEL_MY_FAVORITES,
    link: MY_FAVORITE_PATH,
    icon: 'fa fa-heart',
    activeLink: [FAVORITE_PATH]
  },

  {
    label: LABEL_MY_ASSETS_LIBRARY ,
    link: MY_LIBRARY_PATH,
    icon: 'fa fa-archive',
    activeLink: [MY_LIBRARY_PATH]
  },
  {
    label: LABEL_CREATE_NEW_ASSET,
    link: CREATE_MY_LIBRARY_PATH,
    icon: 'fa fa-plus',
    activeLink: [CREATE_MY_LIBRARY_PATH]
  },

];

/*
{
  label: LABEL_SETTINGS,
    link: COMING_SOON_PATH,
  icon: 'fa-cog',
  activeLink: [COMING_SOON_PATH]
},*/

export const myAccountMenuAdmin = [
  {
    label: LABEL_MODERATE,
    link: MODERATE_PATH,
    icon: 'fa  fa-calendar-check-o',
    activeLink: [MODERATE_PATH]
  },
  {
    label: LABEL_DASHBOARD,
    link: DASHBOARD_PATH,
    icon: 'fa fa-tachometer',
    activeLink: [DASHBOARD_PATH]
  },
  {
    label: LABEL_MY_PROFILE,
    link: PROFILE_PATH,
    icon: 'fa  fa-user',
    activeLink: [PROFILE_PATH]
  },
  {
    label: LABEL_MY_EXPERIENCES,
    link: MY_EXPERIENCES_PATH,
    icon: 'fas fa-expand',
    activeLink: [EXPERIENCE_PATH, EDIT_PATH]
  },
  {
    label: LABEL_CREATE_NEW_EXPERIENCE,
    link: CREATE_EXPERIENCE_PATH,
    icon: 'fa fa-plus',
    activeLink: 'rien'
  },
  {
    label: LABEL_MY_TEMPLATES,
    link: MY_TEMPLATES_PATH,
    icon: 'fas fa-clipboard-list',
    activeLink: [EDIT_PATH]
  },
  {
    label: LABEL_CREATE_NEW_TEMPLATE,
    link: CREATE_TEMPLATE_PATH ,
    icon: 'fa fa-plus',
    activeLink: 'rien'
  },
  {
    label: LABEL_MY_GROUPS,
    link: MY_GROUPS_PATH,
    icon: 'fa fa-users',
    activeLink: [MY_GROUPS_PATH, MANAGE_EXPERIENCES_GROUPS_PATH, MANAGE_MEMBERS_GROUPS_PATH, EDIT_GROUP_PATH]
  },
  {
    label: LABEL_CREATE_NEW_GROUP,
    link: CREATE_GROUP_PATH,
    icon: 'fa fa-plus',
    activeLink: [CREATE_GROUP_PATH]
  },
  {
    label: LABEL_MY_FAVORITES,
    link: MY_FAVORITE_PATH,
    icon: 'fa fa-heart',
    activeLink: [FAVORITE_PATH]
  },

  {
    label: LABEL_MY_ASSETS_LIBRARY ,
    link: MY_LIBRARY_PATH,
    icon: 'fa fa-archive',
    activeLink: [MY_LIBRARY_PATH]
  },
  {
    label: LABEL_CREATE_NEW_ASSET,
    link: CREATE_MY_LIBRARY_PATH,
    icon: 'fa fa-plus',
    activeLink: [CREATE_MY_LIBRARY_PATH]
  },

];

/*
{
  label: LABEL_SETTINGS,
    link: COMING_SOON_PATH,
  icon: 'fa fa-cog',
  activeLink: [COMING_SOON_PATH]
},*/
