<div class="error-popup">
  <div mat-dialog-title class="row ">
    <i class="fa fa-exclamation-circle fa-4x col-md-2"></i>
    <div class="col-md-9 popup-info" >
      <h2 class="col-md-12">{{data[0] }} </h2>

        <p class="col-md-12"class=" label" >{{data[1] }}</p>

    </div>
  </div>

</div>
<div class=" row col-md-12 actions-popup">
  <div class="col-md-1"></div>
  <button class="  button  col-md-offset-5 col-sm-4  waves-light btn-large  " (click)="onClick()">{{'understood' | translate }}</button>
</div>

