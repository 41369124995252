<h1 mat-dialog-title>{{msg}}  </h1>


<div mat-dialog-actions class="row  dialog-actions">
  <div class=" col-md-4" >
    <button class="surface-button  col-md-12"  (click)="onNoClick(surface)"></button>
    <p class=" label col-md-12 " >{{'Surface' | translate }}</p>
  </div>
  <div class=" col-md-4" >
    <button class="pc-button  col-md-12"  (click)="onNoClick(marker)"></button>
    <p class="  label col-md-12" >{{'Marker' | translate }}</p>
  </div>
  <div class=" col-md-4" >
    <button  class="poly-button  col-md-12" (click)="onNoClick(geo)"></button>
    <p class="  label col-md-12" >{{'GeoPosition' | translate }}</p>
  </div>
</div>

