<div class="row">
   <h4>{{sectionTitle | translate}}</h4>
   <div class="db-list-com tz-db-table">
     <div class="item-view">
      <a [routerLink]="[suggestedGroupsLink]">
            {{'VIEW_MORE' | translate}}
      </a>
     </div>
        <!------------ no suggested groups --------->
        <div class="tz-2-com tz-2-main" *ngIf="groups.length == 0 || noSuggestedGroup">
          <div class="center no_data_message">
            <div class="icon-content"><i class="fas fa-cubes"></i></div>
            {{"no_suggested_groups_message" | translate}}
          </div>
        </div>
        <!------------ end no suggested groups --------->
        <!------------ suggested groups  exist --------->
          <!----------------------------  carousel  ------------------------------>
         <owl-carousel *ngIf="groups.length != 0 && !noSuggestedGroup" [options]="carouselOptions" [carouselClasses]="['owl-theme', 'row', 'sliding']">
           <div *ngFor="let item of groups;let i= index" id="list">
             <app-listing-group-item id="suggested_group_item_{{i}}" [data]="item" (groupClickAction)="onItemGroupClick($event, i)" [disabledButton]="disabledButton">
             </app-listing-group-item>
           </div>
          </owl-carousel>
          <!----------------------------  end carousel  ------------------------------>
        <!------------ end suggested groups  exist--------->
   </div>
</div>
