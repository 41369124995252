import { Component, OnInit } from '@angular/core';
import {
  ASK_FOR_CONFIRM_DELETE_EXPERIENCE_FROM_FAVORITES,
  ASK_FOR_CONFIRM_DELETE_GROUP_FROM_FAVORITES,
  ASK_FOR_CONFIRM_DELETE_TEMPLATE_FROM_FAVORITES,
  REMOVE,
  DELETE_EXPERIENCE_FROM_FAVORITES, DELETE_GROUP_FROM_FAVORITES,
  DELETE_TEMPLATE_FROM_FAVORITES,
  MY_FAVORITES_PAGE_TITLE,
  PREVIEW_EXPERIENCE_ACTION, PREVIEW_GROUP_ACTION,
  PREVIEW_TEMPLATE_ACTION,
  SUCCESS_DELETE_EXPERIENCE_FROM_FAVORITES_MESSAGE_TITLE, SUCCESS_DELETE_GROUP_FROM_FAVORITES_MESSAGE_TITLE,
  SUCCESS_DELETE_TEMPLATE_FROM_FAVORITES_MESSAGE_TITLE
} from '../../utils/actionsLabels/labels';
import {EXPERIENCE_PATH, GROUPS_PATH, TEMPLATES_PATH} from '../../utils/actionsLabels/pathLabels';
import {User} from "../../reusables/interfaces/user";
import {ExperienceFavorite} from "../../reusables/interfaces/ExperienceFavorite";
import {ActivatedRoute, Router} from "@angular/router";
import {UsersService} from "../../reusables/services/users.service";
import {ExperiencesService} from "../../reusables/services/experiences.service";
import {Title} from "@angular/platform-browser";
import {CookieService} from "ngx-cookie-service";
import {ConfirmDialogService} from "../../reusables/components/confirm-dialog/confirm-dialog.service";
import {NotificationService} from "../../reusables/services/notification.service";
import {TokenHelperService} from "../../reusables/services/token-helper.service";
import {AuthGuard} from "../../reusables/_helpers";
import {HttpParams} from "@angular/common/http";
import {USER_ID} from "../../utils/queryParams/experiencesQueryParams";
import {TemplateFavorite} from "../../reusables/interfaces/TemplateFavorite";
import {GroupsService} from '../../reusables/services/groups.service';
import {GroupFavorite} from '../../reusables/interfaces/GroupFavorite';
import * as $ from 'jquery';


@Component({
  selector: 'app-profile-my-favorites',
  templateUrl: './profile-my-favorites.component.html',
  styleUrls: ['./profile-my-favorites.component.scss']
})
export class ProfileMyFavoritesComponent implements OnInit {

  deleteText = REMOVE;
  user: User;
  doneForUserProfile = false;
  disabledButtonExp = false;
  disabledButtonTemp = false;
  disabledButtonGroup = false;
  listExperiences: ExperienceFavorite[];
  listTemplates: TemplateFavorite[];
  listGroups: GroupFavorite[];
  noFavoritesGroup = false;
  noFavoritesExperiences = false;
  noFavoritesTemplates = false;
  doneForExperiences = false;
  doneForTemplates = false;
  doneForGroups = false;
  carouselOptions = {
    dots: false,
    autoplay: true,
    autoplayTimeout: 6000,
    nav: true,
    navText: ['<div class=\'nav-btn prev-slide\'></div>', '<div class=\'nav-btn next-slide\'></div>'],
    responsiveClass: true,
    margin: 10,
    // rewind: true,
    responsive: {
      0: {
        items: 1,
        nav: true,
        // loop: true
      },
      1200: {
        items: 2,
        nav: true,
        // loop: true
      },
      1600: {
        items: 3,
        nav: true,
        // loop: true
      },
      1890: {
        items: 4,
        nav: true,
        // loop: true
      }
    }
  };

  constructor(private route: ActivatedRoute,
              private router: Router,
              private usersService: UsersService,
              private experiencesService: ExperiencesService,
              private titleService: Title,
              private cookieService: CookieService,
              private confirmDialogService: ConfirmDialogService,
              private notificationService: NotificationService,
              private tokenHelperService: TokenHelperService,
              private groupsService: GroupsService,
              private authGuard: AuthGuard) {
  }

  ngOnInit(): void {
    if (this.authGuard.canActivate) {
      this.titleService.setTitle(MY_FAVORITES_PAGE_TITLE);
      this.getProfile();
    }
  }

  getProfile() {
    this.usersService.getUserProfile().subscribe((data: User) => {
      this.user = data;
      this.doneForUserProfile = true;
      this.getListExperiencesFavorite();
      this.getListTemplatesFavorite();
      this.getListGroupFavorite();
    });
  }

  //ExperienceFavorite

  getListExperiencesFavorite(){
    let params = new HttpParams();
    params = params.append(USER_ID, this.user.id);
    this.experiencesService.getListExperiencesFavorite(params).subscribe((data: ExperienceFavorite[]) => {
        this.listExperiences=data;
        this.doneForExperiences = true;
        this.disabledButtonExp = false;
      });
  }

  deleteExperienceFromFavorites(id,i) {
    this.confirmDialogService.confirmThis(ASK_FOR_CONFIRM_DELETE_EXPERIENCE_FROM_FAVORITES + '?', () => {
      this.disabledButtonExp = true;
      this.experiencesService.deleteExperienceFromFavorites(id).subscribe(() => {
        this.notificationService.showSuccess('', SUCCESS_DELETE_EXPERIENCE_FROM_FAVORITES_MESSAGE_TITLE);
        $('#suggested_experience_item_' + i).hide();
        const item = document.getElementById('suggested_experience_item_' + i);
        item.parentElement.parentElement.style.setProperty('display', 'none', 'important');
        if (this.listExperiences.length === 1) {
          this.noFavoritesExperiences = true;
        }
      });
    }, () => {
    });

  }


  //TemplateFavorite

  getListTemplatesFavorite(){
    let params = new HttpParams();
    params = params.append(USER_ID, this.user.id);
    this.experiencesService.getListTemplatesFavorite(params).subscribe((data: TemplateFavorite[]) => {
        this.listTemplates=data;
        this.doneForTemplates = true;
        this.disabledButtonTemp = false;
      });
  }

  deleteTemplateFromFavorites(id,i) {
    this.confirmDialogService.confirmThis(ASK_FOR_CONFIRM_DELETE_TEMPLATE_FROM_FAVORITES + '?', () => {
      this.disabledButtonTemp = true;
      this.experiencesService.deleteTemplateFromFavorites(id).subscribe(() => {
        this.notificationService.showSuccess('', SUCCESS_DELETE_TEMPLATE_FROM_FAVORITES_MESSAGE_TITLE);
        $('#suggested_template_item_' + i).hide();
        const item = document.getElementById('suggested_template_item_' + i);
        item.parentElement.parentElement.style.setProperty('display', 'none', 'important');
        if (this.listTemplates.length === 1) {
          this.noFavoritesTemplates = true;
        }
      });
    }, () => {
    });
  }


  //GroupFavorite

  getListGroupFavorite(){
    let params = new HttpParams();
    params = params.append(USER_ID, this.user.id);
    this.groupsService.getListGroupFavorite(params).subscribe((data: GroupFavorite[]) => {
        this.listGroups=data;
        this.doneForGroups = true;
        this.disabledButtonGroup = false;
      });
  }

  deleteGroupFromFavorites(id,i) {
    this.confirmDialogService.confirmThis(ASK_FOR_CONFIRM_DELETE_GROUP_FROM_FAVORITES + '?', () => {
      this.disabledButtonGroup = true;
      this.groupsService.deleteGroupFromFavorites(id).subscribe(() => {
        this.notificationService.showSuccess('', SUCCESS_DELETE_GROUP_FROM_FAVORITES_MESSAGE_TITLE);
        $('#suggested_group_item_' + i).hide();
        const item = document.getElementById('suggested_group_item_' + i);
        item.parentElement.parentElement.style.setProperty('display', 'none', 'important');
        if (this.listGroups.length === 1) {
          this.noFavoritesGroup = true;
        }
      });
    }, () => {
    });

  }

  openDetailsExperience(id) {
    this.router.navigateByUrl(EXPERIENCE_PATH + '/' + id).then();
  }
  openDetailsTemplate(id) {
    this.router.navigateByUrl(TEMPLATES_PATH + '/' + id).then();
  }
  openDetailsGroup(id) {
    this.router.navigateByUrl(GROUPS_PATH + '/' + id).then();
  }

  experienceFavoriteAction(event) {
    if (event && event.data !== '') {
      switch (event.data) {
        case DELETE_EXPERIENCE_FROM_FAVORITES:
          this.deleteExperienceFromFavorites(event.id,event.i);
          break;
        case PREVIEW_EXPERIENCE_ACTION:
          this.openDetailsExperience(event.id);
          break;
      }
    }
  }
  templateFavoriteAction(event) {
    if (event && event.data !== '') {
      switch (event.data) {
        case DELETE_TEMPLATE_FROM_FAVORITES:
          this.deleteTemplateFromFavorites(event.id,event.i);
          break;
        case PREVIEW_TEMPLATE_ACTION:
          this.openDetailsTemplate(event.id);
          break;
      }
    }
  }
  groupFavoriteAction(event) {
    if (event && event.data !== '') {
      switch (event.data) {
        case DELETE_GROUP_FROM_FAVORITES:
          this.deleteGroupFromFavorites(event.id,event.i);
          break;
        case PREVIEW_GROUP_ACTION:
          this.openDetailsTemplate(event.id);
          break;
      }
    }
  }

}
