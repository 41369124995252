import {Component, OnInit} from '@angular/core';
import {User} from '../../reusables/interfaces/user';
import {AuthGuard} from '../../reusables/_helpers';
import {UsersService} from '../../reusables/services/users.service';

@Component({
  selector: 'app-my-templates',
  templateUrl: './my-templates.component.html',
  styleUrls: ['./my-templates.component.scss']
})
export class MyTemplatesComponent implements OnInit {

  user: User;
  waitingLoad = true;
  constructor(private authGuard: AuthGuard,
              private usersService: UsersService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    if (this.authGuard.canActivate) {
        this.getProfile();
      }
  }

  getProfile() {
    this.usersService.getUserProfile().subscribe((data: User) => {
      this.user = data;
      this.waitingLoad = false;
    });
  }

}
