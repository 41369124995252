import * as BABYLON from 'babylonjs';
import * as GUI from 'babylonjs-gui';
import {rotationForUnity} from '../../../utils/functions/RotationFunction';
import {OnClick} from '../../interfaces/on-click';
import {fromMToPx, fromPxToM} from '../../../utils/functions/PositionFunction';
import {v4 as uuidv4} from 'uuid';
import {round} from '../../../utils/functions/GlobalFunctions';
import {FOUR_DECIMALS, TOW_DECIMALS} from '../../../utils/global-constants/forModel';
import {changeScale} from '../../../utils/functions/sizeFunction';

export  const ratio = 9 / 16;
// export const defaultImageVideo = 'http://int.squeezer-software.com/camlann-int-test/assets/images/play.png';
export const defaultImageVideo = "assets/images/editor/default-video-image.png";

export class Video {
  public uniqueId;
  public url =  defaultImageVideo; // de  l'image
  public uri: string; // pour video
  public name = 'video';
  public type = 'video';
  public visible = true;
  public triggerVisible = true;

  // public id ;
  public width = 0;
  public height = 0;
  public nbrCopy = 0;

  public rotation = {x: 0, y: 0, z: 0};
  public position = {x: 0, y: 20, z: 30};
  public scale = {x: 1, y: 1, z: 1};

  public onClick: Array<OnClick>; // [{ type: 'URL', info:['https..........']}]

  // recreate=true: si c'est un objet dans content (database)
  constructor(object?, recreate?, name?, position?, uri?) {
    if (object ) { // used on clone or recreate from ccontent
      this.type = 'video';
      this.uri = object.uri;
      this.url = object.url;
      this.onClick = object.onClick;
      this.rotation = {x: object.rotation.x, y: object.rotation.y, z: object.rotation.z};
      if (recreate) { // recreate from ccontent
        this.name = object.name;
        this.position = {x: object.position.x, y: object.position.y, z: object.position.z};
        this.nbrCopy = 0;
        this.triggerVisible = true;
        this.visible = true;
        this.scale = {x: object.scale, y: object.scale, z: object.scale};
        this.width = fromMToPx(object.size.width / object.scale);
        this.height = fromMToPx(object.size.height / object.scale);

      } else { // used on clone (recreate === null)
        this.visible = object.visible;
        this.width = object.width;
        this.height = object.height;
        this.scale = object.scale;
      }
    }
    if (name) { // create new or clone
      this.name = name;
    }
    if (position) { // create new or clone
      this.position = position;
    }
    if (uri) { // create new
      this.uri = uri;
    }
  }

  addVideoFromObjScene(scene: BABYLON.Scene, recreate?) {
    this.url = defaultImageVideo;
    const image = new GUI.Image(' ', this.url);

    image.onImageLoadedObservable.add(() => {
      if (this.height === 0 && this.width === 0) {
        image.heightInPixels = image.domImage.naturalHeight;
        image.widthInPixels = image.domImage.naturalWidth;
        this.width = image.widthInPixels;
        this.height = this.width * ratio;
      }

      const plane = BABYLON.MeshBuilder.CreatePlane('video', {height: this.height, width: this.width }, scene);
      // if size > 50cm
      if (!recreate) { this.scale = changeScale(this.width, this.height, 0, this.scale); }

      plane.position = new BABYLON.Vector3(this.position.x, this.position.y, this.position.z);
      plane.rotation = new BABYLON.Vector3(this.rotation.x, this.rotation.y, this.rotation.z);
      plane.scaling = new BABYLON.Vector3(this.scale.x, this.scale.y, this.scale.z);
      const advancedTextureImage = GUI.AdvancedDynamicTexture.CreateForMesh(plane,  this.width, this.height);
      advancedTextureImage.addControl(image);
      plane.uniqueId = uuidv4();
      this.uniqueId = plane.uniqueId;
      plane.renderingGroupId = 2;
      plane.isVisible = this.visible;
      return plane;

    });
  }
  recreate(scene) {
    this.addVideoFromObjScene(scene, true);
  }
  outputJson(trigger) {
    // round function : pour limiter le nombre des chiffres après la virgule
    return {
      id: this.uniqueId,
      type: this.type,
      name: this.name,
      uri: this.uri,
      url: this.url,
      position: {
        x: round(fromPxToM(this.position.x - trigger.position.x), FOUR_DECIMALS),
        y: round(fromPxToM(this.position.y - trigger.position.y), FOUR_DECIMALS),
        z: round(fromPxToM(this.position.z - trigger.position.z), FOUR_DECIMALS),
      },
      rotation: {
        x: round(rotationForUnity(this.rotation).x, TOW_DECIMALS),
        y: round(rotationForUnity(this.rotation).y, TOW_DECIMALS),
        z: round(rotationForUnity(this.rotation).z, TOW_DECIMALS),
      },
      size: {
        width: round(fromPxToM(this.width * this.scale.x), FOUR_DECIMALS),
        height: round(fromPxToM(this.height * this.scale.x), FOUR_DECIMALS),
      },
      scale: round(this.scale.x, TOW_DECIMALS),
      onClick: this.onClick
    };
  }


}
