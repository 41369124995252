<div class="pglist-p1 pglist-bg pglist-p-com" id="ld-abour">
  <div class="pglist-p-com-ti" >
    <h3 style="overflow: auto;"><span > {{section.label | translate}} </span>
      <span *ngIf="section.experiencesNbr" class ="section-number"> {{experiencesNbr}}</span>
      <button *ngIf="section.addExperiencesButton"
              class=" section-button waves-effect waves-light "
              (click)="onAdd()" [class.isDisabled]="buttonsDisabled"
      >
       <i class="fa fa-plus"> </i> {{'Add' | translate}}
      </button>
    </h3>
    </div>
  <div class="list-pg-inn-sp" *ngIf="experiences && experiences.length > 6 && section.experiencesList">
    <div class="row span-none">
      <app-experience-grid
        *ngFor="let item of experiences | paginate: { itemsPerPage: 6, currentPage: e , id: 'pagesExperiences'}"
        [data]="item"></app-experience-grid>
    </div>
    <div class="row center-align">
      <pagination-controls (pageChange)="e = $event" id="pagesExperiences"
                           previousLabel=""
                           nextLabel=""
                           directionLinks="true"
                           autoHide="false"></pagination-controls>
    </div>
  </div>
  <div class="list-pg-inn-sp" *ngIf="experiences && experiences.length <= 6 && section.experiencesList">
    <div class="row span-none">
      <app-experience-grid
        *ngFor="let item of experiences "
        [data]="item"></app-experience-grid>
    </div>
  </div>
</div>

