<section class="item-favorite">
  <div class="">
    <div class="tz-2-com tz-2-main">
      <h4>{{"My favorite templates" | translate}}</h4>
      <div class="db-list-com tz-db-table">

        <!------------ no published templates --------->
        <div class="tz-2-com tz-2-main" *ngIf="listTemplates.length == 0 || noFavoritesTemplates">
          <div class="center no_data_message">
            <div class="icon-content"><i class="fas fa-cubes"></i></div>
            {{"NO_PUBLISEHD_TEMPLATE_MESSAGE" | translate}}
          </div>
        </div>
        <!------------ end no published templates  --------->


        <!------------ published templates  exist --------->
        <!----------------------------  carousel  ------------------------------>
        <owl-carousel *ngIf="listTemplates.length != 0 && !noFavoritesTemplates" [options]="carouselOptions" [carouselClasses]="['owl-theme', 'row', 'sliding']">
          <div *ngFor="let item of listTemplates;let i= index">
            <app-listing-template-favorite-item id="suggested_template_item_{{i}}" (templateAction)="TemplateFavoriteAction(item.template_id,i)"
                                                [data]="item">
            </app-listing-template-favorite-item>
          </div>
        </owl-carousel>
        <!----------------------------  end carousel  ------------------------------>
        <!------------ end published templates  exist--------->

      </div>
    </div>
  </div>
</section>




