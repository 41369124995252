import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Experience} from '../../../interfaces/experience';
import {Template} from '../../../interfaces/templates';

@Component({
  selector: 'app-details-suggestions',
  templateUrl: './details-suggestions.component.html',
  styleUrls: ['./details-suggestions.component.scss']
})
export class DetailsSuggestionsComponent implements OnInit {

  constructor() {
  }

  @Input() suggestedExperiences: Experience [];
  @Input() suggestedTemplates: Template [];
  @Input() source: string;
  @Output() visitExperience = new EventEmitter();

  ngOnInit(): void {
  }

  addAction() {
  }
}
