<section *ngIf="doneForAnalyticsChart">
  <div class="">
    <div class="">
      <div class="tz-2-com tz-2-main">
        <h4>{{"Visitors variation per" | translate}} {{variationTime}}</h4>
        <div class="db-list-com tz-db-table">

          <div class="filter-dates">
            <mat-form-field>
              <mat-label *ngIf="variationTime != dayVariationTime">{{"Start date:" | translate}}</mat-label>
              <mat-label *ngIf="variationTime == dayVariationTime">{{"Date:" | translate}}</mat-label>
              <input matInput [matDatepicker]="fromDatePicker" [formControl]="startDateFormControl"
                     (dateChange)="startDateChangedEvent($event)" disabled>
              <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #fromDatePicker disabled="false"></mat-datepicker>
            </mat-form-field>

            <mat-form-field *ngIf="variationTime != dayVariationTime">
              <mat-label>{{"End date" | translate}}</mat-label>
              <input matInput [matDatepicker]="toDatePicker" [formControl]="endDateFormControl"
                     (dateChange)="endDateChangedEvent($event)" disabled>
              <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #toDatePicker disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>


          <div class="filter-btn">
            <span class="span-link action-0" (click)="changeSelection(0)">{{'Daily' | translate}}</span>
            <span class="span-link action-1" (click)="changeSelection(1)">{{'Weekly' | translate}}</span>
            <span class="span-link  action-2 active" (click)="changeSelection(2)">{{'Monthly' | translate}}</span>
            <span class="span-link action-3" (click)="changeSelection(3)">{{'Yearly' | translate}}</span>
          </div>


          <div class="chartjs-container">
            <canvas baseChart
                    [datasets]="lineChartData"
                    [labels]="lineChartLabels"
                    [options]="lineChartOptions"
                    [colors]="lineChartColors"
                    [legend]="lineChartLegend"
                    [chartType]="lineChartType"
                    [plugins]="lineChartPlugins"
                    (chartHover)="chartHovered($event)"
                    (chartClick)="chartClicked($event)">
            </canvas>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

