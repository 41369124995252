import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from '../../../interfaces/user';
import {
  DISPLAY_NAME,
  EDIT_PASSWORD,
  EDIT_PASSWORD_ACTION,
  EDIT_PROFILE,
  EDIT_PROFILE_ACTION,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  MANAGE_PROFILE
} from '../../../../utils/actionsLabels/labels';

@Component({
  selector: 'app-manage-profile',
  templateUrl: './manage-profile.component.html',
  styleUrls: ['./manage-profile.component.scss']
})
export class ManageProfileComponent implements OnInit {

  constructor() {
  }

  @Input() user: User;
  @Output() changeAction = new EventEmitter();
  data: { label: string, value: string }[] = []; // data to display; // data to display
  actions: any; // actions to apply
  edit_password_label = EDIT_PASSWORD;
  edit_profile_label = EDIT_PROFILE;
  section_title = MANAGE_PROFILE;
  ngOnInit(): void {
    this.data = [
      {label: EMAIL, value: this.user.email},
      {label: FIRST_NAME, value: this.user.firstName},
      {label: LAST_NAME, value: this.user.lastName},
      {label: DISPLAY_NAME, value: this.user.displayName},
    ];
  }

  sendAction() {
    this.changeAction.emit({action: EDIT_PASSWORD_ACTION});
  }
  editProfile() {
    this.changeAction.emit({action: EDIT_PROFILE_ACTION});
  }
}
